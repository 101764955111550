import { Component, ElementRef, ViewChild, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomAgGrid } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { HomePageService } from '../shared/services/homePage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-audit-modal',
  templateUrl: './view-audit-modal.component.html',
  styleUrls: ['./view-audit-modal.component.less']
})
export class ViewAuditModalComponent implements OnInit, AfterViewInit {
  public rowData: any = [];
  @Input() auditData: any;
  @Output() onClickClose: EventEmitter<boolean> = new EventEmitter();
  public profileTableColumn = ['Last modified date', 'Last modified by', 'Last modified details'];
  @ViewChild('content', { static: false })
  content!: ElementRef;
  public gridModel: CustomAgGrid = {
    gridOptions: {},
    keysToSuppress: [13],
    labelAuthoring: {
      headerPageCount: '',
      filter: '',
      resetFilter: '',
      paginationHeader: '',
      noData: '',
      noDataOnFilter: '',
      noDataOnSearch: '',
      toTop: ''
    }
  };
  constructor(
    public homepageservice: HomePageService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit() {
    let row;
    if (this.auditData?.auditDetails && this.auditData.auditDetails.length > 0) {
      for (const list of this.auditData.auditDetails) {
        row = {
          lmDate: list.modifiedDate,
          lmBy: list.modifiedBy,
         /*  lmDetails: list.modifiedDetails.replace(/,/g,'') */         
         lmDetails: list.modifiedDetails
        }
        this.rowData.push(row);
      }
    } else {
      this.rowData = [];
    }
  }

  ngAfterViewInit() {
    this.open();
  }

  close () {
    this.onClickClose.emit(true);
    this.modalService.dismissAll();
  }

  open() {
    this.modalService.open(this.content, { ariaLabelledBy: 'audit-log-title', keyboard: false, backdrop: 'static',size: 'lg', windowClass: 'modal-xl' }).result.then(
      (result) => {
      },
      (reason) => {
      },
    );
  }
}
