import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminSettingsComponent } from './admin-settings/admin-settings.component';
import { ModifyCustomerComponent } from './admin-settings/modify-customer/modify-customer.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { AuthGuardService } from 'tw-core-ui';
import { CreateCabinetComponent } from './cabinets/create-cabinet/create-cabinet.component';
import { CabinetsComponent } from './cabinets/cabinets.component';
import { UploadCabinetsDocumentComponent } from './cabinets/upload-cabinets-document/upload-cabinets-document.component';
import { CabinetsDocumentListComponent } from './cabinets/cabinets-document-list/cabinets-document-list.component';
import { WhitelistComponent } from './whitelist/whitelist.component';
import { ManageCabinetsListComponent } from './manage-cabinets-list/manage-cabinets-list.component';
import { ManageCabinetsAccessComponent } from './manage-cabinet-access/manage-cabinet-access.component';
import { FaqComponent } from './faq/faq.component';
import { DuctAccessFormComponent } from './duct-access-form/duct-access-form.component';
import { NbnDocumentsListComponent } from './nbn-user/documents-list/nbn-documents-list.component';
import { NbnUserLandingPageComponent } from './nbn-user/landing-page/landing-page.component';
import { RouteGuardService } from './shared/services/route-guard.service';
import { CreateUserComponent } from './admin-settings/user/create-user/create-user.component';
import { UserListComponent } from './admin-settings/user/user-list/user-list.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { ViewDocumentRendererComponent } from '../app/view-document-renderer/view-document-renderer.component';
import { ErrorComponent } from './error/error.component';
import { QuoteManagementComponent } from './quote-management/quote-management.component';
import { ReviewAndSubmitComponent } from './quote-management/create-new-quote/review-and-submit/review-and-submit.component';
import { RequestDetailsComponent } from './quote-management/create-new-quote/request-details/request-details.component';
import { MyQuotesComponent } from './quote-management/my-quotes/my-quotes.component';
import { CreateNewQuoteComponent } from './quote-management/create-new-quote/create-new-quote.component';
import { SuccessPageComponent } from './success-page/success-page.component';
import { ProductDetailsComponent } from './quote-management/create-new-quote/product-details/product-details.component';
import { SiteDetailsComponent } from './quote-management/create-new-quote/site-details/site-details.component';
import { QuoteSummaryComponent } from './quote-management/quote-summary/quote-summary.component';
import { CanNavigateGuardService } from './can-navigate-guard.service';
import { OrderSiteDetailsComponent } from './order-managemnt/create-new-order/order-site-details/order-site-details.component';
import { MyOrdersComponent } from './order-managemnt/my-orders/my-orders.component';
import { OrderRequestDetailsComponent } from './order-managemnt/create-new-order/order-request-details/order-request-details.component';
import { OrderReviewSubmitComponent } from './order-managemnt/create-new-order/order-review-submit/order-review-submit.component';
import { BulkRequestDetailsComponent } from './bulk-quote-management/bulk-request-details/bulk-request-details.component';
import { BulkUploadComponent } from './bulk-quote-management/bulk-upload/bulk-upload.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: HomeComponent, canActivate: [AuthGuardService] },
  { path: 'cabinet', component: CabinetsComponent, canActivate: [AuthGuardService] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuardService, RouteGuardService], data: { path: 'profile' } },
  { path: 'admin/:id', component: AdminSettingsComponent, canActivate: [AuthGuardService, RouteGuardService], data: { path: 'admin' } },
  { path: 'admin', component: AdminSettingsComponent, canActivate: [AuthGuardService, RouteGuardService], data: { path: 'admin' } },
  { path: 'home/create-document-cabinet', component: CreateCabinetComponent, canActivate: [AuthGuardService] },
  { path: 'cabinet-list/:name/:id', component: CabinetsDocumentListComponent, canActivate: [AuthGuardService, RouteGuardService], data: { path: 'cabinet-list' } },
  { path: 'cabinet-list/:name/:id/:role', component: CabinetsDocumentListComponent, canActivate: [AuthGuardService, RouteGuardService], data: { path: 'cabinet-list' } },
  { path: 'home/upload-cabinets-document/:name/:id', component: UploadCabinetsDocumentComponent, canActivate: [AuthGuardService] },
  { path: 'home/upload-cabinets-document/:name/:id/:role', component: UploadCabinetsDocumentComponent, canActivate: [AuthGuardService] },
  { path: 'whitelist/:id', component: WhitelistComponent, canActivate: [AuthGuardService] },
  { path: 'whitelist', component: WhitelistComponent, canActivate: [AuthGuardService] },
  { path: 'manage-cabinet/:id', component: ManageCabinetsListComponent, canActivate: [AuthGuardService, RouteGuardService], data: { path: 'manage-cabinet' } },
  { path: 'manage-cabinet', component: ManageCabinetsListComponent, canActivate: [AuthGuardService, RouteGuardService], data: { path: 'manage-cabinet' } },
  { path: 'manage-cabinet-access', component: ManageCabinetsAccessComponent, canActivate: [AuthGuardService] },
  { path: 'faqs', component: FaqComponent, canActivate: [AuthGuardService] },
  { path: 'duct-access-form', component: DuctAccessFormComponent, canActivate: [AuthGuardService] },
  { path: 'documents', component: NbnUserLandingPageComponent, canActivate: [AuthGuardService] },
  { path: 'document', component: NbnDocumentsListComponent, canActivate: [AuthGuardService, RouteGuardService], data: { path: 'document' } },
  { path: 'document/:cid/:name', component: ViewDocumentRendererComponent, canActivate: [AuthGuardService, RouteGuardService], data: { path: 'view-document' } },
  { path: 'create-user', component: CreateUserComponent, canActivate: [AuthGuardService] },
  { path: 'user-list', component: UserListComponent, canActivate: [AuthGuardService] },
  { path: 'quote', component: QuoteManagementComponent, canActivate: [AuthGuardService] },
  { path: 'new-quote', component: CreateNewQuoteComponent, canActivate: [AuthGuardService] },
  { path: 'myQuotes', component: MyQuotesComponent, canActivate: [AuthGuardService, CanNavigateGuardService], data: { path: 'myQuotes' } },
  { path: 'quoteSummary/:id', component: QuoteSummaryComponent, canActivate: [AuthGuardService, CanNavigateGuardService], data: { path: 'quoteSummary' } },
  { path: 'access-denied', component: AccessDeniedComponent, canActivate: [AuthGuardService] },
  { path: 'error', component: ErrorComponent, canActivate: [AuthGuardService] },
  { path: 'review-submit', component: ReviewAndSubmitComponent, canActivate: [AuthGuardService, CanNavigateGuardService], data: { path: 'review-submit' } },
  { path: 'success', component: SuccessPageComponent, canActivate: [AuthGuardService, CanNavigateGuardService], data: { path: 'success' } },
  { path: 'product-details', component: ProductDetailsComponent, canActivate: [AuthGuardService, CanNavigateGuardService], data: { path: 'product-details' } },
  { path: 'site-details', component: SiteDetailsComponent, canActivate: [AuthGuardService, CanNavigateGuardService], data: { path: 'site-details' } },
  { path: 'request-details', component: RequestDetailsComponent, canActivate: [AuthGuardService, CanNavigateGuardService], data: { path: 'request-details' } },
  { path: 'order-site-details/:type/:quoteId', component: OrderSiteDetailsComponent, canActivate: [AuthGuardService, CanNavigateGuardService], data: { path: 'order-site-details' } },
  { path: 'order-request-details/:type/:quoteId', component: OrderRequestDetailsComponent, canActivate: [AuthGuardService, CanNavigateGuardService], data: { path: 'order-request-details' } },
  { path: 'order-review-submit/:type/:quoteId', component: OrderReviewSubmitComponent, canActivate: [AuthGuardService, CanNavigateGuardService], data: { path: 'order-review-submit' } },
  { path: 'order-success', component: SuccessPageComponent, canActivate: [AuthGuardService, CanNavigateGuardService], data: { path: 'order-success' } },
  { path: 'myOrders', component: MyOrdersComponent, canActivate: [AuthGuardService, CanNavigateGuardService], data: { path: 'myOrders' } },
  { path: 'orderSummary/:path/:id', component: QuoteSummaryComponent, canActivate: [AuthGuardService, CanNavigateGuardService], data: {path: 'orderSummary'}},
  { path: 'bulk-quote-request-details/:id', component: BulkRequestDetailsComponent, canActivate: [AuthGuardService,CanNavigateGuardService], data: { path: 'bulk-quote-request-details' } },
  { path: 'bulk-quote-request-details', component: BulkRequestDetailsComponent, canActivate: [AuthGuardService,CanNavigateGuardService], data: { path: 'bulk-quote-request-details' } },
  { path: 'bulk-quote-upload/:id', component: BulkUploadComponent, canActivate: [AuthGuardService,CanNavigateGuardService], data: { path: 'bulk-quote-upload' } },
  { path: 'bulk-quote-success', component: SuccessPageComponent, canActivate: [AuthGuardService,CanNavigateGuardService], data: { path: 'bulk-quote-success' } },
  { path: '**', pathMatch: 'full', component: HomeComponent, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
