import { Component, ElementRef, HostListener, Inject, Input, LOCALE_ID, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { CustomDatePicker, CustomDropDown, TooltipContent } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import Holidays from 'date-holidays';
import * as moment_ from 'moment-timezone';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import { myQuotesService } from 'src/app/quote-management/my-quotes/myquotes.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MyOrdersService } from 'src/app/order-managemnt/my-orders.service';
import { CreateQuoteService } from 'src/app/quote-management/create-new-quote/create-quote.service';
import { BulkQuoteService } from '../bulk-quote.service';
const moment_business_days = require('moment-business-days');


@Component({
  selector: 'app-bulk-request-details',
  templateUrl: './bulk-request-details.component.html',
  styleUrls: ['./bulk-request-details.component.less']
})
export class BulkRequestDetailsComponent implements OnInit {
  @ViewChildren("tref") tref: QueryList<ElementRef>;
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;

  public aemLabelAuthoring: any;
  stepperData = ['Request details', 'Upload and Submit'];
  stepName = 'Request details';
  pattern = /^[a-zA-Z0-9!@#$%^&*()\-\_=+:'",.?|{}\n\[\]\/\`\~\\ ]*$/;
  pattern2 = /^[a-zA-Z!@#$%^&*()\-\_=+:'",.?|{}\n\[\]\/\`\~\\ ]*$/;

  public bulkRequestDetailsForm: FormGroup;
  cidn: string;
  orderDetailsData: any;
  userTimeZone: string;
  form = this.fb.group({
    externalRefernces: this.fb.array([]),
  });
  AdditionalExternalReferenceList;
  options: GlobalConfig;
  serviceDatePicker = {
    year: '',
    month: '',
    day: ''
  };
  isQuoteSavedStatus: boolean = false;
  public custRequestedDate: CustomDatePicker = {
    automationTypeImage: '',
    automationIdImage: '',
    automationTypeDatePicker: '',
    automationIdDatePicker: '',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: '',
    automationIdValidationError: '',
    placeholderCaption: '--/--/----',
    id: 'custRequestedDate',
    tabindex: '',
    requiredText: 'Please select a date',
    validationMessage: '',
    isMandatory: false,
    name: 'custRequestedDate',
    formControlName: 'custRequestedDate',
    isDisabled: false,
    isWeekendsDisabled: true,
    isPublicHolidayDisabled: true,
    publicHolidayRegion: {
      country: 'AU'
    },
    isManualEntry: true,
    addBusinessDays: 2
  };

  moment = moment_;
  momentBusiness = moment_business_days;
  selectedTimeZone = 'Australia/Victoria';
  publicHolidays = [];
  fromDate;
  toMaxDate;
  dateRequired: string;
  selectedServiceDate: any;
  sysID: any;
  public externalRefernceForm: FormGroup;
  public externalReferenceDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'External reference Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  public externalReferenceDropDownModelAdd: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'External reference Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  public businessDriverDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Business driver Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };

  bulkRequestId: string;
  public customerChange: Subscription;
  infraCoCIDN = window['environment'].InfraCoCIDN;
  public businessDriverList = [];
  productData: any;
  public externalReferenceList = [];
  public businessList = [];
  createQuotePayload: any;
  public dateTooltipContentModel: TooltipContent = {
    content: 'At least 2 business days after request submission Business days (Monday to Friday) only',
    automationTypeTooltipIcon: 'image',
    automationIdTooltipIcon: 'imgAddressInformation'
  };

  showBulkQuoteId: boolean = false;
  bulkRequestStatus: string;
  loggedInUserEmail: string = sessionStorage.getItem('email') ? sessionStorage.getItem('email') : '';
  isBulkRequestSaved: boolean = false;

  isNewBulkQuoteRequestDetailPage: boolean = false;
  showAlertBusinessDriver: boolean = false;
  showAlertExternalRef: boolean = false;
  isModifyBulkQuote: any;
  serviceType: string;
  holidayDates: any = [];
  minbusinessDayFromToday: any;
  isTermsCondition: boolean;
  tAndcText;
  product: any;
  bulkQuoteID: any;
  bulkPayload: any;
  constructor(private bulkQuoteService: BulkQuoteService, @Inject(LOCALE_ID) private locale: string,
    private fb: FormBuilder, private toastr: ToastrService, private titleService: Title, private router: Router, private utility: UtilityService,
    private route: ActivatedRoute, private commonService: CommonService,
    private dataSvc: myQuotesService,
    private createQuoteService: CreateQuoteService,
  ) {
    this.titleService.setTitle('InfraCo Customer Portal - Bulk Request Details page');
    this.bulkQuoteID = this.route.snapshot.paramMap.get('id');
    this.options = this.toastr.toastrConfig;
    // Get User TimeZone
    this.getUserTimeZone();

    // Get CIDN
    if (localStorage.getItem('selectedHeaderCIDN')) {
      this.cidn = localStorage.getItem('selectedHeaderCIDN')
    }
    if (localStorage.getItem('snowID')) {
      this.sysID = JSON.parse(JSON.stringify(localStorage.getItem('snowID')));
    }

    this.customerChange = this.commonService.getCustomerName().subscribe((data) => {
      this.dataSvc.customerNameDropdownChange(false, data);
    });


  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.isNewBulkQuoteRequestDetailPage = (this.router.url.startsWith('/bulk-quote-request-details/')) ? true : false;
    this.isModifyBulkQuote = window.history.state.isModifyBulkQuote;
    this.getLabels();
    this.createForm();

    if (this.isNewBulkQuoteRequestDetailPage) {
      this.bulkRequestId = this.route.snapshot.paramMap.get('id');
      // GET Bulk Request Details
      this.isBulkRequestSaved = true;
      this.getBulkRequestDetails();
    }

    // GET Business Driver and External reference
    this.getProductCatalogDataFromAPI();
    this.setDatePicker();

  }

  getLengthOfbulkQuoteSummary() {
    return this.bulkRequestDetailsForm.getRawValue().bulkQuoteSummary?.length
  }




  selectBusinessDriver() {
    this.showAlertBusinessDriver = true;
  }

  selectExternalRef() {
    this.showAlertExternalRef = true;
  }

  getProductCatalogDataFromAPI(params?: any) {
    const param = params || null;
    const isBulkQuote = true;
    return new Promise((resolve, reject) => {
      this.createQuoteService.getProductCatalogDataFromAPI(param, this.cidn, isBulkQuote).then((res) => {

        if (res && res?.serviceType) {
          this.serviceType = res?.serviceType;
        }

        this.productData = this.createQuoteService.productDataObj
        this.getDiversityDropdownValues();
        resolve(true);
      }).catch((err) => {
        this.productData = [];
        const msg = 'We can’t submit your request at this time. Please refresh and try again later.';
        this.showToasterMessage(msg, '', this.options, 'error');
        reject(err);
      });

    });
  }



  private getDiversityDropdownValues() {
    this.businessDriverList = [];
    this.bulkRequestDetailsForm['controls']['businessDriver'].setValidators([Validators.required]);
    if (this.productData) {
      this.productData.forEach(val => {
        if (val.name === 'Dark Fibre') {
          val.prodSpecCharValueUse.forEach(value => {
            if (value.name === 'Business Driver') {
              value.productSpecCharacteristicValue.forEach(a => { this.businessDriverList.push(a.value) })
            }
            if (value.name === 'External Reference Options') {
              value.productSpecCharacteristicValue.forEach(a => { this.externalReferenceList.push(a.value) })
            }
          })

          this.businessDriverList.sort(function (a, b) { return (a.trim().toLowerCase() > b.trim().toLowerCase() ? 1 : (a.trim().toLowerCase() === b.trim().toLowerCase() ? 0 : -1)) })
          this.externalReferenceList.sort(function (a, b) { return (a.trim().toLowerCase() > b.trim().toLowerCase() ? 1 : (a.trim().toLowerCase() === b.trim().toLowerCase() ? 0 : -1)) })
        }
      });

    }
  }


  createForm() {
    this.bulkRequestDetailsForm = this.fb.group({
      custRequestedDate: [this.fromDate, Validators.required],
      extReferenceType1: [''],
      extReferenceValue1: ['', [Validators.pattern(this.pattern), Validators.maxLength(64)]],
      extReferenceType: [''],
      businessDriver: [''],
      estimatedQuoteRequestsBulk: ['', [Validators.pattern(this.pattern), Validators.maxLength(3), Validators.min(1), Validators.max(500), Validators.required]],
      extReferenceValue: ['', [Validators.pattern(this.pattern), Validators.maxLength(64)]],
      bulkQuoteName: ['', [Validators.pattern(this.pattern2), Validators.maxLength(256)]],
      bulkQuoteReference: ['', [Validators.pattern(this.pattern), Validators.maxLength(255)]],
      bulkQuoteSummary: ['', [Validators.pattern(this.pattern), Validators.maxLength(4000)]],
      externalRefernces: ['']
    });

    if (this.serviceType == "RG") {
      this.bulkRequestDetailsForm.get('businessDriver').setValidators([Validators.required]);
      this.bulkRequestDetailsForm.get('extReferenceType1').setValidators([Validators.required]);
      this.bulkRequestDetailsForm.get('extReferenceValue1').setValidators([Validators.required])
    }



  }



  setFormData(orderDetail) {
    if (!orderDetail) return;

    this.setFormValue('estimatedQuoteRequestsBulk', orderDetail.estimatedQuoteRequests);
    this.setFormValue('bulkQuoteName', orderDetail.bulkQuoteName);
    if (orderDetail.relatedBulkQuoteReferences?.length) {
      this.setFormValue('bulkQuoteReference', orderDetail.relatedBulkQuoteReferences[0]);
    }
    this.setFormValue('bulkQuoteSummary', orderDetail.bulkQuoteSummary);

    this.setFormValue('businessDriver', orderDetail.businessDriver);



    const dateString = orderDetail.customerRequestedDate;
    if (dateString) {
      this.dateRequired = orderDetail.customerRequestedDate;
      const date = new Date(dateString);
      const fromDate = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
      };
      this.bulkRequestDetailsForm.get('custRequestedDate').setValue(fromDate);
    }

    this.setExternalreferences();



    if (this.isModifyBulkQuote) {
      this.bulkRequestDetailsForm.get('bulkQuoteName').disable();
      this.bulkRequestDetailsForm.get('estimatedQuoteRequestsBulk').disable();
      this.bulkRequestDetailsForm.get('custRequestedDate').disable();
      this.bulkRequestDetailsForm.get('bulkQuoteSummary').disable();
      this.bulkRequestDetailsForm.get('extReferenceValue1').disable();
      this.businessDriverDropDownModel.isDisabled = true;
      this.externalReferenceDropDownModel.isDisabled = true;
      this.externalReferenceDropDownModelAdd.isDisabled = true;
      (this.form.get('externalRefernces') as FormArray).disable();

    }
  }


  // back() {
  //   this.saveBulkRequestDetails('/order-site-details');
  // }

  next() {
    this.saveBulkRequestDetails('next');
  }

  cancel() {
    this.router.navigate(['/myQuotes']);
  }


  getLabels() {
    this.bulkQuoteService
      .getBulkRequestPageLabel()
      .then((data: any) => {
        this.aemLabelAuthoring = data;
      })
      .catch((error) => { });
  }


  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-top-center';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 5000;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    // document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
    //document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
  }


  setFormValue(id, value) {
    // this.setFormValue('bulkQuoteReference', orderDetail.relatedBulkQuoteReferences[0]);

    // if (id === 'extReferenceType' && value) {
    //   if (this.externalRefernceForm) {
    //     this.externalRefernceForm['controls'][id].setValue(value);
    //   }
    // }
    this.bulkRequestDetailsForm['controls'][id].patchValue(value || '');
  }


  setDatePicker() {

    let hd = new Holidays('AU', 'NSW');
    const today = this.moment().tz(this.selectedTimeZone).toDate();
    const holidayTwoYears = [];
    hd.getHolidays(today.getFullYear()).forEach(dateObj => {
      this.publicHolidays.push(dateObj.date.substr(0, 10));
      holidayTwoYears.push(dateObj.date.substr(0, 10));
    });

    if (today.getMonth() >= 10) {
      hd.getHolidays(today.getFullYear() + 1).forEach(dateObj => {
        this.publicHolidays.push(dateObj.date.substr(0, 10));
      });
    }
    hd.getHolidays(today.getFullYear() + 1).forEach(dateObj => {
      holidayTwoYears.push(dateObj.date.substr(0, 10));
    });

    holidayTwoYears.map(ph => {
      this.holidayDates.push(this.moment(ph).format("DD/MM/YYYY"));
    });
    this.momentBusiness.updateLocale('au', {
      holidays: this.publicHolidays,
      holidayFormat: 'YYYY-MM-DD'
    });
    let minDate;
    if (moment_business_days(this.moment(today).format('YYYY-MM-DD'), 'YYYY-MM-DD').isBusinessDay()) {
      minDate = this.momentBusiness(today).businessAdd(2).toDate();
      this.custRequestedDate.addBusinessDays = 2;
    } else {
      minDate = this.momentBusiness(today).businessAdd((3)).toDate();
      this.custRequestedDate.addBusinessDays = 3;
    }
    if(this.orderDetailsData && this.orderDetailsData.responseRequiredDate) {
      const date1 = new Date(this.orderDetailsData.responseRequiredDate);
      const date2 = new Date(minDate);
      date1.setHours(0, 0, 0, 0);
      date2.setHours(0, 0, 0, 0);
      if (date1 && (date1 > date2)) {
        this.fromDate = {
          year: date1.getFullYear(),
          month: date1.getMonth() + 1,
          day: date1.getDate()
        };
        this.bulkRequestDetailsForm.get('custRequestedDate').setValue(this.fromDate);
      }
    } else {
      this.fromDate = {
        year: minDate.getFullYear(),
        month: minDate.getMonth() + 1,
        day: minDate.getDate()
      };
      this.bulkRequestDetailsForm.get('custRequestedDate').setValue(this.fromDate);
    }
    const date = new Date(this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day);
    this.dateRequired = this.fromDate.year + '-' + ((this.fromDate.month.toString()).length === 1 ? ('0' + this.fromDate.month) : (this.fromDate.month)) + '-' + ((this.fromDate.day.toString()).length === 1 ? ('0' + this.fromDate.day) : (this.fromDate.day)) + 'T00:00:00Z'
    this.moment(date).utc().format();
    this.toMaxDate = {
      year: minDate.getFullYear() + 1,
      month: minDate.getMonth() + 1,
      day: minDate.getDate()
    };

  }

  onChangeServiceDate($event) {
    this.selectedServiceDate = this.bulkRequestDetailsForm.controls['custRequestedDate'].value;
    if (!this.selectedServiceDate) return;
    this.dateRequired = this.selectedServiceDate.year + '-' + ((this.selectedServiceDate.month?.toString())?.length === 1 ? ('0' + this.selectedServiceDate.month) : (this.selectedServiceDate.month)) + '-' + ((this.selectedServiceDate.day?.toString())?.length === 1 ? ('0' + this.selectedServiceDate.day) : (this.selectedServiceDate.day)) + 'T00:00:00Z'
  }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  getUserTimeZone() {
    const userTimeZone = Intl.DateTimeFormat(this.locale, {
      timeZoneName: 'long'
    }).resolvedOptions().timeZone;

    this.userTimeZone = userTimeZone;
  }

  deleteExternalRefernce(externalRefernceIndex: number) {
    this.externalRefernces.removeAt(externalRefernceIndex);
  }


  setExternalreferences() {
    let newArray = [];
    if (this.orderDetailsData?.bulkExternalReference?.length > 0) {
      newArray = this.orderDetailsData.bulkExternalReference.slice(1);
      newArray.forEach((data) => {
        const extForm = this.fb.group({
          extReferenceType: [data.extReferenceType],
          extReferenceValue: [data.extReferenceValue],
        });
        (this.form.get('externalRefernces') as FormArray).push(extForm);
      });
    };

    if (this.orderDetailsData.bulkExternalReference?.length > 0) {
      this.bulkRequestDetailsForm.controls['extReferenceType1'].patchValue(this.orderDetailsData.bulkExternalReference[0]?.extReferenceType);
      this.bulkRequestDetailsForm.controls['extReferenceValue1'].setValue(this.orderDetailsData.bulkExternalReference[0].extReferenceValue);
    }
  }




  convertDateFormatISO(d) {
    const date = new Date(d);
    return date.toISOString();
  }

  get externalRefernces() {
    return this.form.controls["externalRefernces"] as FormArray;

  }


  addExternalRefernceForm() {
    const forbiddenCharsPattern = /[<>;]/;
    this.externalRefernceForm = this.fb.group({
      extReferenceType: ['', Validators.required],
      extReferenceValue: ['', [Validators.pattern(this.pattern), Validators.maxLength(64), this.forbiddenCharsValidator(forbiddenCharsPattern)]],
    });
    this.externalRefernceForm['controls']['extReferenceType'].updateValueAndValidity();
    this.externalRefernceForm['controls']['extReferenceValue'].updateValueAndValidity();
    this.externalRefernces.push(this.externalRefernceForm);
    setTimeout(() => this.tref?.last.nativeElement.focus(), 1);

  }


  forbiddenCharsValidator(forbiddenCharsPattern: RegExp) {
    return (control: FormControl): { [key: string]: any } | null => {
      const forbidden = forbiddenCharsPattern.test(control.value);
      return forbidden ? { 'forbiddenChars': { value: control.value } } : null;
    };
  }


  deleteLesson(lessonIndex: number) {
    this.externalRefernces.removeAt(lessonIndex);
  }




  // GET bulk requet details
  getBulkRequestDetails(bulkRequestId?, sysId?) {
    const params = {
      cidn: this.cidn,
      timeZone: this.userTimeZone,
      bulkRequestId: this.bulkRequestId,
      sysId: JSON.parse(this.sysID)
    }

    if (JSON.parse(this.sysID) === null) {
      delete params.sysId;
    }

    return new Promise((resolve, reject) => {
      this.bulkQuoteService.getBulkRequestDetails(params.cidn, params.timeZone, params.bulkRequestId, params.sysId).then((res) => {
        if (res && res.data) {
          this.orderDetailsData = res.data;

          this.setFormData(res.data);
          this.setDatePicker();
        }
        resolve(true);
      }).catch((err) => {
        //  console.log(err);
        reject(err);
      });
    });
  }



  saveBulkRequestDetails(action?) {
    this.getAdditonalExternalReferneceData();
    const formData = this.bulkRequestDetailsForm.getRawValue();
    const rdate = this.bulkRequestDetailsForm.get('custRequestedDate').value;
    const responseRequiredDate = rdate.year + '-' + ((rdate.month.toString()).length === 1 ? ('0' + rdate.month) : (rdate.month)) + '-' + ((rdate.day.toString()).length === 1 ? ('0' + rdate.day) : (rdate.day)) + 'T00:00:00Z'
    const today = new Date();
    let payload = {
      "bulkQuoteReferenceId": "",
      "action": "save",
      "status": "new",
      "cidn": this.cidn,
      "customerName": "",
      "requestorUser": "",
      "bulkQuoteName": formData.bulkQuoteName,
      "estimatedQuoteRequests": parseInt(formData.estimatedQuoteRequestsBulk),
      "responseRequiredDate": responseRequiredDate,
      "relatedBulkQuoteReferences": [formData.bulkQuoteReference],
      "businessDriver": formData.businessDriver,
      "bulkQuoteSummary": formData.bulkQuoteSummary,
      "submittedDate": new Date().toISOString(),
      "bulkExternalReference": this.AdditionalExternalReferenceList

      // "modifyObject": ["relatedBulkQuoteReferences"]
    }


    if (this.serviceType !== "RG") {
      delete payload.businessDriver;
      delete payload.bulkExternalReference;
    }


    if (this.bulkRequestId) {
      payload.bulkQuoteReferenceId = this.bulkRequestId
    }

    if (this.isModifyBulkQuote) {
      payload['modifiedDetails'] = ["relatedBulkQuoteReferences"]
    }

    if (!action) {
      this.isQuoteSavedStatus = true;
    }
    setTimeout(() => {
      this.isQuoteSavedStatus = false;
    }, 5000);


    if (action === 'tnc') {
    this.bulkPayload = payload;
    this.openTermsAndCondition();
    } else {
    // if(!this.isBulkRequestSaved){
    this.bulkQuoteService.saveBulkRequestsDetails(payload, this.isModifyBulkQuote).then((res) => {
      if (res.data) {
        const bulkRequestData = res.data
        this.bulkRequestId = bulkRequestData.bulkQuoteReferenceId;
        this.bulkRequestStatus = bulkRequestData.status;
        this.showBulkQuoteId = true;
        this.isBulkRequestSaved = true;

        if (action === 'next') {
          this.gotoBulkUploadPage(this.bulkRequestId);
        }
      }

    }).catch((err) => {
      this.showToasterMessage(this.aemLabelAuthoring?.errorMsg?.genericError, '', this.options, 'error');
    });
  }
    // }

    // if(this.isBulkRequestSaved){
    //   payload.bulkQuoteReferenceId = this.bulkRequestId;

    //   this.bulkQuoteService.patchBulkRequestsDetails(payload).then((res) => {
    //     if(res.data){
    //       const bulkRequestData = res.data
    //       this.bulkRequestId = bulkRequestData.bulkQuoteReferenceId;
    //       this.bulkRequestStatus = bulkRequestData.status;
    //       this.showBulkQuoteId = true;
    //       this.isBulkRequestSaved = true;

    //       if(action === 'next'){
    //         this.gotoBulkUploadPage(this.bulkRequestId);
    //       }

    //     }

    //   }).catch((err) => {
    //     this.showToasterMessage(this.aemLabelAuthoring?.errorMsg?.genericError, '', this.options, 'error');
    //   });
    // }

  }

  gotoBulkUploadPage(bulkRequestId) {
    let navigationExtras;
    if (this.isModifyBulkQuote) {
      navigationExtras = {
        state: {
          isModifyBulkQuote: true,
          holidayDates: this.holidayDates
        }
      }
    } else {
      navigationExtras = {
        state: {
          holidayDates: this.holidayDates
        }
      }
    }

    this.router.navigateByUrl(`/bulk-quote-upload/${bulkRequestId}`, navigationExtras);
  }



  getAdditonalExternalReferneceData() {
    const d = this.externalRefernces?.value.filter(l => (l.extReferenceType !== '' && l.extReferenceValue !== ''));

    if ((this.bulkRequestDetailsForm.value.extReferenceType1 !== '') && (this.bulkRequestDetailsForm.value.extReferenceValue1 !== '')) {
      const keyValueObject = { extReferenceType: this.bulkRequestDetailsForm.value.extReferenceType1, extReferenceValue: this.bulkRequestDetailsForm.value.extReferenceValue1 };

      if (d.length > 0) {
        this.AdditionalExternalReferenceList = [keyValueObject, ...d];
      } else {
        this.AdditionalExternalReferenceList = [keyValueObject]
      }
    } else {
      if (d.length > 0) {
        this.AdditionalExternalReferenceList = [...d];
      }
    }
  }


  onPasteCheck(event: ClipboardEvent): void {
    const d = event.clipboardData.getData('text/plain');
    if (!this.validateNumber(d)) {
      event.preventDefault();
    }
  }

  onPasteCheckNumber(event: ClipboardEvent): void {
    const d = event.clipboardData.getData('text/plain');
    if (this.validateNumber(d)) {
      event.preventDefault();
    }
  }

  validateNumber(value: string): boolean {
    return /^\d+$/.test(value);
  }

  ngOnDestroy(): void {
    if (this.customerChange) {
      this.customerChange.unsubscribe();
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyUp(event) {
    const activeElement = document.activeElement;
    const textareaElement = document.getElementById('bulkQuoteSummary')
    if (event.ctrlKey) {
      if (activeElement === textareaElement) {
        const computedStyle = window.getComputedStyle(textareaElement);
        const currentHeight = parseInt(computedStyle.getPropertyValue('height'), 10);
        const currentRows = parseInt(textareaElement.getAttribute('rows'), 10)
        if (event.key === 'ArrowUp' && currentRows > 1) {
          textareaElement.setAttribute('rows', `${currentRows - 1}`)
          textareaElement.style.height = `${currentHeight - 10}px`;
        } else if (event.key === 'ArrowDown') {
          textareaElement.setAttribute('rows', `${currentRows + 1}`)
          textareaElement.style.height = `${currentHeight + 10}px`;
        }
      }
    }
  }

  onQuoteSummaryKeyPress(event, val) {
    const element = document.getElementById('bulkQuoteSummary');
    if (event.key === " ") {
      const label = `${(val + 1)} out of 4000 characters`
      element.setAttribute('aria-label', label)
    }
  }

  onNumericKeyPressDown(event: KeyboardEvent) {
    if (event.key >= '0' && event.key <= '9') {
      event.preventDefault();
    }
  }
  getDetails() {
    this.bulkQuoteService.getBulkRequestDetails(this.cidn, this.userTimeZone, this.bulkQuoteID, this.sysID,).then((res) => {
      this.product = res?.data?.productName;
      this.serviceType = res?.data?.serviceType;
    })
  }

  openTermsAndCondition() {
    this.dataSvc.getTandCforOrder(this.cidn, this.product).then(res => {
      this.isTermsCondition = true;
      this.tAndcText = res;
    })
  }
  onClickClose(event) {
    this.isTermsCondition = false;
  }

}
