import { throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
// import { CreateCustomerData } from './customer.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'tw-core-ui';
import { DatePipe } from '@angular/common';
// import { GridData } from '../../../shared/models/common-model';


export interface GridHeaderInterface {
  headers: any;
}

export interface GridDataInterface {
  data: any;
}



@Injectable()
export class CustomerService {
  spinnerflag = false;
  // private sendSpinnerFlag = new BehaviorSubject(this.spinnerflag);
  // spinnerCurrentStatus = this.sendSpinnerFlag.asObservable();
  createCustomerData: any;
  public gridHeaderDataObj: any = [];
  public gridDataObj: any = [];
  public fetchCIDN;
  private env: any = window['environment'];
  public createUserLabelAuthoringDataObj: any;
  public modifyUserLabelAuthoringDataObj: any;
  public gridLabelAuthoringDataObj: any;
  public createCustomerFormData: any;

  private customerUri = {
    customer: 'users/customers',
    getCreateCustomerModel: 'createCustomer',
    customerGridHeader: 'customer-grid-header',
    customerGridLabel: 'customer-authoring-data',
    customerGridData: 'customers',
    customerCIDN: 'users/customers/all',
    createCustomer: 'users/customers',
    modifyCustomer: 'users/customers',
    mockCustomerList: 'mock/customer-list.json'
  };
  private modifyCustomerUri = {
    getModifyCreateCustomer: 'modifyCustomer',
    modifyCustomer: 'users/customer'
  };
  constructor(private http: HttpClient, private authService: AuthService
  ) { }

  public setCreateCustomerData(data) {
    this.createCustomerData = data;
  }

  public getCreateCustomerData() {
    return this.createCustomerData;
  }

  public createCustomer(params, cidns) {
    const payLoad = {
      "customerName": params.customerName.trim(),
      "customerType": params.customerType,
      "customerChannel": params.customerChannel,
      "primaryCidn": params.primaryCIDN.trim(),
      "status": "Active",
      "linkedCidn": cidns.toString(),
      "contactDetails": [
        {
          "contactType": params.contactType,
          "contactName": params.contactName,
          "contactNumber": params.contactNumber,
          "contactEmail": params.contactEmail
        }
      ]
    };
    if (params.contactTypeSec && params.contactNameSec && params.contactNumberSec && params.contactEmailSec) {
      payLoad.contactDetails.push({
        contactType: params.contactTypeSec,
        contactName: params.contactNameSec,
        contactNumber: params.contactNumberSec,
        contactEmail: params.contactEmailSec,
      });
    }

    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(this.env.apiPoint + this.customerUri.createCustomer, payLoad, httpOptions)
        .subscribe(res => {
          resolve(res);
          // this.loader.setLoader(false);
        }, err => {
          // this.loader.setLoader(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getCustomerListModel() {
    return new Promise((resolve, reject) => {
      if (!this.gridLabelAuthoringDataObj) {
        const worklistUrl = this.env.aemEndPoint + this.customerUri.customerGridLabel;
        this.http.get<any>(worklistUrl)
          .subscribe(
            res => {
              this.gridLabelAuthoringDataObj = res.data.content;
              resolve(this.gridLabelAuthoringDataObj);
            },
            err => {
              this.gridLabelAuthoringDataObj = null;
              reject(err);
              return observableThrowError(err || 'Server error');
            }
          );
      } else {
        resolve(this.gridLabelAuthoringDataObj);
      }
    });
  }

  public getGridHeader() {
    return new Promise((resolve, reject) => {
      this.http.get<GridHeaderInterface>(this.env.aemEndPoint + this.customerUri.customerGridHeader)
        .subscribe(res => {
          this.gridHeaderDataObj = res;
          resolve(true);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getGridData(params?) {
    return new Promise<any>((resolve, reject) => {
      const tokenValue = this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : false;
      let httpOptions;
      if (tokenValue) {
        httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization': tokenValue
          })
        };
      } else {
        httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': '*/*'
          })
        };
      }
      const url = this.env.apiPoint + this.customerUri.customer;
      // uncomment to run in local
      //  const url = this.env.aemEndPoint + this.customerUri.mockCustomerList;
      this.http.get<any>(url, httpOptions)
        .subscribe(res => {
          this.gridDataObj = res['data']['customerList'];
          resolve(true);
        }, err => {
          this.gridDataObj = [];
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getCustomerData(val) {
    return new Promise<any>((resolve, reject) => {
      resolve(true);
    });
  }

  public getCreateMyUserModel() {
    return new Promise((resolve, reject) => {
      if (!this.createUserLabelAuthoringDataObj) {
        // this.loader.setLoader(true);
        const CREATE_CUSTOMER_URL = this.env.aemEndPoint + this.customerUri.getCreateCustomerModel;
        this.http.get<any>(CREATE_CUSTOMER_URL)
          .subscribe(
            res => {
              this.createUserLabelAuthoringDataObj = res.data.content;
              resolve(this.createUserLabelAuthoringDataObj);
            },
            err => {
              // this.loader.setLoader(false);
              this.createUserLabelAuthoringDataObj = null;
              reject(err);
              return observableThrowError(err || 'Server error');
            }
          );
      } else {
        // this.loader.setLoader(false);
        resolve(this.createUserLabelAuthoringDataObj);
      }
    });
  }

  public modifyCustomer(params, cidns, isSecondaryContact) {
    // this.loader.setLoader(true);
    let contactDetail;
    if (isSecondaryContact) {
      contactDetail = [
        {
          "contactType": params.contactType,
          "contactName": params.contactName,
          "contactNumber": params.contactNumber,
          "contactEmail": params.contactEmail
        },
        {
          "contactType": params.contactTypeSec,
          "contactName": params.contactNameSec,
          "contactNumber": params.contactNumberSec,
          "contactEmail": params.contactEmailSec
        }
      ]
    } else {
      contactDetail = [
        {
          "contactType": params.contactType,
          "contactName": params.contactName,
          "contactNumber": params.contactNumber,
          "contactEmail": params.contactEmail
        }
      ]
    }
    const payLoad = {
      "customerName": params.customerName,
      "customerType": params.customerType,
      "customerChannel": params.customerChannel,
      "primaryCidn": params.primaryCIDN,
      "status": params.status,
      "linkedCidn": cidns.toString(),
      "contactDetails": contactDetail
    };
    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    return new Promise<any>((resolve, reject) => {
      this.http.put<any>(this.env.apiPoint + this.customerUri.modifyCustomer + '/' + params.primaryCIDN.trim(), payLoad, httpOptions)
        .subscribe(res => {
          resolve(res);
          // this.loader.setLoader(false);
        }, err => {
          // this.loader.setLoader(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  getLinkedCIDNs() {
    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    let url = this.env.apiPoint + this.customerUri.customerCIDN;
    //to run in local
   // let url = this.env.aemEndPoint + 'mock/customer.json';
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(url, httpOptions)
        .subscribe(res => {
          resolve(res);
          // this.loader.setLoader(false);
        }, err => {
          // this.loader.setLoader(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getLinkedCIDN() {
    return this.getLinkedCIDNs;
  }
}