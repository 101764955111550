import { Component } from '@angular/core';

@Component({
  selector: 'app-btn-cell-renderer',
  templateUrl: './btn-cell-renderer.component.html',
  styleUrls: ['./btn-cell-renderer.component.less']
})
export class BtnCellRendererComponent {

  private params: any;

  constructor() { }

  ngOnInit(): void {
  }

  agInit(params: any): void {
    this.params = params;
  }

  public remove() {
    this.params.clicked(this.params.data)
  }

}
