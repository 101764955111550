import { Component, OnInit } from '@angular/core';
import { ErrorService } from '../shared/services/error.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.less']
})
export class ErrorComponent implements OnInit {

  public errorDetailsObj: Object = {};
  public errorHeader: String;
  public errorText: String;
  public errorSubText: String;
  constructor(private errorService: ErrorService) {
    errorService.getErrorDetails().then((res) => {
      this.errorDetailsObj = res;
      this.errorText = this.errorDetailsObj['errorText'];
      this.errorHeader = this.errorDetailsObj['errorHeader'];
      this.errorSubText = this.errorDetailsObj['errorSubText'];
    })
    .catch((err) => {
    });
   }

  ngOnInit() {
  }

}
