  <ng-template #content let-modal role="dialog" aria-modal="true">
      <section class="head" aria-modal="true">
        <h1 class="audit-log" id="audit-log-title">Audit log</h1>
        <button class="cancelBtn close" (click)="close()" aria-label="Close"><i class="icon-icon-ui-cross"></i></button>
      </section>
      <div class="modal-body">
        <div class="row">
          <div class="col-3 display-flex">
            <div> Created date </div>
            <div class="value"> {{auditData.createdDate}} </div>
          </div>
          <div class="col-3 display-flex">
            <div> Created by </div>
            <div class="value"> {{auditData.createdBy}} </div>
          </div>
          <br>
        </div>
        <table class="scrolldown" *ngIf="rowData.length > 0">
          <thead>
            <tr>
              <th *ngFor="let col of profileTableColumn">
                {{col}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="audit-text" *ngFor="let row of rowData">
              <td>{{row.lmDate}}</td>
              <td>{{row.lmBy}}</td>
              <!-- <td>{{row.lmDetails}}</td> -->
              <td class="text-wrap" [innerHtml]="row.lmDetails"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-template>