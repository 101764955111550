import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CommonService } from '../shared/services/common.service';
import { CustomerService } from './customer-list/customer.service';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.css']
})
export class AdminSettingsComponent implements OnInit {

  selectedIndex = 0;
  createUserFlag = false;
  public modifyUserFlag: boolean = false;
  infraCoCIDN = window['environment'].InfraCoCIDN;
  createCustFlag: boolean = false;
  modifyCustFlag: boolean = false;
  showCustomercreate: boolean = false;
  showCustomerListTab: boolean = false;
  roleAccess: string;
  roleFunction: string;
  customerList: any;
  cidnSelected: any;
  showUserListTab: boolean = false;
  public businessList = [];
  public isInfracoNbn: boolean = false;
  routeSubscription: Subscription;
  constructor(private router: Router, private commonService: CommonService,
    private customerService: CustomerService, private titleService: Title,
    private route: ActivatedRoute
  ) {

    this.customerService.getLinkedCIDNs().then(res => {
      if (res) {
        const iteratedArr = res.data.businessNameDetails.map(({
          bussinessName,
          cidn
        }) => [bussinessName, cidn]);
        for (var i = 0; i < iteratedArr.length; i++) {
          const businessName = iteratedArr[i][0];
          const cidn = iteratedArr[i][1];
          if (businessName || cidn) {
            this.businessList.push({
              businessName: businessName,
              cidn: cidn
            });
          }
        }
      }
    }).catch((err) => {
    });
    if (this.commonService.getDefaultCustomer()) {
      this.commonService.getDefaultCustomer().pipe().subscribe(resp => {
        this.businessList.forEach(item => {
          if (item.businessName === resp) {
            this.cidnSelected = item.cidn;
            if (this.cidnSelected === this.infraCoCIDN) {
              const role = 'Administrator';
              const function1 = 'Customer Management';
              const filteredArray = this.customerList.some(obj => obj.role === role && obj.function === function1);
              if (filteredArray) {
                this.showCustomerListTab = true;
              }
              else {
                this.showCustomerListTab = false;
              }
            }
            else {
              this.showCustomerListTab = false;
            }
          }
        })
      })
    }
    this.getRoleDetails();
    this.titleService.setTitle('InfraCo Customer Portal - Admin settings');

    if (this.isInfracoNbn) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.showUsers();
        }
      });
    }
    this.routeSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const id = this.route.snapshot.paramMap.get('id')
        if (id === 'createUser') {
          this.createUserFlag = true;
          this.modifyUserFlag = false;
          this.selectedIndex = 1;
        } else if (id === 'myUser') {
          this.createUserFlag = false;
          this.modifyUserFlag = false;
          this.selectedIndex = 1;
        }
      }
    });
  }

  ngOnInit() {
    this.checkIsUserAuthorized();
    // this.isShowCreateUser();
  }

  checkIsUserAuthorized() {
    const roles = (this.commonService.getroleDetails()).customerList;
    const isNBNUser = roles.some(obj => (obj.role === 'NBN Read & Write') || (obj.role === 'NBN Read Only'))
    if (this.cidnSelected !== this.infraCoCIDN && isNBNUser) {
      this.router.navigateByUrl('/documents')
    }
  }

  isShowCreateUser() {
    this.commonService.showCreateUserFromMenu.subscribe(val => {
      if (val) {
        this.showUserListTab = true;
        this.createUserFlag = true;
      } else {
        this.createUserFlag = false;
      }
    })
  }

  getRoleDetails() {
    this.cidnSelected = this.commonService.selectedCidn;
    this.customerList = (this.commonService.getroleDetails()).customerList;
    const nbn = this.customerList.some((obj) => obj.role.includes('NBN') || obj.function.includes('NBN'));
    this.isInfracoNbn = nbn ? true : false;
    if (this.cidnSelected === this.infraCoCIDN) {
      const role = 'Administrator';
      const function1 = 'Customer Management';
      const filteredArray = this.customerList.some(obj => obj.role === role && obj.function === function1);
      if (filteredArray) {
        this.showCustomerListTab = true;
      }
      else {
        this.showCustomerListTab = false;
      }
    }
    else {
      this.showCustomerListTab = false;
    }
    const role = 'Administrator';
    const function2 = 'User Management';
    const filteredArrayUser = this.customerList.some(obj => obj.role === role && obj.function === function2);
    const nbnUser = this.customerList.some(obj => obj.role === 'NBN Administrator' && obj.function === 'NBN User Management');
    const ductAdmin = this.customerList.some(obj => obj.role === 'Duct Administrator' && obj.function === 'Duct User Management');
    if (filteredArrayUser || nbnUser || ductAdmin) {
      this.showUserListTab = true;
    }
    else {
      this.showUserListTab = false;
    }
  }

  public showUsers = () => {
    // this.router.navigate(['/admin', 'myUser']);
    this.selectedIndex = 1;
    this.createUserFlag = false;
    this.modifyUserFlag = false;
  }

  public createUserOpen = (value: string) => {
    this.createUserFlag = !this.createUserFlag;
    this.modifyUserFlag = false;
  }

  public showCustomerList = (): void => {
    this.router.navigate(['/admin','']);
    this.selectedIndex = 0;
    this.createCustFlag = false;
    this.modifyCustFlag = false;
  }

  public openCreateCustomer = (): void => {
    this.createCustFlag = !this.createCustFlag;
    this.modifyCustFlag = false;
  }

  public openModifyCustomer = (): void => {
    this.createCustFlag = false;
    this.modifyCustFlag = true;
  }

  public selectTab = (tabName: string): void => {
    if (tabName === 'user') {
      this.showUsers();
    } else if (tabName === 'modifyuser') {
      this.createUserFlag = false;
      this.modifyUserFlag = true;
    } else this.showCustomerList();
  }

  public createCustomerEvent = (value: string) => {
    this.createCustFlag = !this.createCustFlag;
    this.modifyCustFlag = false;
  }

  public modifyCustomerEvent = (value: string) => {
    this.createCustFlag = false;
    this.modifyCustFlag = true;
  }

  public createUserEvent = (value: string) => {
    this.createUserFlag = true;
    this.selectedIndex = 1;
    this.router.navigate(['/admin', 'createUser']);
  }

  public modifyUserEvent = (value: string) => {
    this.createUserFlag = false;
    this.modifyUserFlag = true;
  }
  tabChange($event) {
    if($event.index === 1){
      this.router.navigate(['/admin', 'myUser']);
    } else {
      this.router.navigate(['/admin', '']);
    }
  }
  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }
}
