export class CustomNameField {
    'automationTypeLabel': string = 'label';
    'automationIdLabel': string;
    'automationTypeTextField': string = 'textbox';
    'automationIdTextField': string;
    'automationTypeRequired': string;
    'automationIdRequired': string;
    'automationTypeValidationError': string = 'dev';
    'automationIdValidationError': string = 'divCheckServiceValidationErrMsg';
    'placeholderCaption': string;
    'id': string;
    'tabindex': string = '0';
    'maxlength': string = '100';
    'headerText': string;
    'headerTextAccessible': string;
    'inputText': string;
    'requiredText': string;
    'validationMessage': string;
    'pattern': string;
    'isMandatory': boolean;
    'isKeyDownDefaultValidation': boolean = false;
    'name': string;
    'formControlName': string;
    'isDisabled': boolean = false;
    'showValidationIcon': boolean = false;
}
