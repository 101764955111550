import { Component } from '@angular/core';

@Component({
  selector: 'app-error-renderer',
  templateUrl: './error-renderer.component.html',
  styleUrls: ['./error-renderer.component.less']
})
export class ErrorRendererComponent {
  bulkQuoteError = '';
  agInit(params: any) {
      if(!params.value) {
        this.bulkQuoteError = 'Error';
      }
  }

}
