import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { throwError as observableThrowError } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class MapsService {

  public items: any;
  private gmapsApiKey: string;
  private env: any = window['environment'];
  private gmapsApiKeyUrl = 'infraco-addresses/google-map-id';
  private searchByAddressApiUrl = 'https://maps.googleapis.com/maps/api/geocode/json';

  constructor(private http: HttpClient, @Inject(DOCUMENT) private document: Document) {}

  getItems() {
    return this.items;
  }

  storeItems(item: any) {
    this.items = item;
  }

  searchByAddress(address: string) {
    const params = new HttpParams()
      .set('address', address)
      .set('key', this.gmapsApiKey);

    return this.http.get(this.searchByAddressApiUrl, { params });
  }

  public fetchGoogleMapsApiKey() {
    const url = this.env.apiPoint + this.gmapsApiKeyUrl;
    return new Promise<any>((resolve, reject) => {
      this.http.get<{ apiKey: string }>(url)
        .subscribe((res: any) => {
          if(res && res?.data && res?.data?.googleMapKey) {
            this.gmapsApiKey = atob(res?.data?.googleMapKey) || ''; // To Decrypt base64
            this.loadJsScript(this.gmapsApiKey);
          }
          resolve(res);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Error fetching Google Maps API key');
        });
    });
  }

  public loadJsScript(key: string): void {
    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.src = "https://maps.googleapis.com/maps/api/js?key=" + key;
    script.async = true;
    script.defer = true;
    this.document.body.appendChild(script);
  }
}
