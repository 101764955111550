import { Component, OnInit, Input, OnChanges, ViewChild, HostListener, Inject, LOCALE_ID, Output, EventEmitter } from '@angular/core';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';
import { Notification } from './notification.interface';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.less']
})
export class NotificationComponent implements OnInit {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;


  @Input() cdkOrigin: any;
  @Input() notificationParams: any;
  // @Output() isIconDisabled : EventEmitter<boolean> = new EventEmitter();


  isPopOpen: boolean;
  public options: GlobalConfig;
  notificationData: any;
  userTimeZone: string;
  isDataLoaded: boolean = true;

  constructor(private toastr: ToastrService,
    private notificationService: NotificationService, @Inject(LOCALE_ID) private locale: string,
    private router: Router) {
    this.options = this.toastr.toastrConfig;
  }

  ngOnInit(): void {
    this.getUserTimeZone();
  }

  ngOnChanges() {
    this.isPopOpen = this.notificationParams.isNotificationOpen;
    let cidn = localStorage.getItem('selectedHeaderCIDN');
    //cidn = "9999999998";

    if (this.isPopOpen) {
      this.notificationData = null;
      this.isDataLoaded = false;

      this.notificationService.getNotifications(cidn, this.userTimeZone).subscribe((res: any) => {
        this.notificationData = res;
        //this.notificationData.length = 0;
      }, (err: any) => {
        const msg = 'An error has occured, please try again later.';
        this.showToasterMessage(msg, '', this.options, 'error');
        this.closeNotification();
      })
        .add(() => {
          this.notificationParams.isNotificationIconDisabled = false;
          this.isDataLoaded = true;
          const popUp = document.getElementById('cdk-overlay-0');
          if (popUp && popUp.style.top.toString() === '44.5px') {
            (<HTMLElement>document.getElementsByClassName('notification-container')[0]).classList.add('notification-container-alt');
          } else {
            (<HTMLElement>document.getElementsByClassName('notification-container')[0]).classList.remove('notification-container-alt');
          }
          //this.isIconDisabled.emit(this.isNotificationIconDisabled);
        });
    }

  }

  closeNotification() {
    this.isPopOpen = false;
    this.notificationParams.isNotificationOpen = false;
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-top-center';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 5000;
    this.options.closeButton = false;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
  }

  // /**
  //  * 
  //  * @param event for escape key
  //  */
  // // @HostListener('document:keydown.escape', ['$event'])
  // // handleEscapeKey(event: KeyboardEvent) {
  // //   this.closeNotification();
  // // }

  getUserTimeZone() {
    const userTimeZone = Intl.DateTimeFormat(this.locale, {
      timeZoneName: 'long'
    }).resolvedOptions().timeZone;

    this.userTimeZone = userTimeZone;
  }

  navigateRoute(notification: Notification) {
    this.notificationService.saveSnowID(notification.externalReferenceId);
    if (notification.requestType === 'Order') {
      this.router.navigate([`orderSummary/${notification.orderConvertedFrom}/${notification.requestReferenceId}`]);
    } else if (notification.requestType === 'Quote') {
      this.router.navigate([`quoteSummary/${notification.requestReferenceId}`]);
    }
    this.closeNotification();
  }
}
