<div  class="nbn-dropdown-menu-container" id="myDiv"  (mouseleave)="mouseLeaveEvent()">
  <div class="drpdwn-container" aria-expanded="true">
    <button  class="btn" aria-expanded="true" aria-haspopup="true">
      <ul class="drpdwn-menu">
        <li tabindex="0" aria-label={{menu.title}} (focusout)="collapse(menu.title)" (keypress)="navigate(menu.title)"
          class="drpdwn-menu-list menu-list-text"
          *ngFor="let menu of mainMenu; let i = index"
          [ngClass]="{ 'active-menu': selectedIndex === i }"
          (mouseover)="showSubMenu(menu.title, i)"
          (focus)="showSubMenu(menu.title, i)"
          (keydown)="takeme($event)"
          routerLink="{{menu.url}}"
          (click)="closeMenu(menu.title)"
          (keypress)="closeMenu(menu.title)">
          {{ menu.title }}
        </li>
      </ul>
    </button>
    <div *ngIf="isSubMenuOpen">
      <div class="drpdwn-sub-menu menu-list-text row"  role="menu">
        <span class="sub-menu-list" aria-label={{menu.title}} role="menuitem" tabindex="0" 
        *ngFor="let menu of subMenu; let i=index" (keypress)="navigate1(menu.title)" 
        [routerLink]="[menu.url, menu.id]" tabindex="0" (focusout)="check(menu.title)" 
        (keypress)="closeMenu(menu.title)" (click)="closeMenu(menu.title)">
          <span>{{
          menu.title
        }}</span>
      </span>
      </div>
    </div>
  </div>
</div>
