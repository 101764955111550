<div class="infraco-document-list-action-render">
  <span class="icon-view status-background-icon" (click)="viewDocument()">
    <img tabindex="0" src="../../../../assets/images/icon-view.png" alt="view {{params.data.documentTitle}}"/>
    <span class="tooltiptext">View</span>
  </span>
  <span class="icon-download status-background-icon" (click)="download()">
    <img tabindex="0" src="../../../../assets/images/icon-download.svg" alt="download {{params.data.documentTitle}}"/>
    <span class="tooltiptext">Download</span>
  </span>
  <span class="icon-copy status-background-icon" (click)="copyDocument()" (mouseover)="showTooltip()">
    <img tabindex="0" src="../../../../assets/images/icon-copy.svg" alt="copy {{params.data.documentTitle}}"/>
    <span class="tooltiptext copy-icon-tooltip"
    [ngClass]="{'link-copied-tooltip': copyToltip==='Link copied'}"
    >{{copyToltip}}</span>
  </span>
  <span *ngIf="showDeleteButton || cabinetRole == 'nbnreadandwrite'" class="icon-delete status-background-icon"
    (click)="deleteDocument()"><img tabindex="0" src="../../../../assets/images/icon-delete.svg" alt="delete {{params.data.documentTitle}}"/>
    <span class="tooltiptext">Remove</span>
  </span>
</div>