import { Router } from '@angular/router';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { GridOptions } from 'ag-grid-community';

import * as moment_ from 'moment';

declare const window;
declare var $: any;
import { saveAs as importedSaveAs } from "file-saver";
import { CustomAgGrid } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { DashboardService } from 'src/app/cabinets/dashboard.service';
import { CheckboxFilterComponent } from 'src/app/grid-filter/checkbox-filter/checkbox-filter.component';
import { CustomTextFilterComponent } from 'src/app/grid-filter/custom-text-filter/custom-text-filter.component';
import { CustomDateFilterComponent } from 'src/app/grid-filter/custom-date-filter/custom-date-filter.component';
import { AuthService } from 'tw-core-ui';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.less'],
  providers: [NgbCarouselConfig]
})
export class NbnUserLandingPageComponent implements OnInit {
  public aemLabelAuthoring: any;
  private columnDefs: any[];
  moment = moment_;
  rowData: any = [];
  viewMore: boolean = true;
  public gridData: CustomAgGrid = {
    gridOptions: {},
    keysToSuppress: [13],
    labelAuthoring: {
      headerPageCount: '',
      filter: '',
      resetFilter: '',
      paginationHeader: '',
      noData: '',
      noDataOnFilter: '',
      noDataOnSearch: '',
      toTop: ''
    }
  };
  gridApi: any;
  gridConfig: GridOptions;
  gridInitialized: boolean;
  documentData: any;
  profile: { contactName: any };

  constructor(private router: Router,
    private dashboardService: DashboardService,
    private authService: AuthService,
    private commonservice:CommonService
  ) {
    this.profile = {
      'contactName': this.authService.contactName() ? this.authService.contactName() : window['environment']['contactNameUI'],
    }
  }

  ngOnInit() {
    this.getLabels();
    const roles = this.commonservice.getroleDetails().customerList;
    if (!roles.some((obj) => obj.role.includes('NBN') || obj.function.includes('NBN'))) {
      this.router.navigateByUrl('/access-denied');
    }
    this.commonservice.showTopButtonInFooter(true);
  }

  getLabels() {
    return new Promise((resolve, reject) => {
      this.dashboardService.getDocumentModel().then(() => {
        this.aemLabelAuthoring = { ...this.dashboardService.gridLabelAuthoringDataObj };
        this.gridData.labelAuthoring = {...this.aemLabelAuthoring.form.grid};
        this.createColumnDefs();
        resolve(true);
      }).catch(() => {
        reject(false);
      });
    });
  }

  createColumnDefs() {
    return new Promise((resolve, reject) => {
      this.dashboardService.getGridHeader().then(() => {
        this.columnDefs = this.dashboardService.documentsGridHeader.headers;
        if (this.columnDefs) {
          this.createRowData();
        }
        this.columnDefs.forEach((x: any) => {
          if (x.cellRendererFramework) {
            if (x.colId == 'documents-grid-actionItems') {
              x.cellRendererParams.clicked = function (field: any, type) {
                switch (type) {
                  case 'view':
                    this.viewDocument(field);
                    break;
                  case 'download':
                    this.download(field);
                    break;
                }
              }.bind(this);
            } else if (x.colId == 'documents-grid-Documenttitle') {
              x.cellRendererParams.clicked = function (field: any) {
                this.download(field);
              }.bind(this);
            }
          }
        });
        resolve(true);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  focusOnTop(event) {
    if (event) {
      let focusableElement;
      focusableElement = document.getElementById('welco');
      if(focusableElement){
      focusableElement.setAttribute('tabindex', '0');
      focusableElement.addEventListener('blur', () => {
        focusableElement.removeAttribute('tabindex');
      });
      setTimeout(() => {
        if (focusableElement) {
          focusableElement.focus();
        }
      }, 10);
    } 
  }
}

createRowData() {
  return new Promise((resolve, reject) => {
    this.dashboardService.getDocumentGridData().then(() => {
      this.documentData = this.dashboardService.documentGridData;
      if (this.documentData && this.documentData.length > 0) {
        let rowdata = this.documentData;
        rowdata.sort(function (a, b) {
          return +new Date(b.pubDate) - +new Date(a.pubDate);
        });
        rowdata.forEach(element => {
          element.pubDate = this.moment(element.pubDate).format('DD MMM YYYY HH:mm')
          if (this.rowData.length <= 4) {
            this.rowData.push(element);
          }
        });
      } else {
        this.rowData = [];
        this.gridData.labelAuthoring.noDataOnSearch = this.aemLabelAuthoring.form.grid.noData;
      }
      this.createGridConfig();
      resolve(true);
    }).catch((err) => {
      reject(false);
    });
  });
}

  createGridConfig() {
    this.gridConfig = <GridOptions>{};
    this.gridConfig.paginationPageSize = 5;
    this.gridConfig.columnDefs = this.columnDefs;
    this.gridConfig.rowData = this.rowData;
    this.gridConfig.suppressHorizontalScroll = true;
    this.gridConfig.pagination = false;
    this.gridConfig.suppressRowHoverHighlight = true;
    this.gridConfig.suppressRowClickSelection = true;
    this.gridConfig.rowSelection = 'single';
    this.gridConfig.enableFillHandle = false;
    this.gridConfig.pagination = false;
    this.gridInitialized = true;
    this.gridConfig.frameworkComponents = {
      CheckboxFilterComponent: <any>CheckboxFilterComponent,
      customTextFilter: <any>CustomTextFilterComponent,
      CustomDateFilterComponent: <any>CustomDateFilterComponent
    };
    this.gridData.gridOptions = this.gridConfig;
  }

  viewDocument(documentDetails) {
    const filename = (documentDetails) ? documentDetails.fileName : '';
    const modFilename = this.encodeURIComponent(filename);
    const modCid = this.encodeURIComponent(documentDetails.cabinetId);
    const serailizeUrl = this.router.serializeUrl(this.router.createUrlTree([`document/${modCid}/${modFilename}`]));
    window.open(serailizeUrl, '_blank');
  }

  download(documentDetails) {
    const filename = (documentDetails) ? documentDetails.fileName : '';
    const name = documentDetails.cabinetId + '_' + filename;
    this.dashboardService.downloadFile(name).then(async res => {
      if (res) {
        if (res['body'].type === "application/json") {
          let content = JSON.parse(await res['body'].text());
        }
        importedSaveAs(res['body'], filename);
      }
    }).catch((err) => {
      if (err.status === 401) {
        this.router.navigateByUrl('/access-denied');
      }
    });
  }

  gridReady(param) {
    this.gridApi = param.value.event.api;
  }

  viewMoreTests() {
    this.dashboardService.updateDocumentGridData(this.documentData);
    this.router.navigateByUrl('/document');
  }

  encodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
  }

}


