import { Component, AfterViewInit, Input, ElementRef, ViewChild } from '@angular/core';
import { MapInfoWindow, MapMarker, GoogleMap, } from '@angular/google-maps';
import { Observable } from 'rxjs';
import { MapsService } from 'src/app/maps.service';


@Component({
  selector: 'app-map-item',
  templateUrl: './map-item.component.html',
  styleUrls: ['./map-item.component.less']
})
export class MapItemComponent implements AfterViewInit {
  @Input() LatLng;
  @Input() type;
  markerClustererImagePath = '../../../../assets/images/logo.png';

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;

  zoom = 20;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    mapTypeId: 'terrain',
    maxZoom: 15,
    minZoom: 8,
    disableDefaultUI: true, // Disable default map controls
    gestureHandling: 'none', // Disable gesture handling
    draggable: false, // Disable map dragging
    clickableIcons: false,

  };
  markers: any[] = [];
  infoContent = '';

  //   latitude
  // : 
  // "-34:41:00.2"
  // longitude
  // : 
  // "150:51:02.6"

  constructor(private elementRef: ElementRef, private mapsService: MapsService) { }

  ngAfterViewInit(): void {
    this.mapsService.searchByAddress(this.LatLng.address).subscribe((response: any) => {
      const location = response.results[0]?.geometry?.location;
      this.center = {
        lat: location.lat,
        lng: location.lng
      };

      this.markers.push({
        position: this.center,
        title: '',
        info: '',
        options: {
          icon: {
            url: this.getIcon(),
            scaledSize: new google.maps.Size(30, 40), // scaled size

          },
        },
      });
    })
  }

  getIcon() {
    if (this.type == 'A')
      return '../../../../assets/images/Map-pointer-A.png'
    else
      return '../../../../assets/images/Map-pointer-Z.png'
  }
}
