import { Component, Renderer2, ViewChild } from '@angular/core';
import { NgbCarouselConfig, NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { Image } from '../shared/model/general.model';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css'],
  providers: [NgbCarouselConfig]

})
export class BannerComponent {
  @ViewChild('carousel', { static: false }) carousel!: NgbCarousel;
  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  showNavigationArrows = true;
  showNavigationIndicators = true;
  images:Image[]=[];
  updatedUrl: any;
  imageUrl: any;
  slideValue: any;
  contactName: string = sessionStorage.getItem('contactName') ? sessionStorage.getItem('contactName').split(' ')[0] : '';

  constructor(private config: NgbCarouselConfig, private renderer: Renderer2) {
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
    this.images =[
      {imgLink:'../../../../assets/images/WelcomeBanner1.jpg',url:'../../../../assets/images/WelcomeBanner1.jpg',imgAltText:'banner-image1'},
      {imgLink:'../../../../assets/images/WelcomeBanner2.jpg',url:'../../../../assets/images/WelcomeBanner2.jpg',imgAltText:'banner-image2'},
      {imgLink:'../../../../assets/images/WelcomeBanner3.jpg',url:'../../../../assets/images/WelcomeBanner3.jpg',imgAltText:'banner-image3'},
      {imgLink:'../../../../assets/images/WelcomeBanner5.jpg',url:'../../../../assets/images/WelcomeBanner5.jpg',imgAltText:'banner-image4'},
      {imgLink:'../../../../assets/images/WelcomeBanner4.jpg',url:'../../../../assets/images/WelcomeBanner4.jpg',imgAltText:'banner-image5'},
    ]
  }

  ngAfterViewInit() {
    let checkExistCarousel = setInterval(function () {
      const divs = document.querySelectorAll('.carousel.slide.custom-slider');
      if (divs.length && divs.length > 0) {
        for (let i = 0; i < divs.length; i++) {
          divs[i].setAttribute('tabindex', '-1');
        }
        clearInterval(checkExistCarousel);
      }
    }, 100);
    const prevBtn = document.querySelector('.carousel-control-prev') as HTMLElement;
    const nextBtn = document.querySelector('.carousel-control-next') as HTMLElement;

    this.renderer.setAttribute(prevBtn, 'tabindex', '0');
    this.renderer.setAttribute(nextBtn, 'tabindex', '0');

    const prevElement = document.getElementsByClassName('carousel-control-prev');
    const nextElement = document.getElementsByClassName('carousel-control-next');
    window.onkeyup = function (event) {
      if (event.keyCode === 9) {
      for(let i=0; i< prevElement.length; i++) {
        const inputElement = prevElement[i];
        inputElement.classList.remove("focused")
      }
      for(let i=0; i< nextElement.length; i++) {
        const inputElement = nextElement[i];
        inputElement.classList.remove("focused")
      }
      const activeElement = document.activeElement;
      if(activeElement && (activeElement.classList.contains('carousel-control-prev')) || activeElement.classList.contains('carousel-control-next')) {
        activeElement.classList.add("focused")
      }  
    }
    }
  }

  NaviagteUrl(updatedUrl:any, value: any) {
    this.imageUrl = updatedUrl;
    this.slideValue = document.getElementById("currentSlide");
  }

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: any) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

}
