<div class="containerDiv">
  <img class="adminLogo" src="../../assets/images/icon-button-settings.svg" alt="admin logo" />
  <h1 style="margin-bottom: 0;" role="main" id="adminBtn" class="header" aria-label="Admin settings page">Admin settings</h1>
   <!-- <div aria-live="assertive" class="visually-hidden">
    <span role="presentation" tabindex="0" style="position:absolute;left:-9999px">Admin Settings screen</span>
  </div> -->
  <mat-tab-group [disableRipple]="true" [(selectedIndex)]=selectedIndex id="qa-banner" (selectedTabChange)="tabChange($event)">
    <ng-container *ngIf="!isInfracoNbn">
      <mat-tab  *ngIf="showCustomerListTab" label="Customer" class="tab conatctDetails" (click)="showCustomerList()">
        <ng-template matTabContent>
          <div id="qa-banner-cust-list">
            <app-customer-list (createCustomerEvent)="createCustomerEvent($event)"
              (modifyCustomerEvent)="modifyCustomerEvent($event)" [selectedIndex]="selectedIndex"
              *ngIf="!createCustFlag && !modifyCustFlag"></app-customer-list>
            <app-create-customer *ngIf="createCustFlag && !modifyCustFlag"
              (moveTab)="selectTab('customer')"></app-create-customer>
            <app-modify-customer *ngIf="modifyCustFlag" (moveTab)="selectTab('customer')"></app-modify-customer>
          </div>
        </ng-template>
      </mat-tab>
    </ng-container>
    <mat-tab *ngIf="showUserListTab" label="User" class="conatctDetails" (click)="showUsers()">
      <ng-template matTabContent>
        <app-user-list (createUserEvent)="createUserEvent($event)" (modifyUserEvent)="modifyUserEvent($event)"
          *ngIf="!createUserFlag && !modifyUserFlag" [selectedIndex]="selectedIndex"></app-user-list>
        <app-create-user *ngIf="createUserFlag && !modifyUserFlag" (moveTab)="selectTab($event)"></app-create-user>
        <app-modify-user *ngIf="modifyUserFlag && !createUserFlag" (moveTab)="selectTab('user')"></app-modify-user>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
