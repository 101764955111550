<div class="container-fluid order-site-details-container">
  <div class="toastrContainer" aria-live="assertive"  toastContainer></div>
  <div class="row">
    <div class="col-4 page-header-container">
      <main aria-labelledby="orderSiteDetailsHeader">
      <h1 class="new-order-label" id="orderSiteDetailsHeader" aria-live="assertive"  aria-label="New Order">{{aemLabelAuthoring?.pageHeader.newOrderHeaderlabel}}</h1></main>
      <div class="quote-ref-id"><span aria-label="Quote reference" class="color-grey-41 font-16">{{aemLabelAuthoring?.pageHeader.pageSubHeaderLabel}}</span> <b class="font-16 color-black"> {{orderDetailsData?.orderReferenceId}}</b>
        <span *ngIf="isOrderSiteDetailsSaved" class="pl-20 font-16 fw-500 color-black"><img src="../../../../assets/images/Submitted.svg" class="saved-icon" aria-label="saved"/> {{aemLabelAuthoring?.saved}}</span></div>
    </div>
    <div class="col-5">
      <app-page-stepper [stepperData]="stepperData" [stepName]="stepName"></app-page-stepper>
    </div>
  </div>

  <div>
    <div class="row heading-font mt-3">
      <div class="col-lg-1 col-md-2 col-sm-2">
        <img class="mt-3" src="../../../../assets/images/Product.svg" alt="product icon" />
      </div>
      <div class="col-lg-2 col-md-3 col-sm-3 product-text pt-3 ms-2">
        {{aemLabelAuthoring?.product}}
        <div class="prod-name details-font"> {{orderDetailsData?.productName}}</div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-4 pt-3">
        <span class="requirement-text">{{aemLabelAuthoring?.fiberRequirement}}</span>
        <div class="prod-name details-font">{{orderDetailsData?.linkRequirement}} </div>
      </div>
    </div>
  </div>

  <!-- <div class="row w-100 mt-40 color-black">
    <div class="col-1">
      <img class="product-icon" src="../../../../assets/images/Product.svg"  />
    </div>
    <div class="col-2">
      <div class="font-bold font-22">{{aemLabelAuthoring?.product}}</div>
      <div class="font-16">{{orderDetailsData?.productName}}</div>
    </div>
    <div class="col-2">
      <div class="font-bold">{{aemLabelAuthoring?.fiberRequirement}}</div>
      <div>{{orderDetailsData?.linkRequirement}}</div>
    </div>
  </div> -->


  <form [formGroup]="orderSiteDetailsForm">

<!-- Site A  -->
    <div>

      <div class="d-flex w-100 mt-40 align-items-center  justify-content-between align-items-center">
        <div class="d-flex align-items-center gap-3">
          <div><img src="../../../../assets/images/LocationPinBlack.svg" alt=""></div>
          <h2 class="font-24 font-bold color-black font-weight">{{aemLabelAuthoring?.siteAendAddress}}</h2>
        </div>
        <div class="d-flex">
          <span class="font-18">Fields marked with <span class="color-red">*</span> are mandatory</span>
        </div>
      </div>

      <hr>

    <div class="row" style="padding-bottom: 69px;">
      <div class="col-4">
        <div class="pb-40">
          <div class="fw-500 font-16 color-grey-41">{{aemLabelAuthoring?.address}}</div>
          <div class="font-16">{{orderDetailsData?.aEndAdress?.address}}</div>
        </div>
        <div class="pb-20">
          <div class="fw-500 font-14 color-black">{{aemLabelAuthoring?.serviceDeliveryPoint}}</div>
          <div class="font-14 text-color-grey">{{aemLabelAuthoring?.handoverLocation}}</div>
          <input class="form-control w-100 pad-20 font-16" aria-describedby="serviceDeliveryPoint_error" type="text" formControlName="serviceDeliveryPoint" maxlength="32" [placeholder]="aemLabelAuthoring?.placeHolderServiceDeliveryPoint" attr.aria-label="{{aemLabelAuthoring?.siteAendAddress}}{{aemLabelAuthoring?.serviceDeliveryPoint}}" [ngClass]="{'invalid-form-control': ((orderSiteDetailsForm.controls.serviceDeliveryPoint.touched || orderSiteDetailsForm.controls.serviceDeliveryPoint.dirty) && orderSiteDetailsForm.controls.serviceDeliveryPoint.invalid)}">
          <ng-container *ngIf="orderSiteDetailsForm.controls['serviceDeliveryPoint'].invalid && (orderSiteDetailsForm.controls['serviceDeliveryPoint'].dirty || orderSiteDetailsForm.controls['serviceDeliveryPoint'].touched)">
            <small id="serviceDeliveryPoint_error" *ngIf="orderSiteDetailsForm.controls['serviceDeliveryPoint'].value?.length > 0 && orderSiteDetailsForm.controls['serviceDeliveryPoint'].invalid" class="text-danger errorMsg">
              <img class="error-icon" src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.thirdPartyInfoMsg}}
            </small>
          </ng-container>
        </div>
        <div class="pb-20"  *ngIf="orderDetailsData?.serviceType === 'RG' && orderDetailsData?.businessDriver === '5G Small Cell' && orderDetailsData?.aEndAdress?.siteName === 'NON-PREMISE'">
          <div class="fw-500 font-14 color-black">{{aemLabelAuthoring?.poleId}} </div>
          <input class="form-control w-100 pad-20 font-16" type="text" formControlName="poleId" maxlength="32" [placeholder]="aemLabelAuthoring?.placeHolderPoleID" attr.aria-label="{{aemLabelAuthoring?.poleId}}" [ngClass]="{'invalid-form-control': ((orderSiteDetailsForm.controls.poleId.touched || orderSiteDetailsForm.controls.poleId.dirty) && orderSiteDetailsForm.controls.poleId.invalid)}">
          <ng-container *ngIf="orderSiteDetailsForm.controls['poleId'].invalid && (orderSiteDetailsForm.controls['poleId'].dirty || orderSiteDetailsForm.controls['poleId'].touched)">
            <small *ngIf="orderSiteDetailsForm.controls['poleId'].value?.length > 0 && orderSiteDetailsForm.controls['poleId'].invalid" class="text-danger errorMsg">
              <img class="error-icon" src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.thirdPartyInfoMsg}}
            </small>
          </ng-container>
        </div>
        <div class="pb-20"  *ngIf="orderDetailsData?.serviceType === 'RG' && orderDetailsData?.businessDriver === '5G Small Cell'">
          <div class="fw-500 font-14 color-black">{{aemLabelAuthoring?.nodeCode}} </div>
          <input class="form-control w-100 pad-20 font-16" type="text" formControlName="nodeCode" maxlength="4" [placeholder]="aemLabelAuthoring?.placeHolderNodeCode" attr.aria-label="{{aemLabelAuthoring?.nodeCode}}" [ngClass]="{'invalid-form-control': ((orderSiteDetailsForm.controls.nodeCode.touched || orderSiteDetailsForm.controls.nodeCode.dirty) && orderSiteDetailsForm.controls.nodeCode.invalid)}">
          <ng-container *ngIf="orderSiteDetailsForm.controls['nodeCode'].invalid && (orderSiteDetailsForm.controls['nodeCode'].dirty || orderSiteDetailsForm.controls['nodeCode'].touched)">
            <small *ngIf="orderSiteDetailsForm.controls['nodeCode'].value?.length > 0 && orderSiteDetailsForm.controls['nodeCode'].invalid" class="text-danger errorMsg">
              <img class="error-icon" src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.thirdPartyInfoMsg}}
            </small>
          </ng-container>
        </div>
        <div class="pb-20" *ngIf="orderDetailsData?.serviceType === 'RG' && orderDetailsData?.businessDriver === '5G Small Cell' && orderDetailsData?.aEndAdress?.siteName === 'NON-PREMISE'">
          <div class="font-14 color-black fw-500"  attr.aria-label="{{aemLabelAuthoring?.newLeadInRequired}}">{{aemLabelAuthoring?.newLeadInRequired}}</div>
          <div class="radio-btn-container">
            <mat-radio-group class="smart-ui-radio-group" formControlName="newLeadInRequired" role="radiogroup"
              class="radio-flex">
              <div tabindex="0" (keyup.enter)="onRadioButtonChange('yes')" role="radio"
              [attr.aria-checked]="(orderSiteDetailsForm.value.newLeadInRequired === 'yes') ? 'true' : 'false'">
                <mat-radio-button class="col-2 smart-ui-button-group newLeadInRequired-radio-btn" value="yes" >
                  <div class="mat-custom-label" aria-label="New lead-in required? Yes"><span>Yes</span></div>
                </mat-radio-button>
              </div>
              <div tabindex="0" (keyup.enter)="onRadioButtonChange('no')" role="radio"
              [attr.aria-checked]="(orderSiteDetailsForm.value.newLeadInRequired === 'no') ? 'true' : 'false'">
                <mat-radio-button class="col-2 smart-ui-button-group newLeadInRequired-radio-btn" value="no">
                  <div class="mat-custom-label" aria-label="New lead-in required? No"><span>No</span></div>
                </mat-radio-button>
              </div>

            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="pb-20">
          <div class="fw-500 font-14 color-black">{{aemLabelAuthoring?.alternativeSiteName}} </div>
          <input aria-describedby="alternativeSiteName_error" (paste)="onPasteCheckAlphaNumeric($event)" (keydown)="typeAlphaNumeric($event)" class="form-control w-100 pad-20 font-16" type="text" maxlength="255" formControlName="alternativeSiteName" [placeholder]="aemLabelAuthoring?.placeHolderAlternativeSiteName"  attr.aria-label="{{aemLabelAuthoring?.alternativeSiteName}}" [ngClass]="{'invalid-form-control': ((orderSiteDetailsForm.controls.alternativeSiteName.touched || orderSiteDetailsForm.controls.alternativeSiteName.dirty) && orderSiteDetailsForm.controls.alternativeSiteName.invalid)}">
          <ng-container *ngIf="orderSiteDetailsForm.controls['alternativeSiteName'].invalid && (orderSiteDetailsForm.controls['alternativeSiteName'].dirty || orderSiteDetailsForm.controls['alternativeSiteName'].touched)">
            <small id="alternativeSiteName_error" *ngIf="orderSiteDetailsForm.controls['alternativeSiteName'].value?.length > 0 && orderSiteDetailsForm.controls['alternativeSiteName'].invalid" class="text-danger errorMsg">
              <img class="error-icon" src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.validationMsg?.inValidAlternativeSiteANameMsg}}
            </small>
          </ng-container>
        </div>
        <div class="pb-20">
          <div class="fw-500 font-14 color-black">{{aemLabelAuthoring?.additionalAddressInformation}} </div>
          <textarea class="form-control w-100 pad-20 font-16" type="text" rows="12" maxlength="250" id="additionalAddressInfo"
          formControlName="additionalAddressInfo" [placeholder]="aemLabelAuthoring?.placeHolderAdditionAddressInfo" attr.aria-label="{{aemLabelAuthoring?.additionalAddressInformation}}"
          (keypress)="onAdditionalInfoKeyPress($event, orderSiteDetailsForm?.value?.additionalAddressInfo?.length,'additionalAddressInfo')" (input)="limitAdditionalAddressInfoLength()"
          [ngClass]="{'invalid-form-control': ((orderSiteDetailsForm.controls.additionalAddressInfo.touched || orderSiteDetailsForm.controls.additionalAddressInfo.dirty) && orderSiteDetailsForm.controls.additionalAddressInfo.invalid)}"  aria-describedby="siteAAdditionalInfoErrMsg" attr.aria-live="(orderSiteDetailsForm.controls.additionalAddressInfo.touched || orderSiteDetailsForm.controls.additionalAddressInfo.dirty) && orderSiteDetailsForm.controls.additionalAddressInfo.invalid)?'assertive':'off'"></textarea>

          <div class="d-flex w-100 justify-content-between">
            <div class="d-flex">
              <ng-container *ngIf="orderSiteDetailsForm.controls['additionalAddressInfo'].invalid && (orderSiteDetailsForm.controls['additionalAddressInfo'].dirty || orderSiteDetailsForm.controls['additionalAddressInfo'].touched)">
                <small *ngIf="orderSiteDetailsForm.controls['additionalAddressInfo'].value?.length > 0 && orderSiteDetailsForm.controls['additionalAddressInfo'].invalid"  id="siteAAdditionalInfoErrMsg" class="text-danger errorMsg">
                  <img class="error-icon" src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.thirdPartyInfoMsg}}
                </small>
              </ng-container>
            </div>
            <div class="d-flex">
              <div class="d-flex w-100 justify-content-end color-theme-green"><span>{{orderSiteDetailsForm.get('additionalAddressInfo')?.value?.length}}</span>/250</div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div>
      <div class="row pb-20">
        <div class="col-12">
          <h3 class="font-bold color-black font-20 font-family" role="heading" aria-label="Site Contact Details of siteA'">{{aemLabelAuthoring?.siteContactDetails}}</h3>
          <div class="text-color-grey font-14">{{aemLabelAuthoring?.siteContactDetailsDesc}}</div>
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <div class="font-14 fw-500">{{aemLabelAuthoring?.siteContactName}} <span class="color-red">*</span></div>
          <input (keydown)="onNumericKeyPressDown($event)"  class="form-control w-100 pad-20 font-16" type="text" maxlength="21" formControlName="siteContactName" [placeholder]="aemLabelAuthoring?.placeHolderSiteContactName"  aria-required="true" attr.aria-label="{{aemLabelAuthoring?.siteAendAddress}} {{aemLabelAuthoring?.siteContactDetails}} {{aemLabelAuthoring?.siteContactName}}" [ngClass]="{'invalid-form-control': ((orderSiteDetailsForm.controls.siteContactName.touched || orderSiteDetailsForm.controls.siteContactName.dirty) && orderSiteDetailsForm.controls.siteContactName.invalid)}" aria-describedby="errorMsgContactName">
          <ng-container *ngIf="orderSiteDetailsForm.controls['siteContactName'].invalid && (orderSiteDetailsForm.controls['siteContactName'].dirty || orderSiteDetailsForm.controls['siteContactName'].touched)" >
          <small *ngIf="orderSiteDetailsForm.controls['siteContactName'].value?.length > 0 && orderSiteDetailsForm.get('siteContactName').hasError('forbiddenChars')" class="text-danger errorMsg" id="errorMsgContactName"  >
            <img class="error-icon" src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.thirdPartyInfoMsg}}
          </small>
          <small
            *ngIf="orderSiteDetailsForm.controls['siteContactName'].errors.required && (orderSiteDetailsForm.controls['siteContactName'].dirty || orderSiteDetailsForm.controls['siteContactName'].touched)"
            class="text-danger errorMsg" id="errorMsgContactName" attr.aria-label="{{aemLabelAuthoring?.validationMsg.siteContactName}}">
            <img class="error-icon" src="../../../../assets/images/icon-error.svg" /> {{aemLabelAuthoring?.validationMsg.siteContactName}}
          </small>
        </ng-container>
        </div>
        <div class="col-4">
          <div class="font-14 fw-500">{{aemLabelAuthoring?.siteContactNumber}} <span class="color-red">*</span></div>
          <input class="form-control w-100 pad-20 font-16" maxlength="10" type="text" formControlName="siteContactNumber" [placeholder]="aemLabelAuthoring?.placeHolderSiteContactNumber"  aria-required="true" attr.aria-label="{{aemLabelAuthoring?.siteAendAddress}} {{aemLabelAuthoring?.siteContactDetails}} {{aemLabelAuthoring?.siteContactNumber}}" aria-describedby="siteContactNumberErr" [ngClass]="{'invalid-form-control': ((orderSiteDetailsForm.controls.siteContactNumber.touched || orderSiteDetailsForm.controls.siteContactNumber.dirty) && orderSiteDetailsForm.controls.siteContactNumber.invalid)}" (keypress)="numberOnly($event)">
           <ng-container *ngIf="orderSiteDetailsForm.controls['siteContactNumber'].invalid && (orderSiteDetailsForm.controls['siteContactNumber'].dirty || orderSiteDetailsForm.controls['siteContactNumber'].touched)">
            <small *ngIf="orderSiteDetailsForm.controls['siteContactNumber'].value?.length > 0 &&  orderSiteDetailsForm.get('siteContactNumber').hasError('forbiddenChars')"   id="siteContactNumberErr" class="text-danger errorMsg">
              <img class="error-icon" src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.thirdPartyInfoMsg}}
            </small>
            <small
              *ngIf="!orderSiteDetailsForm.controls['siteContactNumber'].value?.length && orderSiteDetailsForm.controls['siteContactNumber'].errors.required && (orderSiteDetailsForm.controls['siteContactNumber'].dirty || orderSiteDetailsForm.controls['siteContactNumber'].touched)"
              class="text-danger errorMsg"  id="siteContactNumberErr">
              <img class="error-icon" src="../../../../assets/images/icon-error.svg" /> {{aemLabelAuthoring?.validationMsg.siteContacNumber}}
            </small>
            <small  *ngIf="((orderSiteDetailsForm.controls['siteContactNumber'].value?.length > 0 && orderSiteDetailsForm.controls['siteContactNumber'].value?.length < 10) &&  (!orderSiteDetailsForm.get('siteContactNumber').hasError('forbiddenChars'))) || orderSiteDetailsForm.controls['siteContactNumber'].value?.length > 10"
              class="text-danger errorMsg"   id="siteContactNumberErr">
              <img class="error-icon" src="../../../../assets/images/icon-error.svg" />  {{aemLabelAuthoring?.validationMsg?.invalidContactNumberErrorMsg}}
              </small>
          </ng-container>

        </div>
        <div class="col-4">
          <div class="font-14 fw-500">{{aemLabelAuthoring?.siteContactEmail}} <span class="color-red">*</span></div>
          <input class="form-control w-100 pad-8 font-16 text-box" maxlength="100" type="email" formControlName="siteContactEmail" [placeholder]="aemLabelAuthoring?.placeHolderSiteContactEmail"  aria-required="true" attr.aria-label="{{aemLabelAuthoring?.siteAendAddress}} {{aemLabelAuthoring?.siteContactDetails}} {{aemLabelAuthoring?.siteContactEmail}}" [ngClass]="{'invalid-form-control': ((orderSiteDetailsForm.controls.siteContactEmail.touched || orderSiteDetailsForm.controls.siteContactEmail.dirty) && orderSiteDetailsForm.controls.siteContactEmail.invalid)}" aria-describedby="errorMsgContactEmail">
          <ng-container *ngIf="orderSiteDetailsForm.controls['siteContactEmail'].invalid && (orderSiteDetailsForm.controls['siteContactEmail'].dirty || orderSiteDetailsForm.controls['siteContactEmail'].touched || orderSiteDetailsForm.get('siteContactEmail').hasError('forbiddenChars'))">
            <small *ngIf="orderSiteDetailsForm.controls['siteContactEmail'].value?.length > 0 && orderSiteDetailsForm.get('siteContactEmail').hasError('forbiddenChars')" class="text-danger errorMsg"  id="errorMsgContactEmail" >
              <img class="error-icon" src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.thirdPartyInfoMsg}}
            </small>
            <small
              *ngIf="(orderSiteDetailsForm.controls['siteContactEmail'].errors.required && (orderSiteDetailsForm.controls['siteContactEmail'].dirty || orderSiteDetailsForm.controls['siteContactEmail'].touched))"
              class="text-danger errorMsg"  id="errorMsgContactEmail" >
              <img class="error-icon" src="../../../../assets/images/icon-error.svg" /> {{aemLabelAuthoring?.validationMsg?.siteContacEmail}}
            </small>
            <small  *ngIf="orderSiteDetailsForm.controls['siteContactEmail'].value?.length > 0 && orderSiteDetailsForm.controls['siteContactEmail'].invalid && !orderSiteDetailsForm.get('siteContactEmail').hasError('forbiddenChars')"
            class="text-danger errorMsg"  id="errorMsgContactEmail" >
            <img class="error-icon" src="../../../../assets/images/icon-error.svg" />  {{aemLabelAuthoring?.validationMsg?.inValidEmailAddressErrorMsg}}
            </small>

          </ng-container>
        </div>
      </div>

    </div>

    </div>

    <!-- Site z  -->
    <div>

      <div class="d-flex w-100 mt-100 align-items-center  justify-content-between align-items-center">
        <div class="d-flex align-items-center gap-3">
          <div><img src="../../../../assets/images/LocationPinBlack.svg" alt=""></div>
          <h2 class="font-24 font-bold color-black font-weight">{{aemLabelAuthoring?.siteZendAddress}}</h2>
        </div>
        <!-- <div class="d-flex">
          <span class="font-18">Fields marked with <span class="color-red">*</span> are mandatory</span>
        </div> -->
      </div>

      <hr>

    <div class="row" style="padding-bottom: 69px;">
      <div class="col-4">
        <div class="pb-40">
          <div class="fw-500 font-16 color-grey-41">{{aemLabelAuthoring?.address}}</div>
          <div class="font-16">{{orderDetailsData?.zEndAdress?.address}}</div>
        </div>
        <div class="pb-20">
          <div class="fw-500 font-14 color-black">{{aemLabelAuthoring?.serviceDeliveryPoint}}</div>
          <div class="font-14 text-color-grey">{{aemLabelAuthoring?.handoverLocation}}</div>
          <input class="form-control w-100 pad-20 font-16" aria-describedby="serviceDeliveryPoint2_error"  type="text" maxlength="32" formControlName="serviceDeliveryPoint2"  [placeholder]="aemLabelAuthoring?.placeHolderServiceDeliveryPoint" attr.aria-label="{{aemLabelAuthoring?.siteZendAddress}} {{aemLabelAuthoring?.serviceDeliveryPoint}}"  [ngClass]="{'invalid-form-control': ((orderSiteDetailsForm.controls.serviceDeliveryPoint2.touched || orderSiteDetailsForm.controls.serviceDeliveryPoint2.dirty) && orderSiteDetailsForm.controls.serviceDeliveryPoint2.invalid)}">
          <ng-container  *ngIf="orderSiteDetailsForm.controls['serviceDeliveryPoint2'].invalid && (orderSiteDetailsForm.controls['serviceDeliveryPoint2'].dirty || orderSiteDetailsForm.controls['serviceDeliveryPoint2'].touched)">
            <small id="serviceDeliveryPoint2_error" *ngIf="orderSiteDetailsForm.controls['serviceDeliveryPoint2'].value?.length > 0 && orderSiteDetailsForm.controls['serviceDeliveryPoint2'].invalid" class="text-danger errorMsg">
              <img class="error-icon" src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.thirdPartyInfoMsg}}
            </small>
          </ng-container>
        </div>
        <div class="pb-20" *ngIf="orderDetailsData?.serviceType === 'RG' && orderDetailsData?.businessDriver === '5G Small Cell'  && orderDetailsData?.zEndAdress?.siteName === 'NON-PREMISE'">
          <div class="fw-500 font-14 color-black">{{aemLabelAuthoring?.poleId}} </div>
          <input class="form-control w-100 pad-20 font-16" type="text" maxlength="32" formControlName="poleId2" [placeholder]="aemLabelAuthoring?.placeHolderPoleID" attr.aria-label="{{aemLabelAuthoring?.poleId}}"  [ngClass]="{'invalid-form-control': ((orderSiteDetailsForm.controls.poleId2.touched || orderSiteDetailsForm.controls.poleId2.dirty) && orderSiteDetailsForm.controls.poleId2.invalid)}">
          <ng-container *ngIf="orderSiteDetailsForm.controls['poleId2'].invalid && (orderSiteDetailsForm.controls['poleId2'].dirty || orderSiteDetailsForm.controls['poleId2'].touched)">
            <small *ngIf="orderSiteDetailsForm.controls['poleId2'].value?.length > 0 && orderSiteDetailsForm.controls['poleId2'].invalid" class="text-danger errorMsg">
              <img class="error-icon" src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.thirdPartyInfoMsg}}
            </small>
          </ng-container>
        </div>
        <div class="pb-20" *ngIf="orderDetailsData?.serviceType === 'RG' && orderDetailsData?.businessDriver === '5G Small Cell'">
          <div class="fw-500 font-14 color-black">{{aemLabelAuthoring?.nodeCode}} </div>
          <input class="form-control w-100 pad-20 font-16" maxlength="4" type="text" formControlName="nodeCode2" [placeholder]="aemLabelAuthoring?.placeHolderNodeCode" attr.aria-label="{{aemLabelAuthoring?.nodeCode}}" [ngClass]="{'invalid-form-control': ((orderSiteDetailsForm.controls.nodeCode2.touched || orderSiteDetailsForm.controls.nodeCode2.dirty) && orderSiteDetailsForm.controls.nodeCode2.invalid)}">
          <ng-container *ngIf="orderSiteDetailsForm.controls['nodeCode2'].invalid && (orderSiteDetailsForm.controls['nodeCode2'].dirty || orderSiteDetailsForm.controls['nodeCode2'].touched)">
            <small *ngIf="orderSiteDetailsForm.controls['nodeCode2'].value?.length > 0 && orderSiteDetailsForm.controls['nodeCode2'].invalid" class="text-danger errorMsg">
              <img class="error-icon" src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.thirdPartyInfoMsg}}
            </small>
          </ng-container>
        </div>
        <div class="pb-20" *ngIf="orderDetailsData?.serviceType === 'RG' && orderDetailsData?.businessDriver === '5G Small Cell' && orderDetailsData?.zEndAdress?.siteName === 'NON-PREMISE'">
          <div class="font-14 color-black fw-500">{{aemLabelAuthoring?.newLeadInRequired}}</div>
          <div>
            <mat-radio-group class="smart-ui-radio-group" formControlName="newLeadInRequired2" role="radiogroup"
              class="radio-flex">
              <div tabindex="0" (keyup.enter)="onRadioButton2Change('yes')" role="radio"
              [attr.aria-checked]="(orderSiteDetailsForm.value.newLeadInRequired2 === 'yes') ? 'true' : 'false'">
                <mat-radio-button class="col-2 smart-ui-button-group newLeadInRequired-radio-btn" value="yes">
                  <div class="mat-custom-label" aria-label="New lead-in required? Yes"><span>Yes</span></div>
                </mat-radio-button>
              </div>
              <div tabindex="0" (keyup.enter)="onRadioButton2Change('no')" role="radio"
              [attr.aria-checked]="(orderSiteDetailsForm.value.newLeadInRequired2 === 'no') ? 'true' : 'false'">
                <mat-radio-button class="col-2 smart-ui-button-group newLeadInRequired-radio-btn" value="no" >
                  <div class="mat-custom-label" aria-label="New lead-in required? No"><span>No</span></div>
                </mat-radio-button>
              </div>

            </mat-radio-group>

          </div>
        </div>
      </div>
      <div class="col">
        <div class="pb-20">
          <div class="fw-500 font-14 color-black">{{aemLabelAuthoring?.alternativeSiteName}} </div>
          <input (paste)="onPasteCheckAlphaNumeric($event)" (keydown)="typeAlphaNumeric($event)" aria-describedby="alternativeSiteName2_error"  class="form-control w-100 pad-20 font-16" type="text" maxlength="255" formControlName="alternativeSiteName2" placeholder="Enter alternative site name" attr.aria-label="{{aemLabelAuthoring?.alternativeSiteName}}" [ngClass]="{'invalid-form-control': ((orderSiteDetailsForm.controls.alternativeSiteName2.touched || orderSiteDetailsForm.controls.alternativeSiteName2.dirty) && orderSiteDetailsForm.controls.alternativeSiteName2.invalid)}">
          <ng-container *ngIf="orderSiteDetailsForm.controls['alternativeSiteName2'].invalid && (orderSiteDetailsForm.controls['alternativeSiteName2'].dirty || orderSiteDetailsForm.controls['alternativeSiteName2'].touched)">
            <small id="alternativeSiteName2_error" *ngIf="orderSiteDetailsForm.controls['alternativeSiteName2'].value?.length > 0 && orderSiteDetailsForm.controls['alternativeSiteName2'].invalid" class="text-danger errorMsg">
              <img class="error-icon" src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.validationMsg?.inValidAlternativeSiteZNameMsg}}
            </small>
          </ng-container>
        </div>
        <div class="pb-20">
          <div class="fw-500 font-14 color-black">{{aemLabelAuthoring?.additionalAddressInformation}} </div>
          <textarea class="form-control w-100 pad-20 font-16" type="text" rows="12" maxlength="250" formControlName="additionalAddressInfo2"
          [placeholder]="aemLabelAuthoring?.placeHolderAdditionAddressInfo"  attr.aria-label="{{aemLabelAuthoring?.additionalAddressInformation}}" id="additionalAddressInfo2" siteZAdditionalInfoErrMsg
          (keypress)="onAdditionalInfoKeyPress($event, orderSiteDetailsForm?.value?.additionalAddressInfo2?.length,'additionalAddressInfo2')" (input)="limitAdditionalAddressInfoLength()"
          [ngClass]="{'invalid-form-control': ((orderSiteDetailsForm.controls.additionalAddressInfo2.touched || orderSiteDetailsForm.controls.additionalAddressInfo2.dirty) && orderSiteDetailsForm.controls.additionalAddressInfo2.invalid)}" aria-describedby="siteAAdditionalInfoErrMsg2" attr.aria-live="(orderSiteDetailsForm.controls.additionalAddressInfo2.touched || orderSiteDetailsForm.controls.additionalAddressInfo2.dirty) && orderSiteDetailsForm.controls.additionalAddressInfo2.invalid)?'assertive':'off'"></textarea>

          <div class="d-flex w-100 justify-content-between">
            <div class="d-flex">
              <ng-container *ngIf="orderSiteDetailsForm.controls['additionalAddressInfo2'].invalid && (orderSiteDetailsForm.controls['additionalAddressInfo2'].dirty || orderSiteDetailsForm.controls['additionalAddressInfo2'].touched)" >
                <small *ngIf="orderSiteDetailsForm.controls['additionalAddressInfo2'].value?.length > 0 && orderSiteDetailsForm.controls['additionalAddressInfo2'].invalid" class="text-danger errorMsg"  id="siteAAdditionalInfoErrMsg2" >
                  <img class="error-icon" src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.thirdPartyInfoMsg}}
                </small>
              </ng-container>
            </div>
            <div class="d-flex">
              <div class="d-flex w-100 justify-content-end color-theme-green"><span>{{orderSiteDetailsForm.get('additionalAddressInfo2')?.value?.length}}</span>/250</div>
            </div>
          </div>
        </div>
      </div>
    </div>


     <div>
      <div class="row pb-20">
        <div class="col-12">
          <h3 class="font-bold color-black font-20 font-family">{{aemLabelAuthoring?.siteContactDetails}}</h3>
          <div class="text-color-grey font-14">{{aemLabelAuthoring?.siteContactDetailsDesc}}</div>
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <div class="font-14 fw-500">{{aemLabelAuthoring?.siteContactName}} <span class="color-red">*</span></div>
          <input (keydown)="onNumericKeyPressDown($event)" class="form-control w-100 pad-20 font-16" type="text" maxlength="21" formControlName="siteContactName2" [placeholder]="aemLabelAuthoring?.placeHolderSiteContactName"  aria-required="true" attr.aria-label="{{aemLabelAuthoring?.siteZendAddress}} {{aemLabelAuthoring?.siteContactDetails}} {{aemLabelAuthoring?.siteContactName}}" aria-describedby="errorMsgContactcontactNameSiteZ" [ngClass]="{'invalid-form-control': ((orderSiteDetailsForm.controls.siteContactName2.touched || orderSiteDetailsForm.controls.siteContactName2.dirty) && orderSiteDetailsForm.controls.siteContactName2.invalid)}">
          <ng-container *ngIf="orderSiteDetailsForm.controls['siteContactName2'].invalid && (orderSiteDetailsForm.controls['siteContactName2'].dirty || orderSiteDetailsForm.controls['siteContactName2'].touched)">
          <small *ngIf="orderSiteDetailsForm.controls['siteContactName2'].value?.length > 0 && orderSiteDetailsForm.get('siteContactName2').hasError('forbiddenChars')" class="text-danger errorMsg"  id="errorMsgContactcontactNameSiteZ">
            <img class="error-icon" src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.thirdPartyInfoMsg}}
          </small>
          <small
            *ngIf="orderSiteDetailsForm.controls['siteContactName2'].errors.required && (orderSiteDetailsForm.controls['siteContactName2'].dirty || orderSiteDetailsForm.controls['siteContactName2'].touched)"
            class="text-danger errorMsg"   id="errorMsgContactcontactNameSiteZ">
            <img class="error-icon" src="../../../../assets/images/icon-error.svg" /> {{aemLabelAuthoring?.validationMsg.siteContactName}}
          </small>
        </ng-container>
        </div>
        <div class="col-4">
          <div class="font-14 fw-500">{{aemLabelAuthoring?.siteContactNumber}} <span class="color-red">*</span></div>
          <input class="form-control w-100 pad-20 font-16" maxlength="10" type="text" formControlName="siteContactNumber2" [placeholder]="aemLabelAuthoring?.placeHolderSiteContactNumber"  aria-required="true" attr.aria-label="{{aemLabelAuthoring?.siteZendAddress}} {{aemLabelAuthoring?.siteContactDetails}} {{aemLabelAuthoring?.siteContactNumber}}" aria-describedby="errorMsgContactcontactNumSiteZ" [ngClass]="{'invalid-form-control': ((orderSiteDetailsForm.controls.siteContactNumber2.touched || orderSiteDetailsForm.controls.siteContactNumber2.dirty) && orderSiteDetailsForm.controls.siteContactNumber2.invalid)}" (keypress)="numberOnly($event)">
          <ng-container *ngIf="orderSiteDetailsForm.controls['siteContactNumber2'].invalid || (orderSiteDetailsForm.controls['siteContactNumber2'].dirty || orderSiteDetailsForm.controls['siteContactNumber2'].touched)">
            <small *ngIf="orderSiteDetailsForm.controls['siteContactNumber2'].value?.length > 0 && orderSiteDetailsForm.get('siteContactNumber2').hasError('forbiddenChars')" class="text-danger errorMsg"  id="errorMsgContactcontactNumSiteZ">
              <img class="error-icon" src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.thirdPartyInfoMsg}}
            </small>
            <small
            *ngIf="!orderSiteDetailsForm.controls['siteContactNumber2'].value?.length && orderSiteDetailsForm.controls['siteContactNumber2'].errors.required && (orderSiteDetailsForm.controls['siteContactNumber2'].dirty || orderSiteDetailsForm.controls['siteContactNumber2'].touched)"
            class="text-danger errorMsg"  id="errorMsgContactcontactNumSiteZ">
            <img class="error-icon" src="../../../../assets/images/icon-error.svg" /> {{aemLabelAuthoring?.validationMsg.siteContacNumber}}
          </small>
          <small  *ngIf="((orderSiteDetailsForm.controls['siteContactNumber2'].value?.length > 0 && orderSiteDetailsForm.controls['siteContactNumber2'].value?.length < 10) &&  (!orderSiteDetailsForm.get('siteContactNumber2').hasError('forbiddenChars'))) || orderSiteDetailsForm.controls['siteContactNumber2'].value?.length > 10"
            class="text-danger errorMsg"  id="errorMsgContactcontactNumSiteZ">
            <img class="error-icon" src="../../../../assets/images/icon-error.svg" />  {{aemLabelAuthoring?.validationMsg?.invalidContactNumberErrorMsg}}
            </small>
          </ng-container>

        </div>
        <div class="col-4">
          <div class="font-14 fw-500">{{aemLabelAuthoring?.siteContactEmail}} <span class="color-red">*</span></div>
          <input class="form-control w-100 pad-8 font-16 text-box" maxlength="100" type="email"  formControlName="siteContactEmail2" [placeholder]="aemLabelAuthoring?.placeHolderSiteContactEmail"  aria-required="true" attr.aria-label="{{aemLabelAuthoring?.siteZendAddress}} {{aemLabelAuthoring?.siteContactDetails}} {{aemLabelAuthoring?.siteContactEmail}}"  [ngClass]="{'invalid-form-control': ((orderSiteDetailsForm.controls.siteContactEmail2.touched || orderSiteDetailsForm.controls.siteContactEmail2.dirty) && orderSiteDetailsForm.controls.siteContactEmail2.invalid)}"  aria-describedby="errorMsgsiteContactEmail2">
          <ng-container *ngIf="orderSiteDetailsForm.controls['siteContactEmail2'].invalid && (orderSiteDetailsForm.controls['siteContactEmail2'].dirty || orderSiteDetailsForm.controls['siteContactEmail2'].touched || orderSiteDetailsForm.get('siteContactEmail2').hasError('forbiddenChars'))">
            <small *ngIf="orderSiteDetailsForm.controls['siteContactEmail2'].value?.length > 0 && orderSiteDetailsForm.get('siteContactEmail2').hasError('forbiddenChars')" class="text-danger errorMsg"  id="errorMsgsiteContactEmail2">
              <img class="error-icon" src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.thirdPartyInfoMsg}}
            </small>
            <small
              *ngIf="(orderSiteDetailsForm.controls['siteContactEmail2'].errors.required && (orderSiteDetailsForm.controls['siteContactEmail2'].dirty || orderSiteDetailsForm.controls['siteContactEmail2'].touched))"
              class="text-danger errorMsg"  id="errorMsgsiteContactEmail2">
              <img class="error-icon" src="../../../../assets/images/icon-error.svg" /> {{aemLabelAuthoring?.validationMsg?.siteContacEmail}}
            </small>
            <small  *ngIf="orderSiteDetailsForm.controls['siteContactEmail2'].value?.length > 0 && orderSiteDetailsForm.controls['siteContactEmail2'].invalid && !orderSiteDetailsForm.get('siteContactEmail2').hasError('forbiddenChars')"
            class="text-danger errorMsg"  id="errorMsgsiteContactEmail2">
            <img class="error-icon" src="../../../../assets/images/icon-error.svg" />  {{aemLabelAuthoring?.validationMsg?.inValidEmailAddressErrorMsg}}
            </small>

          </ng-container>
        </div>
      </div>

    </div>

    </div>



<!-- Nav -->
<div class="row">
  <div class="col-12">
    <div class="submitCancelBtn btnRow">
      <button mat-button class="cancelBtn save-cancel-btn" (click)="saveOrderDetails(true, 'cancel')">
        <span class="cancel-btn"><i class="icon-icon-ui-cross"></i>
          {{aemLabelAuthoring?.button.cancel}}
        </span>
      </button>
      <button  class="app-btn-green app-btn-white save-cancel-btn" [disabled]="isSavingInProgress" (click)="saveOrderDetails(true, 'save')">
        <div class="btn-svg save-icon"></div>
        {{aemLabelAuthoring?.button.save}}
      </button>
      <label role="alert" *ngIf="isOrderSaved" style="position:absolute;left:-9999px">Successfully saved</label>
      <button mat-button class="app-btn-green" [disabled]="orderSiteDetailsForm.invalid" (click)="saveOrderDetails(false, 'next')" tabindex="0">
        {{aemLabelAuthoring?.button.next}}
        <div class="btn-svg next-icon"></div>
      </button>


      <!-- <button mat-button  class="back-btn cancelBtn save-cancel-btn">
        <span class="cancel-btn">
          <div class="btn-svg back-icon"></div>
          {{aemLabelAuthoring?.button.back}}
        </span>
      </button> -->
    </div>
  </div>
</div>
  </form>
</div>
