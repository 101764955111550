<div class="container-fluid whitelist" id="qa-banner">
  <h1 class="heading" id="dom">Domain Whitelist</h1>
  <span class="accLabel">Fields marked with <span class="star">*</span> are mandatory</span>
  <form [formGroup]="domainForm" id="createDomain" #createDomainName="ngForm">
    <div class="form-group whitelist-form">
      <div  aria-live="polite" role="alert" class="toastrContainer" toastContainer></div>
      <div class="row">
        <div class="col-6">
          <div class="col-8">
            <div class="displayInlineBlock appNameDropDown">
              <label class="user-details-label-top required-field" automationType="label" automationId="lblrole"
                for="appName" attr.aria-label="Application">
                Application
              </label>
              <app-cui-searchable-dropdown class="selectDropdowns" formControlName="appName" name="AppName"
                [items]="appList" [isRequired]=true [model]="appNameModel"
                automationType="dropdown" automationId="drpdwnroleType" [labelName]="'Records'" [noAutoComplete]=false
                id="appName" class="user-dropDown" (ngModelChange)="updateGrid()" required>
              </app-cui-searchable-dropdown>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row rightRow">
            <div class="col-4"></div>
            <div class="col-4">
              <div class="downloadSection" (click)="downloadDomainList()">
                <img class="mobileImg" src="../../assets/images/icon-download.svg" />
                <button type="button" class="downloadBtn" aria-label="Download Domain List">
                  Download
                </button>
              </div>
            </div>
            <div class="col-4">
              <div class="addDomainSection" (click)="addDomain()">
                <button type="button" class="app-btn-green" aria-label="Add a domain">
                  <i class="icon-icon-ui-plus"></i>
                  Add a domain
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template #spinner>
        <div class="grid-container white-list-grid" *ngIf="!gridInitialized">
          <div class="spin-container">
            <app-spinner></app-spinner>
          </div>
        </div>
      </ng-template>
      <div class="modal" aria-label="Add a domain" role="dialog" aria-modal="true" [ngClass]="{'addDomainModal':isModalOpen}">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="container">
              <div class="row" *ngIf="isModalOpen">
                <div class="closeBtn">
                  <span (click)="closeModal()" id="close" class="cancelBtn close" tabindex="0" aria-label="Close" role="button">
                    <i class="icon-icon-ui-cross"></i>
                  </span>
                </div>
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                    <label class="user-details-label-top" aria-label="Enter a domain to be added to Whitelisting" automationType="label" automationId="lblrole">
                      Enter a domain to be added to Whitelisting
                    </label>
                    <app-cui-textfield [(model)]="domainModel" [parentFormGroup]="domainForm" (click)="domainNameFocus()">
                    </app-cui-textfield>
                  </div>
                  <div class="btnRow">
                    <div class="submitCancelBtn">
                      <button type="button" class="app-cancel-btn" tabindex="0" id="can" (click)="closeModal()" (keydown) = 'setFocusInModal($event)' aria-label="Cancel add new domain pop up">
                        <i class="icon-icon-ui-cross"></i>
                        Cancel
                      </button>
                      <button type="button" class="app-save-btn"  tabindex="0" id="sub" (click)="onSubmitDomain()" aria-label="Add new domain save button"
                        [disabled]="domainForm.invalid" [aria-disabled]="domainForm.invalid">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="grid-container domainList" *ngIf="gridConfig && gridInitialized">
    <app-cui-ag-grid [model]="gridModel" (filtersOnChanged)="onFilterSelected($event)" (focusOnTop)="focusOnTop($event)">
    </app-cui-ag-grid>
  </div>
</div>
