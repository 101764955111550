<form [formGroup]="thirdFormGroup">
  <div class="backButtonTag">
    <button class="backButton" mat-button matStepperPrevious><span class="back-arrow"></span>Back</button>
  </div>
  <div class="stepper-container">
    <div class="addressDetailsIcon"></div>
    <div class="header">
      <h2 class="section-header">Surveying details</h2>
      <p class="section-header-label">The Telstra accredited contractor authorised by the Acquirer to complete the
        following activities on behalf
        of the Acquirer.</p>
    </div>
  </div>
  <hr>
  <div class="group1">
    <div class="row col-12 pt-3">
      <div class="col-4 pl-0">
        <label class="user-details-label-top" for="surveyingCompany" aria-required="true"
          required>Surveying company</label>
        <input type="text" class="form-control field-input" maxlength="100" id="surveyingCompany"
          name="surveyingCompany" placeholder="Enter surveying company" attr.aria-label="document title"
          formControlName="surveyingCompany">
      </div>
      <div class="col-4">
        <label class="user-details-label-top" for="surveyorName" aria-required="true" required>Surveyor
          name</label>
        <input type="text" class="form-control field-input" maxlength="100" id="surveyorName" name="surveyorName"
          placeholder="Enter surveyor name" attr.aria-label="document title" formControlName="surveyorName">
      </div>
      <div class="col-4">
        <label class="user-details-label-top" for="surveyorContactNumber" aria-required="true"
          required>Contact number</label>
        <input type="text" class="form-control field-input" maxlength="12" id="surveyorContactNumber"
          name="surveyorContactNumber" placeholder="Enter contact number" attr.aria-label="document title"
          formControlName="surveyorContactNumber"
          [ngClass]="{'invalid-form-control': (thirdFormGroup.controls.surveyorContactNumber.touched && thirdFormGroup.controls.surveyorContactNumber.invalid)}">
        <span role="alert"
          *ngIf="thirdFormGroup.controls.surveyorContactNumber.touched && thirdFormGroup.controls.surveyorContactNumber.invalid"
          class="help-block errorMsg">Please enter a valid contact number</span>
      </div>
    </div>

    <div class="row col-12 pt-3">
      <div class="col-4 pl-0">
        <label class="user-details-label-top" for="civilsCompany" aria-required="true" required>Civils
          Company</label>
        <input type="text" class="form-control field-input" maxlength="100" id="civilsCompany" name="civilsCompany"
          placeholder="Enter civil company" attr.aria-label="document title" formControlName="civilsCompany">
      </div>
      <div class="col-4">
        <label class="user-details-label-top" for="civilsContactName" aria-required="true"
          required>Contact name</label>
        <input type="text" class="form-control field-input" maxlength="100" id="civilsContactName"
          name="civilsContactName" placeholder="Enter contact name" attr.aria-label="document title"
          formControlName="civilsContactName">
      </div>
      <div class="col-4">
        <label class="user-details-label-top" for="civilsContactNumber" aria-required="true"
          required>Contact number</label>
        <input type="text" class="form-control field-input" maxlength="12" id="civilsContactNumber"
          name="civilsContactNumber" placeholder="Enter contact number" attr.aria-label="document title"
          formControlName="civilsContactNumber"
          [ngClass]="{'invalid-form-control': (thirdFormGroup.controls.civilsContactNumber.touched && thirdFormGroup.controls.civilsContactNumber.invalid)}">
        <span role="alert"
          *ngIf="thirdFormGroup.controls.civilsContactNumber.touched && thirdFormGroup.controls.civilsContactNumber.invalid"
          class="help-block errorMsg">Please enter a valid contact number</span>
      </div>
    </div>

    <div class="row col-12 pt-3 pb-4">
      <div class="col-4 pl-0">
        <label class="user-details-label-top" for="haulingCompany" aria-required="true" required>Hauling
          company</label>
        <input type="text" class="form-control field-input" maxlength="100" id="haulingCompany" name="haulingCompany"
          placeholder="Enter hauling company" attr.aria-label="document title" formControlName="haulingCompany">
      </div>
      <div class="col-4">
        <label class="user-details-label-top" for="haulingContactName" aria-required="true"
          required>Contact name</label>
        <input type="text" class="form-control field-input" maxlength="100" id="haulingContactName"
          name="haulingContactName" placeholder="Enter contact name" attr.aria-label="document title"
          formControlName="haulingContactName">
      </div>
      <div class="col-4">
        <label class="user-details-label-top" for="haulingContactNumber" aria-required="true"
          required>Contact number</label>
        <input type="text" class="form-control field-input" maxlength="12" id="haulingContactNumber"
          name="haulingContactNumber" placeholder="Enter contact number" attr.aria-label="document title"
          formControlName="haulingContactNumber"
          [ngClass]="{'invalid-form-control': (thirdFormGroup.controls.haulingContactNumber.touched && thirdFormGroup.controls.haulingContactNumber.invalid)}">
        <span role="alert"
          *ngIf="thirdFormGroup.controls.haulingContactNumber.touched && thirdFormGroup.controls.haulingContactNumber.invalid"
          class="help-block errorMsg">Please enter a valid contact number</span>
      </div>
    </div>
    <hr>
  </div>

  <div class="row upload-document" *ngIf="aemLabelAuthoring">

    <div class="col-12">
      <label class="dropdown-title" required>File Attachment</label>
      <label class="dropdown-title" for="file" hidden>File Attachment</label>
      <div class="uploadfilecontainer" appductFileUpload [allowSingle]="true" (onFileDropped)="uploadFile($event)">
        <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)" (click)="fileInput.value=null"
          id="file">
        <div class="center-content">
          <img id="upload_to_cloud" src="../../../../../../assets/images/upload_to_cloud.png" alt="upload_to_cloud">
          <br />
          <h3 class="drag">Drag and drop file or</h3>
          <p class="instruction">( pdf, doc, docx, jpeg, jpg, png, xls, xlsx, zip )</p>
          <button tabindex="0" aria-label="file attachment browse button" role="button" [disabled]="thirdFormGroup.get('fileName').value" class="btn secondary-btn browse-btn"
            (click)="fileInput.click()">Browse</button>
          <p class="mb-0 mt-2 info">Total upload size is 20MB, <strong>if you have multiple files please zip and
              upload.</strong></p>
        </div>
      </div>
      <div>
        <p style="color: darkred;" *ngIf="thirdFormGroup.get('validationError').value">
          <i class="icon-icon-alert-triangle"></i>
          {{thirdFormGroup.get('validationError').value}}
        </p>
        <div class="files-list mb-2 max-w-100" *ngIf="thirdFormGroup.get('fileName').value">
          <p class="mb-0 overflow-hidden word-break"> {{thirdFormGroup.get('fileName').value}} </p>
          <button aria-label="delete attachment" role="button" class="delete-file" (click)="deleteAttachment()">
            <i class="icon-icon-ui-cross icon-sm file-cross-btn"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <div class="saci">
    <input type="checkbox" formControlName="saci" id="saci" name="saci" checked style="transform: scale(1.45);"
      (ngModelChange)="updateSaciValidators()">
    &nbsp;
    <label class="question" style="font-size: 16px;" for="saci">Will you be conducting a
      SACI?
      <span class="field-comment">(Self Assessment Completion Inspection)</span>
    </label>
    <div class="row col-12 mt-3" *ngIf="thirdFormGroup.controls.saci.value">
      <div class="col-4 pl-0">
        <label class="user-details-label-top required-field" for="saciCompany" aria-label="Accredited SACI Company required" aria-required="true" required>Accredited
          SACI Company</label>
        <input type="text" class="form-control field-input" maxlength="100" id="saciCompany" name="saciCompany"
          placeholder="Enter Accredited SACI Company" attr.aria-label="document title" formControlName="saciCompany">
      </div>
      <div class="col-4">
        <label class="user-details-label-top" for="contactNameSaci" aria-required="true" required>Contact
          name</label>
        <input type="text" class="form-control field-input" maxlength="50" id="contactNameSaci" name="contactNameSaci"
          placeholder="Enter contact name" attr.aria-label="document title" formControlName="contactNameSaci">
      </div>
      <div class="col-4">
        <label class="user-details-label-top" for="contactNumberSaci" aria-required="true"
          required>Contact number</label>
        <input type="text" class="form-control field-input" maxlength="12" id="contactNumberSaci"
          name="contactNumberSaci" placeholder="Enter contact number" attr.aria-label="document title"
          formControlName="contactNumberSaci"
          [ngClass]="{'invalid-form-control': thirdFormGroup.controls.contactNumberSaci.errors?.pattern}">
        <span role="alert" *ngIf="thirdFormGroup.controls.contactNumberSaci.errors?.pattern"
          class="help-block errorMsg">Please enter a valid contact number</span>
      </div>
    </div>
  </div>
  <div class="terms-condition">
    <input type="checkbox" aria-required="true" formControlName="termsCheck" id="termsCheck" name="termsCheck"
      style="transform: scale(1.45);"> &nbsp;
    <label class="question" aria-label="I agree to the terms and conditions" style="font-size: 16px;" for="termsCheck">I agree to the
    </label><a role="button" (click)="termsAndConditions(true)" (keypress)="termsAndConditions(true)" tabindex="0" class="field-title required-field"
      style="font-size: 16px;text-decoration: none;"> terms and conditions</a>
  </div>

</form>
<app-terms-and-conditions-renderer (isModalOpen)="termsAndConditions($event)"
  *ngIf="termsAndConditionsDisplay"></app-terms-and-conditions-renderer>