import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HomePageService } from '../shared/services/homePage.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nbn-dropdown-menu',
  templateUrl: './nbn-dropdown-menu.component.html',
  styleUrls: ['./nbn-dropdown-menu.component.css']
})
export class NbnDropdownMenuComponent implements OnInit {
  isSubMenuOpen: boolean = false;
  customerDetails: any;
  @Output() closeDropdownMenu = new EventEmitter<void>();
  private isMenuOpen: boolean = false;

  mainMenu: any[] = [
    {
      title: 'Admin',
      url: ''
    },
    {
      title: 'Cabinets',
      url: '/cabinet'
    },
    {
      title: 'Documents',
      url: '/document'
    },
    {
      title: 'FAQs',
      url: '/faqs'
    }
  ];
  adminSubMenu: any[] = [
    {
      title: 'Create a user',
      url: '/admin',
      id: 'createUser'
    },
    {
      title: 'My users',
      url: '/admin',
      id: 'myUser'
    },
    {
      title: 'Manage cabinets',
      url: '/manage-cabinet',
      id:''
    }
  ];
  subMenu: string[];
  selectedIndex: number;
  constructor(
    private homepageservice: HomePageService,
    private commonService: CommonService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.customerDetails = this.homepageservice.getCustomerDetails();
    this.getNbnMenu();
    window.onkeyup = function (event) {
      if (event.keyCode == 27) {
        (<HTMLElement>document.getElementsByClassName('drpdwn-menu')[0]).style.display = 'none';
        (<HTMLElement>document.getElementsByClassName('nbn-dropdown-menu-container')[0]).style.display = 'none';
      }
    }
  }

  navigate(title) {
    if (title === 'Admin') {
      this.router.navigate(['']);
    }
    if (title === 'Cabinets') {
      this.router.navigate(['/cabinet']);
    }
    if (title === 'Documents') {
      this.router.navigate(['/documents']);
    }
    if (title === 'FAQs') {
      this.router.navigate(['/faqs']);
    }
  }
  getNbnMenu() {
    const roles = this.commonService.getroleDetails().customerList;
    const adminUserMgm = roles.some((obj) => (obj?.role === 'NBN Administrator' && obj?.function === 'NBN User Management'));
    const adminManageCabinet = roles.some((obj) => (obj?.role === 'NBN Administrator' && obj?.function === 'NBN Cabinet Access Management'));
    if (this.customerDetails.isInfraCoCustomer) {
      this.mainMenu.splice(2, 1); //remove document
      if (adminManageCabinet && adminUserMgm) {
        this.adminSubMenu.push({
          title: 'Domain Whitelist',
          url: '/whitelist',
          id: ''
        });
      } else if (adminUserMgm) {
        this.adminSubMenu.splice(2, 1); //remove manage cabinet
        this.adminSubMenu.push({
          title: 'Domain Whitelist',
          url: '/whitelist',
          id: ''
        });
      } else if (adminManageCabinet) {
        this.adminSubMenu.splice(0, 2); //remove user list, create user
      } else if (roles.some((obj) => (obj?.function === 'NBN Cabinets') // no role check require
        || (obj?.function === 'NBN DA Documents'))) {
        this.mainMenu.splice(0, 1); //remove admin
      }
    } else if (this.customerDetails.isNBNCustomer) {
      this.mainMenu.splice(1, 1); //remove cabinet
      if (adminManageCabinet && adminUserMgm) {
        this.adminSubMenu = [];
        this.adminSubMenu.push({
          title: 'Create a user',
          url: '/admin',
          id: 'createUser'
        },
          {
            title: 'My users',
            url: '/admin',
            id: 'myUser'
          },
          {
            title: 'Manage cabinets',
            url: '/manage-cabinet',
            id: ''
          });
      }
      else if (adminUserMgm) {
        this.adminSubMenu.splice(2, 1); //remove manage cabinet
      } else if (adminManageCabinet) {
        this.adminSubMenu.splice(0, 2); //remove user list, create user
      } else if (roles.some((obj) => (obj?.function === 'NBN Cabinets') // no role check require
        || (obj?.function === 'NBN DA Documents'))) {
        this.mainMenu.splice(0, 1); //remove admin
      }
    }
  }

  showSubMenu(menu, index) {
    this.subMenu = [];
    this.isSubMenuOpen = true;
    this.selectedIndex = index;
    if (menu === 'Admin') {
      this.subMenu = this.adminSubMenu;
    }
  }
  navigate1(title) {
    if (title === 'Create a user') {
      this.router.navigate(['/admin', 'createUser']);
    }
    if (title === 'My users') {
      this.router.navigate(['/admin', 'myUser']);
    }
    if (title === 'Manage cabinets') {
      this.router.navigate(['/manage-cabinet']);
    }
    if (title === 'Domain Whitelist') {
      this.router.navigate(['/whitelist']);
    }
  }
  takeme(e) {
    if (e) {
      if (e.key === 'ArrowRight') {
        this.setFocusToFirstElement();
      }
    }
  }

  setFocusToFirstElement() {
    const firstElement = document.querySelector('span');
    if (firstElement) {
      firstElement.focus();
    }
  }
  check(title) {
    if (title === 'Domain Whitelist') {
      this.closeDropdownMenu.emit();
    }
  }
  collapse(title) {
    let _this = this;
    window.onkeyup = function (event) {
      if ((event.keyCode == 9 && !event.shiftKey && title === 'FAQs') || (event.keyCode == 9 && event.shiftKey && title === 'Admin')) {
        _this.closeDropdownMenu.emit();
      }
    }
  }
  closeMenu(subMenu) {
    if (subMenu === 'Create a user') {
      this.commonService.showCreateUser(true);
    } else {
      this.commonService.showCreateUser(false);
    }
    this.closeDropdownMenu.emit();
  }

  mouseLeaveEvent = () => {
    this.closeDropdownMenu.emit();
  }

}
