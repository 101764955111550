import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomTextField, CustomDropDown, TooltipContent } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastContainerDirective, ToastrService, GlobalConfig } from 'ngx-toastr';
import { CabinetsService } from '../../cabinets/cabinets.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-create-cabinet',
  templateUrl: './create-cabinet.component.html',
  styleUrls: ['./create-cabinet.component.less']
})
export class CreateCabinetComponent implements OnInit {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  @Output() goToCabinet = new EventEmitter();
  public aemLabelAuthoring: any = null;
  public cabinetIDModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMyCabinetID',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxcabinetID',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divcabinetIDValidationErrMsg',
    placeholderCaption: 'Enter the document cabinet ID',
    id: 'cabinetID',
    tabindex: '',
    maxlength: '15',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'cabinetID',
    formControlName: 'cabinetID',
    isDisabled: false,
    showValidationIcon: false
  };

  public cabinetNameModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMyCabinetName',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxcabinetName',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divcabinetIDValidationErrMsg',
    placeholderCaption: 'Enter the document cabinet name',
    id: 'cabinetName',
    tabindex: '',
    maxlength: '100',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: true,
    isKeyDownDefaultValidation: false,
    name: 'cabinetName',
    formControlName: 'cabinetName',
    isDisabled: false,
    showValidationIcon: false
  };
  options: GlobalConfig;
  constructor(private router: Router, private toastr: ToastrService, private cabinetsService: CabinetsService,private commonService:CommonService) { }

  ngOnInit() {
    this.getLabels();
    const roles = this.commonService.getroleDetails().customerList;
    if (!roles.some((obj) => obj.role.includes('NBN') || obj.function.includes('NBN'))) {
      this.router.navigateByUrl('/access-denied');
    }
  }

  specialCharCheck(event) {
    const regex = new RegExp('((\\\\)|(/)|(:)|(;)|(\\*)|(\\?)|(")|(<)|(>)|(\\|)|(#))', "g");
    if (this.cabinetIDModel.inputText.match(regex)) {
      this.cabinetIDModel.validationMessage = 'Characters \\ / : ; * ? “ < > | # are not allowed';
    }
    else {
      this.cabinetIDModel.validationMessage = '';
    }
  }

  getLabels() {
    return new Promise((resolve, reject) => {
      this.cabinetsService.getCreateDocumentModel().then(() => {
        this.aemLabelAuthoring = this.cabinetsService.createCabinetLabelAuthoringDataObj;
        resolve(true);
      }).catch(() => {
        reject(false);
      });
    });
  }

  onClickSubmit() {
    let params = {
      cabinetId: this.cabinetIDModel.inputText,
      cabinetName: this.cabinetNameModel.inputText
    }
    return new Promise((resolve, reject) => {
      this.cabinetsService.createCabinetDocument(params).then((res: any) => {
        this.options = this.toastr.toastrConfig;
        if (res['status'] === 400 && res['code'] === 400) {
          let msg = res.message;
          this.showToasterMessage(msg, '', this.options, 'error');
          this.goToCabinet.emit(true);
          this.router.navigateByUrl('/cabinet');
        } else {
          let successMsg = 'Congratulations. You have successfully created a new cabinet.';
          this.showToasterMessage(successMsg, '', this.options, 'success');
          this.goToCabinet.emit(true);
          this.router.navigateByUrl('/cabinet');
        }
        resolve(true);
      }).catch((err) => {
        if (err['error']['status'] === 400 && err['error']['code'] === 400) {
          let msg = err.error.message;
          this.options = this.toastr.toastrConfig;
          this.showToasterMessage(msg, '', this.options, 'error');
          this.goToCabinet.emit(true);
          this.router.navigateByUrl('/cabinet');
        } else {
          this.router.navigateByUrl('/error');
        }
        reject(false);
      });
    });
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-top-center';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 5000;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
  }

  cancel() {
    this.goToCabinet.emit(true);
    this.router.navigateByUrl('/cabinet');
  }
}
