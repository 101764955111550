<div class="container-fluid document-list-container page-bg" *ngIf="aemLabelAuthoring" id="qa-banner">
  <div id="head" class="header-container"><h1 class="heading">Documents</h1></div>

<hr>
    <div class="col-12 search-box-container">
      <app-cui-search-box *ngIf="aemLabelAuthoring"  [model]="searchModel" aria-label="search"
    (searchEvent)="filterSearchInput($event)" (resetEvent)="resetGrid(null)" (keyup)="inputChange($event)">
  </app-cui-search-box>
    </div>
 
  <div class="grid-container" *ngIf="gridConfig && gridInitialized">
    <div class="ticket-grid">
      <app-cui-ag-grid [model]="gridData" (filtersOnChanged)="onFilterSelected($event)"
        (gridOnReady)='gridReady($event)' (focusOnTop)="focusOnTop($event)">
      </app-cui-ag-grid>
    </div>
  </div>
</div>