import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { HomePageService } from 'src/app/shared/services/homePage.service';
import { AuthService } from 'tw-core-ui';

@Component({
  selector: 'app-cabinets-action-renderer',
  templateUrl: './cabinets-action-renderer.component.html',
  styleUrls: ['./cabinets-action-renderer.component.css']
})

export class CabinetsActionRendererComponent {

  params;
  isInfracoAdmin: boolean;
  roles=[];

  constructor(
    private authService: AuthService,
    private commonService: CommonService,
    private homepageservice: HomePageService
  ) {

    const roles = this.commonService.getroleDetails();
    const customerDetails = this.homepageservice.getCustomerDetails();
    try {
      this.roles= roles.customerList;
        if (
          customerDetails.isNBNCustomer 
        ) {
          this.isInfracoAdmin = false;
        } else if(
          customerDetails.isInfraCoCustomer && this.roles.some(a => a?.role === 'NBN Administrator')
        ) {
          this.isInfracoAdmin = true;
        } else {
          this.isInfracoAdmin = false;
        }
    } catch(err) {
      this.roles= [];
    }
  }

  agInit(params: any): void {
    this.params = params;
  }

  viewDocument(){
    this.params.clicked(this.params.data, "view");
  }
  
  deleteDocument(){
    this.params.clicked(this.params.data, "delete");
  }

}
