<div class="fibre-dropdown-menu-container" (mouseleave)="mouseLeaveEvent()" *ngIf="mainMenu.length">
    <div class="drpdwn-container">
      <div>
        <ul class="drpdwn-menu">
          <li tabindex="0"
            class="drpdwn-menu-list menu-list-text"
            (keypress)="navigate(menu.title)"
            (keydown)="showSubMenu(menu.title, i,$event)"
            *ngFor="let menu of mainMenu; let i = index"
            [ngClass]="{ 'active-menu': selectedIndex === i }"
            (keydown)="navigatebh(menu.title, $event)"
            (mouseover)="showSubMenu(menu.title, i)"
            (focus)="showSubMenu(menu.title, i)"
            routerLink="{{menu.url}}"
            (click)="closeMenu(menu.title)"
            attr.aria-label="{{menu.title}} menu">
            <img class="title-icon" src="{{menu.icon}}" />
            {{ menu.title }}
          </li>
        </ul>
      </div>
      <div *ngIf="isSubMenuOpen">
        <div class="drpdwn-sub-menu menu-list-text row" role="menu" [ngStyle]="{'display': (subMenu?.length) > 2 ? 'flex' : 'grid'}">
          <span class="col-6 sub-menu-list" attr.aria-label="{{menu.title}} submenu" role="menuitem" tabindex="0" *ngFor="let menu of subMenu; let i=index" routerLink="{{menu.url}}" (click)="closeMenu(menu.title)" (keydown)="collapse(menu.title,$event)" (keypress)="closeMenu(menu.title)">
            <span>{{
            menu.title
          }}</span></span>
        </div>
      </div>
    </div>
  </div>
  
