import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SiteDetailsService } from 'src/app/quote-management/create-new-quote/site-details/site-details.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { CreateQuoteService } from '../../../create-quote.service';
//import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { CustomDropDown } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { Subscription } from 'rxjs';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-refrence-form',
  templateUrl: './refrence-form.component.html',
  styleUrls: [
    './refrence-form.component.less',
    '../../site-details.component.less',
    '../../../product-details/product-details.component.less',
  ],
  providers: [TitleCasePipe],
})
export class RefrenceFormComponent implements OnInit {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  @Output() onrefActivateZend = new EventEmitter<any>();
  @Output() onrefLocationRef = new EventEmitter<any>();

  @Output() onrefA2ctivateZend = new EventEmitter<any>();
  @Output() onrefZ2ctivateZend = new EventEmitter<any>();
  @Output() clearBtnClicked = new EventEmitter<any>();

  @Input() formConfig;
  @Input() aemLabelAuthoring;
  options: GlobalConfig;


  @Input() UpdateLocRefForm;
  pattern = /^[a-zA-Z0-9!@#$%^&*()\-\_=+:'",.?|{}\n\[\]\/\`\~\\ ]*$/;
  env = window['environment'];
  latLngApiEndPoint = 'infraco-addresses/search-by-latitude-longitude';
  refrenceForm = new FormGroup({
    latitude: new FormControl('-31.74105465', [Validators.required]),
    longitude: new FormControl('115.75854319', [Validators.required]),
    siteType: new FormControl(''),
    searchMethod: new FormControl(''),
    selectedAddress: new FormControl(''),
    additionalAddressInfo: new FormControl('', [Validators.maxLength(255),Validators.pattern(this.pattern)]),
    definedPropertyLocation: new FormControl('Yes'),
    rawSearchText: new FormControl('', [Validators.required]),
    adborId: new FormControl(),
    nbnLocId: new FormControl(),
    address: new FormControl('', [Validators.required]),
    refresh: new FormControl(false),
  });
  searchResults: any[] = [];
  refrences: any[];
  primaryAutoComplete: any;
  preDefinedSiteNode: any;
  address: any;

  public searchMethodDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId:
      'Location reference Search Type Required Combobox. Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under',
  };
  searchMethod: any;
  showSubAddresses: boolean = false;
  showAddressContainer: boolean = false;
  noAddressFound: boolean = false;
  subscription: Subscription;
  constructor(
    private siteDetailsService: SiteDetailsService,
    private api: ApiService,
    private titleCasePipe: TitleCasePipe,
    private createQuoteService: CreateQuoteService,
    private toastr: ToastrService,

  ) {
    this.options = this.toastr.toastrConfig;
  }

  ngOnInit(): void {
    this.refrences = this.siteDetailsService.refrenceSearchType();
    this.refrenceForm.controls['searchMethod'].setValue('refrence-ADBoR ID');
    this.searchMethod = 'refrence-' + this.refrences[0];
    this.subscription = this.refrenceForm.valueChanges.subscribe((value) => {
      setTimeout(() => {
        let refrenceFormValue = this.refrenceForm.value;
        refrenceFormValue.formName = this.formConfig.formName;
        if (refrenceFormValue.latitude && refrenceFormValue.longitude) {
          refrenceFormValue.valid = this.refrenceForm.valid;
          refrenceFormValue.address = this.address;
          refrenceFormValue.searchMethod = this.searchMethod;
        }

        if (this.clearClicked) {
          this.clearClicked = false;
          refrenceFormValue.clearClicked = true;
        } else {
          refrenceFormValue.clearClicked = false;
        }
        this.siteDetailsService.referenceFormChange(refrenceFormValue);
      }, 1);
    });
    this.fillDefaultData();
  }


  fillDefaultData(clear?) {
    this.refrenceForm.patchValue({
      siteType: this.formConfig.formName,
      definedPropertyLocation: 'Yes',
      searchMethod: this.refrences[0],
    });
    if (!clear) {
      let formData =
        this.createQuoteService.productDetailsFormValue[
          this.formConfig.formName
        ];
      this.formConfig.formData = formData;

      if (
        this.formConfig &&
        this.formConfig.formData &&
        this.formConfig.formData.selectedAddress &&
        this.formConfig.formData.searchMethod?.includes('refrence-')
      ) {
        this.refrenceForm.patchValue(this.formConfig.formData);
        this.refrenceForm.patchValue({
          searchMethod: this.formConfig.formData.searchMethod.substring(9),
        });
        switch (this.formConfig.formData.searchMethod) {
          case 'refrence-' + this.refrences[0]:
            this.refrenceForm.patchValue({
              rawSearchText:  this.formConfig?.formData?.adborId ,
            });
            break;

          case 'refrence-' + this.refrences[1]:
            this.refrenceForm.patchValue({
              rawSearchText: this.formConfig.formData.nodeCode || this.formConfig.formData.rawSearchText,
            });
            break;

          case 'refrence-' + this.refrences[2]:
            this.refrenceForm.patchValue({
              rawSearchText: this.formConfig.formData.nbnLocId,
            });
            break;

          default:
            break;
        }
        this.searchMethod = this.formConfig.formData.searchMethod;

        if (this.formConfig.formName == 'zEndAdress') {
          this.refrenceForm.patchValue({ refresh: true }, { emitEvent: true });
          this.onrefA2ctivateZend.emit(true);
        }
        if (this.formConfig.formName == 'a2EndAdress') {
          this.refrenceForm.patchValue({ refresh: true }, { emitEvent: true });
          this.onrefZ2ctivateZend.emit(true);
        }

        this.searchRefrence();
      }
    }
  }

  clearClicked = false;
  resetForm() {
    this.clearBtnClicked.emit({
      clearBtnClicked: true,
      formName: this.formConfig.formName,
    });
    if (this.formConfig.formName == 'aEndAdress') {
      this.siteDetailsService.onTabChangeClearData(
        { activeId: 3 },
        { formName: 'zEndAdress' }
      );
    }

    this.formConfig.formData = this.siteDetailsService.onTabChangeClearData(
      { activeId: 3 },
      { formName: this.formConfig.formName }
    );

    this.clearClicked = true;
    this.siteDetailsService.clearClicked = true;
    this.refrenceForm.reset();
    this.fillDefaultData('cls');
    this.searchResults = [];
    if (this.formConfig.activateZendOnSearch) {
      this.onrefActivateZend.emit(false);
    }

    if (
      this.formConfig.activateA2endOnSearch &&
      this.formConfig.formName == 'zEndAdress'
    ) {
      this.onrefA2ctivateZend.emit(false);
    }

    if (
      this.formConfig.activateZ2endOnSearch &&
      this.formConfig.formName == 'a2EndAdress'
    ) {
      this.onrefZ2ctivateZend.emit(false);
    }
  }

  searchRefrence() {

    this.siteDetailsService.clearClicked = false;
    const payloadAndApi = this.siteDetailsService.refrenceSearchApiAndPayload(
      this.refrenceForm.value
    );
    this.searchResults = [];
    if (!payloadAndApi) return;

    let adborIdSelected = 'refrence-ADBoR ID';
    let refNodeCode = 'refrence-Node Code';
    let nbnLocationId = 'refrence-NBN Location ID';

    // For Adbor Id
    if (this.searchMethod.toLowerCase() === adborIdSelected.toLowerCase()) {
      let subAddressArr: any;

      this.api.post(this.env.apiPoint + payloadAndApi.api, payloadAndApi.payload).subscribe(
        (res) => {
          this.searchResults =
            this.siteDetailsService.getSearchResultRefrenceApi(
              this.refrenceForm.value,
              res
            );

          this.showAddressContainer = true;
          this.refrenceForm.patchValue({
            selectedAddress: this.searchResults[0],
          });

          this.primaryAutoComplete = this.formConfig?.formData?.address
            ? this.formConfig.formData.address
            : this.formConfig.formData.selectAddress ||
              this.searchResults[0].primaryAddress;

          this.refrenceForm.patchValue({
            adborId: this.refrenceForm.value.selectedAddress.adborId,
            latitude: this.refrenceForm.value.selectedAddress.latitude,
            longitude: this.refrenceForm.value.selectedAddress.longitude,
            nbnLocId: this.refrenceForm.value.selectedAddress.nbnLocId,
          });
          this.noAddressFound = false;

          // select primary address
          if (this.formConfig?.formData?.adborId === this.searchResults[0].adborId) {
            this.selectAddress(this.searchResults[0]);
          } else {
            // select sub address
            if (this.searchResults[0]?.subaddresses?.length && this.formConfig?.formData?.adborId) {
              subAddressArr = this.searchResults[0]?.subaddresses;
              if (this.formConfig?.formData?.adborId) {
                subAddressArr.forEach((e) => {
                  if (e.adborId == this.formConfig?.formData?.adborId) {
                    this.selectSubAddress(e);
                  }
                });
              }
            }
          }
        },
        (err) => {
          // const msg = err.error?.message ? err.error?.message : 'Something went wrong';
          // this.showToasterMessage(msg, '', this.options, 'error');

          this.showAddressContainer = true;
          this.searchResults = this.siteDetailsService.fillNoDataReference();
          this.refrenceForm.patchValue({
            selectedAddress: this.searchResults[0],
          });
          this.primaryAutoComplete = this.searchResults[0].primaryAddress;
          this.noAddressFound = true;
          // if (this.formConfig.activateZendOnSearch)
          //   this.onSearchActivateZend.emit(true);
        }
      );
    }


    if (this.searchMethod.toLowerCase() === refNodeCode.toLowerCase()) {
      let subAddressArr: any;

      this.api.post(this.env.apiPoint + payloadAndApi.api, payloadAndApi.payload).subscribe(
        (res) => {
          this.searchResults =
            this.siteDetailsService.getSearchResultRefrenceApi(
              this.refrenceForm.value,
              res
            );

          this.showAddressContainer = true;
          this.refrenceForm.patchValue({
            selectedAddress: this.searchResults[0],
          });

          this.primaryAutoComplete = this.formConfig?.formData?.selectedAddress
            ? this.formConfig.formData.selectedAddress
            : this.searchResults[0].primaryAddress;

          this.refrenceForm.patchValue({
            adborId: this.refrenceForm.value.selectedAddress.adborId,
            latitude: this.refrenceForm.value.selectedAddress.latitude,
            longitude: this.refrenceForm.value.selectedAddress.longitude,
            nbnLocId: this.refrenceForm.value.selectedAddress.nbnLocId,
          });
          this.noAddressFound = false;

          // Select address based on nodeCode
          if (this.formConfig?.formData?.nodeCode === this.searchResults[0].nodeCode) {
            this.selectAddress(this.searchResults[0]);
          } else {
            // Select subaddress based on nodeCode
            if (this.searchResults[0]?.subaddresses && this.searchResults[0]?.subaddresses?.length && this.formConfig?.formData?.nodeCode) {
              subAddressArr = this.searchResults[0]?.subaddresses;
              if(this.formConfig?.formData?.nodeCode) {
                subAddressArr.forEach(e => {
                  if(e.nodeCode == this.formConfig?.formData?.nodeCode){
                    this.selectSubAddress(e);
                  }
                });
              }
            }
          }

          // Checkbox flow object fix for node code
          if(this.formConfig?.formData?.address?.nodeCode === this.searchResults[0].nodeCode){
            this.selectAddress(this.searchResults[0]);
          }
        },
        (err) => {
          // const msg = err.error?.message ? err.error?.message : 'Something went wrong';
          // this.showToasterMessage(msg, '', this.options, 'error');

          this.showAddressContainer = true;
          this.searchResults = this.siteDetailsService.fillNoDataReference();
          this.refrenceForm.patchValue({
            selectedAddress: this.searchResults[0],
          });
          this.primaryAutoComplete = this.searchResults[0].primaryAddress;
          this.noAddressFound = true;
          // if (this.formConfig.activateZendOnSearch)
          //   this.onSearchActivateZend.emit(true);
        }
      );
    }


    // For NBN Location ID
    if (this.searchMethod.toLowerCase() === nbnLocationId.toLowerCase()) {
      let subAddressArr: any;

      this.api.post(this.env.apiPoint + payloadAndApi.api, payloadAndApi.payload).subscribe(
        (res) => {
          this.searchResults =
            this.siteDetailsService.getSearchResultRefrenceApi(
              this.refrenceForm.value,
              res
            );

          this.showAddressContainer = true;
          this.refrenceForm.patchValue({
            selectedAddress: this.searchResults[0],
          });

          this.primaryAutoComplete = this.formConfig?.formData?.selectedAddress
            ? this.formConfig.formData.selectedAddress
            : this.searchResults[0].primaryAddress;

          this.refrenceForm.patchValue({
            adborId: this.refrenceForm.value.selectedAddress.adborId,
            latitude: this.refrenceForm.value.selectedAddress.latitude,
            longitude: this.refrenceForm.value.selectedAddress.longitude,
            nbnLocId: this.refrenceForm.value.selectedAddress.nbnLocId,
          });
          this.noAddressFound = false;

          // Select primary address
          if (this.formConfig?.formData?.nbnLocId === this.searchResults[0].nbnLocId) {
            this.selectAddress(this.searchResults[0]);
          } else {
            // Select subaddress
            if (this.searchResults[0]?.subaddresses?.length && this.formConfig?.formData?.nbnLocId) {
              subAddressArr = this.searchResults[0]?.subaddresses;

              if (this.formConfig?.formData?.nbnLocId) {
                subAddressArr.forEach(e => {
                  if (e.nbnLocId == this.formConfig?.formData?.nbnLocId) {
                    this.selectSubAddress(e);
                  }
                });
              }
            }
          }
        },
        (err) => {
          // const msg = err.error?.message ? err.error?.message : 'Something went wrong';
          // this.showToasterMessage(msg, '', this.options, 'error');

          this.showAddressContainer = true;
          this.searchResults = this.siteDetailsService.fillNoDataReference();
          this.refrenceForm.patchValue({
            selectedAddress: this.searchResults[0],
          });
          this.primaryAutoComplete = this.searchResults[0].primaryAddress;
          this.noAddressFound = true;
          // if (this.formConfig.activateZendOnSearch)
          //   this.onSearchActivateZend.emit(true);
        }
      );
    }
}


  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-top-center';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 5000;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
  }

  updateFormValueForSearch(address, preDefinedSiteNode?) {
    this.refrenceForm.patchValue({
      selectedAddress: address,
    });
    this.primaryAutoComplete = address;
    this.preDefinedSiteNode =
      preDefinedSiteNode &&
      preDefinedSiteNode != null &&
      preDefinedSiteNode.toLowerCase() != 'no'
        ? preDefinedSiteNode
        : false;
  }

  searchMethodDropdownChange($event) {
    this.refrenceForm.patchValue(
      {
        latitude: '',
        longitude: '',
        siteType: this.formConfig.formName,
        selectedAddress: '',
        additionalAddressInfo: '',
        definedPropertyLocation: 'Yes',
        rawSearchText: '',
        adborId: '',
        nbnLocId: '',
        address: '',
        refresh: '',
      },
      { emitEvent: true }
    );
    this.searchMethod = 'refrence-' + $event.value.value;
  }

  selectAddress(result) {

    this.onrefLocationRef.emit(result.primaryAddress);

    this.refrenceForm.controls['address'].setValue(result.primaryAddress);
    this.showAddressContainer = !this.showAddressContainer;
    this.address = result;
    const preDefinedSiteNode = result.predefinedSiteNode;
    this.preDefinedSiteNode =
      preDefinedSiteNode &&
      preDefinedSiteNode != null &&
      preDefinedSiteNode.toLowerCase() != 'no'
        ? preDefinedSiteNode
        : false;
    if (this.preDefinedSiteNode) {
      this.refrenceForm.controls['definedPropertyLocation'].setValue(
        preDefinedSiteNode
      );
    }
    if (this.formConfig.activateZendOnSearch) {
      this.onrefActivateZend.emit(true);
    }

    if (
      this.formConfig.activateA2endOnSearch &&
      this.formConfig.formName == 'zEndAdress'
    ) {
      this.onrefA2ctivateZend.emit(true);
    }

    if (
      this.formConfig.activateZ2endOnSearch &&
      this.formConfig.formName == 'a2EndAdress'
    ) {
      this.onrefZ2ctivateZend.emit(true);
    }

    //   if(this.formConfig.activateA2endOnSearch && this.formConfig.formName == "zEndAdress")
    //     this.onSearchA2ctivateZend.emit(true);

    // if(this.formConfig.activateZ2endOnSearch && this.formConfig.formName == "a2EndAdress")
    //     this.onSearchZ2ctivateZend.emit(true);
  }

  openSubAddress() {
    this.showSubAddresses = !this.showSubAddresses;
  }


  selectSubAddress(subAddress) {

      this.onrefLocationRef.emit(subAddress.address);
      this.refrenceForm.controls['address'].setValue(subAddress.address);
      this.address = subAddress;
      this.showAddressContainer = !this.showAddressContainer;
      if (this.formConfig.activateZendOnSearch) {
        this.onrefActivateZend.emit(true);
      }

      if (
        this.formConfig.activateA2endOnSearch &&
        this.formConfig.formName == 'zEndAdress'
      ) {
        this.onrefA2ctivateZend.emit(true);
      }

      if (
        this.formConfig.activateZ2endOnSearch &&
        this.formConfig.formName == 'a2EndAdress'
      ) {
        this.onrefZ2ctivateZend.emit(true);
      }

      const preDefinedSiteNode = subAddress.predefinedSiteNode;
      this.preDefinedSiteNode =
        preDefinedSiteNode &&
        preDefinedSiteNode != null &&
        preDefinedSiteNode.toLowerCase() != 'no'
          ? preDefinedSiteNode
          : false;
      if (this.preDefinedSiteNode) {
        this.refrenceForm.controls['definedPropertyLocation'].setValue(
          preDefinedSiteNode
        );
      }
  }

  toggleToLatLongSearch(id) {
    this.formConfig.activeTab = id;
  }

  ngOnDestroy(): void {
    if (this.siteDetailsService.clearClicked) {
      if (this.formConfig.formName == 'zEndAdress') {
        this.resetForm();
      }

      this.siteDetailsService.clearClicked = false;
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onChangeRadioButton(value){
    this.refrenceForm.controls['definedPropertyLocation'].setValue(value);
  }

  inputChanged(ev) {
    this.refrenceForm.patchValue({
      selectedAddress: '',
    });
    this.primaryAutoComplete = '';
    this.refrenceForm.controls['address'].setValue('');
  }

}
