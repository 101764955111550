<div class="container-fluid1">
  <div class="toastrContainer" aria-live="assertive"  toastContainer></div>
  <form [formGroup]="orderRequestForm" class="row" *ngIf="aemLabelAuthoring">
    <div class="row ms-5 mt-5">
      <div class="col-lg-4 col-md-4 col-sm-5 ms-1 mt-2">
        <main aria-labelledby="orderRequestDetailsHeader">
        <h1 class="page-heading" id="orderRequestDetailsHeader" attr.aria-label="{{aemLabelAuthoring?.label?.pageHeader?.pageHeaderLabel}}"> {{
          aemLabelAuthoring?.label?.pageHeader?.pageHeaderLabel }} </h1></main>
        <div attr.aria-label="{{ aemLabelAuthoring?.label?.pageHeader?.pageSubHeaderLabel }} {{orderDetailsData?.orderReferenceId}}">
          {{ aemLabelAuthoring?.label?.pageHeader?.pageSubHeaderLabel }} <strong>{{orderDetailsData?.orderReferenceId}}
          </strong>
          <span *ngIf="isOrderSaved" class="pl-20 fw-500 color-black"><img src="../../../assets/images/Submitted.svg"
              class="saved-icon" alt="saved icon" />
            {{aemLabelAuthoring?.label?.pageHeader?.saved }}</span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <app-page-stepper [stepperData]="stepperData" [stepName]="stepName"></app-page-stepper>
      </div>
    </div>
    <div>
      <div class="row heading-font mt-3">
        <div class="col-lg-1 col-md-2 col-sm-2 ms-4">
          <img class="mt-3 ms-5" src="../../../../assets/images/Product.svg" alt="product icon" />
        </div>
        <div class="col-lg-2 col-md-3 col-sm-3 product-text pt-3 ms-5">
          {{aemLabelAuthoring?.label?.product}}
          <div class="prod-name details-font"> {{orderDetailsData?.productName}}</div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-4 pt-3">
          <span class="requirement-text" attr.aria-label="{{aemLabelAuthoring?.label?.fibreRequirement}}">{{aemLabelAuthoring?.label?.fibreRequirement}}</span>
          <div class="prod-name details-font">{{orderDetailsData?.linkRequirement}} </div>
        </div>
      </div>
    </div>
    <div class="top-heading ms-3">
      <img class="ms-5" src="../../../../assets/images/Details-Doc.svg" alt="product icon" />
      <h3 class="ms-3 reqDetails-font" attr.aria-label="{{aemLabelAuthoring?.label?.requestDetails}}">{{aemLabelAuthoring?.label?.requestDetails}}</h3>
      <span class="accLabel mt-4">Fields marked with <span class="star">*</span> are mandatory</span>
    </div>




    <div class="row ms-5 heading-font">
      <hr>
      <div class="col-6 text-bold-18" attr.aria-label="{{aemLabelAuthoring?.label?.customerContactDetails}}">{{aemLabelAuthoring?.label?.customerContactDetails}}</div>
    </div>
    <div class="row col-12 ms-5 mt-4 head-values" aria-label="Customer Contact details">

      <div class="col-4 pl-0">
        <label class="required-field" for="contactName" aria-required="true" required>
          {{aemLabelAuthoring?.label?.contactName}}
        </label>
        <input (paste)="onPasteCheckNumber($event)" (keydown)="onNumericKeyPressDown($event)" tabindex="0" type="text" class="form-control text-box mb-1" maxlength="21" id="contactName"
          name="contactName" [placeholder]="aemLabelAuthoring?.placeholder?.contactName"
          attr.aria-label="{{aemLabelAuthoring?.label?.contactName}}" aria-required="true" aria-describedby="contactNameErrMsg"
          formControlName="contactName"
          [ngClass]="{'invalid-form-control': ((orderRequestForm.controls.contactName.touched || orderRequestForm.controls.contactName.dirty) && orderRequestForm.controls.contactName.invalid)}">

        <ng-container
          *ngIf="orderRequestForm.controls.contactName.invalid && (orderRequestForm.controls.contactName.dirty || orderRequestForm.controls.contactName.touched)">
          <small *ngIf="orderRequestForm.controls.contactName.errors.required" class="text-danger errorMsg" id="contactNameErrMsg" >
            <img class="error-icon"
              src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.validationMsg?.contactNameReqMsg}}
          </small>
          <small
            *ngIf="!orderRequestForm.controls.contactName.errors.required && orderRequestForm.controls.contactName.invalid" id="contactNameErrMsg"
            class="text-danger errorMsg">
            <img class="error-icon"
              src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.validationMsg?.contactNameMsg}}
          </small>
        </ng-container>
      </div>

      <div class="col-4">
        <label class="required-field" aria-label="Contact Number" for="contactNumber" aria-required="true"
          required>{{aemLabelAuthoring?.label?.contactNumber}} </label>
        <input tabindex="0" type="text" aria-required="true" class="form-control text-box mb-1" maxlength="10"
          id="contactNumber" name="contactNumber" [placeholder]="aemLabelAuthoring?.placeholder?.contactNumber"
          attr.aria-label="{{aemLabelAuthoring?.label?.contactNumber}}" aria-describedby="contactNumErrMsg" formControlName="contactNumber"
          (keypress)="numberOnly($event)"
          [ngClass]="{'invalid-form-control': (((orderRequestForm.controls['contactNumber'].value?.length > 0 && orderRequestForm.controls['contactNumber'].value?.length < 10)) || orderRequestForm.controls['contactNumber'].value?.length > 10) || (!orderRequestForm.controls['contactNumber'].value?.length && orderRequestForm.controls['contactNumber'].errors.required && (orderRequestForm.controls['contactNumber'].dirty || orderRequestForm.controls['contactNumber'].touched))}">

        <ng-container *ngIf="orderRequestForm.controls['contactNumber'].invalid || (orderRequestForm.controls['contactNumber'].dirty || orderRequestForm.controls['contactNumber'].touched)">
          <small
            *ngIf="!orderRequestForm.controls['contactNumber'].value?.length && orderRequestForm.controls['contactNumber'].errors.required && (orderRequestForm.controls['contactNumber'].dirty || orderRequestForm.controls['contactNumber'].touched)"
            class="text-danger errorMsg"  id="contactNumErrMsg" >
            <img class="error-icon" src="../../../../assets/images/icon-error.svg" /> {{aemLabelAuthoring?.validationMsg?.contactNumberReqMsg}}
          </small>
          <small  *ngIf="((orderRequestForm.controls['contactNumber'].value?.length > 0 && orderRequestForm.controls['contactNumber'].value?.length < 10)) || orderRequestForm.controls['contactNumber'].value?.length > 10"
            class="text-danger errorMsg" id="contactNumErrMsg" >
            <img class="error-icon" src="../../../../assets/images/icon-error.svg" />  {{aemLabelAuthoring?.validationMsg?.contactNumberMsg}}
            </small>
        </ng-container>
      </div>



      <div class="col-4 mb-5">
        <label class=" text-bold-18  required-field" aria-label="Contact Email Address" for="contactEmail" aria-required="true"
          required> {{aemLabelAuthoring?.label?.contactEmail}} </label>
        <input tabindex="0" type="email" aria-required="true" class="form-control text-box mb-1" maxlength="150"
          id="contactEmail" name="contactEmail" [placeholder]="aemLabelAuthoring?.placeholder?.contactEmail"
          attr.aria-label="{{aemLabelAuthoring?.label?.contactEmail}}"  aria-describedby="contactEmailErrMsg"  formControlName="contactEmail"
          [ngClass]="{'invalid-form-control': ((orderRequestForm.controls.contactEmail.touched || orderRequestForm.controls.contactEmail.dirty) && orderRequestForm.controls.contactEmail.invalid)}">
        <ng-container
          *ngIf="orderRequestForm.controls.contactEmail.invalid && (orderRequestForm.controls.contactEmail.dirty || orderRequestForm.controls.contactEmail.touched)">
          <small *ngIf="orderRequestForm.controls.contactEmail.errors.required" class="text-danger errorMsg" id="contactEmailErrMsg" >
            <img class="error-icon"
              src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.validationMsg?.contactEmailReqMsg}}
          </small>
          <small
            *ngIf="!orderRequestForm.controls.contactEmail.errors.required && orderRequestForm.controls.contactEmail.invalid"
            class="text-danger errorMsg" id="contactEmailErrMsg" >
            <img class="error-icon"
              src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.validationMsg?.contactEmailMsg}}
          </small>
        </ng-container>
      </div>
      <hr>
    </div>
    <div class="row ms-5 heading-font">
      <div class="col-6 text-bold-18" attr.aria-label="{{aemLabelAuthoring?.label?.references}}">{{aemLabelAuthoring?.label?.references}}</div>
    </div>
    <div class="row col-12 ms-5 head-values text-values" aria-label="references">
      <div class="col-4 pl-0 mt-4">
        <label class="user-details-label-top" for="customerReference" >
          {{aemLabelAuthoring?.label?.customerReference}}
        </label>
        <input type="text" class="form-control text-box mb-1"
        attr.aria-label="{{aemLabelAuthoring?.label?.customerReference}}" [placeholder]="aemLabelAuthoring?.placeholder?.customerReference" formControlName="customerReference"
          maxlength="10"
          [ngClass]="{'invalid-form-control': ((orderRequestForm.controls.customerReference.touched || orderRequestForm.controls.customerReference.dirty) && orderRequestForm.controls.customerReference.invalid)}">
        <ng-container
          *ngIf="orderRequestForm.controls.customerReference.invalid && (orderRequestForm.controls.customerReference.dirty || orderRequestForm.controls.customerReference.touched)">
          <small *ngIf="orderRequestForm.controls.customerReference.errors.pattern" class="text-danger errorMsg">
            <img class="error-icon" alt="error icon"
              src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.validationMsg?.inputValidationMsg}}
          </small>

          <small *ngIf="orderRequestForm.controls.customerReference.errors.maxlength" class="text-danger errorMsg">
            <img class="error-icon" alt="error icon"
              src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.validationMsg?.customerRefernceLenthMsg}}
          </small>
        </ng-container>
      </div>

      <div class="col-lg-4 col-sm-4 mt-4">
        <label class="user-details-label-top required-field"
          for="custRequestedDate" aria-required="true" required>{{aemLabelAuthoring?.label?.customerReqDate}}</label>
        <div class="input-group col-lg-4 col-md-3 col-sm-4 request-datepicker" aria-required="true" aria-describedby="customReqDtErr" (change)="openCalendar($event)" >
          <app-cui-datepicker  aria-label="Customer requested date" [datePlaceholder]="'dd/mm/yyyy'" 
          [modelDate]="serviceDatePicker" [datePickerId]="custRequestedDate" [navigation]="false"
          [model]="custRequestedDate" (dateSelected)="onChangeServiceDate($event)" aria-required="true"
          [accessibleHeader]="'Customer requested date required'" [parentFormGroup]="orderRequestForm" class="datepicker_core mb-1"
          [minDate]="fromDate" [maxDate]="toMaxDate" [isManualEntry] = "false"  (keydown)="openCalendar($event)"
          [ngClass]="{'invalid-form-control': (orderRequestForm?.controls?.custRequestedDate?.touched && orderRequestForm?.controls?.custRequestedDate?.invalid)}"  aria-describedby="custDateErrMsg">
        </app-cui-datepicker>

        </div>
        <ng-container
          *ngIf="orderRequestForm.controls.custRequestedDate.invalid && (orderRequestForm.controls.custRequestedDate.dirty || orderRequestForm.controls.custRequestedDate.touched)">
          <small *ngIf="orderRequestForm.controls.custRequestedDate.errors.required" class="text-danger errorMsg" role="alert" id="customReqDtErr">
            <img class="error-icon" alt="error icon"
              src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.validationMsg?.custRequestedDateReqMsg}}
          </small>
          <small
            *ngIf="!orderRequestForm.controls.custRequestedDate.errors.required && orderRequestForm.controls.custRequestedDate.invalid" role="alert"
            class="text-danger errorMsg"  id="customReqDtErr">
            <img class="error-icon" alt="error icon"
              src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.validationMsg?.custRequestedDateMsg}}
          </small>
        </ng-container>
      </div>


      <div class="col-4 mt-4" *ngIf="orderDetailsData?.serviceType==='RG'">
        <label class=" text-bold-18  required-field" aria-label="Project ID" for="projectID" aria-required="true"
          required>{{aemLabelAuthoring?.label?.projectID}}</label>
          <div aria-required="true" attr.aria-label="{{aemLabelAuthoring?.label?.projectID}}" aria-describedby="projectIdErrMsg">
        <app-cui-searchable-dropdown name="projectID" [model]="projectIDDropDownModel"
          [parentFormGroup]="orderRequestForm" [labelName]="'projectID'" (click)="selectProjectID()"
          [placeholder]="aemLabelAuthoring?.placeholder?.projectID"  [formControlName]="'projectID'" [isRequired]=true
          [items]="orderDetailsData?.projectIDLOVs | orderBy:'value'" automationType="dropdown" automationId="projectID"
          [noAutoComplete]=false id="projectID" class="customer-dropDown mb-1"
          [ngClass]="{'invalid-form-control': (orderRequestForm.controls.projectID.invalid && orderRequestForm.controls.projectID.touched)}"
          required>
        </app-cui-searchable-dropdown>
      </div>
        <ng-container *ngIf="showAlertProjectID  && orderRequestForm?.controls?.projectID?.invalid">
          <small class="text-danger errorMsg" id="projectIdErrMsg" >
            <img class="error-icon" alt="error icon"
              src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.validationMsg?.projectIDReqMsg}}
          </small>
        </ng-container>
      </div>

      <div *ngIf="orderDetailsData?.serviceType==='RG'" class="col-4 pl-0 mt-4">
        <label class="user-details-label-top" for="opportunityNo" >
          {{aemLabelAuthoring?.label?.opportunityNo}}
        </label>
        <input type="text" class="form-control text-box mb-1" attr.aria-label="{{aemLabelAuthoring?.label?.opportunityNo}}"
          [placeholder]="aemLabelAuthoring?.placeholder?.opportunityNo" formControlName="opportunityNo" maxlength="64"
          [ngClass]="{'invalid-form-control': ((orderRequestForm.controls.opportunityNo.touched || orderRequestForm.controls.opportunityNo.dirty) && orderRequestForm.controls.opportunityNo.invalid)}">
        <ng-container
          *ngIf="orderRequestForm.controls.opportunityNo.invalid && (orderRequestForm.controls.opportunityNo.dirty || orderRequestForm.controls.opportunityNo.touched)">
          <small
            *ngIf="!orderRequestForm.controls.opportunityNo.errors.required && orderRequestForm.controls.opportunityNo.invalid"
            class="text-danger errorMsg">
            <img class="error-icon" alt="error icon"
              src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.validationMsg?.inputValidationMsg}}
          </small>
        </ng-container>
      </div>


      <div *ngIf="(orderDetailsData?.serviceType==='RG') && (orderDetailsData?.businessDriver==='5G Small Cell')"
        class="col-4 pl-0 mt-4">
        <label class="user-details-label-top" aria-label="Cluster ID" for="clusterID" >
          {{aemLabelAuthoring?.label?.clusterID}}
        </label>
        <input type="text" class="form-control text-box mb-1" attr.aria-label="{{aemLabelAuthoring?.label?.clusterID}}" [placeholder]="aemLabelAuthoring?.placeholder?.clusterID"
          formControlName="clusterID" maxlength="64"
          [ngClass]="{'invalid-form-control': ((orderRequestForm.controls.clusterID.touched || orderRequestForm.controls.clusterID.dirty) && orderRequestForm.controls.clusterID.invalid)}">
        <ng-container
          *ngIf="orderRequestForm.controls.clusterID.invalid && (orderRequestForm.controls.clusterID.dirty || orderRequestForm.controls.clusterID.touched)">
          <small
            *ngIf="!orderRequestForm.controls.clusterID.errors.required && orderRequestForm.controls.clusterID.invalid"
            class="text-danger errorMsg">
            <img class="error-icon" alt="error icon"
              src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.validationMsg?.inputValidationMsg}}
          </small>
        </ng-container>
      </div>

      <div *ngIf="(orderDetailsData?.serviceType==='RG') && (orderDetailsData?.businessDriver==='5G Small Cell')"
        class="col-4 mt-4">
        <label class=" text-bold-18" aria-label="mirage ID" for="mirageID"
          >{{aemLabelAuthoring?.label?.mirageID}} </label>
        <input tabindex="0" type="text" class="form-control text-box mb-1" maxlength="10"
          id="mirageID" name="mirageID" [placeholder]="aemLabelAuthoring?.placeholder?.mirageID"
          attr.aria-label="{{aemLabelAuthoring?.label?.mirageID}}" formControlName="mirageID"
          [ngClass]="{'invalid-form-control': ((orderRequestForm.controls.mirageID.touched || orderRequestForm.controls.mirageID.dirty) && orderRequestForm.controls.mirageID.invalid)}">
        <ng-container
          *ngIf="orderRequestForm.controls.mirageID.invalid && (orderRequestForm.controls.mirageID.dirty || orderRequestForm.controls.mirageID.touched)">
          <small
            *ngIf="!orderRequestForm.controls.mirageID.errors.required && orderRequestForm.controls.mirageID.invalid"
            class="text-danger errorMsg">
            <img class="error-icon" alt="error icon"
              src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.validationMsg?.inputValidationMsg}}
          </small>
        </ng-container>
      </div>
    </div>


    <div class="row head-values mt-5 ref-border" *ngIf="orderDetailsData?.serviceType==='RG'">
      <div class="col-4 text-bold-18 mt-4">{{aemLabelAuthoring?.label?.externalReference}}</div>
      <div class="col-4 text-bold-18 mt-4"><label>{{aemLabelAuthoring?.label?.value}}</label>
      </div>
      <div class="row  details-font">
        <div class="col-4 text-bold-18" attr.aria-label="{{aemLabelAuthoring?.label?.externalReference}}">
          <app-cui-searchable-dropdown name="customerType" [model]="externalReferenceDropDownModel" id="type"
            [labelName]="'externalReferenceDropDown'" [placeholder]="aemLabelAuthoring?.placeholder?.externalReference"
            formControlName="customerType1" [items]=orderDetailsData.externalReferenceLOVs automationType="dropdown"
            automationId="drpdwnCustomerType" [noAutoComplete]="false" id="customerType" class="customer-dropDown">
          </app-cui-searchable-dropdown>
        </div>
        <div class="w-35 text-bold-18 ms-1">

          <input type="text" maxlength="64" class="form-control text-box" attr.aria-label="{{aemLabelAuthoring?.label?.value}}"
            [placeholder]="aemLabelAuthoring?.placeholder?.externalValue" id="externalReference1"
            formControlName="externalReference1" name="externalReference1"
            [ngClass]="{'invalid-form-control': ((orderRequestForm.controls.externalReference1.touched || orderRequestForm.controls.externalReference1.dirty) && orderRequestForm.controls.externalReference1.invalid)}">

          <ng-container
            *ngIf="orderRequestForm.controls.externalReference1.invalid && (orderRequestForm.controls.externalReference1.dirty || orderRequestForm.controls.externalReference1.touched)">
            <small
              *ngIf="!orderRequestForm.controls.externalReference1.errors.required && orderRequestForm.controls.externalReference1.invalid"
              class="text-danger errorMsg d-block">
              <img class="error-icon" alt="error icon"
                src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.validationMsg?.inputValidationMsg}}
            </small>
          </ng-container>
        </div>

        <ng-container *ngFor="let externalRefernceForm of externalRefernces?.controls; let i = index;"
          formArrayName="externalRefernces">
          <div class="head-values" *ngIf="externalRefernces?.controls" [formGroup]="externalRefernceForm">
            <div class="row">
              <div class="col-4 text-bold-18 mt-4 ms-1">{{aemLabelAuthoring?.label?.externalReference}}
              </div>
              <div class="col-4 text-bold-18 mt-4 ms-1"><label>{{aemLabelAuthoring?.label?.value}}</label>
              </div>
            </div>
            <div class="row details-font">
              <div class="col-4 text-bold-18 external-ref" #tref tabindex="-1">
                <app-cui-searchable-dropdown name="externalReferenceType" [model]="externalReferenceDropDownModel"
                  id="type" [labelName]="'externalReferenceDropDown'"
                  [placeholder]="aemLabelAuthoring?.placeholder?.externalReference"
                  formControlName="externalReferenceType" [items]=orderDetailsData.externalReferenceLOVs
                  automationType="dropdown" automationId="drpdwnCustomerType" [noAutoComplete]=false id="customerType"
                  class="customer-dropDown">
                </app-cui-searchable-dropdown>
              </div>
              <div class="col-4 w-35 text-bold-18 ms-1">
                <input type="text" class="form-control text-box" maxlength="64"
                  [placeholder]="aemLabelAuthoring?.placeholder?.externalValue" formControlName="externalReferenceValue"
                  [ngClass]="{'invalid-form-control': ((externalRefernceForm.controls.externalReferenceValue.touched || externalRefernceForm.controls.externalReferenceValue.dirty) && externalRefernceForm.controls.externalReferenceValue.invalid)}">
                <ng-container
                  *ngIf="externalRefernceForm.controls['externalReferenceValue'].invalid && (externalRefernceForm.controls['externalReferenceValue'].dirty || externalRefernceForm.controls['externalReferenceValue'].touched)">
                  <small
                    *ngIf="!externalRefernceForm.controls['externalReferenceValue'].errors.required && externalRefernceForm.controls['extReferenceValuey'].invalid"
                    class="text-danger errorMsg d-block">
                    <img class="error-icon" src="../../../../assets/images/icon-error.svg" /> The
                    following special
                    characters [<>;] are unable to be used, please revise your input
                  </small>
                </ng-container>
              </div>
              <div class="col-2"> <button (keypress)="deleteExternalRefernce(i)" role="button"
                attr.aria-label="Remove external reference {{externalRefernceForm.controls['externalReferenceType'].value}}"
                  (click)="deleteExternalRefernce(i)" class="removeBtn"><img alt=""
                    src="../../../../assets/images/Remove.svg">
                  <label class="ms-2 remove-font">Remove</label>
                </button>
              </div>
            </div>
          </div>
        </ng-container>




        <div class="addBtnReview btnRow ">
          <button mat-button class="app-btn-green add-btn ml-0" role="button" (click)="addExternalRefernce()">
            <div class="btn-svg"><img src="../../../../assets/images/Icon-Add-btn-active.svg" alt=""></div>
            <label class="label-space">Add</label>
          </button>
        </div>
      </div>
    </div>
    <div class="row ms-5 mt-2 head-values">
      <div class="col-6 text-bold-18 mt-4 ms-2">{{aemLabelAuthoring?.label?.additionalInfo}}</div>
    </div>
    <div class="form-group row col-8 ms-5 details-font ">
      <textarea class="form-control textarea ms-3" rows="6" id="additionalinfo" maxlength="255"
        formControlName="additionalinfo" name="additionalinfo"
        attr.aria-label="{{aemLabelAuthoring?.label?.additionalInfo}}"
        (keypress)="onAdditionalInfoKeyPress($event, orderRequestForm?.value?.additionalinfo?.length)"
        [placeholder]="aemLabelAuthoring?.placeholder?.additionalinfo"
        [ngClass]="{'invalid-form-control': ((orderRequestForm.controls.additionalinfo.touched || orderRequestForm.controls.additionalinfo.dirty) && orderRequestForm.controls.additionalinfo.invalid)}"
        aria-describedby="additionalInfoErrMsg" attr.aria-live="(orderRequestForm.controls.additionalinfo.touched || orderRequestForm.controls.additionalinfo.dirty) && orderRequestForm.controls.additionalinfo.invalid)?'assertive':'off'"></textarea>
      <div class="text-right ms-4">
        <span class="text-right-blue">{{orderRequestForm.get('additionalinfo')?.value?.length}}/255</span>
      </div>
      <ng-container
        *ngIf="orderRequestForm.controls.additionalinfo.invalid && (orderRequestForm.controls.additionalinfo.dirty || orderRequestForm.controls.additionalinfo.touched)">
        <small
          *ngIf="!orderRequestForm.controls.additionalinfo.errors.required && orderRequestForm.controls.additionalinfo.invalid"
          class="text-danger errorMsg" id="additionalInfoErrMsg">
          <img class="error-icon" alt="error icon"
            src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.validationMsg?.inputValidationMsg}}
        </small>
      </ng-container>
    </div>
    <div class="row ms-5">
      <div class="col-12 ms-2">
        <div class="submitCancelBtnReview">
          <button mat-button (click)="back()" class="back-btn cancelBtn " role="button">
            <span class="cancel-btn">
              <div class="btn-svg back-icon"></div>
              {{ aemLabelAuthoring?.label?.button.back }}
            </span>
          </button>
          <button mat-button role="button" (click)="cancel()" class="cancelBtn me-2">
            <i class="icon-icon-ui-cross"></i>{{ aemLabelAuthoring?.label?.button?.cancel }}</button>
          <button role="button" class="app-btn-green app-btn-white me-3" (click)="saveOrderDetails()">
            <div class="btn-svg save-icon"></div>
            {{ aemLabelAuthoring?.label?.button?.save }}
          </button>
          <label role="alert"  *ngIf="isOrderSavedStatus" style="position:absolute;left:-9999px">Successfully saved</label>
          <button mat-button role="button" class="app-btn-green me-3" [disabled]="orderRequestForm.invalid"
            (click)="review()">
            {{ aemLabelAuthoring?.label?.button?.review }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
