import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DuctAccessService } from '../duct-access.service';
// import { UploadDocumentPageData } from '../attachment-requirement/attachment-requirement.model';
import { SuccessModalWithCopyLinkService } from '../../cabinets/upload-cabinets-document/success-modal-with-copy-link/success-modal-with-copy-link.service';
import { SuccessModalWithCopyLinkComponent } from '../../cabinets/upload-cabinets-document/success-modal-with-copy-link/success-modal-with-copy-link.component';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { UtilityService } from '../../shared/services/utility.service';

@Component({
  selector: 'app-attachment-requirement',
  templateUrl: './attachment-requirement.component.html',
  styleUrls: ['./attachment-requirement.component.less']
})
export class AttachmentRequirementComponent implements OnInit {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;

  isFormSubmitted: boolean;
  options: GlobalConfig
  termsAndConditionsDisplay: boolean = false;

  thirdFormGroup: FormGroup;

  documentTitle: string;
  public aemLabelAuthoring: any;
  uploadedFile:{
    canShowProgressBar: boolean,
    isUploadDone: boolean
  } = {
    canShowProgressBar: false,
    isUploadDone: false
  };


  allowedExtensions: Array<String> = [
    "DOC",
    "DOCX",
    "PDF",
    "JPEG",
    "JPG",
    "ZIP",
    "PNG",
    "XLS",
    "XLSX"   
  ];

  constructor(private ductService: DuctAccessService,
    private router: Router, private activeRoute: ActivatedRoute, private fb: FormBuilder, private rootFormGroup : FormGroupDirective, private SuccessModalService: SuccessModalWithCopyLinkService, private toastr: ToastrService, private utility: UtilityService) { }

  ngOnInit(): void {
    this.getLabels();

    this.thirdFormGroup = this.rootFormGroup.control.get('thirdForm') as FormGroup;
    const elements = document.getElementsByClassName('field-input');
    window.onkeyup = function (event) {
      if (event.keyCode === 9) {
      for(let i=0; i< elements.length; i++) {
        const inputElement = elements[i];
        inputElement.classList.remove("focused")
      }
      const activeElement = document.activeElement;
      if(activeElement && activeElement.classList.contains('field-input')) {
        activeElement.classList.add("focused")
      }  
    }
    }

  }

  @HostListener('document:keydown.escape', ['$event'])
  handleEscapeKey(event: KeyboardEvent) {
    this.termsAndConditionsDisplay=false;
  }
  uploadFile(event:any) :void {
    this.uploadedFile.canShowProgressBar = true;
    this.uploadedFile.isUploadDone = false;
    const regex = new RegExp('((\\\\)|(/)|(:)|(;)|(\\*)|(\\?)|(")|(<)|(>)|(\\|)|(#))',"g");
    if (event.item) {
      this.thirdFormGroup.get('file').setValue(event);
    } else if (event.files) {
      this.thirdFormGroup.get('file').setValue(event.files);
    }
    let fileArr:any[] = Array.from(this.thirdFormGroup.get('file').value);
    this.thirdFormGroup.get('fileName').setValue(fileArr[0].name);
    const fileExtension = fileArr[0].name.split('.').pop().toUpperCase();
    const fileSize = Math.round(fileArr[0].size*0.00000095367432);
    if ((<any>this.allowedExtensions).indexOf(fileExtension) === -1) {
      this.thirdFormGroup.get('validationError').setValue('The upload of your attachment was unsuccessful. Please try again using one of the acceptable file types and if the issue persists please contact Telstra - InfraCo support team.');
    } else if (fileSize > 20) {
      this.thirdFormGroup.get('validationError').setValue('The upload of your attachment was unsuccessful. Please try again using a file 20MB or smaller and if the issue persists please contact Telstra - InfraCo support team.');
    } else if (this.thirdFormGroup.get('fileName').value.length > 100) {
      this.thirdFormGroup.get('validationError').setValue('The upload of your attachment was unsuccessful. Please try again using a file name of length 100 or smaller and if the issue persists please contact Telstra - InfraCo support team.');
    } else if (this.thirdFormGroup.get('fileName').value.match(regex)) {
      this.thirdFormGroup.get('validationError').setValue('The upload of your attachment was unsuccessful. Please try again using a file name without \\ / : ; * ? “ < > | # and if the issue persists please contact Telstra - InfraCo support team.');
    } else {
      this.thirdFormGroup.get('validationError').setValue('');
    }
    setTimeout(()=>{
      this.uploadedFile.isUploadDone = true; 
    }, 500);
  }

  deleteAttachment() :void {
    this.uploadedFile = {
      canShowProgressBar: false,
      isUploadDone: false
    };
    this.thirdFormGroup.get('file').reset();
    this.thirdFormGroup.get('fileName').reset();
    this.thirdFormGroup.get('validationError').reset();
    this.documentTitle = '';
  }

  
  getLabels() {
    this.ductService.getDuctFormData().then((data: any) => {
      this.aemLabelAuthoring = data;
    }).catch((error) => {
    });
  }

  updateSaciValidators(){
    
    if(this.thirdFormGroup.controls.saci.value){
      this.addRequiredValidatorSaci('saciCompany');
      this.addRequiredValidatorSaci('contactNameSaci');
      this.addRequiredValidatorSaci('contactNumberSaci',true);
    }
    else{
      this.removeRequiredValidatorSaci('saciCompany');
      this.removeRequiredValidatorSaci('contactNameSaci');
      this.removeRequiredValidatorSaci('contactNumberSaci');
    }

  }

  addRequiredValidatorSaci(id: any,numberValidation?: boolean){
    if(numberValidation){
      this.thirdFormGroup['controls'][id].setValidators([Validators.required,Validators.pattern(this.utility.getNumberRegEx())]);
    } else {
      this.thirdFormGroup['controls'][id].setValidators([Validators.required]);
    }
    this.thirdFormGroup['controls'][id].updateValueAndValidity();
  }

  removeRequiredValidatorSaci(id: any){
    this.thirdFormGroup['controls'][id].clearValidators();
    this.thirdFormGroup['controls'][id].updateValueAndValidity();
    this.thirdFormGroup['controls'][id].setValue('');
  }

  termsAndConditions(status: boolean) {
    this.termsAndConditionsDisplay = status;
  }

}
