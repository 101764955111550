import { Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { MyOrdersService } from 'src/app/order-managemnt/my-orders.service';
import { myQuotesService } from 'src/app/quote-management/my-quotes/myquotes.service';
import { BulkQuoteService } from '../bulk-quote.service';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { ValidationErrors } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { HttpService } from 'src/app/shared/services/http.service';

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.less', '../bulk-request-details/bulk-request-details.component.less']
})
export class BulkUploadComponent implements OnInit {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  stepperData = ['Request details', 'Upload and Submit'];
  stepName = 'Upload and Submit';
  aemLabelAuthoring: any;
  cidn: any;
  product: any;
  quoteID: any;
  sysID: any;
  userTimeZone: string;
  isTermsCondition: boolean;
  tAndcText;
  error: string;
  file: any;
  overWriteFile: any;
  uploadProgress: number;
  uploadSubscription: any;
  quotesData: any;
  isFileUploaded: boolean = false;
  isSubmitDisabled: boolean = true;
  bulkQuoteID: string;
  errorCsv: any;
  options: GlobalConfig;
  public customerChange: Subscription;
  isModifyBulkQuote: any;
  serviceType: string;
  holidayDates: any;

  constructor(
    private dataSvc: myQuotesService,
    private route: ActivatedRoute,
    @Inject(LOCALE_ID) private locale: string,
    private myOrderService: MyOrdersService,
    private bulkQuoteService: BulkQuoteService,
    private http: HttpClient,
    private toastr: ToastrService,
    private commonService: CommonService,
    private router: Router,
    private titleService: Title,
    private apiService: HttpService
  ) {
    this.titleService.setTitle('InfraCo Customer Portal - Bulk Upload and Submit page');
    this.bulkQuoteID = this.route.snapshot.paramMap.get('id');
    this.options = this.toastr.toastrConfig;
    this.getUserTimeZone();
    this.customerChange = this.commonService.getCustomerName().subscribe((data) => {
      this.dataSvc.customerNameDropdownChange(false, data);
    });
  }

  ngOnInit(): void {
    this.cidn = localStorage.getItem('selectedHeaderCIDN');
    this.sysID = localStorage.getItem('snowID');
    if (this.sysID) {
      this.sysID = JSON.parse(JSON.stringify(this.sysID));
    }
    this.isModifyBulkQuote = window.history.state.isModifyBulkQuote;
    this.holidayDates = window.history.state.holidayDates;
    this.getLabels();
    this.getDetails();

  }

  openTermsAndCondition() {
    this.dataSvc.getTandCforOrder(this.cidn, this.product).then(res => {
      this.isTermsCondition = true;
      this.tAndcText = res;
    })
  }

  onClickClose(event) {
    this.isTermsCondition = false;
  }

  getLabels() {
    this.bulkQuoteService
      .getBulkUploadPageLabel()
      .then((data: any) => {
        this.aemLabelAuthoring = data;
      })
      .catch((error) => { });
  }



  getDetails() {
    this.bulkQuoteService.getBulkRequestDetails(this.cidn, this.userTimeZone, this.bulkQuoteID, this.sysID,).then((res) => {
      this.product = res?.data?.productName;
      this.serviceType = res?.data?.serviceType;
    })
  }


  getUserTimeZone() {
    const userTimeZone = Intl.DateTimeFormat(this.locale, {
      timeZoneName: 'long'
    }).resolvedOptions().timeZone;

    this.userTimeZone = userTimeZone;
  }

  onFileChange(event: any) {
    this.file = event.target.files[0];
    this.validateFile(this.file);
  }

  onFileDrop(event: any) {
    event.preventDefault();
    this.error = null;
    this.isFileUploaded = false;
    this.uploadProgress = 0;
    this.isSubmitDisabled = true;
    this.file = null;
    if (event.dataTransfer.files.length > 1) {
      this.error = this.aemLabelAuthoring.validationMsg.multipleFilesDropMsg;
      return;
    }
    this.file = event.dataTransfer.files[0];
    this.validateFile(this.file);
  }

  validateFile(file: File) {
    this.error = null;
    this.isFileUploaded = false;
    this.uploadProgress = 0;
    this.isSubmitDisabled = true;
    if (!file.type.match(/text\/csv/)) {
      this.error = this.aemLabelAuthoring.validationMsg.fileTypeNotAllowed;
    }
    if (file.size > 10 * 1024 * 1024) {
      this.error = this.aemLabelAuthoring.validationMsg.maxAllowFileSize;

    }
  }

  deleteAttachment() {
    this.file = null;
    this.error = null;
    this.isSubmitDisabled = true;
    this.uploadProgress = 0;
    if (this.uploadSubscription) {
      this.uploadSubscription.unsubscribe();
    }
  }

  uploadFile() {
    const formData = new FormData();
    formData.append('file', this.file);
    formData.append('holidayDates', new Blob([JSON.stringify(this.holidayDates)], {
      type: "application/json"
    }));

    const url = this.bulkQuoteService.env.apiPoint + this.bulkQuoteService.aemDefaultUri.fileUpload + this.bulkQuoteID + '?cidn=' + this.cidn
    this.uploadSubscription = this.http.post(url,  formData, {
      headers: this.apiService.getHeaders().headers,
      reportProgress: true,
      observe: 'events',
    })
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            this.uploadProgress = Math.round(100 * event.loaded / event.total) - 10;
            break;
          case HttpEventType.Response:
            this.isFileUploaded = true;
            // this.isFileUploaded = event?.body?.data?.quotes?.length > 0 ? true : false;
            this.quotesData = event.body?.data;
            this.errorCsv = event.body.data.file;
            this.isSubmitDisabled = event.body.data.file ? true : false;
            if (this.errorCsv) {
              const msg = this.aemLabelAuthoring.validationMsg.errorCsvToasterMsg;
              this.showToasterMessage(msg, '', this.options, 'error');
            }
            break;
        }
      }, err => {
        if (err.status == 500) {
          this.error = err.error.message;
        } else {
          this.error = this.aemLabelAuthoring.validationMsg.uploadFailed;
        }
        this.uploadProgress = 0;
        this.isFileUploaded = false;
      });
  }

  downloadTemplate() {
    this.bulkQuoteService.downloadTemplate(this.cidn, this.serviceType);
  }

  downloadErrorCsv() {
    this.bulkQuoteService.downloadErrorCsv(this.errorCsv);
  }

  cancel() {
    this.router.navigate(['/myQuotes']);
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-top-center';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 5000;
    this.options.closeButton = false;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    if (document.getElementsByClassName('overlay-container') && document.getElementsByClassName('overlay-container').length) {
      document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
      document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
    }
  }
  ngOnDestroy(): void {
    if (this.customerChange) {
      this.customerChange.unsubscribe();
    }
  }
}
