<ng-container *ngIf="showContentFlag">
    <div class="container-fluid">
        <div class="view-container main-page-container-cls">
            <app-header></app-header>
            <router-outlet role="main"></router-outlet>
            <app-footer></app-footer>
        </div>
    </div>
</ng-container>

<div role="alert" aria-live="assertive" aria-atomic="true">
<ngx-ui-loader role="alert" [fgsTemplate]="foregroundSpinner"></ngx-ui-loader>
</div>

<ng-template #foregroundSpinner></ng-template>
