import { throwError as observableThrowError, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'tw-core-ui';
import { DashboardActionsRendererComponent } from '../nbn-user/components/dashboard-actions-renderer/dashboard-actions-renderer.component';
import { DocumentsTitleRendererComponent } from '../nbn-user/components/documents-title-renderer-component/documents-title-renderer-component.component';

export interface GridHeaderInterface {
  headers: any;
}

export interface GridDataInterface {
  data: any;
}
@Injectable()

export class DashboardService {
  public docDetailsData = new BehaviorSubject<any>('');
  public docDetailsDataObj = this.docDetailsData.asObservable();

  private env: any = window['environment'];
  spinnerflag = false;
  private sendSpinnerFlag = new BehaviorSubject(this.spinnerflag);
  spinnerCurrentStatus = this.sendSpinnerFlag.asObservable();
  private apiUrl = {
    dashboard: 'dashboard-search-service',
    users: 'users',
    downloadFile: 'document-management/documents/'
  };
  gridLabelAuthoringDataObj: any;
  documentListModel: any;
  documentListGridData: any;
  public documentsGridHeader: any = [];
  private documentUri = {
    documentModel: 'nbn-document',
    documentGridData: 'document-management/documents',
    documentListModel: 'nbn-document-list',
    gridHeader: 'nbnDocumentGridHeader'
  };
  public documentGridData: any;
  constructor(private http: HttpClient, private authService: AuthService) { }

  public getGridHeader() {
    const map = {
      DashboardActionsRendererComponent: DashboardActionsRendererComponent,
      DocumentsTitleRendererComponent: DocumentsTitleRendererComponent
    };
    return new Promise((resolve, reject) => {
      this.http.get<GridHeaderInterface>(this.env.aemEndPoint + this.documentUri.gridHeader)
        .subscribe(res => {
          res.headers.forEach((x: any) => {
            if (x.cellRendererFramework) {
              x.cellRendererFramework = map[x.cellRendererFramework];
            }
          });
          this.documentsGridHeader = res;
          resolve(true);
        }, err => {
          reject(err);
        });
    });
  }

  public getDocumentModel() {
    this.sendSpinnerFlag.next(true);
    return new Promise((resolve, reject) => {
      const url = this.env.aemEndPoint + this.documentUri.documentModel;
      this.http.get<any>(url)
        .subscribe(
          res => {
            this.gridLabelAuthoringDataObj = res.data.content;
            resolve(this.gridLabelAuthoringDataObj);
          },
          err => {
            this.gridLabelAuthoringDataObj = null;
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }
  public getDocumentListModel() {
    this.sendSpinnerFlag.next(true);
    return new Promise((resolve, reject) => {
      const url = this.env.aemEndPoint + this.documentUri.documentListModel;
      this.http.get<any>(url)
        .subscribe(
          res => {
            this.documentListModel = res.data.content;
            resolve(this.documentListModel);
          },
          err => {
            this.documentListModel = null;
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }

  public updateDocumentGridData(data) {
    this.documentGridData = data;
  }

  public getDocumentGridData(payload?) {  
  const tokenValue = this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : false;
    let httpOptions;
    if (tokenValue) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': '*/*',
          'Authorization': tokenValue
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': '*/*'
        })
      };
    }
    this.sendSpinnerFlag.next(true);
    let flag = false;
    let workListUrl = this.env.apiPoint + this.documentUri.documentGridData;
    if (payload != undefined) {
      this.documentGridData = null;
      if (payload.searchKeyword && payload.startDate && payload.endDate) {
        flag = true;
        workListUrl = workListUrl + '?searchKeyword=' + payload.searchKeyword + '&startDate=' + payload.startDate + '&endDate=' + payload.endDate
      } else if (payload.startDate && payload.endDate && !payload.searchKeyword) {
        flag = true;
        workListUrl = workListUrl + '?startDate=' + payload.startDate + '&endDate=' + payload.endDate
      } else if (payload.searchKeyword) {
        flag = true;
        payload.searchKeyword = this.handleSpecialChar(payload.searchKeyword);
        workListUrl = workListUrl + '?searchKeyword=' + payload.searchKeyword
      }
    } else if (payload === undefined || payload === null && !this.documentGridData) {
      this.documentGridData = null;
    }
    if (flag && payload && payload.cabinetId) {
      workListUrl = workListUrl + '&cabinetId=' + payload.cabinetId
    } else if (!flag && payload && payload.cabinetId) {
      workListUrl = workListUrl + '?cabinetId=' + payload.cabinetId
    } 

    return new Promise((resolve, reject) => {
      this.http.get<any>(workListUrl, httpOptions)
      .subscribe(
        res => {
          this.sendSpinnerFlag.next(false);
          if (res && res['data'] && res['data']['documentsList']) {
            this.documentGridData = res['data']['documentsList'];
          } else {
            this.documentGridData = [];
          }
          resolve(this.documentGridData);
        },
        err => {
          this.sendSpinnerFlag.next(false);
          this.documentGridData = null;
          reject(err);
          return observableThrowError(err || 'Server error');
        }
      );
    });
  }

    public downloadFile(filename) {
    this.sendSpinnerFlag.next(true);
    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      }),
      responseType: 'blob', 
      observe: 'response'
    };
    let fileURL: any = this.handleSpecialChar(filename);
    let url = this.env.apiPoint + this.apiUrl.downloadFile + fileURL;
    return new Promise((resolve, reject) => {
      this.http.get(url, httpOptions).pipe(catchError((error: any): any => {
        this.sendSpinnerFlag.next(false);
        reject(error);
        return observableThrowError(error || 'Server error');
      })).subscribe((res: object) => {
        if (res) {
          this.sendSpinnerFlag.next(false);
          resolve(res);

        }
      });
    })
  }

  handleSpecialChar(value): string {
    value = value.replaceAll("'", "''");
    enum Special {
      '(' = '%28',
      ')' = '%29',
      '-' = '%2D',
      '_' = '%5F',
      '*' = '%2A',
      '!' = '%21',
      '~' = '%7E',
      "'" = '%27'
    };
    Object.keys(Special).map(x => {
      value = value.replaceAll(x, Special[x]);
    })
    return value;
  }

  public getroleDetails() {
    const roleList = [];
    const appList = [];
    const cidnList = [];
    const user_roles: any = this.authService.user ? this.authService.user.profile.roles : this.env.roles;
    if (user_roles) {
      if (Array.isArray(user_roles)) {
        user_roles.forEach(item => {
          const tempApp = item.type.split(':')[1];
          const tempRole = item.value.split(':')[1];
          const tempCidn = item.value.split(':')[0];
          if (tempApp) {
            appList.push(tempApp);
          }
          if (tempRole) {
            roleList.push(tempRole);
          }
          if (tempCidn) {
            cidnList.push(tempCidn);
          }
        });
      } else {
        const tempApp = user_roles.type.split(':')[1];
        const tempRole = user_roles.value.split(':')[1];
        const tempCidn = user_roles.value.split(':')[0];
        if (tempApp) {
          appList.push(tempApp);
        }
        if (tempRole) {
          roleList.push(tempRole);
        }
        if (tempCidn) {
          cidnList.push(tempCidn);
        }
      }
    }
    return { 'cidnList': cidnList, 'roleList': roleList, 'appList': appList };
  }

  setDocDetailsData(data: any) {
    this.docDetailsData.next(data);
  }

}
