<div class="container-fluid cabinetPage page-bg infra-cabinet-list-container" *ngIf="aemLabelAuthoring" id="qa-banner">
  <h1 class="wlcm-cls" id="wel">Welcome {{profile.contactName}},</h1>
  <h3 class="message-label">{{aemLabelAuthoring.pageHeader.pageHeaderLabel}}</h3>
  <div class="welcome-msg">Welcome to the Telstra - InfraCo Portal, your gateway to Telstra documents provided to NBNCo
    under the
    Definitive Agreement.</div>
  <div class="row myuser-button-container">
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
      <label class="label">Search cabinet</label>
      <app-cui-textfield [(model)]="cabinetModel" (keyup)="checkCabinet($event)" *ngIf="!isResetField"
        class="searchBox">
      </app-cui-textfield>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-7 search-btn-container">
      <button type="button" class="primary-globalbtn-cls" (click)="searchCabinetData()"
        [disabled]="!cabinetModel.inputText">{{aemLabelAuthoring.form.grid.search}}
      </button>
      &nbsp;&nbsp;
      <button type="button" class="texttype-globalbtn-cls" (click)="resetSearch()" [disabled]="!cabinetModel.inputText">
        {{aemLabelAuthoring.form.grid.reset}}
      </button>
    </div>
  </div>

  <div class="row cabinet-list-header">
    <h3 class="cabinet-title col-3">Cabinets</h3>
    <div class="col-6">
      <div class="btnWrap" *ngIf="showCreateCabinetBtn">
        <button class="primary-globalbtn-cls" (click)="navigateToCreateCabinet()">
          <i class="icon-icon-ui-plus roundWhiteBorderCls"></i>
          Add new document cabinet
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="isInfracoUser"><br></div>
  <div class="grid-container cabinet listUsers padding-top-10">

    <ng-container *ngIf="gridConfig && gridInitialized">
      <app-cui-ag-grid [model]="gridModel" (filtersOnChanged)="onFilterSelected($event)"
        (cellClicked)="onCellClicked($event)" (focusOnTop)="focusOnTop($event)">
      </app-cui-ag-grid>
    </ng-container>
  </div>
  <!-- Remove Modal -->
  <ng-container>
    <div class="modal" role="dialog" aria-modal="true" aria-haspopup="dialog" *ngIf="aemLabelAuthoring"
      [ngClass]="{'profileModal':isModalOpen}">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="container p-0">

            <div class="row m-0 p-0">
              <div class="p-0">
                <span (keypress)="closeModal()" (click)="closeModal()" id="closePopup" class="close" tabindex="0"
                  aria-label="Close" role="button"><i class="icon-icon-ui-cross"></i></span>
              </div>
              <div class="closeBtn">
                <span class="modal-heading">Remove cabinet?</span>
              </div>
              <div>

                <p class="modal-confirmation-text">Are you sure you want to <b>remove</b> cabinet? The cabinet might
                  have
                  documents. If you proceed, all
                  the
                  documents will be deleted.</p>
                <div class="modal-btn-container">
                  <button type="button" tabindex="0" class="texttype-globalbtn-cls" (click)="closeModal()"><i
                      class="icon-icon-ui-cross"></i>Cancel
                  </button>
                  <button type="button" tabindex="0" id="remove" class="primary-globalbtn-cls"
                    (click)="onClickRemove()">Remove</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
