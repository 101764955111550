<!-- {{address | json}} -->
<!-- Latlong  Tab -->
<form [formGroup]="searchForm" autocomplete="off">
  <div class="d-flex flex-column py-4 w-100">
    <div class="d-flex align-items-center gap-2 w-100 input-wrp">
      <img src="../../../../../../assets/images/Search.svg" class="bi bi-search" />
      <div class="d-flex w-100">
        <mat-label></mat-label>
        <input #trigger="matAutocompleteTrigger" type="text" class="address-input" name="primaryAutoComplete1"
          (input)="autoCompletePrimary($event)" [matAutocomplete]="auto" #searchInput
          [placeholder]="aemLabelAuthoring?.searchForm.searchPlaceHolder + formConfig.title"
          value="{{primaryAutoComplete}}" (focus)="searchFocus($event)" [disabled]="isSearchDisabled()" >
        <mat-autocomplete #auto="matAutocomplete" disableRipple class="mat-autocomplete-css">
          <ng-container *ngIf="primaryAutoCompleteOptions && !searchUnstructuredAddressesCompleteOptions.length">
            <label aria-live="assertive" class="visually-hidden">Address Suggestions found</label>
            <cdk-virtual-scroll-viewport itemSize="20" [style.height]="caclVSHeight()">
              <ng-container *ngIf="primaryAutoCompleteOptions.length">
                <mat-option class="multiple-addresses" role="none presentation">
                  <div class="we-found-multiple-addresses">
                    {{aemLabelAuthoring?.searchForm?.multipleAddressesLabel}}
                  </div>
                </mat-option>
              </ng-container>
              <mat-option *cdkVirtualFor="let option of primaryAutoCompleteOptions" [value]="option.primaryAddress"
                (click)="autoCompletePrimarySelect(option.primaryAddress)">
                {{option.primaryAddress | titlecase}}
              </mat-option>
            </cdk-virtual-scroll-viewport>
          </ng-container>
          <ng-container>
            <ng-container *ngIf="searchUnstructuredAddressesCompleteOptions.length">
              <cdk-virtual-scroll-viewport itemSize="20" [style.height]="caclVSHeight()">
                <ng-container *ngIf="searchUnstructuredAddressesCompleteOptions.length">
                  <mat-option class="multiple-addresses">
                    <div class="we-found-multiple-addresses">
                      {{aemLabelAuthoring?.searchForm?.multipleAddressesLabel}}
                    </div>
                  </mat-option>
                </ng-container>
                <mat-option class="multiple-addresses multiple-addresses-heading-1"
                  *ngFor="let option of searchUnstructuredAddressesCompleteOptions;  let i = index"
                  (click)="$event.stopPropagation();">
                  <div class="row-accordion-wrapper">
                    <button class="accordion" id="button-{{i}}"
                      (click)="searchUnstructuredAddressesSelect(option, 'address'); searchInput.focus();" #schematicView>
                      <span (click)="schematicView.click();">
                        {{option.primaryAddress | titlecase}}
                      </span>
                      <span *ngIf="option.subaddresses && option.subaddresses.length"
                        (click)="$event.stopPropagation(); toggleAccordian($event, i);">
                        <span class="blackCircleArrow"><img
                            src="../../../../../../assets/images/BlackCircleArrow.svg" /></span>
                        <span class="blackCircleDownArrow"><img
                            src="../../../../../../assets/images/BlackCircleDownArrow.svg" /></span>
                      </span>
                    </button>
                    <div id="panel-{{i}}" class="panel">
                      <div id="schematic-svg">
                        <div class="list-group">
                          <ng-container *ngFor="let opt of option.subaddresses">
                            <div class="multiple-addresses-heading-2"
                              (click)="searchUnstructuredAddressesSelect(opt, 'subaddresses'); $event.stopPropagation();searchInput.focus();">
                              {{opt.address | titlecase}}
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-option>
              </cdk-virtual-scroll-viewport>
            </ng-container>
            <ng-container *ngIf="showAddressContainer">
              <mat-option class="visibilty-hidden"></mat-option>
              <div
                class="multiple-addresses-cannot-found-fixed multiple-addresses multiple-addresses-cannot-found height-auto"
                [value]="''" (click)="toggleToLatLongSearch(aemLabelAuthoring?.searchForm.LatLongTabId);">
                <div class="">
                  <hr class="mt-0" />
                </div>
                <div tabindex="0" class="cannot-found-multiple-addresses">
                  <span><img class="cannot-found-multiple-addresses-icon"
                      src="../../../../../../assets/images/Icon-info.svg" /></span>
                  <span class="cannot-found-multiple-addresses-text">
                    {{aemLabelAuthoring?.searchForm?.cantFindLabel}}
                  </span>
                </div>
              </div>

            </ng-container>
          </ng-container>

        </mat-autocomplete>
      </div>
    </div>
    <span class="search-example">{{aemLabelAuthoring?.searchForm.searchExample}}</span>
    <ng-container *ngIf="formConfig.formName == 'a2EndAdress'">
      <div class="col-12 mt-2">
        <div class="form-check form-check-inline margin-right-50px">
          <input formControlName="a2sameAsA1EndAddress" #a2sameAsA1EndAddress
            (change)="onCheckChange(a2sameAsA1EndAddress, 'sameAsPath1AEndAddressDetails', 'sameAsPath1A2EndAddressDetails')"
            [checked]="false" class="form-check-input checkbox-input" type="checkbox"
            [id]="aemLabelAuthoring?.label?.path2SiteAEndAddress.uid + aemLabelAuthoring?.label.path2SiteAEndAddress.sameAsPath1AEndAddressDetails">
          <label class="form-check-label checkbox-text"
            [for]="aemLabelAuthoring?.label?.path2SiteAEndAddress.uid + aemLabelAuthoring?.label?.path2SiteAEndAddress?.sameAsPath1AEndAddressDetails">{{aemLabelAuthoring?.label.path2SiteAEndAddress.sameAsPath1AEndAddressDetails}}</label>
        </div>
        <div class="form-check form-check-inline ml-2" >
          <input formControlName="a2sameAsZ1EndAddress" #a2sameAsZ1EndAddress
            (change)="onCheckChange(a2sameAsZ1EndAddress, 'sameAsPath1ZEndAddressDetails', 'sameAsPath1A2EndAddressDetails')"
            [checked]="false" class="form-check-input checkbox-input" type="checkbox"
            [id]="aemLabelAuthoring?.label?.path2SiteAEndAddress?.uid + aemLabelAuthoring?.label?.path2SiteAEndAddress?.sameAsPath1ZEndAddressDetails">
          <label class="form-check-label checkbox-text"
           [for]="aemLabelAuthoring?.label.path2SiteAEndAddress?.uid + aemLabelAuthoring?.label.path2SiteAEndAddress?.sameAsPath1ZEndAddressDetails">{{aemLabelAuthoring?.label?.path2SiteAEndAddress?.sameAsPath1ZEndAddressDetails}}</label>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="formConfig.formName == 'z2EndAdress'">
      <div class="col-12 mt-2">
        <div class="form-check form-check-inline margin-right-50px">
          <input formControlName="z2sameAsA1EndAddress" #z2sameAsA1EndAddress
            (change)="onCheckChange(z2sameAsA1EndAddress, 'sameAsPath1AEndAddressDetails', 'sameAsPath1Z2EndAddressDetails')"
            [checked]="false" class="form-check-input checkbox-input" type="checkbox"
            [id]="aemLabelAuthoring?.label?.path2SiteZEndAddress?.uid + aemLabelAuthoring?.label?.path2SiteZEndAddress?.sameAsPath1AEndAddressDetails">
          <label class="form-check-label checkbox-text"
            [for]="aemLabelAuthoring?.label?.path2SiteZEndAddress?.uid + aemLabelAuthoring?.label?.path2SiteZEndAddress?.sameAsPath1AEndAddressDetails">{{aemLabelAuthoring?.label?.path2SiteZEndAddress?.sameAsPath1AEndAddressDetails}}</label>
        </div>
        <div class="form-check form-check-inline ml-2" >
          <input formControlName="z2sameAsZ1EndAddress" #z2sameAsZ1EndAddress
            (change)="onCheckChange(z2sameAsZ1EndAddress, 'sameAsPath1ZEndAddressDetails', 'sameAsPath1Z2EndAddressDetails')"
            [checked]="false" class="form-check-input checkbox-input" type="checkbox"
            [id]="aemLabelAuthoring?.label?.path2SiteZEndAddress?.uid + aemLabelAuthoring?.label?.path2SiteZEndAddress?.sameAsPath1ZEndAddressDetails">
          <label class="form-check-label checkbox-text"
                      [for]="aemLabelAuthoring?.label?.path2SiteZEndAddress?.uid + aemLabelAuthoring?.label?.path2SiteZEndAddress?.sameAsPath1ZEndAddressDetails">{{aemLabelAuthoring?.label?.path2SiteZEndAddress?.sameAsPath1ZEndAddressDetails}}</label>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="displayForm">
      <div class="col-12 m-t-46" *ngIf="!preDefinedSiteNode">
        <div class="Is-the-address-a-defined-property-location text-nowrap">
          <span>{{aemLabelAuthoring?.searchForm?.isDefinedPropertyLocation}}
            <span class="text-danger" aria-hidden="true">*</span>
          </span>
        </div>
        <div class="For-example-Has-your-address-been-recognised-by-your-local-council">
          {{aemLabelAuthoring?.searchForm?.isDefinedPropertyLocationEaxample}}
        </div>
        <div class="radio-btn-container radio-left">
          <mat-radio-group formControlName="definedPropertyLocation">
            <div
              class="form-check form-check-inline"
              (click)="onChangeRadioButton('Yes')"
              (keyup.enter)="onChangeRadioButton('Yes')"
              [attr.aria-checked]="(searchForm?.value?.definedPropertyLocation === 'Yes') ? 'true' : 'false'"
              tabindex="0"
              id="inlineRadio1"
              role="radio">
              <mat-radio-button
                class="col-6 radio-allignment-cls"
                [value]="'Yes'">
                <div class="mat-custom-label">
                  <span aria-label="Is the address a defined property location? Yes" value="Yes">
                    {{aemLabelAuthoring?.label?.yes}}
                  </span>
                </div>
              </mat-radio-button>
            </div>

            <div
              class="form-check form-check-inline"
              (click)="onChangeRadioButton('No')"
              (keyup.enter)="onChangeRadioButton('No')"
              [attr.aria-checked]="(searchForm?.value?.definedPropertyLocation === 'No') ? 'true' : 'false'"
              tabindex="0"
              id="inlineRadio2"
              role="radio">
              <mat-radio-button
                class="col-6 radio-allignment-cls radio-allignment-cls-no"
                [value]="'No'">
                <div class="mat-custom-label">
                  <span aria-label="Is the address a defined property location? No" value="No">
                    {{aemLabelAuthoring?.label?.no}}
                  </span>
                </div>
              </mat-radio-button>
            </div>
          </mat-radio-group>

        </div>

      </div>
      <div class="col-12 col-12 m-t-46">
        <div class="Provide-additional-address-information w-100 mb-0">
          {{aemLabelAuthoring?.searchForm?.additionalAddressInformation}}
        </div>
        <textarea class="Path-13254 w-100 resizableTextarea" id="provideAdditionalInfo" attr.aria-label="{{aemLabelAuthoring?.searchForm.additionalAddressInformation}}" formControlName="additionalAddressInfo" maxlength="250"
          [placeholder]="aemLabelAuthoring?.searchForm.additionalAddressInformationPlaceHolder"
          [ngClass]="{'invalid-form-control': (searchForm.controls.additionalAddressInfo.touched && searchForm.controls.additionalAddressInfo.invalid)}"></textarea>
        <small class="float-end pull-right text-success">{{searchForm?.value?.additionalAddressInfo?.length ?
          searchForm?.value?.additionalAddressInfo?.length : 0}}/250</small>
        <ng-container
          *ngIf="searchForm.controls['additionalAddressInfo'].invalid && (searchForm.controls['additionalAddressInfo'].dirty || searchForm.controls['additionalAddressInfo'].touched)">
          <small *ngIf="searchForm.controls['additionalAddressInfo'].errors.required" class="text-danger float-start errorMsg">
            <img _ngcontent-jdt-c255="" src="../../../../../assets/images/icon-error.svg" class="error-icon">
            {{aemLabelAuthoring?.searchForm?.additionalAddressInformationRequiredError}}
          </small>
          <!-- <small *ngIf="searchForm.controls['additionalAddressInfo'].errors.invalid" class="text-danger float-start">
            <img _ngcontent-jdt-c255="" src="../../../../../assets/images/icon-error.svg" class="error-icon">
            {{aemLabelAuthoring?.searchForm.additionalAddressInformationInvalidError}}
          </small> -->
          <small
            *ngIf="!searchForm.controls['additionalAddressInfo']?.errors?.required && searchForm.controls['additionalAddressInfo']?.invalid"
            class="text-danger float-start errorMsg">
            <img _ngcontent-jdt-c255="" src="../../../../../assets/images/icon-error.svg" class="error-icon">
            {{aemLabelAuthoring?.searchForm?.additionalAddressInformationInvalidError}}
          </small>
        </ng-container>
      </div>
    </ng-container>
  </div>
</form>
