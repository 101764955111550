import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'tw-core-ui';
import { throwError as observableThrowError, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private env: any = window['environment'];
  private userObj: any = window['userManagerObj'];
  private aemDefaultUri = {
    profileLabels: 'profile-authoring-data',
    updatePreference: 'users/updatePreference'
  };
  constructor(private http: HttpClient, private authService: AuthService) { }

  public getProfileDetails() {
    const profile = {
      'roles': this.authService.user ? this.authService.user.profile.roles : window['environment']['roles'],
      'contactName': this.authService.contactName() ? this.authService.contactName() : window['environment']['contactNameUI'],
      'contactEmail': this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail'],
      'userInitials': '',
      'targetUrl': this.userObj ? this.userObj.authority + '/idp/startSLO.ping?TargetResource=' + this.userObj.post_logout_redirect_uri : ''
    };
    profile.userInitials = profile.contactName.toUpperCase().split(' ').map(n => n[0]).join('');
    return profile;
  }

  public modifyProfile(val, email) {
    // this.sendSpinnerFlag.next(true);
    // this.loader.setLoader(true);
    const payload = {
      userId: email,
      preferenceType: "defaultcustomer",
      preference: val,
      preferenceCategory: "hub_preference",
      status: null,
      deliveryChannel: null

    }
    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    return new Promise<any>((resolve, reject) => {
      this.http.put<any>(this.env.apiPoint + this.aemDefaultUri.updatePreference, payload, httpOptions)
        .subscribe(res => {
          resolve(res);
          //this.loader.setLoader(false);
        }, err => {
          // this.loader.setLoader(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }
}


