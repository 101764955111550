import { Component } from '@angular/core';

@Component({
  selector: 'app-worklist-status-renderer',
  templateUrl: './worklist-status-renderer.component.html',
  styleUrls: ['./worklist-status-renderer.component.less']
})
export class WorklistStatusRendererComponent {
  quoteOrOrderStatus = ''
  agInit(params: any): void {
    this.quoteOrOrderStatus = params.value;
  }

}
