<div *ngIf="cabinetListFlag">
  <app-cabinet-list (showDocumentList)="showDocumentList($event)"
                    (showCreateCabinet)="showCreateCabinet($event)">
  </app-cabinet-list>
</div>
<div *ngIf="documentListFlag">
  <app-cabinets-document-list [cabinetId] = "cabinetId"
                              [cabinetName]="cabinetName"
                              [cabinetRole]="cabinetRole"
                              (goToCabinet)="showCabinetList()"
                              (openUploadDoc)="openUploadDoc()">
  </app-cabinets-document-list>
</div>
<div *ngIf="createCabinetFlag">
  <app-create-cabinet (goToCabinet)="showCabinetList()"></app-create-cabinet>
</div>
<div *ngIf="showUploadDoc">
  <app-upload-cabinets-document [cabinetId] = "cabinetId"
                                [cabinetName]="cabinetName"
                                [cabinetRole]="cabinetRole"
                                (goToCabinet)="showCabinetList()"
                                (navigateToDocList)="showDocumentList()">
  </app-upload-cabinets-document>
</div>