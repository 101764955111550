
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError as observableThrowError } from 'rxjs';
import { AuthService } from 'tw-core-ui';

export interface GridHeaderInterface {
  headers: any;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  aPIUrl: string = '';
  public homePageLabelAuthoringDataObj: any;
  private env: any = window['environment'];
  private UserServiceUrl = {
    createUserPageLabel: 'users-codelist',
    gridData: 'users',
    users: 'users/portal-users',
    userData: 'user-data',
    selectedCidn: 'selected_cidn'

  };
  userToModifyData: any;
  isusperadim: Boolean = false;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  public getLabels() {
    return new Promise((resolve, reject) => {
      if (!this.homePageLabelAuthoringDataObj) {
        const worklistUrl = this.env.aemEndPoint + this.UserServiceUrl.createUserPageLabel;
        this.http.get<any>(worklistUrl)
          .subscribe(
            res => {
              this.homePageLabelAuthoringDataObj = res.data.content;
              resolve(this.homePageLabelAuthoringDataObj);
            },
            err => {
              return observableThrowError(err || 'Server error');
            }
          );
      }
      else {
        resolve(this.homePageLabelAuthoringDataObj);
      }
    });
  }

  public getUserRoles() {
    let roles = [];
    let user_roles: any = this.authService.user ? this.authService.user.profile.roles : this.env.roles;
    if (Array.isArray(user_roles)) {
      user_roles.forEach(item => roles.push(item.value));
    } else {
      roles.push(user_roles.value);
    }
    return roles;
  }

  public getUserData(userName, selectedCidn?: string) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',

      })
    };

    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(this.env.apiPoint + this.UserServiceUrl.gridData + '/' + userName + '?selected_cidn=' + selectedCidn, httpOptions)
        .subscribe(res => {
          this.userToModifyData = res.data;
          resolve(res);
          // this.sendSpinnerFlag.next(false);
        }, err => {
          // this.sendSpinnerFlag.next(false);
          if (err.status === 404) {
            resolve(err);
          } else {
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        });
    });
  }

  public createUser(params, roleDetails) {
    // this.sendSpinnerFlag.next(true);	
    const payLoad = {
      username: params.username ? params.username : "",
      firstName: params.firstname ? params.firstname : "",
      lastName: params.lastname ? params.lastname : "",
      email: params.username ? params.username : "",
      phoneNumber: params.mobilenumber ? params.mobilenumber : "",
      status: params.status ? params.status : "Active",
      roles: roleDetails
    };

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',

      })
    };
    const url = this.UserServiceUrl.gridData + '/create-user'
    // const url = this.UserServiceUrl.gridData
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(this.env.apiPoint + url, payLoad, httpOptions)
        .subscribe(res => {
          resolve(res);
        }, err => {
          //   this.sendSpinnerFlag.next(false);	
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public setModifyUserData(email, id) {

    const obj = {
      id,
      email
    }
    this.userToModifyData = obj;
  }


  public getModifyUserData() {
    const res = this.userToModifyData;
    return res;
  }
  toggleIsusperadim(setToTrue: boolean = false) {
    this.isusperadim = setToTrue;
  }

  public modifyUser(params, userId) {
    // this.sendSpinnerFlag.next(true);
    const payLoad = {
      'operations': params
    }

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',

      })
    };

    let url = this.env.apiPoint + this.UserServiceUrl.gridData + '/' + userId;
    if (this.isusperadim) {
      url += '?isSuperAdmin=true';
    }
    return new Promise<any>((resolve, reject) => {
      this.http.patch<any>(url, payLoad, httpOptions)
        .subscribe(res => {
          // this.sendSpinnerFlag.next(false);
          resolve(res);
        }, err => {
          // this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });

  }

}