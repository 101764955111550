import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CommonService } from '../shared/services/common.service';

@Component({
  selector: 'app-cabinets',
  templateUrl: './cabinets.component.html',
  styleUrls: ['./cabinets.component.less'],
})
export class CabinetsComponent implements OnInit {
  cabinetListFlag: boolean;
  documentListFlag: boolean;
  createCabinetFlag: boolean;
  showUploadDoc: boolean;
  cabinetId: string;
  cabinetName: string;
  cabinetRole: string;
  constructor(private commonService:CommonService,private router: Router, private titleService: Title ) {
    this.titleService.setTitle('InfraCo Customer Portal - cabinets');
  }
  ngOnInit() {
    this.cabinetListFlag = true;
    this.documentListFlag = false;
    this.createCabinetFlag = false;
    this.showUploadDoc = false;
    const roles = this.commonService.getroleDetails().customerList;
    if (!roles.some((obj) => obj.role.includes('NBN') || obj.function.includes('NBN'))) {
      this.router.navigateByUrl('/access-denied');
    }
  }

  showDocumentList(rowData) {
    if(rowData){
    this.cabinetId = rowData.cabinetId;
    this.cabinetName = rowData.cabinetName;
    this.cabinetRole = rowData.cabinetRole;
    }
    this.cabinetListFlag = false;
    this.documentListFlag = true;
    this.createCabinetFlag = false;
    this.showUploadDoc = false;
  }

  showCreateCabinet() {
    this.cabinetListFlag = false;
    this.createCabinetFlag = true;
    this.documentListFlag = false;
    this.showUploadDoc = false;
  }

  showCabinetList() {
    this.cabinetListFlag = true;
    this.createCabinetFlag = false;
    this.documentListFlag = false;
    this.showUploadDoc = false;
  }

  openUploadDoc() {
    this.cabinetListFlag = false;
    this.createCabinetFlag = false;
    this.documentListFlag = false;
    this.showUploadDoc = true;
  }
}
