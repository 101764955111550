<form [formGroup]="popupForm" id="popupForm">
  <ng-template #content let-modal>
    <section class="wlcm-hdr-cls">
      <h1 class="welcome-heading" id="welcome-pop-up-title">{{popUpModel?.modalTitle}}</h1>
      <span class="wlcom-name-cls">
        {{contactName}}</span>, 
    </section>
    <div class="modal-body">
      <div class="contnt-cls">{{popUpModel?.modalContent}}
      </div>
      <div *ngFor="let customer of customerList">
        <div class="Default-customer"> {{customer?.entitlement}}</div>
        <app-cui-searchable-dropdown name="businessTypes" [formControlName]="'businessTypes'"
          [model]="businessNameDropDownModel" [labelName]="'businessDropdown'" [placeholder]="'Select Default Customer'"
          [isRequired]=true [items]=businessNames automationType="dropdown" automationId="drpdwnbusinessType"
          [noAutoComplete]=false id="businessTypes" class="slct-drpdwn-cls">
        </app-cui-searchable-dropdown>
      </div>
    </div>
    <div class="button-div-cls">
      <button mat-button class="primary-globalbtn-cls" (click)="save()"
        [disabled]="!this.popupForm.get('businessTypes')?.valid">{{popUpModel?.buttonName}}</button>
    </div>
  </ng-template>
</form>
