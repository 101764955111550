<div class="modal customModal" role="dialog" aria-modal="true" id="success-modal-with-copy-link" aria-haspopup="dialog" *ngIf="content" tabindex="0">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="container">
        <div class="row m-0">
          <div class="closeBtn">
            <span (click)="remove()" class="close" id="close" tabindex="0" aria-label="Close" role="button">
              <i class="icon-icon-ui-cross"></i>
            </span>
          </div>
          <div class="succes-container">
            <img class="tick-svg" src="../../../../assets/images/icon-tick.svg" alt="success icon"/>
            <p role="alert" aria-live="polite" class="pl-2">{{content.successMsg}}</p>
          </div>
          <div class="title">
            <h3>{{content.title}}</h3>
          </div>
          <div class="success-link pr-3">
            <p>{{content.link}}</p>
            <span class="copySpan" id="copy" tabindex="0">
            <i (keypress)="copyLink()" (click)="copyLink()" class="copy-icon">
              <img class="copy-svg" src="../../../../assets/images/icon-copy.svg" alt="copy icon"/>
            </i>
          </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>