<div (mouseleave)="showDropDown = false" (keypress)="dropsh($event)" class="drpdwn-div-cls" id="multiSelectDrpdwnContainer" tabindex="0" aria-label="Role function required combobox collapsed">
    <button tabindex="-1" class="drop-toggle btn flat" [ngClass]="{'invalid-form-control': (checkedList.length === 0)}"
    (click)="toggleDropdown()" aria-label="Select role function combobox required" [disabled]="isDisabled" >
        <span  *ngIf="checkedList.length<=0">Select {{placeholder}} </span>
        <span *ngIf="checkedList.length>0" style="width: 20%;">{{checkedList.join(', ')}}</span>
        <i class="icon-icon-ui-down-arrow icon-sm"></i>
    </button>
    <div class="drop-show" *ngIf="showDropDown" role="dialog" aria-modal="true">
        <label *ngFor="let a of list; let last = last" [attr.aria-checked]="a.checked" role="checkbox" class="main" tabindex="0" (keydown)="onLabelKeypress($event, a, last)">       
            <input type="checkbox"  id="role-func-checkbox" *ngIf="a.name" [(ngModel)]="a.checked" (change)="getSelectedValue(a.checked,a.name)" 
            tabindex="0">
            <span class="checkicon"></span>
            {{a.name}}
        </label>
    </div>
</div>
