export class UtilityService {
  private readonly nameRegEX = /^([a-zA-Z0-9'&-]+\s)*[a-zA-Z0-9'&-]+$/;
    private readonly stateRegEX = /^[a-zA-Z]+$/;
    private readonly emailRegEx = /^[A-Za-z0-9._-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,63}$/;
    private readonly serviceIdRegex = /^[A-Za-z0-9\s]{0,}$/;
    private readonly numberRegEx = /^(\+)?[0-9]{10,20}$/;
    private readonly shortNumberRegEx = /^(\+)?[0-9]{1,5}$/;
    private readonly domainRegEx = /^(?:[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?\.)+[A-Za-z0-9][A-Za-z0-9-]{0,61}[A-Za-z0-9]$/;
    private readonly macAddressRegEx = /([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/gm;
    private zipCodeRegEx = /^(\+)?[0-9]{4,}$/;
    private readonly alternateNumberRegEx = /^(\+)?[0-9]{10}$/;
    private readonly callDiversionNumberRegEx = /^0[0-9]{9}$/;
    private readonly onlynumberRegEx = /^[0-9]*$/;
    private readonly positiveNumberRegEx = /^[0-9]{1,}.?[0-9]*$/;
    private readonly postCodeRegEx = /^[0-9]{4}$/;
    private readonly cidnRegEx = /^[0-9]{1,255}$/;
  constructor() { }

  getNameRegEx(): RegExp {
    return this.nameRegEX;
  }
  getEmailRegEx(): RegExp {
    return this.emailRegEx;
  }
  getNumberRegEx(): RegExp {
    return this.numberRegEx;
  }
  getShortNumberRegEx(): RegExp {
    return this.shortNumberRegEx;
  }
  getDomainRegEx(): RegExp {
    return this.domainRegEx;
  }
  getServiceNumberRegEx(): RegExp {
    return this.serviceIdRegex;
  }
  getMACAddressRegEx(): RegExp {
    return this.macAddressRegEx;
  }
  getZipCodeRegEx(): RegExp {
    return this.zipCodeRegEx;
  }
  getAlternateNumberRegEx(): RegExp {
    return this.alternateNumberRegEx;
  }
  getCallDiversionNumberRegEx(): RegExp {
    return this.callDiversionNumberRegEx;
  }
  getStreetNumberRegEx(): RegExp {
    return this.onlynumberRegEx;
  }
  getSubAddrNumberRegEx(): RegExp {
    return this.onlynumberRegEx;
  }
  getStateRegEx(): RegExp {
    return this.stateRegEX;
  }
  getCidnRegEx(): RegExp {
    return this.cidnRegEx;
  }
  getPositiveNumberRegEx(): RegExp {
    return this.positiveNumberRegEx;
  }
  getPostCodeRegEx(): RegExp {
    return this.postCodeRegEx;
  }
  scrollToElement(target: any) {
    const targetElement = document.getElementById(target);
    setTimeout(() => {
      this.animate(
        document.scrollingElement || document.documentElement,
        'scrollTop',
        '',
        window.pageYOffset,
        targetElement?.offsetTop && targetElement?.offsetTop + 530,
        500,
        true
      );
    }, 0);
  }
  animate(elem: any, style: any, unit: any, from: any, to: any, time: any, prop: any) {
    if (!elem) {
      return;
    }
    const start = new Date().getTime(),
      timer = setInterval(function () {
        const step = Math.min(1, (new Date().getTime() - start) / time);
        if (prop) {
          elem[style] = from + step * (to - from) + unit;
        } else {
          elem.style[style] = from + step * (to - from) + unit;
        }
        if (step === 1) {
          clearInterval(timer);
        }
      }, 25);
    if (prop) {
      elem[style] = from + unit;
    } else {
      elem.style[style] = from + unit;
    }
  }
  customDateSorter(date1: string, date2: string): number {
    if (!date1 && !date2) {
      return 0; // Treat both empty dates as equal
    }
    if (!date1) {
      return -1; // Treat empty date1 as smaller (earlier) for descending order
    }
    if (!date2) {
      return 1; // Treat empty date2 as greater (latest) for descending order
    }

    const dateObj1 = this.parseCustomDate(date1);
    const dateObj2 = this.parseCustomDate(date2);

    if (!isNaN(dateObj1) && !isNaN(dateObj2)) {
      // If both dates are valid, perform the comparison in descending order
      return dateObj2 - dateObj1;
    } else if (!isNaN(dateObj1) && isNaN(dateObj2)) {
      return 1; // date1 is valid, date2 is empty, so date1 should come after date2
    } else if (isNaN(dateObj1) && !isNaN(dateObj2)) {
      return -1; // date1 is empty, date2 is valid, so date2 should come before date1
    } else {
      return 0; // Both dates are empty or invalid
    }
  }

  parseCustomDate(dateString: string): number {
    if (!dateString) {
      return Number.MAX_SAFE_INTEGER; // Return a very large value for empty dates
    }

    const parts = dateString.split(' ');
    const day = parseInt(parts[0], 10);
    const month = this.getMonthNumber(parts[1]);
    const year = parseInt(parts[2], 10);
    const timeParts = parts[3].split(':');
    const hour = parseInt(timeParts[0], 10);
    const minute = parseInt(timeParts[1], 10);

    return -Date.UTC(year, month, day, hour, minute); // Negative sign for descending order
  }
  getMonthNumber(monthAbbreviation: string): number {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    return months.indexOf(monthAbbreviation);
  }

}
