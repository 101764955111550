import { Component, ElementRef, HostListener, Inject,  LOCALE_ID, OnInit, QueryList,  ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { CustomDatePicker, CustomDropDown } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import Holidays from 'date-holidays';
import * as moment_ from 'moment-timezone';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MyOrdersService } from '../../my-orders.service';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import { myQuotesService } from 'src/app/quote-management/my-quotes/myquotes.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
const moment_business_days = require('moment-business-days');

@Component({
  selector: 'app-order-request-details',
  templateUrl: './order-request-details.component.html',
  styleUrls: ['./order-request-details.component.less', '../order-site-details/order-site-details.component.less']
})
export class OrderRequestDetailsComponent implements OnInit {
  @ViewChildren("tref") tref: QueryList<ElementRef>;
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  @ViewChild('myDatePicker', { static: false }) myDatePicker: ElementRef;
  public aemLabelAuthoring: any;
  stepperData = ['Site details', 'Request details', 'Review and submit']
  stepName = 'Request details';
  pattern = /^[a-zA-Z0-9!@#$%^&*()\-\_=+:'",.?|{}\n\[\]\/\`\~\\ ]*$/;
  public orderRequestForm: FormGroup;
  isOrderSaved: boolean = false;
  isOrderSavedStatus: boolean = false;
  cidn: string;
  orderDetailsData: any;
  userTimeZone: string;
  form = this.fb.group({
    externalRefernces: this.fb.array([])
  });
  finalExternalReferenceList;
  options: GlobalConfig;
  serviceDatePicker = {
    year: '',
    month: '',
    day: ''
  };
  public custRequestedDate: CustomDatePicker = {
    automationTypeImage: '',
    automationIdImage: '',
    automationTypeDatePicker: '',
    automationIdDatePicker: '',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: '',
    automationIdValidationError: '',
    placeholderCaption: '--/--/----',
    id: 'custRequestedDate',
    tabindex: '',
    requiredText: 'Please select a date',
    validationMessage: '',
    isMandatory: false,
    name: 'custRequestedDate',
    formControlName: 'custRequestedDate',
    isDisabled: false,
    isWeekendsDisabled: true,
    isPublicHolidayDisabled: true,
    publicHolidayRegion: {
      country: 'AU'
    },
    addBusinessDays: 0
  };
  moment = moment_;
  momentBusiness = moment_business_days;
  selectedTimeZone = 'Australia/Victoria';
  publicHolidays = [];
  fromDate;
  toMaxDate;
  dateRequired: string;
  selectedServiceDate: any;

  public externalRefernceForm: FormGroup;
  additionalinfo: string;
  public externalReferenceDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'External reference Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  showAlertProjectID: boolean = false;
  public projectIDDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Project ID Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  quoteId: string;
  path: string;
  public customerChange: Subscription;
  infraCoCIDN = window['environment'].InfraCoCIDN;
  constructor(private myOrdersService: MyOrdersService, @Inject(LOCALE_ID) private locale: string, private myOrderService: MyOrdersService,
    private fb: FormBuilder, private toastr: ToastrService, private titleService: Title, private router: Router, private utility: UtilityService,
    private route: ActivatedRoute, private commonService: CommonService,
    private dataSvc: myQuotesService) {
    this.titleService.setTitle('InfraCo Customer Portal - Order Request Details page');
    this.options = this.toastr.toastrConfig;
    // Get User TimeZone
    this.getUserTimeZone();

    // Get CIDN
    if (localStorage.getItem('selectedHeaderCIDN')) {
      this.cidn = localStorage.getItem('selectedHeaderCIDN')
    }
    if (this.infraCoCIDN === this.cidn) {
      this.router.navigate(['/myOrders']);
    }
    this.customerChange = this.commonService.getCustomerName().subscribe((data) => {
      this.dataSvc.customerNameDropdownChange(true, data);
    });
    this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
        if (
          event.id === 1 &&
          event.url === event.urlAfterRedirects
        ) {
          this.router.navigateByUrl('/myOrders');
        }
      })
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.route.paramMap.subscribe(params => {
      this.quoteId = params.get('quoteId');
      this.path = params.get('type');
      this.getOrderDetails(this.quoteId, this.path);
      this.getLabels();
    });
    this.createForm();
    this.formValueChange();
  }

  getOrderDetails(oid, path) {
    const params = {
      cidn: this.cidn,
      timeZone: this.userTimeZone,
      orderId: oid,
      path: path
    }
    return new Promise((resolve, reject) => {
      this.myOrderService.getSingleOrder(params.cidn, params.timeZone, params.orderId, params.path).then((res) => {
        if (res && res.data) {
          this.orderDetailsData = res.data;
          this.setFormData(res.data);
          this.setDatePicker();
        }
        resolve(true);
      }).catch((err) => {
        //  console.log(err);
        reject(err);
      });
    });
  }

  createForm() {
    this.orderRequestForm = this.fb.group({
      contactName: ["", [Validators.required, Validators.pattern(this.utility.getNameRegEx())]],
      contactNumber: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      contactEmail: ["", [Validators.required, Validators.pattern(this.utility.getEmailRegEx())]],
      customerReference: ['', [Validators.pattern(this.pattern), Validators.maxLength(10)]],
      custRequestedDate: [this.fromDate, Validators.required],
      projectID: [""],
      opportunityNo: ["", Validators.pattern(this.pattern)],
      clusterID: ["", Validators.pattern(this.pattern)],
      mirageID: ["", Validators.pattern(this.pattern)],
      customerType1: [''],
      externalReference1: ['', [Validators.pattern(this.pattern)]],
      externalReferenceType: [''],
      externalReferenceValue: ['', [Validators.pattern(this.pattern)]],
      additionalinfo: ['', Validators.pattern(this.pattern)]
    });

    if (this.orderDetailsData?.serviceType === 'RG') {
      this.orderRequestForm.get('projectID').setValidators(Validators.required);
    }

  }

  openCalendar(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (this.myDatePicker) {
        const inputElement = document.querySelector('app-cui-datepicker input') as HTMLInputElement;
        const imgInputElement = document.querySelector('app-cui-datepicker img') as HTMLImageElement;
        console.log("inputElement",inputElement," ===" ,"imgInputElement:",imgInputElement);
        if (inputElement) {
          console.log("inputElement",inputElement);
          inputElement.click();
        }else if(imgInputElement){
          console.log("imgInputElement:",imgInputElement);
          imgInputElement.click();
        }
      }
    }
  }

  setFormData(orderDetail) {
    this.setFormValue('contactName', orderDetail.customerContactName);
    this.setFormValue('contactNumber', orderDetail.customerContactNumber);
    this.setFormValue('contactEmail', orderDetail.customerContactEmail);
    this.setFormValue('customerReference', orderDetail.customerReference);
    this.setFormValue('projectID', orderDetail.projectReferenceId);
    this.setFormValue('opportunityNo', orderDetail.opportunityId);
    this.setFormValue('clusterID', orderDetail.clusterId);
    this.setFormValue('mirageID', orderDetail.miragepclId);
    this.setFormValue('additionalinfo', orderDetail.additionalInformation);
    const dateString = orderDetail.customerRequestedDate;
    if (dateString) {
      this.dateRequired = orderDetail.customerRequestedDate;
      const date = new Date(dateString);
      this.fromDate = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
      };
      this.orderRequestForm.get('custRequestedDate').setValue(this.fromDate);
    }
    this.setExternalreferences();
  }

  saveOrderDetails(routePath?) {

    if (!routePath) {
      this.isOrderSavedStatus = true;
    }
    setTimeout(() => {
      this.isOrderSavedStatus = false;
    }, 5000);

    this.getExternalReferneceFinal();

    if (!this.orderDetailsData) return;

    const formData = this.orderRequestForm.value;
    let payload = this.orderDetailsData;
    payload.customerContactNameUpdated = (this.orderDetailsData.customerContactName === formData.contactName) ? false : true;
    payload.customerContactNumberUpdated = (this.orderDetailsData.customerContactNumber === formData.contactNumber) ? false : true;
    payload.customerContactEmailUpdated = (this.orderDetailsData.customerContactEmail === formData.contactEmail) ? false : true;
    payload.screenName = 'orderRequestDetails';
    payload.customerContactName = formData.contactName;
    payload.customerContactNumber = formData.contactNumber;
    payload.customerContactEmail = formData.contactEmail;
    payload.customerReference = formData.customerReference;
    payload.customerRequestedDate = this.convertDateFormatISO(this.dateRequired) || null;
    payload.quoteExpiryDate = this.convertDateFormatISO(this.orderDetailsData.quoteExpiryDate) || null;
    payload.projectReferenceId = formData.projectID;
    payload.opportunityId = formData.opportunityNo;
    payload.clusterId = formData.clusterID;
    payload.miragepclId = formData.mirageID;
    payload.additionalInformation = formData.additionalinfo;
    payload.externalReference = this.finalExternalReferenceList;
    payload.timezone = this.userTimeZone,
      payload.action = 'Save'
    if (payload?.maxSignalLoss) {
      delete payload.maxSignalLoss;
    }

    this.myOrderService.saveNewOrder(payload).then((res) => {
      this.isOrderSaved = true;
      // this.showToasterMessage('Order Details Saved Successfully', '', this.options, 'success');
      if (routePath) {
        this.router.navigate([routePath, this.path, this.quoteId]);
      }
    }).catch((err) => {
      this.showToasterMessage(this.aemLabelAuthoring?.errorMsg?.genericError, '', this.options, 'error');
    });
  }

  back() {
    this.saveOrderDetails('/order-site-details');

  }

  review() {
    this.saveOrderDetails('/order-review-submit');
  }

  cancel() {
    this.router.navigate(['/myOrders']);
  }


  getLabels() {
    this.myOrdersService
      .getRequestDetailsPageLabel()
      .then((data: any) => {
        this.aemLabelAuthoring = data;
      })
      .catch((error) => { });
  }

  getExternalReferneceFinal() {
    const DATA2 = this.externalRefernces?.value.filter(l => (l.externalReferenceType !== '' && l.externalReferenceValue !== ''));

    if ((this.orderRequestForm.value.customerType1 !== '') && (this.orderRequestForm.value.externalReference1 !== '')) {
      const keyValueObject = { externalReferenceType: this.orderRequestForm.value.customerType1, externalReferenceValue: this.orderRequestForm.value.externalReference1 };

      if (DATA2.length > 0) {
        this.finalExternalReferenceList = [keyValueObject, ...DATA2];
      } else {
        this.finalExternalReferenceList = [keyValueObject]
      }
    } else {
      if (DATA2.length > 0) {
        this.finalExternalReferenceList = [...DATA2];
      }
    }
  }


  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-top-center';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 5000;
    this.options.closeButton = false;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    // document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
    //document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
  }


  setFormValue(id, value) {
    if (id === 'externalReference' && value) {
      if (this.externalRefernceForm) {
        this.externalRefernceForm['controls'][id].setValue(value);
      }
    }
    this.orderRequestForm['controls'][id].patchValue(value || '');
  }


  setDatePicker() {
    let hd = new Holidays('AU', 'NSW');
    const today = this.moment().tz(this.selectedTimeZone).toDate();

    hd.getHolidays(today.getFullYear()).forEach(dateObj => {
      this.publicHolidays.push(dateObj.date.substr(0, 10));
    });

    if (today.getMonth() >= 10) {
      hd.getHolidays(today.getFullYear() + 1).forEach(dateObj => {
        this.publicHolidays.push(dateObj.date.substr(0, 10));
      });
    }
    this.momentBusiness.updateLocale('au', {
      holidays: this.publicHolidays,
      holidayFormat: 'YYYY-MM-DD'
    });
    let minDate;
    if (moment_business_days(this.moment(today).format('YYYY-MM-DD'), 'YYYY-MM-DD').isBusinessDay()) {
      minDate = this.momentBusiness(today).businessAdd(this.orderDetailsData.crdLeadInTime).toDate();
    } else {
      minDate = this.momentBusiness(today).businessAdd((this.orderDetailsData.crdLeadInTime + 1)).toDate();
    }

    if (this.orderDetailsData && this.orderDetailsData.customerRequestedDate) {
      const date1 = new Date(this.orderDetailsData.customerRequestedDate);
      const date2 = new Date(minDate);
      date1.setHours(0, 0, 0, 0);
      date2.setHours(0, 0, 0, 0);
      if (date1 && (date1 > date2)) {
        this.fromDate = {
          year: date1.getFullYear(),
          month: date1.getMonth() + 1,
          day: date1.getDate()
        };
        this.toMaxDate = {
          year: date1.getFullYear() + 1,
          month: date1.getMonth() + 1,
          day: date1.getDate()
        };
        this.orderRequestForm.get('custRequestedDate').setValue(this.fromDate);
        const date = new Date(this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day);
        this.dateRequired = this.fromDate.year + '-' + ((this.fromDate.month.toString()).length === 1 ? ('0' + this.fromDate.month) : (this.fromDate.month)) + '-' + ((this.fromDate.day.toString()).length === 1 ? ('0' + this.fromDate.day) : (this.fromDate.day)) + 'T00:00:00Z'
        this.moment(date).utc().format();
      } else {
        this.fromDate = {
          year: minDate.getFullYear(),
          month: minDate.getMonth() + 1,
          day: minDate.getDate()
        };
        this.toMaxDate = {
          year: minDate.getFullYear() + 1,
          month: minDate.getMonth() + 1,
          day: minDate.getDate()
        };
        this.orderRequestForm.get('custRequestedDate').setValue(this.fromDate);
        const date = new Date(this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day);
        this.dateRequired = this.fromDate.year + '-' + ((this.fromDate.month.toString()).length === 1 ? ('0' + this.fromDate.month) : (this.fromDate.month)) + '-' + ((this.fromDate.day.toString()).length === 1 ? ('0' + this.fromDate.day) : (this.fromDate.day)) + 'T00:00:00Z'
        this.moment(date).utc().format();
      }
    }

    // this.fromDate = {
    //   year: minDate.getFullYear(),
    //   month: minDate.getMonth() + 1,
    //   day: minDate.getDate()
    // };
    // this.orderRequestForm.get('custRequestedDate').setValue(this.fromDate);


  }

  onChangeServiceDate($event) {
    this.selectedServiceDate = this.orderRequestForm.controls['custRequestedDate'].value;
    this.dateRequired = this.selectedServiceDate?.year + '-' + ((this.selectedServiceDate.month.toString()).length === 1 ? ('0' + this.selectedServiceDate.month) : (this.selectedServiceDate.month)) + '-' + ((this.selectedServiceDate.day.toString()).length === 1 ? ('0' + this.selectedServiceDate.day) : (this.selectedServiceDate.day)) + 'T00:00:00Z'
  }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  selectProjectID() {
    this.showAlertProjectID = true;
  }

  getUserTimeZone() {
    const userTimeZone = Intl.DateTimeFormat(this.locale, {
      timeZoneName: 'long'
    }).resolvedOptions().timeZone;

    this.userTimeZone = userTimeZone;
  }

  addExternalRefernce() {
    this.externalRefernceForm = this.fb.group({
      externalReferenceType: [''],
      externalReferenceValue: ['', [Validators.pattern(this.pattern)]]
    });

    this.externalRefernceForm['controls']['externalReferenceType'].updateValueAndValidity();
    this.externalRefernceForm['controls']['externalReferenceValue'].updateValueAndValidity();
    this.externalRefernces.push(this.externalRefernceForm);
    setTimeout(() => this.tref?.last.nativeElement.focus(), 1);
  }

  deleteExternalRefernce(externalRefernceIndex: number) {
    this.externalRefernces.removeAt(externalRefernceIndex);
  }

  get externalRefernces() {
    return this.form.controls["externalRefernces"] as FormArray;

  }



  setExternalreferences() {
    let newArray = [];
    if (this.orderDetailsData?.externalReference?.length > 0) {
      newArray = this.orderDetailsData.externalReference.slice(1);
      newArray.forEach((data) => {
        const extForm = this.fb.group({
          externalReferenceType: [data.externalReferenceType],
          externalReferenceValue: [data.externalReferenceValue],
        });
        (this.form.get('externalRefernces') as FormArray).push(extForm);
      });
    }
    if (this.orderDetailsData.customerType1) {
      this.setFormValue('customerType1', this.orderDetailsData.customerType1);
    } else if (this.orderDetailsData.externalReference?.length > 0) {
      this.orderRequestForm.controls['customerType1'].setValue(this.orderDetailsData.externalReference[0].externalReferenceType);
    }
    if (this.orderDetailsData.externalReference1) {
      this.setFormValue('externalReference1', this.orderDetailsData.externalReference1);
    } else if (this.orderDetailsData.externalReference?.length > 0) {
      this.orderRequestForm.controls['externalReference1'].setValue(this.orderDetailsData.externalReference[0].externalReferenceValue);
    }
  }
  formValueChange() {
    this.orderRequestForm.valueChanges.subscribe(val => {
      if (this.isOrderSaved) {
        this.isOrderSaved = false;
      }
    })
  }
  ngOnDestroy(): void {
    if (this.customerChange) {
      this.customerChange.unsubscribe();
    }
  }

  convertDateFormatISO(d) {
    const date = new Date(d);
    return date.toISOString();
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyUp(event) {
    const activeElement = document.activeElement;
    const textareaElement = document.getElementById('additionalinfo')
    if (event.ctrlKey) {
      if (activeElement === textareaElement) {
        const computedStyle = window.getComputedStyle(textareaElement);
        const currentHeight = parseInt(computedStyle.getPropertyValue('height'), 10);
        const currentRows = parseInt(textareaElement.getAttribute('rows'), 10)
        if (event.key === 'ArrowUp' && currentRows > 1) {
          textareaElement.setAttribute('rows', `${currentRows - 1}`)
          textareaElement.style.height = `${currentHeight - 10}px`;
        } else if (event.key === 'ArrowDown') {
          textareaElement.setAttribute('rows', `${currentRows + 1}`)
          textareaElement.style.height = `${currentHeight + 10}px`;
        }
      }
    }
  }

  onAdditionalInfoKeyPress(event, val) {
    const element = document.getElementById('additionalinfo');
    if (event.key === " ") {
      const label = `${(val + 1)} out of 255 characters`
      element.setAttribute('aria-label', label)
    }
  }


  onPasteCheckNumber(event: ClipboardEvent): void {
    const d = event.clipboardData.getData('text/plain');
    if (this.validateNumber(d)) {
      event.preventDefault();
    }
  }

  validateNumber(value: string): boolean {
    return /^\d+$/.test(value);
  }

  onNumericKeyPressDown(event: KeyboardEvent) {
    if (event.key >= '0' && event.key <= '9') {
      event.preventDefault();
    }
  }

}
