<div class="page-bg my-order-container">
  <main aria-labelledby="myOrdersHeader">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex gap-2">
        <img class="quoteLogo" src="../../../assets/images/My-Quote.svg" />
        <h1 class="my-quote-header" id="myOrdersHeader">
          <strong>{{aemLabelAuthoring?.pageHeader?.pageHeaderLabel}}</strong>
        </h1>
      </div>
      <div class="alignNewquote d-flex">
        <div *ngIf="isReadWriteOnQuoteMgmt && cidnSelected !== infraCoCIDN">
          <button type="button" id="" class="app-btn app-btn-white mr-20" aria-label="New Single Quote"
          (click)="navigateToCreateQuote()">
          {{aemLabelAuthoring?.pageHeader?.newQuote}}
        </button>
          <button type="button" id="" class="app-btn app-btn-white" aria-label="New Bulk Quote"
            (click)="navigateToCreateBulkQuote()">
            {{aemLabelAuthoring?.pageHeader?.newBulkQuote}}
          </button>
        </div>
      </div>    
    </div>

    <div class="col-12 colPrefBtnDiv row">

      <div class="col-8 search-grid-container">
        <div class="searchGrid col-12">
          <span>
            <input maxlength="255" type="text" class="searchInputfilterQuote" id="text" (input)="onInputChange()"
              [placeholder]="aemLabelAuthoring?.pageHeader?.searchLabel" [(ngModel)]="searchvalue" />
          </span>
          <span class="col-xs-12 col-sm-3 col-md-4 col-lg-3 idsearch">
            <button type="button" style="height: 42px;font-size: 15px;font-family: 'HelveticaNeue'"
              class="primary-globalbtn-cls" aria-label="Search" (click)="quickSearch()"
              [disabled]="!searchvalue || searchvalue?.length < 3">{{aemLabelAuthoring?.pageHeader?.search}}</button>
          </span>
          <span class="col-xs-12 col-sm-3 col-md-3 col-lg-3 idsearch">
            <button type="button" class="texttype-globalbtn-cls" [disabled]="!searchvalue" (click)="clearsearch()"
              aria-label="Clear Search">{{aemLabelAuthoring?.pageHeader?.clear}}</button>
          </span>
        </div>
      </div>
      <div class="colPref col-4">
        <button [disabled]="!selectedRows.length" [ngClass]="{'disabledDwn-button': !selectedRows.length}"
          class="quoteColPref download-btn" tabindex="0" aria-label="Download" type="button" role="button"
          (click)="downloadSummary()">
          <img class="downloadIcon" *ngIf="selectedRows.length" src="../../../../assets/images/icon-download.svg" alt="download icon" />
          <img class="downloadIcon" *ngIf="!selectedRows.length" src="../../../../assets/images/icon-download-disabled.svg" alt="download icon disabled" />
         
          {{aemLabelAuthoring?.pageHeader?.download}}
        </button>
        <!-- <button tabindex="0" class="quoteColPref" (keypress)="showPop()" (click)="showPop()"
          aria-label="Column preferences">
          <img class="downloadIcon" src="../../../assets/images/icon-column-preferences.svg"
            alt="column preference icon" /> {{aemLabelAuthoring?.pageHeader?.colPref}}
        </button> -->
        <button role="button" type="button" id="colPref" tabindex="0" class="quoteColPref" aria-labelledby="col-pref-dialog" aria-modal="true" [aria-label]="aemLabelAuthoring?.pageHeader?.colPref"
        (click)="showPop()">
       <img class="downloadIcon" src="../../../assets/images/icon-column-preferences.svg"
         alt="" /> {{aemLabelAuthoring?.pageHeader?.colPref}}
     </button>
        <div *ngIf="showFilterPop" class="custom-arrow-up-column"></div>
        <div class="drop-show" id="colPrefTabUsr" *ngIf="showFilterPop">
          <!-- <label [aria-label]="aemLabelAuthoring?.pageHeader?.colPrefOpen" tabindex="0"
            style="position:absolute;left:-9999px">{{aemLabelAuthoring?.pageHeader?.colPrefOpen}}</label> -->
            <label style="position:absolute;left:-9999px" id="col-pref-dialog"  aria-label="column preferences pop up opens">{{aemLabelAuthoring?.pageHeader?.colPrefOpen}}</label>

          <span class="headCls">
            <label class="col-10 labelPref">{{aemLabelAuthoring?.pageHeader?.colPref}}</label>
            <button class="closePop" tabindex="0" id="fir" aria-label="Close column preferences"
              (click)="closePopup()"><i class="icon-icon-ui-cross"></i></button>
          </span>
          <div class="popup">
            <div class="decorPop">
              <label [aria-label]="aemLabelAuthoring?.pageHeader?.selectPref" class="colmPrefHeaderDiv" >{{aemLabelAuthoring?.pageHeader?.selectPref}}</label>
              <ng-container *ngFor="let a of functionList; index as i">
                <label class="labelItm" attr.aria-labelledby="span_{{i}}" for="input_{{i}}" class="mainColPref" (keydown)="onCheckboxKeydown($event, a)">
                  <input type="checkbox" tabindex="0" class="ijm" [(ngModel)]="a.checked" name="name_{{i}}" class="ijm"
                    id="input_{{i}}"
                    (change)="getSelectedValue(a.checked,a.value,a.colId,a.tooltipField,a.filter,a.headerName,a.fullHeaderName,a.customFilter,a.cellRendererFramework)"
                    (keydown)="a.checked" />
                  <span [attr.aria-label]="a.name" attr.aria-checked="{{a.checked}}" role="checkbox" class="checkicon"
                    tabindex="0"></span>
                  {{a.name}}
                </label>
              </ng-container>
            </div>
          </div>
          <div class="saveCancelBlock">
            <button class="closePop accessibilty-last-cross" id="rmv" aria-label="Close column preferences" (click)="closePopup()" (keydown.enter)="closePopup()" (keydown.space)="closePopup()">
              <i class="icon-icon-ui-cross"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
  <div class="toastrContainer" aria-live="assertive" role="alert" toastContainer></div>
  <div class="grid-container my-quotes-grid" *ngIf="gridConfig && gridInitialized && columnsPresent">
    <app-cui-ag-grid [model]="gridModel" (selectedOnChanged)="onSelectionChanged($event)"
      (buttonClicked)="navigateToCreateUser()" (focusOnTop)="focusOnTop($event)"
      (filtersOnChanged)="onFilterSelected($event)" (rowClicked)="onRowClicked($event)" [rowSelection]="'multiple'"
      [suppressRowClickSelection]="true">
    </app-cui-ag-grid>
  </div>
  <div *ngIf="!columnsPresent" class="no-column"> No columns selected </div>
</div>
