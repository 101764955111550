<div class="custom-arrow-up"></div>
<div class="custom-filter">
  <h3>{{customFilterObj?.fullHeaderName}}
    <a 
      class="closefilterclass" 
      tabindex="0" 
      (click)="closeFilter($event)" 
      aria-label="click to close filter" 
      (keypress)="closeFilter('keypress')" ><i class="icon-icon-ui-cross icon-xs closeicon"></i></a>
  </h3>
  <div class="container">
    <label 
      class="filter-type" 
      for="{{filtersArray[0].elementData}}">Filter by {{customFilterObj?.fullHeaderName}}
    </label>
    <form 
      #checkboxForm="ngForm" 
      (submit)="onApplyFilters()">
      <div 
        class="form-control" 
       >
          <label class="labelItm" 
          *ngFor="let item of filtersArray" 
          class="mainColPref">
            <input type="checkbox" tabindex="0" 
            class="ijm" [(ngModel)]="item.Checked"
            id="{{item.elementData}}" 
            value="{{item.elementData}}" 
            [name]="item.elementData" 
            (change)="selectCheckbox()"
            (keypress)="item.checked" />
            <span class="checkicon" tabindex="0" 
            role="button"></span>
            {{item.elementData}}
          </label>
      </div>
      <button 
        id="btnApply" tabindex="0"
        disabled="disabled" 
        aria-label="Apply Filter" 
        (click)="onApplyFilters()" 
        class="secondary-btn">Apply filter/s
      </button>
    </form>
  </div>
</div>

