import { Component, OnInit ,Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-terms-and-conditions-renderer',
  templateUrl: './terms-and-conditions-renderer.component.html',
  styleUrls: ['./terms-and-conditions-renderer.component.less']
})
export class TermsAndConditionsRendererComponent implements OnInit {
  @Output() isModalOpen = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  closeModal(){
    this.isModalOpen.emit(false);
  }

}
