
import { throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'tw-core-ui';

@Injectable()
export class ReviewSubmitService {
  spinnerflag = false;

  private env: any = window['environment'];

  private aemDefaultUri = {
    reviewSubmit: 'review.json',
    reviewDetails: 'review-details-label.json'
  };
  public reviewDetailsLabelAuthoringDataObj: any;
  public reviewSubmitLabelAuthoringDataObj: any;
  constructor(private http: HttpClient, private authService: AuthService) { }
 
  public getCabinetsAccessModel() {
    return new Promise((resolve, reject) => {
      const reviewsubmitdetailsUrl = this.env.aemEndPoint + this.aemDefaultUri.reviewSubmit;
      this.http.get<any>(reviewsubmitdetailsUrl)
        .subscribe(
          res => {
            this.reviewSubmitLabelAuthoringDataObj = res;
            resolve(this.reviewSubmitLabelAuthoringDataObj);
          },
          err => {
            this.reviewSubmitLabelAuthoringDataObj = null;
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }

  public getReviewDetailsPageLabel() {
    // this.sendSpinnerFlag.next(true);
    return new Promise((resolve, reject) => {
      const reviewDetailsUrl = this.env.aemEndPoint + this.aemDefaultUri.reviewDetails;
      this.http.get<any>(reviewDetailsUrl)
        .subscribe(
          res => {
            this.reviewDetailsLabelAuthoringDataObj = res;
            resolve(this.reviewDetailsLabelAuthoringDataObj);
          },
          err => {
            this.reviewDetailsLabelAuthoringDataObj = null;
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }

}
