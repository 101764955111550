<div class="order-review-details-container">
  <div class="header-details">
    <div class="toastrContainer" aria-live="assertive" role="alert" toastContainer></div>
    <div class="row">
      <div class="col-4 page-header-container">
        <main aria-labelledby="orderReviewDetailsHeader">
        <h1 class="new-order-label" id="orderReviewDetailsHeader" aria-label="New Order" >{{aemLabelAuthoring?.pageHeader.newOrderHeaderlabel}}</h1></main>
        <div class="quote-ref-id"><span aria-label="Quote reference" class="color-grey-41 font-16" >{{aemLabelAuthoring?.pageHeader.pageSubHeaderLabel}}</span> <b class="font-16 color-black"> {{orderReviewDetailsData?.orderReferenceId}}</b> <span *ngIf="isSaved" class="pl-20 font-16 fw-500 color-black"><img src="../../../../assets/images/Submitted.svg" class="saved-icon" /> {{aemLabelAuthoring?.saved}}</span></div>

        <div class="quote-ref-id"><span aria-label="Customer reference" class="color-grey-41 font-16">{{aemLabelAuthoring?.customerRef}}: </span> <b class="font-16 color-black"> {{orderReviewDetailsData?.customerReference}}</b> </div>
      </div>
      <div class="col-5"  aria-label="stepperData">
        <app-page-stepper [stepperData]="stepperData" [stepName]="stepName"></app-page-stepper>
      </div>
    </div>

  </div>

  <div *ngIf="isSaveApiError" class="error-section w-auto p-3 offset-md-4"  >
    <small>
    <img class="err-icon" alt="error icon"
            src="../../../../assets/images/icon-error.svg" />{{isSaveApiError}}
        </small>
  </div>

<!-- Product Address -->
    <div class="mt-45 bg-grey product-address ">
      <div class="d-flex w-100 pt-45 pb-45" >
        <div style="padding-left: 47px;"  aria-label="product-icon" role="image" name="product-icon">
          <img class="product-icon" alt="Product Icon" src="../../../../assets/images/Product.svg"  />
        </div>
        <div class="pl-50" >
          <div class="font-bold font-22 clr-blk" attr.aria-label="{{aemLabelAuthoring?.product}}">{{aemLabelAuthoring?.product}}</div>
          <div class="font-16"  attr.aria-label="{{orderReviewDetailsData?.productName || '-'}}">{{orderReviewDetailsData?.productName || '-'}}</div>
        </div>
        <div class="pl-100" style="width: 45%;margin-right: 20px;" >
          <div class="font-bold font-22 clr-blk"  attr.aria-label="{{aemLabelAuthoring?.address}}">{{aemLabelAuthoring?.address}}</div>
          <div class="pb-10 font-14"  attr.aria-label="{{aemLabelAuthoring?.siteAendAddressInfo}}">
            {{aemLabelAuthoring?.siteAendAddressInfo}}</div>
          <div class="font-16 clr-blk"  attr.aria-label="{{orderReviewDetailsData?.aEndAdress?.address || '-'}}">{{orderReviewDetailsData?.aEndAdress?.address || '-'}}</div>
          <div class="font-16 pt-15" *ngIf="orderReviewDetailsData?.aEndAdress?.siteAlternativeName"  attr.aria-label="{{orderReviewDetailsData?.aEndAdress?.siteAlternativeName}}">
            {{orderReviewDetailsData?.aEndAdress?.siteAlternativeName}}</div>
        </div>
        <div style="width: 62%;">
          <div style="width: 62%;">
            <div class="pt-28 pb-10 font-14"  attr.aria-label="{{aemLabelAuthoring?.siteZendAddressInfo}}">
              {{aemLabelAuthoring?.siteZendAddressInfo}}</div>
            <div class="font-16 clr-blk"  attr.aria-label="{{orderReviewDetailsData?.zEndAdress?.address || '-'}}">{{orderReviewDetailsData?.zEndAdress?.address || '-'}}</div>
            <div class="font-16 pt-15" *ngIf="orderReviewDetailsData?.zEndAdress?.siteAlternativeName" attr.aria-label="{{orderReviewDetailsData?.zEndAdress?.siteAlternativeName}}">
              {{orderReviewDetailsData?.zEndAdress?.siteAlternativeName}}</div>
          </div>

        </div>
      </div>
    </div>

    <div class="site-details-container">
    <!-- Site A end Address Details -->
      <div >
        <div class="d-flex w-100 mt-45 align-items-center  justify-content-between align-items-center">
          <div class="d-flex align-items-center gap-3">
            <div><img src="../../../../assets/images/LocationPinBlack.svg" alt=""></div>
            <h2 class="font-24 font-bold color-black font-weight" >{{aemLabelAuthoring?.siteAendAddress}}</h2>
          </div>
        </div>

        <hr />

        <div class="pb-45" *ngIf="orderReviewDetailsData?.aEndAdress?.serviceDeliveryPoint || orderReviewDetailsData?.aEndAdress?.poleId ||
        orderReviewDetailsData?.aEndAdress?.nodeCode || orderReviewDetailsData?.aEndAdress?.leadInRequired">
          <div class="row">
            <div class="col-3 text-wrap" *ngIf="orderReviewDetailsData?.aEndAdress?.serviceDeliveryPoint">
              <div class="font-14 color-grey-41" attr.aria-label="{{aemLabelAuthoring?.serviceDeliveryPoint}}">{{aemLabelAuthoring?.serviceDeliveryPoint}}</div>
              <div class="font-16 clr-blk"   attr.aria-label="{{orderReviewDetailsData?.aEndAdress?.serviceDeliveryPoint}}">{{orderReviewDetailsData?.aEndAdress?.serviceDeliveryPoint}}</div>
            </div>
            <div class="col-3 text-wrap" *ngIf="orderReviewDetailsData?.aEndAdress?.poleId">
              <ng-container *ngIf="orderReviewDetailsData?.serviceType === 'RG' && orderReviewDetailsData?.businessDriver === '5G Small Cell' && orderReviewDetailsData?.aEndAdress?.siteName === 'NON-PREMISE'">
              <div class="font-14 color-grey-41" attr.aria-label="{{aemLabelAuthoring?.poleId}}">{{aemLabelAuthoring?.poleId}}</div>
              <div class="font-16 clr-blk" attr.aria-label="{{orderReviewDetailsData?.aEndAdress?.poleId}}">{{orderReviewDetailsData?.aEndAdress?.poleId}}</div>
              </ng-container>
            </div>
            <div class="col-3 text-wrap" *ngIf="orderReviewDetailsData?.aEndAdress?.nodeCode">
              <ng-container *ngIf="orderReviewDetailsData?.serviceType === 'RG' && orderReviewDetailsData?.businessDriver === '5G Small Cell'">
                <div class="font-14 color-grey-41"  attr.aria-label="{{aemLabelAuthoring?.nodeCode}}">{{aemLabelAuthoring?.nodeCode}}</div>
                <div class="font-16 clr-blk"  attr.aria-label="{{orderReviewDetailsData?.aEndAdress?.nodeCode}}">{{orderReviewDetailsData?.aEndAdress?.nodeCode}}</div>
              </ng-container>
            </div>
            <div class="col-3 text-wrap" *ngIf="orderReviewDetailsData?.aEndAdress?.leadInRequired">
              <ng-container *ngIf="orderReviewDetailsData?.serviceType === 'RG' && orderReviewDetailsData?.businessDriver === '5G Small Cell' && orderReviewDetailsData?.aEndAdress?.siteName === 'NON-PREMISE'">
              <div class="font-14 color-grey-41"  attr.aria-label="{{aemLabelAuthoring?.leadInRequired}}">{{aemLabelAuthoring?.leadInRequired}}</div>
              <div class="font-16 clr-blk"  attr.aria-label="{{orderReviewDetailsData?.aEndAdress?.leadInRequired}}">{{orderReviewDetailsData?.aEndAdress?.leadInRequired}}</div>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="pb-50" *ngIf="orderReviewDetailsData?.aEndAdress?.additionalAddressInfo">
          <div class="row">
            <div class="col text-wrap" >
              <div class="font-14 color-grey-41" attr.aria-label="{{aemLabelAuthoring?.additionalAddressInformation}}">{{aemLabelAuthoring?.additionalAddressInformation}}</div>
              <div class="font-16 clr-blk" attr.aria-label="{{orderReviewDetailsData?.aEndAdress?.additionalAddressInfo}}">{{orderReviewDetailsData?.aEndAdress?.additionalAddressInfo}}</div>
            </div>
          </div>
        </div>

        <div class="pb-45">
          <div class="font-18 font-bold clr-blk pb-15"  attr.aria-label="{{aemLabelAuthoring?.siteContactDetails}}">{{aemLabelAuthoring?.siteContactDetails}}</div>
          <div class="row">
            <div class="col-4 text-wrap" *ngIf="orderReviewDetailsData?.aEndAdress?.orderSiteContactDetails[0]?.primarySiteContactName">
              <div class="font-14 color-grey-41" attr.aria-label="{{aemLabelAuthoring?.siteContactName}}">{{aemLabelAuthoring?.siteContactName}}</div>
              <div class="font-16 clr-blk" attr.aria-label="{{orderReviewDetailsData?.aEndAdress?.orderSiteContactDetails[0]?.primarySiteContactName}}">{{orderReviewDetailsData?.aEndAdress?.orderSiteContactDetails[0]?.primarySiteContactName}}</div>
            </div>
            <div class="col-4 text-wrap" *ngIf="orderReviewDetailsData?.aEndAdress?.orderSiteContactDetails[0]?.primarySiteContactNumber">
              <div class="font-14 color-grey-41" attr.aria-label="{{aemLabelAuthoring?.siteContactNumber}}">{{aemLabelAuthoring?.siteContactNumber}}</div>
              <div class="font-16 clr-blk"  attr.aria-label="{{orderReviewDetailsData?.aEndAdress?.orderSiteContactDetails[0]?.primarySiteContactNumber}}">{{orderReviewDetailsData?.aEndAdress?.orderSiteContactDetails[0]?.primarySiteContactNumber}}</div>
            </div>
            <div class="col-4 text-wrap" *ngIf="orderReviewDetailsData?.aEndAdress?.orderSiteContactDetails[0]?.primarySiteContactEmail">
              <div class="font-14"  attr.aria-label="{{aemLabelAuthoring?.siteContactEmail}}">{{aemLabelAuthoring?.siteContactEmail}}</div>
              <div class="font-16 clr-blk" attr.aria-label="{{orderReviewDetailsData?.aEndAdress?.orderSiteContactDetails[0]?.primarySiteContactEmail}}">{{orderReviewDetailsData?.aEndAdress?.orderSiteContactDetails[0]?.primarySiteContactEmail}}</div>
            </div>

          </div>
        </div>
      </div>

      <!-- Site Z end Address Details-->
      <div>
        <div class="d-flex w-100 mt-45 align-items-center  justify-content-between align-items-center">
          <div class="d-flex align-items-center gap-3">
            <div><img src="../../../../assets/images/LocationPinBlack.svg" alt=""></div>
            <h2 class="font-24 font-bold color-black font-weight">{{aemLabelAuthoring?.siteZendAddress}}</h2>
          </div>
        </div>

        <hr />

        <div class="pb-45" *ngIf="orderReviewDetailsData?.zEndAdress?.serviceDeliveryPoint || orderReviewDetailsData?.zEndAdress?.poleId ||
        orderReviewDetailsData?.zEndAdress?.nodeCode || orderReviewDetailsData?.zEndAdress?.leadInRequired">
          <div class="row">
            <div class="col-3 text-wrap" *ngIf="orderReviewDetailsData?.zEndAdress?.serviceDeliveryPoint">
              <div class="font-14 color-grey-41"  attr.aria-label="{{aemLabelAuthoring?.serviceDeliveryPoint}}">{{aemLabelAuthoring?.serviceDeliveryPoint}}</div>
              <div class="font-16 clr-blk"  attr.aria-label="{{orderReviewDetailsData?.zEndAdress?.serviceDeliveryPoint}}">{{orderReviewDetailsData?.zEndAdress?.serviceDeliveryPoint}}</div>
            </div>
            <div class="col-3 text-wrap" *ngIf="orderReviewDetailsData?.zEndAdress?.poleId">
              <ng-container *ngIf="orderReviewDetailsData?.serviceType === 'RG' && orderReviewDetailsData?.businessDriver === '5G Small Cell' && orderReviewDetailsData?.zEndAdress?.siteName === 'NON-PREMISE'">
              <div class="font-14 color-grey-41"  attr.aria-label="{{aemLabelAuthoring?.poleId}}">{{aemLabelAuthoring?.poleId}}</div>
              <div class="font-16 clr-blk"  attr.aria-label="{{orderReviewDetailsData?.zEndAdress?.poleId || '-'}}">{{orderReviewDetailsData?.zEndAdress?.poleId || '-'}}</div>
            </ng-container>
            </div>
            <div class="col-3 text-wrap" *ngIf="orderReviewDetailsData?.zEndAdress?.nodeCode">
              <ng-container *ngIf="orderReviewDetailsData?.serviceType === 'RG' && orderReviewDetailsData?.businessDriver === '5G Small Cell'">
              <div class="font-14 color-grey-41">{{aemLabelAuthoring?.nodeCode}}</div>
              <div class="font-16 clr-blk">{{orderReviewDetailsData?.zEndAdress?.nodeCode}}</div>
            </ng-container>
            </div>
            <div class="col-3 text-wrap" *ngIF="orderReviewDetailsData?.zEndAdress?.leadInRequired">
              <ng-container *ngIf="orderReviewDetailsData?.serviceType === 'RG' && orderReviewDetailsData?.businessDriver === '5G Small Cell' && orderReviewDetailsData?.zEndAdress?.siteName === 'NON-PREMISE'">
                <div class="font-14 color-grey-41"  attr.aria-label="{{aemLabelAuthoring?.leadInRequired}}">{{aemLabelAuthoring?.leadInRequired}}</div>
                <div class="font-16 clr-blk"  attr.aria-label="{{orderReviewDetailsData?.zEndAdress?.leadInRequired}}">{{orderReviewDetailsData?.zEndAdress?.leadInRequired}}</div>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="pb-50" *ngIf="orderReviewDetailsData?.zEndAdress?.additionalAddressInfo">
          <div class="row" >
            <div class="col text-wrap" >
              <div class="font-14 color-grey-41 pad-b-5"  attr.aria-label="{{aemLabelAuthoring?.additionalAddressInformation}}">{{aemLabelAuthoring?.additionalAddressInformation}}</div>
              <div class="font-16 clr-blk"  attr.aria-label="{{orderReviewDetailsData?.zEndAdress?.additionalAddressInfo}}">{{orderReviewDetailsData?.zEndAdress?.additionalAddressInfo}}</div>
            </div>
          </div>
        </div>

        <div class="pb-45">
          <div class="font-18 font-bold clr-blk pb-15">{{aemLabelAuthoring?.siteContactDetails}}</div>
          <div class="row">
            <div class="col-4 text-wrap" *ngIf="orderReviewDetailsData?.zEndAdress?.orderSiteContactDetails[0]?.primarySiteContactName">
              <div class="font-14 color-grey-41"   attr.aria-label="{{aemLabelAuthoring?.siteContactName}}">{{aemLabelAuthoring?.siteContactName}}</div>
              <div class="font-16 clr-blk"  attr.aria-label="{{orderReviewDetailsData?.zEndAdress?.orderSiteContactDetails[0]?.primarySiteContactName}}">{{orderReviewDetailsData?.zEndAdress?.orderSiteContactDetails[0]?.primarySiteContactName}}</div>
            </div>
            <div class="col-4 text-wrap" *ngIf="orderReviewDetailsData?.zEndAdress?.orderSiteContactDetails[0]?.primarySiteContactNumber">
              <div class="font-14 color-grey-41"  attr.aria-label="{{aemLabelAuthoring?.siteContactNumber}}">{{aemLabelAuthoring?.siteContactNumber}}</div>
              <div class="font-16 clr-blk"  attr.aria-label="{{orderReviewDetailsData?.zEndAdress?.orderSiteContactDetails[0]?.primarySiteContactNumber}}">{{orderReviewDetailsData?.zEndAdress?.orderSiteContactDetails[0]?.primarySiteContactNumber}}</div>
            </div>
            <div class="col-4 text-wrap" *ngIf="orderReviewDetailsData?.zEndAdress?.orderSiteContactDetails[0]?.primarySiteContactEmail">
              <div class="font-14"  attr.aria-label="{{aemLabelAuthoring?.siteContactEmail}}">{{aemLabelAuthoring?.siteContactEmail}}</div>
              <div class="font-16 clr-blk"  attr.aria-label="{{orderReviewDetailsData?.zEndAdress?.orderSiteContactDetails[0]?.primarySiteContactEmail}}">{{orderReviewDetailsData?.zEndAdress?.orderSiteContactDetails[0]?.primarySiteContactEmail}}</div>
            </div>

          </div>
        </div>
      </div>

      <!-- Product Details -->
      <div class="pb-45">
        <div class="d-flex w-100  align-items-center  justify-content-between align-items-center">
          <div class="d-flex align-items-center gap-3">
            <div><img src="../../../../assets/images/Details-Doc.svg" alt=""></div>
            <h2 class="font-24 font-bold color-black font-weight" >{{aemLabelAuthoring?.productDetails}}</h2>
          </div>
        </div>
        <hr />

        <div>
          <div class="row" *ngIf="orderReviewDetailsData?.linkRequirement">
            <div class="col" >
              <div class="font-14 color-grey-41"  attr.aria-label="{{aemLabelAuthoring?.fiberRequirement}}">{{aemLabelAuthoring?.fiberRequirement}}</div>
              <div class="font-16 clr-blk"  attr.aria-label="{{orderReviewDetailsData?.linkRequirement}}">{{orderReviewDetailsData?.linkRequirement}}</div>
            </div>
          </div>
        </div>
      </div>

         <!-- Customer Contact  Details -->
         <div class="pb-45">
          <div class="d-flex w-100  align-items-center  justify-content-between align-items-center">
            <div class="d-flex align-items-center gap-3">
              <div><img src="../../../../assets/images/Details-Doc.svg" alt=""></div>
              <h2 class="font-24 font-bold color-black font-weight" >{{aemLabelAuthoring?.customerContactDetails}}</h2>
            </div>
          </div>
          <hr />

          <div >
            <div class="row">
              <div class="col-3 text-wrap" *ngIf="orderReviewDetailsData?.customerContactName">
                <div class="font-14 color-grey-41"  attr.aria-label="{{aemLabelAuthoring?.contactName}}">{{aemLabelAuthoring?.contactName}}</div>
                <div class="font-16 clr-blk"  attr.aria-label="{{orderReviewDetailsData?.customerContactName}}">{{orderReviewDetailsData?.customerContactName}}</div>
              </div>
              <div class="col-3 text-wrap" *ngIf="orderReviewDetailsData?.customerContactNumber">
                <div class="font-14 color-grey-41"  attr.aria-label="{{aemLabelAuthoring?.contactNumber}}">{{aemLabelAuthoring?.contactNumber}}</div>
                <div class="font-16 clr-blk"   attr.aria-label="{{orderReviewDetailsData?.customerContactNumber}}">{{orderReviewDetailsData?.customerContactNumber}}</div>
              </div>
              <div class="col-3 text-wrap" *ngIf="orderReviewDetailsData?.customerContactEmail">
                <div class="font-14 color-grey-41"  attr.aria-label="{{aemLabelAuthoring?.contactEmail}}">{{aemLabelAuthoring?.contactEmail}}</div>
                <div class="font-16 clr-blk"  attr.aria-label="{{orderReviewDetailsData?.customerContactEmail}}" style="line-height: 1.3em;">{{orderReviewDetailsData?.customerContactEmail}}</div>
              </div>
            </div>
          </div>
        </div>


         <!-- Customer Contact  Details -->
         <div class="pb-45 mr-20">
          <div class="d-flex w-100  align-items-center  justify-content-between align-items-center">
            <div class="d-flex align-items-center gap-3">
              <div><img src="../../../../assets/images/Details-Doc.svg" alt=""></div>
              <h2 class="font-24 font-bold color-black font-weight" >{{aemLabelAuthoring?.references}}</h2>
            </div>
          </div>
          <hr />

          <div class="pb-40">
            <div class="row">
              <div class="col-3 text-wrap" *ngIf="orderReviewDetailsData?.customerRequestedDate">
                <div class="font-14 color-grey-41"  attr.aria-label="{{aemLabelAuthoring?.customerRequestedDate}}">{{aemLabelAuthoring?.customerRequestedDate}}</div>
                <div class="font-16 clr-blk"  attr.aria-label="{{orderReviewDetailsData?.customerRequestedDate}}">{{orderReviewDetailsData?.customerRequestedDate}}</div>
              </div>
              <div class="col-3 text-wrap" *ngIf="orderReviewDetailsData?.projectReferenceId">
                <div class="font-14 color-grey-41"   attr.aria-label="{{aemLabelAuthoring?.projectID}}">{{aemLabelAuthoring?.projectID}}</div>
                <div class="font-16 clr-blk"   attr.aria-label="{{orderReviewDetailsData?.projectReferenceId}}">{{orderReviewDetailsData?.projectReferenceId}}</div>
              </div>
              <div class="col-3 text-wrap" *ngIf="orderReviewDetailsData?.opportunityId">
                <div class="font-14 color-grey-41"   attr.aria-label="{{aemLabelAuthoring?.oppurtunityNo}}">{{aemLabelAuthoring?.oppurtunityNo}}</div>
                <div class="font-16 clr-blk"   attr.aria-label="{{orderReviewDetailsData?.opportunityId}}">{{orderReviewDetailsData?.opportunityId}}</div>
              </div>
               <div class="col-3 text-wrap" *ngIf="orderReviewDetailsData?.clusterId">
                <ng-container *ngIf="orderReviewDetailsData?.serviceType === 'RG' && orderReviewDetailsData?.businessDriver === '5G Small Cell'">
                <div class="font-14 color-grey-41"   attr.aria-label="{{aemLabelAuthoring?.clusterSSID}}">{{aemLabelAuthoring?.clusterSSID}}</div>
                <div class="font-16 clr-blk"   attr.aria-label="{{orderReviewDetailsData?.clusterId}}">{{orderReviewDetailsData?.clusterId}}</div>
                </ng-container>
              </div>
            </div>
            <div class="row pt-50 text-wrap">
              <div class="col-3" *ngIF="orderReviewDetailsData?.miragepclId">
                <ng-container *ngIf="orderReviewDetailsData?.serviceType === 'RG' && orderReviewDetailsData?.businessDriver === '5G Small Cell'">
                  <div class="font-14 color-grey-41"   attr.aria-label="{{aemLabelAuthoring?.miragePCLID}}">{{aemLabelAuthoring?.miragePCLID}}</div>
                  <div class="font-16 clr-blk"   attr.aria-label="{{orderReviewDetailsData?.miragepclId}}">{{orderReviewDetailsData?.miragepclId}}</div>
                </ng-container>
              </div>
              <div class="col-6 text-wrap">

                <ng-container *ngIf="orderReviewDetailsData?.externalReference?.length">
                  <div class="row brdr-b-grey-70 pb-11">
                    <div class="col-6">
                      <div class="font-14 color-grey-41"   attr.aria-label="{{aemLabelAuthoring?.externalReference}}">{{aemLabelAuthoring?.externalReference}}</div>
                    </div>
                    <div class="col-6">
                      <div class="font-14 color-grey-41"   attr.aria-label="{{aemLabelAuthoring?.value}}">{{aemLabelAuthoring?.value}}</div>
                    </div>
                  </div>
                  <ng-container *ngFor="let e of orderReviewDetailsData?.externalReference">
                    <div class="row brdr-b-grey-70 pb-13 pt-13">
                      <div class="col-6">
                        <div class="font-16 clr-blk"   attr.aria-label="{{e?.externalReferenceType || '-'}}">{{e?.externalReferenceType || '-'}}</div>
                      </div>
                      <div class="col-6">
                        <div class="font-16 clr-blk"   attr.aria-label="{{e?.externalReferenceValue || '-'}}">{{e?.externalReferenceValue || '-'}}</div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>

              </div>
              <div class="col-3"></div>
            </div>
          </div>
        </div>

        <div *ngIf="orderReviewDetailsData?.additionalInformation">
          <div class="row">
            <div class="col text-wrap" >
              <div class="font-14 color-grey-41 pad-b-5"   attr.aria-label="{{aemLabelAuthoring?.additionalInformation}}">{{aemLabelAuthoring?.additionalInformation}}</div>
              <div class="font-16 clr-blk"   attr.aria-label="{{orderReviewDetailsData?.additionalInformation}}">{{orderReviewDetailsData?.additionalInformation}}</div>
            </div>
          </div>
        </div>
    </div>


<!-- Nav -->
<div class="row pb-45 pr-40">
  <div class="col-12">
    <div class="submitCancelBtn btnRow">
      <button mat-button  class="back-btn cancelBtn save-cancel-btn"  aria-label="back" tabindex="0" (keypress.enter)="back()" (click)="back()" >
        <span class="cancel-btn">
          <div class="btn-svg back-icon" attr.aria-label="{{aemLabelAuthoring?.button.back}}"></div>
          {{aemLabelAuthoring?.button.back}}
        </span>
      </button>
      <button mat-button class="cancelBtn save-cancel-btn"  tabindex="0" aria-label="cancel" (keypress.enter)="cancel()" (click)="cancel()" >
        <span class="cancel-btn"><i class="icon-icon-ui-cross"></i>
          {{aemLabelAuthoring?.button.cancel}}
        </span>
      </button>

      <button  class="app-btn-green app-btn-white save-cancel-btn" [disabled]="isSavingInProgress"  tabindex="0"  aria-label="save" (keypress.enter)="saveOrderReviewDetails('Save')" (click)="saveOrderReviewDetails('Save')" >
        <div class="btn-svg save-icon"  attr.aria-label="{{aemLabelAuthoring?.button.save}}"></div>
        {{aemLabelAuthoring?.button.save}}
      </button>
      <label role="alert" *ngIf="isOrderSaved" style="position:absolute;left:-9999px">Successfully saved</label>
      <button mat-button class="app-btn-green save-cancel-btn"  tabindex="0" (click)="saveOrderReviewDetails('Submit', 'success')"   attr.aria-label="{{aemLabelAuthoring?.button.submit}}" (keypress.enter)="saveOrderReviewDetails('Submit', 'success')">
        {{aemLabelAuthoring?.button.submit}}
        <!-- <div class="btn-svg next-icon"></div> -->
      </button>



    </div>
</div>
</div>
