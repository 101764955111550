import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nbn-user',
  templateUrl: './nbn-user-home.component.html',
  styleUrls: ['./nbn-user-home.component.css']
})
export class NbnUserHomeComponent implements OnInit {
  landingPageFlag: boolean;
  documentViewFlag: boolean;

  ngOnInit() {
    this.landingPageFlag = true;
    this.documentViewFlag = false;
  }

  showDocumentView() {
    this.landingPageFlag = false;
    this.documentViewFlag = true;
  }

  showLandingPage() {
    this.landingPageFlag = true;
    this.documentViewFlag = false;
  }
}

