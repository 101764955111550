import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { DashboardService } from '../../app/cabinets/dashboard.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { CommonService } from '../shared/services/common.service';


@Component({
  selector: 'app-view-document-renderer',
  templateUrl: './view-document-renderer.component.html',
  styleUrls: ['./view-document-renderer.component.less']
})

export class ViewDocumentRendererComponent implements OnInit {
  public docURL;
  isModalOpen: Boolean = true;
  blobURL;
  cabinetId: string;
  filename;
  filetype;
  showDownloadMessage: string;

  constructor(private router: Router, private sanitizer: DomSanitizer, private dashboardservice: DashboardService, private activeRoute: ActivatedRoute,private commonService:CommonService) {
  }

  async ngOnInit() {
    this.blobURL = this.router.getCurrentNavigation()?.extras.state ? this.router.getCurrentNavigation().extras.state.pdfURL : "";
    this.docURL = (this.blobURL) ? this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.blobURL)) : '';
    this.downloadFile();
    const roles = this.commonService.getroleDetails().customerList;
    if (!roles.some((obj) => obj.role.includes('NBN') || obj.function.includes('NBN'))) {
      this.router.navigateByUrl('/access-denied');
    }
  }

  downloadFile() {
    const routeParams = this.activeRoute.snapshot.params;
    this.filename = decodeURIComponent(routeParams.name);
    this.cabinetId = decodeURIComponent(routeParams.cid);
    this.filetype = this.filename ? this.filename.split('.').pop() : '';
    this.filetype = this.filetype.toLowerCase();
    if (!this.blobURL) {
      this.viewDocumentsFile();
    }
  }

  viewDocumentsFile() {
    this.dashboardservice.downloadFile(`${this.cabinetId}_${this.filename}`).then(async res => {
      if (res) {
        const data: Blob = res['body'];
        if (data.type === "application/json") {
          let content = JSON.parse(await data.text());
          if (content.status == 500 && content.code == 500) {
            this.showDownloadMessage = 'This document is currently unavailable, kindly reach out to support team';
            return;
          }
        }
        const blobtype = this.getFileType(this.filetype);
        const blob = new Blob([data], { type: blobtype });
        if (this.filetype === 'txt' || this.filetype === 'jpeg' || this.filetype === 'jpg' || this.filetype === 'png' || this.filetype === 'pdf') {
          this.docURL = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
        }
        else {
          importedSaveAs(data, this.filename);
          this.showDownloadMessage = 'Please check your Downloads folder to view this file...';
        }
      }
    }).catch((err) => {
      if (err.status === 401) {
        this.router.navigateByUrl('/access-denied');
      } else {
        this.router.navigateByUrl('/error');
      }
    });
  }

  getFileType(filetype) {
    let blobtype;
    filetype = filetype.toLowerCase();
    switch (filetype) {
      case 'txt': blobtype = 'text/plain'
        break;
      case 'docx': blobtype = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        break;
      case 'doc': blobtype = 'application/msword'
        break;
      case 'pdf': blobtype = 'application/pdf'
        break;
      case 'xlsx': blobtype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        break;
      case 'xls': blobtype = 'application/vnd.ms-excel'
        break;
      case 'xlsb': blobtype = 'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
        break;
      case 'png': blobtype = 'image/png'
        break;
      case 'jpg':
      case 'jpeg': blobtype = 'image/jpeg'
        break;
      case 'pptx': blobtype = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        break;
      case 'zip': blobtype = 'application/zip';
        break;
      case 'csv': blobtype = 'text/csv';
        break;
      case 'bmp': blobtype = 'image/bmp';
        break;
      case 'json': blobtype = 'application/json';
        break;
      case 'ppt': blobtype = 'application/vnd.ms-powerpoint';
        break;
      case 'eml': blobtype = 'application/vnd.sealed.eml';
        break;
    }
    return blobtype;
  }
}

