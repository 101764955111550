
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError as observableThrowError, BehaviorSubject } from 'rxjs';
import { AuthService } from 'tw-core-ui';

@Injectable({
  providedIn: 'root'
})
export class HomePageService {

  aPIUrl: string = '';
  public homePageLabelAuthoringDataObj: any;
  private env: any = window['environment'];
  private homeUrl = {
    homePageLabel: 'homepage-codelist',
    userProfile: 'users/profile/',
    getUserPreferences: 'users/preferences/',
    putUserPreferences: 'users/updatePreference',
  };
  appRoleFunctionMappingReferences: any;
  userProfileDetails: any;
  isInfraCoCustomer: boolean;
  isNBNCustomer: boolean;
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  public getLabels() {
    return new Promise((resolve, reject) => {
      if (!this.homePageLabelAuthoringDataObj) {
        const worklistUrl = this.env.aemEndPoint + this.homeUrl.homePageLabel;
        this.http.get<any>(worklistUrl)
          .subscribe(
            res => {
              this.homePageLabelAuthoringDataObj = res.data.content;
              resolve(this.homePageLabelAuthoringDataObj);
            },
            err => {
              this.homePageLabelAuthoringDataObj = null;
              reject(err);
              return observableThrowError(err || 'Server error');
            }
          );
      }
      else {
        resolve(this.homePageLabelAuthoringDataObj);
      }
    });
  }

  public getUserProfile(userName) {
    // this.sendSpinnerFlag.next(true);
    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',

      })
    };
    const url = this.env.apiPoint + this.homeUrl.userProfile + userName;
    // to run in local
   // let url =  this.env.aemEndPoint + 'mock/profile.json';
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(url, httpOptions)
        .subscribe(res => {
          this.appRoleFunctionMappingReferences = res;
          this.userProfileDetails = res;
          resolve(res);
          // this.sendSpinnerFlag.next(false);
        }, err => {
          // this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getPreferences(userName) {
    // this.sendSpinnerFlag.next(true);
    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',

      })
    };
    const url = this.env.apiPoint + this.homeUrl.getUserPreferences + userName;
    // to run in local
   // let url =  this.env.aemEndPoint + 'mock/preferences.json';
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(url, httpOptions)
        .subscribe(res => {
          resolve(res);
          // this.sendSpinnerFlag.next(false);
        }, err => {
          // this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public putPreferences(payload) {
    // this.sendSpinnerFlag.next(true);
    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',
      })
    };
    const url = this.env.apiPoint + this.homeUrl.putUserPreferences;
    return new Promise<any>((resolve, reject) => {
      this.http.put<any>(url, payload, httpOptions)
        .subscribe(res => {
          resolve(res);
          // this.sendSpinnerFlag.next(false);
        }, err => {
          // this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  //role and function mapping of customers as internal service
  getRoleFunctionMapping = () => {
    const actualRolesMapping = this.appRoleFunctionMappingReferences.data.appRoleFunctionMappingReferences;
    return actualRolesMapping;
  }

  getCustomerDetails = () => {
    const custDetails = this.userProfileDetails?.data?.customerDetails;
    if (custDetails?.some(val => val.customerName.toUpperCase() === 'INFRACO')) {
      this.isInfraCoCustomer = true;
      this.isNBNCustomer = false;
    } else if (custDetails?.some(val => val.customerName === 'NBNCo')) {
      this.isNBNCustomer = true;
      this.isInfraCoCustomer = false;
    }
    const customerDetails = {
      isInfraCoCustomer: this.isInfraCoCustomer,
      isNBNCustomer: this.isNBNCustomer,
    }
    return customerDetails;
  }
}