<div class="page-bg">
  <div>
    <div class="row">
      <h2 class="col-2" id="customerList"><strong>My users</strong></h2>
      <div class="col-10 colPrefBtnDiv">
        <ng-container *ngIf="gridConfig && gridInitialized && (isInfracoNbn || isDuctUser)">
          <button tabindex="0" aria-label="download button" type="button" class="texttype-globalbtn-cls"
            (click)="download()">
            <img class="downloadIcon" src="../../../../assets/images/icon-download.svg" alt="upload icon" />
            {{$any(aemLabelAuthoring).form.grid.download}}
          </button>
        </ng-container>
        <div class="colPref">
          <button tabindex="0" class="texttype-globalbtn-cls colPrefBtn" (click)="showPop()"  (keypress)="showPop()">
            <img src="../../../assets/images/icon-column-preferences.svg" alt="column preference icon"
              class="textIcon" />
            Column preferences</button>
          <div class="drop-show" id="colPrefTab"  role="dialog" aria-labelledby="col-pref-dialog" aria-modal="true" (mouseleave)="leaveColPref()" *ngIf="showFilterPop">
            <!-- <label role="alert" style="position:absolute;left:-9999px" aria-live="assertive">Column preference popup opens</label> -->
            <span class="headCls">
              <label class="col-10 labelPref" id="col-pref-dialog">Column preferences</label>
              <button class="closePop" id="fir" tabindex="0" aria-label="Close column preferences" (click)="closePopup()"><i
                  class="icon-icon-ui-cross"></i></button>
            </span>
            <div class="popup">
              <div class="decorPop">
                <label class="colmPrefHeaderDiv">Select Columns</label>
                <ng-container *ngFor="let a of functionList; index as i">
                  <label class="labelItm" attr.aria-labelledby="span_{{i}}" for="input_{{i}}"  class="mainColPref">
                    <input type="checkbox" name="name_{{i}}" class="ijm" id="input_{{i}}"  ngDefaultControl [checked]="a.checked" [(ngModel)]="a.checked"
                      (change)="getSelectedValue(a.checked, a.value, a.colId, a.tooltipField, a.filter, a.headerName, a.fullHeaderName, a.customFilter)"
                      (keypress)="a.checked" />
                     <span class="checkicon" [attr.aria-label]="a.name" id="span_{{i}}" (keydown.enter)="toggleCheckbox(a)" (keydown.space)="toggleCheckbox(a)"  attr.aria-checked="{{a.checked}}" role="checkbox" name="span_{{i}}" ngDefaultControl [(ngModel)]="a.checked"
                    (keydown.enter)="getSelectedValue(a.checked, a.value, a.colId, a.tooltipField, a.filter, a.headerName, a.fullHeaderName, a.customFilter)"
                    (keyup.space)="getSelectedValue(a.checked, a.value, a.colId, a.tooltipField, a.filter, a.headerName, a.fullHeaderName, a.customFilter)"
                    [ngClass]="{'checked': a.checked}" tabindex="0" role="checkbox"></span>
                    {{a.name}}
                  </label>
                </ng-container>
              </div>
            </div>
            <!-- <div class="saveCancelBlock">
              <button tabindex="0" class="closePop accessibilty-last-cross" id="rmv" aria-label="Close column preferences"
              (click)="closePopup()"><i class="icon-icon-ui-cross"></i></button>
              <button class="texttype-globalbtn-cls cancel-btn" tabindex="0" id="rmv" (click)="closePopup()">
                <i class="icon-icon-ui-cross"></i>Cancel
              </button>
              <button class="outline-globalbtn-cls save-btn" (click)="onSubmit()" disabled>
                <i class="icon-icon-star"></i>Save view
              </button>
            </div> -->
          </div>
        </div>
        <div>
          <button type="button" class="primary-globalbtn-cls" aria-label="Create new user"
            (click)="navigateToCreateUser()">
            <i class="icon-icon-ui-plus roundWhiteBorderCls"></i>
            Create new user
          </button>
        </div>
      </div>
    </div>
    <div class="col-9 search-grid-container">
      <div class="searchGrid col-9">
        <span>
          <input type="text" class="searchInputfilter" id="text" placeholder="Search user" [(ngModel)]="searchvalue" />
        </span>
        <span class="col-xs-12 col-sm-3 col-md-4 col-lg-3 idsearch">
          <button type="button" class="primary-globalbtn-cls" aria-label="Search for Users" (click)="quickSearch()"
            [disabled]="!searchvalue">Search</button>
        </span>
        <span class="col-xs-12 col-sm-3 col-md-3 col-lg-3 idsearch">
          <button type="button" class="texttype-globalbtn-cls" [disabled]="!searchvalue" (click)="clearsearch()">Clear
            search</button>
        </span>
      </div>

    </div>

    <div class="toastrContainer" aria-live="polite" role="alert" toastContainer></div>
    <div class="grid-container user-list-grid" *ngIf="gridConfig && gridInitialized  && columnsPresent">
      <app-cui-ag-grid [model]="gridModel" (selectedOnChanged)="onSelectionChanged($event)"
        (buttonClicked)="navigateToCreateUser()" (focusOnTop)="focusOnTop($event)"
        (filtersOnChanged)="onFilterSelected($event)">
      </app-cui-ag-grid>
    </div>
    <div *ngIf="!columnsPresent" class="no-column"> No columns selected </div>
  </div>
</div>
