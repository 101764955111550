import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HomePageService } from '../shared/services/homePage.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'duct-access-dropdown-menu',
  templateUrl: './duct-access-dropdown-menu.component.html',
  styleUrls: ['./duct-access-dropdown-menu.component.css']
})
export class DuctAccessDropdownMenuComponent implements OnInit {
  isSubMenuOpen: boolean = false;
  customerDetails: any;
  @Output() closeDropdownMenu = new EventEmitter<void>();
  private isMenuOpen: boolean = false;

  mainMenu: any[] = [];
  adminSubMenu: any[] = [
    {
      title: 'Create a user',
      url: '/admin',
      id: 'createUser'
    },
    {
      title: 'My users',
      url: '/admin',
      id: 'myUser'
    }
  ];
  subMenu: string[];
  selectedIndex: number;
  constructor(
    private homepageservice: HomePageService,
    private commonService: CommonService,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.customerDetails = this.homepageservice.getCustomerDetails();
    this.getDuctAccessMenu();
    window.onkeyup = function (event) {
      if (event.keyCode == 27) {
        (<HTMLElement>document.getElementsByClassName('drpdwn-menu')[0]).style.display = 'none';
        (<HTMLElement>document.getElementsByClassName('duct-access-dropdown-menu-container')[0]).style.display = 'none';
      }
    }
  }

  getDuctAccessMenu() {
    const roles = this.commonService.getroleDetails().customerList;
    const ductUserRole = roles.some((obj) => (obj?.role === 'Duct Read & Write' && obj?.function === 'Duct Access Form'));
    const ductAdminRole = roles.some((obj) => (obj?.role === 'Duct Administrator'));
      if(ductAdminRole && ductUserRole) {
        this.mainMenu.push({
          title: 'Admin',
          url: ''
        },
        {
          title: 'Domain Whitelist',
          url: '/whitelist'
        },
        {
          title: 'Duct access form',
          url: '/duct-access-form'
        }
        )
      }
      else if (ductAdminRole) {        
        this.mainMenu.push({
          title: 'Admin',
          url: ''
        },
        {
          title: 'Domain Whitelist',
          url: '/whitelist'
        });
      } else if (ductUserRole) {
        this.mainMenu.push({
          title: 'Duct access form',
          url: '/duct-access-form'
        });
      } 
  }

  showSubMenu(menu, index) {
    this.subMenu = [];
    this.isSubMenuOpen = true;
    this.selectedIndex = index;
    if (menu === 'Admin') {
      this.subMenu = this.adminSubMenu;
    }
  }

  closeMenu(subMenu) {
    if(subMenu === 'Create a user'){
    this.commonService.showCreateUser(true);
    } else {
      this.commonService.showCreateUser(false);
    }
    this.closeDropdownMenu.emit();
  }
  navigate(title){
    if(title ==='Admin'){
     this.router.navigate(['']);
    }
    if(title ==='Domain Whitelist'){
     this.router.navigate(['/whitelist']);
    }
    if(title ==='Duct access form'){
     this.router.navigate(['/duct-access-form']);
    }
   }
  mouseLeaveEvent = () => {
    this.closeDropdownMenu.emit();
  }

  collapse(title){
    if(title === 'Duct access form'){
     this.closeDropdownMenu.emit();
    }
   }

}

