<form [formGroup]="refrenceForm" autocomplete="off">
    <div class="d-flex flex-column w-100">
        <div class="d-flex w-100 gap-4 latlong-container">
            <div class="d-flex flex-column w-50">
                <div>
                    <app-cui-searchable-dropdown name="searchMethod" [model]="searchMethodDropDownModel"
                        [formControlName]="'searchMethod'" id="type" [labelName]="'searchMethod'" [isRequired]="true"
                        [items]="refrences" placeholder="Select location reference" automationType="dropdown"
                        automationId="fundingSource" [noAutoComplete]="false" id="searchMethod"
                        class="customer-dropDown" (selectedValue)="searchMethodDropdownChange($event)" required>
                    </app-cui-searchable-dropdown>
                </div>
            </div>
            <div class="d-flex flex-column w-50">
                <div class="d-flex align-items-center gap-2 w-100 reference-input">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi"
                        viewBox="0 0 16 16">
                        <path
                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                    <div class="d-flex w-100">
                        <input class="address-input-textbox" name="geocodeAddress" placeholder="Enter an address here" aria-label="Location reference search.Enter address to search" style="width: 100%;padding: 5px;background: #fff;border: 0;" type="text"
                            formControlName='rawSearchText' maxlength="20" (change)="inputChanged($event)">
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-end gap-4 mt-3">
            <button mat-button (click)="resetForm()" class="cancelBtn save-cancel-btn"
                [disabled]="!refrenceForm.value.rawSearchText">
                <span class="cancel-btn"><i class="icon-icon-ui-cross"></i>Clear
                </span>
            </button>
            <button type="button"
                class="btn btn-primary d-flex gap-2 justify-content-center align-items-center search-btn"
                (click)="searchRefrence()"
                [disabled]="refrenceForm.controls['rawSearchText'].invalid || refrenceForm.controls['rawSearchText'].errors">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi"
                    viewBox="0 0 16 16">
                    <path
                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
                Search
            </button>
        </div>
        <div *ngIf="showAddressContainer" class="lat-searchbox-title">Search result</div>
        <ng-container *ngIf="searchResults.length">
            <!-- <label>{{primaryAutoComplete}}</label> -->
            <!-- <input type="text" nam="selectedAddress" formControlName="selectedAddress" value="primarySelect"> -->
            <div style="position:relative">
            <img src="../../../../../../assets/images/Search.svg" class="bi bi-search" />
             <input type="text" formControlName="address" class="address-input-text" />
            </div>
            <div *ngIf="showAddressContainer" class="outer-address-container">
                <div *ngIf="!noAddressFound" class="address-container">
                    <div  class="multi-adr-lbl">We found multiple addresses</div>
                    <div class="address-options" *ngFor="let result of searchResults">
                        <div class="primary-adr" (click)="selectAddress(result)">{{result?.primaryAddress}} </div>
                        <span *ngIf="result?.subaddresses?.length>0">
                            <span *ngIf="!showSubAddresses" (click)="openSubAddress()"><img
                                    src="../../../../../../assets/images/BlackCircleArrow.svg" /></span>
                            <span *ngIf="showSubAddresses" (click)="openSubAddress()"><img
                                    src="../../../../../../assets/images/BlackCircleDownArrow.svg" /></span>
                        </span>
                        <div *ngIf="result?.subaddresses?.length>0 && showSubAddresses">
                            <div class="sub-address-options" *ngFor="let subAddress of result?.subaddresses"
                                (click)="selectSubAddress(subAddress)">
                                {{subAddress.address}}
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="address-separator">
                <div class="lat-long-nav-container" style="width:100%">
                    <div tabindex="0" class="lat-long-nav" (click)="toggleToLatLongSearch(2);">
                        <span><img class="cannot-found-multiple-addresses-icon"
                                src="../../../../../../assets/images/Icon-info.svg" /></span>
                        <div style="padding-left:5px; display: inline-block; cursor: pointer;">
                            Can’t find your address? Try latitude and longitude search
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="d-flex align-items-center gap-2 w-100 input-wrp mt-3">
                <img src="../../../../../../assets/images/Search.svg" class="bi bi-search" />
                <div class="d-flex w-100">
                    <mat-label> </mat-label>
                    <input #trigger="matAutocompleteTrigger" type="text" class="address-input"
                        name="primaryAutoComplete1" (keyup)="autoCompletePrimary($event)" [matAutocomplete]="auto"
                        [placeholder]="aemLabelAuthoring?.searchForm.searchPlaceHolder + formConfig.title"
                        value="{{primaryAutoComplete}}" (focus)="searchFocus($event)">
                    <mat-autocomplete #auto="matAutocomplete" disableRipple>
                        <ng-container
                            *ngIf="primaryAutoCompleteOptions && !searchUnstructuredAddressesCompleteOptions.length">
                            <mat-option *ngFor="let option of primaryAutoCompleteOptions"
                                [value]="option.primaryAddress"
                                (click)="autoCompletePrimarySelect(option.primaryAddress)">
                                {{option.primaryAddress | titlecase}}
                            </mat-option>
                        </ng-container>
                        <ng-container *ngIf="searchUnstructuredAddressesCompleteOptions.length">
                            <mat-option class="multiple-addresses">
                                <div class="we-found-multiple-addresses">
                                    {{aemLabelAuthoring?.searchForm.multipleAddressesLabel}}
                                </div>
                            </mat-option>
                            <mat-option class="multiple-addresses multiple-addresses-heading-1"
                                *ngFor="let option of searchUnstructuredAddressesCompleteOptions"
                                (click)="$event.stopPropagation();" (onSelectionChange)="$event.stopPropagation();">
                                <div class="row-accordion-wrapper">
                                    <button class="accordion"
                                        (click)="toggleAccordian($event); searchUnstructuredAddressesSelect(option, 'address'); $event.stopPropagation();"
                                        #schematicView>
                                        <span (click)="schematicView.click();$event.stopPropagation();">
                                            {{option.primaryAddress | titlecase}}
                                            <span *ngIf="option.subaddresses && option.subaddresses.length">
                                                <span class="blackCircleArrow"><img
                                                        src="../../../../../../assets/images/BlackCircleArrow.svg" /></span>
                                                <span class="blackCircleDownArrow"><img
                                                        src="../../../../../../assets/images/BlackCircleDownArrow.svg" /></span>

                                            </span>
                                        </span>
                                    </button>
                                    <div id="panel" class="panel">
                                        <div id="schematic-svg">
                                            <div class="list-group">
                                                <ng-container *ngFor="let opt of option.subaddresses">
                                                    <div class="multiple-addresses-heading-2"
                                                        (click)="searchUnstructuredAddressesSelect(opt, 'subaddresses'); $event.stopPropagation();">
                                                        {{opt.address | titlecase}}
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-option>
                            <mat-option class="multiple-addresses multiple-addresses-cannot-found height-auto"
                                [value]="''"
                                (click)="toggleToLatLongSearch(aemLabelAuthoring?.searchForm.LatLongTabId);">
                            </mat-option>
                        </ng-container>
                    </mat-autocomplete>
                </div>
            </div> -->
            <!-- <div class="d-flex flex-column mt-3">
                <div class="py-2 lat-searchbox-title">Search Result</div>
                <select class="form-select lat-searchbox" aria-label="Default select example"
                    formControlName="selectedAddress">
                    <option *ngFor="let item of searchResults" [value]="item">{{item.primaryAddress}}</option>
                </select>
            </div> -->
            <div class="col-12 mt-5" *ngIf="!preDefinedSiteNode">
                <div class="Is-the-address-a-defined-property-location text-nowrap">
                    <span>Is the address a defined property location?
                        <span class="text-danger" aria-hidden="true">*</span>
                    </span>
                </div>
                <div class="For-example-Has-your-address-been-recognised-by-your-local-council">
                    For example: Has your address been recognised by your local council?
                </div>
                <div class="radio-btn-container radio-left">
                  <mat-radio-group role="radiogroup" formControlName="definedPropertyLocation">
                    <div tabindex="0" id="inlineRadio1" role="radio"
                         [attr.aria-checked]="(refrenceForm.value.definedPropertyLocation === 'Yes') ? 'true' : 'false'"
                         (click)="onChangeRadioButton('Yes')"
                         (keyup.enter)="onChangeRadioButton('Yes')"
                         class="form-check form-check-inline">
                      <mat-radio-button class="col-2 smart-ui-button-group diversity-radio-btn" [value]="aemLabelAuthoring?.label?.yes">
                        <div class="mat-custom-label">
                          <span aria-label="Is the address a defined property location? Yes" value="Yes">
                            {{aemLabelAuthoring?.label?.yes}}
                          </span>
                        </div>
                      </mat-radio-button>
                    </div>
                    <div tabindex="0" id="inlineRadio2" role="radio"
                         [attr.aria-checked]="(refrenceForm.value.definedPropertyLocation === 'No') ? 'true' : 'false'"
                         (click)="onChangeRadioButton('No')"
                         (keyup.enter)="onChangeRadioButton('No')"
                         class="form-check form-check-inline">
                      <mat-radio-button class="col-2 smart-ui-button-group diversity-radio-btn" [value]="aemLabelAuthoring?.label?.no">
                        <div class="mat-custom-label">
                          <span aria-label="Is the address a defined property location? No" value="No">
                            {{aemLabelAuthoring?.label?.no}}
                          </span>
                        </div>
                      </mat-radio-button>
                    </div>
                  </mat-radio-group>

                  </div>
            </div>

           <div class="col-12 mt-5">
                <div class="Provide-additional-address-information w-100 mb-0">
                    Provide additional address information
                </div>
                <textarea class="Path-13254 w-100" aria-label="Provide additional address information" formControlName="additionalAddressInfo"
                    placeholder="For example: New construction, LOT number, business estate"
                    [ngClass]="{'invalid-form-control': (refrenceForm.controls.additionalAddressInfo.touched && refrenceForm.controls.additionalAddressInfo.invalid)}"
                    maxlength="250"></textarea>
                <small class="pull-right text-success">{{refrenceForm?.value?.additionalAddressInfo?.length ? refrenceForm?.value?.additionalAddressInfo?.length : 0}}/250</small>

                <ng-container
                    *ngIf="refrenceForm.controls['additionalAddressInfo'].invalid && (refrenceForm.controls['additionalAddressInfo'].dirty || refrenceForm.controls['additionalAddressInfo'].touched)">

                    <small *ngIf="refrenceForm.controls['additionalAddressInfo'].errors.required" class="text-danger errorMsg">
                        Please enter additional address information
                    </small>
                    <!-- <small *ngIf="refrenceForm.controls['additionalAddressInfo'].errors.valid" class="text-danger">
                        Please enter valid additional address information
                    </small> -->
                    <small
                        *ngIf="!refrenceForm.controls['additionalAddressInfo'].errors.required && refrenceForm.controls['additionalAddressInfo'].invalid"
                        class="text-danger errorMsg">
                        The following special characters [<>;] are unable to be used, please revise your input
                    </small>

                </ng-container>
            </div>
        </ng-container>
    </div>
</form>
