<div class="container-fluid">
  <div class="toastrContainer" aria-live="assertive" role="alert" toastContainer></div>
  <form [formGroup]="createNewQuoteForm" class="row" *ngIf="aemLabelAuthoring">
    <div class="row">
      <div class="col-3 page-header-container">
        <span class="page-heading"> {{ aemLabelAuthoring.pageHeader.pageHeaderLabel }} </span>
        <div class="quote-ref-text" *ngIf="isProductSelected">
          {{ aemLabelAuthoring.pageHeader.pageSubHeaderLabel }} <strong>IR09288288888 </strong>
          <img src="../../../assets/images/Submitted.svg" class="saved-icon" /><strong> {{
            aemLabelAuthoring.pageHeader.saved }}</strong>
        </div>
        <!-- <div class="quote-ref-text" *ngIf="custReference" >          
          {{ aemLabelAuthoring.pageHeader.reviewPageSubHeaderLabel }} <strong>Ref1234 </strong>
         </div> -->
      </div>
      <div class="col-6">
        <app-page-stepper [stepperData]="stepperData" [stepName]="stepName"></app-page-stepper>
      </div>

      <!-- <mat-horizontal-stepper [linear]="isLinear" labelPosition="bottom" #stepper>
        <mat-step [stepControl]="firstFormGroup" formGroupName="firstForm" [completed]="false">
          <ng-template matStepLabel>{{ aemLabelAuthoring.stepperLabel.productDetails }}</ng-template>
          <app-product-details (showQuoteReference)="showQuoteReference($event)"
            [productData]="productData"></app-product-details>
          <div class="submitCancelBtn btnRow">
            <button mat-button (click)="cancel(stepper)" class="cancelBtn save-cancel-btn">
              <i class="icon-icon-ui-cross"></i>{{ aemLabelAuthoring.button.cancel }}</button>
            <button class="app-btn-green save-cancel-btn" disabled>
              <img src="../../../assets/images/Icon-Save-btn-disable.svg">{{ aemLabelAuthoring.button.save }}
            </button>
            <button mat-button matStepperNext class="app-btn-green save-cancel-btn">{{ aemLabelAuthoring.button.next }}
              <img src="../../../assets/images/NextArrow_btn.png" class="next-arrow-icon"></button>
          </div>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup" formGroupName="secondForm" [completed]="false">
          <ng-template matStepLabel>{{ aemLabelAuthoring.stepperLabel.siteDetails }}</ng-template>
          <app-site-details></app-site-details>
          <div class="submitCancelBtn btnRow">
            <button mat-button (click)="cancel(stepper)" class="cancelBtn save-cancel-btn">
              <i class="icon-icon-ui-cross"></i>{{ aemLabelAuthoring.button.cancel }}</button>
            <button class="app-btn-green save-cancel-btn" disabled>
              <img src="../../../assets/images/Icon-Save-btn-disable.svg">{{ aemLabelAuthoring.button.save }}
            </button>
            <button mat-button matStepperNext class="app-btn-green save-cancel-btn">{{ aemLabelAuthoring.button.next }}
              <img src="../../../assets/images/NextArrow_btn.png" class="next-arrow-icon"></button>
          </div>
        </mat-step>
        <mat-step [stepControl]="thirdFormGroup" formGroupName="thirdForm" [completed]="false">
          <ng-template matStepLabel>{{ aemLabelAuthoring.stepperLabel.requestDetails }}</ng-template>
          <app-request-details></app-request-details>
          <div class="submitCancelBtn btnRow">
            <button mat-button (click)="cancel(stepper)" class="cancelBtn save-cancel-btn">
              <i class="icon-icon-ui-cross"></i>{{ aemLabelAuthoring.button.cancel }}</button>
            <button class="app-btn-green save-cancel-btn" disabled>
              <img src="../../../assets/images/Icon-Save-btn-disable.svg">{{ aemLabelAuthoring.button.save }}
            </button>
            <button mat-button matStepperNext class="app-btn-green save-cancel-btn">{{ aemLabelAuthoring.button.next }}
              <img src="../../../assets/images/NextArrow_btn.png" class="next-arrow-icon"></button>
          </div>
        </mat-step>

        <mat-step [stepControl]="fourthFormGroup" formGroupName="fourthForm" [completed]="false">
          <ng-template matStepLabel>{{ aemLabelAuthoring.stepperLabel.reviewAndSubmit }}</ng-template>
          <app-review-and-submit (showQuoteReferenceNew)="showReviewLabel($event)"></app-review-and-submit>
          <div class="submitCancelBtn btnRow">
            <button mat-button (click)="cancel(stepper)" class="cancelBtn save-cancel-btn">
              <img src="../../../assets/images/Icon-Table-Sort-DownArrow.svg" class="back-arrow">{{ aemLabelAuthoring.button.back }}</button>
            <button mat-button (click)="cancel(stepper)" class="cancelBtn save-cancel-btn">
              <i class="icon-icon-ui-cross"></i>{{ aemLabelAuthoring.button.cancel }}</button>
            <button class="app-btn-green save-cancel-btn" disabled>
              <img src="../../../assets/images/Icon-Save-btn-disable.svg">{{ aemLabelAuthoring.button.save }}
            </button>
            <button mat-button matStepperNext class="app-btn-green save-cancel-btn">{{ aemLabelAuthoring.button.next }}
              <img src="../../../assets/images/NextArrow_btn.png" class="next-arrow-icon"></button>
          </div>
        </mat-step>

      </mat-horizontal-stepper> -->
    </div>
  </form>
</div>