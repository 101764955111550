import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { SiteDetailsService } from 'src/app/quote-management/create-new-quote/site-details/site-details.service';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { MapsService } from 'src/app/maps.service';
import { LatLngFormComponent } from '../lat-lng-form/lat-lng-form.component';
import { CreateQuoteService } from '../../../create-quote.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.less'],
})
export class TabsComponent implements OnInit {
  @ViewChild(GoogleMap) sitemap!: GoogleMap;

  @ViewChild(MapInfoWindow) MapInfoWindow!: MapInfoWindow;

  @ViewChild('markerElem') markerElem: MapMarker;

  @ViewChild(LatLngFormComponent) latLngFormComponent: LatLngFormComponent;


  @Input() aemLabelAuthoring;
  @Input() savedData;
  @Output() TabChanged = new EventEmitter<any>();

  activeId = 1;
  formsConfig: any[] = [];
  zEndActivatedFlag: boolean = false;
  a2EndActivatedFlag: boolean = false;
  z2EndActivatedFlag: boolean = false;

  markers: any[] = [];
  markers2: any[] = [];
  addressSearchResult = '';
  mapZoom = 15;
  infoContent = '';
  mapCenter: google.maps.LatLngLiteral;
  searchInp = '';
  mapData: any;
  updateLatLngFromMap;
  UpdateLocRefForm;

  updateReferenceForm;

  pinOnMapData: any;
  infoWindowSelectBtn = 1;
  hideInfoWindowBtn: boolean = true;
  latlong;
  showInfoWindowBtn;
  currentIndex;
  selectBtnMap = false;
  isQuoteSaved;

  siteAddressGoogleMapsCustomMarker = {
    options: {
      icon: {
        url: '../../../../assets/images/Map-pointer-A.png',
        scaledSize: new google.maps.Size(42, 60),

      },
    },
  }

  constructor(
    private siteDetailsService: SiteDetailsService,
    private mapsService: MapsService,
    private createQuoteService: CreateQuoteService
  ) {

  }

  clearBtnClicked(e) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (e.clearBtnClicked === true && e.formName === 'aEndAdress') {
          this.formsConfig[0].mapCenter = {
            lat: -37.8098714,
            lng: 144.9698015,
          };
          this.formsConfig[0].mapZoom = 15;
          this.formsConfig[0].markers = [];
          this.formsConfig[0].infoContent = '';
          this.formsConfig[0].infoWindowSelectBtn = false;

        }

        if (e.clearBtnClicked === true && e.formName === 'zEndAdress') {
          this.formsConfig[1].mapCenter = {
            lat: -37.8098714,
            lng: 144.9698015,
          };
          this.formsConfig[1].mapZoom = 15;
          this.formsConfig[1].markers = [];
          this.formsConfig[1].infoContent = '';
          this.formsConfig[1].infoWindowSelectBtn = false;

        }
        if (e.clearBtnClicked === true && e.formName === 'a2EndAdress') {
          this.formsConfig[2].mapCenter = {
            lat: -37.8098714,
            lng: 144.9698015,
          };
          this.formsConfig[2].mapZoom = 15;
          this.formsConfig[2].markers = [];
          this.formsConfig[2].infoContent = '';
          this.formsConfig[2].infoWindowSelectBtn = false;

        }
        if (e.clearBtnClicked === true && e.formName === 'z2EndAdress') {
          this.formsConfig[3].mapCenter = {
            lat: -37.8098714,
            lng: 144.9698015,
          };
          this.formsConfig[3].mapZoom = 15;
          this.formsConfig[3].markers = [];
          this.formsConfig[3].infoContent = '';
          this.formsConfig[3].infoWindowSelectBtn = false;

        }
      });
    }
  }


  ngOnInit(): void {

    if(this.savedData.siteType == "aEndAdress" || this.savedData.siteType == "a2EndAdress" || this.savedData.siteType == "zEndAdress" || this.savedData.siteType == "z2EndAdress"){

      if (this.savedData.siteType == "aEndAdress" || this.savedData.siteType == "a2EndAdress") {
        this.siteAddressGoogleMapsCustomMarker.options.icon.url = "../../../../assets/images/Map-pointer-A.png";
      }

      if (this.savedData.siteType == "zEndAdress" || this.savedData.siteType == "z2EndAdress") {
        this.siteAddressGoogleMapsCustomMarker.options.icon.url = "../../../../assets/images/Map-pointer-Z.png";
      }

      if(this.savedData.aEndAdress?.siteType?.length){
        this.formsConfig[0] = this.savedData.aEndAdress;
        this.createQuoteService.productDetailsFormValue['aEndAdress'] = this.savedData.aEndAdress;
        this.createQuoteService.productDetailsFormValue['aEndpreDefinedNodeName'] = this.savedData.aEndpreDefinedNodeName;

      }

      if(this.savedData.zEndAdress?.siteType?.length){
        this.formsConfig[1] = this.savedData.zEndAdress;
        this.createQuoteService.productDetailsFormValue['zEndAdress'] = this.savedData.zEndAdress;
        this.createQuoteService.productDetailsFormValue['zEndpreDefinedNodeName'] = this.savedData.zEndpreDefinedNodeName;

      }

      if(this.savedData.a2EndAdress?.siteType?.length){
        this.formsConfig[2] = this.savedData.a2EndAdress;
        this.createQuoteService.productDetailsFormValue['a2EndAdress'] = this.savedData.a2EndAdress;
        this.createQuoteService.productDetailsFormValue['a2EndpreDefinedNodeName'] = this.savedData.a2EndpreDefinedNodeName;

      }

      if(this.savedData.z2EndAdress?.siteType?.length){
        this.formsConfig[3] = this.savedData.z2EndAdress;
        this.createQuoteService.productDetailsFormValue['z2EndAdress'] = this.savedData.z2EndAdress;
        this.createQuoteService.productDetailsFormValue['z2EndpreDefinedNodeName'] = this.savedData.z2EndpreDefinedNodeName;

      }

    } else {

      this.formsConfig.push(
        this.siteDetailsService.getSiteAendFormConfig(this.savedData)
      );
    }

  }


  activateZend(ev) {
    if (ev && !this.zEndActivatedFlag) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.formsConfig[1].mapCenter = {
            lat: -37.8098714,
            lng: 144.9698015,
          };
          this.formsConfig[1].mapZoom = 15;
          this.formsConfig[1].markers = [];
          this.formsConfig[1].infoContent = '';
          this.formsConfig[1].infoWindowSelectBtn = false;

        });
      }
      this.formsConfig.push(
        this.siteDetailsService.getSiteZendFormConfig(this.savedData)
      );
      this.zEndActivatedFlag = true;
    } else if (!ev && this.formsConfig.length !== 1) {
      this.formsConfig.length = 1;
      this.zEndActivatedFlag = false;
      this.a2EndActivatedFlag = false;
      this.z2EndActivatedFlag = false;
    }
  }

  activateA2end(ev) {
    if (
      ev &&
      !this.a2EndActivatedFlag &&
      this.savedData.doYouRequireDiversity == 'Yes' &&
      this.savedData.describeYourDiversity === 'All new services with diversity'
    ) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.formsConfig[2].mapCenter = {
            lat: -37.8098714,
            lng: 144.9698015,
          };
          this.formsConfig[2].mapZoom = 15;
          this.formsConfig[2].markers = [];
          this.formsConfig[2].infoContent = '';
          this.formsConfig[2].infoWindowSelectBtn = false;

        });
      }
      this.formsConfig.push(
        this.siteDetailsService.getSiteA2endFormConfig(this.savedData)
      );
      this.a2EndActivatedFlag = true;
    } else if (!ev && this.formsConfig.length > 2) {
      this.formsConfig.length = 2;
      this.a2EndActivatedFlag = false;
      this.z2EndActivatedFlag = false;
    }
  }

  activateZ2end(ev) {
    if (
      ev &&
      !this.z2EndActivatedFlag &&
      this.savedData.doYouRequireDiversity == 'Yes' &&
      this.savedData.describeYourDiversity === 'All new services with diversity'
    ) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.formsConfig[3].mapCenter = {
            lat: -37.8098714,
            lng: 144.9698015,
          };
          this.formsConfig[3].mapZoom = 15;
          this.formsConfig[3].markers = [];
          this.formsConfig[3].infoContent = '';
          this.formsConfig[3].infoWindowSelectBtn = false;

        });
      }
      this.formsConfig.push(
        this.siteDetailsService.getSiteZ2endFormConfig(this.savedData)
      );
      this.z2EndActivatedFlag = true;
    } else if (!ev && this.formsConfig.length > 3) {
      this.formsConfig.length = 3;
      this.z2EndActivatedFlag = false;
    }
  }

  userChangedTab(ev?, formConfig?, index?) {
    // maps
    this.formsConfig[index]['infocontent'] = '';
    this.infoContent = '';
    this.formsConfig[index].mapZoom = 15;
    this.MapInfoWindow.close();
    // this.formsConfig[index].markers = [];
    // this.formsConfig[index].infoContent = '';
    this.formsConfig[index].infoWindowSelectBtn = false;

    // setTimeout(() => {
    this.siteDetailsService.onTabChangeClearData(ev, formConfig);
    this.TabChanged.emit(formConfig);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.formsConfig[index].mapCenter = {
          lat: -37.8098714,
          lng: 144.9698015,
        };
        this.formsConfig[index].mapZoom = 15;
        this.formsConfig[index].markers = [];
        this.formsConfig[index].infoContent = '';
        this.formsConfig[index].infoWindowSelectBtn = false;

      });
    }

    if (formConfig.formName == 'aEndAdress' && this.formsConfig.length !== 1) {
      this.formsConfig.length = 1;
      this.zEndActivatedFlag = false;
      this.a2EndActivatedFlag = false;
      this.z2EndActivatedFlag = false;
    }

    if (formConfig.formName == 'zEndAdress' && this.formsConfig.length > 2) {
      this.formsConfig.length = 2;
      this.a2EndActivatedFlag = false;
      this.z2EndActivatedFlag = false;
    }

    if (formConfig.formName == 'a2EndAdress' && this.formsConfig.length > 3) {
      this.formsConfig.length = 3;
      this.z2EndActivatedFlag = false;
    }

    // }, 100);
  }

  getSelectedAddressVal(address?: any, index?: number, item?: any) {
    this.addressSearchDynamic(address, index, item);
  }

  fillMapDataForLatLng(savedData?) {
    const d = savedData;
    if (
      d.aEndAdress &&
      d.aEndAdress.searchMethod === 'Latitude and Longitude' &&
      d.aEndAdress.latitude &&
      d.aEndAdress.longitude
    ) {
      this.showInfoWindowBtn = false;
      this.formsConfig[0].mapCenter = {
        lat: 1 * d.aEndAdress.latitude,
        lng: 1 * d.aEndAdress.longitude,
      };
      this.formsConfig[0].mapZoom = 15;
      this.formsConfig[0].markers = [
        {
          position: {
            lat: 1 * d.aEndAdress.latitude,
            lng: 1 * d.aEndAdress.longitude,
          },
          // icon: this.customMarker,
        },
      ];
    }

    if (
      d.zEndAdress &&
      d.zEndAdress.searchMethod === 'Latitude and Longitude' &&
      d.zEndAdress.latitude &&
      d.zEndAdress.longitude
    ) {
      this.showInfoWindowBtn = false;
      this.formsConfig[1].mapCenter = {
        lat: 1 * d.zEndAdress.latitude,
        lng: 1 * d.zEndAdress.longitude,
      };
      this.formsConfig[1].mapZoom = 15;
      this.formsConfig[1].markers = [
        {
          position: {
            lat: 1 * d.zEndAdress.latitude,
            lng: 1 * d.zEndAdress.longitude,
          },
          // icon: this.customMarker,
        },
      ];
    }

    if (
      d.a2EndAdress &&
      d.a2EndAdress.searchMethod === 'Latitude and Longitude' &&
      d.a2EndAdress.latitude &&
      d.a2EndAdress.longitude
    ) {
      this.showInfoWindowBtn = false;
      this.formsConfig[2].mapCenter = {
        lat: 1 * d.a2EndAdress.latitude,
        lng: 1 * d.a2EndAdress.longitude,
      };
      this.formsConfig[2].mapZoom = 15;
      this.formsConfig[2].markers = [
        {
          position: {
            lat: 1 * d.a2EndAdress.latitude,
            lng: 1 * d.a2EndAdress.longitude,
          },
          // icon: this.customMarker,
        },
      ];
    }

    if (
      d.z2EndAdress &&
      d.z2EndAdress.searchMethod === 'Latitude and Longitude' &&
      d.z2EndAdress.latitude &&
      d.z2EndAdress.longitude
    ) {
      this.showInfoWindowBtn = false;
      this.formsConfig[3].mapCenter = {
        lat: 1 * d.z2EndAdress.latitude,
        lng: 1 * d.z2EndAdress.longitude,
      };
      this.formsConfig[3].mapZoom = 15;
      this.formsConfig[3].markers = [
        {
          position: {
            lat: 1 * d.z2EndAdress.latitude,
            lng: 1 * d.z2EndAdress.longitude,
          },
          // icon: this.customMarker,
        },
      ];
    }
  }

  addressSearchDynamic(address?: any, index?: number, item?: any) {

    if (item.formName == "aEndAdress" || item.formName == "a2EndAdress") {
      this.siteAddressGoogleMapsCustomMarker.options.icon.url = "../../../../assets/images/Map-pointer-A.png";
    }

    if (item.formName == "zEndAdress" || item.formName == "z2EndAdress") {
      this.siteAddressGoogleMapsCustomMarker.options.icon.url = "../../../../assets/images/Map-pointer-Z.png";
    }

    this.markers = [];
    this.mapsService.searchByAddress(address).subscribe((response: any) => {
      if (response.status === 'OK' && response.results.length > 0) {
        const location = response.results[0].geometry.location;
        this.updateMapData(true, index, item, location.lat, location.lng);
        this.addressSearchResult = response.results[0].formatted_address;
        this.formsConfig[index]['infocontent'] =
          address || response.results[0].formatted_address;
        this.formsConfig[index].infoWindowSelectBtn = false;

      }
    });
  }

  getSearchInputs(e?: any, item?: any, index?: number) {
    const address = e.primaryAddress || e.address || e || '';
    if (item.formName === 'aEndAdress' || item.formName === 'a2EndAdress') {
      this.getSelectedAddressVal(address, index, item);
    } else if (
      item.formName === 'zEndAdress' ||
      item.formName === 'z2EndAdress'
    ) {
      this.getSelectedAddressVal(address, index, item);
    }
  }

  getLatLongSearch(e?: any, item?: any, index?: number) {

    if (item.formName == "aEndAdress" || item.formName == "a2EndAdress") {
      this.siteAddressGoogleMapsCustomMarker.options.icon.url = "../../../../assets/images/Map-pointer-A.png";
    }

    if (item.formName == "zEndAdress" || item.formName == "z2EndAdress") {
      this.siteAddressGoogleMapsCustomMarker.options.icon.url = "../../../../assets/images/Map-pointer-Z.png";
    }

    if (e.type === "address" && e.address != '(New address)') {
      this.getSelectedAddressVal(e.address, index, item);
    }


    if (e.type === "latlong") {

      this.formsConfig[index].infoWindowSelectBtn = false;

      this.formsConfig[index].mapCenter = {
        lat: 1 * e.lat || -37.8098714,
        lng: 1 * e.long || 144.9698015,
      };
      this.formsConfig[index].mapZoom = 15;
      this.formsConfig[index].markers = [
        {
          position: {
            lat: 1 * e.lat || -37.8098714,
            lng: 1 * e.long || 144.9698015,
          },
        }
      ]
    }

  }

  getLocationRefSearch(e?: any, item?: any, index?: number) {
    this.getSelectedAddressVal(e, index, item);
  }

  // Function to update map data for a specific form
  updateMapData(
    isAPISearch?: boolean,
    index?: number,
    item?: any,
    nlat?: number,
    nlng?: number,
    isCustomDrop?: boolean
  ) {


    if (item.formName == "aEndAdress" || item.formName == "a2EndAdress") {
      this.siteAddressGoogleMapsCustomMarker.options.icon.url = "../../../../assets/images/Map-pointer-A.png";
    }

    if (item.formName == "zEndAdress" || item.formName == "z2EndAdress") {
      this.siteAddressGoogleMapsCustomMarker.options.icon.url = "../../../../assets/images/Map-pointer-Z.png";
    }

    this.currentIndex = index;
    this.formsConfig[index]['infocontent'] = '';
    this.showInfoWindowBtn = true;

    this.MapInfoWindow.close();
    this.formsConfig[index].formData = item;
    this.infoContent = '';
    this.formsConfig[index].infoWindowSelectBtn = false;

    const latlng =
      this.formsConfig[index].mapCenter.lat +
      ', ' +
      this.formsConfig[index].mapCenter.lng;

    if (isAPISearch) {
      this.showInfoWindowBtn = false;

      this.formsConfig[index]['showInfoWindowBtn'] = false;
      this.showInfoWindowBtn = true;

      this.formsConfig[index].mapCenter = { lat: nlat, lng: nlng };
      this.formsConfig[index].mapZoom = 15;
      this.formsConfig[index].markers = [
        {
          position: { lat: nlat, lng: nlng },
          // icon: this.customMarker,
        },
      ];
      this.infoContent =
        this.formsConfig[index]['infocontent'] || this.addressSearchResult;
      this.formsConfig[index].infoWindowSelectBtn = false;

    } else {
      this.showInfoWindowBtn = false;
      this.formsConfig[index]['showInfoWindowBtn'] = true;

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.formsConfig[index].mapCenter = {
            lat: position.coords.latitude || '',
            lng: position.coords.longitude || '',
          };
          this.formsConfig[index].mapZoom = 15;
          this.formsConfig[index].markers = [
            {
              position: {
                lat: position.coords.latitude || '',
                lng: position.coords.longitude || '',
              },
              // icon: this.customMarker,
            },
          ];
          this.formsConfig[index].activeTab = 2;
        });
      }
      this.infoContent = this.formsConfig[index]['infocontent'] || latlng;
      this.formsConfig[index].infoWindowSelectBtn = true;

    }
  }

  nopesnInfo(index, markerIndex: string, marker: MapMarker, markerElem, item) {
    this.selectBtnMap = this.formsConfig[index].infoWindowSelectBtn;

    this.MapInfoWindow.close();
    const latlng =
      this.formsConfig[index].mapCenter.lat +
      ', ' +
      this.formsConfig[index].mapCenter.lng || '';
    this.infoContent = this.formsConfig[index]['infocontent'] || latlng || '';
    this.MapInfoWindow.open(markerElem);

    this.mapsService.storeItems({
      item: item,
      formIndex: index,
      markerIndex: markerIndex,
      showInfoWindowBtn: item.showInfoWindowBtn,
    });
  }

  // Inside your TabsComponent class
  addMarker(
    event: google.maps.MouseEvent,
    index: number,
    item: any,
    markerElem
  ) {
    this.formsConfig[index].activeTab = 2;

    if (item.formName == "aEndAdress" || item.formName == "a2EndAdress") {
      this.siteAddressGoogleMapsCustomMarker.options.icon.url = "../../../../assets/images/Map-pointer-A.png";
    }

    if (item.formName == "zEndAdress" || item.formName == "z2EndAdress") {
      this.siteAddressGoogleMapsCustomMarker.options.icon.url = "../../../../assets/images/Map-pointer-Z.png";
    }

    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    this.showInfoWindowBtn = false;

    this.formsConfig[index].formData = item;
    this.formsConfig[index]['infocontent'] = '';
    this.infoContent = '';

    // Create a new marker object
    const newMarker = {
      position: { lat, lng },
    };

    // Add the new marker to the markers array of the specific formConfig
    item.markers.push(newMarker);

    //  this.formsConfig[index]['showInfoWindowBtn'] = true;
    this.formsConfig[index].mapZoom = 15;
    this.formsConfig[index].markers = [
      {
        position: {
          lat: lat || '',
          lng: lng || '',
        },
        // icon: this.customMarker,
      },
    ];
    this.formsConfig[index].activeTab = 2;
    this.MapInfoWindow.open(markerElem);
    this.nopesnInfo(index, '', item, markerElem, item);

    const newLatLng = newMarker.position.lat + ', ' + newMarker.position.lng;

    if (newLatLng) {
      this.formsConfig[index].mapCenter = {
        lat: newMarker.position.lat || '',
        lng: newMarker.position.lng || '',
      };
      this.formsConfig[index].mapZoom = 15;
      this.formsConfig[index].markers = [
        {
          position: {
            lat: newMarker.position.lat || '',
            lng: newMarker.position.lng || '',
          },
          // icon: this.customMarker,
        },
      ];

      this.formsConfig[index]['infocontent'] = newLatLng;
      this.infoContent = this.formsConfig[index]['infocontent'] || '';
      this.formsConfig[index].infoWindowSelectBtn = true;

    }
  }

  infowindowSelectActions(index, item, ev) {
    const itemVal = this.mapsService.getItems();
    this.MapInfoWindow.close();

    this.formsConfig[itemVal.formIndex].formData.latitude =
      itemVal.item.mapCenter.lat || '';
    this.formsConfig[itemVal.formIndex].formData.longitude =
      itemVal.item.mapCenter.lng || '';
    this.formsConfig[itemVal.formIndex].latitude =
      itemVal.item.mapCenter.lat || '';
    this.formsConfig[itemVal.formIndex].longitude =
      itemVal.item.mapCenter.lng || '';
    this.infoContent = this.formsConfig[itemVal.formIndex]['infocontent'] || '';

    this.updateLatLngFromMap = {
      lat: this.formsConfig[itemVal.formIndex].mapCenter.lat || '',
      lng: this.formsConfig[itemVal.formIndex].mapCenter.lng || '',
      siteType: this.formsConfig[itemVal.formIndex].formName || '',
    };
  }


  getpatch2CheckedInfo(event, item, index) {

    if (item.formName == "aEndAdress" || item.formName == "a2EndAdress") {
      this.siteAddressGoogleMapsCustomMarker.options.icon.url = "../../../../assets/images/Map-pointer-A.png";
    }

    if (item.formName == "zEndAdress" || item.formName == "z2EndAdress") {
      this.siteAddressGoogleMapsCustomMarker.options.icon.url = "../../../../assets/images/Map-pointer-Z.png";
    }

    const referenceFormAEndAdress = this.siteDetailsService.referenceFormAEndAdress ? JSON.parse(JSON.stringify(this.siteDetailsService.referenceFormAEndAdress)) : this.siteDetailsService.referenceFormAEndAdress;
    const referenceFormZEndAdress = this.siteDetailsService.referenceFormZEndAdress ? JSON.parse(JSON.stringify(this.siteDetailsService.referenceFormZEndAdress)) : this.siteDetailsService.referenceFormZEndAdress;
    const latLongFormAEndAdress = this.siteDetailsService.latLongFormAEndAdress ? JSON.parse(JSON.stringify(this.siteDetailsService.latLongFormAEndAdress)) : this.siteDetailsService.latLongFormAEndAdress;
    const latLongFormZEndAdress = this.siteDetailsService.latLongFormZEndAdress ? JSON.parse(JSON.stringify(this.siteDetailsService.latLongFormZEndAdress)) : this.siteDetailsService.latLongFormZEndAdress;
    const searchFormAEndAdress = this.siteDetailsService.searchFormAEndAdress ? JSON.parse(JSON.stringify(this.siteDetailsService.searchFormAEndAdress)) : this.siteDetailsService.searchFormAEndAdress;
    const searchFormZEndAdress = this.siteDetailsService.searchFormZEndAdress ? JSON.parse(JSON.stringify(this.siteDetailsService.searchFormZEndAdress)) : this.siteDetailsService.searchFormZEndAdress;
    // const referenceFormA2EndAdress = this.siteDetailsService.referenceFormA2EndAdress;
    // const referenceFormZ2EndAdress = this.siteDetailsService.referenceFormZ2EndAdress;
    // const latLongFormA2EndAdress = this.siteDetailsService.latLongFormA2EndAdress;
    // const latLongFormZ2EndAdress = this.siteDetailsService.latLongFormZ2EndAdress;
    // const searchFormA2EndAdress = this.siteDetailsService.searchFormA2EndAdress;
    // const searchFormZ2EndAdress = this.siteDetailsService.searchFormZ2EndAdress;


    if (event.type === "sameAsPath1AEndAddressDetails" && event.from === "sameAsPath1A2EndAddressDetails" && item.formName == "a2EndAdress") {

      if (searchFormAEndAdress?.searchMethod === 'search') {
        this.formsConfig[2]['mapCenter'] = this.formsConfig[0]['mapCenter'];
        this.formsConfig[2]['mapZoom'] = this.formsConfig[0]['mapZoom'];
        this.formsConfig[2]['markers'] = this.formsConfig[0]['markers'];
        this.formsConfig[2]['infocontent'] = this.formsConfig[0]['infocontent'];

      }

      // Check and proceed if aEndAdress has searchMethod starts refrence-
      if (referenceFormAEndAdress?.searchMethod?.includes('refrence-')) {

        const contactDetailsData = this.createQuoteService.productDetailsFormValue['a2EndAdress'];

        // Switch tab to location refernce
        var a = setTimeout(() => {
          this.formsConfig[2].activeTab = 3;
          clearTimeout(a);
        }, 200);


        const d = referenceFormAEndAdress;
        d.siteType = 'a2EndAdress';
        d.formName = 'a2EndAdress';

        if (contactDetailsData?.primaryContactName || contactDetailsData?.primaryContactNumber || contactDetailsData?.primaryContactEmail || contactDetailsData?.altContactName || contactDetailsData?.altContactNumber || contactDetailsData?.altContactEmail) {
          d.primaryContactName = contactDetailsData.primaryContactName;
          d.primaryContactNumber = contactDetailsData.primaryContactNumber;
          d.primaryContactEmail = contactDetailsData.primaryContactEmail;
          d.altContactName = contactDetailsData.altContactName;
          d.altContactNumber = contactDetailsData.altContactNumber;
          d.altContactEmail = contactDetailsData.altContactEmail;
        }

        //  d.definedPropertyLocation = contactDetailsData.definedPropertyLocation;

        this.siteDetailsService.referenceFormA2EndAdress = d;

        // Assign to createquote service
        this.createQuoteService.productDetailsFormValue['a2EndAdress'] = this.siteDetailsService.referenceFormA2EndAdress;
      }


      // Check and proceed if aEndAdress has searchMethod is latlong
      if (latLongFormAEndAdress?.searchMethod === "Latitude and Longitude") {
        const contactDetailsData = this.createQuoteService.productDetailsFormValue['a2EndAdress'];

        // Switch tab to location refernce
        var a = setTimeout(() => {
          this.formsConfig[2].activeTab = 2;
          clearTimeout(a);
        }, 200);


        const d = latLongFormAEndAdress;
        d.siteType = 'a2EndAdress';
        d.formName = 'a2EndAdress';

        if (contactDetailsData?.primaryContactName || contactDetailsData?.primaryContactNumber || contactDetailsData?.primaryContactEmail || contactDetailsData?.altContactName || contactDetailsData?.altContactNumber || contactDetailsData?.altContactEmail) {
          d.primaryContactName = contactDetailsData.primaryContactName;
          d.primaryContactNumber = contactDetailsData.primaryContactNumber;
          d.primaryContactEmail = contactDetailsData.primaryContactEmail;
          d.altContactName = contactDetailsData.altContactName;
          d.altContactNumber = contactDetailsData.altContactNumber;
          d.altContactEmail = contactDetailsData.altContactEmail;
        }
        // d.definedPropertyLocation = contactDetailsData.definedPropertyLocation;

        this.siteDetailsService.latLongFormA2EndAdress = d;

        // Assign to createquote service
        this.createQuoteService.productDetailsFormValue['a2EndAdress'] = this.siteDetailsService.latLongFormA2EndAdress;
      }


    }

    if (event.type === "sameAsPath1ZEndAddressDetails" && event.from === "sameAsPath1A2EndAddressDetails") {

      if (searchFormZEndAdress?.searchMethod === 'search') {
        this.formsConfig[2]['mapCenter'] = this.formsConfig[1]['mapCenter'];
        this.formsConfig[2]['mapZoom'] = this.formsConfig[1]['mapZoom'];
        this.formsConfig[2]['markers'] = this.formsConfig[1]['markers'];
        this.formsConfig[2]['infocontent'] = this.formsConfig[1]['infocontent'];
      }


      if (referenceFormZEndAdress?.searchMethod?.includes('refrence-')) {
        const contactDetailsData = this.createQuoteService.productDetailsFormValue['a2EndAdress'];

        // Switch tab to location refernce
        var a = setTimeout(() => {
          this.formsConfig[2].activeTab = 3;
          clearTimeout(a);
        }, 200);

        const d = referenceFormZEndAdress;
        d.siteType = 'a2EndAdress';
        d.formName = 'a2EndAdress';

        if (contactDetailsData?.primaryContactName || contactDetailsData?.primaryContactNumber || contactDetailsData?.primaryContactEmail || contactDetailsData?.altContactName || contactDetailsData?.altContactNumber || contactDetailsData?.altContactEmail) {
          d.primaryContactName = contactDetailsData.primaryContactName;
          d.primaryContactNumber = contactDetailsData.primaryContactNumber;
          d.primaryContactEmail = contactDetailsData.primaryContactEmail;
          d.altContactName = contactDetailsData.altContactName;
          d.altContactNumber = contactDetailsData.altContactNumber;
          d.altContactEmail = contactDetailsData.altContactEmail;
        }
        // d.definedPropertyLocation = contactDetailsData.definedPropertyLocation;

        this.siteDetailsService.referenceFormA2EndAdress = d;

        // Assign to createquote service
        this.createQuoteService.productDetailsFormValue['a2EndAdress'] = this.siteDetailsService.referenceFormA2EndAdress;
      }


      // Check and proceed if aEndAdress has searchMethod is latlong
      if (latLongFormZEndAdress?.searchMethod === "Latitude and Longitude") {
        const contactDetailsData = this.createQuoteService.productDetailsFormValue['a2EndAdress'];

        // Switch tab to location refernce
        var a = setTimeout(() => {
          this.formsConfig[2].activeTab = 2;
          clearTimeout(a);
        }, 200);


        // localStorage.setItem('patchDataA2EndAdress', JSON.stringify(data));
        const d = latLongFormZEndAdress;
        d.siteType = 'a2EndAdress';
        d.formName = 'a2EndAdress';

        if (contactDetailsData?.primaryContactName || contactDetailsData?.primaryContactNumber || contactDetailsData?.primaryContactEmail || contactDetailsData?.altContactName || contactDetailsData?.altContactNumber || contactDetailsData?.altContactEmail) {
          d.primaryContactName = contactDetailsData.primaryContactName;
          d.primaryContactNumber = contactDetailsData.primaryContactNumber;
          d.primaryContactEmail = contactDetailsData.primaryContactEmail;
          d.altContactName = contactDetailsData.altContactName;
          d.altContactNumber = contactDetailsData.altContactNumber;
          d.altContactEmail = contactDetailsData.altContactEmail;
        }
        // d.definedPropertyLocation = contactDetailsData.definedPropertyLocation;

        this.siteDetailsService.latLongFormA2EndAdress = d;

        // Assign to createquote service
        this.createQuoteService.productDetailsFormValue['a2EndAdress'] = this.siteDetailsService.latLongFormA2EndAdress;
      }
    }

    if (event.type == "sameAsPath1AEndAddressDetails" && event.from == "sameAsPath1Z2EndAddressDetails") {

      if (searchFormAEndAdress?.searchMethod === 'search') {
        this.formsConfig[3]['mapCenter'] = this.formsConfig[0]['mapCenter'];
        this.formsConfig[3]['mapZoom'] = this.formsConfig[0]['mapZoom'];
        this.formsConfig[3]['markers'] = this.formsConfig[0]['markers'];
        this.formsConfig[3]['infocontent'] = this.formsConfig[0]['infocontent'];

      }

      // Check and proceed if aEndAdress has searchMethod starts refrence-
      if (referenceFormAEndAdress?.searchMethod?.includes('refrence-')) {
        const contactDetailsData = this.createQuoteService.productDetailsFormValue['z2EndAdress'];

        // Switch tab to location refernce
        var a = setTimeout(() => {
          this.formsConfig[3].activeTab = 3;
          clearTimeout(a);
        }, 200);

        const d = referenceFormAEndAdress;
        d.siteType = 'z2EndAdress';
        d.formName = 'z2EndAdress';

        if (contactDetailsData?.primaryContactName || contactDetailsData?.primaryContactNumber || contactDetailsData?.primaryContactEmail || contactDetailsData?.altContactName || contactDetailsData?.altContactNumber || contactDetailsData?.altContactEmail) {
          d.primaryContactName = contactDetailsData.primaryContactName;
          d.primaryContactNumber = contactDetailsData.primaryContactNumber;
          d.primaryContactEmail = contactDetailsData.primaryContactEmail;
          d.altContactName = contactDetailsData.altContactName;
          d.altContactNumber = contactDetailsData.altContactNumber;
          d.altContactEmail = contactDetailsData.altContactEmail;
        }
        // d.definedPropertyLocation = contactDetailsData.definedPropertyLocation;

        this.siteDetailsService.referenceFormZ2EndAdress = d;

        // Assign to createquote service
        this.createQuoteService.productDetailsFormValue['z2EndAdress'] = this.siteDetailsService.referenceFormZ2EndAdress;

      }


      // Check and proceed if aEndAdress has searchMethod is latlong
      if (latLongFormAEndAdress?.searchMethod === "Latitude and Longitude") {
        const contactDetailsData = this.createQuoteService.productDetailsFormValue['z2EndAdress'];
        // // Switch tab to location refernce
        var a = setTimeout(() => {
          this.formsConfig[3].activeTab = 2;
          clearTimeout(a);
        }, 200);

        const d = latLongFormAEndAdress;
        d.siteType = 'z2EndAdress';
        d.formName = 'z2EndAdress';

        if (contactDetailsData?.primaryContactName || contactDetailsData?.primaryContactNumber || contactDetailsData?.primaryContactEmail || contactDetailsData?.altContactName || contactDetailsData?.altContactNumber || contactDetailsData?.altContactEmail) {
          d.primaryContactName = contactDetailsData.primaryContactName;
          d.primaryContactNumber = contactDetailsData.primaryContactNumber;
          d.primaryContactEmail = contactDetailsData.primaryContactEmail;
          d.altContactName = contactDetailsData.altContactName;
          d.altContactNumber = contactDetailsData.altContactNumber;
          d.altContactEmail = contactDetailsData.altContactEmail;
        }
        // d.definedPropertyLocation = contactDetailsData.definedPropertyLocation;

        this.siteDetailsService.latLongFormZ2EndAdress = d;

        // Assign to createquote service
        this.createQuoteService.productDetailsFormValue['z2EndAdress'] = this.siteDetailsService.latLongFormZ2EndAdress;
      }


    }

    if (event.type === "sameAsPath1ZEndAddressDetails" && event.from === "sameAsPath1Z2EndAddressDetails") {


      if (searchFormZEndAdress?.searchMethod === 'search') {

        this.formsConfig[3]['mapCenter'] = this.formsConfig[1]['mapCenter'];
        this.formsConfig[3]['mapZoom'] = this.formsConfig[1]['mapZoom'];
        this.formsConfig[3]['markers'] = this.formsConfig[1]['markers'];
        this.formsConfig[3]['infocontent'] = this.formsConfig[1]['infocontent'];

      }

      if (referenceFormZEndAdress?.searchMethod?.includes('refrence-')) {
        const contactDetailsData = this.createQuoteService.productDetailsFormValue['z2EndAdress'];

        // Switch tab to location refernce
        var a = setTimeout(() => {
          this.formsConfig[3].activeTab = 3;
          clearTimeout(a);
        }, 200);

        const d = referenceFormZEndAdress;
        d.siteType = 'z2EndAdress';
        d.formName = 'z2EndAdress';

        if (contactDetailsData?.primaryContactName || contactDetailsData?.primaryContactNumber || contactDetailsData?.primaryContactEmail || contactDetailsData?.altContactName || contactDetailsData?.altContactNumber || contactDetailsData?.altContactEmail) {
          d.primaryContactName = contactDetailsData.primaryContactName;
          d.primaryContactNumber = contactDetailsData.primaryContactNumber;
          d.primaryContactEmail = contactDetailsData.primaryContactEmail;
          d.altContactName = contactDetailsData.altContactName;
          d.altContactNumber = contactDetailsData.altContactNumber;
          d.altContactEmail = contactDetailsData.altContactEmail;
        }
        // d.definedPropertyLocation = contactDetailsData.definedPropertyLocation;

        this.siteDetailsService.referenceFormZ2EndAdress = d;



        // Assign to createquote service
        this.createQuoteService.productDetailsFormValue['z2EndAdress'] = this.siteDetailsService.referenceFormZ2EndAdress;
      }


      // Check and proceed if aEndAdress has searchMethod is latlong
      if (latLongFormZEndAdress?.searchMethod === "Latitude and Longitude") {

        const contactDetailsData = this.createQuoteService.productDetailsFormValue['z2EndAdress'];


        // Switch tab to location refernce
        var a = setTimeout(() => {
          this.formsConfig[3].activeTab = 2;
          clearTimeout(a);
        }, 200);

        const d = latLongFormZEndAdress;
        d.siteType = 'z2EndAdress';
        d.formName = 'z2EndAdress';

        if (contactDetailsData?.primaryContactName || contactDetailsData?.primaryContactNumber || contactDetailsData?.primaryContactEmail || contactDetailsData?.altContactName || contactDetailsData?.altContactNumber || contactDetailsData?.altContactEmail) {
          d.primaryContactName = contactDetailsData.primaryContactName;
          d.primaryContactNumber = contactDetailsData.primaryContactNumber;
          d.primaryContactEmail = contactDetailsData.primaryContactEmail;
          d.altContactName = contactDetailsData.altContactName;
          d.altContactNumber = contactDetailsData.altContactNumber;
          d.altContactEmail = contactDetailsData.altContactEmail;
        }
        // d.definedPropertyLocation = contactDetailsData.definedPropertyLocation;


        this.siteDetailsService.latLongFormZ2EndAdress = d;
        // Assign to createquote service
        this.createQuoteService.productDetailsFormValue['z2EndAdress'] = this.siteDetailsService.latLongFormZ2EndAdress;

      }

    }

  }

}
