import { Component, OnInit, Output, EventEmitter, HostListener, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { forkJoin } from 'rxjs';
import { ToastrService, GlobalConfig, ToastContainerDirective } from 'ngx-toastr';
import { UsersService } from './users.service';
import { CheckboxFilterComponent } from '../../../grid-filter/checkbox-filter/checkbox-filter.component';
import { CustomTextFilterComponent } from '../../../grid-filter/custom-text-filter/custom-text-filter.component';
import { CustomDateFilterComponent } from '../../../grid-filter/custom-date-filter/custom-date-filter.component';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { UserService } from '../user.service';
import { CustomerService } from '../../customer-list/customer.service';
import { DashboardService } from '../../../cabinets/dashboard.service';
import * as moment_ from 'moment';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { Title } from '@angular/platform-browser';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.less']
})
export class UserListComponent implements OnInit, AfterViewInit, OnDestroy {
  public currentlyCheckedCount = 0;
  public isInfracoNbn: boolean = false;
  public inactiveRow = [];
  colDefsAthleteEXcluded: {
  }[];
  currentSelected: { field: String; colId: string; tooltipField: string; filter: string; headerName: string; fullHeaderName: string; customFilter: any, width: any, minWidth: any, maxWidth: any };
  checkedList: any[];
  stopSelect: boolean = false;

  public colDefsAthleteIncluded;

  showFilterPop: boolean = false;


  functionList: any;

  @Output() createUserEvent = new EventEmitter();
  @Output() modifyUserEvent = new EventEmitter();
  public searchvalue;
  public gridConfig: GridOptions;
  public businessList = [];
  public rowData: any;
  public columnDefs: any;
  public gridInitialized: Boolean;
  public aemLabelAuthoring: any;
  subscription: any;
  subscribedflag: Boolean = false;
  public options: GlobalConfig;
  public gridModel: any = {
    // CustomAgGrid = {
    gridOptions: {},
    keysToSuppress: [13], // Supress Enter key row selection
    labelAuthoring: {
      headerPageCount: '',
      filter: '',
      resetFilter: '',
      paginationHeader: '',
      noData: '',
      noDataOnFilter: '',
      noDataOnSearch: '',
      toTop: ''
    }
  };
  public filteredRowData: any = [];
  cidn: any;
  linkedCIDNs: any;
  cidn1: any;
  infraCoCIDN = window['environment'].InfraCoCIDN;
  moment = moment_;
  isDuctUser: boolean = false;
  focusableElement: HTMLElement;
  columnsPresent: boolean = true;
  columnHeaderId = [];
  @Input() selectedIndex: any;
  constructor(private dataSvc: UsersService, private router: Router, private customerService: CustomerService, private commonService: CommonService, private commonUserservice: UserService,
    private dashBoardSvc: DashboardService, private titleService: Title, private utilityService: UtilityService
  ) {
    this.cidn = this.commonService.selectedCidn;
    this.cidn1 = this.commonService.selectedCidn;
    this.createGrid(null); //initialization call
    this.checkedList = [];
    this.customerService.getLinkedCIDNs().then(res => {

      if (res) {

        // let businessList = [];

        const iteratedArr = res.data.businessNameDetails.map(({
          bussinessName,
          cidn
        }) => [bussinessName, cidn]);


        for (var i = 0; i < iteratedArr.length; i++) {

          const businessName = iteratedArr[i][0];

          const cidn = iteratedArr[i][1];

          if (businessName || cidn) {

            this.businessList.push({
              businessName: businessName,
              cidn: cidn
            });

          }


        }





      }

    }).catch((err) => {

    });
    if (this.commonService.getDefaultCustomer()) {
      this.commonService.getDefaultCustomer().pipe().subscribe(resp => {

        this.businessList.forEach(item => {

          if (item.businessName === resp) {


            this.cidn = item.cidn;
            this.gridInitialized = false;
            this.createGrid(null);
            this.checkedList = [];

          }

        })

      })
    }
    this.titleService.setTitle('InfraCo Customer Portal - Admin Settings');

    // this.createGrid(null);
    // this.checkedList = [];
  }

  ngOnDestroy() {
    this.commonService.showTopButtonInFooter(false);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.commonService.showTopButtonInFooter(true);
    }, 1000)
  }

  ngOnInit(): void {
    this.showFilterPop = false;
    this.getLabels();
    const roles = this.commonService.getroleDetails().customerList;
    const nbn = roles.some((obj) => obj.role.includes('NBN') || obj.function.includes('NBN'));
    const duct = roles.some((obj) => obj.role.includes('Duct') || obj.function.includes('Duct'))
    if (nbn) {
      this.isInfracoNbn = true;
    } else {
      this.isInfracoNbn = false;
    }
    const isDuct = roles.some((obj) => obj.role.includes('Duct') || obj.function.includes('Duct'));
    this.isDuctUser = isDuct ? true : false;
    this.colDefsAthleteIncluded = [
      {
        headerName: "Username",
        fullHeaderName: "Username",
        filter: "customTextFilter",
        field: "username",
        colId: "a",
        tooltipField: "username",
        minWidth: 300

      },
      {
        headerName: "First name",
        fullHeaderName: "First name",
        field: "firstName",
        colId: "b",
        tooltipField: "firstName",
        filter: "customTextFilter",
        minWidth: 150
      },

      {
        headerName: "Status",
        fullHeaderName: "status",
        field: "status",
        colId: "d",
        tooltipField: "status",
        filter: "CheckboxFilterComponent",
        minWidth: 120,
        customFilter: [
          {
            "elementData": "Active"
          },
          {
            "elementData": "Inactive"
          }
        ]
      },

      {
        headerName: "Mobile number",
        fullHeaderName: "Mobile number",
        field: "phoneNumber",
        colId: "e",
        tooltipField: "phoneNumber",
        filter: "customTextFilter",
        minWidth: 200
      },
      {
        headerName: "Customer name",
        fullHeaderName: "Customer name",
        field: "customerName",
        colId: "f",
        tooltipField: "customerName",
        filter: "customTextFilter",
        minWidth: 180
      },
      {
        headerName: "Role access",
        fullHeaderName: "Role access",
        field: "role_name",
        colId: "h",
        tooltipField: "role_name",
        filter: "CheckboxFilterComponent",
        minWidth: 210,
        customFilter: this.getCustomRoleAccessFilter()
      },
      {
        headerName: this.isInfracoNbn ? "Modified date" : "Role functions",
        fullHeaderName: this.isInfracoNbn ? "Modified date" : "Role functions",
        field: this.isInfracoNbn ? "modifiedDate" : "functionName",
        colId: this.isInfracoNbn ? "k" : "i",
        tooltipField: this.isInfracoNbn ? "modifiedDate" : "functionName",
        filter: this.isInfracoNbn ? "customDateFilter" : "CheckboxFilterComponent",
        minWidth: 240,
        "customFilter": this.getCustomRoleFunctionFilter()
      }
    ]
    this.functionList =
      [
        {
          name: 'Username', value: 'username', checked: false, colId: "a",
          tooltipField: "username", filter: "customTextFilter", headerName: "Username",
          fullHeaderName: "Username"
        },
        {
          name: 'First name', value: 'firstName', checked: false, colId: "b",
          tooltipField: "firstName",
          filter: "customTextFilter",
          headerName: "First name",
          fullHeaderName: "First name"
        },

        {
          name: 'Last name', value: 'lastName', checked: false, colId: "c",
          tooltipField: "lastName",
          filter: "customTextFilter",
          headerName: "Last name",
          fullHeaderName: "Last name"
        },
        {
          name: 'Status', value: 'status', checked: false, colId: "d",
          tooltipField: "status",
          filter: "CheckboxFilterComponent", headerName: "Status",
          fullHeaderName: "status",
          "customFilter": [
            {
              "elementData": "Active"
            },
            {
              "elementData": "Inactive"
            }
          ]

        },
        {
          name: 'Mobile number', value: 'phoneNumber', checked: false, colId: "e",
          tooltipField: "phoneNumber",
          filter: "customTextFilter", headerName: "Mobile number",
          fullHeaderName: "Mobile number"
        },
        {
          name: 'Customer name', value: 'customerName', checked: false, colId: "f",
          tooltipField: "customerName",
          filter: "customTextFilter", headerName: "Customer name",
          fullHeaderName: "Customer name"
        },
        {
          name: 'Application', value: 'appName', checked: false, colId: "g",
          tooltipField: "appName",
          filter: "customTextFilter", headerName: "Application",
          fullHeaderName: "Application"
        },
        {
          name: 'Role access', value: 'role_name', checked: false, colId: "h",
          tooltipField: "role_name",
          filter: "CheckboxFilterComponent", headerName: "Role access",
          fullHeaderName: "Role access",
          customFilter: this.getCustomRoleAccessFilter()
        },
        {
          name: "Role functions",
          value: "functionName",
          checked: false,
          colId: "i",
          tooltipField: "functionName",
          filter: "CheckboxFilterComponent",
          headerName: "Role functions",
          fullHeaderName: "Role functions",
          "customFilter": this.getCustomRoleFunctionFilter()
        },
        {
          name: 'Modified by', value: 'modifiedBy', checked: false, colId: "j",
          tooltipField: "modifiedBy",
          filter: "customTextFilter", headerName: "Modified by",
          fullHeaderName: "Modified by",
        },
        {
          name: 'Modified date', value: 'modifiedDate', checked: false, colId: "k",
          tooltipField: "modifieddate",
          filter: "customDateFilter", headerName: "Modified date",
          fullHeaderName: "Modified date",
        }
      ];
    // this.cidn=this.commonService.getSelectedCidn();

  }
  ngOnChanges() {
    this.showFilterPop = this.selectedIndex === 0 ? false : this.showFilterPop;
  }
  closePopup() {
    this.showFilterPop = false;
  }

  getLabels() {
    return new Promise((resolve, reject) => {
      this.dataSvc.getUserListModel().then(() => {
        this.aemLabelAuthoring = this.dataSvc.gridLabelAuthoringDataObj;
        this.gridModel.labelAuthoring = this.aemLabelAuthoring.form.grid;
        // this.successMsg();
        resolve(true);
      }).catch(() => {
        // reject(false);
      });
    });
  }

  shareIndividualCheckedList(item: {}) {
  }

  leaveColPref() {
    document.getElementById('colPrefTabUsr').style.display = 'none';
  }
  disableRemainingCheckboxes() {
    //document.querySelectorAll('input[type="checkbox"]:not(:checked)'.disabled=false;
    Array.from(document.querySelectorAll('input[type="checkbox"]:not(:checked)')).forEach(ele => {
      ele.setAttribute('disabled', 'true');
    });


  }

  focusOnTop(event) {
    if (event) {
      let focusableElement;
      focusableElement = document.getElementById('customerList');
      if (focusableElement) {
        focusableElement.setAttribute('tabindex', '0');
        focusableElement.addEventListener('blur', () => {
          focusableElement.removeAttribute('tabindex');
        });
        setTimeout(() => {
          if (focusableElement) {
            focusableElement.focus();
          }
        }, 10);
      }
    }
  }

  enableAllCheckboxes() {
    Array.from(document.querySelectorAll('input[type="checkbox"]:not(:checked)')).forEach(ele => {
      ele.removeAttribute('disabled');
    })

  }



  createGrid(payload: any) {
    if (this.subscribedflag) {
      this.subscription.unsubscribe();
    } else {
      this.subscribedflag = true;
    }
    const promise1 = this.createColumnDefs();
    const promise2 = this.createRowData(payload);
    this.subscription = forkJoin([promise1, promise2]).subscribe(result => {
      this.createGridConfig();
    }, error => {
      this.createGridConfig();
      //  this.router.navigateByUrl('/error');
    });
  }

  private createColumnDefs() {
    return new Promise((resolve, reject) => {
      this.dataSvc.getGridHeader().then(() => {
        this.columnDefs = this.colDefsAthleteIncluded;
        this.columnDefs.forEach((x: any) => {
          this.columnHeaderId.push(x.field);
        });
        this.functionList.map(f => {
          if (this.columnHeaderId.includes(f.value)) {
            f.checked = true;
          }
        });
        this.checkedList = this.deepCopy(this.colDefsAthleteIncluded);

        resolve(true);
      }).catch((err) => {
        reject(err);
      });
    });
  }
  deepCopy(element) {
    return JSON.parse(JSON.stringify(element));
  }
  getSelectedValue(status: Boolean, value: String, colId: string, tooltipField: string, filter: string, headerName: string, fullHeaderName: string, customFilter: any) {
    // if (status) {
    //   this.currentlyCheckedCount++
    // } else {
    //   this.currentlyCheckedCount--
    // }
    // if (this.currentlyCheckedCount == 7) {
    //   this.disableRemainingCheckboxes()
    // } else {
    //   this.enableAllCheckboxes()
    // }

    // if (status) {
    //   this.checkedList.push(value);
    // } else {
    //   var index = this.checkedList.indexOf(value);
    //   this.checkedList.splice(index, 1);
    // }

    if (status) {
      this.currentSelected = { field: value, colId: colId, tooltipField: tooltipField, filter: filter, headerName: headerName, fullHeaderName: fullHeaderName, customFilter: customFilter, width: 200, minWidth: 150, maxWidth: 200 };
      this.checkedList.push(this.currentSelected);
    } else {
      const index = this.checkedList.findIndex(obj => obj.field === value); // Find the index of the object with id=2
      if (index !== -1) {
        this.checkedList.splice(index, 1); // Remove the object at the found index
      }
    }
    this.checkedList.sort((a, b) => a.colId.localeCompare(b.colId))
    this.checkedList.map(c => {
      if (c.headerName === 'Modified date') {
        c.sortable = true;
        c.sort = 'desc';
        c.comparator = (date1: string, date2: string) => this.utilityService.customDateSorter(date1, date2),
          c.getQuickFilterText = function (params) {
            return '';
          }
      }
    });
    if (this.checkedList.length === 0) {
      this.columnsPresent = false;
    } else {
      this.columnsPresent = true;
    }
    setTimeout(() => {
      if (this.gridModel.gridOptions.api) {
        this.gridModel.gridOptions.api.setColumnDefs([]);
        this.gridModel.gridOptions.api.setColumnDefs(this.checkedList);
        this.gridModel.gridOptions.api.sizeColumnsToFit();
      }
      //   this.createGrid(null);

      setTimeout(() => {
        this.gridModel.gridOptions.api.setQuickFilter('');
        this.gridModel.gridOptions.api.setQuickFilter(this.searchvalue);
      }, 0);
    }, 500);
  }

  showPop() {
    this.showFilterPop = true;
    setTimeout(() => {
      document.getElementById('colPrefTabUsr').style.display = 'block';
      this.focusableElement = document.getElementById('fir');
      this.focusableElement.focus();
    }, 1);
    this.commonService.trapFocusInModal('fir', 'rmv');
  }

  private createRowData(params?: any) {
    const param = params || null;
    this.rowData = [];

    if (this.cidn === this.infraCoCIDN) {
      return new Promise((resolve, reject) => {

        this.dataSvc.getGridDataForAll(param).then((res) => {
          const DATA = res['data']['userDetailsList'];
          this.getRowData(this.dataSvc.gridDataObj);
          resolve(true);
        }).catch((err) => {
          this.rowData = [];
          reject(err);
        });
      });
    } else {
      return new Promise((resolve, reject) => {
        // const cidn = this.commonService.setSelectedCidn;
        this.dataSvc.getGridData(param, this.cidn).then((res) => {
          const DATA = res['data']['userDetailsList'];
          this.getRowData1(this.dataSvc.gridDataObj);
          resolve(true);
        }).catch((err) => {
          this.rowData = [];
          reject(err);
        });
      });
    }
  }

  getRowData(data) {
    this.rowData = data;
    const DATA_ROW = [];
    for (let i = 0; i < this.rowData.length; i++) {
      const SELECT_ROW = this.rowData[i];
      if (SELECT_ROW.roles) {
        const USER_ROLE = SELECT_ROW.roles;
        SELECT_ROW.roles = [];
        for (let j = 0; j < USER_ROLE.length; j++) {
          const TEMP = JSON.parse(JSON.stringify(SELECT_ROW));
          TEMP.roles = USER_ROLE[j].value;
          TEMP.customerName = USER_ROLE[j].customerName;
          TEMP.role_name = USER_ROLE[j].role_name;
          TEMP.functionName = USER_ROLE[j].functionName;
          TEMP.appName = USER_ROLE[j].appName;
          //  TEMP.appName = USER_ROLE[j].appName.toUpperCase();
          if (TEMP.customerName) {
            DATA_ROW.push(TEMP);
          }
          // if( TEMP.role_name){
          //   DATA_ROW.push(TEMP);
          // }
        }
      }
      if (SELECT_ROW.status === 'Inactive') {
        const DATA_ROW1 = []
        const TEMP1 = JSON.parse(JSON.stringify(SELECT_ROW));
        this.inactiveRow.push(TEMP1);
      }
    }
    this.rowData = DATA_ROW.length > 0 ? DATA_ROW : this.rowData;
    this.rowData = [...this.rowData, ...this.inactiveRow];
  }
  getRowData1(data) {
    this.rowData = data;
    const DATA_ROW = [];
    for (let i = 0; i < this.rowData.length; i++) {
      const SELECT_ROW = this.rowData[i];
      if (SELECT_ROW.roles) {
        const USER_ROLE = SELECT_ROW.roles;
        SELECT_ROW.roles = [];
        for (let j = 0; j < USER_ROLE.length; j++) {
          const TEMP = JSON.parse(JSON.stringify(SELECT_ROW));
          TEMP.roles = USER_ROLE[j].value;
          TEMP.customerName = USER_ROLE[j].customerName;
          TEMP.role_name = USER_ROLE[j].role_name;
          TEMP.functionName = USER_ROLE[j].functionName;
          TEMP.appName = USER_ROLE[j].appName;
          //  TEMP.appName = USER_ROLE[j].appName.toUpperCase();
          if (TEMP.customerName) {
            DATA_ROW.push(TEMP);
          }
          // if( TEMP.role_name){
          //   DATA_ROW.push(TEMP);
          // }
        }
      }

    }
    this.rowData = DATA_ROW.length > 0 ? DATA_ROW : this.rowData;
  }

  private createGridConfig() {
    this.gridConfig = <GridOptions>{};
    this.gridConfig.rowData = this.rowData;
    this.gridConfig.paginationPageSize = 10;
    this.gridConfig.columnDefs = this.columnDefs;
    this.gridConfig.suppressHorizontalScroll = true;
    this.gridConfig.pagination = true;
    this.gridConfig.rowSelection = 'single';
    this.gridInitialized = true;
    this.gridConfig.frameworkComponents = {
      CheckboxFilterComponent: <any>CheckboxFilterComponent,
      customTextFilter: <any>CustomTextFilterComponent,
      customDateFilter: <any>CustomDateFilterComponent
      //  FullwidthRowRenderer: <any>GridTilesCustomerViewComponent
    };
    this.gridModel.gridOptions = this.gridConfig;
  }

  onSelectionChanged(arg: any) {
    const selectedRows = arg.gridApi.getSelectedRows();
    this.navigateToModify(selectedRows[0]);
  }

  navigateToCreateUser() {
    // this.router.navigate(['admin/createUser']);
    //  location.reload();
    this.createUserEvent.emit('true');

  }

  onFilterSelected(filteredData: any) {
    this.filteredRowData.length = 0;
    if (filteredData.filteredRowData && filteredData.filteredRowData.length > 0) {
      filteredData.filteredRowData.forEach(row => {
        if (row.data) {
          this.filteredRowData.push(row.data);
        }
      });
    }
  }
  quickSearch() {
    this.gridModel.gridOptions.api.setQuickFilter(this.searchvalue);
  }
  clearsearch() {
    (<HTMLInputElement>document.getElementById("text")).value = '';
    this.gridModel.gridOptions.api.setQuickFilter('');
    this.searchvalue = '';

  }


  navigateToModify(data) {
    this.commonUserservice.setModifyUserData(data.username, data.id);
    this.modifyUserEvent.emit('true');
  }

  toggleCheckbox(a: any) {
    a.checked = !a.checked;
  }


  download() {
    const data = [];
    let dataHeaders = [];
    if (this.isInfracoNbn) {
      dataHeaders = ['Username', 'First name', 'Status', 'Mobile Number', 'Customer Name', 'Role access', 'Modified date'];
    } else if (this.isDuctUser) {
      dataHeaders = ['Username', 'First name', 'Last name', 'Customer Name', 'Role access'];
    }
    const now = this.moment().format('YYYYMMDDHHmmss');
    let dataToBeDownloaded = [];
    if (this.filteredRowData && this.filteredRowData.length > 0) {
      dataToBeDownloaded = this.filteredRowData;
    } else {
      if (this.rowData && this.rowData.length) {
        dataToBeDownloaded = this.rowData;
      }
    }
    if (this.isInfracoNbn) {
      dataToBeDownloaded.forEach(selectedRow => {
        data.push({
          'Username': selectedRow.username,
          'First name': selectedRow['firstName'],
          'Status': selectedRow.status,
          'Mobile Number': selectedRow.phoneNumber,
          'Customer name': selectedRow.customerName,
          'Role access': selectedRow.role_name,
          'Modified date': selectedRow.modifiedDate
        });
      });
    } else if (this.isDuctUser) {
      dataToBeDownloaded.forEach(selectedRow => {
        data.push({
          'Username': selectedRow.username,
          'First name': selectedRow['firstName'],
          'Last name': selectedRow['lastName'],
          'Customer name': selectedRow.customerName,
          'Role access': selectedRow.role_name,
        });
      });
    }
    new Angular5Csv(data, "My users_" + now, { headers: dataHeaders });
  }

  getCustomRoleAccessFilter(): any[] {
    if (this.isInfracoNbn) {
      return [
        {
          "elementData": "NBN Administrator"
        },
        {
          "elementData": "NBN Read & Write"
        },
        {
          "elementData": "NBN Read Only"
        }
      ]
    } else if (this.isDuctUser) {
      return [
        {
          "elementData": "Duct Administrator"
        },
        {
          "elementData": "Duct Read & Write"
        }
      ]
    } else {
      return [
        {
          "elementData": "Administrator"
        },
        {
          "elementData": "Read & Write"
        },
        {
          "elementData": "Read Only"
        }
      ];
    }
  };

  getCustomRoleFunctionFilter() {
    if (this.isInfracoNbn) {
      return [
        {
          "elementData": "NBN Cabinets"
        },
        {
          "elementData": "NBN Cabinet Access Management"
        },
        {
          "elementData": "NBN User Management"
        },
        {
          "elementData": "NBN DA Documents"
        }
      ]
    } else if (this.isDuctUser) {
      return [
        {
          "elementData": "Duct User Management"
        },
        {
          "elementData": "Duct Access Form"
        },
      ]
    } else {
      return [
        {
          "elementData": "Customer Management"
        },
        {
          "elementData": "Document Management"
        },
        {
          "elementData": "Quote Request Management"
        },
        {
          "elementData": "Order Management"
        },
        {
          "elementData": "Service Management"
        },
        {
          "elementData": "User Management"
        }
      ]
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyUp(event) {
    if ((event.keyCode === 32 || event.key === " ") && event.target.nodeName !== 'INPUT') {
      event.preventDefault();
    }
    if (event.keyCode === 27) {
      this.closePopup();
    }
    if (event.keyCode === 13 && event.target.classList.contains('closePop') && this.showFilterPop) {
      this.closePopup();
    }
  }

}
