<div class="footer-cls">
  
  <div class="top-btn-container" *ngIf="showTopButton">
    <button class="back-to-top-btn col-1"  (click)="backToTop()" style="border: none !important;" tabindex="0" aria-label="top">
      Top
      <img class="mb-1" src="../../../../assets/images/Icon-Top-Arrow-btn.svg" alt="top arrow button" />
    </button>
  </div>
  <div class="d-flex align-items-center justify-content-end">
  <span class="link" role="contentinfo">
  <a tabindex="0" aria-label="privacy" class="prvcy-cls-new" rel="noopener noreferrer" target="_blank" role="link"
      href="https://www.telstra.com.au/privacy"> Privacy </a>
  </span>
 </div>
</div>
