<div class="page-bg">
    <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex gap-2">
            <img class="quoteLogo" src="../../../assets/images/Details-Doc.svg" alt="error page" />
            <h2 class="my-quote-header" id="myOrdersHeader">
                <strong>Bulk quote details</strong></h2>
        </div>
    </div>
    <div class="grid-container" *ngIf="gridConfig && gridInitialized && rowData?.length">
        <app-cui-ag-grid [model]="gridModel">
        </app-cui-ag-grid>
    </div>
</div>