import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'tw-core-ui';

@Injectable({
  providedIn: 'root'
})
export class MyOrdersService {
  public orderLabelDataObj: any;

  private env: any = window['environment'];
  private aemDefaultUri = {
    orderGridLabel: 'myOrders',
    orderGridHeader: 'order-header.json',
    allOrderGridHeader: 'all-order-header.json',
    requestDetails: 'order-request-details.json',
    mockGetOrderList: 'mock/my-orders.json',
    myOrders: 'darkfibre-order/order-requests',
    mockOrderDetails: 'mock/order-details.json',
    orderSiteDetails: 'order-site-details.json',
    mockSaveOrder: 'mock/order-details.json',
  };
  public gridHeaderDataObj: any = [];
  public requestDetailsLabelAuthoringDataObj: any;
  public orderSiteDetailsLabelAuthoringDataObj: any;

  public allGridHeaderDataObj: any = [];
  constructor(private http: HttpClient, private authService: AuthService) { }
  public getOrderLabels() {
    return new Promise((resolve, reject) => {
      if (!this.orderLabelDataObj) {
        const url = this.env.aemEndPoint + this.aemDefaultUri.orderGridLabel;
        this.http.get<any>(url)
          .subscribe(
            res => {
              this.orderLabelDataObj = res.data.content;
              resolve(this.orderLabelDataObj);
            },
            err => {
              this.orderLabelDataObj = null;
              reject(err);
              return observableThrowError(err || 'Server error');
            }
          );
      } else {
        resolve(this.orderLabelDataObj);
      }
    });
  }


  public getGridHeader() {
    return new Promise((resolve, reject) => {
      this.http.get<GridHeaderInterface>(this.env.aemEndPoint + this.aemDefaultUri.orderGridHeader)
        .subscribe(res => {
          this.gridHeaderDataObj = res;
          resolve(this.gridHeaderDataObj);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }
  public getAllOrderGridHeader() {
    return new Promise((resolve, reject) => {
      this.http.get<GridHeaderInterface>(this.env.aemEndPoint + this.aemDefaultUri.allOrderGridHeader)
        .subscribe(res => {
          this.allGridHeaderDataObj = res;
          resolve(this.allGridHeaderDataObj);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }
  public getOrderList(cidn, timeZone) {
    const username = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail'];
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',

      })
    };
    const url = this.env.apiPoint + this.aemDefaultUri.myOrders + '?cidn=' + cidn + '&timezone=' + timeZone;
    //To run in local
   //  const url = this.env.aemEndPoint + this.aemDefaultUri.mockGetOrderList;
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(url, httpOptions)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }
  public saveNewOrder(payload) {

    const username = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail'];

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',
      })
    };
    const url = this.env.apiPoint + this.aemDefaultUri.myOrders;
    //To run in local
   //  const url = this.env.aemEndPoint + this.aemDefaultUri.mockSaveOrder;

    // if(payload.aEndAdress.siteType === "a2EndAdress"){
    //   payload.aEndAdress.siteType = "aEndAdress";
    // }

    return new Promise<any>((resolve, reject) => {
      this.http.put<any>(url, payload, httpOptions)
    //  this.http.get<any>(url, httpOptions)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getSingleOrder(cidn, timeZone, oid, path, screenName?) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',

      })
    };
    //  const url = this.env.aemEndPoint + this.aemDefaultUri.mockOrderDetails;

      let url = this.env.apiPoint + this.aemDefaultUri.myOrders + '/'+oid +'?cidn=' + cidn+'&timezone='+timeZone+'&orderConvertedFrom='+path;
      if(screenName){
        url += "&screenName=" + screenName;
      }
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(url, httpOptions)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }







  public getRequestDetailsPageLabel() {
    // this.sendSpinnerFlag.next(true);
    return new Promise((resolve, reject) => {
      const requestDetailsUrl = this.env.aemEndPoint + this.aemDefaultUri.requestDetails;
      this.http.get<any>(requestDetailsUrl)
        .subscribe(
          res => {
            this.requestDetailsLabelAuthoringDataObj = res;
            resolve(this.requestDetailsLabelAuthoringDataObj);
          },
          err => {
            this.requestDetailsLabelAuthoringDataObj = null;
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }






  public getOrderSiteDetailsPageLabel() {
    return new Promise((resolve, reject) => {
      const orderSiteDetailsUrl = this.env.aemEndPoint + this.aemDefaultUri.orderSiteDetails;
      this.http.get<any>(orderSiteDetailsUrl)
        .subscribe(
          res => {
            this.orderSiteDetailsLabelAuthoringDataObj = res;
            resolve(this.orderSiteDetailsLabelAuthoringDataObj);
          },
          err => {
            this.orderSiteDetailsLabelAuthoringDataObj = null;
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }
}

function observableThrowError(arg0: any) {
  throw new Error('Function not implemented.');
}
export interface GridHeaderInterface {
  headers: any;
}
