import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { ToastrService, ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProfileComponent } from './profile/profile.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { BannerComponent } from './banner/banner.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AdminSettingsComponent } from './admin-settings/admin-settings.component';
import { UserListComponent } from './admin-settings/user/user-list/user-list.component';
import { CustomerListComponent } from './admin-settings/customer-list/customer-list.component';
import { ModifyCustomerComponent } from './admin-settings/modify-customer/modify-customer.component';
import { ModifyUserComponent } from './admin-settings/user/modify-user/modify-user.component';
import { CreateUserComponent } from './admin-settings/user/create-user/create-user.component';
import { CreateCustomerComponent } from './admin-settings/create-customer/create-customer.component';
import {  AuthGuardService, AuthService, CanDeactivateGuard, SpinnerModule, TwCoreUIFormModule, TwCoreUIUtilityService } from 'tw-core-ui';
import { LandingPopUpComponent } from './landing-pop-up/landing-pop-up.component';
import { CustomerService } from './admin-settings/customer-list/customer.service';
import { GridFilterModule } from './grid-filter/grid-filter.module';
import { AgGridModule } from 'ag-grid-angular';
import { CustomTextFilterComponent } from './grid-filter/custom-text-filter/custom-text-filter.component';
import { CheckboxFilterComponent } from './grid-filter/checkbox-filter/checkbox-filter.component';
import { CustomDateFilterComponent } from './grid-filter/custom-date-filter/custom-date-filter.component';
import { NgbModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from './shared/services/utility.service';
import { UsersModule } from './admin-settings/user/user.module';
import { ViewAuditModalComponent } from './view-audit-modal/view-audit-modal.component';
import { DatePipe, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { APP_BASE_HREF } from '@angular/common';
import { ProfileService } from './profile/profile.service';
import { UsersService } from './admin-settings/user/user-list/users.service';
import { CreateCabinetComponent } from './cabinets/create-cabinet/create-cabinet.component';
import { CabinetsComponent } from './cabinets/cabinets.component';
import { CabinetsActionRendererComponent } from './cabinets/cabinets-action-renderer/cabinets-action-renderer.component';
import { NbnDropdownMenuComponent } from './nbn-dropdown-menu/nbn-dropdown-menu.component';
import { UploadCabinetsDocumentComponent } from './cabinets/upload-cabinets-document/upload-cabinets-document.component';
import { SuccessModalWithCopyLinkComponent } from './cabinets/upload-cabinets-document/success-modal-with-copy-link/success-modal-with-copy-link.component';
import { CabinetsDocumentListComponent } from './cabinets/cabinets-document-list/cabinets-document-list.component';
import { CabinetsDocumentListActionRendererComponent } from './cabinets/cabinets-document-list/cabinets-document-list-action-renderer/cabinets-document-list-action-renderer.component';
import { WhitelistComponent } from './whitelist/whitelist.component';
import { ManageCabinetsListComponent } from './manage-cabinets-list/manage-cabinets-list.component';
import { CabinetListComponent } from './cabinets/cabinet-list/cabinet-list.component';
import { ManageCabinetsAccessComponent } from './manage-cabinet-access/manage-cabinet-access.component';
import { CommonService } from './shared/services/common.service';
import { DashboardService } from './cabinets/dashboard.service';
import { FaqComponent } from './faq/faq.component';
import { DuctAccessFormComponent } from './duct-access-form/duct-access-form.component';
import { TermsAndConditionsRendererComponent } from './duct-access-form/terms-and-conditions-renderer/terms-and-conditions-renderer.component';
import { CompanyCustomerDetailsComponent } from './duct-access-form/company-customer-details/company-customer-details.component';
import { AttachmentRequirementComponent } from './duct-access-form/attachment-requirement/attachment-requirement.component';
import { AddressDetailsComponent } from './duct-access-form/address-details/address-details.component';
import { MatStepperModule } from '@angular/material/stepper';
import { NbnDocumentsListComponent } from './nbn-user/documents-list/nbn-documents-list.component';
import { NbnUserHomeComponent } from './nbn-user/nbn-user-home.component';
import { NbnUserLandingPageComponent } from './nbn-user/landing-page/landing-page.component';
import { DashboardActionsRendererComponent } from './nbn-user/components/dashboard-actions-renderer/dashboard-actions-renderer.component';
import { DocumentsTitleRendererComponent } from './nbn-user/components/documents-title-renderer-component/documents-title-renderer-component.component';
import { RouteGuardService } from './shared/services/route-guard.service';
import { FileUploadDirective } from './shared/directive/fileUpload.directive';
import { DuctFileUploadDirective } from './shared/directive/ductFileUpload.directive';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { DuctAccessDropdownMenuComponent } from './duct-access-dropdown-menu/duct-access-dropdown-menu.component';
import { ViewDocumentRendererComponent } from './view-document-renderer/view-document-renderer.component';
import { BtnCellRendererComponent } from './whitelist/btn-cell-renderer/btn-cell-renderer.component';
import { CabinetsDocumentTitleActionRendererComponent } from './cabinets/cabinets-document-list/cabinets-document-title-action-renderer/cabinets-document-title-action-renderer.component';
import { ErrorComponent } from './error/error.component';
import { Interceptor } from './shared/interceptor/interceptor';
import { FibreDropdownMenuComponent } from './fibre-dropdown-menu/fibre-dropdown-menu.component';
import { SpinnerModalComponent } from './spinner-modal/spinner-modal.component';
import { QuoteManagementComponent } from './quote-management/quote-management.component';
import { MyQuotesComponent } from './quote-management/my-quotes/my-quotes.component';
import { CreateNewQuoteComponent } from './quote-management/create-new-quote/create-new-quote.component';
import { ProductDetailsComponent } from './quote-management/create-new-quote/product-details/product-details.component';
import { SiteDetailsComponent } from './quote-management/create-new-quote/site-details/site-details.component';
import { RequestDetailsComponent } from './quote-management/create-new-quote/request-details/request-details.component';
import { ReviewSubmitService } from './quote-management/create-new-quote/review-and-submit/review-and-submit.service';
import { ReviewAndSubmitComponent } from './quote-management/create-new-quote/review-and-submit/review-and-submit.component';
import { CreateQuoteService } from './quote-management/create-new-quote/create-quote.service';
import { SiteDetailsService } from './quote-management/create-new-quote/site-details/site-details.service';
import { myQuotesService } from './quote-management/my-quotes/myquotes.service';
import { WorklistStatusRendererComponent } from './quote-management/my-quotes/worklist-status-renderer/worklist-status-renderer.component';
import { QuoteSummaryComponent } from './quote-management/quote-summary/quote-summary.component';
import { MatRadioModule } from '@angular/material/radio';
import { SuccessPageComponent } from './success-page/success-page.component';
import { PageStepperComponent } from './shared/components/page-stepper/page-stepper.component';
import { TabsComponent } from './quote-management/create-new-quote/site-details/components/tabs/tabs.component';
import { SearchFormComponent } from './quote-management/create-new-quote/site-details/components/search-form/search-form.component';
import { LatLngFormComponent } from './quote-management/create-new-quote/site-details/components/lat-lng-form/lat-lng-form.component';
import { RefrenceFormComponent } from './quote-management/create-new-quote/site-details/components/refrence-form/refrence-form.component';
import { ContactDetailsComponent } from './quote-management/create-new-quote/site-details/components/contact-details/contact-details.component';
import { TermsConditionModalComponent } from './quote-management/terms-conditions/terms-conditions-modal.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from "ngx-ui-loader";
import { ngxUiLoaderConfig, ngxUiLoaderHttpConfig } from './shared/config/loader.configuration';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CanNavigateGuardService } from './can-navigate-guard.service';
import { OrderByPipe } from './order-by.pipe';
import { WithdrawModalComponent } from './quote-management/quote-summary/withdraw/withdraw-modal.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapsService } from './maps.service';
import { MapItemComponent } from './quote-management/quote-summary/map-item/map-item.component';
import { HtmlToPdfComponent } from './quote-management/quote-summary/html-to-pdf/html-to-pdf.component';
import { InputRefDirective } from './quote-management/create-new-quote/request-details/Input.ref.directive';
import { TitleCasePipe } from '@angular/common';
import { OrderSiteDetailsComponent } from './order-managemnt/create-new-order/order-site-details/order-site-details.component';
import { OrderRequestDetailsComponent } from './order-managemnt/create-new-order/order-request-details/order-request-details.component';
import { OrderReviewSubmitComponent } from './order-managemnt/create-new-order/order-review-submit/order-review-submit.component';
import { MyOrdersComponent } from './order-managemnt/my-orders/my-orders.component';
import { MyOrdersService } from './order-managemnt/my-orders.service';
import { BulkRequestDetailsComponent } from './bulk-quote-management/bulk-request-details/bulk-request-details.component';
import { BulkUploadComponent } from './bulk-quote-management/bulk-upload/bulk-upload.component';
import { BulkQuoteListComponent } from './bulk-quote-management/bulk-quote-list/bulk-quote-list.component';
import { BulkQuoteService } from './bulk-quote-management/bulk-quote.service';
import { ErrorRendererComponent } from './bulk-quote-management/bulk-quote-list/error-renderer/error-renderer.component';

import { ToolTipComponent } from './shared/components/tooltip/tool-tip.component';
import { NotificationComponent } from './notification/notification.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatDividerModule } from '@angular/material/divider';
import { A11yModule } from '@angular/cdk/a11y';


@NgModule({
  declarations: [
    AppComponent,
    InputRefDirective,
    ProfileComponent,
    HomeComponent,
    BannerComponent,
    HeaderComponent,
    FooterComponent,
    AdminSettingsComponent,
    CustomerListComponent,
    ModifyCustomerComponent,
    CreateCustomerComponent,
    LandingPopUpComponent,
    ViewAuditModalComponent,
    CreateCabinetComponent,
    CabinetsComponent,
    CabinetsActionRendererComponent,
    NbnDropdownMenuComponent,
    UploadCabinetsDocumentComponent,
    SuccessModalWithCopyLinkComponent,
    CabinetsDocumentListComponent,
    CabinetsDocumentListActionRendererComponent,
    WhitelistComponent,
    ManageCabinetsListComponent,
    CabinetListComponent,
    ManageCabinetsAccessComponent,
    FaqComponent,
    DuctAccessFormComponent,
    TermsAndConditionsRendererComponent,
    CompanyCustomerDetailsComponent,
    AttachmentRequirementComponent,
    AddressDetailsComponent,
    NbnDocumentsListComponent,
    NbnUserHomeComponent,
    NbnUserLandingPageComponent,
    DashboardActionsRendererComponent,
    DocumentsTitleRendererComponent,
    FileUploadDirective,
    DuctFileUploadDirective,
    AccessDeniedComponent,
    DuctAccessDropdownMenuComponent,
    ViewDocumentRendererComponent,
    BtnCellRendererComponent,
    CabinetsDocumentTitleActionRendererComponent,
    ErrorComponent,
    FibreDropdownMenuComponent,
    SpinnerModalComponent,
    QuoteManagementComponent,
    MyQuotesComponent,
    CreateNewQuoteComponent,
    ProductDetailsComponent,
    SiteDetailsComponent,
    RequestDetailsComponent,
    ReviewAndSubmitComponent,
    WorklistStatusRendererComponent,
    ToolTipComponent,
    QuoteSummaryComponent,
    SuccessPageComponent,
    PageStepperComponent,
    TabsComponent,
    SearchFormComponent,
    LatLngFormComponent,
    RefrenceFormComponent,
    ContactDetailsComponent,
    TermsConditionModalComponent,
    OrderByPipe,
    WithdrawModalComponent,
    MapItemComponent,
    HtmlToPdfComponent,
    OrderSiteDetailsComponent,
    OrderRequestDetailsComponent,
    OrderReviewSubmitComponent,
    MyOrdersComponent,
    BulkRequestDetailsComponent,
    BulkUploadComponent,
    BulkQuoteListComponent,
    ErrorRendererComponent,
    NotificationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatTabsModule,
    BrowserAnimationsModule,
    FormsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot(ngxUiLoaderHttpConfig),
    ReactiveFormsModule,
    MatDialogModule,
    HttpClientModule,
    TwCoreUIFormModule,
    GridFilterModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    AgGridModule.withComponents([
      CustomTextFilterComponent,
      CheckboxFilterComponent,
      CustomDateFilterComponent,
      WorklistStatusRendererComponent,
      ErrorRendererComponent,
      ToolTipComponent
    ]),
    NgbTooltipModule,
    NgbModule,
    UsersModule,
    ToastrModule.forRoot(),
    ToastContainerModule,
    MatStepperModule,
    SpinnerModule,
    MatRadioModule,
    NgbNavModule,
    ScrollingModule,
    GoogleMapsModule,
    OverlayModule,
    MatDividerModule,
    A11yModule
  ],
  exports: [
    NgbTooltipModule
  ],
  providers: [
    [{ provide: APP_BASE_HREF, useValue: '/' }],
    AuthService,
    AuthGuardService,
    TwCoreUIUtilityService,
    UtilityService,
    CustomerService,
    UsersService,
    ProfileService,
    ToastrService,
    DashboardService,
    CommonService,
    CanDeactivateGuard,
    RouteGuardService,
    ReviewSubmitService,
    CreateQuoteService,
    SiteDetailsService,
    MyOrdersService,
    myQuotesService,
    TwCoreUIUtilityService,
    DatePipe,
    TitleCasePipe,
    CanNavigateGuardService,
    MapsService,
    BulkQuoteService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor, multi: true
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
