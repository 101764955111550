import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'tw-core-ui';
import { throwError as observableThrowError, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DuctAccessService {
  private httpClient: HttpClient;
  public uploadLabelAuthoringDataObj: any;
  private companyNameData: any;
  spinnerflag = false;
  private sendSpinnerFlag = new BehaviorSubject(this.spinnerflag);
  spinnerCurrentStatus = this.sendSpinnerFlag.asObservable();  
  public bareFibreDataObj: any;

  private apiPoint: String = window['environment'].apiPoint;
  private env: any = window['environment'];
  private apiUrl = {
    dropdownModel: 'duct-access-form.json',
    dataUrl: 'duct-payload.json',
    postUrl: 'forms/duct-webforms',
    companyNameUrl: 'users/customers',
    bareFibreValuesUrl: 'forms/bare-fibre-street-network'
  };

  constructor(private http: HttpClient,
    private authService: AuthService,
    private handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  public getDuctFormData() {
    this.sendSpinnerFlag.next(true);
    return new Promise((resolve, reject) => {
      const createuserUrl = this.env.aemEndPoint + this.apiUrl.dropdownModel;
      this.http.get<any>(createuserUrl)
        .subscribe(
          res => {
            this.sendSpinnerFlag.next(false);
            this.uploadLabelAuthoringDataObj = res;
            resolve(this.uploadLabelAuthoringDataObj);
          },
          err => {
            this.uploadLabelAuthoringDataObj = null;
            this.sendSpinnerFlag.next(false);
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }

  public postData(payload, fileToUpload) {
    this.sendSpinnerFlag.next(true);
    let formData: FormData = new FormData();
    for (let a of fileToUpload) {
      formData.append('file', a);
    }
    formData.append('payload', JSON.stringify(payload));
    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    return new Promise((resolve, reject) => {
      const url = this.env.apiPoint + this.apiUrl.postUrl;
      this.httpClient.post<any>(url, formData, httpOptions)
        .subscribe(res => {
          this.sendSpinnerFlag.next(false);
          resolve(res);
        }, err => {
          this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getCompanyNameData() {
    this.sendSpinnerFlag.next(true);
    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };

    const isDuctUser:boolean = true;
    return new Promise((resolve, reject) => {
      const getCompanyNameUrl = this.env.apiPoint + this.apiUrl.companyNameUrl + '?' + `ductUserFlag=${isDuctUser}`;
      this.http.get<any>(getCompanyNameUrl, httpOptions)
        .subscribe(
          res => {
            this.sendSpinnerFlag.next(false);
            if (res && res['data'] && res['data']['customerList']) {
              this.companyNameData = res['data']['customerList'];
            } else {
              this.companyNameData = [];
            }
            resolve(this.companyNameData);
          },
          err => {
            this.sendSpinnerFlag.next(false);
            this.companyNameData = null;
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }

  public getBareFibreData() {
    this.sendSpinnerFlag.next(true);
    return new Promise((resolve, reject) => {
      let httpOptions;
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': '*/*',
          'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
        })
      };
      const getBareFibreValuesUrl = this.env.apiPoint + this.apiUrl.bareFibreValuesUrl;
      this.http.get<any>(getBareFibreValuesUrl, httpOptions)
        .subscribe(
          res => {
            this.bareFibreDataObj = res;
            this.sendSpinnerFlag.next(false);
            let dataArray = [];
            this.bareFibreDataObj.data.bareFibreStreetNetwork.forEach(element => {
              dataArray.push(element);
            });
            resolve(dataArray);
          },
          err => {
            this.sendSpinnerFlag.next(false);
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }
}

