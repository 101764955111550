import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild, } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { CustomNameField } from 'src/app/shared/model/custom-core-ui.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { HomePageService } from 'src/app/shared/services/homePage.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { TwCoreUIUtilService } from 'tw-core-ui';
import { CustomTextField, CustomDropDown } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { CustomerService } from '../../customer-list/customer.service';
import { UserService } from '../user.service';
// import * as codeList from '../../../assets/labelAuthoring/user-codelist';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css'],
})
export class CreateUserComponent implements OnInit {

  public createUserForm: FormGroup;
  firstNameModel = new CustomNameField;
  userNameModel = new CustomNameField;
  lastNameModel = new CustomNameField;
  showRemoveAlert: Boolean = false;
  mobileNumberModel = new CustomNameField;
  showErrorMsg: boolean = false;
  showErrorMsgRole: boolean = false;
  showErrorMsgRoleFunction: boolean = false;
  statusDropDownModel: CustomDropDown = {
    isDisabled: true,
    accessibleHeaderId: 'Status Required Dropdown.Press Enter/return to expand the Dropdown and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  statuses = [
    "Active",
    "Inactive"
  ];
  customernameDropDownModel: CustomDropDown = {
    isDisabled: true,
    accessibleHeaderId: 'Customer Name Required Dropdown.Press Enter/return to expand the Dropdown and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  customers = [];
  applicationDropDownModel: CustomDropDown = {
    isDisabled: true,
    accessibleHeaderId: 'Application Required Dropdown.Press Enter/return to expand the Dropdown and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  applications = [];
  roleAccessDropDownModel: CustomDropDown = {
    isDisabled: true,
    accessibleHeaderId: 'Role Accesss Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  roleAccesses = [];
  functionList: any = [];
  isRoleAccessEnabled: boolean = true;
  canSubmit: boolean = true;
  customerDetailsList: any = [];
  userNameExistMessage = '';
  domainErrorMessage = '';
  isValidUser: boolean = false;
  @Output() moveTab = new EventEmitter<string>();
  checkedRoleFunctions: any = [];
  toAddCustomer: boolean = false;
  isCustomerNameValid: boolean = true;
  isRoleAccessValid: boolean = true;
  isRoleFunctionDisabled: boolean = true;
  isRoleFunctionValid: boolean = true;
  isResetFunctions: boolean = false;
  isRoleFunctionTouched: boolean = false;
  isRoleSaved: boolean = false;
  aemLabelAuthoring: any = '';
  disableSaveButton: boolean = false;
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  options: GlobalConfig;
  allRoleFunctionsMasterList: any[][] = [];
  createUserLabelModel: any;
  customerList: { cidn: string; app: string; role: string; function: string; businessName: string }[];
  linkedCIDNs: any;
  myLinkedCustomerListFromCaiman = [];
  @ViewChild('divToScroll') divToScroll: ElementRef;
  @ViewChild("tref") tref: ElementRef;
  cidn: string = '';
  infraCoCIDN = window['environment'].InfraCoCIDN;
  public isNBN: boolean = false;
  public isDuctRole: boolean = false;
  headerDropdownValue: string;
  isInfracoAdminUserMgmtRole: boolean = false;

  constructor(
    private route: Router,
    public fb: FormBuilder,
    private utility: UtilityService,
    private userservice: UserService,
    private util: TwCoreUIUtilService,
    private toastr: ToastrService,
    private homepageservice: HomePageService,
    private customerService: CustomerService,
    private commonservice: CommonService,
    private titleService: Title
  ) {
    this.createUserForm = this.fb.group({
      username: ['', [Validators.required, Validators.pattern(this.utility.getEmailRegEx())]],
      firstname: ['', [Validators.required, Validators.pattern(this.utility.getNameRegEx())]],
      lastname: ['', [Validators.required, Validators.pattern(this.utility.getNameRegEx())]],
      mobilenumber: ['', [Validators.pattern(this.utility.getNumberRegEx())]],
      status: [this.statuses[0], [Validators.required]],
      customername: ['', [Validators.required]],
      application: ['', [Validators.required]],
      roleAccess: ['', [Validators.required]],
    });
    this.options = this.toastr.toastrConfig;
    this.setuserNameModelFieldValue();
    this.setfirstNameModelFieldValue();
    this.setlastNameModelFieldValue();
    this.setmobileNumberModelFieldValue();
    this.initialDisabling();
    this.titleService.setTitle('InfraCo Customer Portal - Create a User');
  }

  ngOnInit(): void {
    const roles = this.commonservice.getroleDetails().customerList;
    const nbn = roles.some((obj) => obj.role.includes('NBN') || obj.function.includes('NBN'));
    if (nbn) {
      this.isNBN = true;
    } else {
      this.isNBN = false;
    }
    const isDuct = roles.some((obj) => obj.role.includes('Duct') || obj.function.includes('Duct'));
    if (isDuct) {
      this.isDuctRole = true;
    } else {
      this.isDuctRole = false;
    }
    const isInfracoAdmin = roles.some((obj) => obj.role.includes('Administrator') && obj.function.includes('User Management'));
    if (isInfracoAdmin) {
      this.isInfracoAdminUserMgmtRole = true;
    } else {
      this.isInfracoAdminUserMgmtRole = false;
    }
    this.getLabels();
    this.controlsObservables();
    this.getCommonServices();
    this.getRoles();
  }

  getCommonServices = () => {
    if (this.commonservice.getDefaultCustomerObject()) {
      const popUpData = JSON.parse(this.commonservice.getDefaultCustomerObject());
      this.myLinkedCustomerListFromCaiman = popUpData.allCustomerList;
    }
    this.cidn = localStorage.getItem('selectedHeaderCIDN');
    //this.commonservice.getSelectedCidn();
    this.commonservice.getDefaultCustomer().pipe().subscribe(resp => {
      if (resp) {
        this.createUserForm.get('username').reset();
        this.customerDetailsList = [];
        this.headerDropdownValue = resp;
        this.setCustomerDropdown(resp);
      }
    });
  }

  getRoles = () => {
    const response = this.homepageservice.getRoleFunctionMapping();
    response.forEach(item => {
      //set value of application
      if (this.applications.indexOf(item.application) === -1) {
        this.applications.push(item.application);
      }
      //set value of roleAccesses
      if (this.isNBN || this.isDuctRole) {
        this.roleAccesses = [];
      } else {
        if (this.roleAccesses.indexOf(item.role) === -1) {
          this.roleAccesses.push(item.role);
        }
      }
      //set value of role functions
      this.allRoleFunctionsMasterList[item.role] = this.allRoleFunctionsMasterList[item.role] || [];
      this.allRoleFunctionsMasterList[item.role].push(item.function);

    });
    this.createUserForm.get('application').setValue(this.applications[0]);
  }

  getLabels = () => {
    return new Promise((resolve, reject) => {
      this.userservice.getLabels().then((res) => {
        this.aemLabelAuthoring = res;
        resolve(true);
      }).catch(() => {
      });
    });
  }

  setuserNameModelFieldValue() {
    this.userNameModel.automationIdLabel = 'lbleusrnme';
    this.userNameModel.automationIdTextField = 'txtbxusrnme';
    this.userNameModel.placeholderCaption = 'Enter user name';
    this.userNameModel.id = 'username';
    this.userNameModel.headerTextAccessible = 'UserName required';
    this.userNameModel.isKeyDownDefaultValidation = false;
    this.userNameModel.name = 'username';
    this.userNameModel.formControlName = 'username';
    this.userNameModel.validationMessage = 'Please enter an email address in the format: email@example.com';
    this.userNameModel.requiredText = 'Please enter user name';
    this.userNameModel.maxlength = '64';
  }

  setfirstNameModelFieldValue() {
    this.firstNameModel.automationIdLabel = 'lblefrstnme';
    this.firstNameModel.automationIdTextField = 'txtbxfrstnme';
    this.firstNameModel.placeholderCaption = 'Enter first name';
    this.firstNameModel.id = 'firstname';
    this.firstNameModel.headerTextAccessible = 'FirstName required';
    this.firstNameModel.isKeyDownDefaultValidation = false;
    this.firstNameModel.name = 'firstname';
    this.firstNameModel.formControlName = 'firstname';
    this.firstNameModel.validationMessage = "Only letters, numbers and '&- can be used in the first name";
    this.firstNameModel.requiredText = 'Please enter first name';
    this.firstNameModel.maxlength = '64';
  }

  setlastNameModelFieldValue() {
    this.lastNameModel.automationIdLabel = 'lblelstnme';
    this.lastNameModel.automationIdTextField = 'txtbxlstnme';
    this.lastNameModel.placeholderCaption = 'Enter last name';
    this.lastNameModel.id = 'lastname';
    this.lastNameModel.headerTextAccessible = 'Lastname required';
    this.lastNameModel.isKeyDownDefaultValidation = false;
    this.lastNameModel.name = 'lastname';
    this.lastNameModel.formControlName = 'lastname';
    this.lastNameModel.validationMessage = "Only letters, numbers and '&- can be used in the last name";
    this.lastNameModel.requiredText = 'Please enter last name';
    this.lastNameModel.maxlength = '64';
  }

  setmobileNumberModelFieldValue() {
    this.mobileNumberModel.automationIdLabel = 'lblembleNmbr';
    this.mobileNumberModel.automationIdTextField = 'txtbxmbleNmbr';
    this.mobileNumberModel.placeholderCaption = 'Enter mobile number';
    this.mobileNumberModel.id = 'mobilenumber';
    this.mobileNumberModel.headerTextAccessible = 'MobileNumber';
    this.mobileNumberModel.isKeyDownDefaultValidation = false;
    this.mobileNumberModel.name = 'mobilenumber';
    this.mobileNumberModel.formControlName = 'mobilenumber';
    this.mobileNumberModel.validationMessage = "Please enter a valid phone number (20 digits)";
    this.mobileNumberModel.maxlength = '20';
  }

  initialDisabling = () => {
    this.isRoleFunctionDisabled = true;
  }

  controlsObservables = () => {
    this.createUserForm?.get('customername')?.valueChanges.subscribe(val => {
      if (this.createUserForm?.get('customername')?.valid && val) {
        this.isCustomerNameValid = true;
      }
    });
    this.createUserForm?.get('roleAccess')?.valueChanges.subscribe(val => {
      if (this.createUserForm?.get('roleAccess')?.valid) {
        this.isRoleAccessValid = true;
      }
    });
  }

  controlsEnabling = () => {
    this.createUserForm.controls['firstname'].enable();
    this.createUserForm.controls['lastname'].enable();
    this.createUserForm.controls['mobilenumber'].enable();
    this.customernameDropDownModel.isDisabled = false;
    this.roleAccessDropDownModel.isDisabled = false;
    this.isRoleFunctionDisabled = false;
  }

  resetControls = () => {
    this.createUserForm?.get('customername')?.reset();
    this.createUserForm?.get('roleAccess')?.reset();
    this.isResetFunctions = true;
    this.checkedRoleFunctions = [];
    this.functionList.forEach(element => {
      element.checked = false;
    });
    this.isCustomerNameValid = true;
    this.isRoleAccessValid = true;
    this.toAddCustomer = true;
    this.isRoleSaved = true;
    this.disableSaveButton = false;
  }


  checkUserIfEnabled = () => {
    if(!this.createUserForm) return;
    if (this.createUserForm.controls['username'].invalid) {
      this.initialDisabling();
      this.isValidUser = false;
      this.customernameDropDownModel.isDisabled = true;
      this.roleAccessDropDownModel.isDisabled = true;
      this.isRoleFunctionDisabled = true;
      this.toAddCustomer = true;
      this.createUserForm?.get('firstname')?.reset();
      this.createUserForm?.get('lastname')?.reset();
      this.createUserForm?.get('mobilenumber')?.reset();
      this.resetControls();
      return true
    }
  }

  checkUser() {
    if (this.createUserForm.value.username.match(this.utility.getEmailRegEx())) {
      //API CALL
      this.userservice.getUserData(this.createUserForm.value.username, this.cidn)
        .then(res => {
          if (res.status === 200) {
            this.userservice.setModifyUserData(this.createUserForm.value.username, '');
            this.showToasterMessage(this.aemLabelAuthoring.form.existingUser, '', this.options, 'error', 'toast-top-center');
            // navigate to modify user screen
            this.moveTab.emit('modifyuser');
          }
          else if (res.status === 404) {
            this.isValidUser = true;
            this.controlsEnabling();
            this.toAddCustomer = false;
            this.setCustomerDropdown(this.headerDropdownValue);
          }
          else if (res.status === 401) {
            this.showToasterMessage(this.aemLabelAuthoring.form.unauthorizedErrorMessage, '', this.options, 'error', 'toast-top-center');
            this.route.navigate(['/']);
          } else {
            this.showToasterMessage(res.message, '', this.options, 'error', 'toast-top-center');
          }
        })
        .catch((err) => {
          if (err.error && err.error['code'] === 404) {
            this.controlsEnabling();
            this.toAddCustomer = false;
          } else if (err.status === 401) {
            this.isValidUser = false;
            this.showToasterMessage(this.aemLabelAuthoring.form.unauthorizedErrorMessage, '', this.options, 'error', 'toast-top-center');
            this.route.navigate(['/']);
          } else {
            this.showToasterMessage(err.error['message'], '', this.options, 'error', 'toast-top-center');
          }
        });
    } else {
      const control = this.createUserForm.get('username');
      if (control?.invalid) {
        this.createUserForm.controls['username'].markAsTouched();
        return this.createUserForm.controls['username'].invalid;
      } else return this.createUserForm.controls['username'].valid;
    }
  }

  setCustomerDropdown = (selectedHeaderValue?: string) => {
    const getRolesFromCaiman = this.commonservice.getroleDetails().customerList;
    const custNames = [];
    this.customerService.getLinkedCIDNs().then(res => {
      this.linkedCIDNs = res.data.businessNameDetails;
      this.customers = [];
      //get latest CIDN
      if (selectedHeaderValue) {
        const findCIDNobject = this.searchCidn(this.linkedCIDNs, selectedHeaderValue)
        this.cidn = findCIDNobject?.cidn;
      }
      //check if my role is Infraco Admin,populate all linked cidn list
      if (getRolesFromCaiman.some(ele => ele.cidn === this.infraCoCIDN) && selectedHeaderValue !== 'NBNCo') {
        this.linkedCIDNs.forEach(item => {
          custNames.push(item.bussinessName);
        });
      } else {
        this.linkedCIDNs.forEach(item => {
          //matching Infraco cidn to show all customer list else show the selectd cidn customer only
          if (this.cidn === this.infraCoCIDN) {
            // this.customers.push(item.bussinessName)
            custNames.push(item.bussinessName);
          }
          else if (getRolesFromCaiman.some(ele => ele.cidn === item.cidn && ele.cidn !== this.infraCoCIDN && ele.role === 'Administrator' &&
            item.status === 'Active' && ele.function === 'User Management')) {
            getRolesFromCaiman.forEach((ele) => {
              custNames.push(item.bussinessName);
            });
          }
          else if (item.cidn === this.cidn) {
            custNames.push(item.bussinessName); //matching cidn for other company
          }
        });
      }
      this.customers = [...new Set(custNames)];

    }).catch((err) => {
    });
  }

  changeRoleDropdown = () => {
    this.isRoleFunctionValid = true;
    this.isRoleFunctionTouched = false
    this.checkedRoleFunctions = [];
    const selectedCustomer = this.createUserForm.get('customername').value;
    const selectedRoleAccess = this.createUserForm.get('roleAccess').value;
    //set function list based on selected role
    if (this.allRoleFunctionsMasterList[selectedRoleAccess]) {
      this.functionList = [];
      this.isResetFunctions = !this.isResetFunctions;
      this.allRoleFunctionsMasterList[selectedRoleAccess].forEach(role => {
        this.functionList.push({ name: role, checked: false })
      });
      if (this.isNBN) { //nbn users with infraco customer
        if (selectedCustomer.toUpperCase() == 'INFRACO') {
          if (selectedRoleAccess === 'NBN Read & Write') {
            let filteredArray = this.functionList.filter(e => (e.name == 'NBN DA Documents'));
            this.functionList = filteredArray;
          } else if (selectedRoleAccess === 'NBN Read Only') {
            let filteredArray = this.functionList.filter(e => (e.name == 'NBN Cabinets') || (e.name == 'NBN DA Documents'));
            this.functionList = filteredArray;
          }
        } else { //customer nbnco
          if (selectedRoleAccess === 'NBN Read Only') {
            let filteredArray = this.functionList.filter(e => (e.name == 'NBN Cabinets') || (e.name == 'NBN DA Documents'));
            this.functionList = filteredArray;
          }
        }
      } else if (this.isDuctRole) {
        if (selectedCustomer.toUpperCase() == 'INFRACO') {
          if (selectedRoleAccess === 'Duct Administrator') {
            let filteredArray = this.functionList.filter(e => (e.name == 'Duct User Management'));
            this.functionList = filteredArray;
          } else if (selectedRoleAccess === 'Duct Read & Write') {
            let filteredArray = this.functionList.filter(e => (e.name == 'Duct Access Form'));
            this.functionList = filteredArray;
          }
        } else { //customer nbnco
          if (selectedRoleAccess === 'Duct Read & Write') {
            let filteredArray = this.functionList.filter(e => (e.name == 'Duct Access Form'));
            this.functionList = filteredArray;
          }
        }
      }

      else {
        //for selected customer other than NBN, NBN option should not come and for customers other than InfraCo with Admin role, User Management function to be excluded
        if (selectedCustomer !== 'NBN CO') {
          let filteredArray = this.functionList.filter(e => e.name !== 'NBN Cabinet')
          this.functionList = filteredArray;
        }
        if (selectedCustomer.toUpperCase() !== 'INFRACO' && selectedRoleAccess === 'Administrator') {
          let filteredArray = this.functionList.filter(e => (e.name !== 'Customer Management')) //|| (e.name !== 'customermgmt')
          this.functionList = filteredArray;
        }
        if (selectedCustomer.toUpperCase() === 'INFRACO' && selectedRoleAccess === 'Read & Write') {
          let filteredArray = this.functionList.filter(e => (e.name !== 'Quote Request Management'))
          this.functionList = filteredArray;
        }
        if (selectedCustomer.toUpperCase() === 'INFRACO' && selectedRoleAccess === 'Read & Write') {
          let filteredArray = this.functionList.filter(e => (e.name !== 'Order Management'))
          this.functionList = filteredArray;
        }
      }
    }
  }

  changeCustomerDropdown = (e) => {

    this.createUserForm.get('roleAccess').reset();
    this.isResetFunctions = true;

    this.functionList = [];
    // if(this.checkedRoleFunctions.length) {
       this.isRoleFunctionValid = true;
       this.showErrorMsgRole = false;
    // } else {
    //   this.isRoleFunctionValid = false;
    // }
    this.checkedRoleFunctions = [];
 //
    if (this.isNBN) {
      if (e.toUpperCase() == 'INFRACO') {
        this.roleAccesses = ['NBN Administrator', 'NBN Read & Write', 'NBN Read Only'];
      } else if (this.headerDropdownValue === 'NBNCo') {
        this.roleAccesses = ['NBN Read Only'];
      } else {
        this.roleAccesses = ['NBN Administrator', 'NBN Read Only'];
      }
      if (this.customerDetailsList.length && ((this.customerDetailsList.some(list => list.customerName.toUpperCase() === 'INFRACO') && (e == 'NBNCo'))
        || (this.customerDetailsList.some(list => list.customerName === 'NBNCo') && (e.toUpperCase() == 'INFRACO')))) {
        this.showToasterMessage('Selected role is not applicable for the user domain. Please add a different role.', '', this.options, 'error', 'toast-middle-center');
        this.disableSaveButton = true;
      } else {
        this.disableSaveButton = false;
      }
    } else if (this.isDuctRole) {
      if (e.toUpperCase() == 'INFRACO') {
        this.roleAccesses = ['Duct Administrator', 'Duct Read & Write'];
      } else {
        this.roleAccesses = ['Duct Read & Write'];
      }
    }
  //  this.isRoleFunctionValid = false;
  }
  touchCustomerName() {
    this.showErrorMsg = true;
  }

  touchRoleAccess() {
    this.showErrorMsgRole = true;
  }

  touchRoleFunction() {
    this.isRoleFunctionTouched = true;
    this.isRoleFunctionValid = false;
  }
  checkFormValidation = () => {
    if (this.createUserForm.controls['username'].value && this.createUserForm.controls['firstname'].value && this.createUserForm.controls['lastname'].value) {
      // this.createUserForm.controls.customername.valid && this.createUserForm.controls.application.valid &&
      // this.createUserForm.controls.roleAccess.valid && this.checkedRoleFunctions.length > 0
      return false
    } else {
      return true
    }
  }

  saveCustomer = (): void => {
    if (this.createUserForm.controls.customername.invalid) {
      this.showErrorMsg = true;
    }
    if (this.createUserForm.controls.roleAccess.invalid) {
      this.showErrorMsgRole = true;
    }
    if (this.checkedRoleFunctions.length < 1) {
      this.isRoleFunctionValid = false;
    }

    this.isRoleFunctionTouched = false;
    if (this.createUserForm.valid && this.checkedRoleFunctions.length) {
      let isCustomerError = false;
      //role functions matching
      this.checkedRoleFunctions.forEach(role => {
        if (this.customerDetailsList.some(list => list.functionName === role && list.role_name === this.createUserForm.value.roleAccess
          && list.customerName === this.createUserForm.value.customername)) {
          this.showToasterMessage('Role already added. Please add a different role', '', this.options, 'error', 'toast-middle-center');
          this.divToScroll.nativeElement.scrollTop = 0;
          isCustomerError = true;
          return false;
        }
      });
      if (!isCustomerError) {
        //fetch the cidn of the selected customer
        let findCIDNobject;
        findCIDNobject = this.searchCidn(this.myLinkedCustomerListFromCaiman, this.createUserForm.value.customername)
        if (!findCIDNobject?.cidn) {
          findCIDNobject = this.searchCidn(this.linkedCIDNs, this.createUserForm.value.customername)
        }
        this.checkedRoleFunctions.forEach(role => {
          this.customerDetailsList.push({
            cidn: findCIDNobject?.cidn,
            customerName: this.createUserForm.controls['customername'].value,
            appName: this.createUserForm.controls['application'].value,
            role_name: this.createUserForm.controls['roleAccess'].value,
            functionName: role
          })
        });
        this.resetControls();
        this.toAddCustomer = true;
        this.isRoleFunctionValid = true;
        this.isRoleSaved = true;
      }

    } else {
      if (!this.createUserForm.controls['customername'].value) {
        this.isCustomerNameValid = false;
      }
      if (!this.createUserForm.controls['roleAccess'].value) {
        this.isRoleAccessValid = false;
      }
      if (!this.checkedRoleFunctions.length) {
        this.isRoleFunctionValid = false;
      }
    }
  }

  addRoleAccess = () => {
    this.isRoleAccessEnabled = !this.isRoleAccessEnabled;
    this.toAddCustomer = false;
    this.functionList = [];
    setTimeout(() => this.tref?.nativeElement.focus(), 1);
    this.showErrorMsg = false;
    this.showErrorMsgRole = false;
    this.isRoleFunctionValid = true;
    this.isRoleFunctionTouched = false;
  }

  cancel = () => {
    if (this.route.url.indexOf('/create-user') > -1) {
      this.route.navigate(['/admin','createUser']);
    } else {
      this.route.navigate(['/admin','myUser']);
      this.moveTab.emit('user');
    }
  }

  shareCheckedList = (item: any[]) => {
    if(item.length>0) {
      this.checkedRoleFunctions = item;
      this.isRoleFunctionValid = true;
    }
  }

  shareIndividualCheckedList = (item: {}) => {
  }

  removeCustomerDetails(index) {
    this.showRemoveAlert = true;
    if (index > -1) { // only splice array when item is found
      this.customerDetailsList.splice(index, 1); // 2nd parameter means remove one item only
    }
  }

  checkSubmitBtnDisabled = () => {
    if (this.isValidUser && this.createUserForm.controls['username'].valid &&
      this.createUserForm.controls['firstname'].valid && this.createUserForm.controls['lastname'].valid &&
      this.createUserForm.controls['mobilenumber'].valid && this.customerDetailsList.length && this.isRoleSaved && !this.disableSaveButton) {
      return false
    } else return true
  }

  submitUserForm = () => {
    this.customerDetailsList.forEach(row => {
      row.functionName = row.functionName.toString();
    });
    this.userservice.createUser(this.createUserForm.value, this.customerDetailsList).then(res => {
      this.showToasterMessage(this.aemLabelAuthoring.form.createUserSuccessMessage
        , '', this.options, 'success', 'toast-top-center');
      this.cancel();
    }).catch((err) => {
      if (err.error.status === 409) {
        this.userNameExistMessage = this.aemLabelAuthoring.form.userNameMessage;
      } else if (err.error.status === 401 && err.error.code === 401 && err.error.message.includes('domain name')) {
        this.domainErrorMessage = this.aemLabelAuthoring.form.domainErrorMessage;
        this.showToasterMessage(this.aemLabelAuthoring.form.domainErrorMessage
          , '', this.options, 'error', 'toast-middle-center');
      } else {
        this.showToasterMessage(this.aemLabelAuthoring.form.createUserFailedMessage
          , '', this.options, 'error', 'toast-middle-center');
        this.util.bringFocusToElement(window.event, 'toast-close-button', false);
        setTimeout(() => (document.getElementById('toast-container').
          getElementsByClassName('toast-close-button')[0] as HTMLElement).focus(), 0);
      }
    });
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string, divPosition: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = divPosition;
    this.options.disableTimeOut = false;
    this.options.autoDismiss = false;
    this.options.timeOut = 5000;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = true;
    this.toastr.show(
      message,
      header === '' ? null : header,
      options,
      this.options.iconClasses[type]
    );
    document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
  }

  searchCidn(myArray, nameKey) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].bussinessName === nameKey) {
        return myArray[i];
      }
    }
  }

  buildRoleBasedOnLabelsSaved(value) {
    if (value === 'InfraCo Customer Portal') {
      return 'infraco';
    }
    if (value === 'Administrator') {
      return 'admin';
    }
    if (value === 'Read & Write') {
      return 'readwrite';
    }
    if (value === 'Read Only') {
      return 'readonly';
    }
    if (value === 'NBN Read & Write') {
      return 'nbnreadwrite';
    }
    if (value === 'NBN Read Only') {
      return 'nbnread';
    }
    if (value === 'NBN Administrator') {
      return 'nbnadmin';
    }
    if (value === 'Customer Management') {
      return 'customermgmt';
    }
    if (value === 'NBN Cabinets') {
      return 'nbncabinet';
    }
    if (value === 'User Management') {
      return 'usermgmt';
    }
    if (value === 'NBN DA Documents') {
      return 'nbndocument';
    }
    if (value === 'NBN User Management') {
      return 'nbnusermgmt';
    }
    if (value === 'NBN Cabinet Access Management') {
      return 'nbncabinetmgmt';
    }
    if (value === 'Duct Administrator') {
      return 'ductadmin';
    }
    if (value === 'Duct Read & Write') {
      return 'ductreadwrite';
    }
    if (value === 'Duct User Management') {
      return 'ductusermgmt';
    }
    if (value === 'Duct Access Form') {
      return 'ductaccessform';
    }
    if (value === 'Quote Request Management') {
      return 'quotemgmt';
    }
    if (value === 'Order Management') {
      return 'ordermgmt';
    }
    if (value === 'Service Management') {
      return 'servicemgmt';
    }
    if (value === 'Document Management') {
      return 'documentmgmt';
    }
  }
  onUsernameChange(event) {
    this.showErrorMsg = false;
    this.showErrorMsgRole = false;
    this.isRoleFunctionTouched = false;
  }
}
