
import { throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'tw-core-ui';
// import { GridData } from '../../../shared/models/common-model';
// import { CreateUserData } from './users.model';

export interface GridHeaderInterface {
  headers: any;
}

export interface GridHeaderCabinetInterface {
  headers: any;
}

export interface GridDataInterface {
  data: any;
}

@Injectable()
export class UsersService {
  spinnerflag = false;
  // private sendSpinnerFlag = new BehaviorSubject(this.spinnerflag);
  // spinnerCurrentStatus = this.sendSpinnerFlag.asObservable();
  private env: any = window['environment'];
  adminRoles = [];
  validUserRoles = [];
  private aemDefaultUri = {
    gridHeader: 'myuser-grid-header',
    gridData: 'users',
    gridDataAll: 'users/all',
    newgridData: 'portal-users',
    users: 'users/portal-users',
    gridLabels: 'myuser',
    createUser: 'create-user',
    getUser: 'users',
    phoneUpdate: 'users/profile/',
    deletePreference: 'customer-notification-preferences',
    manageCabinets: 'manage-cabinets',
    cabinetsAccess: 'cabinets-access.json',
    cabinets: 'document-management/cabinets',
    addCabinetsAccess: 'document-management/cabinets/cabinet-access',
    cabinetHeader: 'ticketing-myuser-grid-header',
    mockUserList: 'mock/user-list.json'
  };

  public gridHeaderDataObj: any = [];
  public gridHeaderDataObj1: any = [];
  public gridDataObj: any = [];
  public gridDataObj1: any = [];
  public gridLabelAuthoringDataObj: any;
  public createUserLabelAuthoringDataObj: any;
  payLoad: any;
  getPayload: any;
  createUserRoleObj: any = [];
  public userDataObj: any = [];
  public createuserFormData: any;
  public existingUserData: any;
  sendCidn: any;
  public gridLabelAuthoringDataCabinetObj: any;
  public userDetailsData: any;
  public cabinatesAccessLabelAuthoringDataObj: any;
  public cabinetListObj: any;
  constructor(private http: HttpClient, private authService: AuthService) { }

  public getUserListModel() {
    return new Promise((resolve, reject) => {
      if (!this.gridLabelAuthoringDataObj) {
        const worklistUrl = this.env.aemEndPoint + this.aemDefaultUri.gridLabels;
        this.http.get<any>(worklistUrl)
          .subscribe(
            res => {
              this.gridLabelAuthoringDataObj = res.data.content;
              resolve(this.gridLabelAuthoringDataObj);
            },
            err => {
              this.gridLabelAuthoringDataObj = null;
              reject(err);
              return observableThrowError(err || 'Server error');
            }
          );
      } else {
        resolve(this.gridLabelAuthoringDataObj);
      }
    });
  }

  public getCabinetGridHeader() {
    return new Promise((resolve, reject) => {
      this.http.get<GridHeaderCabinetInterface>(this.env.aemEndPoint + this.aemDefaultUri.cabinetHeader)
        .subscribe(res => {
          this.gridHeaderDataObj1 = res;
          resolve(true);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getGridHeader() {
    return new Promise((resolve, reject) => {
      this.http.get<GridHeaderInterface>(this.env.aemEndPoint + this.aemDefaultUri.gridHeader)
        .subscribe(res => {
          this.gridHeaderDataObj = res;
          resolve(true);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getGridData(params, cidn) {
    this.sendCidn = cidn;
    return new Promise<any>((resolve, reject) => {
      const tokenValue = this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : false;
      let httpOptions;
      if (tokenValue) {
        httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization': tokenValue
          })
        };
      } else {
        httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': '*/*'
          })
        };
      }
      // uncomment to run in local
    //  const url = this.env.aemEndPoint + this.aemDefaultUri.mockUserList;
      const url = this.env.apiPoint + this.aemDefaultUri.gridData + "?cidn=" + cidn;
      this.http.get<any>(url, httpOptions)
        .subscribe(res => {
          this.gridDataObj = res['data']['userDetailsList'];
          resolve(res);
        }, err => {
          this.gridDataObj = [];
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getGridDataForAll(params) {
    return new Promise<any>((resolve, reject) => {
      const tokenValue = this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : false;
      let httpOptions;
      if (tokenValue) {
        httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization': tokenValue
          })
        };
      } else {
        httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': '*/*'
          })
        };
      }
      const url = this.env.apiPoint + this.aemDefaultUri.gridDataAll;
      this.http.get<any>(url, httpOptions)
        .subscribe(res => {
          this.gridDataObj = res['data']['userDetailsList'];
          resolve(res);
        }, err => {
          this.gridDataObj = [];
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getManageCabinetsModel(infracoAdmin) {
    // this.sendSpinnerFlag.next(true);
    return new Promise((resolve, reject) => {
      // if (!this.gridLabelAuthoringDataCabinetObj) {
      const worklistUrl = this.env.aemEndPoint + this.aemDefaultUri.manageCabinets;
      this.http.get<any>(worklistUrl)
        .subscribe(
          res => {
            if (infracoAdmin) {
              // this.sendSpinnerFlag.next(false);
            }
            this.gridLabelAuthoringDataCabinetObj = res.data.content;
            resolve(this.gridLabelAuthoringDataCabinetObj);
          },
          err => {
            if (infracoAdmin) {
              // this.sendSpinnerFlag.next(false);
            }
            this.gridLabelAuthoringDataCabinetObj = null;
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }

  public getCabinetsGridData(params) {
    const queryParams = [];
    if (params.role) {
      queryParams.push(`rolesforFilter=${params.role}`);
    }
    if (params.userName) {
      queryParams.push(`searchKeyword=${params.userName}`);
    }
    if (params.cabinetId) {
      queryParams.push(`cabinetId_Flag=${params.cabinetId}`);
    }
    if (params.cidn) {
      queryParams.push(`cidn=${params.cidn}`);
    }
    const queryStr = queryParams.join('&');
    let url = this.env.apiPoint + this.aemDefaultUri.getUser
    if (queryStr) {
      url = url + '?' + queryStr;
    }
    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    return new Promise((resolve, reject) => {
      this.http.get<GridDataInterface>(url, httpOptions)
        .subscribe(res => {
          // this.sendSpinnerFlag.next(false);
          if (res && res['data'] && res['data']['userDetailsList']) {
            this.gridDataObj1 = res['data']['userDetailsList'];
          } else {
            this.gridDataObj1 = [];
          }
          resolve(this.gridDataObj1);
        }, err => {
          // this.sendSpinnerFlag.next(false);
          this.gridDataObj1 = [];
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });

  }

  setUserDetailsData(data: any) {
    this.userDetailsData = data;
  }

  public getCabinetsAccessModel() {
    // this.sendSpinnerFlag.next(true);
    return new Promise((resolve, reject) => {
      const createuserUrl = this.env.aemEndPoint + this.aemDefaultUri.cabinetsAccess;
      this.http.get<any>(createuserUrl)
        .subscribe(
          res => {
            this.cabinatesAccessLabelAuthoringDataObj = res;
            resolve(this.cabinatesAccessLabelAuthoringDataObj);
          },
          err => {
            this.cabinatesAccessLabelAuthoringDataObj = null;
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }

  getCabinetsByUserName(userName) {
    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    // this.sendSpinnerFlag.next(true);
    return new Promise((resolve, reject) => {
      this.http.get(`${this.env.apiPoint}${this.aemDefaultUri.cabinets}?userName=${userName}`, httpOptions)
        .subscribe((res: any) => {
          let mRes = []
          try {
            mRes = res.data.cabinets;
          } catch (err) {
            mRes = []
          }
          // this.sendSpinnerFlag.next(false);
          resolve(mRes);
        }, err => {
          // this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getCabinetsList() {
    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    return new Promise((resolve, reject) => {
      if (!this.cabinetListObj) {
        const url = this.env.apiPoint + this.aemDefaultUri.cabinets;
        this.http.get<any>(url, httpOptions)
          /*   .takeUntil(componentDestroyed(this)) */
          .subscribe(
            res => {
              // this.sendSpinnerFlag.next(false);
              this.cabinetListObj = res['data']['cabinets'];
              resolve(this.cabinetListObj);
            },
            err => {
              this.cabinetListObj = null;
              // this.sendSpinnerFlag.next(false);
              reject(err);
              return observableThrowError(err || 'Server error');
            }
          );
      } else {
        // this.sendSpinnerFlag.next(false);
        resolve(this.cabinetListObj);
      }
    });
  }


  addCabinetAccess(userDetails: any, cabinetList) {
    // this.sendSpinnerFlag.next(true);
    const modCabList = cabinetList.map((ele) => {
      return { cabinetId: ele.cabinetId, role: ele.role }
    });
    const roleList = userDetails.roles.map(e => e.role);
    const payload: Object = {
      request: {
        cidn: userDetails.cidn,
        userId: userDetails.username,
        userRole: [...new Set(roleList)],
        data: modCabList
      }
    }
    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    return new Promise((resolve, reject) => {
      this.http.put(this.env.apiPoint + this.aemDefaultUri.addCabinetsAccess, payload, httpOptions)
        .subscribe(res => {
          res = res['data'];
          // this.sendSpinnerFlag.next(false);
          resolve(res);
        }, err => {
          // this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

}
