<div class="container-fluid manage-cabinet-page-container" *ngIf="aemLabelAuthoring">
  <h1 class="page-heading" id="access">{{ aemLabelAuthoring.pageHeader.pageHeaderLabel }}</h1>
  <nav aria-label="breadcrumb" #divToScroll>
    <ol class="breadcrumb">
      <li role="link" class="breadcrumb-item" (keypress)="navigate('/home')" (click)="navigate('/home')" tabindex="0">Home
      </li>
      <i class="icon-icon-ui-right-arrow breadcrumb-icon"></i>
      <li class="breadcrumb-item mid" role="link" tabindex="0" (keypress)="navigate('/manage-cabinet')" (click)="navigate('/manage-cabinet')">Manage cabinets</li>
      <i class="icon-icon-ui-right-arrow breadcrumb-icon"></i>
      <li role="link" class="breadcrumb-item active" aria-current="page">Manage cabinets access</li>
    </ol>
  </nav>
  <h2 class="page-details-title">
    {{ aemLabelAuthoring.pageDetails.pageDetailsTitle }}
  </h2>
  <hr>
  <div class="row">
    <div class="col-3">
      <label class="user-details-label-top">
        {{ aemLabelAuthoring.pageDetails.pageDetailsLabelOne }}
      </label>
      <span class="user-details-label-value title-bold">{{userDetails?.lastName +', ' +
        userDetails?.firstName}}</span>
    </div>
    <div class="col-3">
      <label class="user-details-label-top">
        {{ aemLabelAuthoring.pageDetails.pageDetailsLabelTwo }}
      </label>
      <span class="user-details-label-value title-bold">{{ userDetails?.phoneNumber }}</span>
    </div>
    <div class="col-3">
      <label class="user-details-label-top">
        {{ aemLabelAuthoring.pageDetails.pageDetailsLabelThree }}
      </label>
      <span class="user-details-label-value title-bold">{{ userDetails?.role_name }}</span>
    </div>
  </div>
  <div class="row" *ngIf="selectedCabinetsData?.length > 0 && !isAddCabinetAccess">
    <div class="col-12 " style="overflow-x: auto;">
      <table class="table">
        <tr>
          <th class="title-bold" *ngFor="let col of tableCol">
            {{col}}
          </th>
          <th></th>
        </tr>
        <tr class="tableRow" *ngFor="let row of selectedCabinetsData; let i = index">
          <td class="tableColumn"> {{row?.cabinetId}} </td>
          <td> {{row?.cabinetName}} </td>
          <td *ngIf="isInfracoUser"> {{row?.roleName}} </td>
          <td tabindex="0" (keypress)="removeRow(row.cabinetId)">
            <button type="button" tabindex="-1" attr.aria-label="Remove {{row?.cabinetId}}" class="secondary-btn xl-btn p-0 m-0" (click)="removeRow(row.cabinetId)">
              <img src="../../assets/images/icon-delete.svg" alt="delete" />
            </button>
            <label role="alert" *ngIf="showRmv" style="position:absolute;left:-9999px">Removed cabinet Successfully</label>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="add-cabinet-access-container" *ngIf="isAddCabinetAccess">
    <form [formGroup]="createAccessForm" id="createCabinetAccessId">
      <div class="form-group">
        <div class="row">
          <div class="dropdown-contact-options col-xs-12 col-sm-5 col-md-4 col-lg-4">
            <label class="add-access-label required-field" automationType="label" automationId="lblrole"
              id="cabinetsAccessId" attr.aria-label="{{aemLabelAuthoring.form.accessIdModel}}">
              {{ aemLabelAuthoring.form.accessIdModel }}
            </label>
            <app-cui-searchable-dropdown name="ID" [formControlName]="'ID'" [model]="statusModel"
              [labelName]="aemLabelAuthoring.form.accessIdModel"
              [placeholder]="aemLabelAuthoring.form.accessIdPlaceholder" [isRequired]=true [items]=accessItemsList
              automationType="dropdown" automationId="drpdwnroleType" [noAutoComplete]=false id="statusDropdown"
              class="user-dropDown"
              [ngClass]="{'invalid-form-control': (createAccessForm.controls.ID.invalid && createAccessForm.controls.ID.touched )}"
              required>
            </app-cui-searchable-dropdown>
            <div class="row ml-0" aria-live="assertive">
              <span role="alert" *ngIf="createAccessForm.controls.ID.errors?.duplicate" class="help-block errorMsg">
                <ng-container *ngIf="createAccessForm.controls.ID.errors?.duplicate">
                  {{aemLabelAuthoring.form.accessIdDuplicateMessage}}
                </ng-container>
              </span>
            </div>
          </div>
          <div class="dropdown-contact-options col-xs-12 col-sm-5 col-md-4 col-lg-4" *ngIf="isInfracoUser">
            <label class="add-access-label required-field" automationType="label" automationId="lblrole"
              id="cabinetsRoleId" attr.aria-label="Role functions">
              Role functions
            </label>
            <app-cui-searchable-dropdown name="role" [formControlName]="'role'" [model]="roleModel"
              [labelName]="'Role functions'" [placeholder]="'Select role function'" [isRequired]=true
              [items]=roleItemsList automationType="dropdown" automationId="drpdwnroleType" [noAutoComplete]=false
              class="user-dropDown"
              [ngClass]="{'invalid-form-control': (createAccessForm.controls.role.invalid && createAccessForm.controls.role.touched )}"
              required>
            </app-cui-searchable-dropdown>
          </div>
        </div>

        <div class="row">
          <div class="button-container save-cncl-access-btn-container">
            <button type="button" tabindex="0" class="secondary-btn xl-btn app-cancel-btn" (click)="onClickCancelCabinetAccess()"><i
                class="icon-icon-ui-cross"></i>{{aemLabelAuthoring.form.cancel}}
            </button>
            <button type="button" [disabled]="createAccessForm?.invalid" class="primary-btn xl-btn app-save-btn mr-3"
              (click)="onClickSaveCabinetAccess()">{{aemLabelAuthoring.form.save}}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="row">
    <div class="col-12 mt-3 mb-2 wrapper">
      <div class="addRole">
        <button type="button" class="app-secondary-btn" (click)="onClickAddCabinetAccess()" style="display: flex;">
          <i class="icon-icon-ui-plus icon-type2-cls"></i>
          {{aemLabelAuthoring.form.addCabinateBtnLabel}}
        </button>
      </div>
      <div class="mt-3 save-cncl-access-btn-container">
        <button tabindex="0" type="button" class="secondary-btn sm-btn app-cancel-btn" (click)="navigate('/manage-cabinet')"><i
            class="icon-icon-ui-cross"></i>{{aemLabelAuthoring.form.cancel}}
        </button>
        <button   tabindex="0" type="button" class="primary-btn sm-btn app-save-btn mr-3" (click)="onClickSubmit()"
          [disabled]="!isCabinetsChange">{{aemLabelAuthoring.form.submit}}</button>
      </div>
    </div>
  </div>
</div>
