import { Component, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild, Inject, ElementRef, HostListener } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CreateQuoteService } from '../../../create-quote.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { TitleCasePipe } from '@angular/common';
import { SiteDetailsService } from 'src/app/quote-management/create-new-quote/site-details/site-details.service';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.less', '../../site-details.component.less'],
  providers: [TitleCasePipe]
})
export class SearchFormComponent implements OnInit {
  @Input() aemLabelAuthoring;
  @Output() onSearchActivateZend = new EventEmitter<any>();
  @Output() onSearchA2ctivateZend = new EventEmitter<any>();
  @Output() onSearchZ2ctivateZend = new EventEmitter<any>();
  @Output() onSearchDetails = new EventEmitter<any>();
  @Output() onSearchCheckBoxPathSelection = new EventEmitter<any>();
  @Output() onSelectRefForm = new EventEmitter<any>();
  @Output() onSelectLatLngForm = new EventEmitter<any>();
  @Output() patch2CheckedInfo = new EventEmitter<any>();




  @Input() formConfig;
  @ViewChild('trigger', { static: false }) primarySelect: MatAutocompleteTrigger;

  @ViewChild('a2sameAsA1EndAddress') a2sameAsA1EndAddress: ElementRef;
  @ViewChild('a2sameAsZ1EndAddress') a2sameAsZ1EndAddress: ElementRef;
  @ViewChild('z2sameAsA1EndAddress') z2sameAsA1EndAddress: ElementRef;
  @ViewChild('z2sameAsZ1EndAddress') z2sameAsZ1EndAddress: ElementRef;
  pattern = /^[a-zA-Z0-9!@#$%^&*()\-\_=+:'",.?|{}\n\[\]\/\`\~\\ ]*$/;
  searchForm = new FormGroup({
    primaryAutoComplete: new FormControl('', [Validators.required]),
    additionalAddressInfo: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.pattern)]),
    definedPropertyLocation: new FormControl('Yes'),
    refresh: new FormControl(false),
    a2sameAsA1EndAddress: new FormControl(false),
    a2sameAsZ1EndAddress: new FormControl(false),
    z2sameAsA1EndAddress: new FormControl(false),
    z2sameAsZ1EndAddress: new FormControl(false),
    aEndpreDefinedNodeName: new FormControl(''),
    zEndpreDefinedNodeName: new FormControl(''),
    a2EndpreDefinedNodeName: new FormControl(''),
    z2EndpreDefinedNodeName: new FormControl(''),

  });
  searchResults: any[];
  address: any;
  subscription: Subscription;
  constructor(
    private createQuoteService: CreateQuoteService,
    private siteDetailsService: SiteDetailsService,
    private titleCasePipe: TitleCasePipe,
    @Inject(DOCUMENT) document: Document
  ) { }

  createQuotePayload; searchFormAEndAdress; searchFormZEndAdress; latLongFormAEndAdress; referenceFormAEndAdress; subscription2;
  ngOnInit(): void {
    // document.getElementById('SearchBox').focus();
    // this.searchForm.patchValue();
    let productDetailsFormValue = this.createQuoteService.productDetailsFormValue;
    let formData = productDetailsFormValue[this.formConfig.formName];
    // if (formData.siteType || formData.searchMethod){
    this.formConfig.formData = formData;
    // }


    this.searchForm.patchValue({
      "aEndpreDefinedNodeName": formData.aEndpreDefinedNodeName || productDetailsFormValue?.aEndpreDefinedNodeName,
      "zEndpreDefinedNodeName": formData.zEndpreDefinedNodeName || productDetailsFormValue?.zEndpreDefinedNodeName,
      "a2EndpreDefinedNodeName": formData.a2EndpreDefinedNodeName || productDetailsFormValue?.a2EndpreDefinedNodeName,
      "z2EndpreDefinedNodeName": formData.z2EndpreDefinedNodeName || productDetailsFormValue?.z2EndpreDefinedNodeName,
    });


    if (this.formConfig.formData?.selectedAddress && this.formConfig.formData?.searchMethod == 'search') {
      this.primaryAutoComplete = this.formConfig.formData?.selectedAddress;

      // emit search value when draft flow
      this.onSearchDetails.emit(this.primaryAutoComplete);

      this.searchForm.patchValue(this.formConfig.formData)
      this.displayForm = true;


      this.address = {
        primaryAddress: this.primaryAutoComplete,
        adborId: this.formConfig.formData?.adborId,
        latitude: this.formConfig.formData?.latitude,
        longitude: this.formConfig.formData?.longitude,
        nbnLocId: this.formConfig.formData?.nbnLocId,
        nodeCode: this.formConfig.formData?.nodeCode
      }
      if (this.formConfig.formName == 'aEndAdress') {
      //   this.searchForm.patchValue({ refresh: true }, { emitEvent: true });
        this.onSearchActivateZend.emit(true);
      }
      // this.onSearchActivateZend.emit(true);
      setTimeout(() => {
        this.searchForm.patchValue({
          primaryAutoComplete: this.primaryAutoComplete
        })
        this.setData();
        if (this.formConfig.formName == 'zEndAdress') {
          this.searchForm.patchValue({ refresh: true }, { emitEvent: true });
          this.onSearchA2ctivateZend.emit(true);
        }
        if (this.formConfig.formName == 'a2EndAdress') {
          this.searchForm.patchValue({ refresh: true }, { emitEvent: true });

          if (productDetailsFormValue.sameAsAEndAddress == "Yes") {
            this.searchForm.patchValue({
              "a2sameAsA1EndAddress": true,
              "a2sameAsZ1EndAddress": false,

            })
            this.onCheckChange(this.a2sameAsA1EndAddress.nativeElement, 'sameAsPath1AEndAddressDetails', 'sameAsPath1A2EndAddressDetails')
          }

          if (productDetailsFormValue.sameAsZEndAddress == "Yes") {
            this.searchForm.patchValue({
              "a2sameAsA1EndAddress": false,
              "a2sameAsZ1EndAddress": true
            })
            this.onCheckChange(this.a2sameAsZ1EndAddress.nativeElement, 'sameAsPath1ZEndAddressDetails', 'sameAsPath1A2EndAddressDetails')
          }

          this.onSearchZ2ctivateZend.emit(true);
        }

        if (this.formConfig.formName == 'z2EndAdress') {
          this.searchForm.patchValue({ refresh: true }, { emitEvent: true });

          if (productDetailsFormValue.path2Z2SameAsAEndAddress == "Yes") {
            this.searchForm.patchValue({
              "z2sameAsA1EndAddress": true,
              "z2sameAsZ1EndAddress": false
            })
            this.onCheckChange(this.z2sameAsA1EndAddress.nativeElement, 'sameAsPath1AEndAddressDetails', 'sameAsPath1Z2EndAddressDetails')
          }

          if (productDetailsFormValue.path2Z2SameAsZEndAddress == "Yes") {
            this.searchForm.patchValue({
              "z2sameAsA1EndAddress": false,
              "z2sameAsZ1EndAddress": true
            })
            this.onCheckChange(this.z2sameAsZ1EndAddress.nativeElement, 'sameAsPath1ZEndAddressDetails', 'sameAsPath1Z2EndAddressDetails')
          }
        }
      }, 20);
    }
    this.subscription = this.searchForm.valueChanges.subscribe(value => {
      setTimeout(() => {
        this.setData();
      }, 1);
    })

  }

  setData() {

    let searchFormValue = this.searchForm.value;
    let isValid = Boolean(this.searchForm.valid && this.displayForm);
    searchFormValue.valid = isValid
    searchFormValue.formName = this.formConfig.formName;
    searchFormValue.searchMethod = "search";
    searchFormValue.address = this.address;
    this.siteDetailsService.searchFormChange(searchFormValue);

  }

  isSearchDisabled() {
    if (this.formConfig.formName == 'a2EndAdress') {
      return Boolean(this.searchForm.get("a2sameAsA1EndAddress").value || this.searchForm.get("a2sameAsZ1EndAddress").value);
    } else if (this.formConfig.formName == 'z2EndAdress') {
      return Boolean(this.searchForm.get("z2sameAsA1EndAddress").value || this.searchForm.get("z2sameAsZ1EndAddress").value)
    }
  }

  toggleAccordian(e, i) {
    const element = document.querySelector("#button-" + i);
    element.classList.toggle('active');
    const panel: HTMLElement = document.querySelector("#panel-" + i);
    if (panel.style.height) {
      panel.style.height = null;
    } else {
      panel.style.height = '100%';
    }
  }

  resetForm() {

    if (this.formConfig.formName == 'aEndAdress') {
      this.searchForm.reset();
      this.setAll(this.address, '');
      this.searchForm.controls['definedPropertyLocation'].setValue("Yes");
      this.onSearchActivateZend.emit(false);
      this.formConfig.markers = [];
      this.formConfig.mapZoom = 15;
      this.formConfig.mapCenter = {
        lat: -37.8098714,
        lng: 144.9698015
      }
    }

    if (this.formConfig.formName == 'zEndAdress') {
      this.searchForm.reset();
      this.setAll(this.address, '');
      this.searchForm.controls['definedPropertyLocation'].setValue("Yes");
      this.onSearchA2ctivateZend.emit(false);
      this.formConfig.markers = [];
      this.formConfig.mapZoom = 15;
      this.formConfig.mapCenter = {
        lat: -37.8098714,
        lng: 144.9698015
      }

    }

    if (this.formConfig.formName == 'a2EndAdress') {
      this.searchForm.reset();
      this.setAll(this.address, '');
      this.searchForm.controls['definedPropertyLocation'].setValue("Yes");
      this.onSearchZ2ctivateZend.emit(false);
      this.formConfig.markers = [];
      this.formConfig.mapZoom = 15;
      this.formConfig.mapCenter = {
        lat: -37.8098714,
        lng: 144.9698015
      }
    }



  }

  panelOpenState = false;
  options = [];
  autoCompletePrimary(e) {
    if (e && (e.ctrlKey || e.key == "Control")) {
      return;
    }

    this.displayForm = false;
    this.setData();
    this.resetForm();
    let value = e.target.value;
    if (value && value.length >= 3) {
      this.autocompleteAddressSearch(value);
    }
  }

  primaryAutoCompleteOptions = []; showAddressContainer = false;
  autocompleteAddressSearch(params?: any) {
    this.searchUnstructuredAddressesCompleteOptions = [];
    const param = params || null;
    return new Promise((resolve, reject) => {
      this.createQuoteService.autocompleteAddressSearch(param).then((res) => {
        res.data.results.map(obj => obj.primaryAddress = this.titleCasePipe.transform(obj.primaryAddress));
        this.primaryAutoCompleteOptions = res.data.results;
        this.showAddressContainer = true;
        resolve(res.data.results);
      }).catch((err) => {
        this.showAddressContainer = true;
        reject(err);
      });
    });
  }

  // && !option.numSubaddresses
  searchUnstructuredAddressesSelect(option, type) {

    this.address = option;
    if (type == "address" && option && option.primaryAddress) {
      this.updateFormValueForSearch(option.primaryAddress, option?.predefinedSiteNode);
      this.displayForm = true;
    }

    if (type == "subaddresses" && option && option.address) {
      this.updateFormValueForSearch(option.address, option?.predefinedSiteNode);
      this.displayForm = true;
    }
    if (this.displayForm) {
      if (this.formConfig.activateZendOnSearch)
        this.onSearchActivateZend.emit(true);

      if (this.formConfig.activateA2endOnSearch && this.formConfig.formName == "zEndAdress")
        this.onSearchA2ctivateZend.emit(true);

      if (this.formConfig.activateZ2endOnSearch && this.formConfig.formName == "a2EndAdress")
        this.onSearchZ2ctivateZend.emit(true);
    }
    this.onSearchDetails.emit(this.address);
  }

  displayForm = false;
  primaryAutoComplete = "";
  preDefinedSiteNode = false;
  updateFormValueForSearch(address, preDefinedSiteNode?) {
    address = this.titleCasePipe.transform(address);
    this.searchForm.patchValue({
      primaryAutoComplete: address
    })
    this.primaryAutoComplete = address;

    if(preDefinedSiteNode !==undefined){
      this.preDefinedSiteNode = ((preDefinedSiteNode.toLowerCase() !== 'no')) ? preDefinedSiteNode : false;
    }

    this.primaryAutoCompleteOptions = [];
    this.searchUnstructuredAddressesCompleteOptions = [];
    this.displayForm = false;
    this.showAddressContainer = false;
    this.primarySelect.closePanel();
  }

  searchFocus(e) {
    //  this.updateFormValueForSearch("");
    // this.displayForm = false;
  }

  autoCompletePrimarySelect(primaryAddress) {
    this.updateFormValueForSearch(primaryAddress);
    if (primaryAddress && primaryAddress.length)
      this.searchUnstructuredAddresses(primaryAddress);
  }

  searchUnstructuredAddressesCompleteOptions = [];
  searchUnstructuredAddresses(primaryAddress) {
    let payload = { "addressLine1": primaryAddress };
    this.showAddressContainer = false;
    return new Promise((resolve, reject) => {
      this.createQuoteService.searchUnstructuredAddresses(payload).then((res) => {
        res.data.addresses.map(obj => obj.primaryAddress = this.titleCasePipe.transform(obj.primaryAddress));
        this.searchUnstructuredAddressesCompleteOptions = res.data.addresses;
        this.showAddressContainer = true;
        this.primarySelect.openPanel();
        resolve(res.data.addresses);
      }).catch((err) => {
        this.showAddressContainer = true;
        this.primarySelect.openPanel();
        reject(err);
      });
    });
  }

  caclVSHeight() {
    let h = "25px";
    if (this.primaryAutoCompleteOptions?.length || this.searchUnstructuredAddressesCompleteOptions?.length) {
      h = "175px";
    }
    return h;
  }

  setAll(obj, val) {
    try {
      Object.keys(obj).forEach(function (index) {
        obj[index] = val
      });
    } catch (e) { }
  }

  sameAsPath1ZEndContactDetailsDisable = false;
  onCheckChange(e, type, from) {


    this.patch2CheckedInfo.emit({
      checked: true,
      type: type,
      from: from,
      formName: this.formConfig.formName
    })


    // search
    if (this.siteDetailsService?.searchFormAEndAdress?.searchMethod === 'search' || this.siteDetailsService?.searchFormZEndAdress?.searchMethod === 'search') {

      if (type == "sameAsPath1AEndAddressDetails" && from == "sameAsPath1A2EndAddressDetails") {
        if (e.checked) {
          this.formConfig.aEndAdress = this.siteDetailsService.searchFormAEndAdress;
          this.primaryAutoComplete =
          this.formConfig.aEndAdress?.primaryAutoComplete;
          this.displayForm = true;

          this.address = {
            primaryAddress: this.primaryAutoComplete,
            adborId: this.formConfig.aEndAdress?.address?.adborId,
            latitude: this.formConfig.aEndAdress?.address?.latitude,
            longitude: this.formConfig.aEndAdress?.address?.longitude,
            nbnLocId: this.formConfig.aEndAdress?.address?.nbnLocId,
            nodeCode: this.formConfig.aEndAdress?.address?.nodeCode,
          };

          setTimeout(() => {
            this.searchForm.patchValue({
              a2sameAsA1EndAddress: true,
              a2sameAsZ1EndAddress: false,
              primaryAutoComplete: this.primaryAutoComplete,
              additionalAddressInfo: this.formConfig.aEndAdress?.additionalAddressInfo
            });
            this.setData();

            if (this.formConfig.formName == 'a2EndAdress') {
              this.searchForm.patchValue({ refresh: true }, { emitEvent: true });
              this.onSearchZ2ctivateZend.emit(true);
            }
          }, 20);
        } else {
          this.primaryAutoComplete = '';
          this.displayForm = false;
          this.address = {
            primaryAddress: null,
            adborId: null,
            latitude: null,
            longitude: null,
            nbnLocId: null,
            nodeCode: null,
          };

          setTimeout(() => {
            this.searchForm.patchValue({
              a2sameAsA1EndAddress: false,
              a2sameAsZ1EndAddress: false,
              primaryAutoComplete: this.primaryAutoComplete,
              additionalAddressInfo: ""
            });
            this.setData();
            this.resetForm();
          }, 20);
        }
      }


      if (type == "sameAsPath1ZEndAddressDetails" && from == "sameAsPath1A2EndAddressDetails") {

          if (e.checked && this.siteDetailsService?.searchFormZEndAdress?.searchMethod === 'search' && type == "sameAsPath1ZEndAddressDetails" && from == "sameAsPath1A2EndAddressDetails") {
            this.formConfig.zEndAdress = this.siteDetailsService.searchFormZEndAdress;
            this.primaryAutoComplete = this.formConfig.zEndAdress?.primaryAutoComplete;
            this.displayForm = true;
            this.address = {
              primaryAddress: this.primaryAutoComplete,
              adborId: this.formConfig.zEndAdress?.address?.adborId,
              latitude: this.formConfig.zEndAdress?.address?.latitude,
              longitude: this.formConfig.zEndAdress?.address?.longitude,
              nbnLocId: this.formConfig.zEndAdress?.address?.nbnLocId,
              nodeCode: this.formConfig.zEndAdress?.address?.nodeCode
            }
            setTimeout(() => {
              this.searchForm.patchValue({
                "a2sameAsA1EndAddress": false,
                "a2sameAsZ1EndAddress": true,
                primaryAutoComplete: this.primaryAutoComplete,
                additionalAddressInfo: this.formConfig.zEndAdress.additionalAddressInfo
              })
              this.setData();

              if (this.formConfig.formName == 'a2EndAdress') {
                this.searchForm.patchValue({ refresh: true }, { emitEvent: true });
                this.onSearchZ2ctivateZend.emit(true);
              }
            }, 20);
          } else {
            this.primaryAutoComplete = "";
            this.displayForm = false;
            this.address = {
              primaryAddress: null,
              adborId: null,
              latitude: null,
              longitude: null,
              nbnLocId: null,
              nodeCode: null
            }

            setTimeout(() => {
              this.searchForm.patchValue({
                "a2sameAsA1EndAddress": false,
                "a2sameAsZ1EndAddress": false,
                primaryAutoComplete: this.primaryAutoComplete,
                additionalAddressInfo: ""
              })
              this.setData();
              this.resetForm();
            }, 20);
          }
      }


      if (type == "sameAsPath1AEndAddressDetails" && from == "sameAsPath1Z2EndAddressDetails") {

          // Latitude and Longitude
          if (e.checked && this.siteDetailsService?.searchFormAEndAdress?.searchMethod === 'search' && type == "sameAsPath1AEndAddressDetails" && from == "sameAsPath1Z2EndAddressDetails") {
            this.formConfig.aEndAdress = this.siteDetailsService.searchFormAEndAdress;

            this.primaryAutoComplete = this.formConfig.aEndAdress?.primaryAutoComplete;
            this.displayForm = true;
            this.address = {
              primaryAddress: this.primaryAutoComplete,
              adborId: this.formConfig.aEndAdress?.address?.adborId,
              latitude: this.formConfig.aEndAdress?.address?.latitude,
              longitude: this.formConfig.aEndAdress?.address?.longitude,
              nbnLocId: this.formConfig.aEndAdress?.address?.nbnLocId,
              nodeCode: this.formConfig.aEndAdress?.address?.nodeCode
            }

            setTimeout(() => {
              this.searchForm.patchValue({
                "z2sameAsA1EndAddress": true,
                "z2sameAsZ1EndAddress": false,
                primaryAutoComplete: this.primaryAutoComplete,
                additionalAddressInfo: this.formConfig.aEndAdress?.additionalAddressInfo
              })
              this.setData();

              if (this.formConfig.formName == 'z2EndAdress') {
                this.searchForm.patchValue({ refresh: true }, { emitEvent: true });
              }
            }, 20);
          } else {
            this.primaryAutoComplete = "";
            this.displayForm = false;
            this.address = {
              primaryAddress: null,
              adborId: null,
              latitude: null,
              longitude: null,
              nbnLocId: null,
              nodeCode: null
            }

            setTimeout(() => {
              this.searchForm.patchValue({
                "z2sameAsA1EndAddress": false,
                "z2sameAsZ1EndAddress": false,
                primaryAutoComplete: this.primaryAutoComplete,
                additionalAddressInfo: ""
              })
              this.setData();
              this.resetForm();
            }, 20);
          }
      }


      if (type == "sameAsPath1ZEndAddressDetails" && from == "sameAsPath1Z2EndAddressDetails") {

          if (e.checked && this.siteDetailsService?.searchFormZEndAdress?.searchMethod === 'search' && type == "sameAsPath1ZEndAddressDetails" && from == "sameAsPath1Z2EndAddressDetails") {
            this.formConfig.zEndAdress = this.siteDetailsService.searchFormZEndAdress;
            this.primaryAutoComplete = this.formConfig.zEndAdress?.primaryAutoComplete;
            this.displayForm = true;
            this.address = {
              primaryAddress: this.primaryAutoComplete,
              adborId: this.formConfig.zEndAdress?.address?.adborId,
              latitude: this.formConfig.zEndAdress?.address?.latitude,
              longitude: this.formConfig.zEndAdress?.address?.longitude,
              nbnLocId: this.formConfig.zEndAdress?.address?.nbnLocId,
              nodeCode: this.formConfig.zEndAdress?.address?.nodeCode
            }

            setTimeout(() => {
              this.searchForm.patchValue({
                "z2sameAsA1EndAddress": false,
                "z2sameAsZ1EndAddress": true,
                primaryAutoComplete: this.primaryAutoComplete,
                additionalAddressInfo: this.formConfig.zEndAdress.additionalAddressInfo
              })
              this.setData();

              if (this.formConfig.formName == 'z2EndAdress') {
                this.searchForm.patchValue({ refresh: true }, { emitEvent: true });
              }
            }, 20);
          } else {
            this.primaryAutoComplete = "";
            this.displayForm = false;
            this.address = {
              primaryAddress: null,
              adborId: null,
              latitude: null,
              longitude: null,
              nbnLocId: null,
              nodeCode: null
            }

            setTimeout(() => {
              this.searchForm.patchValue({
                "z2sameAsA1EndAddress": false,
                "z2sameAsZ1EndAddress": false,
                primaryAutoComplete: this.primaryAutoComplete,
                additionalAddressInfo: ""
              })
              this.setData();
              this.resetForm();
            }, 20);
          }
      }
    }


  }

  toggleToLatLongSearch(id) {
    this.formConfig.activeTab = id;
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
  }

  onChangeRadioButton(value: string): void {
    this.searchForm.patchValue({ definedPropertyLocation: value });
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyUp(event) {
    const activeElement = document.activeElement;
    // const textareaElement = document.getElementById('provideAdditionalInfo')
    const textareas = document.querySelectorAll('.resizableTextarea')
    if (event.ctrlKey) {
      textareas.forEach((textareaElement) => {
      if(activeElement === (textareaElement as HTMLTextAreaElement)) {
       const computedStyle = window.getComputedStyle((textareaElement as HTMLTextAreaElement));
       const currentHeight = parseInt(computedStyle.getPropertyValue('height'), 10);
       const currentRows = parseInt((textareaElement as HTMLTextAreaElement).getAttribute('rows'), 10)
        if (event.key === 'ArrowUp') {
          (textareaElement as HTMLTextAreaElement).setAttribute('rows', `${currentRows - 1}`);
            (textareaElement as HTMLTextAreaElement).style.height = `${currentHeight - 10}px`;
        } else if (event.key === 'ArrowDown') {
          (textareaElement as HTMLTextAreaElement).setAttribute('rows', `${currentRows + 1}`);
          (textareaElement as HTMLTextAreaElement).style.height = `${currentHeight + 10}px`;
        }
      }
    })
    }
  }
}
