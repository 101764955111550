<div class="modal" role="dialog" [ngClass]="{'tncModal':isModalOpen}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="closeBtn">
        <span (click)="closeModal()" (keypress)="closeModal()" class="close" tabindex="0" aria-label="Close" role="button">X</span>
      </div>
      <object data="../../../assets/documents/Duct_Access_Form-Terms_and_Conditions.pdf" type="application/pdf"
        class="modal-document">
        <p>Your browser does not support PDFs.</p>
      </object>
    </div>
  </div>
</div>