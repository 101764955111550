<div class="status-selector">
  <div class="status-container row" *ngIf="quoteOrOrderStatus.toLowerCase() === 'cancelled'">
    <div class="col-3"><img src="../../../../assets/images/Cancelled.svg" class="spriteNew"/></div>
    <div class="col-9 status-container-text"><span class="status-text">{{quoteOrOrderStatus}}</span></div>
  </div>
  <div class="status-container row" *ngIf="quoteOrOrderStatus.toLowerCase() === 'expired'">
     <div class="col-3"><img src="../../../../assets/images/Expired.svg" class="spriteNew"/></div>
   <div class="col-9 status-container-text"><span class="status-text">{{quoteOrOrderStatus}}</span></div>
  </div>
  <div class="status-container row" *ngIf="(quoteOrOrderStatus.toLowerCase() === 'in progress' || quoteOrOrderStatus.toLowerCase() === 'appraisal - in progress'
  || quoteOrOrderStatus.toLowerCase() === 'site assessment - in progress' || quoteOrOrderStatus.toLowerCase() === 'design - in progress' || quoteOrOrderStatus.toLowerCase() === 'construction - in progress')">
     <div class="col-3"><img src="../../../../assets/images/In-Progress.svg" class="spriteNew"/></div>
   <div class="col-9 status-container-text" [ngClass]="quoteOrOrderStatus.length > 17 ? 'long-text-status' : ''"><span class="status-text">{{quoteOrOrderStatus}}</span></div>
  </div>
  <div class="status-container row" *ngIf="(quoteOrOrderStatus.toLowerCase() === 'draft' || quoteOrOrderStatus.toLowerCase() === 'awaiting commercial review')">
     <div class="col-3"><img src="../../../../assets/images/Draft.svg" class="spriteNew"/></div>
   <div class="col-9 status-container-text" [ngClass]="quoteOrOrderStatus.length > 17 ? 'long-text-status' : ''"><span class="status-text">{{quoteOrOrderStatus}}</span></div>
  </div>
  <div class="status-container row" *ngIf="quoteOrOrderStatus.toLowerCase() === 'withdrawn'">
     <div class="col-3"><img src="../../../../assets/images/Withdrawn.svg" class="spriteNew"/></div>
   <div class="col-9 status-container-text" [ngClass]="quoteOrOrderStatus.length > 17 ? 'long-text-status' : ''"><span class="status-text">{{quoteOrOrderStatus}}</span></div>
  </div>
  <div class="status-container row" *ngIf="quoteOrOrderStatus.toLowerCase() === 'withdraw submitted'">
     <div class="col-3"><img src="../../../../assets/images/Withdrawn.svg" class="spriteNew"/></div>
   <div class="col-9 status-container-text" [ngClass]="quoteOrOrderStatus.length > 17 ? 'long-text-status' : ''"><span class="status-text">{{quoteOrOrderStatus}}</span></div>
  </div>
  <div class="status-container row" *ngIf="quoteOrOrderStatus.toLowerCase() === 'quote ready' || quoteOrOrderStatus.toLowerCase() === 'ordered' || quoteOrOrderStatus.toLowerCase() === 'completed' || quoteOrOrderStatus.toLowerCase() === 'complete'">
     <div class="col-3"><img src="../../../../assets/images/Quote-Ready.svg" class="spriteNew"/></div>
   <div class="col-9 status-container-text" [ngClass]="quoteOrOrderStatus.length > 17 ? 'long-text-status' : ''"><span class="status-text">{{quoteOrOrderStatus}}</span></div>
  </div>
  <div class="status-container row" *ngIf="(quoteOrOrderStatus.toLowerCase() === 'submitted' || quoteOrOrderStatus.toLowerCase() === 'accepted')">
     <div class="col-3"><img src="../../../../assets/images/Submitted.svg" class="spriteNew"/></div>
   <div class="col-9 status-container-text" [ngClass]="quoteOrOrderStatus.length > 17 ? 'long-text-status' : ''"><span class="status-text">{{quoteOrOrderStatus}}</span></div>
  </div>
  <div class="status-container row" *ngIf="(quoteOrOrderStatus.toLowerCase() === 'awaiting customer response' || quoteOrOrderStatus.toLowerCase() === 'appraisal - on hold'
  || quoteOrOrderStatus.toLowerCase() === 'site assessment - on hold' || quoteOrOrderStatus.toLowerCase() === 'design - on hold' || quoteOrOrderStatus.toLowerCase() === 'construction - on hold')">
     <div class="col-3"><img src="../../../../assets/images/Awaiting-Customer-Response.svg" class="spriteNew"/></div>
   <div class="col-9 status-container-text" [ngClass]="quoteOrOrderStatus.length > 17 ? 'long-text-status' : ''"><span class="status-text">{{quoteOrOrderStatus}}</span></div>
  </div>
  <div class="status-container row" *ngIf="quoteOrOrderStatus.toLowerCase() === 'awaiting dependent action'">
     <div class="col-3"><img src="../../../../assets/images/Awaiting-Dependent-Action.svg" class="spriteNew"/></div>
   <div class="col-9 status-container-text" [ngClass]="quoteOrOrderStatus.length > 17 ? 'long-text-status' : ''"><span class="status-text">{{quoteOrOrderStatus}}</span></div>
  </div>
  <div class="status-container row" *ngIf="quoteOrOrderStatus.toLowerCase() === 'assessment complete'">
    <div class="col-3"><img src="../../../../assets/images/AssessmentComplete.svg" class="spriteNew"/></div>
  <div class="col-9 status-container-text" [ngClass]="quoteOrOrderStatus.length > 17 ? 'long-text-status' : ''"><span class="status-text">{{quoteOrOrderStatus}}</span></div>
 </div>
  <div class="status-container row" *ngIf="quoteOrOrderStatus.toLowerCase() === 'quote accepted'">
    <div class="col-3"><img src="../../../../assets/images/quote-accepted-icon.svg" class="spriteNew"/></div>
    <div class="col-9 status-container-text"><span class="status-text">{{quoteOrOrderStatus}}</span></div>
  </div>
 
</div>
