import { Component, OnInit } from '@angular/core';
import { FaqService } from './faq.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { saveAs as importedSaveAs } from 'file-saver';
import { CommonService } from '../shared/services/common.service';
import { HomePageService } from '../shared/services/homePage.service';
import { AuthService } from 'tw-core-ui';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.less']
})
export class FaqComponent implements OnInit {
  public faqDetailsObj: any;
  public faqHeader: String;
  public faqText: String;
  public faqData;
  public env: any = window['environment'];
  faqArray = [];
  faqSectionHeaders: any;
  roleObj: string = "";
  constructor(private faqService: FaqService, private router: Router, private activeRoute: ActivatedRoute,
    private commonService: CommonService,
    private homepageservice: HomePageService,
    private authService: AuthService,
    private commonservice:CommonService,
    private titleService: Title
  ) {
    this.titleService.setTitle('InfraCo Customer Portal - FAQ');
    const userName = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail'];
    const roles = this.commonService.getroleDetails();
    const customer = roles.customerList;
    this.homepageservice.getUserProfile(userName).then((response) => {
      const customerDetails = response?.data?.customerDetails;
      customerDetails?.forEach(element => {
        if (customer.some(a => a?.role === 'NBN Administrator') || customer.some(a => a?.role === 'NBN Read Only') || customer.some(a => a?.role === 'NBN Read & Write')) {
          if (element.customerName == 'NBNCo') {
            this.roleObj = 'nbn';
          } else if (element.customerName.toUpperCase() == 'INFRACO') {
            this.roleObj = 'infraco';
          }
        } else if (customer.some(a => a?.role === 'Duct Administrator') || customer.some(a => a?.role === 'Duct Read & Write')) { //duct admin
          this.roleObj = 'duct';
        } 
      });
    });

    faqService.getFaqDetails().then((res) => {
      this.faqDetailsObj = res;
      this.faqText = this.faqDetailsObj['faqText'];
      this.faqHeader = this.faqDetailsObj['faqHeader'];
      if (this.roleObj === 'duct') {
        this.faqData = this.faqDetailsObj['faqDataDuct'];
      } else {
        this.faqData = this.faqDetailsObj['faqData'];
      }
      this.faqSectionHeaders = this.faqData.map((item) => {
        return Object.keys(item)[0];
      });
    }).catch((err) => {
      this.router.navigateByUrl('/error');
    });
  }

  toggleAccordian(event, row) {
    // console.log(event);
    // console.log(row);
    const element = event.target;
    element.classList.toggle('active');
    element.setAttribute('aria-expanded', (element.getAttribute('aria-expanded') === 'true' ? 'false' : 'true'));
    const panel = element.nextElementSibling;

    if (panel.style.maxHeight) {
      row.show = false;
      panel.style.maxHeight = null;
    } else {
      row.show = true;
      setTimeout(() => {
        panel.style.maxHeight = panel.scrollHeight + 'px';        
      }, 0);
    }
  }

  ngOnInit() {
    const roles = this.commonservice.getroleDetails().customerList;
    if (!roles.some((obj) => obj.role.includes('NBN') || obj.function.includes('NBN'))) {
      this.router.navigateByUrl('/access-denied');
    }
  }

  ngAfterViewInit() {
    let checkExistLinks = setInterval(function () {
      const divs= document.querySelectorAll('td a');
      if (divs.length && divs.length > 0) {
        for (let i = 0; i < divs.length; i++) {
          divs[i].setAttribute('target', '_blank');
        }
        clearInterval(checkExistLinks);
      }
    }, 100);
  }

  viewDocument(path) {
    importedSaveAs(path, 'User Guide.pdf');
  }

}
