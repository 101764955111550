import { Component, OnInit } from '@angular/core';
import { ErrorService } from '../shared/services/error.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.less']
})
export class AccessDeniedComponent implements OnInit {

  public errorDetailsObj: Object = {};
  public accessDeniedHeader: String;
  public accessDeniedText: String;
  constructor(private titleService: Title, private errorService: ErrorService) {
    this.titleService.setTitle('InfraCo Customer Portal - Access Denied Page');
    errorService.getErrorDetails().then((res) => {
      this.errorDetailsObj = res;
      this.accessDeniedText = this.errorDetailsObj['accessDeniedText'];
      this.accessDeniedHeader = this.errorDetailsObj['accessDeniedHeader'];
    })
    .catch((err) => {
    });
   }

  ngOnInit() {
  }

}
