
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError as observableThrowError, BehaviorSubject, Subject } from 'rxjs';
import { AuthService } from 'tw-core-ui';
import { HomePageService } from './homePage.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  aPIUrl: string = '';
  showHome: Boolean = false;

  public defaultCustomerValue = new BehaviorSubject<any>('');
  $defaultCustomerValue = this.defaultCustomerValue.asObservable()

  defaultCustomerObject: string = '';
  tempApp1: string;
  tempRole1: string;
  tempFunction1: string;
  customerList: { cidn: string; app: string; role: string; function: string; }[];
  selectedCidn: any;
  defaultProfileVal: string;
  isInitialHeaderCustChanged: boolean = false;
  savedPrefSingleCust: boolean = false;
  setAllPageheaderVal: any;
  showCreateUserFromMenu = new BehaviorSubject<any>('');
  public showCreateUserFromMenu$ = this.showCreateUserFromMenu.asObservable();

  showTopButton = new BehaviorSubject<any>('');
  public showTopButton$ = this.showTopButton.asObservable();
  showHomeContent = new BehaviorSubject<any>(false);
  public showHomeContent$ = this.showHomeContent.asObservable();
  public toResetCustomerDropdown = new BehaviorSubject<boolean>(false);
  $toResetCustomerDropdown = this.toResetCustomerDropdown.asObservable()
  dropDownValues: any;
  private customerName = new Subject<string>();
  private userRolesForLocal = [];
  private env: any = window['environment'];

  constructor(private http: HttpClient, private authService: AuthService, private homePageService: HomePageService) {
  }

  showCreateUser(value: any) {
    this.showCreateUserFromMenu.next(value);
  }

  showTopButtonInFooter(value: any) {
    this.showTopButton.next(value);
  }

  setShowHomeContent(value: any) {
    this.showHomeContent.next(value);
  }

  setDefaultCustomerObject = (value: string) => {
    sessionStorage.setItem('defaultCustomerObject', value)
    this.defaultCustomerObject = value;
  }

  setSelectedCidn(val) {
    sessionStorage.setItem('selectedCidn', val)
    this.selectedCidn = val;
  }


  getSelectedCidn = () => {
    return this.selectedCidn;
  }

  public getroleDetails() {
    const roleList = [];
    const appList = [];
    const cidnList = [];
    const functionList = [];
    const custList = [];
    // const profile = {
    // //nbn
    // const user_roles = [
    //     { "type": "cidn:infraco", "value": "9999999999:infraco_nbnadmin_nbnusermgmt" },
    //     { "type": "cidn:infraco", "value": "9999999999:infraco_nbnadmin_nbncabinetmgmt" },
    //     { "type": "cidn:infraco", "value": "9999999999:infraco_nbnreadwrite_nbncabinet" },
    //     { "type": "cidn:infraco", "value": "9999999999:infraco_nbnreadwrite_nbndocument" },
    //     { "type": "cidn:infraco", "value": "9999999999:infraco_nbnread_nbncabinet" },
    //     { "type": "cidn:infraco", "value": "9999999999:infraco_nbnadmin_nbncabinet" },
    //     { "type": "cidn:infraco", "value": "9999999999:infraco_nbnread_nbndocument" }
    //   ]
    // //duct
    //     {"value": "9999999999:infraco_ductadmin_ductusermgmt"},
    //     {"value": "9999999999:infraco_ductreadwrite_ductaccessform"}
    //   ],
    //   'contactName': 'Ayush Sahu',
    //   'contactEmail': 'ayush.sahu@team.telstra.com',
    //   'userInitials': 'AS',
    //   'targetUrl': ''
    // };
    //fiber
    //    const user_roles = [
    //      { type: "cidn:infraco", value: "9999999999:infraco_admin_usermgmt" },
    //      { type: "cidn:infraco", value: "9999999999:infraco_admin_quotemgmt" },
    //      { type: "cidn:infraco",value: "9999999998:infraco_readwrite_quotemgmt" },
    //      { type: "cidn:infraco",value: "9999999998:infraco_readwrite_ordermgmt" }
    //  ]; 
    // const user_roles: any = profile.roles;

    let user_roles:any = [];

  if(this.env['production'] === false && window.location.href.search('localhost') > -1){
    user_roles = this.getUserRolesForLocal();
  }else {
    user_roles = this.authService.user.profile.roles;
  }


    if (user_roles) {
      if (Array.isArray(user_roles)) {
        user_roles.forEach(item => {
          const tempApp1 = item.type.split(':')[1];
          const valString = item.value;
          const tempCidn = valString.split(':')[0];
          const restValstring = valString.split(':')[1];
          let tempApp = restValstring.split('_')[0];
          const tempRole = restValstring.split('_')[1];
          const restVal1string = valString.split('_')[2]
          const tempFunction = restVal1string.split('_')[0];
          if (tempApp1) {
            appList.push(tempApp);
          }
          this.customerList = [
            {
              cidn: tempCidn,
              app: tempApp,
              role: tempRole,
              function: tempFunction
            }
          ]
          if (this.customerList) {
            if (tempApp === 'infraco') {
              this.tempApp1 = 'InfraCo Customer Portal';
            }
            if (tempRole === 'admin') {
              this.tempRole1 = 'Administrator';
            }
            if (tempRole === 'readwrite') {
              this.tempRole1 = 'Read & Write';
            }
            if (tempRole === 'readonly') {
              this.tempRole1 = 'Read Only';
            }
            if (tempRole === 'nbnadmin') {
              this.tempRole1 = 'NBN Administrator';
            }
            if (tempRole === 'nbnreadwrite') {
              this.tempRole1 = 'NBN Read & Write';
            }
            if (tempRole === 'nbnread') {
              this.tempRole1 = 'NBN Read Only';
            }
            if (tempFunction === 'customermgmt') {
              this.tempFunction1 = 'Customer Management';
            }
            if (tempFunction === 'nbncabinet') {
              this.tempFunction1 = 'NBN Cabinets';
            }
            if (tempFunction === 'nbndocument') {
              this.tempFunction1 = 'NBN DA Documents';
            }
            if (tempFunction === 'usermgmt') {
              this.tempFunction1 = 'User Management';
            }
            if (tempFunction === 'nbnusermgmt') {
              this.tempFunction1 = 'NBN User Management';
            }
            if (tempFunction === 'nbncabinetmgmt') {
              this.tempFunction1 = 'NBN Cabinet Access Management';
            }
            if (tempRole === 'ductadmin') {
              this.tempRole1 = 'Duct Administrator';
            }
            if (tempRole === 'ductreadwrite') {
              this.tempRole1 = 'Duct Read & Write';
            }
            if (tempFunction === 'ductusermgmt') {
              this.tempFunction1 = 'Duct User Management';
            }
            if (tempFunction === 'ductaccessform') {
              this.tempFunction1 = 'Duct Access Form';
            }
            if (tempFunction === 'quotemgmt') {
              this.tempFunction1 = 'Quote Request Management';
            }
            if (tempFunction === 'ordermgmt') {
              this.tempFunction1 = 'Order Management';
            }
            if (tempFunction === 'servicemgmt') {
              this.tempFunction1 = 'Service Management';
            }
            if (tempFunction === 'documentmgmt') {
              this.tempFunction1 = 'Document Management';
            }
            custList.push({
              cidn: tempCidn,
              app: this.tempApp1,
              role: this.tempRole1,
              function: this.tempFunction1
            });
          }
        });
      }
    }
    return { 'cidnList': cidnList, 'roleList': roleList, 'appList': appList, 'functionList': functionList, 'customerList': custList };
  }

  setheadervalPage(val) {
    sessionStorage.setItem('setAllPageheaderVal', val)
    this.setAllPageheaderVal = val;
    this.customerName.next(val);
  }
  getCustomerName() {
    return this.customerName.asObservable();
  }
  getheadervalPage() {
    return this.setAllPageheaderVal;
  }

  getDefaultCustomerObject = () => {
    return this.defaultCustomerObject;
  }

  setProfileDefaultCustomer(value: string) {
    sessionStorage.setItem('profileDefault', value)
    this.defaultProfileVal = value;
  }

  getprofileDefaultcustomer() {
    return this.defaultProfileVal;
  }


  setDefaultCustomer(value: string) {
    sessionStorage.setItem('defaultCustomerValue', value);
    sessionStorage.setItem('defaultCustomerValue', value)
    this.defaultCustomerValue.next(value);
  }

  focusOnElement(id: string) {
    let focusableElement = document.getElementById(id);
    setTimeout(() => {
      if (focusableElement) {
        focusableElement.focus();
      }
    }, 10);
  }

  setShowContent(val) {
    this.showHome = val;
  }

  getShowContent() {
    return this.showHome;
  }
  getDefaultCustomer() {
    return this.$defaultCustomerValue;
  }

  setHeaderValueChangeTrace(value: boolean) {
    this.isInitialHeaderCustChanged = value;
  }

  getHeaderValueChangeTrace() {
    return this.isInitialHeaderCustChanged;
  }
  setSavePrefSingleCust(val: boolean) {
    this.savedPrefSingleCust = val;
  }
  getSavePrefSingleCust() {
    return this.savedPrefSingleCust;
  }
  trapFocusInModal(firstEl, secEle) {
    let firstFocusableElement;
    let lastFocusableElement;
    setTimeout(() => {
      firstFocusableElement = document.getElementById(firstEl); // get first element to be focused inside modal
      lastFocusableElement = document.getElementById(secEle);
    }, 10);
    document.addEventListener('keydown', function (e) {
      let isTabPressed = e.key === 'Tab' || e.keyCode === 9;
      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) { // if shift key pressed for shift + tab combination
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus();// add focus for the last focusable element
          e.preventDefault();
        }
      } else { // if tab key is pressed
        if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
          firstFocusableElement.focus();// add focus for the first focusable element
          e.preventDefault();
        }
      }
    });
    if (firstFocusableElement) {
      firstFocusableElement.setAttribute("focus", "true");
    }
  }

  getDuctRoleDetails() {
    const roles = this.getroleDetails().customerList;
    const hasAllDuctRoles = roles.some((obj) => obj.role.includes('Duct') || obj.function.includes('Duct'));
    const isDuctAdmin = roles.some((obj) => obj.role.includes('Duct Administrator'));
    const isDuctUser = roles.some((obj) => obj.role.includes('Duct Read & Write'));
    const ductRoles = { hasAllDuctRoles, isDuctAdmin, isDuctUser }
    return ductRoles;
  }

  setHeaderCustomerDropdown(value: boolean) {
    this.toResetCustomerDropdown.next(value);
  }

  getHeaderCustomerDropdown() {
    return this.$toResetCustomerDropdown;
  }

  setDropdown(dropdownOptions){
    this.dropDownValues=dropdownOptions;
  }

  getDropdown(){
    return this.dropDownValues;
  }

  getUserDetailsForLocal(){
    let userInfo: Object = null;
    return new Promise((resolve, reject) => {
        this.http.get(window['userManagerObj']['userInfoEndPoint'])
          .subscribe((res:any) => {
            userInfo = res;
            this.setUserRolesForLocal(res?.roles);
            resolve(res);
          }, (err) => {
            reject(err);
        });
      
    }); 
  }

  setUserRolesForLocal(roles){
    this.userRolesForLocal = roles;
  }

  getUserRolesForLocal(){
    return this.userRolesForLocal;
  }



}