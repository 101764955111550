import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomTextField, CustomDropDown } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { CommonService } from '../shared/services/common.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HomePageService } from '../shared/services/homePage.service';
// import { PopUpModel } from '../shared/model/general.model';
import { ToastContainerDirective, ToastrService, GlobalConfig } from 'ngx-toastr';
import { AuthService } from 'tw-core-ui';
import { CustomerService } from '../admin-settings/customer-list/customer.service';


export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-landing-pop-up',
  templateUrl: './landing-pop-up.component.html',
  styleUrls: ['./landing-pop-up.component.css']
})
export class LandingPopUpComponent implements OnInit, AfterViewInit {

  public popupForm: FormGroup;
  public businessNames: any[] = [];
  public businessNameDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Bussiness Name Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  closeResult: string = '';
  @ViewChild('content', { static: false }) content!: ElementRef;
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  aemLabelAuthoring: any = '';
  popUpModel: any = {};
  userDate: any = {};
  options: GlobalConfig;
  customerList: any = {};
  contactName: string = sessionStorage.getItem('contactName') ? sessionStorage.getItem('contactName') : '';
  linkedCIDNs: any = {};
  infraCoCIDN = window['environment'].InfraCoCIDN;
  myLinkedCustomerListFromCaiman = [];
  custNames: any[] = [];

  constructor(
    public fb: FormBuilder,
    public commonservice: CommonService,
    private modalService: NgbModal,
    private homepageservice: HomePageService,
    private toastr: ToastrService,
    private authService: AuthService,
    private customerService: CustomerService

  ) {
    this.popupForm = this.fb.group({
      businessTypes: ['', [Validators.required]],
    });
    this.options = this.toastr.toastrConfig;

  }

  ngOnInit() {
    this.getLabels();
    this.getCommonServices();

  }

  ngAfterViewInit() {
    this.open();
  }

  getLabels = () => {
    return new Promise((resolve, reject) => {
      this.homepageservice.getLabels().then((res) => {
        this.aemLabelAuthoring = res;
        this.popUpModel = this.aemLabelAuthoring.form.popUp;
        resolve(true);
      }).catch(() => {
        reject(false);
      });
    });
  }

  getCommonServices = () => {
    const popUpData = JSON.parse(this.commonservice.getDefaultCustomerObject());
    this.customerList = popUpData.dataList;
    this.myLinkedCustomerListFromCaiman = popUpData.allCustomerList;
    this.myLinkedCustomerListFromCaiman.forEach(option => {
      if (option.cidn === this.infraCoCIDN) {
        //match Infraco cidn to get all cutomer list to show in dropdown
        this.matchCIDN();
      } else {
        this.businessNames = popUpData.dropdownOptions;
      }
    });
  }

  matchCIDN = () => {
    //get all roles
    const getRolesFromCaiman = (this.commonservice.getroleDetails()).customerList;
    //get all customers
    this.customerService.getLinkedCIDNs().then(res => {
      this.linkedCIDNs = res.data.businessNameDetails;
      //match customers condition
      this.linkedCIDNs.forEach(item => {
        const isDuctAdmin = getRolesFromCaiman.some(a => a.role === 'Duct Administrator')
        getRolesFromCaiman.forEach(ele => {
          if ((ele.role === 'Administrator' || ele.role === 'Read Only' || isDuctAdmin) && item.status === 'Active') {
            this.custNames.push(item.bussinessName);
          }
          // if (item.status === 'Active') {
          //   if (ele.role === 'Administrator' && ele.function === 'User Management') {
          //     this.custNames.push(item.bussinessName);
          //   } 
          //   else if (ele.role === 'Read Only' && ele.function === 'Quote Request Management') {
          //     this.custNames.push(item.bussinessName);
          //   } else if (isDuctAdmin) {
          //     this.custNames.push(item.bussinessName);
          //   } else {
          //     this.businessNames=this.commonservice.dropDownValues;
          //   }
          // } 

        });
      });
      this.businessNames = [...new Set(this.custNames)];
    }).catch((err) => {
    });
  }

  open() {
    this.modalService.open(this.content, { ariaLabelledBy: 'welcome-pop-up-title', keyboard: false, backdrop: 'static' }).result.then(
      (result) => {
      },
      (reason) => {
      },
    );
  }

  save(): void {
    const val = this.popupForm.get('businessTypes')?.value;
    if (val) {
      const payload = {
        userId: this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail'],// 'sagar.tumma@team.telstra.com',
        preferenceType: this.customerList[0]?.preferenceType, //"defaultcustomer",
        preference: val,
        preferenceCategory: this.customerList[0]?.preferenceCategory,//"hub_preference",
        status: this.customerList[0]?.status,
        deliveryChannel: this.customerList[0]?.deliveryChannel
      }
      this.homepageservice.putPreferences(payload).then(response => {
        this.commonservice.setDefaultCustomer(val);
        this.showToasterMessage('Preference Saved Successfully', '', this.options, 'success');

      }).catch((err) => {
        this.showToasterMessage('Something went wrong', '', this.options, 'error');
      });
      this.modalService.dismissAll();
    }
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-top-center';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = false;
    this.options.timeOut = 5000;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = true;
    this.toastr.show(
      message,
      header === '' ? null : header,
      options,
      this.options.iconClasses[type]
    );
    document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
  }

  onSubmit() {
    alert(JSON.stringify(this.popupForm.value))
  }

}
