import { Component, ElementRef, ViewChild, OnInit, AfterViewInit, Input, Output, EventEmitter, HostListener, Inject } from '@angular/core';
import { CustomAgGrid, CustomDropDown } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateQuoteService } from '../../create-new-quote/create-quote.service';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { myQuotesService } from '../../my-quotes/myquotes.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/shared/services/common.service';
import { DialogData } from 'src/app/home/home.component';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-withdraw-modal',
  templateUrl: './withdraw-modal.component.html',
  styleUrls: ['./withdraw-modal.component.less']
})
export class WithdrawModalComponent implements OnInit {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;

  public rowData: any = [];
  @Output() onClickClose: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('content', { static: false }) modalContent: ElementRef;
  public aemLabelAuthoring: any;
  createQuotePayload: any;
  options: GlobalConfig;
  public withdrawRequestForm: FormGroup;
  additionalDetails: string;
  withdrawalReason: string;
  quoteID: any;
  sysID: any;
  cidn: any;
  food;
  closeResult: string;
  quoteValues: any;
  cidnSelected: string;
  status: any;
  feasibilityRequestType: any;
  feasibilityType: any;
  isBusinessDriverTouched: boolean = false;
  withdrawnResponse: any;
  showAlertReason: boolean = false;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private createQuote: CreateQuoteService, private route: ActivatedRoute,
    private toastr: ToastrService, private fb: FormBuilder, private dataSvc: myQuotesService,
    private dialogRef: MatDialogRef<WithdrawModalComponent>, private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.options = this.toastr.toastrConfig;
    this.getCommonServices();
    this.quoteID = this.route.snapshot.paramMap.get('id');

  }

  public withdrawnReasonModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Withdrawal reason Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  public withdrawnReasonList: string[] = ["Incorrect address information", "Other incorrect information", "No longer required",
    "Other"];

  ngOnInit() {
    this.getLabels();
    this.createForm();
    this.quoteID = localStorage.getItem('quoteID');
    this.sysID = this.data?.sysID;
    this.cidn = localStorage.getItem('selectedHeaderCIDN');
    this.status = this.data.status;
    this.feasibilityType = this.data.feasibilityType;
  }
  createForm() {
    this.withdrawRequestForm = this.fb.group({
      withdrawalReason: ['', [Validators.required]],
      additionalDetails: new FormControl('', [Validators.pattern(/^[^<>;]*$/)])
    });
  }


  selectAlertReason() {
    this.showAlertReason = true;
  }

  getLabels() {
    return new Promise((resolve, reject) => {
      this.dataSvc.getQuoteSummaryListModel().then(() => {
        this.aemLabelAuthoring = this.dataSvc.summaryLabels;
        resolve(true);
      }).catch(() => {

      });
    });
  }


  close() {
    this.onClickClose.emit();
    this.modalService.dismissAll();
  }


  @HostListener('document:keyup.escape') onClose() {
    this.onCancel();
  }

  onCancel() {
    this.dialogRef.close();
  }
  cutomerTypeChange(val) {
    this.isBusinessDriverTouched = true;
  }
  saveWithdrawnDetails() {
    const DATA = this.withdrawRequestForm.value;
    const sys_id = this.sysID;
    const quoteReferenceId = this.quoteID;
    const quoteStatus = this.status;
    const feasibilityType = this.feasibilityRequestType;
    const payload = {
      withdrawalReason: this.withdrawRequestForm.controls['withdrawalReason'].value,
      additionalDetails: this.withdrawRequestForm.controls['additionalDetails'].value,
      quoteReferenceId: quoteReferenceId,
      sys_id: sys_id,
      cidn: this.cidn,
      quoteStatus: quoteStatus,
      feasibilityType: feasibilityType
    }
    this.withdrawnFeasibilityRequest(payload);
  }

  withdrawnFeasibilityRequest(payload) {
    return new Promise((resolve, reject) => {
      this.dataSvc.saveWithdrawnDetails(payload, 'Save', this.quoteID).then((res) => {
        this.withdrawnResponse = true;
        this.dialogRef.close(this.withdrawnResponse);
        resolve(true);
        const msg = 'Withdrawal submitted'
        this.showToasterMessage(msg, '', this.options, 'success');

      }).catch((err) => {
        this.withdrawnResponse = false;
        const msg = err?.error?.message ? err.error.message : 'We can’t submit your request at this time. Please refresh and try again later.';
        this.showToasterMessage(msg, '', this.options, 'error');
        this.dialogRef.close(this.withdrawnResponse);
        reject(err);
      });

    });
  }
  onCommentFieldKeyPress(event, val) {
    const element = document.getElementById('additionalDetails');
    if (event.key === " ") {
      const label = `${(val + 1)} out of 1000 characters`
      element.setAttribute('aria-label', label)
    }
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-top-center';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 5000;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;


    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
     document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');

  }
  getCommonServices() {
    this.commonService.getDefaultCustomer().pipe().subscribe(resp => {
      if (resp) {
        this.quoteID = resp;
      }
    });
  }
  onWithdrawn() {
    this.saveWithdrawnDetails();
  }
}


export function noSpecialCharsValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (value && value.includes('<') || value.includes('>') || value.includes(';')) {
      return { noSpecialChars: true };
    }

    return null;
  };

}



