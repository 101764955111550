export interface Image {
    imgLink: string;
    url: string;
    imgAltText: string;
}

export interface Banner {
    images: Image[];
}

export enum CIDNS {
    INFRACO_CIDN = '9999999999',
    NBNCO_CIDN = '3805945066'
}

// export interface PopUpModel {
//     modalTitle: string,
//     modalContent: string,
//     defaultCustomer: {
//         title: string,
//         options: string[]
//     },
//     buttonName: string

// }
