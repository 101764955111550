import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CreateQuoteService } from '../create-quote.service';
import { SiteDetailsService } from './site-details.service';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import { Subscription } from 'rxjs';
import { CustomerService } from 'src/app/admin-settings/customer-list/customer.service';
import { AuthService } from 'tw-core-ui';
import { HomePageService } from 'src/app/shared/services/homePage.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-site-details',
  templateUrl: './site-details.component.html',
  styleUrls: ['./site-details.component.less', '../product-details/product-details.component.less']
})
export class SiteDetailsComponent implements OnInit {
  isQuoteSaved: boolean = false;
  public businessList = [];
  options;
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  createQuotePayload: any;
  cidn = '';
  saveQuoteShow:boolean=false;
  latLongFormAEndAdress: any;
  latLongFormZEndAdress: any;
  referenceFormAEndAdress: any;
  referenceFormZEndAdress: any;
  latLongFormA2EndAdress: any;
  latLongFormZ2EndAdress: any;
  referenceFormA2EndAdress: any;
  referenceFormZ2EndAdress: any;
  tabChanged: any;
  userName: any;
  cidnSelected: any;
  isModifyQuote: boolean;
  public siteHeaderChange: Subscription;
  myLinkedCustomerList: any;
  constructor(
    private createQuoteService: CreateQuoteService,
    private siteDetailsService: SiteDetailsService,
    private router: Router,
    private toastr: ToastrService,
    private commonService: CommonService,
    private customerService: CustomerService,
    private authService: AuthService,
    private homepageservice: HomePageService,
    private titleService: Title
  ) {
    this.options = this.toastr.toastrConfig;
    if (localStorage.getItem('selectedHeaderCIDN')) {
      this.cidn = localStorage.getItem('selectedHeaderCIDN')
    }
    else {
      this.getCommonServices();
    }
    // code for header change in myquotes
    this.customerService.getLinkedCIDNs().then(res => {
      if (res && res.data && res.data.businessNameDetails && res.data.businessNameDetails.length > 0) {
        // let businessList = [];
        const iteratedArr = res.data.businessNameDetails.map(({
          bussinessName,
          cidn
        }) => [bussinessName, cidn]);

        for (var i = 0; i < iteratedArr.length; i++) {
          const businessName = iteratedArr[i][0];
          const cidn = iteratedArr[i][1];
          if (businessName || cidn) {
            this.businessList.push({
              businessName: businessName,
              cidn: cidn
            });

          }
        }

      }
      else {
        this.userName = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail']
        this.homepageservice
          .getUserProfile(this.userName)
          .then((response) => {
            this.myLinkedCustomerList = response?.data?.customerDetails;
            if (this.myLinkedCustomerList) {
              this.myLinkedCustomerList.forEach((option) => {
                this.businessList.push({
                  businessName: option.customerName,
                  cidn: option.cidn
                });
              });
            }
          });
      }

    }).catch((err) => {

    });

    if (this.commonService.getDefaultCustomer()) {
      this.siteHeaderChange = this.commonService.getDefaultCustomer().pipe().subscribe(resp => {

        this.businessList.forEach(item => {

          if (item.businessName === resp) {
            this.cidnSelected = item.cidn;
            localStorage.setItem('selectedHeaderCIDN', this.cidnSelected)
            const roles = this.commonService.getroleDetails().customerList;
            const isReadWriteOnQuoteMgmt = roles.some((obj) => (obj?.cidn === this.cidnSelected && obj?.role === 'Read & Write' && obj?.function === 'Quote Request Management'));
            const isReadOnlyRole = roles.some((obj) => (obj?.cidn === this.cidnSelected && obj?.role === 'Read Only' && obj?.function === 'Quote Request Management'));
            if (isReadWriteOnQuoteMgmt || isReadOnlyRole) {
              this.router.navigate(['/myQuotes']);
            }
            else {
              this.router.navigate(['']);
            }

          }

        })

      })
    }
    this.titleService.setTitle('InfraCo Customer Portal -Site Details page');
  }
  aemLabelAuthoring1: any;
  aemLabelAuthoring: any;
  stepperData = ['Product details', 'Site details', 'Request details', 'Review and submit']
  stepName = 'Site details';
  contactDetailsForm;
  searchFormAEndAdress; searchFormZEndAdress; searchFormA2EndAdress; searchFormZ2EndAdress;
  isContactDetailsFormValid = true;
  isSaveEnabled = false;
  subscription1: Subscription;
  subscription2: Subscription;
  subscription3: Subscription;
  subscription4: Subscription;
  quoteDataToModify: any;
  modifiedFormFields = {};
  modifiedDataPayload: any;
  serviceType = '';

  ngOnInit(): void {
    this.contactDetailsForm = {};
    this.contactFormSubscribe();


    this.getLabels();
    this.createQuotePayload = this.createQuoteService.getProductDetailsFormValues();
    // console.log(this.createQuotePayload);
    this.quoteRefId = this.createQuotePayload?.quoteReferenceId;
    if (!this.createQuotePayload?.quoteReferenceId) {
      this.createQuotePayload = sessionStorage.getItem('productDetailsFormValue');
      this.quoteRefId = this.createQuotePayload?.quoteReferenceId;
    }


    this.subscription2 = this.siteDetailsService.searchFormMessage.subscribe(value => {
      this.tabChanged = false;

      if (value && value.formName) {
        switch (value.formName) {
          case "aEndAdress":
            this.searchFormAEndAdress = value;
            this.latLongFormAEndAdress = undefined;
            this.referenceFormAEndAdress = undefined;
            if (!Boolean(this.searchFormAEndAdress?.valid || this.latLongFormAEndAdress?.valid || this.referenceFormAEndAdress?.valid)) {
              this.contactDetailsForm = undefined;

              this.searchFormZEndAdress = undefined;
              this.searchFormA2EndAdress = undefined;
              this.searchFormZ2EndAdress = undefined;

              this.latLongFormZEndAdress = undefined;
              this.latLongFormA2EndAdress = undefined;
              this.latLongFormZ2EndAdress = undefined;

              this.referenceFormZEndAdress = undefined;
              this.referenceFormA2EndAdress = undefined;
              this.referenceFormZ2EndAdress = undefined;
            }

            break;
          case "zEndAdress":
            if (this.searchFormAEndAdress?.formName || this.latLongFormAEndAdress?.formName || this.referenceFormAEndAdress?.formName) {
              this.searchFormZEndAdress = value;
              this.latLongFormZEndAdress = undefined;
              this.referenceFormZEndAdress = undefined;
            } else {
              // this.contactDetailsForm = undefined;

              this.searchFormZEndAdress = undefined;
              this.searchFormA2EndAdress = undefined;
              this.searchFormZ2EndAdress = undefined;

              this.latLongFormZEndAdress = undefined;
              this.latLongFormA2EndAdress = undefined;
              this.latLongFormZ2EndAdress = undefined;

              this.referenceFormZEndAdress = undefined;
              this.referenceFormA2EndAdress = undefined;
              this.referenceFormZ2EndAdress = undefined;
            }
            break;
          case "a2EndAdress":
            if (
              this.searchFormAEndAdress?.formName || this.latLongFormAEndAdress?.formName || this.referenceFormAEndAdress?.formName
              || this.searchFormZEndAdress?.formName || this.latLongFormZEndAdress?.formName || this.referenceFormZEndAdress?.formName
            ) {
              this.searchFormA2EndAdress = value;
              this.latLongFormA2EndAdress = undefined;
              this.referenceFormA2EndAdress = undefined;
            } else {
              // this.contactDetailsForm = undefined;

              this.searchFormA2EndAdress = undefined;
              this.searchFormZ2EndAdress = undefined;

              this.latLongFormA2EndAdress = undefined;
              this.latLongFormZ2EndAdress = undefined;

              this.referenceFormA2EndAdress = undefined;
              this.referenceFormZ2EndAdress = undefined;
            }
            break;
          case "z2EndAdress":
            if (
              this.searchFormAEndAdress?.formName || this.latLongFormAEndAdress?.formName || this.referenceFormAEndAdress?.formName
              || this.searchFormZEndAdress?.formName || this.latLongFormZEndAdress?.formName || this.referenceFormZEndAdress?.formName
              || this.searchFormA2EndAdress?.formName
            ) {
              this.searchFormZ2EndAdress = value;
              this.latLongFormZ2EndAdress = undefined;
              this.referenceFormZ2EndAdress = undefined;
            } else {
              // this.contactDetailsForm = undefined;

              this.searchFormZ2EndAdress = undefined;

              this.latLongFormZ2EndAdress = undefined;

              this.referenceFormZ2EndAdress = undefined;
            }
            break;
        }
      }

      if (this.isQuoteSaved) {
        this.isQuoteSaved = false;
      }



      this.isSaveEnabled = (
        this.searchFormAEndAdress?.formName ||
        this.searchFormZEndAdress?.formName ||
        this.searchFormA2EndAdress?.formName ||
        this.searchFormZ2EndAdress?.formName ||

        this.latLongFormAEndAdress?.latitude || this.latLongFormAEndAdress?.longitude ||
        this.latLongFormZEndAdress?.latitude || this.latLongFormZEndAdress?.longitude ||
        this.latLongFormA2EndAdress?.latitude || this.latLongFormA2EndAdress?.longitude ||
        this.latLongFormZ2EndAdress?.latitude || this.latLongFormZ2EndAdress?.longitude ||

        this.referenceFormAEndAdress?.formName ||
        this.referenceFormZEndAdress?.formName ||
        this.referenceFormA2EndAdress?.formName ||
        this.referenceFormZ2EndAdress?.formName
      ) ? true : false;
    });

    this.subscription3 = this.siteDetailsService.latLongFormMessage.subscribe(value => {
      this.tabChanged = false;
      if (value && value.formName) {
        switch (value.formName) {
          case "aEndAdress":
            this.latLongFormAEndAdress = value;
            this.searchFormAEndAdress = undefined;
            this.referenceFormAEndAdress = undefined;

            if (!Boolean(this.searchFormAEndAdress?.formName || this.latLongFormAEndAdress?.formName || this.referenceFormAEndAdress?.formName)) {
              this.contactDetailsForm = undefined;

              this.searchFormZEndAdress = undefined;
              this.searchFormA2EndAdress = undefined;
              this.searchFormZ2EndAdress = undefined;

              this.latLongFormZEndAdress = undefined;
              this.latLongFormA2EndAdress = undefined;
              this.latLongFormZ2EndAdress = undefined;

              this.referenceFormZEndAdress = undefined;
              this.referenceFormA2EndAdress = undefined;
              this.referenceFormZ2EndAdress = undefined;
            }

            break;

          case "zEndAdress":
            if (this.searchFormAEndAdress?.formName || this.latLongFormAEndAdress?.formName || this.referenceFormAEndAdress?.formName) {
              this.latLongFormZEndAdress = value;
              this.searchFormZEndAdress = undefined;
              this.referenceFormZEndAdress = undefined;
            } else {
              // this.contactDetailsForm = undefined;

              this.searchFormZEndAdress = undefined;
              this.searchFormA2EndAdress = undefined;
              this.searchFormZ2EndAdress = undefined;

              this.latLongFormZEndAdress = undefined;
              this.latLongFormA2EndAdress = undefined;
              this.latLongFormZ2EndAdress = undefined;

              this.referenceFormZEndAdress = undefined;
              this.referenceFormA2EndAdress = undefined;
              this.referenceFormZ2EndAdress = undefined;
            }
            break;
          case 'a2EndAdress':
            if (
              this.searchFormAEndAdress?.formName ||
              this.latLongFormAEndAdress?.formName ||
              this.referenceFormAEndAdress?.formName ||
              this.searchFormZEndAdress?.formName ||
              this.latLongFormZEndAdress?.formName ||
              this.referenceFormZEndAdress?.formName
            ) {
              this.latLongFormA2EndAdress = value;
              this.searchFormA2EndAdress = undefined;
              this.referenceFormA2EndAdress = undefined;
            } else {
              // this.contactDetailsForm = undefined;

              this.searchFormA2EndAdress = undefined;
              this.searchFormZ2EndAdress = undefined;

              this.latLongFormA2EndAdress = undefined;
              this.latLongFormZ2EndAdress = undefined;

              this.referenceFormA2EndAdress = undefined;
              this.referenceFormZ2EndAdress = undefined;
            }
            break;
          case 'z2EndAdress':
            if (
              this.searchFormAEndAdress?.formName ||
              this.latLongFormAEndAdress?.formName ||
              this.referenceFormAEndAdress?.formName ||
              this.searchFormZEndAdress?.formName ||
              this.latLongFormZEndAdress?.formName ||
              this.referenceFormZEndAdress?.formName ||
              this.searchFormA2EndAdress?.formName
            ) {
              this.latLongFormZ2EndAdress = value;
              this.searchFormZ2EndAdress = undefined;
              this.referenceFormZ2EndAdress = undefined;
            } else {
              // this.contactDetailsForm = undefined;

              this.searchFormZ2EndAdress = undefined;

              this.latLongFormZ2EndAdress = undefined;

              this.referenceFormZ2EndAdress = undefined;
            }
            break;
        }
      }

      if (value?.clearClicked) {
        this.clearQuote();
      }

      if (this.isQuoteSaved) {
        this.isQuoteSaved = false;
      }


      this.isSaveEnabled = (
        this.searchFormAEndAdress?.formName ||
        this.searchFormZEndAdress?.formName ||
        this.searchFormA2EndAdress?.formName ||
        this.searchFormZ2EndAdress?.formName ||

        this.latLongFormAEndAdress?.latitude || this.latLongFormAEndAdress?.longitude ||
        this.latLongFormZEndAdress?.latitude || this.latLongFormZEndAdress?.longitude ||
        this.latLongFormA2EndAdress?.latitude || this.latLongFormA2EndAdress?.longitude ||
        this.latLongFormZ2EndAdress?.latitude || this.latLongFormZ2EndAdress?.longitude ||

        this.referenceFormAEndAdress?.formName ||
        this.referenceFormZEndAdress?.formName ||
        this.referenceFormA2EndAdress?.formName ||
        this.referenceFormZ2EndAdress?.formName
      ) ? true : false;

      // this.isSaveEnabled = (
      //   this.latLongFormAEndAdress?.latitude || this.latLongFormAEndAdress?.longitude ||
      //   this.latLongFormZEndAdress?.latitude || this.latLongFormZEndAdress?.longitude ||
      //   this.latLongFormA2EndAdress?.latitude || this.latLongFormA2EndAdress?.longitude ||
      //   this.latLongFormZ2EndAdress?.latitude || this.latLongFormZ2EndAdress?.longitude
      // ) ? true : false;
    });

    this.subscription4 = this.siteDetailsService.referenceFormMessage.subscribe(value => {
      this.tabChanged = false;
      if (value && value.formName) {
        switch (value.formName) {
          case 'aEndAdress':
            this.referenceFormAEndAdress = value;
            this.latLongFormAEndAdress = undefined;
            this.searchFormAEndAdress = undefined;
            if (!Boolean(this.searchFormAEndAdress?.formName || this.latLongFormAEndAdress?.formName || this.referenceFormAEndAdress?.formName)) {
              this.contactDetailsForm = undefined;

              this.searchFormZEndAdress = undefined;
              this.searchFormA2EndAdress = undefined;
              this.searchFormZ2EndAdress = undefined;

              this.latLongFormZEndAdress = undefined;
              this.latLongFormA2EndAdress = undefined;
              this.latLongFormZ2EndAdress = undefined;

              this.referenceFormZEndAdress = undefined;
              this.referenceFormA2EndAdress = undefined;
              this.referenceFormZ2EndAdress = undefined;
            }

            break;

          case 'zEndAdress':
            if (
              this.searchFormAEndAdress?.formName ||
              this.latLongFormAEndAdress?.formName ||
              this.referenceFormAEndAdress?.formName
            ) {
              this.referenceFormZEndAdress = value;
              this.latLongFormZEndAdress = undefined;
              this.searchFormZEndAdress = undefined;
            } else {
              // this.contactDetailsForm = undefined;

              this.searchFormZEndAdress = undefined;
              this.searchFormA2EndAdress = undefined;
              this.searchFormZ2EndAdress = undefined;

              this.latLongFormZEndAdress = undefined;
              this.latLongFormA2EndAdress = undefined;
              this.latLongFormZ2EndAdress = undefined;

              this.referenceFormZEndAdress = undefined;
              this.referenceFormA2EndAdress = undefined;
              this.referenceFormZ2EndAdress = undefined;
            }
            break;
          case 'a2EndAdress':
            if (
              this.searchFormAEndAdress?.formName ||
              this.latLongFormAEndAdress?.formName ||
              this.referenceFormAEndAdress?.formName ||
              this.searchFormZEndAdress?.formName ||
              this.latLongFormZEndAdress?.formName ||
              this.referenceFormZEndAdress?.formName
            ) {
              this.referenceFormA2EndAdress = value;
              this.latLongFormA2EndAdress = undefined;
              this.searchFormA2EndAdress = undefined;
            } else {
              // this.contactDetailsForm = undefined;

              this.searchFormA2EndAdress = undefined;
              this.searchFormZ2EndAdress = undefined;

              this.latLongFormA2EndAdress = undefined;
              this.latLongFormZ2EndAdress = undefined;

              this.referenceFormA2EndAdress = undefined;
              this.referenceFormZ2EndAdress = undefined;
            }
            break;
          case 'z2EndAdress':
            if (
              this.searchFormAEndAdress?.formName ||
              this.latLongFormAEndAdress?.formName ||
              this.referenceFormAEndAdress?.formName ||
              this.searchFormZEndAdress?.formName ||
              this.latLongFormZEndAdress?.formName ||
              this.referenceFormZEndAdress?.formName ||
              this.searchFormA2EndAdress?.formName
            ) {
              this.referenceFormZ2EndAdress = value;
              this.searchFormZ2EndAdress = undefined;
              this.latLongFormZ2EndAdress = undefined;
            } else {
              // this.contactDetailsForm = undefined;

              this.searchFormZ2EndAdress = undefined;

              this.latLongFormZ2EndAdress = undefined;

              this.referenceFormZ2EndAdress = undefined;
            }
            break;
        }
      }

      if (value?.clearClicked) {
        this.clearQuote();
      }
      if (this.isQuoteSaved) {
        this.isQuoteSaved = false;
      }


      this.isSaveEnabled = (
        this.searchFormAEndAdress?.formName ||
        this.searchFormZEndAdress?.formName ||
        this.searchFormA2EndAdress?.formName ||
        this.searchFormZ2EndAdress?.formName ||

        this.latLongFormAEndAdress?.latitude || this.latLongFormAEndAdress?.longitude ||
        this.latLongFormZEndAdress?.latitude || this.latLongFormZEndAdress?.longitude ||
        this.latLongFormA2EndAdress?.latitude || this.latLongFormA2EndAdress?.longitude ||
        this.latLongFormZ2EndAdress?.latitude || this.latLongFormZ2EndAdress?.longitude ||

        this.referenceFormAEndAdress?.formName ||
        this.referenceFormZEndAdress?.formName ||
        this.referenceFormA2EndAdress?.formName ||
        this.referenceFormZ2EndAdress?.formName
      ) ? true : false;

      // this.isSaveEnabled = (
      //   this.referenceFormAEndAdress?.formName ||
      //   this.referenceFormZEndAdress?.formName ||
      //   this.referenceFormA2EndAdress?.formName ||
      //   this.referenceFormZ2EndAdress?.formName
      // ) ? true : false;
    });

    this.createQuoteService.isModifyQuote.subscribe(val => {
      if (val) {
        this.isModifyQuote = val;
        this.quoteDataToModify = this.createQuotePayload;
        //  this.getModifiedFormValues();
      } else {
        this.isModifyQuote = false
      }
    })

  // get the service type
    this.getProductCatalogDataFromAPI();
  }


  contactFormSubscribe() {
    var i = 0;
    if (!this.subscription1 || this.subscription1?.closed) {
      this.subscription1 = this.siteDetailsService.contactDetailsFormMessage.subscribe(value => {
        // if (value.A1siteContactName && !(this.searchFormAEndAdress?.valid || this.latLongFormAEndAdress?.valid || this.referenceFormAEndAdress?.valid)) {
        //     value = {};
        // }
        // if (i==0){
        this.contactDetailsForm = value;
        if (Object.keys(this.contactDetailsForm).length) {
          this.isContactDetailsFormValid = this.contactDetailsForm.valid;
        }

        if (this.isQuoteSaved) {
          this.isQuoteSaved = false;
        }
        // }

        // i++;
      });
    }
  }

  contactFormUnSubscribe() {

    if (this.subscription1 && !this.subscription1?.closed) {
      this.subscription1.unsubscribe();
    }
  }

  getCommonServices() {
    this.commonService.getDefaultCustomer().pipe().subscribe(resp => {
      if (resp) {
        this.setCustomerDropdown(resp);
      }
    });
  }

  setCustomerDropdown = (selectedHeaderValue?: string) => {
    const profileDetails = this.homepageservice.userProfileDetails;
    if (profileDetails) {
      const custDetails = profileDetails?.data?.customerDetails;
      if (selectedHeaderValue) {
        const findCIDNobject = this.searchCidn(custDetails, selectedHeaderValue)
        this.cidn = findCIDNobject?.cidn;
        localStorage.setItem('selectedHeaderCIDN', this.cidn)
      }
    }
  }

  searchCidn(myArray, nameKey) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].customerName === nameKey) {
        return myArray[i];
      }
    }
  }


  getLabels() {
    this.createQuoteService
      .getCreateQuoteModel()
      .then((data: any) => {
        this.aemLabelAuthoring1 = data;
      })
      .catch((error) => { });
    this.createQuoteService
      .getSiteDetailsPageLabel()
      .then((data: any) => {
        this.aemLabelAuthoring = data;
      })
      .catch((error) => { });
  }

  navigateToStepThree() {
    const productDetailsFormData = this.createQuoteService.productDetailsFormValue;
    const navigationState = window.history.state;
    const navigationExtras = {
      state: {
        formData: productDetailsFormData,
        isModifyFlow: navigationState.isModifyFlow
      }
    }
    if (this.isModifyQuote) {
      this.modifyQuote();
      this.router.navigateByUrl('/request-details', navigationExtras);
    } else {
      this.saveFeasibilityRequest(this.getSaveFeasibilityPayload());
      this.router.navigateByUrl('/request-details');
    }
  }


  navigateBack() {
    const productDetailsFormData = this.createQuoteService.productDetailsFormValue;
    const navigationState = window.history.state;
    const navigationExtras = {
      state: {
        formData: productDetailsFormData,
        isModifyFlow: navigationState.isModifyFlow
      }
    }
    if (this.isModifyQuote) {
      this.modifyQuote(false);
    } else {
      let payload = this.getSaveFeasibilityPayload();
      payload['avoidPlrLogging'] = false;
      this.removeSplChar(payload);
      this.saveFeasibilityRequest(payload);
    }
    this.router.navigateByUrl('/product-details', navigationExtras);
  }


  removeSplChar(payload) {
    const regexOpenAngularBraces = new RegExp('<');
    const regexCloseAngularBraces = new RegExp('>');
    const regexSemiColon = new RegExp(';');

    for (const key in payload) {
      const value = payload[key];
      if (typeof value === "object") {
        this.removeSplChar(value);
      } else {
        const match1 = regexOpenAngularBraces.test(value);
        const match2 = regexCloseAngularBraces.test(value);
        const match3 = regexSemiColon.test(value);
        if (match1 || match2 || match3) {
          payload[key] = '';
        }
      }
    }
  }


  cancel() {
    if (this.isModifyQuote) {
      this.modifyQuote(false);
    } else {
      this.saveFeasibilityRequest(this.getSaveFeasibilityPayload());
    }
    this.router.navigate(['/myQuotes']);
  }

  tabSelected = 'address_search';
  tabSelect(val: string) {
    this.tabSelected = val;
  }

  clickHandle(e) {
    e.preventDefault();
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-bottom-right';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 5000;
    this.options.closeButton = false;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    document.getElementsByClassName('overlay-container')[0]?.setAttribute('role', 'alert');
    document.getElementsByClassName('overlay-container')[0]?.setAttribute('aria-live', 'polite');
  }

  quoteRefId;
  saveFeasibilityRequest(payload, isSaveProduct?: boolean) {

    // NON-RG Payload
    if(payload && this.serviceType !== "RG") {
        this.createQuoteService.nonRGPayloadPrepare(payload, this.serviceType);
    }

    return new Promise((resolve, reject) => {
      this.createQuoteService.saveNewQuote(payload, 'Save').then((res) => {
        this.quoteRefId = res.data.quoteReferenceId;
        // console.log('this.quoteRefId = ', res.data.quoteReferenceId)
        // console.log("payload a end=", payload.aEndAdress)
        // console.log("payload a end=", payload.zEndAdress)
        if (isSaveProduct) {
          this.isQuoteSaved = true;
        }
        // console.log('this.quoteRefId = ', res.data.quoteReferenceId)
        // console.log("payload a end=", payload.aEndAdress)
        // console.log("payload z end=", payload.zEndAdress)
        sessionStorage.setItem('siteDetails', JSON.stringify(payload))
        // this.createQuotePayload = sessionStorage.getItem('productDetailsFormValue');
        resolve(true);
      }).catch((err) => {
        const msg = 'We can’t submit your request at this time. Please refresh and try again later.';
        this.showToasterMessage(msg, '', this.options, 'error');
        reject(err);
      });


    });


  }


  getSaveFeasibilityPayload() {
    let formType;
    if (this.latLongFormAEndAdress?.searchMethod === 'Latitude and Longitude') {
      formType = this.latLongFormAEndAdress;
    } else if (this.searchFormAEndAdress?.searchMethod === 'search') {
      formType = this.searchFormAEndAdress;
    } else if (this.referenceFormAEndAdress?.searchMethod.includes('refrence-')) {
      formType = this.referenceFormAEndAdress;
    }

    this.createQuotePayload = this.createQuoteService.getProductDetailsFormValues();

    // if (this.contactDetailsForm &&
    //   this.contactDetailsForm.detailedAssessment) {
    //   if (this.contactDetailsForm.detailedAssessment == "Yes") {
    //     this.contactDetailsForm.detailedAssessment = (formType?.address?.primaryAddress) ? this.contactDetailsForm?.detailedAssessment : "No";
    //   } else {
    //     this.contactDetailsForm = {};
    //   }
    // }

    const payload1 = {
      aEndAdress: {
        siteType: formType?.formName ? formType?.formName : '',
        searchMethod: formType?.searchMethod ? formType?.searchMethod : '',
        selectedAddress: formType?.address?.primaryAddress ? formType?.address?.primaryAddress : (formType?.address?.address ? formType?.address?.address : ''),
        definedPropertyLocation: formType?.definedPropertyLocation ? formType?.definedPropertyLocation : '',
        additionalAddressInfo: formType?.additionalAddressInfo ? formType?.additionalAddressInfo : '',
        adborId: formType?.address?.adborId ? formType?.address?.adborId : formType?.adborId,
        latitude: formType?.address?.latitude ? formType?.address?.latitude : '',
        longitude: formType?.address?.longitude ? formType?.address?.longitude : '',
        nbnLocId: formType?.address?.nbnLocId ? formType?.address?.nbnLocId : formType?.nbnLocId,
        nodeCode: formType?.address?.nodeCode ? formType?.address?.nodeCode : formType?.nodeCode,
        primaryContactName: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.A1siteContactName ? this.contactDetailsForm.A1siteContactName : '',
        primaryContactNumber: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.A1siteContactNumber ? this.contactDetailsForm.A1siteContactNumber : '',
        primaryContactEmail: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.A1siteContactEmailAddress ? this.contactDetailsForm.A1siteContactEmailAddress : '',
        altContactName: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.A1alternativeSiteContactName ? this.contactDetailsForm.A1alternativeSiteContactName : '',
        altContactNumber: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.A1alternativeSiteContactNumber ? this.contactDetailsForm.A1alternativeSiteContactNumber : '',
        altContactEmail: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.A1alternativeSiteContactEmailAddress ? this.contactDetailsForm.A1alternativeSiteContactEmailAddress : '',
        primaryContactNameUpdated: this.createQuotePayload?.aEndAdress?.primaryContactName ? (this.contactDetailsForm?.A1siteContactName === this.createQuotePayload?.aEndAdress?.primaryContactName) ? false : true : false,
        primaryContactNumberUpdated: this.createQuotePayload?.aEndAdress?.primaryContactNumber ? ((this.contactDetailsForm?.A1siteContactNumber) === this.createQuotePayload?.aEndAdress.primaryContactNumber) ? false : true : false,
        primaryContactEmailUpdated: this.createQuotePayload?.aEndAdress?.primaryContactEmail ? (this.contactDetailsForm?.A1siteContactEmailAddress === this.createQuotePayload?.aEndAdress?.primaryContactEmail) ? false : true : false,
        altContactNameUpdated: this.createQuotePayload?.aEndAdress?.altContactName ? (this.contactDetailsForm?.A1alternativeSiteContactName === this.createQuotePayload?.aEndAdress?.altContactName) ? false : true : false,
        altContactNumberUpdated: this.createQuotePayload?.aEndAdress?.altContactNumber ? ((this.contactDetailsForm?.A1alternativeSiteContactNumber) === this.createQuotePayload?.aEndAdress.altContactNumber) ? false : true : false,
        altContactEmailUpdated: this.createQuotePayload?.aEndAdress?.altContactEmail ? (this.contactDetailsForm?.A1alternativeSiteContactNumber === this.createQuotePayload?.aEndAdress?.altContactEmail) ? false : true : false,
      }
    };
    if (formType?.address?.predefinedSiteNode) {
      payload1['aEndpreDefinedNodeName'] = formType?.address?.predefinedSiteNode || this.createQuotePayload.aEndpreDefinedNodeName;
    } else if (formType?.selectedAddress?.predefinedSiteNode) {
      payload1['aEndpreDefinedNodeName'] = formType?.selectedAddress?.predefinedSiteNode || this.createQuotePayload.aEndpreDefinedNodeName;
    }
    // if (payload1['aEndAdress']['selectedAddress'] && payload1['aEndAdress']['selectedAddress'] === '(New address)') {
    //   payload1['aEndAdress']['selectedAddress'] = payload1['aEndAdress']['latitude'] + ', ' + payload1['aEndAdress']['longitude'];
    // }

    let formType2;
    if (this.latLongFormZEndAdress?.searchMethod === 'Latitude and Longitude') {
      formType2 = this.latLongFormZEndAdress;
    } else if (this.searchFormZEndAdress?.searchMethod === 'search') {
      formType2 = this.searchFormZEndAdress;
    } else if (this.referenceFormZEndAdress && this.referenceFormZEndAdress.searchMethod?.includes('refrence-')) {
      formType2 = this.referenceFormZEndAdress;
    }

    const payload2 = {
      sameAsAEndContact: (this.contactDetailsForm?.detailedAssessment === 'Yes') ? this.contactDetailsForm?.sameAsAEndContact : false,
      zEndAdress: {
        siteType: formType2?.formName ? formType2?.formName : '',
        searchMethod: formType2?.searchMethod ? formType2?.searchMethod : '',
        selectedAddress: formType2?.address?.primaryAddress ? formType2?.address?.primaryAddress : (formType2?.address?.address ? formType2?.address?.address : ''),
        definedPropertyLocation: formType2?.definedPropertyLocation ? formType2?.definedPropertyLocation : '',
        additionalAddressInfo: formType2?.additionalAddressInfo ? formType2?.additionalAddressInfo : '',
        adborId: formType2?.address?.adborId ? formType2?.address?.adborId : formType2?.adborId,
        latitude: formType2?.address?.latitude ? formType2?.address?.latitude : '',
        longitude: formType2?.address?.longitude ? formType2?.address?.longitude : '',
        nbnLocId: formType2?.address?.nbnLocId ? formType2?.address?.nbnLocId : formType2?.nbnLocId,
        nodeCode: formType2?.address?.nodeCode ? formType2?.address?.nodeCode : formType2?.nodeCode,
        primaryContactName: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.Z1siteContactName ? this.contactDetailsForm.Z1siteContactName : '',
        primaryContactNumber: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.Z1siteContactNumber ? this.contactDetailsForm.Z1siteContactNumber : '',
        primaryContactEmail: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.Z1siteContactEmailAddress ? this.contactDetailsForm.Z1siteContactEmailAddress : '',
        altContactName: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.Z1alternativeSiteContactName ? this.contactDetailsForm.Z1alternativeSiteContactName : '',
        altContactNumber: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.Z1alternativeSiteContactNumber ? this.contactDetailsForm.Z1alternativeSiteContactNumber : '',
        altContactEmail: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.Z1alternativeSiteContactEmailAddress ? this.contactDetailsForm.Z1alternativeSiteContactEmailAddress : '',
        primaryContactNameUpdated: this.createQuotePayload?.zEndAdress?.primaryContactName ? (this.contactDetailsForm?.Z1siteContactName === this.createQuotePayload?.zEndAdress?.primaryContactName) ? false : true : false,
        primaryContactNumberUpdated: this.createQuotePayload?.zEndAdress?.primaryContactNumber ? ((this.contactDetailsForm?.Z1siteContactNumber) === this.createQuotePayload?.zEndAdress.primaryContactNumber) ? false : true : false,
        primaryContactEmailUpdated: this.createQuotePayload?.zEndAdress?.primaryContactEmail ? (this.contactDetailsForm?.Z1siteContactEmailAddress === this.createQuotePayload?.zEndAdress?.primaryContactEmail) ? false : true : false,
        altContactNameUpdated: this.createQuotePayload?.zEndAdress?.altContactName ? (this.contactDetailsForm?.Z1alternativeSiteContactName === this.createQuotePayload?.zEndAdress.altContactName) ? false : true : false,
        altContactNumberUpdated: this.createQuotePayload?.zEndAdress?.altContactNumber ? ((this.contactDetailsForm?.Z1alternativeSiteContactNumber) === this.createQuotePayload?.zEndAdress.altContactNumber) ? false : true : false,
        altContactEmailUpdated: this.createQuotePayload?.zEndAdress?.altContactEmail ? (this.contactDetailsForm?.Z1alternativeSiteContactEmailAddress === this.createQuotePayload?.zEndAdress.altContactEmail) ? false : true : false,
      }
    };
    if (formType2?.address?.predefinedSiteNode) {
      payload2['zEndpreDefinedNodeName'] = formType2?.address?.predefinedSiteNode ||  this.createQuotePayload.zEndpreDefinedNodeName;
    } else if (formType2?.selectedAddress?.predefinedSiteNode) {
      payload2['zEndpreDefinedNodeName'] = formType2?.selectedAddress?.predefinedSiteNode ||  this.createQuotePayload.zEndpreDefinedNodeName;
    }



    let formType3;
    if (this.latLongFormA2EndAdress?.valid || this.searchFormA2EndAdress?.valid || this.referenceFormA2EndAdress?.valid) {
      if (this.latLongFormA2EndAdress?.searchMethod === 'Latitude and Longitude' && this.latLongFormA2EndAdress?.valid) {
        formType3 = this.latLongFormA2EndAdress;
      } else if (this.searchFormA2EndAdress?.searchMethod === 'search' && this.searchFormA2EndAdress?.valid) {
        formType3 = this.searchFormA2EndAdress;
      } else if (this.referenceFormA2EndAdress?.searchMethod.includes('refrence-') && this.referenceFormA2EndAdress?.valid) {
        formType3 = this.referenceFormA2EndAdress;
      }
    } else {
      if (this.latLongFormA2EndAdress?.searchMethod === 'Latitude and Longitude') {
        formType3 = this.latLongFormA2EndAdress;
      } else if (this.searchFormA2EndAdress?.searchMethod === 'search') {
        formType3 = this.searchFormA2EndAdress;
      } else if (this.referenceFormA2EndAdress?.searchMethod.includes('refrence-')) {
        formType3 = this.referenceFormA2EndAdress;
      }
    }


    const payload3 = {
      sameAsAEndAddress: formType3?.a2sameAsA1EndAddress,
      sameAsZEndAddress: formType3?.a2sameAsZ1EndAddress,
      path2A2sameAsAEndContact: (this.contactDetailsForm?.detailedAssessment === 'Yes') ? this.contactDetailsForm?.path2A2sameAsAEndContact : false,
      path2A2sameAsZEndContact: (this.contactDetailsForm?.detailedAssessment === 'Yes') ? this.contactDetailsForm?.path2A2sameAsZEndContact : false,
      a2EndAdress: {
        siteType: formType3?.formName ? formType3?.formName : '',
        searchMethod: formType3?.searchMethod ? formType3?.searchMethod : '',
        selectedAddress: formType3?.address?.primaryAddress ? formType3?.address?.primaryAddress : (formType3?.address?.address ? formType3?.address?.address : ''),
        definedPropertyLocation: formType3?.definedPropertyLocation ? formType3?.definedPropertyLocation : '',
        additionalAddressInfo: formType3?.additionalAddressInfo ? formType3?.additionalAddressInfo : '',
        adborId: formType3?.address?.adborId ? formType3?.address?.adborId : formType3?.adborId,
        latitude: formType3?.address?.latitude ? formType3?.address?.latitude : '',
        longitude: formType3?.address?.longitude ? formType3?.address?.longitude : '',
        nbnLocId: formType3?.address?.nbnLocId ? formType3?.address?.nbnLocId : formType3?.nbnLocId,
        nodeCode: formType3?.address?.nodeCode ? formType3?.address?.nodeCode : formType3?.nodeCode,
        primaryContactName: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.A2siteContactName ? this.contactDetailsForm.A2siteContactName : '',
        primaryContactNumber: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.A2siteContactNumber ? this.contactDetailsForm.A2siteContactNumber : '',
        primaryContactEmail: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.A2siteContactEmailAddress ? this.contactDetailsForm.A2siteContactEmailAddress : '',
        altContactName: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.A2alternativeSiteContactName ? this.contactDetailsForm.A2alternativeSiteContactName : '',
        altContactNumber: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.A2alternativeSiteContactNumber ?  this.contactDetailsForm.A2alternativeSiteContactNumber : '',
        altContactEmail: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.A2alternativeSiteContactEmailAddress ? this.contactDetailsForm.A2alternativeSiteContactEmailAddress : '',
        primaryContactNameUpdated: this.createQuotePayload?.a2EndAdress?.primaryContactName ? (this.contactDetailsForm?.A2siteContactName === this.createQuotePayload?.a2EndAdress.primaryContactName) ? false : true : false,
        primaryContactNumberUpdated: this.createQuotePayload?.a2EndAdress?.primaryContactNumber ? ((this.contactDetailsForm?.A2siteContactNumber) === this.createQuotePayload?.a2EndAdress.primaryContactNumber) ? false : true : false,
        primaryContactEmailUpdated: this.createQuotePayload?.a2EndAdress?.primaryContactEmail ? (this.contactDetailsForm?.A2siteContactEmailAddress === this.createQuotePayload?.a2EndAdress.primaryContactEmail) ? false : true : false,
        altContactNameUpdated: this.createQuotePayload?.a2EndAdress?.altContactName ? (this.contactDetailsForm?.A2alternativeSiteContactName === this.createQuotePayload?.a2EndAdress.altContactName) ? false : true : false,
        altContactNumberUpdated: this.createQuotePayload?.a2EndAdress?.altContactNumber ? ((this.contactDetailsForm?.A2alternativeSiteContactNumber) === this.createQuotePayload?.a2EndAdress.altContactNumber) ? false : true : false,
        altContactEmailUpdated: this.createQuotePayload?.a2EndAdress?.altContactEmail ? (this.contactDetailsForm?.A2alternativeSiteContactEmailAddress === this.createQuotePayload?.a2EndAdress.altContactEmail) ? false : true : false,
      }
    };
    if (formType3?.address?.predefinedSiteNode) {
      payload3['a2EndpreDefinedNodeName'] = formType3?.address?.predefinedSiteNode || this.createQuotePayload.a2EndpreDefinedNodeName;
    } else if (formType3?.selectedAddress?.predefinedSiteNode) {
      payload3['a2EndpreDefinedNodeName'] = formType3?.selectedAddress?.predefinedSiteNode ||  this.createQuotePayload.a2EndpreDefinedNodeName;
    }


    let formType4;

    if (this.latLongFormZ2EndAdress?.valid || this.searchFormZ2EndAdress?.valid || this.referenceFormZ2EndAdress?.valid) {
      if (this.latLongFormZ2EndAdress?.searchMethod === 'Latitude and Longitude' && this.latLongFormZ2EndAdress?.valid) {
        formType4 = this.latLongFormZ2EndAdress;
      } else if (this.searchFormZ2EndAdress?.searchMethod === 'search' && this.searchFormZ2EndAdress?.valid) {
        formType4 = this.searchFormZ2EndAdress;
      } else if (this.referenceFormZ2EndAdress?.searchMethod.includes('refrence-') && this.referenceFormZ2EndAdress?.valid) {
        formType4 = this.referenceFormZ2EndAdress;
      }
    } else {
      if (this.latLongFormZ2EndAdress?.searchMethod === 'Latitude and Longitude') {
        formType4 = this.latLongFormZ2EndAdress;
      } else if (this.searchFormZ2EndAdress?.searchMethod === 'search') {
        formType4 = this.searchFormZ2EndAdress;
      } else if (this.referenceFormZ2EndAdress?.searchMethod.includes('refrence-')) {
        formType4 = this.referenceFormZ2EndAdress;
      }
    }




    const payload4 = {
      path2Z2SameAsAEndAddress: formType4?.z2sameAsA1EndAddress,
      path2Z2SameAsZEndAddress: formType4?.z2sameAsZ1EndAddress,

      path2Z2sameAsAEndContact: (this.contactDetailsForm?.detailedAssessment === 'Yes') ? this.contactDetailsForm?.path2Z2sameAsAEndContact : false,
      path2Z2sameAsZEndContact: (this.contactDetailsForm?.detailedAssessment === 'Yes') ? this.contactDetailsForm?.path2Z2sameAsZEndContact : false,
      path2Z2sameAsA2EndContact: (this.contactDetailsForm?.detailedAssessment === 'Yes') ? this.contactDetailsForm?.path2Z2sameAsA2EndContact : false,
      z2EndAdress: {
        siteType: formType4?.formName ? formType4?.formName : '',
        searchMethod: formType4?.searchMethod ? formType4?.searchMethod : '',
        selectedAddress: formType4?.address?.primaryAddress ? formType4?.address?.primaryAddress : (formType4?.address?.address ? formType4?.address?.address : ''),
        definedPropertyLocation: formType4?.definedPropertyLocation ? formType4?.definedPropertyLocation : '',
        additionalAddressInfo: formType4?.additionalAddressInfo ? formType4?.additionalAddressInfo : '',
        adborId: formType4?.address?.adborId ? formType4?.address?.adborId : formType4?.adborId,
        latitude: formType4?.address?.latitude ? formType4?.address?.latitude : '',
        longitude: formType4?.address?.longitude ? formType4?.address?.longitude : '',
        nbnLocId: formType4?.address?.nbnLocId ? formType4?.address?.nbnLocId : formType4?.nbnLocId,
        nodeCode: formType4?.address?.nodeCode ? formType4?.address?.nodeCode : formType4?.nodeCode,
        primaryContactName: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.Z2siteContactName ? this.contactDetailsForm.Z2siteContactName : '',
        primaryContactNumber: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.Z2siteContactNumber ? this.contactDetailsForm.Z2siteContactNumber : '',
        primaryContactEmail: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.Z2siteContactEmailAddress ? this.contactDetailsForm.Z2siteContactEmailAddress : '',
        altContactName: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.Z2alternativeSiteContactName ? this.contactDetailsForm.Z2alternativeSiteContactName : '',
        altContactNumber: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.Z2alternativeSiteContactNumber ?  this.contactDetailsForm.Z2alternativeSiteContactNumber : '',
        altContactEmail: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.Z2alternativeSiteContactEmailAddress ? this.contactDetailsForm.Z2alternativeSiteContactEmailAddress : '',
        primaryContactNameUpdated: this.createQuotePayload?.z2EndAdress?.primaryContactName ? (this.contactDetailsForm?.Z2siteContactName === this.createQuotePayload?.z2EndAdress.primaryContactName) ? false : true : false,
        primaryContactNumberUpdated: this.createQuotePayload?.z2EndAdress?.primaryContactNumber ? ((this.contactDetailsForm?.Z2siteContactNumber) === this.createQuotePayload?.z2EndAdress.primaryContactNumber) ? false : true : false,
        primaryContactEmailUpdated: this.createQuotePayload?.z2EndAdress?.primaryContactEmail ? (this.contactDetailsForm?.Z2siteContactEmailAddress === this.createQuotePayload?.z2EndAdress.primaryContactEmail) ? false : true : false,
        altContactNameUpdated: this.createQuotePayload?.z2EndAdress?.altContactName ? (this.contactDetailsForm?.Z2alternativeSiteContactName === this.createQuotePayload?.z2EndAdress.altContactName) ? false : true : false,
        altContactNumberUpdated: this.createQuotePayload?.z2EndAdress?.altContactNumber ? ((this.contactDetailsForm?.Z2alternativeSiteContactNumber) === this.createQuotePayload?.z2EndAdress.altContactNumber) ? false : true : false,
        altContactEmailUpdated: this.createQuotePayload?.z2EndAdress?.altContactEmail ? (this.contactDetailsForm?.Z2alternativeSiteContactEmailAddress === this.createQuotePayload?.z2EndAdress.altContactEmail) ? false : true : false,
      }
    };
    if (formType4?.address?.predefinedSiteNode) {
      payload4['z2EndpreDefinedNodeName'] = formType4?.address?.predefinedSiteNode  || this.createQuotePayload.z2EndpreDefinedNodeName;
    } else if (formType4?.selectedAddress?.predefinedSiteNode) {
      payload4['z2EndpreDefinedNodeName'] = formType4?.selectedAddress?.predefinedSiteNode  || this.createQuotePayload.z2EndpreDefinedNodeName;
    }

    const payload5 = {
      feasibilityRequestType: (this.contactDetailsForm?.detailedAssessment === 'Yes') ? "Detailed" : "",
      fundingSource: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.fundingSource ? this.contactDetailsForm.fundingSource : '',
      l3WBSCodeCapex: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.l3WBSCodeCapex ? this.contactDetailsForm.l3WBSCodeCapex : '',
      l3WBSCodeOpex: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.l3WBSCodeOpex ? this.contactDetailsForm.l3WBSCodeOpex : '',
      otherPowImc: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.otherPowImc ? this.contactDetailsForm.otherPowImc : '',
      preApprovalCost: (this.contactDetailsForm?.detailedAssessment === 'Yes') && this.contactDetailsForm?.preApprovalCost ? this.contactDetailsForm.preApprovalCost : '',
      predefinedQuote: this.createQuotePayload?.predefinedQuote ? this.createQuotePayload?.predefinedQuote : 'No'
    }
    const payload6 = {
      cidn: this.createQuotePayload?.cidn,
      productName: this.createQuotePayload?.productName,
      avoidPlrLogging: true,
      customerName: this.createQuotePayload?.customerName,
      quoteReferenceId: this.quoteRefId,
      doYouRequireDiversity: this.createQuotePayload?.doYouRequireDiversity,
      describeYourDiversity: this.createQuotePayload?.describeYourDiversity,
      aEndLeadIn: this.createQuotePayload?.aEndLeadIn,
      zEndLeadIn: this.createQuotePayload?.zEndLeadIn,
      existingInfracoServiceInfo: this.createQuotePayload?.existingInfracoServiceInfo,
      thirdPartyPathInfo: this.createQuotePayload?.thirdPartyPathInfo,
      linkRequirement: this.createQuotePayload?.linkRequirement,
      quantityOfService: this.createQuotePayload?.quantityOfService,
      terms: this.createQuotePayload?.terms,
      customerNotes: this.createQuotePayload?.customerNotes,
      customerReference: this.createQuotePayload?.customerReference,
      businessDriver: this.createQuotePayload?.businessDriver,
      responseRequiredDate: this.createQuotePayload?.responseRequiredDate,
      requestorConstraints: this.createQuotePayload?.requestorConstraints,
      preferredMaxDistance: this.createQuotePayload?.preferredMaxDistance,
      customerType1: this.createQuotePayload?.customerType1,
      externalReference1: this.createQuotePayload?.externalReference1,
      maximumOpticalLossDropDown1: this.createQuotePayload?.maximumOpticalLossDropDown1,
      maximumOpticalLossDropDownValue1: this.createQuotePayload?.maximumOpticalLossDropDownValue1,
      externalReference: this.createQuotePayload?.externalReference,
      maxAllowableOpticalLoss: this.createQuotePayload?.maxAllowableOpticalLoss,
      aEndAdressSiteAlternativeName: this.createQuotePayload?.aEndAdressSiteAlternativeName,
      zEndAdressSiteAlternativeName: this.createQuotePayload?.zEndAdressSiteAlternativeName,
      a2EndAdressSiteAlternativeName: this.createQuotePayload?.a2EndAdressSiteAlternativeName,
      z2EndAdressSiteAlternativeName: this.createQuotePayload?.z2EndAdressSiteAlternativeName,
    }
    let payload = { ...payload1, ...payload2, ...payload3, ...payload4, ...payload5, ...payload6 };

    // console.log('Payload', payload)

    this.modifiedDataPayload = payload;
    // console.log('this.modifiedDataPayload', this.modifiedDataPayload)
    return payload;
  }

  saveQuote() {
    this.saveQuoteShow=true;
     setTimeout(() => {
      this.saveQuoteShow = false;
    }, 5000);
    this.isQuoteSaved = true;
    if (this.isModifyQuote) {
      this.modifyQuote(true);
    } else {
      this.saveFeasibilityRequest(this.getSaveFeasibilityPayload(), true);
    }

    this.reInitData();

  }

  clearClicked = false;
  clearQuote() {
    if (!this.isModifyQuote) {
      this.saveFeasibilityRequest(this.getSaveFeasibilityPayload());
    }
  }

  onTabChange(ev) {
    // console.log('ev =', ev);
    this.tabChanged = true;

    if (ev && ev.formName) {
      switch (ev.formName) {
        case "aEndAdress":
          this.searchFormAEndAdress = undefined;
          this.latLongFormAEndAdress = undefined;
          this.referenceFormAEndAdress = undefined;

          this.contactDetailsForm = undefined;

          this.searchFormZEndAdress = undefined;
          this.searchFormA2EndAdress = undefined;
          this.searchFormZ2EndAdress = undefined;

          this.latLongFormZEndAdress = undefined;
          this.latLongFormA2EndAdress = undefined;
          this.latLongFormZ2EndAdress = undefined;

          this.referenceFormZEndAdress = undefined;
          this.referenceFormA2EndAdress = undefined;
          this.referenceFormZ2EndAdress = undefined;

          break;
        case "zEndAdress":
          this.contactDetailsForm = undefined;

          this.searchFormZEndAdress = undefined;
          this.searchFormA2EndAdress = undefined;
          this.searchFormZ2EndAdress = undefined;

          this.latLongFormZEndAdress = undefined;
          this.latLongFormA2EndAdress = undefined;
          this.latLongFormZ2EndAdress = undefined;

          this.referenceFormZEndAdress = undefined;
          this.referenceFormA2EndAdress = undefined;
          this.referenceFormZ2EndAdress = undefined;
          break;
        case "a2EndAdress":
          this.contactDetailsForm = undefined;

          this.searchFormA2EndAdress = undefined;
          this.searchFormZ2EndAdress = undefined;

          this.latLongFormA2EndAdress = undefined;
          this.latLongFormZ2EndAdress = undefined;

          this.referenceFormA2EndAdress = undefined;
          this.referenceFormZ2EndAdress = undefined;
          break;
        case "z2EndAdress":
          this.contactDetailsForm = undefined;
          this.searchFormZ2EndAdress = undefined;
          this.latLongFormZ2EndAdress = undefined;
          this.referenceFormZ2EndAdress = undefined;
          break;
      }
      // this.clearQuote();
    }

  }


  ngOnDestroy(): void {
    if (this.subscription1) {
      this.subscription1.unsubscribe();
    }
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
    if (this.subscription3) {
      this.subscription3.unsubscribe();
    }

    if (this.subscription4) {
      this.subscription4.unsubscribe();
    }

    // if(this.subscriptionIsNewQuoteButtonClicked){
    //   this.subscriptionIsNewQuoteButtonClicked.unsubscribe();
    // }

    if (this.siteHeaderChange) {
      this.siteHeaderChange.unsubscribe();
    }
  }

  isContactFormEligible() {
    let isContactFormEligible = false;
    if (this.createQuotePayload.doYouRequireDiversity == "Yes" && this.createQuotePayload.describeYourDiversity === 'All new services with diversity') {
      if ((this.searchFormAEndAdress?.valid || this.latLongFormAEndAdress?.valid || this.referenceFormAEndAdress?.valid)
        && (this.searchFormZEndAdress?.valid || this.latLongFormZEndAdress?.valid || this.referenceFormZEndAdress?.valid)
        && (this.searchFormA2EndAdress?.valid || this.latLongFormA2EndAdress?.valid || this.referenceFormA2EndAdress?.valid)
        && (this.searchFormZ2EndAdress?.valid || this.latLongFormZ2EndAdress?.valid || this.referenceFormZ2EndAdress?.valid)
      ) {
        isContactFormEligible = true;
      }
    } else {
      if ((this.searchFormAEndAdress?.valid || this.latLongFormAEndAdress?.valid || this.referenceFormAEndAdress?.valid)
        && (this.searchFormZEndAdress?.valid || this.latLongFormZEndAdress?.valid || this.referenceFormZEndAdress?.valid)) {
        isContactFormEligible = true;
      }
    }

    // if (isContactFormEligible){
    //   this.contactFormSubscribe();
    // }
    return isContactFormEligible;
  }

  // isSaveEnabled() {
  //   console.log('!!!!!!', this.searchFormAEndAdress);
  //   console.log("@@@@@@", this.latLongFormAEndAdress);
  //   console.log("%%%%%%%%", this.referenceFormAEndAdress);

  //   return (
  //       this.searchFormAEndAdress?.formName ||
  //       this.searchFormZEndAdress?.formName ||
  //       this.searchFormA2EndAdress?.formName ||
  //       this.searchFormZ2EndAdress?.formName ||

  //       this.latLongFormAEndAdress?.latitude || this.latLongFormAEndAdress?.longitude ||
  //       this.latLongFormZEndAdress?.latitude || this.latLongFormZEndAdress?.longitude ||
  //       this.latLongFormA2EndAdress?.latitude || this.latLongFormA2EndAdress?.longitude ||
  //       this.latLongFormZ2EndAdress?.latitude || this.latLongFormZ2EndAdress?.longitude ||

  //       this.referenceFormAEndAdress?.rawSearchText ||
  //       this.referenceFormZEndAdress?.rawSearchText ||
  //       this.referenceFormA2EndAdress?.rawSearchText ||
  //       this.referenceFormZ2EndAdress?.rawSearchText
  //     ) ? true : false;
  // }

  modifyQuote(plr?) {
    this.getSaveFeasibilityPayload();
    const payload1 = this.compareObject(this.quoteDataToModify, this.modifiedDataPayload);
    this.modifiedFormFields = payload1;
    const pathSameKey = ['path2Z2SameAsAEndAddress', 'path2Z2SameAsZEndAddress', 'path2A2sameAsAEndContact', 'path2A2sameAsZEndContact', 'path2Z2sameAsAEndContact', 'path2Z2sameAsZEndContact', 'path2Z2sameAsA2EndContact', 'sameAsAEndAddress', 'sameAsAEndContact', 'sameAsAEndAddress', 'sameAsZEndAddress'];
    for (const key of pathSameKey) {
      this.modifiedDataPayload[key] = this.modifiedDataPayload[key] ? 'Yes' : 'No'
      this.modifiedFormFields[key] = this.modifiedFormFields[key] ? 'Yes' : 'No';
      if (this.modifiedFormFields[key] === this.quoteDataToModify[key]) {
        delete this.modifiedFormFields[key]
      }
    }
    if (payload1.hasOwnProperty('aEndAdress')) {
      this.getModifiedContactDetails('aEndAdress', 'primaryContactName')
      this.getModifiedContactDetails('aEndAdress', 'primaryContactNumber')
      this.getModifiedContactDetails('aEndAdress', 'primaryContactEmail')
      this.getModifiedContactDetails('aEndAdress', 'altContactName')
      this.getModifiedContactDetails('aEndAdress', 'altContactNumber')
      this.getModifiedContactDetails('aEndAdress', 'altContactEmail')
      this.modifiedFormFields['aEndAdress'] = this.modifiedDataPayload['aEndAdress']
      this.contactDetailsUpdated('aEndAdress', 'primaryContactName')
      this.contactDetailsUpdated('aEndAdress', 'primaryContactNumber')
      this.contactDetailsUpdated('aEndAdress', 'primaryContactEmail')
      this.contactDetailsUpdated('aEndAdress', 'altContactName')
      this.contactDetailsUpdated('aEndAdress', 'altContactNumber')
      this.contactDetailsUpdated('aEndAdress', 'altContactEmail')
      this.getPredefinedNodeNameForModify('aEndAdress', 'aEndpreDefinedNodeName');
    }
    if (payload1.hasOwnProperty('zEndAdress')) {
      this.getModifiedContactDetails('zEndAdress', 'primaryContactName')
      this.getModifiedContactDetails('zEndAdress', 'primaryContactNumber')
      this.getModifiedContactDetails('zEndAdress', 'primaryContactEmail')
      this.getModifiedContactDetails('zEndAdress', 'altContactName')
      this.getModifiedContactDetails('zEndAdress', 'altContactNumber')
      this.getModifiedContactDetails('zEndAdress', 'altContactEmail')
      this.modifiedFormFields['zEndAdress'] = this.modifiedDataPayload['zEndAdress']
      this.contactDetailsUpdated('zEndAdress', 'primaryContactName')
      this.contactDetailsUpdated('zEndAdress', 'primaryContactNumber')
      this.contactDetailsUpdated('zEndAdress', 'primaryContactEmail')
      this.contactDetailsUpdated('zEndAdress', 'altContactName')
      this.contactDetailsUpdated('zEndAdress', 'altContactNumber')
      this.contactDetailsUpdated('zEndAdress', 'altContactEmail')
      this.getPredefinedNodeNameForModify('zEndAdress', 'zEndpreDefinedNodeName');
    }
    if (payload1.hasOwnProperty('a2EndAdress')) {
      this.getModifiedContactDetails('a2EndAdress', 'primaryContactName')
      this.getModifiedContactDetails('a2EndAdress', 'primaryContactNumber')
      this.getModifiedContactDetails('a2EndAdress', 'primaryContactEmail')
      this.getModifiedContactDetails('a2EndAdress', 'altContactName')
      this.getModifiedContactDetails('a2EndAdress', 'altContactNumber')
      this.getModifiedContactDetails('a2EndAdress', 'altContactEmail')
      this.modifiedFormFields['a2EndAdress'] = this.modifiedDataPayload['a2EndAdress']
      this.contactDetailsUpdated('a2EndAdress', 'primaryContactName')
      this.contactDetailsUpdated('a2EndAdress', 'primaryContactNumber')
      this.contactDetailsUpdated('a2EndAdress', 'primaryContactEmail')
      this.contactDetailsUpdated('a2EndAdress', 'altContactName')
      this.contactDetailsUpdated('a2EndAdress', 'altContactNumber')
      this.contactDetailsUpdated('a2EndAdress', 'altContactEmail')
      this.getPredefinedNodeNameForModify('a2EndAdress', 'a2EndpreDefinedNodeName');
    }
    if (payload1.hasOwnProperty('z2EndAdress')) {
      this.getModifiedContactDetails('z2EndAdress', 'primaryContactName')
      this.getModifiedContactDetails('z2EndAdress', 'primaryContactNumber')
      this.getModifiedContactDetails('z2EndAdress', 'primaryContactEmail')
      this.getModifiedContactDetails('z2EndAdress', 'altContactName')
      this.getModifiedContactDetails('z2EndAdress', 'altContactNumber')
      this.getModifiedContactDetails('z2EndAdress', 'altContactEmail')
      this.modifiedFormFields['z2EndAdress'] = this.modifiedDataPayload['z2EndAdress']
      this.contactDetailsUpdated('z2EndAdress', 'primaryContactName')
      this.contactDetailsUpdated('z2EndAdress', 'primaryContactNumber')
      this.contactDetailsUpdated('z2EndAdress', 'primaryContactEmail')
      this.contactDetailsUpdated('z2EndAdress', 'altContactName')
      this.contactDetailsUpdated('z2EndAdress', 'altContactNumber')
      this.contactDetailsUpdated('z2EndAdress', 'altContactEmail')
      this.getPredefinedNodeNameForModify('z2EndAdress', 'z2EndpreDefinedNodeName');
    }
    this.modifiedDataPayload['feasibilityRequestType'] = this.quoteDataToModify['feasibilityRequestType'];
    this.modifiedDataPayload['fundingSource'] = this.quoteDataToModify['fundingSource'];
    this.modifiedDataPayload['l3WBSCodeCapex'] = this.quoteDataToModify['l3WBSCodeCapex'];
    this.modifiedDataPayload['l3WBSCodeOpex'] = this.quoteDataToModify['l3WBSCodeOpex'];
    this.modifiedDataPayload['otherPowImc'] = this.quoteDataToModify['otherPowImc'];
    this.modifiedDataPayload['preApprovalCost'] = this.quoteDataToModify['preApprovalCost'];

    delete this.modifiedFormFields['feasibilityRequestType'];
    delete this.modifiedFormFields['fundingSource'];
    delete this.modifiedFormFields['l3WBSCodeCapex'];
    delete this.modifiedFormFields['l3WBSCodeOpex'];
    delete this.modifiedFormFields['otherPowImc'];
    delete this.modifiedFormFields['preApprovalCost'];
    //  let dataToSave = this.getSaveFeasibilityPayload();

    const navigationState = window.history.state;
    if (navigationState.isModifyFlow) {
      this.modifiedDataPayload['responseRequiredDate'] = this.quoteDataToModify['responseRequiredDate']
    }
    this.createQuoteService.setProductDetailsFormValues(this.modifiedDataPayload);
    const payload = {
      action: 'Save',
      cidn: this.cidn,
      avoidPlrLogging: plr,
      quoteReferenceId: this.quoteRefId,
      modifiedDetails: Object.keys(this.modifiedFormFields),
      ...this.modifiedFormFields
    }
    // console.log(payload)
    return new Promise((resolve, reject) => {
      this.createQuoteService.modifyFeasibilityRequest(payload).then((res) => {
        resolve(true);
      }).catch((err) => {
        const msg = 'We can’t submit your request at this time. Please refresh and try again later.';
        this.showToasterMessage(msg, '', this.options, 'error');
        reject(err);
      });
    })
  }

  getModifiedContactDetails(addressType, contactField) {
    if (!this.contactDetailsForm || this.contactDetailsForm?.length === 0) {
      this.modifiedDataPayload['feasibilityRequestType'] = this.quoteDataToModify['feasibilityRequestType'];
      this.modifiedDataPayload['fundingSource'] = this.quoteDataToModify['fundingSource'];
      this.modifiedDataPayload['l3WBSCodeCapex'] = this.quoteDataToModify['l3WBSCodeCapex'];
      this.modifiedDataPayload['l3WBSCodeOpex'] = this.quoteDataToModify['l3WBSCodeOpex'];
      this.modifiedDataPayload['otherPowImc'] = this.quoteDataToModify['otherPowImc'];
      this.modifiedDataPayload['preApprovalCost'] = this.quoteDataToModify['preApprovalCost'];
      this.modifiedDataPayload[addressType][contactField] = this.quoteDataToModify[addressType][contactField]
      this.modifiedDataPayload['sameAsAEndContact'] = this.quoteDataToModify['sameAsAEndContact'];
      this.modifiedDataPayload['path2A2sameAsZEndContact'] = this.quoteDataToModify['path2A2sameAsZEndContact'];
      this.modifiedDataPayload['path2Z2sameAsAEndContact'] = this.quoteDataToModify['path2Z2sameAsAEndContact'];
      this.modifiedDataPayload['path2Z2sameAsZEndContact'] = this.quoteDataToModify['path2Z2sameAsZEndContact'];
      this.modifiedDataPayload['path2Z2sameAsA2EndContact'] = this.quoteDataToModify['path2Z2sameAsA2EndContact'];
    }
  }

  contactDetailsUpdated(addressType, contactField) {
    const generateKey = `${contactField}Updated`
    if (this.modifiedFormFields[addressType][contactField] !== this.quoteDataToModify[addressType][contactField]) {

      this.modifiedFormFields[addressType][generateKey] = true;
    } else {
      this.modifiedFormFields[addressType][generateKey] = false;
    }
  }

  getPredefinedNodeNameForModify(addressType, nodeName) {
    this.modifiedFormFields[nodeName] = this.modifiedFormFields[addressType]['definedPropertyLocation'] ? this.modifiedFormFields[addressType]['definedPropertyLocation'] : '';
    // if (this.modifiedFormFields[nodeName] === this.quoteDataToModify[nodeName]) {
    //   delete this.modifiedFormFields[nodeName];
    // }
  }

  compareObject(initialObj, modifiedObj, parentKey = '') {
    const modifiedValues = {};

    for (const key in initialObj) {
      const currentValue = initialObj[key];
      const modifiedValue = modifiedObj[key];

      if (typeof currentValue === 'object' && typeof modifiedValue === 'object') {
        const nestedChanges = this.compareObject(currentValue, modifiedValue);
        // console.log(nestedChanges);
        if (Object.keys(nestedChanges).length > 0) {
          modifiedValues[key] = nestedChanges;
        }
      } else if (currentValue !== modifiedValue) {
        modifiedValues[key] = modifiedValue;
      } else if (typeof currentValue === 'object' && modifiedValue === undefined) {
        modifiedValues[key] = modifiedValue;
      }
    }
    return modifiedValues;
  }


  getProductCatalogDataFromAPI(params?: any) {
    const param = params || null;

    return new Promise((resolve, reject) => {
      this.createQuoteService.getProductCatalogDataFromAPI(param, this.cidn).then((res) => {
        if(res && res.serviceType){
          this.serviceType = res.serviceType;
        }
        resolve(true);
      }).catch((err) => {
        const msg = err?.error?.message ? err.error.message : 'Something went wrong';
        this.showToasterMessage(msg, '', this.options, 'error');
        reject(err);
      });

    });
  }

  reInitData(){

    this.getLabels();
    this.createQuotePayload = this.createQuoteService.getProductDetailsFormValues();
    // console.log(this.createQuotePayload);
    this.quoteRefId = this.createQuotePayload?.quoteReferenceId;
    if (!this.createQuotePayload?.quoteReferenceId) {
      this.createQuotePayload = sessionStorage.getItem('productDetailsFormValue');
      this.quoteRefId = this.createQuotePayload?.quoteReferenceId;
    }


    this.subscription2 = this.siteDetailsService.searchFormMessage.subscribe(value => {
      this.tabChanged = false;

      if (value && value.formName) {
        switch (value.formName) {
          case "aEndAdress":
            this.searchFormAEndAdress = value;
            this.latLongFormAEndAdress = undefined;
            this.referenceFormAEndAdress = undefined;
            if (!Boolean(this.searchFormAEndAdress?.valid || this.latLongFormAEndAdress?.valid || this.referenceFormAEndAdress?.valid)) {
              this.contactDetailsForm = undefined;

              this.searchFormZEndAdress = undefined;
              this.searchFormA2EndAdress = undefined;
              this.searchFormZ2EndAdress = undefined;

              this.latLongFormZEndAdress = undefined;
              this.latLongFormA2EndAdress = undefined;
              this.latLongFormZ2EndAdress = undefined;

              this.referenceFormZEndAdress = undefined;
              this.referenceFormA2EndAdress = undefined;
              this.referenceFormZ2EndAdress = undefined;
            }

            break;
          case "zEndAdress":
            if (this.searchFormAEndAdress?.formName || this.latLongFormAEndAdress?.formName || this.referenceFormAEndAdress?.formName) {
              this.searchFormZEndAdress = value;
              this.latLongFormZEndAdress = undefined;
              this.referenceFormZEndAdress = undefined;
            } else {
              // this.contactDetailsForm = undefined;

              this.searchFormZEndAdress = undefined;
              this.searchFormA2EndAdress = undefined;
              this.searchFormZ2EndAdress = undefined;

              this.latLongFormZEndAdress = undefined;
              this.latLongFormA2EndAdress = undefined;
              this.latLongFormZ2EndAdress = undefined;

              this.referenceFormZEndAdress = undefined;
              this.referenceFormA2EndAdress = undefined;
              this.referenceFormZ2EndAdress = undefined;
            }
            break;
          case "a2EndAdress":
            if (
              this.searchFormAEndAdress?.formName || this.latLongFormAEndAdress?.formName || this.referenceFormAEndAdress?.formName
              || this.searchFormZEndAdress?.formName || this.latLongFormZEndAdress?.formName || this.referenceFormZEndAdress?.formName
            ) {
              this.searchFormA2EndAdress = value;
              this.latLongFormA2EndAdress = undefined;
              this.referenceFormA2EndAdress = undefined;
            } else {
              // this.contactDetailsForm = undefined;

              this.searchFormA2EndAdress = undefined;
              this.searchFormZ2EndAdress = undefined;

              this.latLongFormA2EndAdress = undefined;
              this.latLongFormZ2EndAdress = undefined;

              this.referenceFormA2EndAdress = undefined;
              this.referenceFormZ2EndAdress = undefined;
            }
            break;
          case "z2EndAdress":
            if (
              this.searchFormAEndAdress?.formName || this.latLongFormAEndAdress?.formName || this.referenceFormAEndAdress?.formName
              || this.searchFormZEndAdress?.formName || this.latLongFormZEndAdress?.formName || this.referenceFormZEndAdress?.formName
              || this.searchFormA2EndAdress?.formName
            ) {
              this.searchFormZ2EndAdress = value;
              this.latLongFormZ2EndAdress = undefined;
              this.referenceFormZ2EndAdress = undefined;
            } else {
              // this.contactDetailsForm = undefined;

              this.searchFormZ2EndAdress = undefined;

              this.latLongFormZ2EndAdress = undefined;

              this.referenceFormZ2EndAdress = undefined;
            }
            break;
        }
      }

      if (this.isQuoteSaved) {
        this.isQuoteSaved = false;
      }



      this.isSaveEnabled = (
        this.searchFormAEndAdress?.formName ||
        this.searchFormZEndAdress?.formName ||
        this.searchFormA2EndAdress?.formName ||
        this.searchFormZ2EndAdress?.formName ||

        this.latLongFormAEndAdress?.latitude || this.latLongFormAEndAdress?.longitude ||
        this.latLongFormZEndAdress?.latitude || this.latLongFormZEndAdress?.longitude ||
        this.latLongFormA2EndAdress?.latitude || this.latLongFormA2EndAdress?.longitude ||
        this.latLongFormZ2EndAdress?.latitude || this.latLongFormZ2EndAdress?.longitude ||

        this.referenceFormAEndAdress?.formName ||
        this.referenceFormZEndAdress?.formName ||
        this.referenceFormA2EndAdress?.formName ||
        this.referenceFormZ2EndAdress?.formName
      ) ? true : false;
    });

    this.subscription3 = this.siteDetailsService.latLongFormMessage.subscribe(value => {
      this.tabChanged = false;
      if (value && value.formName) {
        switch (value.formName) {
          case "aEndAdress":
            this.latLongFormAEndAdress = value;
            this.searchFormAEndAdress = undefined;
            this.referenceFormAEndAdress = undefined;

            if (!Boolean(this.searchFormAEndAdress?.formName || this.latLongFormAEndAdress?.formName || this.referenceFormAEndAdress?.formName)) {
              this.contactDetailsForm = undefined;

              this.searchFormZEndAdress = undefined;
              this.searchFormA2EndAdress = undefined;
              this.searchFormZ2EndAdress = undefined;

              this.latLongFormZEndAdress = undefined;
              this.latLongFormA2EndAdress = undefined;
              this.latLongFormZ2EndAdress = undefined;

              this.referenceFormZEndAdress = undefined;
              this.referenceFormA2EndAdress = undefined;
              this.referenceFormZ2EndAdress = undefined;
            }

            break;

          case "zEndAdress":
            if (this.searchFormAEndAdress?.formName || this.latLongFormAEndAdress?.formName || this.referenceFormAEndAdress?.formName) {
              this.latLongFormZEndAdress = value;
              this.searchFormZEndAdress = undefined;
              this.referenceFormZEndAdress = undefined;
            } else {
              // this.contactDetailsForm = undefined;

              this.searchFormZEndAdress = undefined;
              this.searchFormA2EndAdress = undefined;
              this.searchFormZ2EndAdress = undefined;

              this.latLongFormZEndAdress = undefined;
              this.latLongFormA2EndAdress = undefined;
              this.latLongFormZ2EndAdress = undefined;

              this.referenceFormZEndAdress = undefined;
              this.referenceFormA2EndAdress = undefined;
              this.referenceFormZ2EndAdress = undefined;
            }
            break;
          case 'a2EndAdress':
            if (
              this.searchFormAEndAdress?.formName ||
              this.latLongFormAEndAdress?.formName ||
              this.referenceFormAEndAdress?.formName ||
              this.searchFormZEndAdress?.formName ||
              this.latLongFormZEndAdress?.formName ||
              this.referenceFormZEndAdress?.formName
            ) {
              this.latLongFormA2EndAdress = value;
              this.searchFormA2EndAdress = undefined;
              this.referenceFormA2EndAdress = undefined;
            } else {
              // this.contactDetailsForm = undefined;

              this.searchFormA2EndAdress = undefined;
              this.searchFormZ2EndAdress = undefined;

              this.latLongFormA2EndAdress = undefined;
              this.latLongFormZ2EndAdress = undefined;

              this.referenceFormA2EndAdress = undefined;
              this.referenceFormZ2EndAdress = undefined;
            }
            break;
          case 'z2EndAdress':
            if (
              this.searchFormAEndAdress?.formName ||
              this.latLongFormAEndAdress?.formName ||
              this.referenceFormAEndAdress?.formName ||
              this.searchFormZEndAdress?.formName ||
              this.latLongFormZEndAdress?.formName ||
              this.referenceFormZEndAdress?.formName ||
              this.searchFormA2EndAdress?.formName
            ) {
              this.latLongFormZ2EndAdress = value;
              this.searchFormZ2EndAdress = undefined;
              this.referenceFormZ2EndAdress = undefined;
            } else {
              // this.contactDetailsForm = undefined;

              this.searchFormZ2EndAdress = undefined;

              this.latLongFormZ2EndAdress = undefined;

              this.referenceFormZ2EndAdress = undefined;
            }
            break;
        }
      }

      if (value?.clearClicked) {
        this.clearQuote();
      }

      if (this.isQuoteSaved) {
        this.isQuoteSaved = false;
      }


      this.isSaveEnabled = (
        this.searchFormAEndAdress?.formName ||
        this.searchFormZEndAdress?.formName ||
        this.searchFormA2EndAdress?.formName ||
        this.searchFormZ2EndAdress?.formName ||

        this.latLongFormAEndAdress?.latitude || this.latLongFormAEndAdress?.longitude ||
        this.latLongFormZEndAdress?.latitude || this.latLongFormZEndAdress?.longitude ||
        this.latLongFormA2EndAdress?.latitude || this.latLongFormA2EndAdress?.longitude ||
        this.latLongFormZ2EndAdress?.latitude || this.latLongFormZ2EndAdress?.longitude ||

        this.referenceFormAEndAdress?.formName ||
        this.referenceFormZEndAdress?.formName ||
        this.referenceFormA2EndAdress?.formName ||
        this.referenceFormZ2EndAdress?.formName
      ) ? true : false;

      // this.isSaveEnabled = (
      //   this.latLongFormAEndAdress?.latitude || this.latLongFormAEndAdress?.longitude ||
      //   this.latLongFormZEndAdress?.latitude || this.latLongFormZEndAdress?.longitude ||
      //   this.latLongFormA2EndAdress?.latitude || this.latLongFormA2EndAdress?.longitude ||
      //   this.latLongFormZ2EndAdress?.latitude || this.latLongFormZ2EndAdress?.longitude
      // ) ? true : false;
    });

    this.subscription4 = this.siteDetailsService.referenceFormMessage.subscribe(value => {
      this.tabChanged = false;
      if (value && value.formName) {
        switch (value.formName) {
          case 'aEndAdress':
            this.referenceFormAEndAdress = value;
            this.latLongFormAEndAdress = undefined;
            this.searchFormAEndAdress = undefined;
            if (!Boolean(this.searchFormAEndAdress?.formName || this.latLongFormAEndAdress?.formName || this.referenceFormAEndAdress?.formName)) {
              this.contactDetailsForm = undefined;

              this.searchFormZEndAdress = undefined;
              this.searchFormA2EndAdress = undefined;
              this.searchFormZ2EndAdress = undefined;

              this.latLongFormZEndAdress = undefined;
              this.latLongFormA2EndAdress = undefined;
              this.latLongFormZ2EndAdress = undefined;

              this.referenceFormZEndAdress = undefined;
              this.referenceFormA2EndAdress = undefined;
              this.referenceFormZ2EndAdress = undefined;
            }

            break;

          case 'zEndAdress':
            if (
              this.searchFormAEndAdress?.formName ||
              this.latLongFormAEndAdress?.formName ||
              this.referenceFormAEndAdress?.formName
            ) {
              this.referenceFormZEndAdress = value;
              this.latLongFormZEndAdress = undefined;
              this.searchFormZEndAdress = undefined;
            } else {
              // this.contactDetailsForm = undefined;

              this.searchFormZEndAdress = undefined;
              this.searchFormA2EndAdress = undefined;
              this.searchFormZ2EndAdress = undefined;

              this.latLongFormZEndAdress = undefined;
              this.latLongFormA2EndAdress = undefined;
              this.latLongFormZ2EndAdress = undefined;

              this.referenceFormZEndAdress = undefined;
              this.referenceFormA2EndAdress = undefined;
              this.referenceFormZ2EndAdress = undefined;
            }
            break;
          case 'a2EndAdress':
            if (
              this.searchFormAEndAdress?.formName ||
              this.latLongFormAEndAdress?.formName ||
              this.referenceFormAEndAdress?.formName ||
              this.searchFormZEndAdress?.formName ||
              this.latLongFormZEndAdress?.formName ||
              this.referenceFormZEndAdress?.formName
            ) {
              this.referenceFormA2EndAdress = value;
              this.latLongFormA2EndAdress = undefined;
              this.searchFormA2EndAdress = undefined;
            } else {
              // this.contactDetailsForm = undefined;

              this.searchFormA2EndAdress = undefined;
              this.searchFormZ2EndAdress = undefined;

              this.latLongFormA2EndAdress = undefined;
              this.latLongFormZ2EndAdress = undefined;

              this.referenceFormA2EndAdress = undefined;
              this.referenceFormZ2EndAdress = undefined;
            }
            break;
          case 'z2EndAdress':
            if (
              this.searchFormAEndAdress?.formName ||
              this.latLongFormAEndAdress?.formName ||
              this.referenceFormAEndAdress?.formName ||
              this.searchFormZEndAdress?.formName ||
              this.latLongFormZEndAdress?.formName ||
              this.referenceFormZEndAdress?.formName ||
              this.searchFormA2EndAdress?.formName
            ) {
              this.referenceFormZ2EndAdress = value;
              this.searchFormZ2EndAdress = undefined;
              this.latLongFormZ2EndAdress = undefined;
            } else {
              // this.contactDetailsForm = undefined;

              this.searchFormZ2EndAdress = undefined;

              this.latLongFormZ2EndAdress = undefined;

              this.referenceFormZ2EndAdress = undefined;
            }
            break;
        }
      }

      if (value?.clearClicked) {
        this.clearQuote();
      }
      if (this.isQuoteSaved) {
        this.isQuoteSaved = false;
      }


      this.isSaveEnabled = (
        this.searchFormAEndAdress?.formName ||
        this.searchFormZEndAdress?.formName ||
        this.searchFormA2EndAdress?.formName ||
        this.searchFormZ2EndAdress?.formName ||

        this.latLongFormAEndAdress?.latitude || this.latLongFormAEndAdress?.longitude ||
        this.latLongFormZEndAdress?.latitude || this.latLongFormZEndAdress?.longitude ||
        this.latLongFormA2EndAdress?.latitude || this.latLongFormA2EndAdress?.longitude ||
        this.latLongFormZ2EndAdress?.latitude || this.latLongFormZ2EndAdress?.longitude ||

        this.referenceFormAEndAdress?.formName ||
        this.referenceFormZEndAdress?.formName ||
        this.referenceFormA2EndAdress?.formName ||
        this.referenceFormZ2EndAdress?.formName
      ) ? true : false;

      // this.isSaveEnabled = (
      //   this.referenceFormAEndAdress?.formName ||
      //   this.referenceFormZEndAdress?.formName ||
      //   this.referenceFormA2EndAdress?.formName ||
      //   this.referenceFormZ2EndAdress?.formName
      // ) ? true : false;
    });

    this.createQuoteService.isModifyQuote.subscribe(val => {
      if (val) {
        this.isModifyQuote = val;
        this.quoteDataToModify = this.createQuotePayload;
        //  this.getModifiedFormValues();
      } else {
        this.isModifyQuote = false
      }
    })

  // get the service type
    this.getProductCatalogDataFromAPI();
  }

}

