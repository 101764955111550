import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
//import reviewDetails from '../../../../assets/labelAuthoring/review.json';
import { ReviewSubmitService } from './review-and-submit.service';
import { MatStepper } from '@angular/material/stepper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CreateQuoteService } from '../create-quote.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment_ from 'moment-timezone';
import { CommonService } from 'src/app/shared/services/common.service';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/admin-settings/customer-list/customer.service';
import { AuthService } from 'tw-core-ui';
import { HomePageService } from 'src/app/shared/services/homePage.service';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { myQuotesService } from '../../my-quotes/myquotes.service';
@Component({
  selector: 'app-review-and-submit',
  templateUrl: './review-and-submit.component.html',
  styleUrls: ['./review-and-submit.component.less', '../product-details/product-details.component.less']
})
export class ReviewAndSubmitComponent implements OnInit {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  @ViewChild('stepper') stepper: MatStepper;
  public isTermsCondition: boolean = false;
  @ViewChild('myModal') modal!: ElementRef;
  isLinear = false;
  saveQuoteShow:boolean=false;
  reviewSubmitForm: FormGroup;
  public businessList = [];
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  public reviewHeaderChange: Subscription;
  fourthFormGroup: FormGroup;
  public aemLabelAuthoring: any = null;
  public aemReviewLabelAuthoring: any = null;
  @Output() showCustomerReference = new EventEmitter<boolean>();
  aemLabelAuthoring1: any;
  stepperData = ['Product details', 'Site details', 'Request details', 'Review and submit']
  stepName = 'Review and submit';
  isQuoteSaved: boolean = false;
  createQuotePayload: any;
  quoteRefId: any;
  selectedTimeZone = 'Australia/Victoria';
  moment = moment_;
  responseDateRequired: string;
  options: GlobalConfig;
  userName: any;
  myLinkedCustomerList: any;
  cidnSelected: any;
  isModifyQuote: boolean;
  selectedCidn: any;
  tAndcText: unknown;
  serviceType: string;
  constructor(private createQuoteService: CreateQuoteService,
    private reviewSubmitService: ReviewSubmitService,
    private fb: FormBuilder,
    private router: Router, private modalService: NgbModal,
    private commonService: CommonService,
    private customerService: CustomerService, private dataSvc: myQuotesService,
    private toastr: ToastrService, private authService: AuthService, private homepageservice: HomePageService,private titleService: Title) {
      this.titleService.setTitle('InfraCo Customer Portal -Review and Submit page');
    this.options = this.toastr.toastrConfig;
    if (localStorage.getItem('selectedHeaderCIDN')) {
      this.selectedCidn = localStorage.getItem('selectedHeaderCIDN')
    }
    else {
      this.getCommonServices();
      //  this.cidnSelected = '9999999998'
    }
    // code for header change in myquotes
    this.customerService.getLinkedCIDNs().then(res => {
      if (res && res.data && res.data.businessNameDetails && res.data.businessNameDetails.length > 0) {
        // let businessList = [];
        const iteratedArr = res.data.businessNameDetails.map(({
          bussinessName,
          cidn
        }) => [bussinessName, cidn]);

        for (var i = 0; i < iteratedArr.length; i++) {
          const businessName = iteratedArr[i][0];
          const cidn = iteratedArr[i][1];
          if (businessName || cidn) {
            this.businessList.push({
              businessName: businessName,
              cidn: cidn
            });

          }
        }

      }
      else {
        this.userName = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail']
        this.homepageservice
          .getUserProfile(this.userName)
          .then((response) => {
            this.myLinkedCustomerList = response?.data?.customerDetails;
            if (this.myLinkedCustomerList) {
              this.myLinkedCustomerList.forEach((option) => {
                this.businessList.push({
                  businessName: option.customerName,
                  cidn: option.cidn
                });
              });
            }
          });
      }

    }).catch((err) => {

    });

    if (this.commonService.getDefaultCustomer()) {
      this.reviewHeaderChange = this.commonService.getDefaultCustomer().pipe().subscribe(resp => {

        this.businessList.forEach(item => {

          if (item.businessName === resp) {
            this.cidnSelected = item.cidn;
            localStorage.setItem('selectedHeaderCIDN', this.cidnSelected)
            const roles = this.commonService.getroleDetails().customerList;
            const isReadWriteOnQuoteMgmt = roles.some((obj) => (obj?.cidn === this.cidnSelected && obj?.role === 'Read & Write' && obj?.function === 'Quote Request Management'));
            const isReadOnlyRole = roles.some((obj) => (obj?.cidn === this.cidnSelected && obj?.role === 'Read Only' && obj?.function === 'Quote Request Management'));
            if (isReadWriteOnQuoteMgmt || isReadOnlyRole) {
              this.router.navigate(['/myQuotes']);
            }
            else {
              this.router.navigate(['']);
            }

          }

        })

      })
    }
  }

  ngOnInit(): void {
    this.serviceType = this.createQuoteService.getServiceType();
    this.reviewSubmitForm = this.fb.group({
      firstForm: this.fb.group({
        companyName: ['', [Validators.required]],
      }),
      secondForm: this.fb.group({
        companyName: ['', [Validators.required]],
      }),
      thirdForm: this.fb.group({
        surveyingCompany: [''],
      }),
      fourthForm: this.fb.group({
        surveyingCompany: [''],
      }),
    });
    this.getLabels();
    this.getReviewDetailsLabels();
    this.createQuotePayload = this.createQuoteService.getProductDetailsFormValues();
    this.quoteRefId = this.createQuotePayload?.quoteReferenceId;
    if (!this.createQuotePayload?.quoteReferenceId) {
      this.createQuotePayload = sessionStorage.getItem('productDetailsFormValue');
      this.quoteRefId = this.createQuotePayload?.quoteReferenceId;
    }
    this.responseDateRequired = this.createQuotePayload?.responseRequiredDate ? this.moment(this.createQuotePayload?.responseRequiredDate).format('DD MMM YYYY') : '';
    this.showCustomerReference.emit(true);

    this.createQuoteService.isModifyQuote.subscribe(val => {
      if (val) {
        this.isModifyQuote = val;
      } else {
        this.isModifyQuote = false
      }
    })
  }

  getCommonServices() {
    this.commonService.getDefaultCustomer().pipe().subscribe(resp => {
      if (resp) {
        this.setCustomerDropdown(resp);
      }
    });
  }

  setCustomerDropdown = (selectedHeaderValue?: string) => {
    const profileDetails = this.homepageservice.userProfileDetails;
    if (profileDetails) {
      const custDetails = profileDetails?.data?.customerDetails;
      if (selectedHeaderValue) {
        const findCIDNobject = this.searchCidn(custDetails, selectedHeaderValue)
        this.selectedCidn = findCIDNobject?.cidn;
        localStorage.setItem('CIDNCan', this.selectedCidn)
      }
    }
  }

  searchCidn(myArray, nameKey) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].customerName === nameKey) {
        return myArray[i];
      }
    }
  }

  getLabels() {
    this.reviewSubmitService
      .getCabinetsAccessModel()
      .then((data: any) => {
        this.aemLabelAuthoring = data;
      })
      .catch((error) => { });
  }
  getReviewDetailsLabels() {
    this.createQuoteService
      .getCreateQuoteModel()
      .then((data: any) => {
        this.aemLabelAuthoring1 = data;
      })
      .catch((error) => { });
    this.reviewSubmitService
      .getReviewDetailsPageLabel()
      .then((data: any) => {
        this.aemReviewLabelAuthoring = data;
      })
      .catch((error) => { });
  }

  cancel() {
    if (this.isModifyQuote) {
      this.modifyQuote(false, false);
    } else {

      this.saveFeasibilityRequest(false);
    }
    this.router.navigate(['/myQuotes']);
  }
  navigateToTermsNConditions() {

  }
  /* navigateBack() {
    this.router.navigateByUrl('/request-details');
  } */
  navigateBack() {
    const productDetailsFormData = this.createQuoteService.getProductDetailsFormValues();
    const navigationExtras = {
      state: {
        formData: productDetailsFormData,
        isModifyFlow: this.isModifyQuote
      }
    }
    if (this.isModifyQuote) {
      this.modifyQuote(false, false);
      this.router.navigateByUrl('/request-details', navigationExtras);
    } else {
      this.saveFeasibilityRequest(false, true);
      this.router.navigateByUrl('/request-details');
    }

  }

  // openTermsConditionModal() {
  //   this.isTermsCondition = true;
  // }
  public onClickClose(event) {
    this.isTermsCondition = false;
  }


  saveFeasibilityRequest(val: boolean, plr?) {

    if((val=== false) && (plr === false)){
      this.saveQuoteShow=true;
      setTimeout(() => {
       this.saveQuoteShow = false;
     }, 5000);
    }
    
    this.createQuoteService.setProductDetailsFormValues(this.createQuotePayload);
    if (this.isModifyQuote) {
      this.modifyQuote(val, plr);
    } else {
      this.saveFeasibility(val, plr);
    }

  }

  saveFeasibility(val, plr?) {
    
    // NON-RG payload
    if(this.serviceType !== 'RG'){
      this.createQuoteService.nonRGPayloadPrepare(val, this.serviceType);
    }

    this.createQuotePayload['avoidPlrLogging'] = plr ? false : true;
    return new Promise((resolve, reject) => {
      this.createQuoteService.saveNewQuote(this.createQuotePayload, 'Save').then((res) => {
        this.isQuoteSaved = true;
        if (val) {
          this.openTermsAndCondition();
         // this.isTermsCondition = true;
        }
        resolve(true);

      }).catch((err) => {
        const msg = 'We can’t submit your request at this time. Please refresh and try again later.';
        this.showToasterMessage(msg, '', this.options, 'error');
        reject(err);
      });

    });
  }
  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-bottom-right';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 5000;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    // document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
    //document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
  }

  showFundingSourceCodes() {
    return this.createQuotePayload?.fundingSource === 'Other' || this.createQuotePayload?.fundingSource?.toLowerCase().indexOf('sbc') > -1
  }
  getSelectedAddress(adr) {
    if(!adr) return;

    if (adr.selectedAddress !== '(New address)') {
      return adr.selectedAddress;
    } else {
      return (adr.latitude + ',  ' + adr.longitude);
    }
  }

  modifyQuote(val, plr?) {
    const payload = {
      action: 'Save',
      avoidPlrLogging: plr,
      cidn: this.selectedCidn,
      quoteReferenceId: this.quoteRefId,
      modifiedDetails: []
    }
    return new Promise((resolve, reject) => {
      this.createQuoteService.modifyFeasibilityRequest(payload).then((res) => {
        if (val) {
          this.openTermsAndCondition()
        //  this.isTermsCondition = true;
        }
        resolve(true);

      }).catch((err) => {
        const msg = 'We can’t submit your request at this time. Please refresh and try again later.';
        this.showToasterMessage(msg, '', this.options, 'error');
        reject(err);
      });

    });
  }
  openTermsAndCondition() {
    this.dataSvc.getTandCforOrder(this.selectedCidn, this.createQuotePayload?.productName).then(res => {
      this.isTermsCondition = true;
      this.tAndcText = res;
    })
  }
  ngOnDestroy(): void {
    if (this.reviewHeaderChange) {
      this.reviewHeaderChange.unsubscribe();
    }
  }
}


