import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tool-tip',
  templateUrl: './tool-tip.component.html',
  styleUrls: ['./tool-tip.component.less']
})
export class ToolTipComponent  {
  @ViewChild('tooltip', { static: true }) tooltip: ElementRef;
    params: any;
    show: boolean=false;
    

    agInit(params: any): void {
      this.params = params;
    }


    getTooltipPlacement(item: any): string {
      // Check if the length of tooltipContent is greater than 60 characters
      // If true, return 'left', else return 'auto'
      return item && item.length > 200 ? 'left' : 'auto';
    }
   
      
      
      
}

