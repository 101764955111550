import { Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { MyOrdersService } from '../../my-orders.service';
import { Subscription } from 'rxjs';
import { myQuotesService } from 'src/app/quote-management/my-quotes/myquotes.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-order-review-submit',
  templateUrl: './order-review-submit.component.html',
  styleUrls: ['./order-review-submit.component.less', '../order-site-details/order-site-details.component.less']
})
export class OrderReviewSubmitComponent implements OnInit {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;

  aemLabelAuthoring: any;
  orderReviewDetailsData: any;
  userTimeZone: string;
  cidnSelected: string;
  options: GlobalConfig;
  orderId: string;
  path: string;
  quoteId: any;
  isSaved: boolean = false;
  isSavingInProgress: boolean = false;
  isSaveApiError: string;
  public customerChange: Subscription;
  infraCoCIDN = window['environment'].InfraCoCIDN;
  isOrderSaved: boolean = false;

  constructor(
    private myOrderService: MyOrdersService,
    @Inject(LOCALE_ID) private locale: string,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private dataSvc: myQuotesService,
    private titleService: Title
  ) {
    this.titleService.setTitle('InfraCo Customer Portal - Order Review and Submit page');
    // Get User TimeZone
    this.getUserTimeZone();
    this.options = this.toastr.toastrConfig;

    // Get CIDN
    if (localStorage.getItem('selectedHeaderCIDN')) {
      this.cidnSelected = localStorage.getItem('selectedHeaderCIDN')
    }
    if (this.infraCoCIDN === this.cidnSelected) {
      this.router.navigate(['/myOrders']);
    }
    this.customerChange = this.commonService.getCustomerName().subscribe((data) => {
      this.dataSvc.customerNameDropdownChange(true, data);
    });
    this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
        if (
          event.id === 1 &&
          event.url === event.urlAfterRedirects
        ) {
          this.router.navigateByUrl('/myOrders');
        }
      })
  }
  stepperData = ['Site details', 'Request details', 'Review and submit']
  stepName = 'Review and submit';
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.route.paramMap.subscribe((params) => {
      const p = params.get('params');
      if (params.get('quoteId') && params.get('type')) {
        // Get order details
        this.getOrderDetails(params.get('quoteId'), params.get('type'));
        this.orderId = params.get('quoteId');
        this.path = params.get('type');

        const path = params.get('type');
        const quoteId = params.get('quoteId');
      }

      this.getLabels();
    });
  }



  getUserTimeZone() {
    const userTimeZone = Intl.DateTimeFormat(this.locale, {
      timeZoneName: 'long'
    }).resolvedOptions().timeZone;

    this.userTimeZone = userTimeZone;
  }


  getLabels() {
    this.myOrderService
      .getOrderSiteDetailsPageLabel()
      .then((data: any) => {
        this.aemLabelAuthoring = data;
      })
      .catch((error) => { });
  }

  getOrderDetails(oid?, path?) {

    const params = {
      cidn: this.cidnSelected,
      timeZone: this.userTimeZone,
      orderId: oid,
      path: path
    }

    localStorage.setItem('orderId', this.userTimeZone);
    localStorage.setItem('path', this.path);

    // const params = {
    //   cidn: '9999999999',
    //   timeZone: 'Asia/Calcutta',
    //   orderId: 'IR100002785',
    //   path: 'Path1'
    // }
    return new Promise((resolve, reject) => {
      this.myOrderService.getSingleOrder(params.cidn, params.timeZone, params.orderId, params.path, 'orderReview').then((res) => {
        if (res && res.data) {
          this.orderReviewDetailsData = res.data;
        }
        resolve(true);
      }).catch((err) => {
        reject(err);
      });
    });



  }


  back() {
    this.saveOrderReviewDetails('Save', 'back');
  }

  cancel() {
    this.saveOrderReviewDetails('Save', 'cancel');
    // this.router.navigate(['/myOrders']);
  }

  // next() {
  //   this.saveOrderReviewDetails('Submit', 'success');
  // }
  deepCopy(element) {
    return JSON.parse(JSON.stringify(element));
  }

  saveOrderReviewDetails(action?: string, navTo?: string) {
    if (!navTo && (action === 'Save')) {
      this.isOrderSaved=true;
     }
     setTimeout(() => {
       this.isOrderSaved = false;
     }, 5000);
    this.isSavingInProgress = true;
    const payload = this.deepCopy(this.orderReviewDetailsData);
    if(!payload) return;

    payload.timezone = this.userTimeZone;
    payload.quoteExpiryDate = this.convertDateFormatISO(this.orderReviewDetailsData?.quoteExpiryDate) || null;
    payload.customerRequestedDate = this.convertDateFormatISO(this.orderReviewDetailsData?.customerRequestedDate) || null;
    payload.createdDate = this.convertDateFormatISO(this.orderReviewDetailsData?.createdDate) || null;
    payload.modifiedDate = this.convertDateFormatISO(this.orderReviewDetailsData?.modifiedDate) || null;
    payload.aEndAdress.createdDate = this.convertDateFormatISO(this.orderReviewDetailsData?.aEndAdress?.createdDate) || null;
    payload.aEndAdress.modifiedDate = this.convertDateFormatISO(this.convertDateFormatISO(this.orderReviewDetailsData?.aEndAdress?.modifiedDate) || null);

    payload.aEndAdress.orderSiteContactDetails[0].createdDate = this.convertDateFormatISO(this.orderReviewDetailsData?.aEndAdress?.orderSiteContactDetails[0]?.createdDate) || null;
    payload.aEndAdress.orderSiteContactDetails[0].modifiedDate = this.convertDateFormatISO(this.orderReviewDetailsData?.aEndAdress?.orderSiteContactDetails[0]?.modifiedDate) || null;

    payload.zEndAdress.orderSiteContactDetails[0].createdDate = this.convertDateFormatISO(this.orderReviewDetailsData?.zEndAdress?.orderSiteContactDetails[0]?.createdDate) || null;
    payload.zEndAdress.orderSiteContactDetails[0].modifiedDate = this.convertDateFormatISO(this.orderReviewDetailsData?.zEndAdress?.orderSiteContactDetails[0]?.modifiedDate) || null;

    payload.zEndAdress.createdDate = this.convertDateFormatISO(this.orderReviewDetailsData?.zEndAdress?.createdDate) || null;
    payload.zEndAdress.modifiedDate = this.convertDateFormatISO(this.orderReviewDetailsData?.zEndAdress?.modifiedDate) || null;
    payload.action = action;

    if (payload.maxSignalLoss) {
      delete payload.maxSignalLoss;
    }

    this.myOrderService.saveNewOrder(payload).then((res) => {

      if (payload.action === 'Submit' && navTo === 'success') {
        const navigationExtras = {
          state: {
            data: {
              orderId: this.orderId,
              path: this.path,
              customerRef: this.orderReviewDetailsData?.customerReference,
              isOrder: true
            }
          }
        };
        this.router.navigateByUrl('/order-success', navigationExtras);
      }
      else if (payload.action === 'Save' && navTo === 'back') {
        this.router.navigate(['/order-request-details', this.path, this.orderId]);
      }

      else if (payload.action === 'Save' && navTo === 'cancel') {
        //this.router.navigate(['/order-request-details', this.path, this.orderId]);
        this.router.navigate(['/myOrders']);
      }
      else {
        this.isSaved = true;
        // this.showToasterMessage('Order Details Saved Successfully', '', this.options, 'success');
      }
      this.isSavingInProgress = false;

    }).catch((err) => {
      this.isSavingInProgress = false;
      if (err.error.status === 500 && err.error.code === 500 && ((err.error.message).toLowerCase() === this.aemLabelAuthoring?.errorMsg?.addressError.toLowerCase())) {
        this.isSaveApiError = this.aemLabelAuthoring?.errorMsg?.addressError;
      } else if (err.error.status === 500 && err.error.code === 500 && ((err.error.message).toLowerCase() ===  this.aemLabelAuthoring?.errorMsg?.serviceIdError.toLowerCase())) {
        this.isSaveApiError = this.aemLabelAuthoring?.errorMsg?.serviceIdError;
      } else {
        this.showToasterMessage(this.aemLabelAuthoring?.errorMsg?.genericError, '', this.options, 'error');
      }
    });
  }

  convertDateFormatISO(d) {
    const date = new Date(d);
    return date.toISOString();
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-top-center';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 5000;
    this.options.closeButton = false;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
  }
  ngOnDestroy(): void {
    if (this.customerChange) {
      this.customerChange.unsubscribe();
    }
  }



}
