import { throwError as observableThrowError, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'tw-core-ui';

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  private env: any = window['environment'];
  private apiUrl = {
    faqDetails: 'faqDetails',
  };
  spinnerflag = false;
  private sendSpinnerFlag = new BehaviorSubject(this.spinnerflag);
  spinnerCurrentStatus = this.sendSpinnerFlag.asObservable();
  faqDetails: any;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  public getFaqDetails() {
    return new Promise((resolve, reject) => {
      this.http.get(this.env['aemEndPoint'] + this.apiUrl.faqDetails).pipe(catchError((error: any): any => {
        reject(error);
        return observableThrowError(error.json().error || 'Server error');
      })).subscribe((res: any) => {
        this.faqDetails = res.data;
        resolve(this.faqDetails);
      });
    });
  }

  public getroleDetails() {
    const user_roles: any = this.authService.user ? this.authService.user.profile.roles : this.env.roles;
    const roles = [];
    user_roles.forEach(role => {
      roles.push(role.value.split(':')[1]);
    });
    return roles;
  }
}
