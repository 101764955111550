<div  class="success-head" [ngClass]="isBulkQuote ? 'infra-success-head-container-bulk':'infra-success-head-container'">
  <!-- <div aria-live="assertive" class="visually-hidden" id="custom-label">
    <span role="presentation" tabindex="0" style="position:absolute;left:-9999px">success page</span>
  </div> -->
  <label  class="alert-section" aria-live="assertive" role="alert">{{pageTitle}}</label>

  <div class="row success-icon-align">
    <div class="col text-center">
      <div class="pb-30">
        <img style="height: 72px;width: 72px;" src="../../../../assets/images/Sucess.svg" alt="">
      </div>
      <div>
        <div class="success-label-text" id="successScreenHeader">Success</div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!orderDetails?.data?.isOrder  && !isBulkQuote">
    <div class="d-flex justify-content-center">
    <div class="row quote-border-order reference-title mt-3">
      <div [ngClass]="{'quote-column':quoteDetails?.data?.customerReference?.length>0}">
        <div class="col text-center ref-column">{{aemLabelAuthoring?.label?.quoteReference}}</div>
        <div class="col text-center text-break pb-5">
          {{quoteDetails?.data?.quoteReferenceId}}
        </div>
      </div>
      <div [ngClass]="{'quote-column pb-5' : quoteDetails?.data?.customerReference?.length>0}" >
        <div class="col text-center ref-column" *ngIf="quoteDetails?.data?.customerReference?.length>0">
          {{aemLabelAuthoring?.label?.customerReference}}
        </div>
        <div class="col text-center text-break" *ngIf="quoteDetails?.data?.customerReference?.length>0">
          {{quoteDetails?.data?.customerReference}}
        </div>
      </div>
    </div>
    </div>
    <!-- <div class="d-flex justify-content-center">
      <div class="row quote-border-order reference-title mt-3">
        <div class="col text-center ref-column">{{aemLabelAuthoring?.label?.quoteReference}}</div>
        <div class="col text-center ref-column" *ngIf="quoteDetails?.data?.customerReference?.length>0">
          {{aemLabelAuthoring?.label?.customerReference}}
        </div>
        <div class="row ref-number">
          <div class="col text-center ref-column-new">
            <label aria-live="assertive" class="visually-hidden">Quote referenceId is</label>
            {{quoteDetails?.data?.quoteReferenceId}}
          </div>
          <div class="col text-center ref-column-new" *ngIf="quoteDetails?.data?.customerReference?.length>0">
            <label aria-live="assertive" class="visually-hidden">Customer reference is</label>
            {{quoteDetails?.data?.customerReference}}
          </div>
        </div>
  </div>
</div> -->
  </ng-container>

  <ng-container *ngIf="orderDetails?.data?.isOrder  && !isBulkQuote ">
    <div class="d-flex justify-content-center">
      <div class="row quote-border-order reference-title mt-3">
        <div class="col text-center ref-column">{{aemLabelAuthoring?.label?.quoteReference}}</div>
        <div class="col text-center ref-column" *ngIf="orderDetails?.data?.customerRef?.length>0">
          {{aemLabelAuthoring?.label?.customerReference}}</div>
        <div class="row ref-number" style="padding-bottom: 25px;">
          <div class="col text-center ref-column-new">{{orderDetails?.data?.orderId}}</div>
          <div class="col text-center ref-column-new" *ngIf="orderDetails?.data?.customerRef?.length>0">
            {{orderDetails?.data?.customerRef}}</div>
        </div>
      </div>
    </div>
  </ng-container>
  
  <ng-container *ngIf="isBulkQuote && bulkQuoteId">
    <div class="row justify-content-center success-msg ">
      Please expect a wait time of up to 60 minutes depending on the number of requests
    </div>
    <div class="d-flex justify-content-center pt-5">
      <div class="row bulk-quote-border reference-title">
        <div class="col text-center quote-ref-color ref-column">{{aemLabelAuthoring?.label?.bulkQuoteReference}}</div>
        <div class="row ref-number">
          <div class="col text-center ref-column-new">{{bulkQuoteId}}</div>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="quote-btn" *ngIf="!isBulkQuote && aemLabelAuthoring?.label">
    <button *ngIf="orderDetails?.data?.isOrder" type="button" class="btn-my-orders-text"
      (click)="navigateToMyOrders()">{{aemLabelAuthoring?.label?.myOrdersButton}}</button>
    <button type="button" *ngIf="isPredefinedRoute && (!orderDetails?.data?.isOrder)" [ngClass]="{'app-btn-green btn-text mr-25': orderDetails?.data?.isOrder, 'btn-my-orders-text': !orderDetails?.data?.isOrder }"
      (click)="navigateToQuoteSummary()">{{aemLabelAuthoring?.label?.viewQuoteButton}}</button>
    <button (click)="navigateToMyQuote()" type="button" class="app-btn-green btn-text mr-25" [ngClass]="{'btn-my-orders-text': !isPredefinedRoute && !orderDetails?.data?.isOrder}">{{aemLabelAuthoring?.label?.myQuotesButton}}</button>
    <button type="button" class="app-btn-green btn-text"
      (click)="navigateToNewQuote()">{{aemLabelAuthoring?.label?.newQuoteButton}}</button>
      <button type="button" class="app-btn-green btn-text ml-25"
      (click)="navigateToCreateBulkQuote()">{{aemLabelAuthoring?.label?.newBulkQuoteButton}}</button>
  </div>

  <div class="quote-btn" *ngIf="isBulkQuote">
    <button type="button" class="btn-bulk-quote-text mr-25"
      (click)="navigateToMyQuote()">{{aemLabelAuthoring?.label?.myQuotesButton}}</button>
    <button (click)="navigateToNewQuote()" type="button" class="app-btn-green btn-text mr-25" >{{aemLabelAuthoring?.label?.newQuoteButton}}</button>
    <button (click)="navigateToCreateBulkQuote()" type="button" class="app-btn-green btn-text">{{aemLabelAuthoring?.label?.newBulkQuoteButton}}</button>
  </div>

</div>
