import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import * as moment_ from 'moment';
import { Router } from '@angular/router';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { CustomAgGrid, CustomTextField } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { CabinetsService } from '../cabinets.service';
import { CabinetsActionRendererComponent } from '../cabinets-action-renderer/cabinets-action-renderer.component';
import { CheckboxFilterComponent } from 'src/app/grid-filter/checkbox-filter/checkbox-filter.component';
import { CustomTextFilterComponent } from 'src/app/grid-filter/custom-text-filter/custom-text-filter.component';
import { CustomDateFilterComponent } from 'src/app/grid-filter/custom-date-filter/custom-date-filter.component';
import { AuthService } from 'tw-core-ui';
import { DashboardService } from '../dashboard.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { HomePageService } from 'src/app/shared/services/homePage.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-cabinet-list',
  templateUrl: './cabinet-list.component.html',
  styleUrls: ['./cabinet-list.component.less'],
})
export class CabinetListComponent implements OnInit {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  public aemLabelAuthoring: any;
  private columnDefs;
  colDef: any;
  rowData: any = [];
  moment = moment_;
  public filteredRowData: any = [];
  options: GlobalConfig;
  showCabinetList: boolean;
  @Output() showCreateCabinet = new EventEmitter();
  @Output() showDocumentList = new EventEmitter();
  private hideCol: boolean = false;

  public cabinetModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMyUserName',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxuserName',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divUserNameValidationErrMsg',
    placeholderCaption: 'Enter the cabinet name or cabinet ID',
    id: 'userName',
    tabindex: '',
    maxlength: '64',
    headerText: '',
    headerTextAccessible: '',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'userName',
    formControlName: 'userName',
    isDisabled: false,
    showValidationIcon: false,
  };
  public gridModel: CustomAgGrid = {
    gridOptions: {},
    keysToSuppress: [13],
    labelAuthoring: {
      headerPageCount: '',
      filter: '',
      resetFilter: '',
      paginationHeader: '',
      noData: '',
      noDataOnFilter: '',
      noDataOnSearch: '',
      toTop: 'Back to top',
    },
  };
  gridApi: any;
  gridConfig: GridOptions;
  gridInitialized: boolean;
  isTextSearch: Boolean = false;
  isModalOpen: boolean;
  profile: { contactName: any };
  roles = [];
  isInfracoAdmin: boolean;
  isInfracoUser: boolean;
  noData: string;
  noDataOnSearch: string;
  showCreateCabinetBtn: boolean;
  focusableElement: HTMLElement;

  constructor(
    private router: Router,
    private cabinetsService: CabinetsService,
    private toastr: ToastrService,
    private authService: AuthService,
    private dashBoardSvc: DashboardService,
    private commonService: CommonService,
    private homePageService: HomePageService,
    private titleService: Title
  ) {
    this.profile = {
      'contactName': this.authService.contactName() ? this.authService.contactName() : window['environment']['contactNameUI'],
    }
    this.getRoleDetails();
    this.titleService.setTitle('InfraCo Customer Portal - Cabinet-list');
  }

  ngOnInit() {
    this.commonService.showTopButtonInFooter(true);
    this.getLabels();
    this.showCabinetList = true;
  }

  getLabels() {
    return new Promise((resolve, reject) => {
      this.cabinetsService
        .getCabinetsModel()
        .then(() => {
          this.aemLabelAuthoring =
            this.cabinetsService.gridLabelAuthoringDataObj;
          this.createColumnDefs();
          resolve(true);
        })
        .catch(() => {
          reject(false);
        });
    });
  }

  createColumnDefs(searchKeyword?) {
    return new Promise((resolve, reject) => {
      this.cabinetsService
        .getCabinetsGridHeader()
        .then(() => {
          this.columnDefs = this.cabinetsService.documentsGridHeader.headers;
          if (this.hideCol) {
            this.columnDefs[2].hide = true;
          }
          const map = {
            infracoAdminCabinetAction: CabinetsActionRendererComponent,
          };
          this.columnDefs.forEach((x: any) => {
            if (x.cellRendererFramework) {
              x.cellRendererFramework = map[x.cellRendererFramework];
            }
          });

          const index = this.columnDefs.findIndex(
            (ele) => ele.field == 'cabinetName'
          );
          if (index !== -1) {
            this.columnDefs[index].maxWidth = 800;
            this.columnDefs[index].sort = 'asc';
          }

          const index2 = this.columnDefs.findIndex(
            (ele) => ele.field == 'accessLevel'
          );
          if (index2 !== -1) {
            this.columnDefs[index2].hide = true;
          }

          const index3 = this.columnDefs.findIndex(
            (ele) => ele.field == 'view'
          );
          if (index3 !== -1) {
            this.columnDefs[index3].hide = true;
          }

          const index4 = this.columnDefs.findIndex(
            (ele) => ele.field == 'ActionItems'
          );
          if (index4 !== -1) {
            this.columnDefs[index4].hide = false;
          }

          if (this.columnDefs) {
            this.createRowData(searchKeyword);
          }
          this.columnDefs.forEach((x: any) => {
            if (x.cellRendererFramework) {
              if (x.colId == 'documents-grid-actionItems') {
                x.cellRendererParams.clicked = function (field: any, type) {
                  switch (type) {
                    case 'view':
                      this.viewDocument(field);
                      break;
                    case 'delete':
                      this.deleteDocument(field);
                      break;
                  }
                }.bind(this);
              }
            }
          });
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  createRowData(searchKeyword?) {
    return new Promise((resolve, reject) => {
      this.cabinetsService
        .getCabinetGridData()
        .then((res) => {
          this.rowData = this.cabinetsService.infraCoGridData;
          if (this.rowData.length > 0) {
            this.createGridConfig();
            this.gridModel.labelAuthoring.noDataOnSearch = this.noDataOnSearch;
          } else {
            this.rowData = [];
            this.createGridConfig();

            if (searchKeyword) {
              this.gridModel.labelAuthoring.noDataOnSearch =
                this.noDataOnSearch;
            } else {
              this.gridModel.labelAuthoring.noDataOnSearch = this.noData;
            }
          }
          resolve(true);
        })
        .catch((err) => {
          this.rowData = [];
          if (searchKeyword) {
            this.gridModel.labelAuthoring.noDataOnSearch = this.noDataOnSearch;
          } else {
            this.gridModel.labelAuthoring.noDataOnSearch = this.noData;
          }
          reject(false);
        });
    });
  }

  createGridConfig() {
    const cabinetId = [];
    const cabinetName = [];
    const cabinetRole = [];
    const filterCabinetIdOptions = [];
    const filterCabinetNameOptions = [];
    const filterAccessLevelOptions = [];
    this.rowData.forEach((val) => {
      if (cabinetId.indexOf(val.cabinetId) === -1) {
        cabinetId.push(val.cabinetId);
        filterCabinetIdOptions.push({ elementData: val.cabinetId });
      }
      if (cabinetName.indexOf(val.cabinetName) === -1) {
        cabinetName.push(val.cabinetName);
        filterCabinetNameOptions.push({ elementData: val.cabinetName });
      }
      if (
        val.cabinetRoleName &&
        cabinetRole.indexOf(val.cabinetRoleName) === -1
      ) {
        cabinetRole.push(val.cabinetRoleName);
        filterAccessLevelOptions.push({ elementData: val.cabinetRoleName });
      }
    });
    this.columnDefs[0].customFilter = filterCabinetIdOptions;
    this.columnDefs[1].customFilter = filterCabinetNameOptions;
    this.columnDefs[2].customFilter = filterAccessLevelOptions;
    this.gridConfig = <GridOptions>{};
    this.gridConfig.paginationPageSize = 10;
    this.gridConfig.columnDefs = this.columnDefs;
    this.gridConfig.rowData = this.rowData;
    this.gridConfig.suppressHorizontalScroll = true;
    this.gridConfig.pagination = true;
    this.gridConfig.suppressRowHoverHighlight = true;
    this.gridConfig.suppressRowClickSelection = true;
    this.gridConfig.rowSelection = 'single';
    this.gridConfig.enableFillHandle = false;
    this.gridInitialized = true;
    this.gridConfig.frameworkComponents = {
      CheckboxFilterComponent: <any>CheckboxFilterComponent,
      customTextFilter: <any>CustomTextFilterComponent,
      CustomDateFilterComponent: <any>CustomDateFilterComponent,
      infracoAdminCabinetAction: <any>CabinetsActionRendererComponent,
    };
    this.gridModel.labelAuthoring = this.aemLabelAuthoring.form.grid;
    if (!this.noDataOnSearch || !this.noData) {
      this.noDataOnSearch = this.gridModel.labelAuthoring.noDataOnSearch;
      this.noData = this.gridModel.labelAuthoring.noData;
    }

    this.gridModel.gridOptions = this.gridConfig;
  }
  onFilterSelected(filteredData) {
    this.filteredRowData = [];
    if (
      filteredData.filteredRowData &&
      filteredData.filteredRowData.length > 0
    ) {
      filteredData.filteredRowData.forEach((row) => {
        if (row.data) {
          this.filteredRowData.push(row.data);
        }
      });
    }
  }

  viewDocument(rowData) {
    this.cabinetsService.setCabinetsDetailsData(rowData);
    const cabinetName = rowData.cabinetName;
    const cabinetId = rowData.cabinetId;
    const cabinetRole = rowData.cabinetRole;
    this.showCabinetList = false;
    this.cabinetsService.fetchError(null);
    if (this.isInfracoAdmin) {
      this.showCreateCabinet.emit(false);
      this.router.navigate([`/cabinet-list/${cabinetName}/${cabinetId}`]);
    } else {
      if (rowData.cabinetRole) {
        this.router.navigate([`/cabinet-list/${cabinetName}/${cabinetId}/${cabinetRole}`]);
      } else {
        this.router.navigate([`/cabinet-list/${cabinetName}/${cabinetId}`]);
      }
    }
  }

  removeCabinet: any;
  deleteDocument(field) {
    this.isModalOpen = true;
    this.removeCabinet = field.cabinetId;
    setTimeout(() => {
      this.focusableElement = document.getElementById('closePopup');
      this.focusableElement.focus();
    }, 1);
    this.commonService.trapFocusInModal('closePopup', 'remove');
  }

  closeModal() {
    this.isModalOpen = false;
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-top-center';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 5000;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
  }

  onClickRemove() {
    this.gridInitialized = false;
    this.cabinetsService
      .deleteCabinet(this.removeCabinet)
      .then((res) => {
        if (res && (res['code'] == 202 || res['status'] == 202)) {
          const index = this.rowData.findIndex(
            (ele) => ele.cabinetId == this.removeCabinet
          );
          this.rowData.splice(index, 1);
          this.gridConfig.rowData = this.rowData;

          this.options = this.toastr.toastrConfig;
          let successMsg =
            'Congratulations. You have successfully deleted cabinet.';
          this.showToasterMessage(successMsg, '', this.options, 'success');
        }
        this.gridInitialized = true;
      })
      .catch((err) => {
        this.gridInitialized = true;
      });
    this.isModalOpen = false;
  }

  onCellClicked(column) {
    if (column && column.colDef && column.colDef.field == 'ActionItems') {
      return;
    }
    if (column && column.colDef && column.colDef.field == 'cabinetId') {
      return;
    }
    const rowData = column.data;
    const cabinetName = rowData.cabinetName;
    const cabinetId = rowData.cabinetId;
    const cabinetRole = rowData.cabinetRole;
    this.cabinetsService.setCabinetsDetailsData(rowData);
    this.cabinetsService.fetchError(null);
    if (this.isInfracoAdmin) {
      this.router.navigate([`/cabinet-list/${cabinetName}/${cabinetId}`]);
    } else {
      this.router.navigate([`/cabinet-list/${cabinetName}/${cabinetId}/${cabinetRole}`]);
    }
  }

  isResetField: boolean;
  resetSearch() {
    this.cabinetModel.inputText = '';
    this.gridInitialized = false;
    this.isTextSearch = false;
    this.isResetField = true;
    setTimeout(() => {
      this.isResetField = false;
    });
    this.createColumnDefs();
  }

  searchCabinetData() {
    const searchkeyword = this.cabinetModel.inputText;
    this.gridModel.gridOptions.api.setQuickFilter(searchkeyword);
  }

  checkCabinet(event) {
    if (this.cabinetModel.inputText.length > 0) {
      this.isTextSearch = true;
    } else {
      this.isTextSearch = false;
    }
  }

  createCabinet() {
    window.scroll(0, 0);
  }

  gridReady(param) {
    this.gridApi = param.value.event.api;
  }

  download() {
    const data = [];
    const dataHeaders = [
      'Username',
      'First Name',
      'Last Name',
      'Role',
      'Cabinet ID',
    ];
    const now = this.moment().format('YYYYMMDDHHmmss');
    const carriers = [];
    let dataToBeDownloaded = [];
    if (this.filteredRowData && this.filteredRowData.length > 0) {
      dataToBeDownloaded = this.filteredRowData;
    } else {
      if (this.rowData && this.rowData.length) {
        dataToBeDownloaded = this.rowData;
      }
    }
    dataToBeDownloaded.forEach((selectedRow) => {
      data.push({
        Username: selectedRow.userName,
        'First Name': selectedRow.name.givenName,
        'Last Name': selectedRow.name.familyName,
        Role: selectedRow.role,
        'Cabinet ID': selectedRow.cabinetId,
      });
    });
    new Angular5Csv(data, 'infraco Admin' + now, { headers: dataHeaders });
  }

  navigateToCreateCabinet() {
    window.scroll(0, 0);
    this.showCabinetList = false;
    this.showDocumentList.emit(false);
    this.showCreateCabinet.emit(true);
    this.router.navigateByUrl('/home/create-document-cabinet');
  }
  encodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

  navigateToDoc() {
    this.router.navigateByUrl('/home/create-document-cabinet');
  }

  getRoleDetails() {
    const roles = this.commonService.getroleDetails();
    this.roles = roles.customerList;
    const customerDetails = this.homePageService.getCustomerDetails();
    if (customerDetails.isInfraCoCustomer &&
      this.roles?.some((a) => (a?.role === 'NBN Administrator' && a?.function === 'NBN Cabinets'))) {
      this.isInfracoAdmin = true;
      this.showCreateCabinetBtn = true;
      this.hideCol = true;
    }
    else if (customerDetails.isInfraCoCustomer &&
      this.roles?.some((a) => (a?.role === 'NBN Read Only' && a?.function === 'NBN DA Documents') ||
        (a?.role === 'NBN Read & Write' && a?.function === 'NBN DA Documents'))) {
      this.isInfracoAdmin = false;
      this.showCreateCabinetBtn = false;
      this.hideCol = true;
    } else {
      this.isInfracoAdmin = false;
      this.showCreateCabinetBtn = false;
      this.hideCol = false;
    }
  }

  focusOnTop(event) {
    if (event) {
      let focusableElement;
      focusableElement = document.getElementById('wel');
      if (focusableElement) {
        focusableElement.setAttribute('tabindex', '0');
        focusableElement.addEventListener('blur', () => {
          focusableElement.removeAttribute('tabindex');
        });
        setTimeout(() => {
          if (focusableElement) {
            focusableElement.focus();
          }
        }, 10);
      }
    }
  }
  ngOnDestroy() {
    this.commonService.showTopButtonInFooter(false);
  }
}
