import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { saveAs as importedSaveAs } from "file-saver";
import { ToastrService, GlobalConfig, ToastContainerDirective } from 'ngx-toastr';
import { CabinetsService } from '../cabinets.service';
import * as moment_ from 'moment';
import { CustomAgGrid, CustomSearchBox, TooltipContent } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { DashboardService } from '../dashboard.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { HomePageService } from 'src/app/shared/services/homePage.service';
import { CheckboxFilterComponent } from 'src/app/grid-filter/checkbox-filter/checkbox-filter.component';
import { CustomTextFilterComponent } from 'src/app/grid-filter/custom-text-filter/custom-text-filter.component';
import { CustomDateFilterComponent } from 'src/app/grid-filter/custom-date-filter/custom-date-filter.component';
import { AuthService } from 'tw-core-ui';
import { Clipboard } from '@angular/cdk/clipboard';
import { UsersService } from '../../admin-settings/user/user-list/users.service';

@Component({
  selector: 'app-cabinets-document-list',
  templateUrl: './cabinets-document-list.component.html',
  styleUrls: ['./cabinets-document-list.component.less']
})

export class CabinetsDocumentListComponent implements OnInit {
  errSub: any;
  userName: any = '';
  cabinetDetailsList = [];
  public aemLabelAuthoring: any;
  public searchModel: CustomSearchBox = {
    containerId: 'search-row',
    headerId: 'search-order-date-row',
    searchId: 'searchIdInput',
    searchButtonId: 'orderSearchButton',
    isResetVisible: false,
    isSearchDisabled: true,
    isSearchOn: false,
    isDateSearchOn: true,
    labelAuthoring: {
      header: '',
      searchPlaceholder: '',
      searchTextAccessible: '',
      fromDateAccessible: '',
      toDateAccessible: '',
      searchByDate: '',
      search: '',
      reset: ''
    },
  };
  public gridModel: CustomAgGrid = {
    gridOptions: {},
    keysToSuppress: [13],
    labelAuthoring: {
      headerPageCount: '',
      filter: '',
      resetFilter: '',
      paginationHeader: '',
      noData: '',
      noDataOnFilter: '',
      noDataOnSearch: '',
      toTop: 'Back to top'
    }
  };
  public gridConfig: GridOptions;
  gridInitialized: boolean;
  public ordersTooltipContentModel: TooltipContent = {
    content: '',
    automationTypeTooltipIcon: 'image',
    automationIdTooltipIcon: 'imgAddressInformation'
  };
  subscription: any;
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  columnDefs;
  rowData: any = [];
  public filteredRowData: any = [];
  hideDownload: Boolean = false;
  isModalOpen: boolean;
  options: GlobalConfig;
  moment = moment_;
  cabinetId: string;
  cabinetName: string;
  cabinetRole: string;
  filename: any;
  filetype: any;
  showModal: Boolean = false;
  gridapi: any;
  cabinetSubscription: any;
  isInfracoAdmin: boolean = false;
  roles = [];
  noData: string;
  noDataOnSearch: string;
  apiLoader: boolean;
  @Output() goToCabinet = new EventEmitter();
  @Output() openUploadDoc = new EventEmitter();
  focusableElement: HTMLElement;
status: any;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private dashBoardSvc: DashboardService,
    private toastr: ToastrService,
    private cabinetsService: CabinetsService,
    private commonService: CommonService,
    private authService: AuthService,
    private homePageService: HomePageService,
    private clipboard: Clipboard,
    private userService: UsersService
  ) {
    const routeParams = this.activeRoute.snapshot.params;
    // this.cabinetId = decodeURIComponent(routeParams.id || '');
    // this.cabinetName = decodeURIComponent(routeParams.name || '');
    // this.cabinetRole = decodeURIComponent(routeParams.role || '');
    this.cabinetId = routeParams.id?.replace(/%[^%]+?0/g, ' ');
    this.cabinetName = routeParams.name?.replace(/%[^%]+?0/g, ' ');
    this.cabinetRole = routeParams.role?.replace(/%[^%]+?0/g, ' ');
    this.userName = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail'];
    this.getRoleDetails();
  }

  ngOnInit(): void {
    this.commonService.showTopButtonInFooter(true);
    this.options = this.toastr.toastrConfig;
    this.getLabels();
    const roles = this.commonService.getroleDetails().customerList;
    if (!roles.some((obj) => obj.role.includes('NBN') || obj.function.includes('NBN'))) {
      this.router.navigateByUrl('/access-denied');
    }
  }

  getLabels() {
    return new Promise((resolve, reject) => {
      this.cabinetsService.getLoloTrainingMaterialModel().then(() => {
        this.aemLabelAuthoring = this.cabinetsService.LoloTrainingLabelAuthoringDataObj;
        this.searchModel.labelAuthoring = this.aemLabelAuthoring.form.searchBox;
        this.gridModel.labelAuthoring = this.aemLabelAuthoring.form.grid;
        this.ordersTooltipContentModel.content = this.aemLabelAuthoring.miscellaneous.tooltipContent;
        if (!this.noDataOnSearch || !this.noData) {
          this.noDataOnSearch = this.gridModel.labelAuthoring.noDataOnSearch;
          this.noData = this.gridModel.labelAuthoring.noData;
        }
        this.createColumnDefs();
        this.successMsg();
        resolve(true);
      }).catch((err) => {
      });
    });
  }

  getRoleDetails() {
    const roles = this.commonService.getroleDetails();
    this.roles = roles.customerList;
    const app = roles.appList;
    const customerDetails = this.homePageService.getCustomerDetails().isInfraCoCustomer || app.includes('infraco');
    this.roles?.some((a) => (a?.role === 'NBN Administrator' && a?.function === 'NBN DA Documents'));
    const isDocFunction = (customerDetails && this.roles?.some((a) => (a?.role === 'NBN Administrator' && a?.function === 'NBN DA Documents')));
    if (isDocFunction) {
      this.isInfracoAdmin = true;
    } else {
      this.isInfracoAdmin = false;
    }
  }

  createColumnDefs() {
    return new Promise((resolve, reject) => {
      this.cabinetsService.getGridHeader().then(() => {
        this.columnDefs = this.cabinetsService.documentListGridHeader.headers;
        if (this.columnDefs) {
          this.createRowData();
        }
        this.columnDefs.forEach((x: any) => {
          if (x.cellRendererFramework) {
            if (x.colId == 'cabinets-grid-actionItems') {
              x.cellRendererParams.clicked = function (field: any, type) {
                switch (type) {
                  case 'view':
                    this.viewDocument(field);
                    break;
                  case 'download':
                    this.download(field);
                    break;
                  case 'copy':
                    this.copyDocument(field);
                    break;
                  case 'delete':
                    this.deleteDocument(field);
                    break;
                }
              }.bind(this);
            } else if (x.colId == 'cabinets-grid-documentTitle') {
              x.cellRendererParams.clicked = function (field: any) {
                this.download(field);
              }.bind(this);
            }
          }

        });
        resolve(true);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  viewDocument(documentDetails) {
    this.commonService.setShowHomeContent(true);
    const filename = documentDetails.fileName;
    const modCid = documentDetails.cabinetId;
    const serailizeUrl = this.router.serializeUrl(this.router.createUrlTree([`document/${modCid}/${filename}`]));
    window.open(serailizeUrl, '_blank');
  }

  download(documentDetails) {
    const filename = (documentDetails) ? documentDetails.fileName : '',
      filetype = (documentDetails) ? documentDetails.documentType : '';
    const name = documentDetails.cabinetId + '_' + filename;
    this.dashBoardSvc.downloadFile(name).then(async res => {
      if (res) {
        if (res['body'].type === "application/json") {
          let content = JSON.parse(await res['body'].text());
        }
        importedSaveAs(res['body'], filename);
      }
    }).catch((err) => {
      if (err?.status === 401) {
        this.router.navigateByUrl('/access-denied');
      } else {
      }
    });
  }

  copyDocument(documentDetails) {
    const filename = documentDetails.fileName;
    const modCid = documentDetails.cabinetId;
    let url = window.location.origin + `/document/${modCid}/${filename}`;
    this.clipboard.copy(url);
  }

  removeDocument: any;
  deleteDocument(documentDetails) {
    this.filename = documentDetails.fileName;
    this.showModal = true;
    setTimeout(() => {
      this.focusableElement = document.getElementById('closePop');
      this.focusableElement.focus();
    }, 1);
    this.commonService.trapFocusInModal('closePop', 'rem');
  }

  closeModal($event) {
    if ($event.target?.innerText === 'Remove') {
      this.cabinetsService.removeDocumentList(`${this.cabinetId}_${this.filename}`).then(res => {
        if (res && (res['code'] == 202 || res['status'] == 202)) {
          let index = this.rowData.findIndex(x => x.fileName === this.filename);
          this.rowData.splice(index, 1);
          this.gridapi.setRowData(this.rowData);
        }
      }).catch((err) => {
      });
    }
    this.showModal = false;
  }

  onClickRemove() {
    this.isModalOpen = false;
    this.gridInitialized = false;
    this.cabinetsService.removeDocumentList(this.removeDocument).then((resp: any) => {
      this.rowData = resp;
      this.filteredRowData = []; // Prod issue -  IN3 production issue - searched data not getting downloaded
      this.createGridConfig();
      if (this.rowData.length > 0) {
        this.gridModel.labelAuthoring.noDataOnSearch = this.noDataOnSearch;
      } else {
        this.gridModel.labelAuthoring.noDataOnSearch = this.noData;
      }
    })
      .catch(err => {
        this.rowData = [];
        this.gridModel.labelAuthoring.noDataOnSearch = this.noData;
        this.createGridConfig();
      });
  }

  createRowData() {
    this.apiLoader = true;
    this.getOrderListDetails(null).then((result: any) => {
      this.apiLoader = false;
      this.rowData = result?.data;
      this.status=result?.status;
      this.createGridConfig();
      if (this.rowData.length > 0) {
        this.rowData.forEach(element => {
          element.pubDate = this.moment(element.pubDate).format('DD MMM YYYY HH:mm')
        });
        this.gridModel.labelAuthoring.noDataOnSearch = this.noDataOnSearch;
      }
       else if (this.status === 401) {
        this.router.navigateByUrl('/access-denied');
      } else {
        this.gridModel.labelAuthoring.noDataOnSearch = this.noData;
      }
    }
    , error => {
      if (error.status === 401) {
        this.router.navigateByUrl('/access-denied');
      }
      else{
      this.apiLoader = false;
      this.rowData = [];
      this.gridModel.labelAuthoring.noDataOnSearch = this.noData;
      this.createGridConfig();
      }
    });
  }

  getOrderListDetails(searchDetails) {
    let jsonData: any = {
      startDate: searchDetails?.serchfromDate ? searchDetails.serchfromDate : null,
      endDate: searchDetails?.serchtoDate ? searchDetails.serchtoDate : null,
      cabinetId: this.cabinetId,
      searchKeyword: searchDetails?.id ? searchDetails.id : null
    };
    return this.cabinetsService.getDocumentGridData(jsonData);
  }

  createGridConfig() {
    this.gridConfig = <GridOptions>{};
    this.gridConfig.paginationPageSize = 10;
    this.gridConfig.columnDefs = this.columnDefs;
    this.gridConfig.rowData = this.rowData;
    this.gridConfig.suppressHorizontalScroll = true;
    this.gridConfig.suppressRowHoverHighlight = false;
    this.gridConfig.suppressRowClickSelection = false;
    this.gridConfig.rowSelection = 'single';
    this.gridConfig.pagination = true;
    this.gridConfig.frameworkComponents = {
      CheckboxFilterComponent: <any>CheckboxFilterComponent,
      customTextFilter: <any>CustomTextFilterComponent,
      CustomDateFilterComponent: <any>CustomDateFilterComponent
    };
    this.gridModel.gridOptions = this.gridConfig;
    setTimeout(() => {
      this.gridInitialized = true;
    })

  }

  gridReady($event) {
    this.gridapi = ($event)?.value.event.api;
    this.gridConfig.api = this.gridapi;

  }

  onFilterSelected(filteredData) {
    this.filteredRowData = [];
    if (filteredData && Object.keys(filteredData).length != 0) {
      if (filteredData.filteredRowData && filteredData.filteredRowData.length > 0) {
        filteredData.filteredRowData.forEach(row => {
          if (row.data) {
            this.filteredRowData.push(row.data);
          }
        });
      }
    }
    else {
      filteredData = this.gridConfig.api ? this.gridConfig.api['rowModel']['rowsToDisplay'] : '';
      if (filteredData && filteredData.length > 0) {
        filteredData.forEach(row => {
          if (row.data) {
            this.filteredRowData.push(row.data);
          }
        });
      }
    }
    this.hideDownload = (this.filteredRowData.length === 0) ? true : false;
  }

  filterSearchInput(event) {
    this.gridInitialized = false;
    this.apiLoader = true;
    this.getOrderListDetails(event).then((result: any) => {
      this.cabinetsService.getGridHeader().then(() => {
        this.apiLoader = false;
        this.columnDefs = this.cabinetsService.documentListGridHeader.headers;
        this.columnDefs.forEach((x: any) => {
          if (x.cellRendererFramework) {
            if (x.colId == 'cabinets-grid-actionItems') {
              x.cellRendererParams.clicked = function (field: any, type) {
                switch (type) {
                  case 'view':
                    this.viewDocument(field);
                    break;
                  case 'download':
                    this.download(field);
                    break;
                  case 'copy':
                    this.copyDocument(field);
                    break;
                  case 'delete':
                    this.deleteDocument(field);
                    break;
                }
              }.bind(this);
            } else if (x.colId == 'cabinets-grid-documentTitle') {
              x.cellRendererParams.clicked = function (field: any) {
                this.download(field);
              }.bind(this);
            }
          }
        });
      }).catch((err) => {
        this.apiLoader = false;
        this.router.navigateByUrl('/error');
      });
      this.rowData = result?.data;
      this.gridModel.labelAuthoring.noDataOnSearch = this.noDataOnSearch;
      if (this.rowData) {
        this.createGridConfig();
      }
    }, error => {
      this.apiLoader = false;
      this.rowData = [];
      this.gridModel.labelAuthoring.noDataOnSearch = this.noDataOnSearch;
      this.createGridConfig();
    });
  }

  resetGrid(value) {
    this.apiLoader = true;
    this.gridInitialized = false;
    this.getOrderListDetails(value).then(async (resp: any) => {
      this.apiLoader = false;
      if (this.columnDefs) {
        this.rowData = resp?.data;
        this.filteredRowData = []; // Prod issue -  IN3 production issue - searched data not getting downloaded
        this.createGridConfig();
        if (this.rowData.length > 0) {
          this.gridModel.labelAuthoring.noDataOnSearch = this.noDataOnSearch;
        } else {
          this.gridModel.labelAuthoring.noDataOnSearch = this.noData;
        }
      }
      this.columnDefs.forEach((x: any) => {
        if (x.cellRendererFramework) {
          if (x.colId == 'cabinets-grid-actionItems') {
            x.cellRendererParams.clicked = function (field: any, type) {
              switch (type) {
                case 'view':
                  this.viewDocument(field);
                  break;
                case 'download':
                  this.download(field);
                  break;
                case 'copy':
                  this.copyDocument(field);
                  break;
                case 'delete':
                  this.deleteDocument(field);
                  break;
              }
            }.bind(this);
          } else if (x.colId == 'cabinets-grid-documentTitle') {
            x.cellRendererParams.clicked = function (field: any) {
              this.download(field);
            }.bind(this);
          }
        }
      });

    })
      .catch(err => {
        this.apiLoader = false;
        this.rowData = [];
        this.gridModel.labelAuthoring.noDataOnSearch = this.noData;
        this.createGridConfig();
      });
  }

  enableSearch() {
    const searchId = document.getElementById('searchIdInput');
    const fromDate = document.getElementById('fromDateDatePicker');
    const toDate = document.getElementById('fromDateDatePicker');
    const searchButtons = document.getElementById('searchButton');
    if ((<HTMLInputElement>searchButtons)) {
      if ((<HTMLInputElement>searchButtons)?.disabled && ((<HTMLInputElement>document.getElementById('closed'))?.checked ||
        (<HTMLInputElement>fromDate).value || (<HTMLInputElement>toDate).value)) {
        (<HTMLInputElement>searchButtons).disabled = false;
        this.searchModel.isSearchOn = true;
        this.searchModel.isSearchDisabled = false;
      } else if (!(<HTMLInputElement>searchId).value && !(<HTMLInputElement>fromDate).value && !(<HTMLInputElement>toDate).value &&
        !(<HTMLInputElement>document.getElementById('closed'))?.checked) {
        (<HTMLInputElement>searchButtons).disabled = true;
        this.searchModel.isSearchOn = false;
        this.searchModel.isSearchDisabled = true;
      }
    }
  }

  inputChange($event) {
    this.enableSearch();
  }

  navigate(path) {
    window.scroll(0, 0);
    this.router.navigateByUrl(path);
  }

  getFileType(filetype) {
    let blobtype;
    switch (filetype) {
      case 'txt': blobtype = 'text/plain'
        break;
      case 'docx': blobtype = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        break;
      case 'doc': blobtype = 'application/msword'
        break;
      case 'pdf': blobtype = 'application/pdf'
        break;
      case 'xlsx': blobtype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        break;
      case 'xls': blobtype = 'application/vnd.ms-excel'
        break;
      case 'xlsb': blobtype = 'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
        break;
      case 'png': blobtype = 'image/png'
        break;
      case 'jpg': blobtype = 'image/jpeg'
        break;
      case 'jpeg': blobtype = 'image/jpeg'
        break;
      case 'pptx': blobtype = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        break;
      case 'zip': blobtype = 'application/zip';
        break;
      case 'csv': blobtype = 'text/csv';
        break;
      case 'bmp': blobtype = 'image/bmp';
        break;
      case 'json': blobtype = 'application/json';
        break;
      case 'ppt': blobtype = ' application/vnd.ms-powerpoint';
        break;

    }
    return blobtype;
  }

  ngOnDestroy() {
    this.commonService.showTopButtonInFooter(false);
    this.commonService.setShowHomeContent(false);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.cabinetSubscription) {
      this.cabinetSubscription.unsubscribe();
    }
  }

  encodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

  navigateToUpload() {
    // this.openUploadDoc.emit(true);
    // this.router.navigateByUrl('/upload-cabinets-document');
    // const cabinetName = this.encodeURIComponent(this.cabinetName);
    // const cabinetId = this.encodeURIComponent(this.cabinetId);
    // const cabinetRole = this.encodeURIComponent(this.cabinetRole);
    const cabinetName = this.cabinetName;
    const cabinetId = this.cabinetId;
    const cabinetRole = this.cabinetRole;
    this.router.navigate([`home/upload-cabinets-document/${cabinetName}/${cabinetId}/${cabinetRole}`]);
  }

  goToCabinetNav() {
    this.goToCabinet.emit(true);
    this.router.navigateByUrl('/cabinet');
  }

  successMsg() {
    this.errSub = this.cabinetsService.getError.subscribe(val => {
      if (val) {
        this.showToasterMessage(val, '', this.options, 'error');
      }
    });
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-bottom-right';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 5000;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
  }
}
