import { Component, OnInit } from '@angular/core';
import {  NavigationStart, Router } from '@angular/router';
import { CreateQuoteService } from '../../app/quote-management/create-new-quote/create-quote.service';
import { Title } from '@angular/platform-browser';
import { BulkQuoteService } from '../bulk-quote-management/bulk-quote.service';
import { myQuotesService } from '../quote-management/my-quotes/myquotes.service';
import { CommonService } from '../shared/services/common.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-success-page',
  templateUrl: './success-page.component.html',
  styleUrls: ['./success-page.component.less']
})
export class SuccessPageComponent implements OnInit {
  public aemLabelAuthoring: any = null;
  quoteDetails: any;
  orderDetails: any;
  isBulkQuote:boolean = false;
  bulkQuoteId:any;
  pageTitle: string;
  serviceType: string;
  isPredefinedRoute: boolean = false;
  public customerChange: Subscription;
  isOrder: boolean = false;
  constructor(private createQuoteService: CreateQuoteService,private router: Router,private bulkQuoteService: BulkQuoteService, 
    private titleService: Title, private dataSvc: myQuotesService, private commonService: CommonService ) {
    this.getSuccessPageLabels();
    router.events.forEach((event) => {
      if(event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          this.router.navigate(['/myQuotes']);
        }
      }
    });
    this.getPageTitle();
    this.serviceType = this.createQuoteService.getServiceType();
    // Order details
    this.orderDetails = history.state;
    this.isBulkQuote = (this.router.url ==='/bulk-quote-success') ? true :false;
    if(this.isBulkQuote){
      this.bulkQuoteId =  this.bulkQuoteService.getBulkReferenceId();
    }
    if(this.router.url ==='/order-success'){
      this.isOrder = true;
    }
    this.customerChange = this.commonService.getCustomerName().subscribe((data) => {
      this.dataSvc.customerNameDropdownChange(this.isOrder, data, this.quoteDetails);
    });
  }

  getPageTitle(){
    if(this.router.url ==='/bulk-quote-success'){
      this.pageTitle = 'Bulk Quote Success page';
      this.titleService.setTitle('InfraCo Customer Portal - Bulk Quote Success page');
    }else if(this.router.url ==='/order-success'){
      this.pageTitle = 'Order Success page';
      this.titleService.setTitle('InfraCo Customer Portal - Order Success page');
    }else if(this.router.url ==='/success'){
      this.pageTitle = 'Single Quote Success page';
      this.titleService.setTitle('InfraCo Customer Portal - Single Quote Success page');
    }
  }
  ngOnInit(): void {

  //quote details
  if(!this.orderDetails.isOrder){
    this.checkNonRGConditions();
    this.quoteDetails = this.createQuoteService.getQuoteDetails();
    if(this.quoteDetails && this.quoteDetails.data) {
    this.dataSvc.saveSnowID(this.quoteDetails.data.serviceNow_sys_id);
    }
  }



  }

  checkNonRGConditions() {
    let createQuotePayload= this.createQuoteService.getProductDetailsFormValues();
    if (this.serviceType !== 'RG') {
      this.isPredefinedRoute = createQuotePayload.predefinedQuote === 'Yes' ? true : false;
    }
  }

  getSuccessPageLabels() {
    this.createQuoteService
      .getSuccessPageLabel()
      .then((data: any) => {
        this.aemLabelAuthoring = data;
      })
      .catch((error) => { });
  }

  navigateToQuoteSummary(){
    this.router.navigate([`quoteSummary/${this.quoteDetails?.data?.quoteReferenceId}`]);
  }

  navigateToNewQuote(){
    this.createQuoteService.setProductDetailsFormValues(null);
    const navigationExtras = {
      state: {
        formData: null
      }
    }
    this.createQuoteService.isModifyExistingQuote(false);
    this.router.navigateByUrl('/product-details', navigationExtras);
  }

  navigateToCreateBulkQuote() {
    this.router.navigateByUrl('/bulk-quote-request-details');
}

  navigateToMyQuote(){
    this.router.navigate(['myQuotes']);
  }

  navigateToMyOrders(){
    this.router.navigate(['myOrders']);
  }

  ngOnDestroy(): void {
    if (this.customerChange) {
      this.customerChange.unsubscribe();
    }
  }
}
