<form [formGroup]="firstFormGroup">
  <div class="stepper-container">
    <div class="twssp-sprite companyDetailsIcon sprite-m"></div>
    <span class="section-header">Company details</span>
    <span class="side-label">Fields marked with <span class="star">*</span> are mandatory</span>
  </div>
  <hr>
  <div class="row col-12 pt-3">
    <div class="col-4 pl-0 pb-3">
      <label class="user-details-label-top required-field" automationType="label" automationId="lblrole" for="companyName">
        Full Company Name
      </label>
      <app-cui-searchable-dropdown aria-required="true" #businessTypes class="selectDropdowns" formControlName="companyName"
        name="companyName" [items]="companyNameList" [isRequired]="true" [model]="companyNameModel" (click)="touchCompanyName()"
        [placeholder]="'Company name'" automationType="dropdown" automationId="drpdwnroleType" [labelName]="'Records'"
        [noAutoComplete]=false id="companyName" class="user-dropDown" (ngModelChange)="updateExecutionDate($event)"
        required >
      </app-cui-searchable-dropdown>
      <span role="alert" *ngIf="showCompanyName && firstFormGroup?.controls?.companyName?.invalid" class="error-cls">
        <img class="error-icon" src="../../../../assets/images/icon-error.svg" /> Please select company name
      </span>
    </div>
    <!-- Hide Execution Date from duct access form -->
    <div style="visibility: hidden;" class="col-4 datedate">
      <label class="user-details-label-top" role="alert" attr.aria-label="Execution date of facilities access
      agreement"
     for="date" aria-required="true" id="Execution date of facilities access
     agreement" required>Execution date of facilities access
        agreement</label>
      <app-cui-datepicker class="datepicker_core" [datePlaceholder]="'Date'" [datePickerId]="'from_date'"
        [modelDate]="date" [navigation]="false" [model]="fromDatePickerFilter" [accessibleHeader]="'Execution date of facilities access agreement'">
      </app-cui-datepicker>
    </div>
  </div>
  <div class="stepper-container">
    <div class="twssp-sprite customerDetailsIcon sprite-m"></div>
    <span class="section-header">Customer details</span>
  </div>
  <hr>
  <div class="group1">
    <div class="row col-12 pt-3 pb-3" aria-label="Nominated Contact details">
      <div class="col-4 pl-0">
        <label class="user-details-label-top required-field" for="contactName" aria-required="true" required>Nominated contact name
          <span class="field-comment">(For technical enquiries)</span>
        </label>
        <input tabindex="0" type="text" class="form-control field-input" maxlength="50" id="contactName" name="contactName"
          placeholder="Enter contact name" attr.aria-label="Nominated contact name" aria-required="true" formControlName="contactName"
          (ngModelChange)="checkValue(firstFormGroup.controls.isChecked.value)"
          [ngClass]="{'invalid-form-control': (firstFormGroup.controls.contactName.touched && firstFormGroup.controls.contactName.invalid)}">
          <span role="alert" class="error-cls"
          *ngIf="firstFormGroup.controls.contactName.touched && firstFormGroup.controls.contactName.invalid">         
          <img src="/assets/images/telstra/svg/validation_icon.svg" alt="validationIcon" class="crossIcon">
          Please enter contact name</span>
      </div>
      <div class="col-4">
        <label class="user-details-label-top required-field" aria-label="Nominated contact number"  for="contactNumber" aria-required="true" required>Contact number</label>
        <input tabindex="0" type="text"  aria-required="true" class="form-control field-input" maxlength="12" id="contactNumber" name="contactNumber"
          placeholder="Enter contact number" attr.aria-label="Nominated contact number" formControlName="contactNumber"
          (ngModelChange)="checkValue(firstFormGroup.controls.isChecked.value)"
          [ngClass]="{'invalid-form-control': (firstFormGroup.controls.contactNumber.touched && firstFormGroup.controls.contactNumber.invalid)}">
        <span role="alert" class="error-cls"
          *ngIf="firstFormGroup.controls.contactNumber.touched && firstFormGroup.controls.contactNumber.invalid">
          <img src="/assets/images/telstra/svg/validation_icon.svg" alt="validationIcon" class="crossIcon">
          Please enter contact number</span>
      </div>
      <div class="col-4">
        <label class="user-details-label-top required-field" aria-label="Nominated contact email" for="contactEmail" aria-required="true" required>Contact email address</label>
        <input tabindex="0" type="text" aria-required="true" class="form-control field-input" maxlength="200" id="contactEmail" name="contactEmail"
          placeholder="Enter valid email address" attr.aria-label="Nominated contact email" formControlName="contactEmail"
          (ngModelChange)="checkValue(firstFormGroup.controls.isChecked.value)"
          [ngClass]="{'invalid-form-control': (firstFormGroup.controls.contactEmail.touched && firstFormGroup.controls.contactEmail.invalid)}">
        <span role="alert" class="error-cls"
          *ngIf="firstFormGroup.controls.contactEmail.touched && firstFormGroup.controls.contactEmail.invalid">
          <img src="/assets/images/telstra/svg/validation_icon.svg" alt="validationIcon" class="crossIcon">
          Please enter an email address in the format: email@example.com</span>
      </div>
    </div>
    <div class="form-group">
      <input tabindex="0" type="checkbox" aria-required="true" id="copyCheck" name="copyCheck" style="transform: scale(1.45);" formControlName="isChecked"
        (change)="checkValue($event.target.checked)"> &nbsp;
      <label for="copyCheck" class="copyCheck">Same as nominated contact</label>
    </div>
    <div class="row col-12">
      <div class="col-5 pl-0">
        <label class="user-details-label-top required-field" for="ipoContactName" aria-required="true" required>Initial point of contact
          <span class="field-comment">(For Telstra to send the response)</span>
        </label>
        <input tabindex="0" type="text" aria-required="true" class="form-control field-input" maxlength="100"
         id="ipoContactName" name="ipoContactName" style="width: 79%;"
          placeholder="Enter contact name" attr.aria-label="document title" formControlName="ipoContactName"
          [ngClass]="{'invalid-form-control': (firstFormGroup.controls.ipoContactName.touched && firstFormGroup.controls.ipoContactName.invalid)}">
          <span role="alert"
          *ngIf="firstFormGroup.controls.ipoContactName.touched && firstFormGroup.controls.ipoContactName.invalid"
          class="error-cls">
          <img src="/assets/images/telstra/svg/validation_icon.svg" alt="validationIcon" class="crossIcon">
          Please enter initial point of contact name</span>
      </div>
      <div class="col-3" style="margin-left: -8%;">
        <label class="user-details-label-top required-field" aria-label="contact number for Initial point of contact" for="ipoContactNum" aria-required="true" required>Contact number</label>
        <input tabindex="0" type="text" aria-required="true" class="form-control field-input" maxlength="12" 
        id="ipoContactNum" name="ipoContactNum" style="width: 136%;"
          placeholder="Enter contact number" attr.aria-label="document title" formControlName="ipoContactNum"
          [ngClass]="{'invalid-form-control': (firstFormGroup.controls.ipoContactNum.touched && firstFormGroup.controls.ipoContactNum.invalid)}">
        <span role="alert" class="error-cls"
          *ngIf="firstFormGroup.controls.ipoContactNum.touched && firstFormGroup.controls.ipoContactNum.invalid">
          <img src="/assets/images/telstra/svg/validation_icon.svg" alt="validationIcon" class="crossIcon">
          Please enter contact number</span>
      </div>
      <div class="col-3" style="margin-left: 96px;">
        <label class="user-details-label-top required-field" aria-label="contact email for Initial point of contact" for="ipoContactEmail" aria-required="true" required>Contact email
          address</label>
        <input tabindex="0" type="text" aria-required="true" class="form-control field-input" maxlength="200"
         id="ipoContactEmail" name="ipoContactEmail" style="width: 136%;"
          placeholder="Enter valid email address" attr.aria-label="document title" formControlName="ipoContactEmail"
          [ngClass]="{'invalid-form-control': (firstFormGroup.controls.ipoContactEmail.touched && firstFormGroup.controls.ipoContactEmail.invalid)}">
        <span role="alert"
          *ngIf="firstFormGroup.controls.ipoContactEmail.touched && firstFormGroup.controls.ipoContactEmail.invalid"
          class="error-cls">
          <img src="/assets/images/telstra/svg/validation_icon.svg" alt="validationIcon" class="crossIcon">
          Please enter an email address in the format: email@example.com</span>
      </div>
    </div>
  </div>
  <div class="stepper-container pt-3">
    <div class="twssp-sprite studyTypeIcon sprite-m"></div>
    <span class="section-header">Study type required</span>
  </div>
  <hr>
  <div class="row col-12 pt-2 pb-3">
    <div class="col-8 pl-0">
      <label class="user-details-label-top required-field" automationType="label" automationId="lblrole" for="studyType"
        attr.aria-label="Study type">
        Study type
      </label>
      <app-cui-searchable-dropdown #businessTypes tabindex="0" class="selectDropdowns" formControlName="studyType" name="studyType"
        [items]="studyTypeList" [isRequired]=true [model]="studyTypeModel" (click)="touchStudyType()"
        [placeholder]="'Please specify type of study required'" automationType="dropdown" automationId="drpdwnroleType"
        [labelName]="'Records'" [noAutoComplete]=false id="studyType" class="user-dropDown"
        required></app-cui-searchable-dropdown>
        <span role="alert" *ngIf="showStudyType && firstFormGroup?.controls?.studyType?.invalid" class="error-cls">
          <img class="error-icon" src="../../../../assets/images/icon-error.svg" /> Please select study type
        </span>
    </div>
  </div>
  <div class="row col-12" style="margin-bottom: 4rem;">
    <div class="col-4 pl-0">
      <label class="user-details-label-top required-field" for="study" aria-required="true" required>Study reference
        <span class="field-comment">(For this request)</span>
      </label>
      <input tabindex="0" type="text" aria-required="true" class="form-control field-input" maxlength="20" id="study" name="study"
        placeholder="Enter study" attr.aria-label="document title" formControlName="study"
        [ngClass]="{'invalid-form-control': (firstFormGroup.controls.study.touched && firstFormGroup.controls.study.invalid)}">
        <span role="alert" class="error-cls"
        *ngIf="firstFormGroup.controls.study.touched && firstFormGroup.controls.study.invalid">
        <img src="/assets/images/telstra/svg/validation_icon.svg" alt="validationIcon" class="crossIcon">
        Please enter study reference</span>
    </div>
    <div class="col-4">
      <label class="user-details-label-top" for="telstraReservID" aria-required="true">Telstra reservation ID
        <span class="field-comment">(ADR only)</span>
      </label>
      <input tabindex="0" type="text" class="form-control field-input" maxlength="20" id="telstraReservID" name="telstraReservID"
        placeholder="Enter Telstra Reservation ID" attr.aria-label="document title" formControlName="telstraReservID">
    </div>
  </div>
</form>