<div class="toastrContainer" aria-live="polite" role="alert" toastContainer></div>
<div *ngIf="aemLabelAuthoring">
  <form [formGroup]="contactDetailsForm" (ngSubmit)="onSubmit()" class="row contact-details-form-container"
    autocomplete="off">
    <hr />
    <div>
      <div>
        <div>
          <div class="radio-btn-container">
            <label class="radio-btn-label-diverse">{{aemLabelAuthoring?.label?.doYouRequireDetailedAssessment}}
              <span class="inpt-reqrd-cls">*</span></label>
              <mat-radio-group role="radiogroup" formControlName="detailedAssessment"
              class="smart-ui-radio-group disable-radio-container" (change)="createContactDetailsForm($event)"
              [disabled]="isModifyQuote">
              <mat-radio-button id="detailedAssessmentYesRadio" role="radio"  [attr.aria-checked]="(contactDetailsForm.value.detailedAssessment === 'Yes') ? 'true' : 'false'"
                (keyup.enter)="detailedAssessmentRadioFnc(aemLabelAuthoring?.label?.yes)"
                class="col-1 smart-ui-button-group diversity-radio-btn" [value]="aemLabelAuthoring?.label?.yes">
                <div class="mat-custom-label"><span aria-label="Do you require a detailed assessment required yes">{{aemLabelAuthoring?.label?.yes}}
                  </span></div>
              </mat-radio-button>
              <mat-radio-button id="detailedAssessmentNoRadio" role="radio"  [attr.aria-checked]="(contactDetailsForm.value.detailedAssessment === 'No') ? 'true' : 'false'"
                (keyup.enter)="detailedAssessmentRadioFnc(aemLabelAuthoring?.label?.no)"
                class="col-1 smart-ui-button-group diversity-radio-btn" [value]="aemLabelAuthoring?.label?.no">
                <div class="mat-custom-label"><span>{{aemLabelAuthoring?.label?.no}}
                  </span></div>
              </mat-radio-button>
            </mat-radio-group>

          </div>
        </div>
      </div>
    </div>


    <!-- {{contactDetailsForm?.controls['detailedAssessment'].value}}
----
{{aemLabelAuthoring.label.yes}} -->
    <div *ngIf="contactDetailsForm?.controls['detailedAssessment'].value == aemLabelAuthoring?.label?.yes">
      <div class="mt-5 mb-5">
        <img src="../../../.././../../assets/images/SiteContactDetails.svg" class="fiber-icon" />
        <label class="radio-btn-label">{{aemLabelAuthoring?.label?.selectContactDetails}}</label>
      </div>
      <div class="site-A-end-address col-12 m-t-40">
        {{ (isDiversity)? aemLabelAuthoring?.label?.path1SiteAEndAddress?.labelDiversity :
        aemLabelAuthoring.label.path1SiteAEndAddress.label}}
      </div>
      <div class="row mt-4">
        <div class="col-12 col-md-4">
          <label
            [for]="aemLabelAuthoring?.label?.path1SiteAEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteAEndAddress?.siteContactName"
            class="form-label site-details-form-label">{{aemLabelAuthoring?.label?.path1SiteAEndAddress?.siteContactName}}
            <span class="inpt-reqrd-cls" aria-hidden="true">*</span>
          </label>
          <input (paste)="onPasteCheckNumber($event)"
          (keydown)="onNumericKeyPressDown($event)" aria-required="true" aria-label="Path1 site A end address Site Contact Name" aria-describedby="A1siteContactName_error" maxlength="21"
            formControlName="A1siteContactName" type="text"
            placeholder="Enter {{aemLabelAuthoring.label.path1SiteAEndAddress.siteContactName | lowercase}}"
            class="form-control control site-details-form-input"
            [id]="aemLabelAuthoring?.label?.path1SiteAEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteAEndAddress?.siteContactName">

          <ng-container *ngIf="contactDetailsForm.controls['A1siteContactName']?.invalid || (contactDetailsForm.controls['A1siteContactName']?.dirty || contactDetailsForm.controls['A1siteContactName']?.touched)">
            <small id="A1siteContactName_error" *ngIf="contactDetailsForm.controls['A1siteContactName']?.value?.length < 1 && (contactDetailsForm.controls['A1siteContactName']?.dirty || contactDetailsForm.controls['A1siteContactName']?.touched)"
            class="text-danger errorMsg errorMsg">
            <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
            Please enter {{aemLabelAuthoring?.label?.path1SiteAEndAddress?.siteContactName}}
          </small>
          <small id="A1siteContactName_error"
            *ngIf="contactDetailsForm.controls['A1siteContactName']?.value?.length && !contactDetailsForm.controls['A1siteContactName']?.required && contactDetailsForm.controls['A1siteContactName']?.invalid"
            class="text-danger errorMsg">
            <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
            <!-- Please enter a valid {{aemLabelAuthoring.label.path1SiteAEndAddress.siteContactName}} -->
            {{aemLabelAuthoring.searchForm.additionalAddressInformationInvalidError}}
          </small>
          </ng-container>
        </div>

        <div class="col-12 col-md-4">
          <label
            [for]="aemLabelAuthoring?.label?.path1SiteAEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteAEndAddress?.siteContactNumber"
            class="form-label site-details-form-label">{{aemLabelAuthoring?.label?.path1SiteAEndAddress?.siteContactNumber}}
            <span class="inpt-reqrd-cls" aria-hidden="true">*</span>
          </label>
          <div class="input-group">
            <!-- <select class="form-select border-radius-0 phoneCountryList select_box" aria-label="Phone country code list"
              formControlName="A1phoneCountryCode">
              <option *ngFor="let d of phoneCountryList" [value]="d.code">{{d.code}}</option>
            </select> -->
            <input aria-required="true" aria-label="Path1 site A end address Site Contact Number" aria-describedby="A1siteContactNumber_error"  maxlength="10"
              formControlName="A1siteContactNumber" type="tel"
              placeholder="Enter {{aemLabelAuthoring.label.path1SiteAEndAddress.siteContactNumber | lowercase}}"
              class="form-control control site-details-form-input phoneCountryList-input"
              [id]="aemLabelAuthoring?.label?.path1SiteAEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteAEndAddress?.siteContactNumber"
              (keypress)="numberOnly($event)">
          </div>


          <ng-container id="A1siteContactNumber_error"
            *ngIf="contactDetailsForm.controls['A1siteContactNumber']?.invalid || (contactDetailsForm.controls['A1siteContactNumber']?.dirty || contactDetailsForm.controls['A1siteContactNumber']?.touched)">
            <small *ngIf="(contactDetailsForm.controls['A1siteContactNumber']?.dirty || contactDetailsForm.controls['A1siteContactNumber']?.touched) && !contactDetailsForm.controls['A1siteContactNumber']?.value?.length"
              class="text-danger errorMsg errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              Please enter {{aemLabelAuthoring.label.path1SiteAEndAddress.siteContactNumber}}
            </small>
            <small id="A1siteContactNumber_error"
              *ngIf="!contactDetailsForm.controls['A1siteContactNumber'].invalid && (contactDetailsForm.controls['A1siteContactNumber']?.value?.length > 0 && contactDetailsForm.controls['A1siteContactNumber']?.value?.length !=10)"
              class="text-danger errorMsg errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              Please enter a valid {{aemLabelAuthoring.label.path1SiteAEndAddress.siteContactNumber}}
            </small>
          </ng-container>

        </div>

        <div class="col-12 col-md-4">
          <label
            [for]="aemLabelAuthoring?.label?.path1SiteAEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteAEndAddress?.siteContactEmailAddress"
            class="form-label site-details-form-label">{{aemLabelAuthoring?.label?.path1SiteAEndAddress?.siteContactEmailAddress}}
          </label>
          <input  maxlength="100"
            aria-describedby="A1siteContactEmailAddress_error" aria-label="Path1 site A end address Site Contact Email Address" formControlName="A1siteContactEmailAddress" type="email"
            placeholder="Enter {{aemLabelAuthoring.label.path1SiteAEndAddress.siteContactEmailAddress | lowercase}}"
            class="form-control control site-details-form-input"
            [id]="aemLabelAuthoring?.label?.path1SiteAEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteAEndAddress?.siteContactEmailAddress">

            <ng-container *ngIf="contactDetailsForm.controls['A1siteContactEmailAddress']?.invalid && (contactDetailsForm.controls['A1siteContactEmailAddress']?.dirty || contactDetailsForm.controls['A1siteContactEmailAddress']?.touched || contactDetailsForm.get('A1siteContactEmailAddress').hasError('forbiddenChars'))">
            <small id="A1siteContactEmailAddress_error" class="text-danger errorMsg errorMsg" *ngIf="contactDetailsForm.controls['A1siteContactEmailAddress'].value?.length > 0 && contactDetailsForm.controls['A1siteContactEmailAddress']?.invalid && !contactDetailsForm.get('A1siteContactEmailAddress').hasError('forbiddenChars')">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              {{aemLabelAuthoring.label?.inValidEmailAddressErrorMsg}}
            </small>
            <small id="A1siteContactEmailAddress_error" class="text-danger errorMsg errorMsg"
            *ngIf="contactDetailsForm.controls['A1siteContactEmailAddress'].value?.length && contactDetailsForm.controls['A1siteContactEmailAddress'].hasError('forbiddenChars')"
            class="text-danger errorMsg errorMsg">
            <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
           {{aemLabelAuthoring?.searchForm?.additionalAddressInformationInvalidError}}
          </small>
          </ng-container>
          <!-- <ng-container
            *ngIf="contactDetailsForm.controls['A1siteContactEmailAddress']?.invalid && (contactDetailsForm.controls['A1siteContactEmailAddress']?.dirty || contactDetailsForm.controls['A1siteContactEmailAddress']?.touched)">
            <small *ngIf="contactDetailsForm.controls['A1siteContactEmailAddress']?.errors.required" class="text-danger errorMsg">
              <img  src="../../../../../assets/images/icon-error.svg" class="error-icon">
              {{aemLabelAuthoring.label.inValidEmailAddressErrorMsg}}
            </small>
            <small
              *ngIf="!contactDetailsForm.controls['A1siteContactEmailAddress']?.errors.required && contactDetailsForm.controls['A1siteContactEmailAddress']?.invalid"
              class="text-danger errorMsg">
              <img  src="../../../../../assets/images/icon-error.svg" class="error-icon">
              {{aemLabelAuthoring.label.inValidEmailAddressErrorMsg}}
            </small>
          </ng-container> -->
        </div>

      </div>

      <div class="row m-t-40">
        <div class="col-12 col-md-4">
          <label
            [for]="aemLabelAuthoring?.label?.path1SiteAEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteAEndAddress?.alternativeSiteContactName"
            class="form-label site-details-form-label">{{aemLabelAuthoring?.label?.path1SiteAEndAddress?.alternativeSiteContactName}}
          </label>
          <input (paste)="onPasteCheckNumber($event)" (keydown)="onNumericKeyPressDown($event)" maxlength="21" aria-describedby="A1alternativeSiteContactName_error"
            formControlName="A1alternativeSiteContactName" type="text" aria-label="Path1 site A end address Alternative Site Contact Name"
            placeholder="Enter {{aemLabelAuthoring?.label?.path1SiteAEndAddress?.alternativeSiteContactName | lowercase}}"
            class="form-control control site-details-form-input"
            [id]="aemLabelAuthoring?.label?.path1SiteAEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteAEndAddress?.alternativeSiteContactName">
          <ng-container
            *ngIf="contactDetailsForm.controls['A1alternativeSiteContactName']?.required || contactDetailsForm.controls['A1alternativeSiteContactName']?.invalid || (contactDetailsForm.controls['A1alternativeSiteContactName']?.dirty || contactDetailsForm.controls['A1alternativeSiteContactName']?.touched)">
            <small id="A1alternativeSiteContactName_error"
              *ngIf="contactDetailsForm.controls['A1alternativeSiteContactName']?.required && !contactDetailsForm.get('A1alternativeSiteContactName').hasError('forbiddenChars')"
              class="text-danger errorMsg errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              Please enter {{aemLabelAuthoring?.label?.path1SiteAEndAddress?.alternativeSiteContactName}}
            </small>
            <small id="A1alternativeSiteContactName_error"
              *ngIf="contactDetailsForm.controls['A1alternativeSiteContactName']?.value?.length && contactDetailsForm.get('A1alternativeSiteContactName').hasError('forbiddenChars')"
              class="text-danger errorMsg errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              <!-- Please enter a valid {{aemLabelAuthoring.label.path1SiteAEndAddress.alternativeSiteContactName}} -->
              {{aemLabelAuthoring.searchForm.additionalAddressInformationInvalidError}}
            </small>
            <small id="A1alternativeSiteContactName_error" class="errorMsg" *ngIf="((!contactDetailsForm.controls['A1alternativeSiteContactNumber'].value) && !contactDetailsForm.controls['A1alternativeSiteContactEmailAddress'].value) &&  contactDetailsForm.controls['A1alternativeSiteContactName'].value?.length && !contactDetailsForm.get('A1alternativeSiteContactName').hasError('forbiddenChars')">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              {{aemLabelAuthoring.validationMsg.alternativeSiteErrorMsg}}
            </small>
          </ng-container>
        </div>

        <div class="col-12 col-md-4">
          <label
            [for]="aemLabelAuthoring?.label?.path1SiteAEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteAEndAddress?.alternativeSiteContactNumber"
            class="form-label site-details-form-label">{{aemLabelAuthoring?.label?.path1SiteAEndAddress?.alternativeSiteContactNumber}}
          </label>
          <div class="input-group">
            <!-- <select class="form-select border-radius-0 phoneCountryList select_box" aria-label="Phone country code list"
              formControlName="A1alternativePhoneCountryCode">
              <option *ngFor="let d of phoneCountryList" [value]="d.code">{{d.code}}</option>
            </select> -->
            <input  maxlength="10" aria-label="Path1 site A end address Alternative Site Contact Number" aria-describedby="A1alternativeSiteContactNumber_error"
              formControlName="A1alternativeSiteContactNumber" type="tel"
              placeholder="Enter {{aemLabelAuthoring?.label?.path1SiteAEndAddress?.alternativeSiteContactNumber | lowercase}}"
              class="form-control control site-details-form-input phoneCountryList-input"
              [id]="aemLabelAuthoring?.label?.path1SiteAEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteAEndAddress?.alternativeSiteContactNumber"
              (keypress)="numberOnly($event)">
          </div>


          <ng-container id="A1alternativeSiteContactNumber_error"
            *ngIf="contactDetailsForm.controls['A1alternativeSiteContactNumber']?.invalid || (contactDetailsForm.controls['A1alternativeSiteContactNumber']?.dirty || contactDetailsForm.controls['A1alternativeSiteContactNumber']?.touched)">
            <!-- <small *ngIf="(contactDetailsForm.controls['A1alternativeSiteContactNumber']?.dirty || contactDetailsForm.controls['A1alternativeSiteContactNumber']?.touched) && !contactDetailsForm.controls['A1alternativeSiteContactNumber']?.value?.length"
              class="text-danger errorMsg errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              Please enter {{aemLabelAuthoring.label.path1SiteAEndAddress.alternativeSiteContactNumber}}
            </small> -->
            <small id="A1alternativeSiteContactNumber_error"
              *ngIf="!contactDetailsForm.controls['A1alternativeSiteContactNumber'].invalid && (contactDetailsForm.controls['A1alternativeSiteContactNumber']?.value?.length > 0 && contactDetailsForm.controls['A1alternativeSiteContactNumber']?.value?.length !=10)"
              class="text-danger errorMsg errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              Please enter a valid {{aemLabelAuthoring?.label?.path1SiteAEndAddress?.alternativeSiteContactNumber}}
            </small>
          </ng-container>
        </div>

        <div class="col-12 col-md-4">
          <label
            [for]="aemLabelAuthoring?.label?.path1SiteAEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteAEndAddress?.alternativeSiteContactEmailAddress"
            class="form-label site-details-form-label">{{aemLabelAuthoring?.label?.path1SiteAEndAddress?.alternativeSiteContactEmailAddress}}
          </label>
          <input aria-describedby="A1alternativeSiteContactEmailAddress_error" maxlength="100"
            formControlName="A1alternativeSiteContactEmailAddress" type="email" aria-label="Path1 site A end address Alternative Site Contact Email Address"
            placeholder="Enter {{aemLabelAuthoring?.label?.path1SiteAEndAddress?.alternativeSiteContactEmailAddress | lowercase}}"
            class="form-control control site-details-form-input"
            [id]="aemLabelAuthoring?.label?.path1SiteAEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteAEndAddress?.alternativeSiteContactEmailAddress">

          <ng-container
          *ngIf="contactDetailsForm.controls['A1alternativeSiteContactEmailAddress']?.invalid ||
          (contactDetailsForm.controls['A1alternativeSiteContactEmailAddress']?.dirty ||
           contactDetailsForm.controls['A1alternativeSiteContactEmailAddress']?.touched ||
           contactDetailsForm.get('A1alternativeSiteContactEmailAddress').hasError('forbiddenChars'))">
            <small id="A1alternativeSiteContactEmailAddress_error" class="text-danger errorMsg errorMsg" *ngIf="contactDetailsForm.controls['A1alternativeSiteContactEmailAddress'].value?.length > 0 && contactDetailsForm.controls['A1alternativeSiteContactEmailAddress'].invalid && !contactDetailsForm.get('A1alternativeSiteContactEmailAddress').hasError('forbiddenChars')">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              {{aemLabelAuthoring.label?.inValidEmailAddressErrorMsg}}
            </small>
            <small id="A1alternativeSiteContactEmailAddress_error" class="text-danger errorMsg errorMsg"
            *ngIf="contactDetailsForm.controls['A1alternativeSiteContactEmailAddress']?.value?.length && contactDetailsForm.controls['A1alternativeSiteContactEmailAddress']?.hasError('forbiddenChars')"
            class="text-danger errorMsg errorMsg">
            <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
           {{aemLabelAuthoring?.searchForm?.additionalAddressInformationInvalidError}}
          </small>
          </ng-container>

        </div>
      </div>

      <div class="mt-5">
        <hr />
      </div>

      <div class="col-12 m-t-40">
        <div class="form-check form-check-inline margin-right-50px">
          <input formControlName="sameAsAEndContact"
            (change)="onCheckChange($event, 'sameAsPath1AEndContactDetails', 'sameAsPath1ZEndContactDetails')"
            [checked]="false" class="form-check-input checkbox-input" type="checkbox"
            [id]="aemLabelAuthoring?.label?.path1SiteZEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteZEndAddress?.sameAsPath1AEndContactDetails">
          <label class="form-check-label checkbox-text"
            [for]="aemLabelAuthoring?.label?.path1SiteZEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteZEndAddress?.sameAsPath1AEndContactDetails">{{aemLabelAuthoring?.label?.path1SiteZEndAddress?.sameAsPath1AEndContactDetails}}</label>
        </div>
      </div>

      <div class="site-A-end-address col-12 m-t-40">
        {{ (isDiversity)? aemLabelAuthoring?.label?.path1SiteZEndAddress?.labelDiversity :
        aemLabelAuthoring?.label?.path1SiteZEndAddress?.label}}
      </div>
      <div class="row mt-4">
        <div class="col-12 col-md-4">
          <label
            [for]="aemLabelAuthoring?.label?.path1SiteZEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteZEndAddress?.siteContactName"
            class="form-label site-details-form-label">{{aemLabelAuthoring?.label?.path1SiteZEndAddress?.siteContactName}}
            <span class="inpt-reqrd-cls" aria-hidden="true">*</span>
          </label>
          <input (paste)="onPasteCheckNumber($event)" (keydown)="onNumericKeyPressDown($event)" aria-required="true" aria-label="Path1 site Z end address Site Contact Name" aria-describedby="Z1siteContactName_error" maxlength="21"
            formControlName="Z1siteContactName" type="text"
            placeholder="Enter {{aemLabelAuthoring?.label?.path1SiteZEndAddress?.siteContactName | lowercase}}"
            class="form-control control site-details-form-input" [readonly]="sameAsPath1ZEndContactDetailsDisable"
            [id]="aemLabelAuthoring?.label?.path1SiteZEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteZEndAddress?.siteContactName">

          <ng-container *ngIf="contactDetailsForm.controls['Z1siteContactName']?.invalid || (contactDetailsForm.controls['Z1siteContactName']?.dirty || contactDetailsForm.controls['Z1siteContactName']?.touched)">
            <small id="Z1siteContactName_error" *ngIf="contactDetailsForm.controls['Z1siteContactName']?.value?.length < 1 && (contactDetailsForm.controls['Z1siteContactName']?.dirty || contactDetailsForm.controls['Z1siteContactName']?.touched)"
            class="text-danger errorMsg errorMsg">
            <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
            Please enter {{aemLabelAuthoring?.label?.path1SiteZEndAddress?.siteContactName}}
          </small>
          <small id="Z1siteContactName_error"
            *ngIf="contactDetailsForm.controls['Z1siteContactName']?.value?.length && !contactDetailsForm.controls['Z1siteContactName']?.required && contactDetailsForm.controls['Z1siteContactName']?.invalid"
            class="text-danger errorMsg">
            <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
            <!-- Please enter a valid {{aemLabelAuthoring.label.path1SiteAEndAddress.siteContactName}} -->
            {{aemLabelAuthoring?.searchForm?.additionalAddressInformationInvalidError}}
          </small>
          </ng-container>
        </div>

        <div class="col-12 col-md-4">
          <label
            [for]="aemLabelAuthoring?.label?.path1SiteZEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteZEndAddress?.siteContactNumber"
            class="form-label site-details-form-label">{{aemLabelAuthoring?.label?.path1SiteZEndAddress?.siteContactNumber}}
            <span class="inpt-reqrd-cls" aria-hidden="true">*</span>
          </label>
          <div class="input-group">

            <input aria-required="true" aria-label="Path1 site Z end address Site Contact Number" aria-describedby="Z1siteContactNumber_error"  maxlength="10"
              formControlName="Z1siteContactNumber" type="tel"
              placeholder="Enter {{aemLabelAuthoring?.label?.path1SiteZEndAddress?.siteContactNumber | lowercase}}"
              class="form-control control site-details-form-input phoneCountryList-input"
              [readonly]="sameAsPath1ZEndContactDetailsDisable"
              [id]="aemLabelAuthoring?.label?.path1SiteZEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteZEndAddress?.siteContactNumber"
              (keypress)="numberOnly($event)">
          </div>
          <ng-container id="Z1siteContactNumber_error"
            *ngIf="contactDetailsForm.controls['Z1siteContactNumber']?.invalid || (contactDetailsForm.controls['Z1siteContactNumber']?.dirty || contactDetailsForm.controls['Z1siteContactNumber']?.touched)">
            <small *ngIf="(contactDetailsForm.controls['Z1siteContactNumber']?.dirty || contactDetailsForm.controls['Z1siteContactNumber']?.touched) && !contactDetailsForm.controls['Z1siteContactNumber']?.value?.length"
              class="text-danger errorMsg errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              Please enter {{aemLabelAuthoring.label.path1SiteAEndAddress.siteContactNumber}}
            </small>
            <small id="Z1siteContactNumber_error"
              *ngIf="(!contactDetailsForm.controls['Z1siteContactNumber']?.invalid) && (contactDetailsForm.controls['Z1siteContactNumber']?.value?.length > 0 && contactDetailsForm.controls['Z1siteContactNumber']?.value?.length !=10)"
              class="text-danger errorMsg errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              Please enter a valid {{aemLabelAuthoring.label.path1SiteAEndAddress.siteContactNumber}}
            </small>
          </ng-container>
        </div>

        <div class="col-12 col-md-4">
          <label
            [for]="aemLabelAuthoring?.label?.path1SiteZEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteZEndAddress?.siteContactEmailAddress"
            class="form-label site-details-form-label">{{aemLabelAuthoring?.label?.path1SiteZEndAddress?.siteContactEmailAddress}}
          </label>
          <input aria-describedby="Z1siteContactEmailAddress_error"
            maxlength="100" aria-label="Path1 site Z end address Site Contact Email Address" formControlName="Z1siteContactEmailAddress" type="email"
            placeholder="Enter {{aemLabelAuthoring?.label?.path1SiteZEndAddress?.siteContactEmailAddress | lowercase}}"
            class="form-control control site-details-form-input" [readonly]="sameAsPath1ZEndContactDetailsDisable"
            [id]="aemLabelAuthoring?.label?.path1SiteZEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteZEndAddress?.siteContactEmailAddress">


          <ng-container *ngIf="contactDetailsForm.controls['Z1siteContactEmailAddress']?.invalid || (contactDetailsForm.controls['Z1siteContactEmailAddress']?.dirty || contactDetailsForm.controls['Z1siteContactEmailAddress']?.touched || contactDetailsForm.get('Z1siteContactEmailAddress').hasError('forbiddenChars'))">
            <small id="Z1siteContactEmailAddress_error" class="text-danger errorMsg errorMsg" *ngIf="contactDetailsForm.controls['Z1siteContactEmailAddress']?.value?.length > 0 && contactDetailsForm.controls['Z1siteContactEmailAddress']?.invalid && !contactDetailsForm.get('Z1siteContactEmailAddress').hasError('forbiddenChars')">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              {{aemLabelAuthoring.label?.inValidEmailAddressErrorMsg}}
            </small>
            <small id="Z1siteContactEmailAddress_error" class="text-danger errorMsg errorMsg"
            *ngIf="contactDetailsForm.controls['Z1siteContactEmailAddress']?.value?.length && contactDetailsForm.controls['Z1siteContactEmailAddress']?.hasError('forbiddenChars')"
            class="text-danger errorMsg errorMsg">
            <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
           {{aemLabelAuthoring?.searchForm?.additionalAddressInformationInvalidError}}
          </small>
          </ng-container>

        </div>

      </div>

      <div class="row m-t-40">
        <div class="col-12 col-md-4">
          <label
            [for]="aemLabelAuthoring?.label?.path1SiteZEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteZEndAddress?.alternativeSiteContactName"
            class="form-label site-details-form-label">{{aemLabelAuthoring?.label?.path1SiteZEndAddress?.alternativeSiteContactName}}
          </label>
          <input (paste)="onPasteCheckNumber($event)" (keydown)="onNumericKeyPressDown($event)" maxlength="21" aria-label="Path1 site Z end address Alternative Site Contact Name" aria-describedby="Z1alternativeSiteContactName_error"
            formControlName="Z1alternativeSiteContactName" type="text"
            placeholder="Enter {{aemLabelAuthoring?.label?.path1SiteZEndAddress?.alternativeSiteContactName | lowercase}}"
            class="form-control control site-details-form-input" [readonly]="sameAsPath1ZEndContactDetailsDisable"
            [id]="aemLabelAuthoring?.label?.path1SiteZEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteZEndAddress?.alternativeSiteContactName">

<ng-container
*ngIf="contactDetailsForm.controls['Z1alternativeSiteContactName']?.required || contactDetailsForm.controls['Z1alternativeSiteContactName']?.invalid || (contactDetailsForm.controls['Z1alternativeSiteContactName']?.dirty || contactDetailsForm.controls['Z1alternativeSiteContactName']?.touched)">
<small id="Z1alternativeSiteContactName_error"
  *ngIf="contactDetailsForm.controls['Z1alternativeSiteContactName']?.required && !contactDetailsForm.get('Z1alternativeSiteContactName').hasError('forbiddenChars')"
  class="text-danger errorMsg errorMsg">
  <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
  Please enter {{aemLabelAuthoring?.label?.path1SiteAEndAddress?.alternativeSiteContactName}}
</small>
<small id="Z1alternativeSiteContactName_error"
  *ngIf="contactDetailsForm.controls['Z1alternativeSiteContactName']?.value?.length && contactDetailsForm.get('Z1alternativeSiteContactName').hasError('forbiddenChars')"
  class="text-danger errorMsg errorMsg">
  <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
  <!-- Please enter a valid {{aemLabelAuthoring.label.path1SiteAEndAddress.alternativeSiteContactName}} -->
  {{aemLabelAuthoring?.searchForm?.additionalAddressInformationInvalidError}}
</small>
<small id="Z1alternativeSiteContactName_error" class="errorMsg" *ngIf="((!contactDetailsForm.controls['A1alternativeSiteContactNumber']?.value) && !contactDetailsForm.controls['A1alternativeSiteContactEmailAddress']?.value) &&  contactDetailsForm.controls['Z1alternativeSiteContactName']?.value?.length && !contactDetailsForm.get('Z1alternativeSiteContactName').hasError('forbiddenChars')">
  <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
  {{aemLabelAuthoring?.validationMsg?.alternativeSiteErrorMsg}}
</small>
</ng-container>

        </div>

        <div class="col-12 col-md-4">
          <label
            [for]="aemLabelAuthoring?.label?.path1SiteZEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteZEndAddress?.alternativeSiteContactNumber"
            class="form-label site-details-form-label">{{aemLabelAuthoring?.label?.path1SiteZEndAddress?.alternativeSiteContactNumber}}
          </label>
          <div class="input-group">

            <input  maxlength="10" aria-label="Path1 site Z end address Alternative Site Contact Number" aria-describedby="Z1alternativeSiteContactNumber_error"
              formControlName="Z1alternativeSiteContactNumber" type="tel"
              placeholder="Enter {{aemLabelAuthoring?.label?.path1SiteZEndAddress?.alternativeSiteContactNumber | lowercase}}"
              class="form-control control site-details-form-input phoneCountryList-input"
              [readonly]="sameAsPath1ZEndContactDetailsDisable"
              [id]="aemLabelAuthoring?.label?.path1SiteZEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteZEndAddress?.alternativeSiteContactNumber"
              (keypress)="numberOnly($event)">
          </div>

          <ng-container id="Z1alternativeSiteContactNumber_error"
            *ngIf="contactDetailsForm.controls['Z1alternativeSiteContactNumber']?.invalid || (contactDetailsForm.controls['Z1alternativeSiteContactNumber']?.dirty || contactDetailsForm.controls['Z1alternativeSiteContactNumber']?.touched)">
            <!-- <small *ngIf="(contactDetailsForm.controls['Z1alternativeSiteContactNumber']?.dirty || contactDetailsForm.controls['Z1alternativeSiteContactNumber']?.touched) && !contactDetailsForm.controls['Z1alternativeSiteContactNumber']?.value?.length"
              class="text-danger errorMsg errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              Please enter {{aemLabelAuthoring.label.path1SiteZEndAddress.alternativeSiteContactNumber}}
            </small> -->
            <small id="Z1alternativeSiteContactNumber_error"
              *ngIf="(contactDetailsForm.controls['Z1alternativeSiteContactNumber']?.dirty || contactDetailsForm.controls['Z1alternativeSiteContactNumber']?.touched) && (contactDetailsForm.controls['Z1alternativeSiteContactNumber']?.value?.length > 0 && contactDetailsForm.controls['Z1alternativeSiteContactNumber']?.value?.length !=10)"
              class="text-danger errorMsg errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              Please enter a valid {{aemLabelAuthoring?.label?.path1SiteZEndAddress?.alternativeSiteContactNumber}}
            </small>
          </ng-container>
        </div>

        <div class="col-12 col-md-4">
          <label
            [for]="aemLabelAuthoring?.label?.path1SiteZEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteZEndAddress?.alternativeSiteContactEmailAddress"
            class="form-label site-details-form-label">{{aemLabelAuthoring?.label?.path1SiteZEndAddress?.alternativeSiteContactEmailAddress}}
          </label>
          <input aria-describedby="Z1alternativeSiteContactEmailAddress_error"
          aria-label="Path1 site Z end address Alternative Site Contact Email address" maxlength="100"
            formControlName="Z1alternativeSiteContactEmailAddress" type="email"
            placeholder="Enter {{aemLabelAuthoring?.label?.path1SiteZEndAddress?.alternativeSiteContactEmailAddress | lowercase}}"
            class="form-control control site-details-form-input" [readonly]="sameAsPath1ZEndContactDetailsDisable"
            [id]="aemLabelAuthoring?.label?.path1SiteZEndAddress?.uid + aemLabelAuthoring?.label?.path1SiteZEndAddress?.alternativeSiteContactEmailAddress">


            <ng-container
            *ngIf="contactDetailsForm.controls['Z1alternativeSiteContactEmailAddress']?.invalid ||
            (contactDetailsForm.controls['Z1alternativeSiteContactEmailAddress']?.dirty ||
             contactDetailsForm.controls['Z1alternativeSiteContactEmailAddress']?.touched ||
             contactDetailsForm.get('Z1alternativeSiteContactEmailAddress').hasError('forbiddenChars'))">
              <small id="Z1alternativeSiteContactEmailAddress_error" class="text-danger errorMsg errorMsg" *ngIf="contactDetailsForm.controls['Z1alternativeSiteContactEmailAddress']?.value?.length > 0 && contactDetailsForm.controls['Z1alternativeSiteContactEmailAddress']?.invalid && !contactDetailsForm.get('Z1alternativeSiteContactEmailAddress').hasError('forbiddenChars')">
                <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
                {{aemLabelAuthoring.label?.inValidEmailAddressErrorMsg}}
              </small>
              <small id="Z1alternativeSiteContactEmailAddress_error" class="text-danger errorMsg errorMsg"
              *ngIf="contactDetailsForm.controls['Z1alternativeSiteContactEmailAddress']?.value?.length && contactDetailsForm.controls['Z1alternativeSiteContactEmailAddress']?.hasError('forbiddenChars')"
              class="text-danger errorMsg errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
             {{aemLabelAuthoring?.searchForm?.additionalAddressInformationInvalidError}}
            </small>
            </ng-container>

        </div>
      </div>
      <div class="mt-5">
        <hr />
      </div>


      <ng-container *ngIf="isDiversity">
        <div class="col-12 m-t-40">
          <div class="form-check form-check-inline margin-right-50px">
            <input formControlName="path2A2sameAsAEndContact"
              (change)="onCheckChange($event, 'sameAsPath1AEndContactDetails', 'sameAsPath2AEndContactDetails')"
              class="form-check-input checkbox-input" type="checkbox"
              [id]="aemLabelAuthoring?.label?.path2SiteAEndAddress?.uid + aemLabelAuthoring?.label?.path2SiteAEndAddress?.sameAsPath1AEndContactDetails">
            <label class="form-check-label checkbox-text"
              [for]="aemLabelAuthoring?.label?.path2SiteAEndAddress?.uid + aemLabelAuthoring?.label?.path2SiteAEndAddress?.sameAsPath1AEndContactDetails">{{aemLabelAuthoring?.label?.path2SiteAEndAddress?.sameAsPath1AEndContactDetails}}</label>
          </div>
          <div class="form-check form-check-inline ml-2">
            <input formControlName="path2A2sameAsZEndContact"
              (change)="onCheckChange($event, 'sameAsPath1ZEndContactDetails', 'sameAsPath2AEndContactDetails')"
              class="form-check-input checkbox-input" type="checkbox"
              [id]="aemLabelAuthoring?.label?.path2SiteAEndAddress?.uid + aemLabelAuthoring?.label?.path2SiteAEndAddress?.sameAsPath1ZEndContactDetails">
            <label class="form-check-label checkbox-text"
              [for]="aemLabelAuthoring?.label?.path2SiteAEndAddress?.uid + aemLabelAuthoring.label?.path2SiteAEndAddress?.sameAsPath1ZEndContactDetails">{{aemLabelAuthoring?.label?.path2SiteAEndAddress?.sameAsPath1ZEndContactDetails}}</label>
          </div>
        </div>

        <div class="site-A-end-address col-12 mt-4">
          {{aemLabelAuthoring.label.path2SiteAEndAddress.label}}
        </div>
        <div class="row mt-4">
          <div class="col-12 col-md-4">
            <label
              [for]="aemLabelAuthoring.label.path2SiteAEndAddress.uid + aemLabelAuthoring.label.path2SiteAEndAddress.siteContactName"
              class="form-label site-details-form-label">{{aemLabelAuthoring.label.path2SiteAEndAddress.siteContactName}}
              <span class="inpt-reqrd-cls" aria-hidden="true">*</span>
            </label>
            <input (paste)="onPasteCheckNumber($event)" (keydown)="onNumericKeyPressDown($event)" aria-required="true" aria-label="Path2 site A end address Site Contact Name" aria-describedby="A2siteContactName_error" maxlength="21"
              formControlName="A2siteContactName" type="text"
              placeholder="Enter {{aemLabelAuthoring?.label?.path2SiteAEndAddress?.siteContactName | lowercase}}"
              class="form-control control site-details-form-input"
              [readonly]="path2A2sameAsAEndContact || path2A2sameAsZEndContact"
              [id]="aemLabelAuthoring?.label?.path2SiteAEndAddress?.uid + aemLabelAuthoring?.label?.path2SiteAEndAddress?.siteContactName">


            <ng-container *ngIf="contactDetailsForm.controls['A2siteContactName']?.invalid || (contactDetailsForm.controls['A2siteContactName']?.dirty || contactDetailsForm.controls['A2siteContactName']?.touched)">
              <small id="A2siteContactName_error" *ngIf="contactDetailsForm.controls['A2siteContactName']?.value?.length < 1 && (contactDetailsForm.controls['A2siteContactName']?.dirty || contactDetailsForm.controls['A2siteContactName']?.touched)"
              class="text-danger errorMsg errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              Please enter {{aemLabelAuthoring?.label?.path1SiteAEndAddress?.siteContactName}}
            </small>
            <small id="A2siteContactName_error"
              *ngIf="contactDetailsForm.controls['A2siteContactName']?.value?.length && !contactDetailsForm.controls['A2siteContactName'].required && contactDetailsForm.controls['A2siteContactName']?.invalid"
              class="text-danger errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              <!-- Please enter a valid {{aemLabelAuthoring.label.path1SiteAEndAddress.siteContactName}} -->
              {{aemLabelAuthoring?.searchForm?.additionalAddressInformationInvalidError}}
            </small>
            </ng-container>

          </div>

          <div class="col-12 col-md-4">
            <label
              [for]="aemLabelAuthoring?.label?.path2SiteAEndAddress?.uid + aemLabelAuthoring?.label?.path2SiteAEndAddress?.siteContactNumber"
              class="form-label site-details-form-label">{{aemLabelAuthoring.label.path2SiteAEndAddress.siteContactNumber}}
              <span class="inpt-reqrd-cls" aria-hidden="true">*</span>
            </label>
            <div class="input-group">
              <input aria-required="true" aria-label="Path2 site A end address Site Contact Number" aria-describedby="A2siteContactNumber_error"  maxlength="10"
                formControlName="A2siteContactNumber" type="tel"
                [readonly]="path2A2sameAsAEndContact || path2A2sameAsZEndContact"
                placeholder="Enter {{aemLabelAuthoring?.label?.path2SiteAEndAddress?.siteContactNumber | lowercase}}"
                class="form-control control site-details-form-input phoneCountryList-input"
                [id]="aemLabelAuthoring?.label?.path2SiteAEndAddress?.uid + aemLabelAuthoring?.label?.path2SiteAEndAddress?.siteContactNumber"
                (keypress)="numberOnly($event)">
            </div>

            <ng-container id="A2siteContactNumber_error"
            *ngIf="contactDetailsForm.controls['A2siteContactNumber']?.invalid || (contactDetailsForm.controls['A2siteContactNumber']?.dirty || contactDetailsForm.controls['A2siteContactNumber']?.touched)">
            <small *ngIf="(contactDetailsForm.controls['A2siteContactNumber']?.dirty || contactDetailsForm.controls['A2siteContactNumber']?.touched) && !contactDetailsForm.controls['A2siteContactNumber']?.value?.length"
              class="text-danger errorMsg errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              Please enter {{aemLabelAuthoring?.label?.path2SiteAEndAddress?.siteContactNumber}}
            </small>
            <small id="A2siteContactNumber_error"
              *ngIf="(contactDetailsForm.controls['A2siteContactNumber']?.dirty || contactDetailsForm.controls['A2siteContactNumber']?.touched) && (contactDetailsForm.controls['A2siteContactNumber']?.value?.length > 0 && contactDetailsForm.controls['A2siteContactNumber']?.value?.length !=10)"
              class="text-danger errorMsg errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              Please enter a valid {{aemLabelAuthoring?.label?.path2SiteAEndAddress?.siteContactNumber}}
            </small>
          </ng-container>

          </div>

          <div class="col-12 col-md-4">
            <label
              [for]="aemLabelAuthoring.label.path2SiteAEndAddress.uid + aemLabelAuthoring.label.path2SiteAEndAddress.siteContactEmailAddress"
              class="form-label site-details-form-label">{{aemLabelAuthoring.label.path2SiteAEndAddress.siteContactEmailAddress}}
            </label>
            <input aria-describedby="A2siteContactEmailAddress_error" aria-label="Path2 site A end address Site Contact Email address"

              maxlength="100" formControlName="A2siteContactEmailAddress" type="email"
              [readonly]="path2A2sameAsAEndContact || path2A2sameAsZEndContact"
              placeholder="Enter {{aemLabelAuthoring?.label?.path2SiteAEndAddress?.siteContactEmailAddress | lowercase}}"
              class="form-control control site-details-form-input"
              [id]="aemLabelAuthoring?.label?.path2SiteAEndAddress?.uid + aemLabelAuthoring?.label?.path2SiteAEndAddress?.siteContactEmailAddress">

            <ng-container *ngIf="contactDetailsForm.controls['A2siteContactEmailAddress'].invalid || (contactDetailsForm.controls['A2siteContactEmailAddress'].dirty || contactDetailsForm.controls['A2siteContactEmailAddress']?.touched || contactDetailsForm.get('A2siteContactEmailAddress').hasError('forbiddenChars'))">
              <small id="A2siteContactEmailAddress_error" class="text-danger errorMsg errorMsg" *ngIf="contactDetailsForm.controls['A2siteContactEmailAddress'].value?.length > 0 && contactDetailsForm.controls['A2siteContactEmailAddress']?.invalid && !contactDetailsForm.get('A2siteContactEmailAddress').hasError('forbiddenChars')">
                <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
                {{aemLabelAuthoring.label?.inValidEmailAddressErrorMsg}}
              </small>
              <small id="A2siteContactEmailAddress_error" class="text-danger errorMsg errorMsg"
              *ngIf="contactDetailsForm.controls['A2siteContactEmailAddress']?.value?.length && contactDetailsForm.controls['A2siteContactEmailAddress']?.hasError('forbiddenChars')"
              class="text-danger errorMsg errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
             {{aemLabelAuthoring?.searchForm?.additionalAddressInformationInvalidError}}
            </small>
            </ng-container>

          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12 col-md-4">
            <label
              [for]="aemLabelAuthoring?.label?.path2SiteAEndAddress?.uid + aemLabelAuthoring?.label?.path2SiteAEndAddress?.alternativeSiteContactName"
              class="form-label site-details-form-label">{{aemLabelAuthoring?.label?.path2SiteAEndAddress?.alternativeSiteContactName}}
            </label>
            <input (paste)="onPasteCheckNumber($event)" (keydown)="onNumericKeyPressDown($event)" maxlength="21" aria-label="Path2 site A end address Alternative Site Contact Name" aria-describedby="A2alternativeSiteContactName_error"
              formControlName="A2alternativeSiteContactName" type="text"
              [readonly]="path2A2sameAsAEndContact || path2A2sameAsZEndContact"
              placeholder="Enter {{aemLabelAuthoring?.label?.path2SiteAEndAddress?.alternativeSiteContactName | lowercase}}"
              class="form-control control site-details-form-input"
              [id]="aemLabelAuthoring?.label?.path2SiteAEndAddress?.uid + aemLabelAuthoring?.label?.path2SiteAEndAddress?.alternativeSiteContactName">

            <ng-container
            *ngIf="contactDetailsForm.controls['A2alternativeSiteContactName']?.required || contactDetailsForm.controls['A2alternativeSiteContactName']?.invalid || (contactDetailsForm.controls['A2alternativeSiteContactName']?.dirty || contactDetailsForm.controls['A2alternativeSiteContactName']?.touched)">
            <small id="A2alternativeSiteContactName_error"
              *ngIf="contactDetailsForm.controls['A2alternativeSiteContactName'].required && !contactDetailsForm.get('A2alternativeSiteContactName').hasError('forbiddenChars')"
              class="text-danger errorMsg errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              Please enter {{aemLabelAuthoring?.label?.path1SiteAEndAddress?.alternativeSiteContactName}}
            </small>
            <small id="A2alternativeSiteContactName_error"
              *ngIf="contactDetailsForm.controls['A2alternativeSiteContactName']?.value?.length && contactDetailsForm.get('A2alternativeSiteContactName').hasError('forbiddenChars')"
              class="text-danger errorMsg errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              <!-- Please enter a valid {{aemLabelAuthoring.label.path1SiteAEndAddress.alternativeSiteContactName}} -->
              {{aemLabelAuthoring?.searchForm?.additionalAddressInformationInvalidError}}
            </small>
            <small id="A2alternativeSiteContactName_error" class="errorMsg" *ngIf="((!contactDetailsForm.controls['A1alternativeSiteContactNumber']?.value) && !contactDetailsForm.controls['Z1alternativeSiteContactEmailAddress']?.value) &&  contactDetailsForm.controls['A2alternativeSiteContactName']?.value?.length && !contactDetailsForm.get('A2alternativeSiteContactName').hasError('forbiddenChars')">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              {{aemLabelAuthoring?.validationMsg?.alternativeSiteErrorMsg}}
            </small>
            </ng-container>


          </div>

          <div class="col-12 col-md-4">
            <label
              [for]="aemLabelAuthoring?.label?.path2SiteAEndAddress?.uid + aemLabelAuthoring?.label?.path2SiteAEndAddress.alternativeSiteContactNumber"
              class="form-label site-details-form-label">{{aemLabelAuthoring?.label?.path2SiteAEndAddress?.alternativeSiteContactNumber}}
            </label>
            <div class="input-group">
              <input  maxlength="10" aria-label="Path2 site A end address Alternative Site Contact Number" aria-describedby="A2alternativeSiteContactNumber_error"
                formControlName="A2alternativeSiteContactNumber" type="tel"
                [readonly]="path2A2sameAsAEndContact || path2A2sameAsZEndContact"
                placeholder="Enter {{aemLabelAuthoring?.label?.path2SiteAEndAddress?.alternativeSiteContactNumber | lowercase}}"
                class="form-control control site-details-form-input phoneCountryList-input"
                [id]="aemLabelAuthoring?.label?.path2SiteAEndAddress?.uid + aemLabelAuthoring?.label?.path2SiteAEndAddress?.alternativeSiteContactNumber"
                (keypress)="numberOnly($event)">
            </div>

          <ng-container id="A2alternativeSiteContactNumber_error"
          *ngIf="contactDetailsForm.controls['A2alternativeSiteContactNumber']?.invalid || (contactDetailsForm.controls['A2alternativeSiteContactNumber']?.dirty || contactDetailsForm.controls['A2alternativeSiteContactNumber']?.touched)">
          <!-- <small *ngIf="(contactDetailsForm.controls['A2alternativeSiteContactNumber']?.dirty || contactDetailsForm.controls['A2alternativeSiteContactNumber']?.touched) && !contactDetailsForm.controls['A2alternativeSiteContactNumber']?.value?.length"
            class="text-danger errorMsg errorMsg">
            <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
            Please enter {{aemLabelAuthoring.label.path2SiteAEndAddress.alternativeSiteContactNumber}}
          </small> -->
          <small id="A2alternativeSiteContactNumber_error"
            *ngIf="(contactDetailsForm.controls['A2alternativeSiteContactNumber']?.dirty || contactDetailsForm.controls['A2alternativeSiteContactNumber']?.touched) && (contactDetailsForm.controls['A2alternativeSiteContactNumber']?.value?.length > 0 && contactDetailsForm.controls['A2alternativeSiteContactNumber']?.value?.length !=10)"
            class="text-danger errorMsg errorMsg">
            <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
            Please enter a valid {{aemLabelAuthoring?.label?.path2SiteAEndAddress?.alternativeSiteContactNumber}}
          </small>
        </ng-container>


          </div>

          <div class="col-12 col-md-4">
            <label
              [for]="aemLabelAuthoring?.label?.path2SiteAEndAddress.uid + aemLabelAuthoring.label.path2SiteAEndAddress.alternativeSiteContactEmailAddress"
              class="form-label site-details-form-label">{{aemLabelAuthoring.label.path2SiteAEndAddress.alternativeSiteContactEmailAddress}}
            </label>
            <input aria-label="Path2 site A end address Alternative Site Contact Email address" aria-describedby="A2alternativeSiteContactEmailAddress_error"

              maxlength="100" formControlName="A2alternativeSiteContactEmailAddress" type="email"
              [readonly]="path2A2sameAsAEndContact || path2A2sameAsZEndContact"
              placeholder="Enter {{aemLabelAuthoring.label.path2SiteAEndAddress.alternativeSiteContactEmailAddress | lowercase}}"
              class="form-control control site-details-form-input"
              [id]="aemLabelAuthoring.label.path2SiteAEndAddress.uid + aemLabelAuthoring.label.path2SiteAEndAddress.alternativeSiteContactEmailAddress">


            <ng-container
*ngIf="contactDetailsForm.controls['A2alternativeSiteContactEmailAddress'].invalid ||
(contactDetailsForm.controls['A2alternativeSiteContactEmailAddress'].dirty ||
 contactDetailsForm.controls['A2alternativeSiteContactEmailAddress'].touched ||
 contactDetailsForm.get('A2alternativeSiteContactEmailAddress').hasError('forbiddenChars'))">
  <small id="A2alternativeSiteContactEmailAddress_error" class="text-danger errorMsg errorMsg" *ngIf="contactDetailsForm.controls['A2alternativeSiteContactEmailAddress'].value?.length > 0 && contactDetailsForm.controls['A2alternativeSiteContactEmailAddress'].invalid && !contactDetailsForm.get('A2alternativeSiteContactEmailAddress').hasError('forbiddenChars')">
    <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
    {{aemLabelAuthoring.label?.inValidEmailAddressErrorMsg}}
  </small>
  <small id="A2alternativeSiteContactEmailAddress_error" class="text-danger errorMsg errorMsg"
  *ngIf="contactDetailsForm.controls['A2alternativeSiteContactEmailAddress']?.value?.length && contactDetailsForm.controls['A2alternativeSiteContactEmailAddress']?.hasError('forbiddenChars')"
  class="text-danger errorMsg errorMsg">
  <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
 {{aemLabelAuthoring.searchForm.additionalAddressInformationInvalidError}}
</small>
</ng-container>

          </div>

        </div>

        <div class="mt-5">
          <hr />
        </div>

        <div class="col-12 m-t-40">
          <div class="form-check form-check-inline margin-right-50px">
            <input formControlName="path2Z2sameAsAEndContact"
              (change)="onCheckChange($event, 'sameAsPath1AEndContactDetails', 'sameAsPath2ZEndContactDetails')"
              class="form-check-input checkbox-input" type="checkbox"
              [id]="aemLabelAuthoring.label.path2SiteZEndAddress.uid + aemLabelAuthoring.label.path2SiteZEndAddress.sameAsPath1AEndContactDetails">
            <label class="form-check-label checkbox-text"
              [for]="aemLabelAuthoring.label.path2SiteZEndAddress.uid + aemLabelAuthoring.label.path2SiteZEndAddress.sameAsPath1AEndContactDetails">{{aemLabelAuthoring.label.path2SiteZEndAddress.sameAsPath1AEndContactDetails}}</label>
          </div>
          <div class="form-check form-check-inline margin-right-50px">
            <input formControlName="path2Z2sameAsZEndContact"
              (change)="onCheckChange($event, 'sameAsPath1ZEndContactDetails', 'sameAsPath2ZEndContactDetails')"
              class="form-check-input checkbox-input" type="checkbox"
              [id]="aemLabelAuthoring.label.path2SiteZEndAddress.uid + aemLabelAuthoring.label.path2SiteZEndAddress.sameAsPath1ZEndContactDetails">
            <label class="form-check-label checkbox-text"
              [for]="aemLabelAuthoring.label.path2SiteZEndAddress.uid + aemLabelAuthoring.label.path2SiteZEndAddress.sameAsPath1ZEndContactDetails">{{aemLabelAuthoring.label.path2SiteZEndAddress.sameAsPath1ZEndContactDetails}}</label>
          </div>
          <div class="form-check form-check-inline ml-2">
            <input formControlName="path2Z2sameAsA2EndContact"
              (change)="onCheckChange($event, 'sameAsPath2AEndContactDetails', 'sameAsPath2ZEndContactDetails')"
              class="form-check-input checkbox-input" type="checkbox"
              [id]="aemLabelAuthoring.label.path2SiteZEndAddress.uid + aemLabelAuthoring.label.path2SiteZEndAddress.sameAsPath2AEndContactDetails">
            <label class="form-check-label checkbox-text"
              [for]="aemLabelAuthoring.label.path2SiteZEndAddress.uid + aemLabelAuthoring.label.path2SiteZEndAddress.sameAsPath2AEndContactDetails">{{aemLabelAuthoring.label.path2SiteZEndAddress.sameAsPath2AEndContactDetails}}</label>
          </div>
        </div>

        <div class="site-A-end-address col-12 mt-4">
          {{aemLabelAuthoring.label.path2SiteZEndAddress.label}}
        </div>
        <div class="row mt-4">
          <div class="col-12 col-md-4">
            <label
              [for]="aemLabelAuthoring.label.path2SiteZEndAddress.uid + aemLabelAuthoring.label.path2SiteZEndAddress.siteContactName"
              class="form-label site-details-form-label">{{aemLabelAuthoring.label.path2SiteZEndAddress.siteContactName}}
              <span class="inpt-reqrd-cls" aria-hidden="true">*</span>
            </label>
            <input (paste)="onPasteCheckNumber($event)" (keydown)="onNumericKeyPressDown($event)" aria-label="Path2 site Z end address Site Contact Name" aria-required="true" aria-describedby="Z2siteContactName_error" maxlength="21"
              formControlName="Z2siteContactName" type="text"
              [readonly]="path2Z2sameAsAEndContact || path2Z2sameAsZEndContact || path2Z2sameAsA2EndContact"
              placeholder="Enter {{aemLabelAuthoring.label.path2SiteZEndAddress.siteContactName | lowercase}}"
              class="form-control control site-details-form-input"
              [id]="aemLabelAuthoring.label.path2SiteZEndAddress.uid + aemLabelAuthoring.label.path2SiteZEndAddress.siteContactName">
            <ng-container *ngIf="contactDetailsForm.controls['Z2siteContactName']?.invalid || (contactDetailsForm.controls['Z2siteContactName']?.dirty || contactDetailsForm.controls['Z2siteContactName']?.touched)">
              <small id="Z2siteContactName_error" *ngIf="contactDetailsForm.controls['Z2siteContactName']?.value?.length < 1 && (contactDetailsForm.controls['Z2siteContactName']?.dirty || contactDetailsForm.controls['Z2siteContactName']?.touched)"
              class="text-danger errorMsg errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              Please enter {{aemLabelAuthoring.label.path1SiteZEndAddress.siteContactName}}
            </small>
            <small id="Z2siteContactName_error"
              *ngIf="contactDetailsForm.controls['Z2siteContactName']?.value?.length && !contactDetailsForm.controls['Z2siteContactName'].required && contactDetailsForm.controls['Z2siteContactName']?.invalid"
              class="text-danger errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              <!-- Please enter a valid {{aemLabelAuthoring.label.path1SiteAEndAddress.siteContactName}} -->
              {{aemLabelAuthoring.searchForm.additionalAddressInformationInvalidError}}
            </small>
            </ng-container>
          </div>

          <div class="col-12 col-md-4">
            <label
              [for]="aemLabelAuthoring.label.path2SiteZEndAddress.uid + aemLabelAuthoring.label.path2SiteZEndAddress.siteContactNumber"
              class="form-label site-details-form-label">{{aemLabelAuthoring.label.path2SiteZEndAddress.siteContactNumber}}
              <span class="inpt-reqrd-cls" aria-hidden="true">*</span>
            </label>
            <div class="input-group">
              <input aria-required="true" aria-label="Path2 site Z end address Site Contact Number" aria-describedby="Z2siteContactNumber_error"  maxlength="10"
                formControlName="Z2siteContactNumber" type="tel"
                [readonly]="path2Z2sameAsAEndContact || path2Z2sameAsZEndContact || path2Z2sameAsA2EndContact"
                placeholder="Enter {{aemLabelAuthoring.label.path2SiteZEndAddress.siteContactNumber | lowercase}}"
                class="form-control control site-details-form-input phoneCountryList-input"
                [id]="aemLabelAuthoring.label.path2SiteZEndAddress.uid + aemLabelAuthoring.label.path2SiteZEndAddress.siteContactNumber"
                (keypress)="numberOnly($event)">
            </div>

            <ng-container id="Z2siteContactNumber_error"
            *ngIf="contactDetailsForm.controls['Z2siteContactNumber']?.invalid || (contactDetailsForm.controls['Z2siteContactNumber']?.dirty || contactDetailsForm.controls['Z2siteContactNumber']?.touched)">
            <small *ngIf="(contactDetailsForm.controls['Z2siteContactNumber']?.dirty || contactDetailsForm.controls['Z2siteContactNumber']?.touched) && !contactDetailsForm.controls['Z2siteContactNumber']?.value?.length"
              class="text-danger errorMsg errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              Please enter {{aemLabelAuthoring.label.path2SiteZEndAddress.siteContactNumber}}
            </small>
            <small id="Z2siteContactNumber_error"
              *ngIf="(contactDetailsForm.controls['Z2siteContactNumber']?.dirty || contactDetailsForm.controls['Z2siteContactNumber']?.touched) && (contactDetailsForm.controls['Z2siteContactNumber']?.value?.length > 0 && contactDetailsForm.controls['Z2siteContactNumber']?.value?.length !=10)"
              class="text-danger errorMsg errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              Please enter a valid {{aemLabelAuthoring.label.path2SiteZEndAddress.siteContactNumber}}
            </small>
          </ng-container>


          </div>

          <div class="col-12 col-md-4">
            <label
              [for]="aemLabelAuthoring.label.path2SiteZEndAddress.uid + aemLabelAuthoring.label.path2SiteZEndAddress.siteContactEmailAddress"
              class="form-label site-details-form-label">{{aemLabelAuthoring.label.path2SiteZEndAddress.siteContactEmailAddress}}
            </label>
            <input aria-describedby="Z2siteContactEmailAddress_error" aria-label="Path2 site Z end address Site Contact Email address"

              maxlength="100" formControlName="Z2siteContactEmailAddress" type="email"
              [readonly]="path2Z2sameAsAEndContact || path2Z2sameAsZEndContact || path2Z2sameAsA2EndContact"
              placeholder="Enter {{aemLabelAuthoring.label.path2SiteZEndAddress.siteContactEmailAddress | lowercase}}"
              class="form-control control site-details-form-input"
              [id]="aemLabelAuthoring.label.path2SiteZEndAddress.uid + aemLabelAuthoring.label.path2SiteZEndAddress.siteContactEmailAddress">

              <ng-container *ngIf="contactDetailsForm.controls['Z2siteContactEmailAddress'].invalid && (contactDetailsForm.controls['Z2siteContactEmailAddress'].dirty || contactDetailsForm.controls['Z2siteContactEmailAddress'].touched || contactDetailsForm.get('Z2siteContactEmailAddress').hasError('forbiddenChars'))">
                <small id="Z2siteContactEmailAddress_error" class="text-danger errorMsg errorMsg" *ngIf="contactDetailsForm.controls['Z2siteContactEmailAddress'].value?.length > 0 && contactDetailsForm.controls['Z2siteContactEmailAddress'].invalid && !contactDetailsForm.get('Z2siteContactEmailAddress').hasError('forbiddenChars')">
                  <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
                  {{aemLabelAuthoring.label?.inValidEmailAddressErrorMsg}}
                </small>
                <small id="Z2siteContactEmailAddress_error" class="text-danger errorMsg errorMsg"
                *ngIf="contactDetailsForm.controls['Z2siteContactEmailAddress']?.value?.length && contactDetailsForm.controls['Z2siteContactEmailAddress']?.hasError('forbiddenChars')"
                class="text-danger errorMsg errorMsg">
                <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
               {{aemLabelAuthoring.searchForm.additionalAddressInformationInvalidError}}
              </small>
              </ng-container>
          </div>

        </div>

        <div class="row mt-4">
          <div class="col-12 col-md-4">
            <label
              [for]="aemLabelAuthoring.label.path2SiteZEndAddress.uid + aemLabelAuthoring.label.path2SiteZEndAddress.alternativeSiteContactName"
              class="form-label site-details-form-label">{{aemLabelAuthoring.label.path2SiteZEndAddress.alternativeSiteContactName}}
            </label>
            <input maxlength="21" aria-label="Path2 site Z end address alternative Site Contact Name" aria-describedby="Z2alternativeSiteContactName_error"
              (paste)="onPasteCheckNumber($event)" (keydown)="onNumericKeyPressDown($event)" formControlName="Z2alternativeSiteContactName" type="text"
              [readonly]="path2Z2sameAsAEndContact || path2Z2sameAsZEndContact || path2Z2sameAsA2EndContact"
              placeholder="Enter {{aemLabelAuthoring.label.path2SiteZEndAddress.alternativeSiteContactName | lowercase}}"
              class="form-control control site-details-form-input"
              [id]="aemLabelAuthoring.label.path2SiteZEndAddress.uid + aemLabelAuthoring.label.path2SiteZEndAddress.alternativeSiteContactName">


              <ng-container
            *ngIf="contactDetailsForm.controls['Z2alternativeSiteContactName']?.required || contactDetailsForm.controls['Z2alternativeSiteContactName']?.invalid || (contactDetailsForm.controls['Z2alternativeSiteContactName']?.dirty || contactDetailsForm.controls['Z2alternativeSiteContactName']?.touched)">
                <small id="Z2alternativeSiteContactName_error"
                *ngIf="contactDetailsForm.controls['Z2alternativeSiteContactName'].required && !contactDetailsForm.get('Z2alternativeSiteContactName').hasError('forbiddenChars')"
                class="text-danger errorMsg errorMsg">
                <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
                Please enter {{aemLabelAuthoring.label.path1SiteAEndAddress.alternativeSiteContactName}}
                </small>
                <small id="Z2alternativeSiteContactName_error"
                *ngIf="contactDetailsForm.controls['Z2alternativeSiteContactName']?.value?.length && contactDetailsForm.get('Z2alternativeSiteContactName').hasError('forbiddenChars')"
                class="text-danger errorMsg errorMsg">
                <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
                <!-- Please enter a valid {{aemLabelAuthoring.label.path1SiteAEndAddress.alternativeSiteContactName}} -->
                {{aemLabelAuthoring.searchForm.additionalAddressInformationInvalidError}}
                </small>
                <small id="Z2alternativeSiteContactName_error" class="errorMsg" *ngIf="((!contactDetailsForm.controls['A1alternativeSiteContactNumber']?.value) && !contactDetailsForm.controls['A2alternativeSiteContactEmailAddress']?.value) &&  contactDetailsForm.controls['Z2alternativeSiteContactName']?.value?.length && !contactDetailsForm.get('Z2alternativeSiteContactName').hasError('forbiddenChars')">
                <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
                {{aemLabelAuthoring.validationMsg.alternativeSiteErrorMsg}}
                </small>
            </ng-container>
          </div>

          <div class="col-12 col-md-4">
            <label
              [for]="aemLabelAuthoring.label.path2SiteZEndAddress.uid + aemLabelAuthoring.label.path2SiteZEndAddress.alternativeSiteContactNumber"
              class="form-label site-details-form-label">{{aemLabelAuthoring.label.path2SiteZEndAddress.alternativeSiteContactNumber}}
            </label>
            <div class="input-group">
              <input  maxlength="10" aria-label="Path2 site Z end address alternative Site Contact Number" aria-describedby="Z2alternativeSiteContactNumber_error"
                formControlName="Z2alternativeSiteContactNumber" type="tel"
                [readonly]="path2Z2sameAsAEndContact || path2Z2sameAsZEndContact || path2Z2sameAsA2EndContact"
                placeholder="Enter {{aemLabelAuthoring.label.path2SiteZEndAddress.alternativeSiteContactNumber | lowercase}}"
                class="form-control control site-details-form-input phoneCountryList-input"
                [id]="aemLabelAuthoring.label.path2SiteZEndAddress.uid + aemLabelAuthoring.label.path2SiteZEndAddress.alternativeSiteContactNumber"
                (keypress)="numberOnly($event)">
            </div>
          <ng-container id="Z2alternativeSiteContactNumber_error"
          *ngIf="contactDetailsForm.controls['Z2alternativeSiteContactNumber']?.invalid || (contactDetailsForm.controls['Z2alternativeSiteContactNumber']?.dirty || contactDetailsForm.controls['Z2alternativeSiteContactNumber']?.touched)">
          <!-- <small *ngIf="(contactDetailsForm.controls['Z2alternativeSiteContactNumber']?.dirty || contactDetailsForm.controls['Z2alternativeSiteContactNumber']?.touched) && !contactDetailsForm.controls['Z2alternativeSiteContactNumber']?.value?.length"
            class="text-danger errorMsg errorMsg">
            <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
            Please enter {{aemLabelAuthoring.label.path2SiteZEndAddress.alternativeSiteContactNumber}}
          </small> -->
          <small id="Z2alternativeSiteContactNumber_error"
            *ngIf="(contactDetailsForm.controls['Z2alternativeSiteContactNumber']?.dirty || contactDetailsForm.controls['Z2alternativeSiteContactNumber']?.touched) && (contactDetailsForm.controls['Z2alternativeSiteContactNumber']?.value?.length > 0 && contactDetailsForm.controls['Z2alternativeSiteContactNumber']?.value?.length !=10)"
            class="text-danger errorMsg errorMsg">
            <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
            Please enter a valid {{aemLabelAuthoring.label.path2SiteZEndAddress.alternativeSiteContactNumber}}
          </small>
        </ng-container>
          </div>

          <div class="col-12 col-md-4">
            <label
              [for]="aemLabelAuthoring.label.path2SiteZEndAddress.uid + aemLabelAuthoring.label.path2SiteZEndAddress.alternativeSiteContactEmailAddress"
              class="form-label site-details-form-label">{{aemLabelAuthoring.label.path2SiteZEndAddress.alternativeSiteContactEmailAddress}}
            </label>
            <input aria-describedby="Z2alternativeSiteContactEmailAddress_error" aria-label="Path2 site Z end address Site Contact Email address"
              maxlength="100" formControlName="Z2alternativeSiteContactEmailAddress" type="email"
              [readonly]="path2Z2sameAsAEndContact || path2Z2sameAsZEndContact || path2Z2sameAsA2EndContact"
              placeholder="Enter {{aemLabelAuthoring.label.path2SiteZEndAddress.alternativeSiteContactEmailAddress | lowercase}}"
              class="form-control control site-details-form-input"
              [id]="aemLabelAuthoring.label.path2SiteZEndAddress.uid + aemLabelAuthoring.label.path2SiteZEndAddress.alternativeSiteContactEmailAddress">


<ng-container
*ngIf="contactDetailsForm.controls['Z2alternativeSiteContactEmailAddress'].invalid &&
(contactDetailsForm.controls['Z2alternativeSiteContactEmailAddress'].dirty ||
 contactDetailsForm.controls['Z2alternativeSiteContactEmailAddress'].touched ||
 contactDetailsForm.get('Z2alternativeSiteContactEmailAddress').hasError('forbiddenChars'))">
  <small id="Z2alternativeSiteContactEmailAddress_error" class="text-danger errorMsg errorMsg" *ngIf="(contactDetailsForm.controls['Z2alternativeSiteContactEmailAddress'].dirty ||
  contactDetailsForm.controls['Z2alternativeSiteContactEmailAddress'].touched) && contactDetailsForm.controls['Z2alternativeSiteContactEmailAddress'].value?.length > 0 && contactDetailsForm.controls['Z2alternativeSiteContactEmailAddress'].invalid && !contactDetailsForm.get('Z2alternativeSiteContactEmailAddress').hasError('forbiddenChars')">
    <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
    {{aemLabelAuthoring.label?.inValidEmailAddressErrorMsg}}
  </small>
  <small id="Z2alternativeSiteContactEmailAddress_error" class="text-danger errorMsg errorMsg"
  *ngIf="contactDetailsForm.controls['Z2alternativeSiteContactEmailAddress']?.value?.length && contactDetailsForm.controls['Z2alternativeSiteContactEmailAddress']?.hasError('forbiddenChars')"
  class="text-danger errorMsg errorMsg">
  <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
 {{aemLabelAuthoring.searchForm.additionalAddressInformationInvalidError}}
</small>
</ng-container>

          </div>
        </div>

        <div class="mt-5">
          <hr />
        </div>

      </ng-container>


      <div class="site-A-end-address col-12 m-t-40">
        {{aemLabelAuthoring.label.fundingDetails.label}}
      </div>

      <div class="row mt-4">
        <div class="col-12 col-md-4">

          <label class="form-label site-details-form-label"
            [for]="aemLabelAuthoring.label.fundingDetails.uid + aemLabelAuthoring.label.fundingDetails.fundingSource">{{aemLabelAuthoring.label.fundingDetails.fundingSource}}
            <span class="inpt-reqrd-cls" aria-hidden="true">*</span></label>
          <app-cui-searchable-dropdown aria-required="true" aria-describedby="fundingSource_error" name="fundingSource"
            [model]="fundingSourceDropDownModel" [formControlName]="'fundingSource'" id="type"
            [labelName]="'fundingSource'" [isRequired]="true" [items]="fundingSourceList"
            placeholder="Select funding source" automationType="dropdown" automationId="fundingSource"
            [noAutoComplete]="false" id="fundingSource" class="customer-dropDown"
            (ngModelChange)="fundingSourceListChange($event)" required>
          </app-cui-searchable-dropdown>
          <ng-container
            *ngIf="contactDetailsForm.controls['fundingSource']?.invalid && (contactDetailsForm.controls['fundingSource']?.dirty || contactDetailsForm.controls['fundingSource']?.touched)">
            <small id="fundingSource_error" *ngIf="contactDetailsForm.controls['fundingSource'].required"
              class="text-danger errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              Please select {{aemLabelAuthoring.label.fundingDetails.fundingSource |
              lowercase}}
            </small>
            <small id="fundingSource_error"
              *ngIf="!contactDetailsForm.controls['fundingSource'].required && contactDetailsForm.controls['fundingSource']?.invalid"
              class="text-danger errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              Please select a valid {{aemLabelAuthoring.label.fundingDetails.fundingSource |
              lowercase}}
            </small>
          </ng-container>
        </div>
      </div>

      <div class="row">

        <ng-container
          *ngIf="contactDetailsForm.controls['fundingSource'].value.toLowerCase().indexOf('sbc') >-1 || contactDetailsForm.controls['fundingSource'].value == aemLabelAuthoring.label.fundingDetails.isOtherPowImc">
          <div class="col-12 col-md-4 mt-4" [ngClass]="{'disable-input-wrapper' : isModifyQuote}">
            <label
              [for]="aemLabelAuthoring.label.fundingDetails.uid + aemLabelAuthoring.label.fundingDetails.l3WBSCodeCapex"
              class="form-label site-details-form-label">{{aemLabelAuthoring.label.fundingDetails.l3WBSCodeCapex}}
              <span class="inpt-reqrd-cls" aria-hidden="true">*</span>
            </label>
            <input aria-required="true" aria-describedby="l3WBSCodeCapex_error" formControlName="l3WBSCodeCapex"
              type="text" maxlength="32"
              placeholder="Enter {{aemLabelAuthoring.label.fundingDetails.l3WBSCodeCapex | lowercase}}"
              class="form-control control site-details-form-input" [ngClass]="{'disable-form-input' : isModifyQuote}"
              [id]="aemLabelAuthoring.label.fundingDetails.uid + aemLabelAuthoring.label.fundingDetails.l3WBSCodeCapex">
            <ng-container
              *ngIf="contactDetailsForm.controls['l3WBSCodeCapex']?.invalid && (contactDetailsForm.controls['l3WBSCodeCapex']?.dirty || contactDetailsForm.controls['l3WBSCodeCapex']?.touched)">
              <small id="l3WBSCodeCapex_error" *ngIf="contactDetailsForm.controls['l3WBSCodeCapex'].errors?.required"
                class="text-danger errorMsg">
                <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
                Please enter {{aemLabelAuthoring.label.fundingDetails.l3WBSCodeCapex}}
              </small>
              <small id="l3WBSCodeCapex_error"
                *ngIf="!contactDetailsForm.controls['l3WBSCodeCapex'].errors?.required && contactDetailsForm.controls['l3WBSCodeCapex']?.invalid"
                class="text-danger errorMsg">
                <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
                The following special characters [<>;] are unable to be used, please revise your input
              </small>
            </ng-container>
          </div>

          <div class="col-12 col-md-4 mt-4" [ngClass]="{'disable-input-wrapper' : isModifyQuote}">
            <label
              [for]="aemLabelAuthoring.label.fundingDetails.uid + aemLabelAuthoring.label.fundingDetails.l3WBSCodeOpex"
              class="form-label site-details-form-label">{{aemLabelAuthoring.label.fundingDetails.l3WBSCodeOpex}}
              <span class="inpt-reqrd-cls" aria-hidden="true">*</span>
            </label>
            <input aria-required="true" aria-describedby="l3WBSCodeOpex_error" formControlName="l3WBSCodeOpex"
              type="text" maxlength="32"
              placeholder="Enter {{aemLabelAuthoring.label.fundingDetails.l3WBSCodeOpex | lowercase}}"
              class="form-control control site-details-form-input" [ngClass]="{'disable-form-input' : isModifyQuote}"
              [id]="aemLabelAuthoring.label.fundingDetails.uid + aemLabelAuthoring.label.fundingDetails.l3WBSCodeOpex">
            <ng-container
              *ngIf="contactDetailsForm.controls['l3WBSCodeOpex']?.invalid && (contactDetailsForm.controls['l3WBSCodeOpex']?.dirty || contactDetailsForm.controls['l3WBSCodeOpex']?.touched)">
              <small id="l3WBSCodeOpex_error" *ngIf="contactDetailsForm.controls['l3WBSCodeOpex'].errors?.required"
                class="text-danger errorMsg errorMsg">
                <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
                Please enter {{aemLabelAuthoring.label.fundingDetails.l3WBSCodeOpex}}
              </small>
              <small id="l3WBSCodeOpex_error"
                *ngIf="!contactDetailsForm.controls['l3WBSCodeOpex'].errors?.required && contactDetailsForm.controls['l3WBSCodeOpex']?.invalid"
                class="text-danger errorMsg errorMsg">
                <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
                The following special characters [<>;] are unable to be used, please revise your input
              </small>
            </ng-container>
          </div>
        </ng-container>

        <div class="col-12 col-md-4 mt-4"
          *ngIf="contactDetailsForm.controls['fundingSource'].value == aemLabelAuthoring.label.fundingDetails.isOtherPowImc"
          [ngClass]="{'disable-input-wrapper' : isModifyQuote}">
          <label [for]="aemLabelAuthoring.label.fundingDetails.uid + aemLabelAuthoring.label.fundingDetails.otherPowImc"
            class="form-label site-details-form-label">{{aemLabelAuthoring.label.fundingDetails.otherPowImc}}
            <span class="inpt-reqrd-cls" aria-hidden="true">*</span>
          </label>
          <input aria-required="true" aria-describedby="otherPowImc_error" maxlength="32" formControlName="otherPowImc"
            type="text" placeholder="Enter {{aemLabelAuthoring.label.fundingDetails.otherPowImc | lowercase}}"
            class="form-control control site-details-form-input" [ngClass]="{'disable-form-input' : isModifyQuote}"
            [id]="aemLabelAuthoring.label.fundingDetails.uid + aemLabelAuthoring.label.fundingDetails.otherPowImc">
          <ng-container
            *ngIf="contactDetailsForm.controls['otherPowImc']?.invalid && (contactDetailsForm.controls['otherPowImc']?.dirty || contactDetailsForm.controls['otherPowImc']?.touched)">
            <small id="otherPowImc_error" *ngIf="contactDetailsForm.controls['otherPowImc'].errors.required"
              class="text-danger errorMsg errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              Please enter {{aemLabelAuthoring.label.fundingDetails.otherPowImc}}
            </small>
            <small id="otherPowImc_error"
              *ngIf="!contactDetailsForm.controls['otherPowImc'].errors.required && contactDetailsForm.controls['otherPowImc']?.invalid"
              class="text-danger errorMsg errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              The following special characters [<>;] are unable to be used, please revise your input
            </small>
          </ng-container>

        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12 col-md-4">
          <label
            [for]="aemLabelAuthoring.label.fundingDetails.uid + aemLabelAuthoring.label.fundingDetails.preApprovalCost"
            class="form-label site-details-form-label">{{aemLabelAuthoring.label.fundingDetails.preApprovalCost}}
            <span class="inpt-reqrd-cls" aria-hidden="true">*</span>
          </label>
          <div class="input-group" [ngClass]="{'disable-input-wrapper' : isModifyQuote}">
            <span class="input-group-text cost">$</span>
            <input aria-required="true" aria-describedby="preApprovalCost_error" formControlName="preApprovalCost" (paste)="false"
              type="tel" [min]="1" maxlength="6" oninput="this.value = this.value.replace(/^0+|[^0-9]/, '')"
              placeholder="Enter {{aemLabelAuthoring.label.fundingDetails.preApprovalCost | lowercase}}"
              class="form-control control site-details-form-input" (keypress)="numberWithDecimal($event)"
              [ngClass]="{'disable-form-input' : isModifyQuote}"
              [id]="aemLabelAuthoring.label.fundingDetails.uid + aemLabelAuthoring.label.fundingDetails.preApprovalCost">
          </div>
          <ng-container
            *ngIf="contactDetailsForm.controls['preApprovalCost']?.invalid && (contactDetailsForm.controls['preApprovalCost']?.dirty || contactDetailsForm.controls['preApprovalCost']?.touched)">
            <small id="preApprovalCost_error" *ngIf="contactDetailsForm.controls['preApprovalCost'].required"
              class="text-danger errorMsg errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              Please enter {{aemLabelAuthoring.label.fundingDetails.preApprovalCost}}
            </small>
            <small id="preApprovalCost_error"
              *ngIf="!contactDetailsForm.controls['preApprovalCost'].required && contactDetailsForm.controls['preApprovalCost']?.invalid"
              class="text-danger errorMsg errorMsg">
              <img src="../../../../../assets/images/icon-error.svg" class="error-icon">
              Please enter {{aemLabelAuthoring.label.fundingDetails.preApprovalCost}}
            </small>
          </ng-container>
        </div>
      </div>
    </div>

  </form>
</div>
