

<div class="container-fluid order-details-container infra-create-cabinet-container" *ngIf="aemLabelAuthoring">
  <div class="row">
    <h1 class="col-12 page-heading" *ngIf="aemLabelAuthoring">
      {{aemLabelAuthoring.pageHeader.pageHeaderLabel}}
    </h1>
  </div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" (keypress)="cancel()" (click)="cancel()">
        <a tabindex="0" role="button">{{aemLabelAuthoring.pageHeader.mycabinet}}</a>
      </li>
      <i class="icon-icon-ui-right-arrow breadcrumb-icon"></i>
      <li class="breadcrumb-item active" aria-current="page">
        <span>{{aemLabelAuthoring.pageHeader.pageHeaderLabel}}</span>
      </li>
    </ol>
  </nav>
  <div class="row">
    <div class="col-12">
      <h2 class="headings">
        {{aemLabelAuthoring.form.heading}}
      </h2>
    </div>
  </div>
  <hr>
  <span class="desc">{{aemLabelAuthoring.form.description}}</span>
  <br>
  <br>
  <div class="form-group">
    <div class="row">
      <div class="col-4">
        <label aria-label="document cabinet id required" class="user-details-label-top required-field">
          {{aemLabelAuthoring.form.cabinetID}}
        </label>
        <div>
          <app-cui-textfield [(model)]="cabinetIDModel" (input)="specialCharCheck($event)"
            [ngClass]="{'invalid-control': cabinetIDModel.validationMessage}" aria-required="true" required>
          </app-cui-textfield>
        </div>
        <div>
          <span style="color: darkred;" *ngIf="cabinetIDModel.validationMessage">
            {{cabinetIDModel.validationMessage}} </span>
          <span class="text-right-blue">{{cabinetIDModel.inputText ? cabinetIDModel.inputText.length : 0}}/15</span>
        </div>
      </div>
      <div class="col-4">
        <label aria-label="document cabinet name required" class="user-details-label-top required-field">
          {{aemLabelAuthoring.form.cabinetName}}
        </label>
        <div>
          <app-cui-textfield [(model)]="cabinetNameModel" aria-required="true" required></app-cui-textfield>
        </div>
        <div class="text-right">
          <span class="text-right-blue">{{cabinetNameModel.inputText ? cabinetNameModel.inputText.length :
            0}}/100</span>
        </div>
      </div>
    </div>
    <div class="row submitCancelBtn btnRow">
      <div class="col-3">
        <button type="button" class="cancelBtn" (click)="cancel()">
          <i class="icon-icon-ui-cross"></i>
          {{aemLabelAuthoring.form.cancel}}
        </button>
        <button type="button" class="saveBtn" (click)="onClickSubmit()"
          [ngClass]="{'enableSaveBtn': !(cabinetIDModel.validationMessage || !cabinetIDModel.inputText || !cabinetNameModel.inputText)}"
          [ngStyle]="{'cursor': !(cabinetIDModel.validationMessage || !cabinetIDModel.inputText || !cabinetNameModel.inputText) === true ? 'pointer' : 'not-allowed'}"
          [disabled]="cabinetIDModel.validationMessage || !cabinetIDModel.inputText || !cabinetNameModel.inputText">
          {{aemLabelAuthoring.form.submit}}
        </button>
      </div>
    </div>
  </div>
</div>
