<!-- class="container-fluid page-container create-customer" -->
<div *ngIf="aemLabelAuthoring">
  <!-- <div aria-live="assertive" class="visually-hidden" id="customer-label">
    <span role="presentation" style="position:absolute;left:-9999px">Modify Customer Screen opened</span>
  </div> -->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" role="link"><a href="/">Home</a></li>
      <i class="icon-icon-ui-right-arrow breadcrumb-icon"></i>
      <li class="breadcrumb-item mid" role="link" (keypress)="moveTab.emit()" (click)="moveTab.emit()" tabindex="0"><a>My Customer</a></li>
      <i class="icon-icon-ui-right-arrow breadcrumb-icon"></i>
      <li class="breadcrumb-item active" aria-current="page">Modify customer</li>
    </ol>
  </nav>
  <h3 class="createNewCus">{{customerData.customerName}}'s profile</h3>
  <span class="accLabel">Fields marked with <span class="star">*</span> are mandatory</span>
  <form [formGroup]="modifyCustomerForm" id="modifyCustomer">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="label required-field" for="customerName">{{aemLabelAuthoring.form.customername}}</label>
              <app-cui-textfield [(model)]="customerNameModel" [parentFormGroup]="modifyCustomerForm">
              </app-cui-textfield>
            </div>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="form-group col-3">
            <label class="label required-field" for="type">{{aemLabelAuthoring.form.customerTypeHead}}</label>
            <app-cui-searchable-dropdown name="customerType" [model]="customerTypeDropDownModel" id="type"
              [labelName]="'customerTypeDropDown'" [placeholder]="'Select customer type'"
              [formControlName]="'customerType'" [isRequired]=true [items]=customerTypes automationType="dropdown"
              automationId="drpdwnCustomerType" [noAutoComplete]=false id="customerType"
              (ngModelChange)="customerTypeChange($event)" class="customer-dropDown" required>
            </app-cui-searchable-dropdown>
          </div>
          <div class="form-group col-3">
            <label class="label required-field" for="channel">{{aemLabelAuthoring.form.customerChannelHead}}</label>
            <app-cui-searchable-dropdown name="customerChannel" [model]="customerChannelDropDownModel"
              [labelName]="'customerChannelDropDown'" [placeholder]="'Select customer channel'" id="channel"
              formControlName="customerChannel" [isRequired]=true [items]=customerChannels automationType="dropdown"
              automationId="drpdwnCustomerChannel" [noAutoComplete]=false id="customerChannel" class="customer-dropDown"
              required>
            </app-cui-searchable-dropdown>
          </div>
          <div class="form-group col-3">
            <label class="label required-field" for="primaryCIDN">{{aemLabelAuthoring.form.primaryCidn}}</label>
            <app-cui-textfield [model]="primaryCIDNModel" [parentFormGroup]="modifyCustomerForm">
            </app-cui-textfield>
          </div>
          <div class="form-group col-3">
            <label class="label required-field" for="status"> Status</label>
            <div class="inpt-type2-cls">
              <app-cui-searchable-dropdown #status name="businessTypes" [formControlName]="'status'"
                [model]="statusDropDownModel" [labelName]="'statusDropdown'" [placeholder]="'Select Status'"
                [isRequired]=true [items]=statuses automationType="dropdown" automationId="drpdwnstatusType"
                [noAutoComplete]=false id="status" class="user-dropDown"
                [ngClass]="{'invalid-form-control': (modifyCustomerForm.controls.status.invalid && modifyCustomerForm.controls.status.touched)}"
                required>
              </app-cui-searchable-dropdown>
            </div>
            <div class="row ml-0" aria-live="assertive">
              <span role="alert"
                *ngIf="modifyCustomerForm.controls.status.invalid && modifyCustomerForm.controls.status.touched"
                class="help-block errorMsg">
                Please select status
              </span>
            </div>
          </div>
        </div>
        <br>
      </div>
    </div>
    <div class="row">
      <table class="table" *ngIf="rowData.length > 0">
        <tr>
          <th *ngFor="let col of profileTableColumn">
            {{col}}
          </th>
        </tr>
        <tr *ngFor="let row of rowData; let i = index" class="cidnRow">
          <td class="customerColumn"> {{row.customerName}} </td>
          <td class="cidnColumn"> {{row.linkedCIDN}} </td>
          <td>
            <button mat-button class="texttype-globalbtn-cls" (click)="removeCustomerDetails(i)"
              attr.aria-label="Remove customer {{row?.customerName}}">
              <img src="../../../../assets/images/icon-delete.svg" alt="Remove Customer" class="rmve-cstmr-cls" />
              {{aemLabelAuthoring.form.remove}}</button>
          </td>
        </tr>
      </table>
    </div>
    <label role="alert" attr.aria-label="Successfully removed" *ngIf="showRemoveAlert" style="position:absolute;left:-9999px">Remove Action peformed</label>
    <div class="cidnContainer">
      <div class="cidnField">
        <div class="form-group col-3">
          <label class="label required-field">Linked customer identifier (e.g., CIDN)</label>
          <div class="field-focus" #ref aria-hidden="true">
          <app-cui-searchable-dropdown name="linkedCIDN" [model]="linkedCIDNDropDownModel"
            [labelName]="'linkedCIDNDropDown'" [placeholder]="'Primary'" formControlName="linkedCIDN" [isRequired]=false
            [items]=linkedCIDNs automationType="dropdown" automationId="linkedCIDN" [noAutoComplete]=false
            id="linkedCIDN" class="customer-dropDown" (ngModelChange)="cidnChange()">
          </app-cui-searchable-dropdown>
        </div>
          <div class="row ml-0" aria-live="assertive">
            <ng-container *ngIf="modifyCustomerForm.controls['linkedCIDN'].value">
              <span role="alert" *ngIf="(!enableSaveCidn)" class="help-block errorMsg">
                Duplicate CIDN
              </span>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="save-cncl-CIDN-btn">
        <button class="texttype-globalbtn-cls" (click)="cancelCIDN()">
          <i class="icon-icon-ui-cross"></i>{{aemLabelAuthoring.form.cancel}}
        </button>
        <div class="btnWrap" [ngStyle]="{'cursor': enableSaveCidn === true ? 'pointer' : 'not-allowed' }">
          <button class="primary-globalbtn-cls" (click)="saveCIDN()" [ngClass]="{'enableSaveBtn': enableSaveCidn}"
            [ngStyle]="{'pointer-events': enableSaveCidn === true ? 'auto' : 'none' }">
            Save</button>
        </div>
      </div>
    </div>
    <br>
    <div>
      <button class="outline-globalbtn-cls">
        <i class="icon-icon-ui-plus roundGreenBorderCls"></i>{{aemLabelAuthoring.form.link}}</button>
    </div>
    <hr>
    <h4 class="custContact">{{aemLabelAuthoring.pageHeader.pageHeaderLabel1}}</h4>
    <div class="row customer-contact-details-row">
      <div class="form-group col-3">
        <label class="label required-field" style="margin-left: 3.2px !important;" for="conType">{{aemLabelAuthoring.form.contactType}}</label>
        <app-cui-searchable-dropdown name="contactType" [model]="contactTypeDropDownModel"
          [labelName]="'contactTypeDropDown'" [placeholder]="'Primary'" formControlName="contactType" [isRequired]=true
          [items]=primaryContactType automationType="dropdown" automationId="contactTypeChannel" [noAutoComplete]=false
          id="conType" class="customer-dropDown" required>
        </app-cui-searchable-dropdown>
      </div>
      <div class="form-group col-3">
        <label class="label required-field">{{aemLabelAuthoring.form.contactName}}</label>
        <app-cui-textfield [model]="contactNameModel" [parentFormGroup]="modifyCustomerForm">
        </app-cui-textfield>
      </div>
      <div class="form-group col-3">
        <label aria-label="Contact number required" class="label required-field" for="contactNumber" aria-required="true"
          required>{{aemLabelAuthoring.form.contactNumber}}</label>
        <input type="text" class="form-control field-input" maxlength="20" id="contactNumber" name="contactNumber"
          placeholder="Enter contact number" attr.aria-label="document title" formControlName="contactNumber"
          required [ngClass]="{'invalid-form-control': (modifyCustomerForm.controls.contactNumber.touched && modifyCustomerForm.controls.contactNumber.invalid)}">
          <span role="alert" class="error-cls"
          *ngIf="modifyCustomerForm.controls.contactNumber.touched && modifyCustomerForm.controls.contactNumber.invalid">
          <img src="/assets/images/telstra/svg/validation_icon.svg" alt="validationIcon" class="crossIcon ng-star-inserted">
          {{aemLabelAuthoring.form.contactNumbervalidationMsg}}
          </span>
      </div>
      <div class="form-group col-3">
        <label aria-label="Contact email required" class="label required-field" for="contactEmail" aria-required="true"
          required>{{aemLabelAuthoring.form.contactMail}}</label>
        <input type="text" class="form-control field-input" maxlength="255" id="contactEmail" name="contactEmail"
          placeholder="Enter contact email" attr.aria-label="document title" formControlName="contactEmail"
          [ngClass]="{'invalid-form-control': (modifyCustomerForm.controls.contactEmail.touched && modifyCustomerForm.controls.contactEmail.invalid)}">
          <span role="alert" class="error-cls"
          *ngIf="modifyCustomerForm.controls.contactEmail.touched && modifyCustomerForm.controls.contactEmail.invalid">
          <img src="/assets/images/telstra/svg/validation_icon.svg" alt="validationIcon" class="crossIcon ng-star-inserted">
          {{aemLabelAuthoring.form.contactMailvalidationMsg}}
          </span>
      </div>
    </div>
    <ng-container *ngIf="isSecondaryContact">
      <div class="row">
        <div class="form-group col-3">
          <label class="label" for="conType">{{aemLabelAuthoring.form.contactType}}</label>
          <div class="field-focus" #cntct>
          <app-cui-searchable-dropdown name="contactTypeSec" [model]="contactTypeDropDownModelSec"
            [labelName]="'contactTypeDropDown'" [placeholder]="'Alternate'" formControlName="contactTypeSec"
            [isRequired]=false [items]=alternateContactType automationType="dropdown" automationId="contactTypeChannel"
            [noAutoComplete]=false id="conType" class="customer-dropDown">
          </app-cui-searchable-dropdown>
        </div>
        </div>
        <div class="form-group col-3">
          <label class="label">{{aemLabelAuthoring.form.contactName}}</label>
          <app-cui-textfield [model]="contactNameModelSec" [parentFormGroup]="modifyCustomerForm">
          </app-cui-textfield>
        </div>
        <div class="form-group col-3">
          <label class="label" for="contactNumberSec">{{aemLabelAuthoring.form.contactNumber}}</label>
          <input type="text" class="form-control field-input" maxlength="20" id="contactNumberSec"
            name="contactNumberSec" placeholder="Enter contact number" attr.aria-label="document title"
            formControlName="contactNumberSec"
            [ngClass]="{'invalid-form-control': (modifyCustomerForm.controls.contactNumberSec.touched && modifyCustomerForm.controls.contactNumberSec.invalid)}">
            <span role="alert" class="error-cls"
            *ngIf="modifyCustomerForm.controls.contactNumberSec.touched && modifyCustomerForm.controls.contactNumberSec.invalid">
          <img src="/assets/images/telstra/svg/validation_icon.svg" alt="validationIcon" class="crossIcon ng-star-inserted">
          {{aemLabelAuthoring.form.contactNumbervalidationMsg}}
          </span>
        </div>
        <div class="form-group col-3">
          <label class="label" for="contactEmailSec">{{aemLabelAuthoring.form.contactMail}}</label>
          <input type="text" class="form-control field-input" maxlength="255" id="contactEmailSec"
            name="contactEmailSec" placeholder="Enter contact email" attr.aria-label="document title"
            formControlName="contactEmailSec"
            [ngClass]="{'invalid-form-control': (modifyCustomerForm.controls.contactEmailSec.touched && modifyCustomerForm.controls.contactEmailSec.invalid)}">
            <span role="alert" class="error-cls"
            *ngIf="modifyCustomerForm.controls.contactEmailSec.touched && modifyCustomerForm.controls.contactEmailSec.invalid">
          <img src="/assets/images/telstra/svg/validation_icon.svg" alt="validationIcon" class="crossIcon ng-star-inserted">
          {{aemLabelAuthoring.form.contactMailvalidationMsg}}
          </span>
        </div>
      </div>
      <br>
    </ng-container>
    <br>
    <div>
      <button class="outline-globalbtn-cls" (click)="addContact()">
        <i class="icon-icon-ui-plus roundGreenBorderCls"></i>{{aemLabelAuthoring.form.addContact}}</button>
      <ng-container *ngIf="isSecondaryContact">
        <button class="texttype-globalbtn-cls" (click)="removeSecondaryContact()">
          <i class="icon-icon-ui-cross"></i>{{aemLabelAuthoring.form.removeContact}}</button>
      </ng-container>
    </div>
    <hr style="margin-bottom: 60px;">
    <div class="col-12 flex-container word-break">
      <div class="col-2 info-div-cls">
        <span class="infoHead">{{aemLabelAuthoring.form.Createddate}}</span>
        <span class="infoBody">{{customerData.createdDate}}</span>
      </div>
      <div class="col-4 info-div-cls">
        <span class="infoHead">{{aemLabelAuthoring.form.Createdby}}</span>
        <span class="infoBody">{{customerData.createdBy}}</span>
      </div>
      <div class="col-2 info-div-cls ">
        <span class="infoHead">{{aemLabelAuthoring.form.Lastmodifieddate}}</span>
        <span class="infoBody">{{customerData.modifiedDate}}</span>
      </div>
      <div class="col-4 info-div-cls">
        <span class="infoHead">{{aemLabelAuthoring.form.Lastmodifiedby}}</span>
        <span class="infoBody">{{customerData.modifiedBy}}</span>
      </div>
    </div>
    <br>
    <div class="col-12">
      <span class="infoHead">{{aemLabelAuthoring.form.lmd}}</span>
      <!-- <span *ngIf="customerData.auditDetails" class="infoBody">{{customerData.auditDetails[0].modifiedDetails}}</span> -->
      <span *ngIf="customerData.auditDetails" class="modifyNew"
       [innerHtml]="customerData.auditDetails[0].modifiedDetails"></span>

    </div>
    <br>
    <div>
      <button class="texttype-globalbtn-cls" style="padding-left:0;" (click)="openAuditModal()">
        <img class="auditImg" src="../../assets/images/audit-icon.svg" />
        {{aemLabelAuthoring.form.audit}}</button>
    </div>
    <br>
    <div class="submitCancelBtnBlock">
      <button class="texttype-globalbtn-cls" (click)="cancelBtnClick()">
        <i class="icon-icon-ui-cross" style="position: relative; top:2px;"></i>{{aemLabelAuthoring.form.cancel}}
      </button>
      <button class="primary-globalbtn-cls" (click)="onSubmit()" [disabled]="modifyCustomerForm.pristine || !modifyCustomerForm.valid">
        {{aemLabelAuthoring.form.submit}}
      </button>
    </div>
  </form>
  <br>
</div>

<app-view-audit-modal *ngIf="isAuditModalOpen" #myModal [auditData]="customerData"
  (onClickClose)="onClickClose($event)"> </app-view-audit-modal>
