import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomDropDown } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { DuctAccessService } from '../duct-access.service';
// import { UploadDocumentPageData } from '../attachment-requirement/attachment-requirement.model';
import { UtilityService } from '../../shared/services/utility.service';

@Component({
  selector: 'app-address-details',
  templateUrl: './address-details.component.html',
  styleUrls: ['./address-details.component.less']
})
export class AddressDetailsComponent implements OnInit {
  secondFormGroup: FormGroup;
  @Input() studyTypeValue: string;
  public aemLabelAuthoring: any;
  public stateList = [];
  public stateModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'state Required'
  };
  public fibreList = [];
  public fibreModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Bare Fiber-street network(specify cable spec)'
  };
  public leadInModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'leadIn'
  };

  constructor(private ductService: DuctAccessService,
    private router: Router, private activeRoute: ActivatedRoute, private fb: FormBuilder, private rootFormGroup : FormGroupDirective,private utility: UtilityService) { }
  
  ngOnInit(): void {
    this.getLabels();
    this.secondFormGroup = this.rootFormGroup.control.get('secondForm') as FormGroup;
    const elements = document.getElementsByClassName('field-input');
    window.onkeyup = function (event) {
      if (event.keyCode === 9) {
      for(let i=0; i< elements.length; i++) {
        const inputElement = elements[i];
        inputElement.classList.remove("focused")
      }
      const activeElement = document.activeElement;
      if(activeElement && activeElement.classList.contains('field-input')) {
        activeElement.classList.add("focused")
      }  
    }
    }
  }

  get additionalEnds(): FormArray {
    return this.secondFormGroup.get('additionalEnds') as FormArray;
  }

  addEndRows() {
    const control = this.secondFormGroup.get('additionalEnds')['controls'][0];
    return this.fb.group({
        aEndAddress: [control.get('aEndAddress').value, [Validators.required]],
        gpsCoordsA: [control.get('gpsCoordsA').value],
        bEndAddress: ['', [Validators.required]],
        gpsCoordsB: [''],
        stateA: [control.get('stateA').value, [Validators.required]],
        stateB: ['', [Validators.required]],
        suburbA: [control.get('suburbA').value, [Validators.required]],
        postcodeA: [control.get('postcodeA').value, [Validators.required,Validators.pattern(this.utility.getPostCodeRegEx())]],
        suburbB: ['', [Validators.required]],
        postcodeB: ['', [Validators.required,Validators.pattern(this.utility.getPostCodeRegEx())]]
        })
  }

  addEnd() {
    this.additionalEnds.push(this.addEndRows());
  }

  removeEnd(index: number) {
    this.additionalEnds.removeAt(index);
  }

  getLabels() {
    this.ductService.getDuctFormData().then((data: any) => {
      this.aemLabelAuthoring = data;
      this.stateList = this.aemLabelAuthoring.form.stateList;
      // fibreList is populated from the backend using ductService.getBareFibreData() below
      // this.fibreList = this.aemLabelAuthoring.form.fibreList;
    }).catch((error) => {
    });
    this.ductService.getBareFibreData().then((data: any) => {
      this.fibreList = data;
    }).catch((error) => {
    });
  }

  clearDynamicFieldValue(field: string){
    this.secondFormGroup['controls'][field].setValue('');
  }

  clearDynamicField(event, field) {
    if(event.key==='Tab') {
      let focusableElement;
      focusableElement = document.getElementById('accessRadioButton1');
      if (focusableElement) {
        focusableElement.setAttribute('tabindex', '0');
        focusableElement.addEventListener('blur', () => {
          focusableElement.removeAttribute('tabindex');
        });
        setTimeout(() => {
          if (focusableElement) {
            focusableElement.focus();
          }
        }, 10);
      }
    }
  }

  onlyPositiveNumbers(field) {
    let currentValue = this.secondFormGroup['controls'][field]?.value;
    if(currentValue<0){
      this.secondFormGroup['controls'][field].setValue(0);
    }  
  }

}
