import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { CreateQuoteService } from './create-quote.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-new-quote',
  templateUrl: './create-new-quote.component.html',
  styleUrls: ['./create-new-quote.component.less'],
})
export class CreateNewQuoteComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  isLinear = false;
  createNewQuoteForm: FormGroup;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  isProductSelected: boolean = false;
  custReference: boolean = false;
  productData: any;
  stepperData = ['Product details', 'Site details', 'Request details', 'Review and submit']
  stepName = 'Product details';

  public aemLabelAuthoring: [];
  constructor(
    private utility: UtilityService,
    private fb: FormBuilder,
    private createQuoteService: CreateQuoteService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.createNewQuoteForm = this.fb.group({
      firstForm: this.fb.group({
        companyName: ['', [Validators.required]],
      }),
      secondForm: this.fb.group({
        companyName: ['', [Validators.required]],
      }),
      thirdForm: this.fb.group({
        surveyingCompany: [''],
      }),
      fourthForm: this.fb.group({
        surveyingCompany: [''],
      }),
    });
    this.getLabels();
  }

  getLabels() {
    this.createQuoteService
      .getCreateQuoteModel()
      .then((data: any) => {
        this.aemLabelAuthoring = data;
      })
      .catch((error) => { });
  }

  showQuoteReference(event) {
    if (event) {
      this.isProductSelected = true;
    } else {
      this.isProductSelected = false;
    }
  }

  showReviewLabel(event) {
    if (event) {
      this.custReference = true;
    } else {
      this.custReference = false;
    }
  }
  move(index: number) {
    this.stepper.selectedIndex = index;
  }

  cancel(stepper: MatStepper) {
    this.router.navigate(['/myQuotes']);
    stepper.selectedIndex = 0;
  }

}
