import { Component, ElementRef, ViewChild, OnInit, AfterViewInit, Input, Output, EventEmitter, HostListener, LOCALE_ID, Inject } from '@angular/core';
import { CustomAgGrid } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CreateQuoteService } from '../create-new-quote/create-quote.service';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { MyOrdersService } from 'src/app/order-managemnt/my-orders.service';
import { BulkQuoteService } from 'src/app/bulk-quote-management/bulk-quote.service';

@Component({
  selector: 'app-terms-conditions-modal',
  templateUrl: './terms-conditions-modal.component.html',
  styleUrls: ['./terms-conditions-modal.component.less']
})
export class TermsConditionModalComponent implements OnInit, AfterViewInit {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;

  public rowData: any = [];
  @Output() onClickClose: EventEmitter<boolean> = new EventEmitter();
  @Input() type;
  @Input() quoteId;
  @Input() quoteDetails;
  @Input() sysID;
  @Input() tAndcText;
  @Input() isBulkQuote;
  @Input() bulkQuotes;
  @Input() bulkQuoteId;
  @Input() showDiversity;
  @Input() selectedTermForNonRGPath1;
  @Input() selectedTermForNonRGPath2;
  @Input() bulkPayload: any;
  @Input() isModifyBulkQuote: boolean;
  @ViewChild('content', { static: false }) modalContent: ElementRef;
  public aemLabelAuthoring: any;
  createQuotePayload: any;
  options: GlobalConfig;
  isModifyQuote: boolean;
  userTimeZone: string;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private createQuote: CreateQuoteService,
    private toastr: ToastrService,
    private myOrderService: MyOrdersService,
    private bulkQuoteService: BulkQuoteService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.getUserTimeZone();
    this.options = this.toastr.toastrConfig;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // Close the modal when the Escape key is pressed
    this.modalService.dismissAll(); // Assuming you are using Angular Material dialog
  }

  ngOnInit() {
    this.getLabels();
    this.createQuotePayload = this.createQuote.getProductDetailsFormValues();
    this.createQuote.isModifyQuote.subscribe(val => {
      if (val) {
        this.isModifyQuote = val;
      } else {
        this.isModifyQuote = false
      }
    })
  }

  ngAfterViewInit() {
    this.modalService.open(this.modalContent, {
      ariaLabelledBy: 'modal-basic-title',
      keyboard: false,
      backdrop: 'static',
      size: 'xl',
      windowClass: 'modal-xl',
    })
  }
  navigateSuccess() {
    if (this.isModifyQuote) {
      this.modifyFeasibilityRequest(true);
    } else {
      this.saveFeasibilityRequest()
    }
  }

  close() {
    this.onClickClose.emit(true);
    this.modalService.dismissAll();
  }

  getLabels() {
    return new Promise((resolve, reject) => {
      this.createQuote.getTermsConditionModel().then(() => {
        this.aemLabelAuthoring = this.createQuote.termsLabelAuthoringDataObj;
        resolve(true);
      }).catch(() => {
        // reject(false);
      });
    });
  }
  saveFeasibilityRequest() {
    this.createQuotePayload = this.createQuote.getProductDetailsFormValues();
    this.createQuotePayload['avoidPlrLogging'] = true;
    return new Promise((resolve, reject) => {
      this.createQuote.saveNewQuote(this.createQuotePayload, 'Submit').then((res) => {
        this.createQuote.setQuoteDetails(res);
        this.router.navigate(['/success']);
        this.onClickClose.emit(true);
        this.modalService.dismissAll();
        resolve(true);
      }).catch((err) => {
        this.close();
        const msg = 'We can’t submit your request at this time. Please refresh and try again later.';
        this.showToasterMessage(msg, '', this.options, 'error');
        reject(err);
      });

    });
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-top-center';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 5000;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
  }

  modifyFeasibilityRequest(plr?) {
    const payload = {
      action: 'Submit',
      avoidPlrLogging: plr,
      cidn: this.createQuotePayload?.cidn,
      quoteReferenceId: this.createQuotePayload?.quoteReferenceId,
      modifiedDetails: []
    }
    return new Promise((resolve, reject) => {
      this.createQuote.modifyFeasibilityRequest(payload).then((res) => {
        // this.createQuote.setQuoteDetails(res);
        this.createQuote.setQuoteDetails({ data: this.createQuotePayload });
        this.router.navigate(['/success']);
        this.onClickClose.emit(true);
        this.modalService.dismissAll();
        resolve(true);
      }).catch((err) => {
        this.close();
        const msg = 'We can’t submit your request at this time. Please refresh and try again later.';
        this.showToasterMessage(msg, '', this.options, 'error');
        reject(err);
      });
    })
  }
  getUserTimeZone() {
    const userTimeZone = Intl.DateTimeFormat(this.locale, {
      timeZoneName: 'long'
    }).resolvedOptions().timeZone;

    this.userTimeZone = userTimeZone;
  }
  agree() {
    const cidn = localStorage.getItem('selectedHeaderCIDN');
    const aEndAddress = (this.type.toLowerCase() === 'path1' || !this.type) ? this.quoteDetails?.aEndAdress : this.quoteDetails?.a2EndAdress;
    const zEndAddress = (this.type.toLowerCase() === 'path1' || !this.type) ? this.quoteDetails?.zEndAdress : this.quoteDetails?.z2EndAdress;
    const payload = {
      "action": "Init",
      "screenName": "orderSiteDetails",
      "timezone": this.userTimeZone,
      "orderReferenceId": this.quoteId,
      "orderConvertedFrom": this.type,
      "orderStatus": null,
      "cidn": cidn,
      "productName": null,
      "linkRequirement": null,
      "customerName": this.quoteDetails.customerName,
      "requestorUser": this.quoteDetails.requestorUser,
      "requestorChannel": null,
      "serviceType": null,
      "customerContactName": null,
      "customerContactNumber": null,
      "customerContactEmail": null,
      "customerReference": null,
      "customerRequestedDate": null,
      "projectReferenceId": null,
      "opportunityId": null,
      "parentOrderRefId": null,
      "businessDriver": null,
      "additionalInformation": null,
      "quantityOfService": null,
      "quoteExpiryDate": null,
      "snowSysId": null,
      "terms": null,
      "selectedTermForNonRGPath1" : this.selectedTermForNonRGPath1,
      "selectedTermForNonRGPath2" : this.selectedTermForNonRGPath2,
      "doYouRequireDiversity": null,
      "serviceId": null,
      "orderType": null,
      "clusterId": null,
      "aEndLeadIn": null,
      "zEndLeadIn": null,
      "miragepclId": null,
      "quoteSnowOrderId": this.quoteDetails.serviceNowReference,
      "completedDate": null,
      "createdDate": null,
      "createdBy": null,
      "modifiedDate": null,
      "modifiedBy": null,
      "linkDeliveryTimingClass": this.type.toLowerCase() === 'path1' ? this.quoteDetails.assessmentOutcome[0].indicativeLinkDeliveryTimingClass : this.quoteDetails.assessmentOutcome[1].indicativeLinkDeliveryTimingClass,
      "aEndAdress": {
        "siteTypeAOrZEnd": "aEndAdress",
        "siteName": null,
        "address": null,
        "adborId": null,
        "esa": null,
        "siteAlternativeName": null,
        "serviceDeliveryPoint": null,
        "nodeCode": null,
        "leadInRequired": null,
        "additionalAddressInfo": null,
        "latitude": null,
        "longitude": null,
        "poleId": null,
        "createdDate": null,
        "createdBy": null,
        "modifiedDate": null,
        "modifiedBy": null,
        "orderSiteContactDetails": [
          {
            "id": null,
            "siteContactType": "aEndAdress",
            "primarySiteContactName": null,
            "primarySiteContactNumber": null,
            "primarySiteContactEmail": null,
            "primarySiteContactNameUpdated": false,
            "primarySiteContactNumberUpdated": false,
            "primarySiteContactEmailUpdated": false,
            "alternativeSiteContactName": null,
            "alternativeSiteContactNumber": null,
            "alternativeSiteContactEmail": null,
            "createdDate": null,
            "createdBy": null,
            "modifiedDate": null,
            "modifiedBy": null

          }
        ]
      },
      "zEndAdress": {
        "siteTypeAOrZEnd": "zEndAdress",
        "siteName": null,
        "address": null,
        "adborId": null,
        "esa": null,
        "siteAlternativeName": null,
        "serviceDeliveryPoint": null,
        "nodeCode": null,
        "leadInRequired": null,
        "additionalAddressInfo": null,
        "latitude": null,
        "longitude": null,
        "poleId": null,
        "createdDate": null,
        "createdBy": null,
        "modifiedDate": null,
        "modifiedBy": null,
        "orderSiteContactDetails": [
          {
            "id": null,
            "siteContactType": "zEndAdress",
            "primarySiteContactName": null,
            "primarySiteContactNumber": null,
            "primarySiteContactEmail": null,
            "primarySiteContactNameUpdated": false,
            "primarySiteContactNumberUpdated": false,
            "primarySiteContactEmailUpdated": false,
            "alternativeSiteContactName": null,
            "alternativeSiteContactNumber": null,
            "alternativeSiteContactEmail": null,
            "createdDate": null,
            "createdBy": null,
            "modifiedDate": null,
            "modifiedBy": null

          }
        ]
      }
    }
    this.myOrderService.saveNewOrder(payload).then((res) => {
      this.router.navigate(['/order-site-details', this.type, this.quoteId]);
      this.close();
    }).catch((err) => {
      this.close();
      const msg = 'We can’t submit your request at this time. Please refresh and try again later.';
      this.showToasterMessage(msg, '', this.options, 'error');
    });



  }

  uploadBulkQuote() {
    const cidn = localStorage.getItem('selectedHeaderCIDN');
    this.bulkQuoteService.setBulkReferenceId(this.bulkQuoteId);
    this.bulkQuoteService.bulkRequestSubmit(this.bulkQuotes.quotes, this.bulkQuoteId, cidn).then((res) => {
      this.router.navigate(['/bulk-quote-success']);
    }).catch((err) => {
      this.showToasterMessage(this.aemLabelAuthoring?.errorMsg?.genericError, '', this.options, 'error');
    });
    this.modalService.dismissAll();
  }

 submitBulkQuoteModify() {
  this.bulkPayload['action'] = 'Submit';
  this.bulkQuoteService.setBulkReferenceId(this.bulkQuoteId);
  this.bulkQuoteService.saveBulkRequestsDetails(this.bulkPayload, true).then((res) => {
    this.close();
      this.router.navigate(['/bulk-quote-success']); 
  }).catch((err) => {
    this.close();
    this.showToasterMessage(this.aemLabelAuthoring?.errorMsg?.genericError, '', this.options, 'error');
  });
 }
  
}
