import { throwError as observableThrowError, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { CabinetsDocumentListActionRendererComponent } from './cabinets-document-list/cabinets-document-list-action-renderer/cabinets-document-list-action-renderer.component';
import { CabinetsDocumentTitleActionRendererComponent } from './cabinets-document-list/cabinets-document-title-action-renderer/cabinets-document-title-action-renderer.component';
// import { CabinetsUploadDocumentPageData } from './upload-cabinets-document/upload-cabinets-document.model';
import { AuthService } from 'tw-core-ui';

export interface GridHeaderInterface {
  headers: any;
}

@Injectable({
  providedIn: 'root'
})

export class CabinetsService {

  public cabinetsDocDetailsData = new BehaviorSubject<any>('');
  public cabinetsDocDetailsDataObj = this.cabinetsDocDetailsData.asObservable();
  public cabinetsDetailsData: any;
  public documentsGridHeader: any = [];
  private env: any = window['environment'];
  private apiUrl = {
    documentListModal: 'cabinets-document-list.json',
    gridHeader: 'cabinets-document-list-grid-header.json',
    documentGridData: 'document-management/documents',
    cabinetsgridHeader: 'infracoLandingGridHeader',
    cabinetsUrl: 'document-management/cabinets',
    infraCoModel: 'infraco-landing',
    createCabinetModel: 'create-cabinet',
    uploadDocumentModel: 'cabinets-upload-document'
  };
  documentListGridData: any;
  gridLabelAuthoringDataObj: any;
  createCabinetLabelAuthoringDataObj: any;
  public infraCoGridData: any = [];

  spinnerflag = false;
  private sendSpinnerFlag = new BehaviorSubject(this.spinnerflag);
  spinnerCurrentStatus = this.sendSpinnerFlag.asObservable();

  public LoloTrainingLabelAuthoringDataObj: any;
  public documentListGridHeader: any = [];
  public documentGridData: any;

  public uploadLabelAuthoringDataObj: any;
  roles = [];
  private httpClient: HttpClient;
  public getError = new BehaviorSubject<any>('');
  public getError$ = this.getError.asObservable();
  errorData: any;
status: any;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private handler: HttpBackend
  ) {
    this.httpClient = new HttpClient(handler);
  }

  public fetchError(value: any) {
    if (value == null) {
      this.getError.next(null);
    } else {
      this.getError.next(value);
    }
  }

  public getLoloTrainingMaterialModel() {
    this.sendSpinnerFlag.next(true);
    return new Promise((resolve, reject) => {
      if (!this.LoloTrainingLabelAuthoringDataObj) {
        const createuserUrl = this.env.aemEndPoint + this.apiUrl.documentListModal;
        this.http.get<any>(createuserUrl)
          .subscribe(
            res => {
              this.LoloTrainingLabelAuthoringDataObj = res.data.content;
              resolve(this.LoloTrainingLabelAuthoringDataObj);
            },
            err => {
              this.LoloTrainingLabelAuthoringDataObj = null;
              reject(err);
              return observableThrowError(err || 'Server error');
            }
          );
      } else {
        resolve(this.LoloTrainingLabelAuthoringDataObj);
      }
    });

  }

  public getGridHeader() {

    const map = {
      CabinetsDocumentListActionRendererComponent: CabinetsDocumentListActionRendererComponent,
      CabinetsDocumentTitleActionRendererComponent: CabinetsDocumentTitleActionRendererComponent
    };

    return new Promise((resolve, reject) => {
      this.http.get<GridHeaderInterface>(this.env.aemEndPoint + this.apiUrl.gridHeader)
        .subscribe(res => {
          res.headers.forEach((x: any) => {
            if (x.cellRendererFramework) {
              x.cellRendererFramework = map[x.cellRendererFramework];
            }
          });
          this.documentListGridHeader = res;
          resolve(true);
        }, err => {
          reject(err);
        });
    });
  }

  public getDocumentGridData(params) {
    this.sendSpinnerFlag.next(true);
    const tokenValue = this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : false;
    let httpOptions;
    if (tokenValue) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': '*/*',
          'Authorization': tokenValue
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': '*/*'
        })
      };
    }
    const queryParams = [];
    if (params.cabinetId) {
      queryParams.push(`cabinetId=${params.cabinetId}`);
    }
    if (params.searchKeyword) {
      params.searchKeyword = this.handleSpecialChar(params.searchKeyword);
      queryParams.push(`searchKeyword=${params.searchKeyword}`);
    }
    if (params.startDate) {
      const mDate = (params.startDate || '').replaceAll('-', ' ');
      queryParams.push(`startDate=${mDate}`);
    }
    if (params.endDate) {
      const mDate = (params.endDate || '').replaceAll('-', ' ');
      queryParams.push(`endDate=${mDate}`);
    }

    const queryStr = queryParams.join('&');
    let url = this.env.apiPoint + this.apiUrl.documentGridData
    if (queryStr) {
      url = url + '?' + queryStr;
    }
    return new Promise((resolve, reject) => {
      this.http.get<any>(url, httpOptions)
        .subscribe(
          res => {
            if(res){
              this.status=res['status'];
            }
            this.sendSpinnerFlag.next(false);
            if (res && res['data'] && res['data'].documentsList) {
              this.documentGridData = res['data']['documentsList'];
            } else {
              this.documentGridData = [];
            }
              const responseInfo = {
              status: this.status,
              data: this.documentGridData
          };
            resolve(responseInfo);
            // resolve(this.documentGridData);
          },
          err => {
            this.sendSpinnerFlag.next(false);
            this.documentGridData = null;
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }
  handleSpecialChar(value): string {
    value = value.replaceAll("'", "''");
    enum Special {
      '(' = '%28',
      ')' = '%29',
      '-' = '%2D',
      '_' = '%5F',
      '*' = '%2A',
      '!' = '%21',
      '~' = '%7E',
      "'" = '%27'
    };
    Object.keys(Special).map(x => {
      value = value.replaceAll(x, Special[x]);
    })
    return value;
  }
  public removeDocumentList(filename) {
    const tokenValue = this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : false;
    let httpOptions;
    if (tokenValue) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': '*/*',
          'Authorization': tokenValue
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': '*/*'
        })
      };
    }
    this.sendSpinnerFlag.next(true);
    filename = this.handleSpecialChar(filename);
    let url = this.env['apiPoint'] + this.apiUrl.documentGridData + '/' + filename;
    return new Promise((resolve, reject) => {
      this.http.request('delete', url, httpOptions).pipe(catchError((error: any): any => {
        this.sendSpinnerFlag.next(false);
        reject(error);
        return observableThrowError(error.json().error || 'Server error');
      })).subscribe((res: object) => {
        if (res) {
          this.sendSpinnerFlag.next(false);
          resolve(res);
        }
      });
    }).catch(err => {
      this.sendSpinnerFlag.next(false);
    });
  }

  public filterDocumentList(document: any) {
    this.sendSpinnerFlag.next(true);
    return new Promise((resolve, reject) => {
      const workListUrl = this.env.aemEndPoint + this.apiUrl.documentGridData;
      this.http.get<any>(workListUrl)
        .subscribe(
          res => {
            this.documentGridData = res['cabinets'];
            if (!document.serchfromDate) {
              document.serchfromDate = '-11';
            }
            if (!document.serchtoDate) {
              document.serchtoDate = '-11';
            }

            this.documentGridData = this.documentGridData.filter(
              ele => {
                if (ele.documentTitle.toLowerCase().includes(document.id.toLowerCase().trim()) ||
                  ele.publishedDate.toLowerCase().includes(document.serchfromDate.toLowerCase().trim()) ||
                  ele.publishedDate.toLowerCase().includes(document.serchtoDate.toLowerCase().trim())
                ) {
                  return ele
                }
              })
            this.sendSpinnerFlag.next(false);
            resolve(this.documentGridData);
          },
          err => {
            this.sendSpinnerFlag.next(false);
            this.documentGridData = null;
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }

  public getuploadDocumetModel() {
    this.sendSpinnerFlag.next(true);
    return new Promise((resolve, reject) => {
      const createuserUrl = this.env.aemEndPoint + this.apiUrl.uploadDocumentModel;
      this.http.get<any>(createuserUrl)
        .subscribe(
          res => {
            this.sendSpinnerFlag.next(false);
            this.uploadLabelAuthoringDataObj = res;
            resolve(this.uploadLabelAuthoringDataObj);
          },
          err => {
            this.uploadLabelAuthoringDataObj = null;
            this.sendSpinnerFlag.next(false);
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }

  uploadDoc(jsonData, fileToUpload, overWriteFile) {
    this.sendSpinnerFlag.next(true);
    let formData: FormData = new FormData();
    for (let a of fileToUpload) {
      formData.append('file', a);
    }
    const _httpOptions = {
      headers: new HttpHeaders({
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    formData.append('fileInfo', JSON.stringify(jsonData));
    let url = this.env.apiPoint + this.apiUrl.documentGridData;
    if (overWriteFile) {
      url = this.env.apiPoint + this.apiUrl.documentGridData + "?overWrite=true";
    }
    return new Promise((resolve, reject) => {
      this.httpClient.post(url, formData, _httpOptions).subscribe((res: any) => {
        this.sendSpinnerFlag.next(false);
        resolve(res);
      }, err => {
        this.sendSpinnerFlag.next(false);
        reject(err);
      });
    });
  }

  setCabinetsDocDetailsData(data: any) {
    this.cabinetsDocDetailsData.next(data);
  }

  setCabinetsDetailsData(data: any) {
    this.cabinetsDetailsData = data;
  }

  public getCabinetsGridHeader() {
    return new Promise((resolve, reject) => {
      this.http.get<GridHeaderInterface>(this.env.aemEndPoint + this.apiUrl.cabinetsgridHeader)
        .subscribe(res => {
          this.documentsGridHeader = res;
          resolve(true);
        }, err => {
          reject(err);
        });
    });
  }

  public getCabinetsModel() {
    this.sendSpinnerFlag.next(true);
    return new Promise((resolve, reject) => {
      const url = this.env.aemEndPoint + this.apiUrl.infraCoModel;
      this.http.get<any>(url)
        .subscribe(
          res => {
            this.gridLabelAuthoringDataObj = res.data.content;
            resolve(this.gridLabelAuthoringDataObj);
          },
          err => {
            this.gridLabelAuthoringDataObj = null;
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }

  public getCreateDocumentModel() {
    this.sendSpinnerFlag.next(true);
    return new Promise((resolve, reject) => {
      const url = this.env.aemEndPoint + this.apiUrl.createCabinetModel;
      this.http.get<any>(url)
        .subscribe(
          res => {
            this.sendSpinnerFlag.next(false);
            this.createCabinetLabelAuthoringDataObj = res.data.content;
            resolve(this.createCabinetLabelAuthoringDataObj);
          },
          err => {
            this.sendSpinnerFlag.next(false);
            this.createCabinetLabelAuthoringDataObj = null;
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }

  public createCabinetDocument(payload) {
    this.sendSpinnerFlag.next(true);
    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    return new Promise((resolve, reject) => {
      const url = this.env.apiPoint + this.apiUrl.cabinetsUrl;
      this.http.post<any>(url, payload, httpOptions)
        .subscribe(res => {
          this.sendSpinnerFlag.next(false);
          resolve(res);
        }, err => {
          this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public deleteCabinet(cabinetId) {
    const tokenValue = this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : false;
    let httpOptions;
    if (tokenValue) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': '*/*',
          'Authorization': tokenValue
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': '*/*'
        })
      };
    }
    this.sendSpinnerFlag.next(true);
    cabinetId = this.handleSpecialChar(cabinetId);
    let url = this.env['apiPoint'] + this.apiUrl.cabinetsUrl + '/' + cabinetId;
    return new Promise((resolve, reject) => {
      this.http.delete<any>(url, httpOptions)
        .subscribe(res => {
          this.sendSpinnerFlag.next(false);
          resolve(res);
        }, err => {
          this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getCabinetGridData(searchKeyword?) {
    this.sendSpinnerFlag.next(true);
    const tokenValue = this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : false;
    let httpOptions;
    if (tokenValue) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': '*/*',
          'Authorization': tokenValue
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': '*/*'
        })
      };
    }
    let workListUrl = this.env.apiPoint + this.apiUrl.cabinetsUrl;
    if (searchKeyword) {
      searchKeyword = this.handleSpecialChar(searchKeyword);
      workListUrl = workListUrl + '?searchParam=' + searchKeyword
    }
    this.infraCoGridData = [];
    return new Promise((resolve, reject) => {
      this.http.get<any>(workListUrl, httpOptions)
        .subscribe(
          res => {
            this.sendSpinnerFlag.next(false);
            if (res && res['data'] && res['data']['cabinets'].length > 0) {
              this.infraCoGridData = res['data']['cabinets'];
            } else {
              this.infraCoGridData = [];
            }
            resolve(this.infraCoGridData);
          },
          err => {
            this.sendSpinnerFlag.next(false);
            this.infraCoGridData = null;
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }
}
