
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'tw-core-ui';
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { TitleCasePipe } from '@angular/common';

@Injectable()
export class SiteDetailsService {
  clearClicked: boolean = false;

  constructor(
    private titleCasePipe: TitleCasePipe
  ) {

  }


  getSiteAendFormConfig(savedData) {
    return {
      formName: 'aEndAdress',
      title: (savedData.doYouRequireDiversity == "Yes" &&
        savedData.describeYourDiversity === 'All new services with diversity') ? 'Path 1: Site A end address' : 'Site A end address',
      activeTab: this.getActiveTab(savedData.aEndAdress),
      activateZendOnSearch: true,
      formData: savedData.aEndAdress,
      doYouRequireDiversity: savedData.doYouRequireDiversity,
      mapCenter: { lat: -37.8098714, lng: 144.9698015 },
      mapZoom: 15,
      markers: [],
      showInfoWindowBtn: true,
      aEndpreDefinedNodeName: savedData.aEndpreDefinedNodeName
    }
  }

  getActiveTab(d) {

    if (d) {
      switch (d.searchMethod) {
        case 'search':
          return 1;
        case 'Latitude and Longitude':
          return 2;
        case '':
          return 1;
        default:
          return 3;
      }
    } else {
      return 1;
    }

  }



  getSiteZendFormConfig(savedData) {
    return {
      formName: 'zEndAdress',
      title: (savedData.doYouRequireDiversity == "Yes" &&
        savedData.describeYourDiversity === 'All new services with diversity') ? 'Path 1: Site Z end address' : 'Site Z end address',
      activeTab: this.getActiveTab(savedData.zEndAdress),
      activateZendOnSearch: false,
      activateA2endOnSearch: true,
      formData: savedData.zEndAdress,
      doYouRequireDiversity: savedData.doYouRequireDiversity,
      mapCenter: { lat: -37.8098714, lng: 144.9698015 },
      mapZoom: 15,
      markers: [],
      showInfoWindowBtn: true,
      zEndpreDefinedNodeName: savedData.zEndpreDefinedNodeName
    }
  }

  getSiteA2endFormConfig(savedData) {
    return {
      formName: 'a2EndAdress',
      title: 'Path 2: Site A end address',
      activeTab: this.getActiveTab(savedData.a2EndAdress),
      activateA2endOnSearch: false,
      activateZ2endOnSearch: true,
      formData: savedData.a2EndAdress,
      doYouRequireDiversity: savedData.doYouRequireDiversity,
      mapCenter: { lat: -37.8098714, lng: 144.9698015 },
      mapZoom: 15,
      markers: [],
      showInfoWindowBtn: true,
      a2EndpreDefinedNodeName: savedData.a2EndpreDefinedNodeName
    }
  }

  getSiteZ2endFormConfig(savedData) {
    return {
      formName: 'z2EndAdress',
      title: 'Path 2: Site Z end address',
      activeTab: this.getActiveTab(savedData.z2EndAdress),
      activateZ2endOnSearch: false,
      formData: savedData.z2EndAdress,
      doYouRequireDiversity: savedData.doYouRequireDiversity,
      mapCenter: { lat: -37.8098714, lng: 144.9698015 },
      mapZoom: 15,
      markers: [],
      showInfoWindowBtn: true,
      z2EndpreDefinedNodeName: savedData.z2EndpreDefinedNodeName
    }
  }


  fillNoDataLatLng(lat, lng) {
    return [{
      adborId: "",
      address: "(New address)",
      primaryAddress: "(New address)",
      addressType: "",
      esa: "",
      latitude: lat,
      longitude: lng
    }]
  }
  fillNoDataReference() {
    return [{
      adborId: "",
      address: "",
      primaryAddress: "",
      addressType: "",
      esa: "",
      latitude: '',
      longitude: ''
    }]
  }

  refrenceSearchType() {
    return [
      'ADBoR ID',
      'Node Code',
      'NBN Location ID',
    ]
  }

  refrenceSearchApiAndPayload(refrenceType) {
    switch (refrenceType.searchMethod) {
      case 'ADBoR ID':
      case 'refrence-ADBoR ID':
        return {
          api: 'infraco-addresses/search-by-address-id',
          payload: { addressId: refrenceType.rawSearchText.trim() }
        }
      case 'Node Code':
      case 'refrence-Node Code':
        return {
          api: 'infraco-addresses/search-by-node-code',
          payload: { nodeCode: refrenceType.rawSearchText.trim() }
        }
      case 'NBN Location ID':
      case 'refrence-NBN Location ID':
        return {
          api: 'infraco-addresses/search-by-nbn-location',
          payload: { nbnLocationId: refrenceType.rawSearchText.trim() }
        }
    }

  }

  getSearchResultRefrenceApi(refrenceType, res) {
    switch (refrenceType.searchMethod) {
      case 'ADBoR ID':
      case 'refrence-ADBoR ID':
      case 'NBN Location ID':
      case 'refrence-NBN Location ID':
        let arr = [];
        if (res['data'].addresses.length) {
          res['data'].addresses.map(obj => obj.primaryAddress = this.titleCasePipe.transform(obj.primaryAddress));
          arr = res['data'].addresses;
        } else {
          arr = this.fillNoDataLatLng(refrenceType.latitude, refrenceType.longitude);
        }
        return arr;
      case 'Node Code':
      case 'refrence-Node Code':
        if (res.data) {
          res.data.address = this.titleCasePipe.transform(res.data.address);
          res.data.primaryAddress = res.data.address
        }
        return res.data ? [res.data] : this.fillNoDataLatLng(refrenceType.latitude, refrenceType.longitude);
    }
  }

  onTabChangeClearData(tab, formConfig) {
    switch (tab.activeId) {
      case 1:
        const defaultSearchFormData = {
          primaryAutoComplete: "",
          additionalAddressInfo: "",
          definedPropertyLocation: "Yes",
          valid: false,
          formName: formConfig.formName,
          searchMethod: "",
          tabChanged: true
        }
        this.searchFormChange(defaultSearchFormData);
        break;
      case 2:
        const defaultLatLngFormData = {
          adborId: "",
          additionalAddressInfo: "",
          address: undefined,
          definedPropertyLocation: "Yes",
          formName: formConfig.formName,
          latitude: "",
          longitude: "",
          primaryAutoComplete: "",
          searchMethod: "",
          selectedAddress: "",
          siteType: "aEndAdress",
          valid: false,
          tabChanged: true
        }
        this.latLongFormChange(defaultLatLngFormData);
        break;
      case 3:
        const defaultRefrenceFormData = {
          adborId: null,
          additionalAddressInfo: "",
          address: undefined,
          definedPropertyLocation: "Yes",
          formName: formConfig.formName,
          latitude: "",
          longitude: "",
          nbnLocId: null,
          rawSearchText: "",
          searchMethod: "",
          selectedAddress: "",
          siteType: "aEndAdress",
          valid: false,
          tabChanged: true
        }
        this.referenceFormChange(defaultRefrenceFormData);
        break;
    }
  }


  //Using any
  public contactDetailsForm: any = [];
  // public subject = new Subject<any>();
  private contactDetailsFormSource = new BehaviorSubject(this.contactDetailsForm);
  contactDetailsFormMessage = this.contactDetailsFormSource.asObservable();
  contactDetailsFormChange(contactDetailsForm) {
    this.contactDetailsFormSource.next(contactDetailsForm)
  }

  //Using any
  public searchForm: any = []; searchFormAEndAdress; searchFormZEndAdress; latLongFormAEndAdress; latLongFormZEndAdress; referenceFormAEndAdress; referenceFormZEndAdress; searchFormA2EndAdress; searchFormZ2EndAdress; latLongFormA2EndAdress; latLongFormZ2EndAdress; referenceFormA2EndAdress; referenceFormZ2EndAdress
  // public subject = new Subject<any>();
  private searchFormSource = new BehaviorSubject(this.searchForm);
  searchFormMessage = this.searchFormSource.asObservable();
  searchFormChange(searchForm) {
    if (searchForm && searchForm.formName) {
      switch (searchForm.formName) {
        case "aEndAdress":
          this.searchFormAEndAdress = searchForm;
          this.latLongFormAEndAdress = undefined;
          this.referenceFormAEndAdress = undefined;
          if (!Boolean(this.searchFormAEndAdress?.valid || this.latLongFormAEndAdress?.valid || this.referenceFormAEndAdress?.valid)) {
            this.contactDetailsForm = undefined;

            this.searchFormZEndAdress = undefined;
            this.searchFormA2EndAdress = undefined;
            this.searchFormZ2EndAdress = undefined;

            this.latLongFormZEndAdress = undefined;
            this.latLongFormA2EndAdress = undefined;
            this.latLongFormZ2EndAdress = undefined;

            this.referenceFormZEndAdress = undefined;
            this.referenceFormA2EndAdress = undefined;
            this.referenceFormZ2EndAdress = undefined;
          }

          break;
        case "zEndAdress":
          if (this.searchFormAEndAdress?.formName || this.latLongFormAEndAdress?.formName || this.referenceFormAEndAdress?.formName) {
            this.searchFormZEndAdress = searchForm;
            this.latLongFormZEndAdress = undefined;
            this.referenceFormZEndAdress = undefined;
          } else {
            // this.contactDetailsForm = undefined;

            this.searchFormZEndAdress = undefined;
            this.searchFormA2EndAdress = undefined;
            this.searchFormZ2EndAdress = undefined;

            this.latLongFormZEndAdress = undefined;
            this.latLongFormA2EndAdress = undefined;
            this.latLongFormZ2EndAdress = undefined;

            this.referenceFormZEndAdress = undefined;
            this.referenceFormA2EndAdress = undefined;
            this.referenceFormZ2EndAdress = undefined;
          }
          break;
        case "a2EndAdress":
          if (this.searchFormA2EndAdress?.formName || this.latLongFormA2EndAdress?.formName || this.referenceFormA2EndAdress?.formName) {
            this.searchFormA2EndAdress = searchForm;
            this.latLongFormA2EndAdress = undefined;
            this.referenceFormA2EndAdress = undefined;
          } else {
            // this.contactDetailsForm = undefined;

            this.searchFormA2EndAdress = undefined;
            this.searchFormZ2EndAdress = undefined;

            this.latLongFormA2EndAdress = undefined;
            this.latLongFormZ2EndAdress = undefined;

            this.referenceFormA2EndAdress = undefined;
            this.referenceFormZ2EndAdress = undefined;
          }
          break;
        case "z2EndAdress":
          if (this.searchFormZ2EndAdress?.formName || this.latLongFormZ2EndAdress?.formName || this.referenceFormZ2EndAdress?.formName) {
            this.searchFormZ2EndAdress = searchForm;
            this.latLongFormZ2EndAdress = undefined;
            this.referenceFormZ2EndAdress = undefined;
          } else {
            // this.contactDetailsForm = undefined;

            this.searchFormZ2EndAdress = undefined;

            this.latLongFormZ2EndAdress = undefined;

            this.referenceFormZ2EndAdress = undefined;
          }
          break;
      }
    }

    this.searchFormSource.next(searchForm)
  }

  public latLongForm: any = [];
  // public subject = new Subject<any>();
  private latLongFormSource = new BehaviorSubject(this.latLongForm);
  latLongFormMessage = this.latLongFormSource.asObservable();
  latLongFormChange(latLongForm) {
    if (latLongForm && latLongForm.formName) {
      switch (latLongForm.formName) {
        case "aEndAdress":
          this.latLongFormAEndAdress = latLongForm;
          this.searchFormAEndAdress = undefined;
          this.referenceFormAEndAdress = undefined;

          if (!Boolean(this.searchFormAEndAdress?.formName || this.latLongFormAEndAdress?.formName || this.referenceFormAEndAdress?.formName)) {
            this.contactDetailsForm = undefined;

            this.searchFormZEndAdress = undefined;
            this.searchFormA2EndAdress = undefined;
            this.searchFormZ2EndAdress = undefined;

            this.latLongFormZEndAdress = undefined;
            this.latLongFormA2EndAdress = undefined;
            this.latLongFormZ2EndAdress = undefined;

            this.referenceFormZEndAdress = undefined;
            this.referenceFormA2EndAdress = undefined;
            this.referenceFormZ2EndAdress = undefined;
          }

          break;
        case "zEndAdress":
          if (this.searchFormAEndAdress?.formName || this.latLongFormAEndAdress?.formName || this.referenceFormAEndAdress?.formName) {
            this.latLongFormZEndAdress = latLongForm;
            this.searchFormZEndAdress = undefined;
            this.referenceFormZEndAdress = undefined;
          } else {
            // this.contactDetailsForm = undefined;

            this.searchFormZEndAdress = undefined;
            this.searchFormA2EndAdress = undefined;
            this.searchFormZ2EndAdress = undefined;

            this.latLongFormZEndAdress = undefined;
            this.latLongFormA2EndAdress = undefined;
            this.latLongFormZ2EndAdress = undefined;

            this.referenceFormZEndAdress = undefined;
            this.referenceFormA2EndAdress = undefined;
            this.referenceFormZ2EndAdress = undefined;
          }
          break;
        case "a2EndAdress":
          if (this.searchFormA2EndAdress?.formName || this.latLongFormA2EndAdress?.formName || this.referenceFormA2EndAdress?.formName) {
            this.latLongFormA2EndAdress = latLongForm;
            this.searchFormA2EndAdress = undefined;
            this.referenceFormA2EndAdress = undefined;
          } else {
            // this.contactDetailsForm = undefined;

            this.searchFormA2EndAdress = undefined;
            this.searchFormZ2EndAdress = undefined;

            this.latLongFormA2EndAdress = undefined;
            this.latLongFormZ2EndAdress = undefined;

            this.referenceFormA2EndAdress = undefined;
            this.referenceFormZ2EndAdress = undefined;
          }
          break;
        case "z2EndAdress":
          if (this.searchFormZ2EndAdress?.formName || this.latLongFormZ2EndAdress?.formName || this.referenceFormZ2EndAdress?.formName) {
            this.latLongFormZ2EndAdress = latLongForm;
            this.searchFormZ2EndAdress = undefined;
            this.referenceFormZ2EndAdress = undefined;
          } else {
            // this.contactDetailsForm = undefined;

            this.searchFormZ2EndAdress = undefined;

            this.latLongFormZ2EndAdress = undefined;

            this.referenceFormZ2EndAdress = undefined;
          }
          break;
      }
    }
    this.latLongFormSource.next(latLongForm)
  }


  public referenceForm: any = [];
  // public subject = new Subject<any>();
  private referenceFormSource = new BehaviorSubject(this.referenceForm);
  referenceFormMessage = this.referenceFormSource.asObservable();
  referenceFormChange(referenceForm) {
    if (referenceForm && referenceForm.formName) {
      switch (referenceForm.formName) {
        case "aEndAdress":
          this.referenceFormAEndAdress = referenceForm;
          this.searchFormAEndAdress = undefined;
          this.latLongFormAEndAdress = undefined;
          if (!Boolean(this.searchFormAEndAdress?.formName || this.latLongFormAEndAdress?.formName || this.referenceFormAEndAdress?.formName)) {
            this.contactDetailsForm = undefined;

            this.searchFormZEndAdress = undefined;
            this.searchFormA2EndAdress = undefined;
            this.searchFormZ2EndAdress = undefined;

            this.latLongFormZEndAdress = undefined;
            this.latLongFormA2EndAdress = undefined;
            this.latLongFormZ2EndAdress = undefined;

            this.referenceFormZEndAdress = undefined;
            this.referenceFormA2EndAdress = undefined;
            this.referenceFormZ2EndAdress = undefined;
          }

          break;
        case "zEndAdress":
          if (this.searchFormAEndAdress?.formName || this.latLongFormAEndAdress?.formName || this.referenceFormAEndAdress?.formName) {
            this.referenceFormZEndAdress = referenceForm;
            this.searchFormZEndAdress = undefined;
            this.latLongFormZEndAdress = undefined;
          } else {
            // this.contactDetailsForm = undefined;

            this.searchFormZEndAdress = undefined;
            this.searchFormA2EndAdress = undefined;
            this.searchFormZ2EndAdress = undefined;

            this.latLongFormZEndAdress = undefined;
            this.latLongFormA2EndAdress = undefined;
            this.latLongFormZ2EndAdress = undefined;

            this.referenceFormZEndAdress = undefined;
            this.referenceFormA2EndAdress = undefined;
            this.referenceFormZ2EndAdress = undefined;
          }
          break;
        case "a2EndAdress":
          if (this.searchFormA2EndAdress?.formName || this.latLongFormA2EndAdress?.formName || this.referenceFormA2EndAdress?.formName) {
            this.referenceFormA2EndAdress = referenceForm;
            this.searchFormA2EndAdress = undefined;
            this.referenceFormA2EndAdress = undefined;
          } else {
            // this.contactDetailsForm = undefined;

            this.searchFormA2EndAdress = undefined;
            this.searchFormZ2EndAdress = undefined;

            this.latLongFormA2EndAdress = undefined;
            this.latLongFormZ2EndAdress = undefined;

            this.referenceFormA2EndAdress = undefined;
            this.referenceFormZ2EndAdress = undefined;
          }
          break;
        case "z2EndAdress":
          if (this.searchFormZ2EndAdress?.formName || this.latLongFormZ2EndAdress?.formName || this.referenceFormZ2EndAdress?.formName) {
            this.referenceFormZ2EndAdress = referenceForm;
            this.searchFormZ2EndAdress = undefined;
            this.latLongFormZ2EndAdress = undefined;
          } else {
            // this.contactDetailsForm = undefined;

            this.searchFormZ2EndAdress = undefined;

            this.latLongFormZ2EndAdress = undefined;

            this.referenceFormZ2EndAdress = undefined;
          }
          break;
      }
    }
    this.referenceFormSource.next(referenceForm)
  }
}



