import { Component, OnInit } from '@angular/core';
import { CabinetsService } from '../cabinets/cabinets.service';
import { SpinnerModule } from 'tw-core-ui';
import { FaqService } from '../faq/faq.service';
import { UsersService } from '../admin-settings/user/user-list/users.service';
import { DashboardService } from '../cabinets/dashboard.service';
import { DuctAccessService } from '../duct-access-form/duct-access.service';
import { CustomerService } from '../admin-settings/customer-list/customer.service';
import { WhitelistService } from '../whitelist/whitelist.service';

@Component({
  selector: 'app-spinner-modal',
  templateUrl: './spinner-modal.component.html',
  styleUrls: ['./spinner-modal.component.less']
})
export class SpinnerModalComponent implements OnInit {
  spinnerFlag: Boolean = false;
  constructor(
    private cabinetService: CabinetsService,
    private faqService: FaqService,
    private userService: UsersService, 
    private dashboardService: DashboardService, 
    private ductAccessService: DuctAccessService,
    private whitelistService: WhitelistService) { 
    this.cabinetService.spinnerCurrentStatus.subscribe(spinnerFlag => {
      this.spinnerFlag = spinnerFlag;
    });
    this.dashboardService.spinnerCurrentStatus.subscribe(spinnerFlag => {
      this.spinnerFlag = spinnerFlag;
    });
    this.faqService.spinnerCurrentStatus.subscribe(spinnerFlag => {
      this.spinnerFlag = spinnerFlag;
    });
    this.ductAccessService.spinnerCurrentStatus.subscribe(spinnerFlag => {
      this.spinnerFlag = spinnerFlag;
    });
    this.whitelistService.spinnerCurrentStatus.subscribe(spinnerFlag => {
      this.spinnerFlag = spinnerFlag;
    });
  }

  ngOnInit() {
  }

}

