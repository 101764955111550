import {
  Injectable,
  Injector,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  ApplicationRef
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SuccessModalWithCopyLinkService {
  componentRef: any;
  public content = new BehaviorSubject<any>('');
  public contentObs = this.content.asObservable();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) { }

  show(component: any, content: any) {
    this.content.next(content);
    this.componentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);
    this.appRef.attachView(this.componentRef.hostView);
    const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement
    document.body.appendChild(domElem);
  }

  remove() {
    this.appRef.detachView(this.componentRef.hostView);
    this.componentRef.destroy();
  }

}
