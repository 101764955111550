import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-dashboard-actions-renderer',
  templateUrl: './dashboard-actions-renderer.component.html',
  styleUrls: ['./dashboard-actions-renderer.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardActionsRendererComponent {
  @Input() public status: any;
  params;

  agInit(params: any): void {
    this.status = params.value;
    this.params = params;
  }

  viewDocument(){
    this.params.clicked(this.params.data, "view");
  }
  download(){
    this.params.clicked(this.params.data, "download");
  }
}