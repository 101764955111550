import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpService } from '../shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private httpService:HttpService,private ngxService: NgxUiLoaderService) { }

  private notification_error = new Subject();
  public notification_error$ = this.notification_error.asObservable();
  startNotificationLoader:boolean;


  public getNotifications(cidn:string,timezone:string){
    this.startNotificationLoader=true;
    setTimeout(()=>{
      if(this.startNotificationLoader){
        this.ngxService.startLoader("notificationLoader");
      }
    },5000);

    let obj = {
      cidn:cidn,
      timezone:timezone,
      Headers: this.httpService.getHeaders().headers
    }
    
    return this.httpService.get('inapp-notification/event-notifications', obj)
      .map((res: any) => res.data)
      .pipe(finalize(() => {
        this.ngxService.stopLoader("notificationLoader");
        this.startNotificationLoader = false;
      }), catchError((err: any) => {
        // const msg = err?.error?.message ? err.error.message : 'Something went wrong';
        // this.notification_error.next(msg as string);
        //this.startNotificationLoader = false;
        throw err;
      })
      );
      
  }

  saveSnowID(data) {
    localStorage.setItem('snowID', data);
  }
}
