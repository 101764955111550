import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrService, ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { HamburgerMenuModule, FooterModule, HeaderModule, BannerModule, SpinnerModule, TwCoreUIFormModule, CanDeactivateGuard, AuthGuardService, AuthService } from 'tw-core-ui';
import { UserListComponent } from './user-list/user-list.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { ModifyUserComponent } from './modify-user/modify-user.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectDropdownComponent } from 'src/app/shared/components/multi-select-dropdown/multi-select-dropdown.component';
import { UsersService } from './user-list/users.service';

@NgModule({
    declarations: [
        UserListComponent,
        CreateUserComponent,
        ModifyUserComponent,
        MultiSelectDropdownComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        TwCoreUIFormModule,
    ],
    exports: [
        UserListComponent,
        CreateUserComponent,
        ModifyUserComponent,
        MultiSelectDropdownComponent
    ],
    providers: [
        UsersService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class UsersModule { }
