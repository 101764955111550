import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from '../shared/services/common.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy{
  showTopButton: boolean = false;
  subscription: Subscription;
  constructor(private commonService: CommonService, private changeDetectorRef: ChangeDetectorRef) {}
  ngOnInit() {
    this.changeDetectorRef.detectChanges();
    this.isShowTopButton();
  }
  isShowTopButton() {
    this.subscription = this.commonService.showTopButton.subscribe(val => {
      if(val){
        this.showTopButton = true;
      } else {
        this.showTopButton = false;
      }
    })
  }

  backToTop(){
    const dummyElement = document.createElement('div');
    if(dummyElement) {
    dummyElement.innerHTML = '';
    document.body.appendChild(dummyElement);
    dummyElement.setAttribute('tabindex', '0');
    dummyElement.focus();
    }
    window.scrollTo({ top: 0, behavior: 'smooth' }) 
    setTimeout(() => {
      if(dummyElement) {
      document.body.removeChild(dummyElement);
      }
      // document.getElementById("main-page-container")?.focus();
    },1000)
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
