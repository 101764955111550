<div class="page-bg my-quote-container">
  <label style="position:absolute;left:-9999px" aria-live="assertive" role="alert">My Quotes page</label>
 
  <div class="d-flex justify-content-between align-items-center">
    <main aria-labelledby="myQuotesHeader">
    <div class="d-flex gap-2">
      <img class="quoteLogo" src="../../../assets/images/My-Quote.svg" />
      <h1 class="my-quote-header" id="myQuotesHeader" >
        <strong>{{aemLabelAuthoring?.pageHeader?.pageHeaderLabel}}</strong></h1>
    </div>
   </main>
    <div class="d-flex justify-content-center">
      <div class="notify">
        <span *ngIf="isShowAwait && columnsPresent" (click)="notify()" (keypress)="notify()">
          <img class="notifyImg" src="../../../assets/images/Awaiting-Customer-Response.svg" />
          <span tabindex="0" class="notify-text">{{aemLabelAuthoring?.pageHeader?.notify}} {{quoteStatus}}
            {{aemLabelAuthoring?.pageHeader?.notifyText}}</span>
        </span>
      </div>
    </div>
    <div class="alignNewquote d-flex">
      <div *ngIf="isReadWriteOnQuoteMgmt && cidnSelected !== infraCoCIDN">
        <button type="button" id="" class="app-btn-green app-btn-white mr-20" aria-label="New Single Quote"
        (click)="navigateToCreateQuote()">
        {{aemLabelAuthoring?.pageHeader?.newQuote}}
      </button>
        <button type="button" id="" class="app-btn-green app-btn-white" aria-label="New Bulk Quote"
          (click)="navigateToCreateBulkQuote()">
          {{aemLabelAuthoring?.pageHeader?.newBulkQuote}}
        </button>
      </div>
    </div>    
  </div>

  <div class="col-12 row">

    <div class="col-9 search-grid-container">
      <div class="searchGrid col-12">
        <span>
          <input maxlength="255" type="text" class="searchInputfilterQuote" id="text" (input)="onInputChange()"
            placeholder="Search by quote reference, bulk quote reference, customer reference, status, address" [(ngModel)]="searchvalue" />
        </span>
        <span class="col-xs-12 col-sm-3 col-md-4 col-lg-3 idsearch">
          <button type="button" style="height: 42px;font-size: 15px;font-family: 'HelveticaNeue'"
            class="primary-globalbtn-cls" aria-label="Search" (click)="quickSearch()"
            [disabled]="!searchvalue || searchvalue?.length < 3">{{aemLabelAuthoring?.pageHeader?.search}}</button>
        </span>
        <span class="col-xs-12 col-sm-3 col-md-3 col-lg-3 idsearch">
          <button type="button" class="texttype-globalbtn-cls" [disabled]="!searchvalue"
            (click)="clearsearch()">{{aemLabelAuthoring?.pageHeader?.clear}}</button>
        </span>
      </div>
    </div>
     <div class="colPref col-3">
      <button [disabled]="!selectedRows.length" [ngClass]="{'disabledDwn-button': !selectedRows.length}"
        class="quoteColPref " tabindex="0" aria-label="download" role="button" type="button"
        (click)="downloadSummary()">
        <!-- download-btn -->
        <img class="downloadIcon" *ngIf="selectedRows.length" src="../../../../assets/images/icon-download.svg" alt="download icon" />
        <img class="downloadIcon" *ngIf="!selectedRows.length" src="../../../../assets/images/icon-download-disabled.svg" alt="download icon disabled" />
        {{aemLabelAuthoring?.pageHeader?.download}}
      </button>
      <button role="button" type="button" id="colPref" tabindex="0" class="quoteColPref" aria-labelledby="col-pref-dialog" aria-modal="true" [aria-label]="aemLabelAuthoring?.pageHeader?.colPref"
         (click)="showPop()">
        <img class="downloadIcon" src="../../../assets/images/icon-column-preferences.svg"
          alt="" /> {{aemLabelAuthoring?.pageHeader?.colPref}}
      </button>
      <div  *ngIf="showFilterPop" class="custom-arrow-up-column"></div>    

      <div class="drop-show" id="colPrefTabUsr" *ngIf="showFilterPop">
        <label style="position:absolute;left:-9999px" id="col-pref-dialog" aria-live="assertive">Column preference pop-up</label>
        <span class="headCls">
          <label class="col-10 labelPref" id="col-pref-dialog">{{aemLabelAuthoring?.pageHeader?.colPref}}</label>
          <button class="closePop" tabindex="0" id="fir" aria-label="Close column preferences" (click)="closePopup()" (keydown.enter)="closePopup()" (keydown.space)="closePopup()">
            <i class="icon-icon-ui-cross"></i>
          </button>
        </span>  

        <div class="popup">
          <div class="decorPop">
            <label class="colmPrefHeaderDiv">{{aemLabelAuthoring?.pageHeader?.selectPref}}</label>
            <ng-container *ngFor="let a of functionList; index as i">
              <label class="labelItm" (keydown.enter)="onCheckboxKeydown($event, a)" (keydown.space)="onCheckboxKeydown($event, a)"  attr.aria-labelledby="span_{{i}}" for="input_{{i}}" class="mainColPref" >
                <input type="checkbox" [checked]="a.checked" tabindex="0" name="name_{{i}}" class="ijm" [(ngModel)]="a.checked" name="name_{{i}}" id="input_{{i}}" 
                  (change)="getSelectedValue(a.checked,a.value,a.colId,a.tooltipField,a.filter,a.headerName,a.fullHeaderName,a.customFilter,a.cellRendererFramework)" 
                   />
                <span [attr.aria-label]="a.name"  attr.aria-checked="{{a.checked}}" role="checkbox" class="checkicon" tabindex="0"></span>
                {{a.name}}
              </label>
            </ng-container>
          </div>
        </div>


        <div class="saveCancelBlock">
          <button class="closePop accessibilty-last-cross" id="rmv" aria-label="Close column preferences" (click)="closePopup()" (keydown.enter)="closePopup()" (keydown.space)="closePopup()">
            <i class="icon-icon-ui-cross"></i>
          </button>
        </div>
      </div>     

	   </div>
  </div>
  <div class="toastrContainer" aria-live="polite" role="alert" toastContainer></div>
  <div class="grid-container my-quotes-grid" *ngIf="gridConfig && gridInitialized && columnsPresent">
    <app-cui-ag-grid [model]="gridModel" (selectedOnChanged)="onSelectionChanged($event)"
      (buttonClicked)="navigateToCreateUser()" (focusOnTop)="focusOnTop($event)"
      (filtersOnChanged)="onFilterSelected($event)" (rowClicked)="onRowClicked($event)" [rowSelection]="'multiple'"
      [suppressRowClickSelection]="true">
    </app-cui-ag-grid>
  </div>
  <div *ngIf="!columnsPresent" class="no-column"> No columns selected </div>
</div>
     