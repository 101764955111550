import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-documents-title-renderer-component',
  templateUrl: './documents-title-renderer-component.component.html',
  styleUrls: ['./documents-title-renderer-component.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DocumentsTitleRendererComponent {

  public fileTitle: string;
  public fileDesc: string;
  private params;

  agInit(params: any): void {
    this.fileTitle = params.value;
    this.fileDesc = params.data.documentType;
    this.params = params;
  }

  downloadOnClick() {
    this.params.clicked(this.params.data);
  }
}

