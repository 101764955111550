import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'tw-core-ui';
import { CommonService } from './shared/services/common.service';
import { MapsService } from './maps.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'InfraCo Customer Portal';
  status = [];
  showContentFlag: Boolean = false;
  setFlag: boolean = false;
  isOpenedWindow: Boolean = false;
  private env: any = window['environment'];


  constructor(private authService: AuthService, private router: Router, private commonService: CommonService,
    private activeRoute: ActivatedRoute, private mapsService: MapsService) {
    if (window.location.href.search('error_description') > -1) {
      window.location.href = window['userManagerObj'].unauthorized_access_uri;
    } else {
      this.authService.completeAuthentication().then(() => {
        this.getUserDetails().then((res: any) => {
          this.showContentFlag = true;
          sessionStorage.setItem('contactName', res.given_name + ' ' + res.family_name);
          sessionStorage.setItem('firstName', res.given_name);
          sessionStorage.setItem('lastName', res.family_name);
          sessionStorage.setItem('email', res.username);
          sessionStorage.setItem('cidn', res.roles[0].value.split(":")[0]);
          localStorage.removeItem('snowID');
          this.navigate();
        });
      });
    }
  }

  ngOnInit(): void {
    // this.isShowContentCheck();
  }

  isShowContentCheck() {
    this.commonService.showHomeContent.subscribe(val => {
      if (val) {
        this.showContentFlag = false;
      } else {
        this.showContentFlag = true;
      }
    })
  }


  navigate() {
    if (this.authService.user.state) {
      this.router.navigate([this.authService.user.state]);
      this.showContentFlag = true;
      this.mapsService.fetchGoogleMapsApiKey();

    }
    else {
      // this.showContentFlag = true;
      // setTimeout(() => {
      //   const routePath = this.activeRoute?.snapshot?.routeConfig?.path;
      // if(routePath === 'document/:cid/:name' ) {
      //   this.showContentFlag = false;
      // } else {
      //   this.showContentFlag = true;
      // }   
      // }
      // }, 2000);    
      //this.router.navigate(['/']);
      //  this.commonService.showHomeContent.subscribe(val => {
      //   if(val){
      //     this.showContentFlag = false;
      //   } else {
      //     this.showContentFlag = true;
      //   }
      // })
      this.showContentFlag = false;
      const url = this.router.url;
      if (url.includes('/?code=')) {
        this.showContentFlag = true;
      }
      else {
        this.showContentFlag = false;
      }
    }
  }

  getUserDetails() {
    if (this.env['production'] === false && window.location.href.search('localhost') > -1) {
      return this.commonService.getUserDetailsForLocal()
    } else {
      return this.authService.getUserInfo();
    }
  }

}
