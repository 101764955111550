<div class="wrapper-uploads">
  <div *ngIf="docURL">
    <object [data]="docURL" type="application/pdf" width="100%" height="100vh">
      <p>Your browser does not support PDFs.
        <a [href]="docURL">Download the PDF</a>.
      </p>
    </object>
  </div>
  <div class="download-message" *ngIf="showDownloadMessage">
    <h3>{{showDownloadMessage}}</h3>
  </div>
</div>
<br><br><br><br>