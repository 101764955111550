<div class="duct-access-dropdown-menu-container" (mouseleave)="mouseLeaveEvent()">
    <div class="drpdwn-container">
      <div>
        <ul class="drpdwn-menu">
          <li tabindex="0"
            class="drpdwn-menu-list menu-list-text"
            (keypress)="navigate(menu.title)"
            (focusout)="collapse(menu.title)"
            *ngFor="let menu of mainMenu; let i = index"
            [ngClass]="{ 'active-menu': selectedIndex === i }"
            (mouseover)="showSubMenu(menu.title, i)"
            routerLink="{{menu.url}}"
            (click)="closeMenu(menu.title)">
            {{ menu.title }}
          </li>
        </ul>
      </div>
  
      <div *ngIf="isSubMenuOpen">
        <div class="drpdwn-sub-menu menu-list-text row">
          <span class="sub-menu-list" tabindex="0" *ngFor="let menu of subMenu" 
          [routerLink]="[menu.url, menu.id]" (click)="closeMenu(menu.title)">
             <span>{{
            menu.title
          }}</span></span>
        </div>
      </div>
    </div>
  </div>
  