<div *ngIf="nonNbnUser" class="home-container">
 <app-banner></app-banner>

  <!-- card section starts -->
  <!-- future scope  -->
  <div class="card-sction-cls">
  </div>
  <!-- card section ends -->
</div>

<div *ngIf="toShowPop">
  <app-landing-pop-up #myModal> </app-landing-pop-up>
</div>

<div *ngIf="nbnInfracoAdmin">
  <app-cabinets></app-cabinets>
</div>

<div *ngIf="nbnUser">
  <app-nbn-user></app-nbn-user>
</div>
