<!-- <div aria-live="assertive" class="visually-hidden" id="custom-label">
  <span role="presentation" style="position:absolute;left:-9999px">Create New User Screen opened</span>
</div> -->
<form role="region" [formGroup]="createUserForm" id="createUserForm" aria-labelledby="custom-label">
  <div>
    <nav aria-label="breadcrumb" #divToScroll>
      <ol class="breadcrumb">
        <li class="breadcrumb-item" role="link"><a href="/">Home</a></li>
        <i class="icon-icon-ui-right-arrow breadcrumb-icon"></i>
        <li class="breadcrumb-item mid" role="link" (keypress)="cancel()" (click)="cancel()" tabindex="0"><a>My user</a>
        </li>
        <i class="icon-icon-ui-right-arrow breadcrumb-icon"></i>
        <li class="breadcrumb-item active" aria-current="page">Create New User</li>
      </ol>
    </nav>
    <h2 id="createUser" class="user-hdng-cls">Create New User</h2>
    <span class="accLabel">Fields marked with <span class="star">*</span> are mandatory</span>
    <div>
      <label class="user-lbl-cls"> Username <span class="inpt-reqrd-cls">*</span> </label>
      <div class="row">
        <div class="inpt-type1-cls">
          <app-cui-textfield aria-label="user name with character limit 64" [model]="userNameModel"
            [parentFormGroup]="createUserForm" [ngClass]="{'invalidUserName': userNameExistMessage}"
            (inputValue)="onUsernameChange($event)">
          </app-cui-textfield>
        </div>
        <img *ngIf="isValidUser && !createUserForm.controls['username'].invalid" class="username-checkIcon-cls"
          src="../../../../assets/images/checkmark-circle-outline.svg" alt="Please create a user"
          aria-label="Please create a user" aria-hidden="true" />

        <img *ngIf="createUserForm.controls['username'].touched && createUserForm.controls['username'].invalid"
          src="../../../../assets/images/Cross-Circle.svg" alt="Invalid user" class="username-checkIcon-cls" />

        <span role="alert" class="newUserText-cls"
          *ngIf="isValidUser && !createUserForm.controls['username'].invalid">Username is available</span>
        <button class="primary-globalbtn-cls" (click)="checkUser()" [disabled]="checkUserIfEnabled()">Search
          User</button>
      </div>
    </div>

    <div class="row">

      <div class="inpt-col-cls">
        <label class="user-lbl-cls"> First name <span class="inpt-reqrd-cls">*</span> </label>
        <div class="inpt-type2-cls">
          <app-cui-textfield [model]="firstNameModel" [parentFormGroup]="createUserForm">
          </app-cui-textfield>
        </div>
      </div>

      <div class="inpt-col-cls">
        <label class="user-lbl-cls"> Last name <span class="inpt-reqrd-cls">*</span> </label>
        <div class="inpt-type2-cls">
          <app-cui-textfield [model]="lastNameModel" [parentFormGroup]="createUserForm">
          </app-cui-textfield>
        </div>
      </div>

      <div class="inpt-col-cls">
        <label class="user-lbl-cls"> Mobile number</label>
        <div class="inpt-type2-cls">
          <app-cui-textfield [model]="mobileNumberModel" [parentFormGroup]="createUserForm">
          </app-cui-textfield>
        </div>
      </div>

      <div class="inpt-col-cls" *ngIf="isNBN || isDuctRole || isInfracoAdminUserMgmtRole">
        <label class="user-lbl-cls"> Status <span class="inpt-reqrd-cls">*</span> </label>
        <div class="inpt-type2-cls">
          <app-cui-searchable-dropdown #status name="businessTypes" [formControlName]="'status'"
            [model]="statusDropDownModel" [labelName]="'statusDropdown'" [placeholder]="'Select Status'"
            [isRequired]=true [items]=statuses automationType="dropdown" automationId="drpdwnstatusType"
            [noAutoComplete]=false id="status" class="user-dropDown"
            [ngClass]="{'invalid-form-control': (createUserForm.controls.status.invalid && createUserForm.controls.status.touched)}"
            required>
          </app-cui-searchable-dropdown>
        </div>
        <div class="row ml-0" aria-live="assertive">
          <span role="alert" *ngIf="createUserForm.controls.status.invalid && createUserForm.controls.status.touched"
            class="help-block errorMsg">
            Please select status
          </span>
        </div>
      </div>

    </div>

    <table class="tble-cls" *ngIf="customerDetailsList?.length>0">

      <tr>
        <th>Customer name</th>
        <th>Application</th>
        <th>Role access</th>
        <th style="width: 35%;">Role function</th>
        <th></th>
      </tr>
      <tr class="modfy-usrs-list-cls" *ngFor="let list of customerDetailsList;let i = index">
        <td>{{list.customerName}}</td>
        <td>{{list.appName}}</td>
        <td>{{list.role_name}}</td>
        <td>{{list.functionName}}</td>
        <td>
          <button mat-button class="texttype-globalbtn-cls" type="button" (click)="removeCustomerDetails(i)"
            attr.aria-label="Remove {{list?.customerName}}">
            <img src="../../../../assets/images/Icon-Close-btn.svg" alt="Remove Customer" class="rmve-cstmr-cls" />
            <span aria-hidden="true">Remove</span>
          </button>
        </td>
      </tr>

    </table>
    <label role="alert" attr.aria-label="Successfully removed" *ngIf="showRemoveAlert"
      style="position:absolute;left:-9999px">Remove Action peformed</label>


    <div class="row div-block-cls additional-access-blk" *ngIf="!toAddCustomer">
      <!-- <div class="row"> -->

      <div class="inpt-col-cls">
        <label class="user-lbl-cls"> Customer name <span class="inpt-reqrd-cls">*</span> </label>
        <div class="inpt-type2-cls add-access-req" *ngIf="customers.length" #tref tabindex="-1">
          <app-cui-searchable-dropdown name="customername" [formControlName]="'customername'"
            [model]="customernameDropDownModel" [labelName]="'customerDropdown'" [placeholder]="'Select Customer'"
            [isRequired]=true (click)="touchCustomerName()" [items]=customers automationType="dropdown"
            automationId="drpdwncustomerType" (ngModelChange)="changeCustomerDropdown($event)"
            [ngClass]="{'invalid-form-control': (createUserForm.controls.customername.invalid && showErrorMsg)}" [noAutoComplete]=false
            id="customername" required>
          </app-cui-searchable-dropdown>
        </div>
        <!-- <div class="row ml-0  " aria-live="assertive" *ngIf="!isCustomerNameValid"> -->
        <span role="alert" *ngIf="showErrorMsg
  && createUserForm?.controls?.customername?.invalid" class="error-cls">
          <img class="error-icon" src="../../../../assets/images/icon-error.svg" />
          Please select customer name
        </span>
        <!--  </div> -->
      </div>

      <div class="inpt-col-cls">
        <label class="user-lbl-cls"> Application <span class="inpt-reqrd-cls">*</span> </label>
        <div class="inpt-type2-cls">
          <app-cui-searchable-dropdown name="application" [formControlName]="'application'"
            [model]="applicationDropDownModel" [labelName]="'appicationDropdown'" [placeholder]="'Select Appication'"
            [isRequired]=true [items]=applications automationType="dropdown" automationId="drpdwnappication"
            [noAutoComplete]=false id="application">
          </app-cui-searchable-dropdown>
        </div>
      </div>

      <div class="inpt-col-cls">
        <label class="user-lbl-cls"> Role access <span class="inpt-reqrd-cls">*</span> </label>
        <div class="inpt-type2-cls">
        <app-cui-searchable-dropdown name="roleAccess" [formControlName]="'roleAccess'"
                      [model]="roleAccessDropDownModel" (click)="touchRoleAccess()" [labelName]="'roleaccessDropdown'" [placeholder]="'Select Role Access'"
                      [isRequired]=true [items]=roleAccesses automationType="dropdown" automationId="drpdwnroleAccess"
                      [noAutoComplete]=false required id="roleAccess" (ngModelChange)="changeRoleDropdown()"
                      [ngClass]="{'invalid-form-control': (createUserForm.controls.roleAccess.invalid && showErrorMsgRole )}" [noAutoComplete]=false id="roleAccess">
        </app-cui-searchable-dropdown>
        </div>
        <!-- <div class="row ml-0" aria-live="assertive" *ngIf="!isRoleAccessValid"> -->
        <div class="row pt-1 ml-0" aria-live="assertive">
        <span role="alert" *ngIf="showErrorMsgRole
        && createUserForm?.controls?.roleAccess?.invalid "  class="error-cls">
        <img class="error-icon" src="../../../../assets/images/icon-error.svg" />
                      Please select role access
        </span>
        </div>
        </div> 
      <div class="inpt-col-cls">
        <label class="user-lbl-cls">Role function <span class="inpt-reqrd-cls">*</span> </label>
        <!-- <div tabindex="0"> -->
        <app-multi-select-dropdown [list]="functionList" [placeholder]="'Role Function'"
          [isDisabled]="isRoleFunctionDisabled" (shareCheckedList)="shareCheckedList($event)" role="combobox"
          aria-required="true"
          [ngClass]="{'invalid-multi-select-control': ((!isRoleFunctionValid && checkedRoleFunctions?.length == 0) ||
          ( isRoleFunctionTouched && checkedRoleFunctions.length  == 0))}"
          [resetDropdown]="isResetFunctions" (shareIndividualCheckedList)="shareIndividualCheckedList($event)"
          (click)="touchRoleFunction()" required>
        </app-multi-select-dropdown>
        <!-- </div> -->

        <div class="row ml-0" aria-live="assertive" *ngIf="((!isRoleFunctionValid && checkedRoleFunctions?.length == 0) ||
          ( isRoleFunctionTouched && checkedRoleFunctions?.length  == 0))">
          <span role="alert" class="error-cls">
            <img class="error-icon" src="../../../../assets/images/icon-error.svg" />
            Please select role function
          </span>
        </div>
      </div>
      <!-- </div> -->

      <div class="add-role-btn">
        <div class="save-cncl-btn-cls">
          <button class="texttype-globalbtn-cls" (click)="resetControls() && isResetFunctions = !isResetFunctions"
            tabindex="0">
            <i class="icon-icon-ui-cross"></i>Cancel
          </button>
          <button class="primary-globalbtn-cls" (click)="saveCustomer()"  [disabled]="checkFormValidation()"
            tabindex="0">Save</button>
        </div>
      </div>
    </div>

    <div style="width: 50%;padding:30px 0 10px 0;" *ngIf="isRoleSaved && toAddCustomer">

      <button class="outline-globalbtn-cls" (click)="addRoleAccess()"
        *ngIf="isValidUser && !createUserForm.controls['username'].invalid">
        <i class="icon-icon-ui-plus roundGreenBorderCls"></i>
        Additional access required
      </button>

    </div>

    <div class="submitCancelBtn">
      <button class="texttype-globalbtn-cls" (click)="cancel()" tabindex="0">
        <i class="icon-icon-ui-cross"></i>Cancel
      </button>
      <button class="primary-globalbtn-cls" (click)="submitUserForm()" [disabled]="checkSubmitBtnDisabled()"
        tabindex="0">Submit</button>
    </div>

  </div>

</form>
