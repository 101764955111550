<google-map
  height="150px"
  width="150px"
  [zoom]="zoom"
  [center]="center"
  [options]="options"
>

  <map-marker
    #markerElem
    *ngFor="let marker of markers"
    [position]="marker.position"
    [label]="marker.label"
    [title]="marker.title"
    [options]="marker.options"
   (mapDragend) = 'drag($event)'
   
  >
  </map-marker>
</google-map>