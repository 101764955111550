import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { forkJoin } from 'rxjs';
import * as moment_ from 'moment';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { ToastrService, GlobalConfig, ToastContainerDirective } from 'ngx-toastr';
import { saveAs as importedSaveAs } from 'file-saver';
import { CustomAgGrid, CustomCheckBox, CustomDropDown, CustomSearchBox, TooltipContent } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { DashboardService } from '../../cabinets/dashboard.service';
import { CabinetsService } from '../../cabinets/cabinets.service';
import { CustomTextFilterComponent } from '../../grid-filter/custom-text-filter/custom-text-filter.component';
import { CheckboxFilterComponent } from '../../grid-filter/checkbox-filter/checkbox-filter.component';
import { CustomDateFilterComponent } from '../../grid-filter/custom-date-filter/custom-date-filter.component';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-nbn-documents-list',
  templateUrl: './nbn-documents-list.component.html',
  styleUrls: ['./nbn-documents-list.component.less']
})

export class NbnDocumentsListComponent implements OnInit, OnDestroy {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  options: GlobalConfig;
  public gridConfig: GridOptions;
  currentMomentForNBNDocuments = moment_;
  rowData;
  private columnDefs;
  public searchId: any;
  gridInitialized: boolean;
  subscription: any;
  subscribedflag: Boolean = false;
  currentSubscription: any;
  currentMoment = moment_;
  startDateforApi;
  endDateforApi;
  keywordSearch;
  showGrid: boolean = false;
  noData: string;
  noDataOnSearch: string;
  apiLoader: boolean;
  cabinetIDList = [];
  selectedCabinetID: string;
  public cabinetIDModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'cabinetId'
  };
  public gridData: CustomAgGrid = {
    gridOptions: {},
    keysToSuppress: [13],
    labelAuthoring: {
      headerPageCount: '',
      filter: '',
      resetFilter: '',
      paginationHeader: '',
      noData: '',
      noDataOnFilter: '',
      noDataOnSearch: '',
      toTop: ''
    }
  };

  public searchModel: CustomSearchBox = {
    containerId: 'search-row',
    headerId: 'search-ticket-date-row',
    searchId: 'searchIdInput',
    searchButtonId: 'searchButton',
    isResetVisible: false,
    isSearchDisabled: true,
    isSearchOn: false,
    isDateSearchOn: true,
    labelAuthoring: {
      header: '',
      searchPlaceholder: '',
      searchTextAccessible: '',
      fromDateAccessible: '',
      toDateAccessible: '',
      searchByDate: '',
      search: '',
      reset: ''
    },
  };

  public checkClosedModel: CustomCheckBox = {
    ariaLabel: 'Display Closed Tickets',
    focused: false
  };

  public bandwidthTooltipContentModel: TooltipContent = {
    content: '',
    automationTypeTooltipIcon: 'image',
    automationIdTooltipIcon: 'imgAddressInformation'
  };

  public downloadSummaryTooltipContentModel: TooltipContent = {
    content: '',
    automationTypeTooltipIcon: 'image',
    automationIdTooltipIcon: 'imgAddressInformation'
  };

  public downloadDetailsTooltipContentModel: TooltipContent = {
    content: '',
    automationTypeTooltipIcon: 'image',
    automationIdTooltipIcon: 'imgAddressInformation'
  };

  public aemLabelAuthoring: any;
  public filteredRowData: any = [];
  public dashboardCardFilter = {
    cidn: '',
    status: ''
  };

  gridApi: any;
  selectedTimeZone: string = "Australia/Victoria";

  constructor(private router: Router,
    private dashboardService: DashboardService,
    private toastr: ToastrService,
    private cabinetsService: CabinetsService, private commonService: CommonService) {
    this.options = this.toastr.toastrConfig;
  }

  ngOnInit(): void {
    this.commonService.showTopButtonInFooter(true);
    this.getLabels();
    this.resetGrid(null);
    this.getCabinetIDList();
    const roles = this.commonService.getroleDetails().customerList;
    if (!roles.some((obj) => obj.role.includes('NBN') || obj.function.includes('NBN'))) {
      this.router.navigateByUrl('/access-denied');
    }
  }

  gridReady(param) {
    this.gridApi = param.value.event.api;
  }

  getCabinetIDList() {

    return new Promise((resolve, reject) => {
      this.cabinetsService.getCabinetGridData().then((res) => {
        const cabinetList = this.cabinetsService.infraCoGridData;
        cabinetList.forEach((ele) => {
          this.cabinetIDList.push(ele.cabinetId);
          this.cabinetIDList.sort((a, b) => (a.toLowerCase().localeCompare(b.toLowerCase())))
        })
        resolve(true);
      }).catch((err) => {
        reject(false);
      });
    });
  }

  enableSearch() {
    const searchId = document.getElementById('searchIdInput');
    const fromDate = document.getElementById('fromDateDatePicker');
    const toDate = document.getElementById('fromDateDatePicker');
    const searchButtons = document.getElementById('searchButton');
    if ((<HTMLInputElement>searchButtons)) {
      if ((<HTMLInputElement>searchButtons).disabled && (
        (<HTMLInputElement>fromDate).value || (<HTMLInputElement>toDate).value) || this.selectedCabinetID) {
        (<HTMLInputElement>searchButtons).disabled = false;
        this.searchModel.isSearchOn = true;
        this.searchModel.isSearchDisabled = false;
      } else if (!(<HTMLInputElement>searchId).value && !(<HTMLInputElement>fromDate).value &&
        !(<HTMLInputElement>toDate).value && !this.selectedCabinetID
      ) {
        (<HTMLInputElement>searchButtons).disabled = true;
        this.searchModel.isSearchOn = false;
        this.searchModel.isSearchDisabled = true;
      }
    }
  }

  getLabels() {
    return new Promise((resolve, reject) => {
      this.dashboardService.getDocumentListModel().then(() => {
        this.aemLabelAuthoring = { ...this.dashboardService.documentListModel };
        this.searchModel.labelAuthoring = this.aemLabelAuthoring.form.searchBox;
        this.gridData.labelAuthoring = { ...this.aemLabelAuthoring.form.grid };
        if (!this.noDataOnSearch || !this.noData) {
          this.noDataOnSearch = this.gridData.labelAuthoring.noDataOnSearch;
          this.noData = this.gridData.labelAuthoring.noData;
        }
        this.createGrid();
        resolve(true);
      }).catch(() => {
        reject(false);
      });
    });
  }


  createGrid(payload?) {
    if (this.subscribedflag) {
      this.subscription.unsubscribe();
    } else {
      this.subscribedflag = true;
    }
    const promise1 = this.createColumnDefs();
    const promise2 = this.createRowData(payload);
    this.subscription = forkJoin([promise1, promise2]).subscribe(result => {
      this.createGridConfig();
    }, error => {
      this.createGridConfig();
    });
  }

  private createColumnDefs() {
    return new Promise((resolve, reject) => {
      this.dashboardService.getGridHeader().then(() => {
        this.columnDefs = this.dashboardService.documentsGridHeader.headers;
        this.columnDefs.forEach(ele => {
          ele.suppressFilter = false;
          ele.suppressMenu = false;
        });
        this.columnDefs[0].hide = false;
        this.columnDefs.forEach((x: any) => {
          if (x.cellRendererFramework) {
            if (x.colId == 'documents-grid-actionItems') {
              x.cellRendererParams.clicked = function (field: any, type) {
                switch (type) {
                  case 'view':
                    this.viewDocument(field);
                    break;
                  case 'download':
                    this.download(field);
                    break;
                }
              }.bind(this);
            } else if (x.colId == 'documents-grid-Documenttitle') {
              x.cellRendererParams.clicked = function (field: any) {
                this.download(field);
              }.bind(this);
            }
          }
        });
        resolve(true);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  download(documentDetails) {
    const filename = (documentDetails) ? documentDetails.fileName : '';
    const name = documentDetails.cabinetId + '_' + filename;
    this.dashboardService.downloadFile(name).then(async res => {
      if (res) {
        if (res['body'].type === "application/json") {
          let content = JSON.parse(await res['body'].text());
        }
        importedSaveAs(res['body'], filename);
      }
    }).catch((err) => {
      if (err.status === 401) {
        this.router.navigateByUrl('/access-denied');
      } else {
      }
    });
  }

  async viewDocument(documentDetails) {
    const filename = (documentDetails) ? documentDetails.fileName : '';
    const modFilename = this.encodeURIComponent(filename);
    const modCid = this.encodeURIComponent(documentDetails.cabinetId);
    const serailizeUrl = this.router.serializeUrl(this.router.createUrlTree([`document/${modCid}/${modFilename}`]));
    window.open(serailizeUrl, '_blank');
  }

  getFileType(filetype) {
    let blobtype;
    switch (filetype) {
      case 'txt': blobtype = 'text/plain'
        break;
      case 'docx': blobtype = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        break;
      case 'doc': blobtype = 'application/msword'
        break;
      case 'pdf': blobtype = 'application/pdf'
        break;
      case 'xlsx': blobtype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        break;
      case 'xls': blobtype = 'application/vnd.ms-excel'
        break;
      case 'xlsb': blobtype = 'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
        break;
      case 'png': blobtype = 'image/png'
        break;
      case 'jpeg': blobtype = 'image/jpeg';
        break;
      case 'jpg': blobtype = 'image/jpeg';
        break;
      case 'pptx': blobtype = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        break;
      case 'zip': blobtype = 'application/zip';
        break;
      case 'csv': blobtype = 'text/csv';
        break;
      case 'bmp': blobtype = 'image/bmp';
        break;
      case 'json': blobtype = 'application/json';
        break;
      case 'ppt': blobtype = ' application/vnd.ms-powerpoint';
        break;

    }
    return blobtype;
  }

  private createRowData(payload?) {
    this.apiLoader = true;
    return new Promise((resolve, reject) => {
      this.dashboardService.getDocumentGridData(payload).then(() => {
        this.apiLoader = false;
        let data = this.dashboardService.documentGridData;
        if (data.length > 0) {
          this.rowData = data;
          this.rowData.forEach(element => {
            element.pubDate = this.currentMoment(element.pubDate).format('DD MMM YYYY HH:mm')
          });
          this.gridData.labelAuthoring.noDataOnSearch = this.noDataOnSearch;
        } else {
          this.rowData = [];
          if (payload) {
            this.gridData.labelAuthoring.noDataOnSearch = this.noDataOnSearch;
          } else {
            this.gridData.labelAuthoring.noDataOnSearch = this.noData;
          }
        }
        resolve(true);
      }).catch((err) => {
        this.apiLoader = false;
        this.rowData = [];
        if (payload) {
          this.gridData.labelAuthoring.noDataOnSearch = this.noDataOnSearch;
        } else {
          this.gridData.labelAuthoring.noDataOnSearch = this.noData;
        }
        reject(false);
      });
    });
  }

  focusOnTop(event) {
    if (event) {
      let focusableElement;
      focusableElement = document.getElementById('head');
      if (focusableElement) {
        focusableElement.setAttribute('tabindex', '0');
        focusableElement.addEventListener('blur', () => {
          focusableElement.removeAttribute('tabindex');
        });
        setTimeout(() => {
          if (focusableElement) {
            focusableElement.focus();
          }
        }, 10);
      }
    }
  }

  filterSearchInput(event) {
    this.gridInitialized = false;
    let fromDate;
    let toDate
    this.dashboardService.getGridHeader().then(() => {
      this.columnDefs = this.dashboardService.documentsGridHeader.headers;
      this.columnDefs.forEach(ele => {
        ele.suppressFilter = false;
        ele.suppressMenu = false;
      });
      this.columnDefs[0].hide = false;
      this.columnDefs.forEach((x: any) => {
        if (x.cellRendererFramework) {
          if (x.colId == 'documents-grid-actionItems') {
            x.cellRendererParams.clicked = function (field: any, type) {
              switch (type) {
                case 'view':
                  this.viewDocument(field);
                  break;
                case 'download':
                  this.download(field);
                  break;
              }
            }.bind(this);
          } else if (x.colId == 'documents-grid-Documenttitle') {
            x.cellRendererParams.clicked = function (field: any) {
              this.download(field);
            }.bind(this);
          }
        }
      });
    })
    if (event.serchfromDate != '') {
      fromDate = this.currentMoment(event.serchfromDate).format('DD-MMM-YY');
    }
    if (event.serchtoDate != '') {
      toDate = this.currentMoment(event.serchtoDate).format('DD-MMM-YY');
    }
    this.startDateforApi = fromDate ? fromDate : null;
    this.endDateforApi = toDate ? toDate : null;
    this.keywordSearch = event.id ? event.id : null;

    let jsonData: any = {
      startDate: event && event.serchfromDate ? event.serchfromDate : null,
      endDate: event && event.serchtoDate ? event.serchtoDate : null,
      searchKeyword: event && event.id ? event.id : null,
      cabinetId: this.selectedCabinetID
    };
    this.createGrid(jsonData);
  }

  resetGrid(payload) {
    this.gridInitialized = false;
    this.dashboardService.documentGridData = null;
    this.createGrid(payload);
    this.selectedCabinetID = '';
  }

  selected(event) {
    const searchButtons = document.getElementById('searchButton');
    let jsonData: any = {
      cabinetId: this.selectedCabinetID
    };
    (<HTMLInputElement>searchButtons).disabled = true;
    this.createGrid(jsonData);
  }

  createGridConfig() {
    this.gridConfig = <GridOptions>{};
    this.gridConfig.paginationPageSize = 10;
    this.gridConfig.columnDefs = this.columnDefs;
    this.gridConfig.rowData = this.rowData;
    this.gridConfig.suppressHorizontalScroll = true;
    this.gridConfig.pagination = true;
    this.gridConfig.rowSelection = 'single';
    this.gridInitialized = true;
    this.showGrid = true;
    this.gridConfig.frameworkComponents = {
      CheckboxFilterComponent: <any>CheckboxFilterComponent,
      customTextFilter: <any>CustomTextFilterComponent,
      CustomDateFilterComponent: <any>CustomDateFilterComponent,
    };
    this.gridData.gridOptions = this.gridConfig;
  }


  downloadDocument() {
    const data = [];
    const dataHeaders = ['Cabinet Id', 'Cabinet name', 'Document title', 'Last published date']
    let dataToBeDownloaded = [];
    if (this.rowData && this.rowData.length) {
      dataToBeDownloaded = this.rowData;
    }
    const now = this.currentMomentForNBNDocuments().format('DDMMYYHHMM');
    dataToBeDownloaded.forEach((selectedRow) => {
      data.push({
        'Cabinet Id': selectedRow.cabinetId ? selectedRow.cabinetId : '',
        'Cabinet name': selectedRow.cabinetName ? selectedRow.cabinetName : '',
        'Document title': selectedRow.title ? selectedRow.title : '',
        'Last published date': selectedRow.publishedDate ? selectedRow.publishedDate : ''
      });
    });

    return new Angular5Csv(data, 'NBN Documents' + now, { headers: dataHeaders });
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toastr-custom';
    this.options.disableTimeOut = false;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
  }

  onFilterSelected(filteredData) {
    this.filteredRowData = [];
    if (filteredData.filteredRowData && filteredData.filteredRowData.length > 0) {
      filteredData.filteredRowData.forEach(row => {
        if (row.data) {
          this.filteredRowData.push(row.data);
        }
      });
    }
  }

  ngOnDestroy() {
    this.commonService.showTopButtonInFooter(false);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.currentSubscription) {
      this.currentSubscription.unsubscribe();
    }
  }

  inputChange($event) {
    this.enableSearch();
  }

  encodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

}

