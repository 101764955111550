import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { CustomTextField, CustomDropDown, TooltipContent } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { CommonService } from '../shared/services/common.service';
import { HomePageService } from '../shared/services/homePage.service';
import { ProfileService } from './profile.service';
import { ToastContainerDirective, ToastrService, GlobalConfig } from 'ngx-toastr';
import { CustomerService } from '../admin-settings/customer-list/customer.service';
import { UserService } from '../admin-settings/user/user.service';
import { filter } from 'rxjs/operators'
import { AuthService } from 'tw-core-ui';
import { Title } from '@angular/platform-browser';
import { NgxUiLoaderService } from "ngx-ui-loader";


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  @ViewChild('primaryBusinessGroup') BusinessGroup;
  options: GlobalConfig;
  newRolesToAdd: any[] = [];
  enableSaveBus: boolean = false;
  infraCoCIDN = window['environment'].InfraCoCIDN;
  public operations: Array<Object> = [];
  myLinkedCustomerListFromCaiman = [];
  public selectBusinessNameLabel: any;
  public newRowData = [];
  defaultBusinessName: any;
  public isSelected: boolean = false;
  showNbnOption: boolean = false;
  verticalSaveEnable: boolean = false;
  showFiberOption: boolean = false;
  showIsExchangeAndInfrastructure: boolean = false;
  profileBusinessNames: any;
  existingRolesToModify = [];
  selectedIndex = 0;
  show = false;
  customerDetailsList: any = [
    {
      cidn: '',
      customername: '',
      application: '',
      roleaccess: '',
      rolefunctions: ''
    }
  ];
  EditContactName = false;
  showAdmintab: Boolean = false;
  customerDetails: any;
  headerVal: string = sessionStorage.getItem('headerValAll') ? sessionStorage.getItem('headerValAll') : '';
  primaryMail: string = sessionStorage.getItem('email') ? sessionStorage.getItem('email') : '';
  contactName: string = sessionStorage.getItem('contactName') ? sessionStorage.getItem('contactName') : '';
  hubPreference: string = sessionStorage.getItem('hubPref') ? sessionStorage.getItem('hubPref') : '';
  contactNumber: any;
  public primaryBusinessGroup: FormGroup;
  public businessNames: any[] = [];
  public verticalsNames: any[] = [];
  public bandwidthTooltipContentModel: TooltipContent = {
    content: 'This is your username and It can used as an option for two factor authentication',
    automationTypeTooltipIcon: 'image',
    automationIdTooltipIcon: 'imgAddressInformation'
  };
  public bandwidthTooltip1ContentModel: TooltipContent = {
    content: 'It can used as an option for two factor authentication',
    automationTypeTooltipIcon: 'image',
    automationIdTooltipIcon: 'imgAddressInformation'
  };
  public businessNameDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Default Customer Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  public verticalNameDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Default Super Admin Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };

  profileBusinessName: any;
  abc: any;
  showEmailEdit: boolean = false;
  showPrimaryCntEdit: boolean = false;
  showSecondaryCntEdit: boolean = false;
  EditContactNumber: boolean = false;
  onRefresh: boolean = false;


  isReadOnly: boolean = true;
  isReadOnlyPm: boolean = true;
  isReadOnlySm: boolean = true;
  enableSave: boolean = false;
  cidn: string = '';
  linkedCIDNs: any;
  application: any[];
  roles: any[];
  funtion: any[];
  cidnList: any[];
  customerList: { cidn: string; app: string; role: string; function: string; businessName: string }[];
  userData: any;
  resData: any;
  myLinkedCustomerList: any;
  custNames: any[] = [];
  adminNames: any[] = [];
  resp: string;
  setHeaderAftRefresh: any;
  isNBNuser: boolean = false;
  headerRefreshCheck: boolean = false;
  defaultCustomer: any[];
  popUpData: any;
  userName: any;
  setCustName: any;
  ifInfracoCustomerRole: boolean = false;
  cuurentSelect: any;
  status: any;
  myUserId: any;
  checkVerticalforSave: any;
  constructor(private fb: FormBuilder, private router: Router, private toastr: ToastrService, private profileService: ProfileService, private commonservice: CommonService, private customerService: CustomerService, private userService: UserService,
    private homepageservice: HomePageService, private authService: AuthService, private titleService: Title, private ngxService: NgxUiLoaderService) {
    this.primaryBusinessGroup = this.fb.group({

      businessTypes: [undefined, [Validators.required]],
      verticalTypes: [undefined, [Validators.required]],

    });

    this.options = this.toastr.toastrConfig;
    this.titleService.setTitle('InfraCo Customer Portal - My profile');
  }

  ngOnInit(): void {
    this.customerDetails = this.homepageservice.getCustomerDetails();
    this.isNBNUser();
    this.resp = this.commonservice.defaultProfileVal;
    if (this.resp) {
      this.primaryBusinessGroup.get('businessTypes')?.setValue(this.resp);
    } else {
      this.headerRefreshCheck = true;
      this.getCommonServices();
    }
    this.primaryBusinessGroup.valueChanges.subscribe(data => this.enableSave = true);
    this.getCommonServices();
  }

  ngAfterViewInit(): void {
    this.removeAccessibilityAttribute();
  }

  isNBNUser() {
    const roles = this.commonservice.getroleDetails().customerList;
    if (roles.some((obj) => obj.role.includes('NBN') || obj.function.includes('NBN'))) {
      this.isNBNuser = true
    } else {
      this.isNBNuser = false;
    }
  }

  getCommonServices() {
    this.userName = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail']
    this.homepageservice
      .getUserProfile(this.userName)
      .then((response) => {
        if (response.data.adminDetails.currentSelection) {
          this.cuurentSelect = response.data.adminDetails.currentSelection;
        }
        if (response.data.adminDetails.isSuperAdmin === "true") {
          this.showAdmintab = true;
        }
        if (response.data.adminDetails.isNbnUser === "yes") {
          this.showNbnOption = true;
          this.adminNames.push('NBN Definitive Agreements');
          this.verticalsNames = [...new Set(this.adminNames)];
        }
        else {
          this.showNbnOption = false;
        }
        if (response.data.adminDetails.isFibre === "yes") {
          this.showFiberOption = true;
          this.adminNames.push('Fibre');
          this.verticalsNames = [...new Set(this.adminNames)];

        }
        else {
          this.showFiberOption = false;
        }
        if (response.data.adminDetails.isExchangeAndInfrastructure === "yes") {
          this.showIsExchangeAndInfrastructure = true;
          this.adminNames.push('Exchanges & Infrastructure');
          this.verticalsNames = [...new Set(this.adminNames)];
        }
        else {
          this.showIsExchangeAndInfrastructure = false;
        }
        this.defaultCustomer = response.data.preferenceReference;
        this.myLinkedCustomerList = response.data.customerDetails;
        this.myLinkedCustomerList.forEach(option => {
          if (option.cidn === this.infraCoCIDN) {
            //match Infraco cidn to get all cutomer list to show in dropdown
            if (this.isNBNuser) {
              this.getSelectedCidnInitialization();
              this.matchNBNCidn();
            } else {
              this.matchCIDN();
            }
          } else {
            // this.businessNames.push(option.customerName);
            // this.getProfileData()
            const customerName = option.customerName;
            if (!this.businessNames.includes(customerName)) {
              this.businessNames.push(customerName);
            }
            this.getProfileData()
          }
        });
        if (this.headerRefreshCheck) {
          this.getPreferencesDetails();
        }
      });
    this.customerList = (this.commonservice.getroleDetails()).customerList;
    this.customerService.getLinkedCIDNs()
      .then((res) => {
        if (res && res.data && res.data.businessNameDetails && res.data.businessNameDetails.length > 0) {
          this.linkedCIDNs = res.data.businessNameDetails;
          this.checkRole();
        } else {
          // Handle the case where res, res.data, or businessNameDetails is not present
          //Populate businessName from myLinkedCustomerList
          if (this.myLinkedCustomerList) {
            this.myLinkedCustomerList.forEach((option) => {
              this.customerList.forEach((ele) => {
                if (ele.cidn === option.cidn) {
                  ele.businessName = option.customerName;
                }
              });
            });
          }
          else {
            const popUpData = JSON.parse(this.commonservice.getDefaultCustomerObject());
            this.myLinkedCustomerListFromCaiman = popUpData.allCustomerList;
            this.myLinkedCustomerListFromCaiman.forEach(option => {
              this.customerList.forEach((ele) => {
                if (ele.cidn === option.cidn) {
                  ele.businessName = option.customerName;
                }
              });
            });
          }
        }
      })
      .catch((error) => {
      });
  }

  matchCIDN = () => {
    //get all roles
    const getRolesFromCaiman = this.commonservice.getroleDetails().customerList;
    //get all customers
    this.customerService.getLinkedCIDNs().then((res) => {
      this.linkedCIDNs = res.data.businessNameDetails;
      //match customers condition
      this.linkedCIDNs.forEach((item) => {
        const isDuctAdmin = getRolesFromCaiman.some(a => a.role === 'Duct Administrator')
        getRolesFromCaiman.forEach((ele) => {
          if ((ele.role === 'Administrator' || ele.role === 'Read Only' || isDuctAdmin) && item.status === 'Active') {
            this.custNames.push(item.bussinessName);
            this.businessNames = [...new Set(this.custNames)];

          }
          if (ele.role == 'Duct Read & Write' && ele.function === 'Duct Access Form') {
            this.myLinkedCustomerList.forEach((option) => {
              this.businessNames.push(option.customerName);
            });

          }

        });

      });
      this.getSelectedCidnInitialization()

    })

      .catch((err) => { });

  };

  matchNBNCidn = () => {
    const getRolesFromCaiman = this.commonservice.getroleDetails().customerList;
    this.customerService.getLinkedCIDNs().then((res) => {
      this.linkedCIDNs = res.data.businessNameDetails;
      this.linkedCIDNs.map((item) => {
        getRolesFromCaiman.forEach((ele) => {
          if (ele.role === 'NBN Administrator' && item.status === 'Active' && this.customerDetails.isInfraCoCustomer) {
            this.custNames.push(item.bussinessName);
          }
        });
      });
      this.businessNames = [...new Set(this.custNames)];
      this.getSelectedCidnInitialization();
    }).catch((err) => { });
  }

  getPreferencesDetails = () => {
    const userName = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail']
    this.homepageservice.getPreferences(userName).then(response => {
      const hubPreference = response?.data?.hubPreferences;
      if(hubPreference){
        hubPreference.forEach(hub => {
          this.defaultCustomer.forEach(preference => {
            if (hub.preferenceType === preference.preferenceType &&
              hub.preferenceCategory === preference.preferenceCategory) {
              this.commonservice.setDefaultCustomer(hub.preference);
              this.primaryBusinessGroup.get('businessTypes')?.setValue(hub.preference);
            }
          });
        });
      }
    }).catch((err) => {
    });
  };

  getSelectedCidnInitialization = () => {
    this.cidn = this.commonservice.selectedCidn;
    if (this.commonservice.getDefaultCustomer() && this.linkedCIDNs && this.linkedCIDNs.length) {
      this.commonservice.getDefaultCustomer().pipe().subscribe(resp => {
        if (resp) {
          const findCIDNobject = this.searchCidn(this.linkedCIDNs, resp)
          this.cidn = findCIDNobject?.cidn;
          this.getUserProfileData();
        }
      });
    }
  }

  getProfileData() {
    this.cidn = localStorage.getItem('selectedHeaderCIDN');
  //  this.commonservice.selectedCidn;
    const data = this.primaryMail;
    this.userService.getUserData(data, this.cidn).then(res => {
      if (res && res.status === 200) {
        this.resData = res.data;
        this.contactNumber = this.resData.phoneNumber;
      }
    }).catch((err) => {
    });
  }

  async getUserProfileData() {
    const data = this.primaryMail;
    this.userService.getUserData(data, this.cidn).then(res => {
      if (res && res.status === 200) {
        this.resData = res.data;
        this.contactNumber = this.resData.phoneNumber;
      }
    }).catch((err) => {
    });
  }

  checkRole() {
    this.linkedCIDNs.forEach(item => {
      this.customerList.forEach(ele => {
        if (item.cidn === ele.cidn) {
          ele.businessName = item.bussinessName;
        }
      })
    })
  }

  create() { }
  contactForm = new FormGroup({
    contactName: new FormControl('', [Validators.required]),
    contactMobile: new FormControl('', [Validators.required]),
    contactEmail: new FormControl('', [Validators.required])
  });

  editEmailField() {
    this.showEmailEdit = true;
    this.EditContactName = true;
    this.isReadOnly = false;
  }
  editPrimaryNum() {
    this.showPrimaryCntEdit = true;
    this.EditContactNumber = true;
    this.isReadOnlyPm = false;
  }
  editSecondaryNum() {
    this.showSecondaryCntEdit = true;
    this.isReadOnlySm = false;
  }
  closeEditSecondaryNum() {
    this.showSecondaryCntEdit = false;
    this.isReadOnlySm = true;
  }
  closeEditPrimaryNum() {
    this.showPrimaryCntEdit = false;
    this.isReadOnlyPm = true;
  }
  closeEmailedit() {
    this.showEmailEdit = false;
    this.isReadOnly = true;
  }
  navigateToHome() {
    this.router.navigate(['/']);
  }
  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-top-center';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 5000;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
  }

  dropdownValueChanged(e) {
    const eventValue = e;
    this.checkVerticalforSave = eventValue;
    if (eventValue && this.cuurentSelect.trim() !== eventValue.trim()) {
      this.verticalSaveEnable = true;
    //  this.cidn = this.commonservice.selectedCidn;
      this.cidn = localStorage.getItem('selectedHeaderCIDN');
      const data = this.primaryMail;
      // this.existingRolesToModify.push({
      //   cidn: '9999999999',
      //   application: 'Infraco Hub',
      //   customername: 'Infraco',
      //   roleaccess: 'Administrator',
      //   rolefunctions: 'User Management'//.toString()
      // });
      this.userService.getUserData(data, this.cidn)
        .then(res => {
          if (res.status === 200) {
            if(!res.data) return;
            const resData = res.data;
            this.existingRolesToModify = [];
            res.data.roles?.forEach(element => {
              this.existingRolesToModify.push({
                cidn: element.cidn,
                application: this.buildRoleBasedOnLabelsSaved(element.appName),
                customername: element.customerName,
                roleaccess: this.buildRoleBasedOnLabelsSaved(element.role_name),
                rolefunctions: this.buildRoleBasedOnLabelsSaved(element.functionName) //.toString()
              });
            })
            const ResDataRoles = [];
            resData.roles?.forEach(each => {
              ResDataRoles.push({
                cidn: each.cidn,
                application: this.buildRoleBasedOnLabelsSaved(each.appName),
                customername: each.customerName,
                roleaccess: this.buildRoleBasedOnLabelsSaved(each.role_name),
                rolefunctions: this.buildRoleBasedOnLabelsSaved(each.functionName) //.toString()
              });
            })
            const response = {
              userName: resData.username,
              firstname: resData.firstName,
              lastname: resData.lastName,
              mobilenumber: resData.phoneNumber,
              status: resData.status ? resData.status : "Active",
              roleDetails: ResDataRoles
            }
            this.customerDetailsList = response?.roleDetails;
            this.status = response?.status;
            this.myUserId = res.data.id;
          }
        })
        .catch((err) => {
          if (err.status === 401) {
          }
        });
    }
    else {
      this.verticalSaveEnable = false;
    }

  }

  // Remove existing roles
  removeExistingRoles(existingRoles) {
    return new Promise((resolve, reject) => {
      let removeRole = [];

      existingRoles.forEach(val => {
        removeRole.push({
          role: val.application + '_' + val.roleaccess + '_' + val.rolefunctions?.toString(),
          cidn: val.cidn
        });
      });

      // Simulate a remove operation with a delay (replace this with your actual remove operation)
      setTimeout(() => {
        resolve(removeRole); // Resolve the promise with removed roles
      }, 2000);
    });
  }

  // Add new roles
  addNewRoles(newRoles) {
    return new Promise((resolve, reject) => {
      let addRole = [];

      newRoles.forEach(role => {
        addRole.push(role);
      });

      // Simulate an add operation with a delay (replace this with your actual add operation)
      setTimeout(() => {
        resolve(addRole); // Resolve the promise with added roles
      }, 2000);
    });
  }

  buildRoleBasedOnLabelsSaved(value) {
    if (value === 'infraco') {
      return 'InfraCo Customer Portal';
    }
    if (value === 'admin') {
      return 'Administrator';
    }
    if (value === 'readwrite') {
      return 'Read & Write';
    }
    if (value === 'readonly') {
      return 'Read Only';
    }
    if (value === 'customermgmt') {
      return 'Customer Management';
    }
    if (value === 'nbncabinet') {
      return 'NBN Cabinets';
    }
    if (value === 'nbnread') {
      return 'NBN Read Only';
    }
    if (value === 'nbnreadwrite') {
      return 'NBN Read & Write';
    }
    if (value === 'nbnadmin') {
      return 'NBN Administrator';
    }
    if (value === 'nbndocument') {
      return 'NBN DA Documents';
    }
    if (value === 'usermgmt') {
      return 'User Management';
    }
    if (value === 'nbnusermgmt') {
      return 'NBN User Management';
    }
    if (value === 'nbncabinetmgmt') {
      return 'NBN Cabinet Access Management';
    }
    if (value === 'ductadmin') {
      return 'Duct Administrator';
    }
    if (value === 'ductreadwrite') {
      return 'Duct Read & Write';
    }
    if (value === 'ductusermgmt') {
      return 'Duct User Management';
    }
    if (value === 'ductaccessform') {
      return 'Duct Access Form';
    }
    if (value === 'quotemgmt') {
      return 'Quote Request Management';
    }
    if (value === 'ordermgmt') {
      return 'Order Management';
    }
    if (value === 'servicemgmt') {
      return 'Service Management';
    }
    if (value === 'documentmgmt') {
      return 'Document Management';
    }
  }

  ValueChanged(e) {
    if (e) {
      this.enableSaveBus = true;
    }
  }

  saveProfile() {
    let errMsgs: string = 'Unable to Update ! Please try after sometime';
    if (this.verticalSaveEnable) {
      this.userService.toggleIsusperadim(true);
      this.ngxService.start();
      this.removeExistingRoles(this.existingRolesToModify)
        .then(removeRole => {
          // If removal is successful, add new roles after checking verticals
          if (this.checkVerticalforSave === 'Fibre') {
            this.newRolesToAdd = [
              {
                role: "Administrator",
                cidn: "9999999999",
                functionName: "User Management",
                customerName: "InfraCo",
                appName: "InfraCo Customer Portal",
                status: "Active"
              },
              {
                role: "Administrator",
                cidn: "9999999999",
                functionName: "Customer Management",
                customerName: "InfraCo",
                appName: "InfraCo Customer Portal",
                status: "Active"
              },
              {
                role: "Read Only",
                cidn: "9999999999",
                functionName: "Quote Request Management",
                customerName: "InfraCo",
                appName: "InfraCo Customer Portal",
                status: "Active"
              },
              {
                role: "Read Only",
                cidn: "9999999999",
                functionName: "Order Management",
                customerName: "InfraCo",
                appName: "InfraCo Customer Portal",
                status: "Active"
              },
              {
                role: "Read Only",
                cidn: "9999999999",
                functionName: "Service Management",
                customerName: "InfraCo",
                appName: "InfraCo Customer Portal",
                status: "Active"
              }

            ];
          }
          if (this.checkVerticalforSave === 'NBN Definitive Agreements') {
            this.newRolesToAdd = [
              {
                role: "NBN Administrator",
                cidn: "9999999999",
                functionName: "NBN Cabinets",
                customerName: "InfraCo",
                appName: "InfraCo Customer Portal",
                status: "Active"
              },
              {
                role: "NBN Administrator",
                cidn: "9999999999",
                functionName: "NBN Cabinet Access Management",
                customerName: "InfraCo",
                appName: "InfraCo Customer Portal",
                status: "Active"
              },
              {
                role: "NBN Administrator",
                cidn: "9999999999",
                functionName: "NBN User Management",
                customerName: "InfraCo",
                appName: "InfraCo Customer Portal",
                status: "Active"
              },
              {
                role: "NBN Administrator",
                cidn: "9999999999",
                functionName: "NBN DA Documents",
                customerName: "InfraCo",
                appName: "InfraCo Customer Portal",
                status: "Active"
              }
              // Add more new roles here as needed
            ];
          }
          if (this.checkVerticalforSave === 'Exchanges & Infrastructure') {
            this.newRolesToAdd = [
              {
                role: "Duct Administrator",
                cidn: "9999999999",
                functionName: "Duct User Management",
                customerName: "InfraCo",
                appName: "InfraCo Customer Portal",
                status: "Active"
              },
              {
                role: "Duct Read & Write",
                cidn: "9999999999",
                functionName: "Duct Access Form",
                customerName: "InfraCo",
                appName: "InfraCo Customer Portal",
                status: "Active"
              }

            ];
          }
          return this.addNewRoles(this.newRolesToAdd)
            .then(addRole => {
              // Combine the operations for the API call
              const operations = [
                {
                  op: 'remove',
                  removeRoles: removeRole
                },
                {
                  op: 'add',
                  roles: addRole
                }
              ];
              return this.userService.modifyUser(operations, this.myUserId)
                .then(response => {
                  this.triggerPageReload();
                });
            });
        })
        .catch(error => {
        });
    }
    else {
      const val = this.primaryBusinessGroup.get('businessTypes')?.value;
      this.commonservice.setDefaultCustomer(val);
      this.commonservice.setSavePrefSingleCust(true);
      this.commonservice.setProfileDefaultCustomer(val);
      this.profileService.modifyProfile(val, this.primaryMail).then((res) => {
        this.router.navigate(['/']);
        this.showToasterMessage('Updated Successfully', '', this.options, 'success');
      }).catch((err) => {
        if (err.error.status === 500 && err.error.message.toLowerCase() === errMsgs.toLowerCase()) {
          this.showToasterMessage('Something went wrong', '', this.options, 'error');
        } else {
          const msg = err.error.message ? err.error.message : 'Something went wrong';
          this.showToasterMessage(msg, '', this.options, 'error');
        }
      });
    }
  }

  triggerPageReload() {
    let errMsgs: string = 'Unable to Update ! Please try after sometime';
    const setCompany = 'InfraCo';
    this.commonservice.setDefaultCustomer(setCompany);
    this.commonservice.setProfileDefaultCustomer(setCompany);
    this.profileService.modifyProfile(setCompany, this.primaryMail).then((res) => {
      this.showToasterMessage('Updated Successfully', '', this.options, 'success');
      setTimeout(()=>{
        this.router.navigate(['/']).then(() => {
          // After navigation to the home page, trigger a page reload for authentication calls
          location.reload();
        });
      },5000);

    }).catch((err) => {
      if (err.error.status === 500 && err.error.message.toLowerCase() === errMsgs.toLowerCase()) {
        this.showToasterMessage('Something went wrong', '', this.options, 'error');
      } else {
        const msg = err.error.message ? err.error.message : 'Something went wrong';
        this.showToasterMessage(msg, '', this.options, 'error');
      }
    });
  }

  removeAccessibilityAttribute() {
    const elements = document.getElementsByClassName('mat-tab-body');
    for (let i = 0; i < elements.length; i++) {
      elements[i].removeAttribute('aria-labelledby');
    }
  }

  searchCidn(myArray, nameKey) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].bussinessName === nameKey) {
        return myArray[i];
      }
    }
  }
  ngOnDestroy() {
    this.ngxService.stop();
  }
}
