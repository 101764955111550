<div class="documentPage page-bg document-list-container" *ngIf="aemLabelAuthoring" id="qa-banner">
  <div class="row">
    <h1 class="heading-cls" id="cab" *ngIf="cabinetName">{{ cabinetName }}</h1>
    <div class="col-12 document-list-header">
      <nav aria-label="breadcrumb" class="col-8">
        <ol class="breadcrumb mt-3 mb-0">
          <li tabindex="0" class="breadcrumb-item mid" (keypress)="goToCabinetNav()" (click)="goToCabinetNav()">Cabinets</li>
          <i class="icon-icon-ui-right-arrow breadcrumb-icon"></i>
          <li class="breadcrumb-item active" aria-current="page" *ngIf="cabinetName">{{ cabinetName }}</li>
        </ol>
      </nav>
      <div class="btnWrap col-3" *ngIf="isInfracoAdmin || cabinetRole == 'nbnreadandwrite'">
        <button class="app-btn-green" (click)="navigateToUpload()">
          <img class="uploadIcon" src="../../../assets/images/upload-icon.svg" alt="upload icon"/>
          Upload new document
        </button>
      </div>
    </div>
  </div>
  <div class="col-12 mt-3 mb-3">
    <app-cui-search-box *ngIf="aemLabelAuthoring" [model]="searchModel" (searchEvent)="filterSearchInput($event)"
      (resetEvent)="resetGrid(null)" (keyup)="inputChange($event)">
    </app-cui-search-box>
  </div>
  <div *ngIf="gridModel">
    <div class="grid-container access">
      <ng-container *ngIf="gridConfig && gridInitialized">
        <app-cui-ag-grid [model]="gridModel" (gridOnReady)='gridReady($event)'
          (filtersOnChanged)='onFilterSelected($event)' (focusOnTop)="focusOnTop($event)">
        </app-cui-ag-grid>
      </ng-container>
    </div>
  </div>
</div>
<!-- Remove Modal -->
<div class="modal" role="dialog" aria-modal="true" aria-haspopup="dialog" *ngIf="aemLabelAuthoring"
  [ngClass]="{'profileModal':showModal}">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="container p-0">
        <div class="row m-0 p-0">
          <div class="p-0">
            <span (click)="closeModal(true)" class="close"  id="closePop" tabindex="0" aria-label="Close" role="button"><i
                class="icon-icon-ui-cross"></i></span>
          </div>
          <div class="closeBtn">
            <span class="modal-heading">Remove Document?</span>
          </div>
          <div>
            <p class="modal-confirmation-text">
              Are you sure you want to <b>remove</b> this document?
            </p>
            <div class="modal-btn-container">
              <button type="button" class="secondary-btn sm-btn app-cancel-btn" (click)="closeModal($event)"><i
                  class="icon-icon-ui-cross" value="Cancel"></i>Cancel
              </button>
              <button tabindex="0" id="rem" type="button" class="primary-btn sm-btn app-save-btn mr-3" (click)="closeModal($event)"
                value="Remove">Remove</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>