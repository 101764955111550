import { Component } from '@angular/core';

@Component({
  selector: 'app-cabinets-document-title-action-renderer',
  templateUrl: './cabinets-document-title-action-renderer.component.html',
  styleUrls: ['./cabinets-document-title-action-renderer.component.css']
})
export class CabinetsDocumentTitleActionRendererComponent {
  

  public fileTitle: string;
  public fileDesc: string;
  private params;

    agInit(params: any): void {
      this.fileTitle = params.value;
      this.fileDesc = params.data.documentType;
      this.params = params;
  }

  clickTitle(){
   this.params.clicked(this.params.data);
  }

}
