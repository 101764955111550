<div class="Container infraco-profile-container">
  <h1 class="headingClass" id="profileHeader" role="main" aria-label="My Profile Page">My profile</h1> <br><br>
   <!-- <div aria-live="assertive" class="visually-hidden">
    <span role="presentation" tabindex="0" style="position:absolute;left:-9999px">My Profile screen</  span>
  </div> -->
  <label class="subHeader">{{contactName}}</label>
  <div class="containerFields">
    <div class="email mt-3">
      <img class="emailImg" src="../../assets/images/icon-email.svg" alt="email icon"/> &nbsp;<label
        class="profileContent">{{primaryMail}}</label>
      <ng-template #bandwidthTipContent>
        <app-cui-tooltip-content [model]="bandwidthTooltipContentModel">
        </app-cui-tooltip-content>
      </ng-template>
    </div>
    <div class="pmMob mt-3" *ngIf="contactNumber">
      <img class="mobileImg" src="../../assets/images/icon-mobile.svg" alt="mobile icon"/> &nbsp;<label
        class="profileContent">{{contactNumber}}</label>
    </div>
  </div>
  <div class="tabStart">
    <mat-tab-group [disableRipple]="true" [(selectedIndex)]=selectedIndex>
      <mat-tab label="General information" class="tab conatctDetails tabCon">
        <div style="padding-top: 20px;"></div>
        <div class="tab1" tabindex="0" role="table" aria-label="User role function table">
          <div class="row" role="row">
            <div class="col-3" role="columnheader">
              <label class="my-profile-label" style="font-weight:bold">Customer name</label>
            </div>
            <div class="col-3" role="columnheader">
              <label class="my-profile-label" style="font-weight:bold">Application</label>
            </div>
            <div class="col-3" role="columnheader">
              <label class="my-profile-label" style="font-weight:bold">Role access</label>
            </div>
            <div class="col-3" role="columnheader">
              <label class="my-profile-label" style="font-weight:bold">Role function</label>
            </div>
          </div>
          <div class="row" *ngFor="let ele of customerList; let rowIndex=index">
            <div *ngIf="ele.businessName" role="row" [ngClass]="rowIndex%2===0 ? 'even-row':'odd-row'">
              <label class="conatctDetails col-3" role="cell">{{ele.businessName}}</label>
              <label class="conatctDetails col-3" role="cell">{{ele.app}}</label>
              <label class="conatctDetails col-3" role="cell">{{ele.role}}</label>
              <label class="conatctDetails col-3" role="cell">{{ele.function}}</label>
            </div>
          </div>
          <div *ngIf="!customerList">
            <div class="row mt-3" *ngFor="let ele of customerList">
              <label class="conatctDetails col-3">INFRACO</label>
              <label class="conatctDetails col-3">Infraco hub</label>
              <label class="conatctDetails col-3">Administrator</label>
              <label class="conatctDetails col-3">Customer Management</label>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="InfraCo Customer Portal Preference" class="conatctDetails tabCon" *ngIf="!isNBNuser">
        <form [formGroup]="primaryBusinessGroup" id="primaryBusinessForm" #primaryBusinessForm="ngForm">
          <div class="row col-md-12 hubPreferences">
            <div class="col-md-2">
              <label class="profileLab">Default Customer</label>
            </div>
            <div class="col-xs-12 col-sm-7 col-md-6 col-lg-4">
              <app-cui-searchable-dropdown name="businessTypes" [formControlName]="'businessTypes'"
                [model]="businessNameDropDownModel" [labelName]="'businessDropdown'"
                [placeholder]="'Select Default Customer'" [isRequired]=true [items]=businessNames
                automationType="dropdown" automationId="drpdwnbusinessType" [noAutoComplete]=false id="businessTypes"
                class="user-dropDown" (ngModelChange)="ValueChanged($event)">
              </app-cui-searchable-dropdown>
            </div>
          </div>
          <div class="btn col-md-4 profileBtn">
            <button class="cancelBtn" aria-label="cancel" (click)="navigateToHome()">X Cancel</button>
            <button  [ngClass]="{'saveBtn': enableSaveBus, 'disSave': !enableSaveBus}" (click)="saveProfile()" [disabled]="!enableSaveBus">Save</button>
          </div>
        </form>
      </mat-tab>
        <mat-tab label="Super admin preference" class="conatctDetails tabCon" *ngIf="showAdmintab">
        <form [formGroup]="primaryBusinessGroup" id="primaryBusinessForm" #primaryBusinessForm="ngForm">
          <div class="row col-md-12 hubPreferences">
            <div class="col-md-2">
              <label class="profileLab">Default super admin</label>
            </div>
            <div class="col-xs-12 col-sm-7 col-md-6 col-lg-4">
              <app-cui-searchable-dropdown name="verticalTypes" [formControlName]="'verticalTypes'"
                [model]="verticalNameDropDownModel" [labelName]="'businessDropdown'" (ngModelChange)="dropdownValueChanged($event)"
                [placeholder]="cuurentSelect" [isRequired]=true [items]=verticalsNames
                automationType="dropdown" automationId="drpdwnbusinessType" [noAutoComplete]=false id="verticalTypes"
                class="user-dropDown">
              </app-cui-searchable-dropdown>
            </div>
          </div>
          <div class="col-12 notify">
            <span class="col-8" class="styleAdmin">
            <img alt="error" src="../../assets/images/Icon-info.svg">
            <label class="labelAlign">Please note, changing the <b>Super admin preference</b> will automatically set the <b>Default customer preference</b> to “InfraCo”.</label>
          </span>
          </div>
          <div class="btn col-md-4 profileBtn">
            <button class="cancelBtn" aria-label="cancel" (click)="navigateToHome()">X Cancel</button>
            <button [disabled]="!verticalSaveEnable"  [ngClass]="{'saveBtn': verticalSaveEnable, 'disSave': !verticalSaveEnable}" (click)="saveProfile()">Save</button>
          </div>
        </form>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
