import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { forkJoin } from 'rxjs';
import { ToastrService, GlobalConfig, ToastContainerDirective } from 'ngx-toastr';
import { CustomerService } from './customer.service';
import { CheckboxFilterComponent } from '../../grid-filter/checkbox-filter/checkbox-filter.component';
import { CustomTextFilterComponent } from '../../grid-filter/custom-text-filter/custom-text-filter.component';
import { CustomDateFilterComponent } from '../../grid-filter/custom-date-filter/custom-date-filter.component';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { Title } from '@angular/platform-browser';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.less']
})
export class CustomerListComponent implements OnInit, AfterViewInit {
  @Input() selectedIndex: any;
  public currentlyCheckedCount = 0;

  public colDefsAthleteIncluded = [
    {
      field: 'customerName',
      colId: "a",
      tooltipField: "customerName",
      filter: "customTextFilter",
      headerName: "Customer name",
      fullHeaderName: "Customer name",
      minWidth: 250
    },
    {
      field: 'customerType',
      colId: "b",
      tooltipField: "customerType",
      filter: "CheckboxFilterComponent",
      headerName: "Customer type",
      fullHeaderName: "Customer type",
      minWidth: 150,
      customFilter: [
        {
          "elementData": "Agent"
        },
        {
          "elementData": "Direct"
        },
        {
          "elementData": "Reseller"
        }
      ]
    },
    {
      field: 'customerChannel',
      colId: "c",
      tooltipField: "customerChannel",
      filter: "CheckboxFilterComponent",
      headerName: "Customer channel",
      fullHeaderName: "Customer channel",
      minWidth: 140,
      customFilter: [
        {
          "elementData": "Telstra International"
        },
        {
          "elementData": "Telstra Limited"
        },
        {
          "elementData": "Telstra Wholesale"
        },
        {
          "elementData": "Telstra Enterprise"
        },
        {
          "elementData": "Amplitel"
        },
        {
          "elementData": "External Direct"
        }
      ]
    },
    {
      field: 'primaryCidn',
      colId: "d",
      tooltipField: "primaryCidn",
      filter: "customTextFilter",
      headerName: "Customer identifier (e.g., CIDN)",
      fullHeaderName: "Customer identifier (e.g., CIDN)",
      minWidth: 200
    },
    {
      field: 'status',
      colId: "e",
      tooltipField: "status",
      filter: "CheckboxFilterComponent", headerName: "Status",
      fullHeaderName: "Status",
      minWidth: 100,
      customFilter: [
        {
          "elementData": "Active"
        },
        {
          "elementData": "Inactive"
        }
      ]
    },
    {
      field: 'contactName',
      colId: "f",
      tooltipField: "contactName",
      filter: "customTextFilter",
      headerName: "Contact name",
      fullHeaderName: "Contact name",
      minWidth: 170
    },
    {
      field: 'contactNumber',
      colId: "g",
      tooltipField: "contactNumber",
      filter: "customTextFilter",
      headerName: "Contact number",
      fullHeaderName: "Contact number",
      minWidth: 170
    }

  ];
  showFilterPop: boolean = false;
  checkedList: any[];
  functionList: any =
    [
      {
        name: 'Customer name', value: 'customerName', checked: false, colId: "a",
        tooltipField: "customerName", filter: "customTextFilter", headerName: "Customer name",
        fullHeaderName: "Customer name"
      },
      {
        name: 'Customer type', value: 'customerType', checked: false, colId: "b",
        tooltipField: "customerType",
        filter: "CheckboxFilterComponent",
        headerName: "Customer type",
        fullHeaderName: "Customer type",
        customFilter: [
          {
            "elementData": "Agent"
          },
          {
            "elementData": "Direct"
          },
          {
            "elementData": "Reseller"
          }
        ]
      },
      {
        name: 'Customer channel',
        value: 'customerChannel',
        checked: false,
        colId: "c",
        tooltipField: "customerChannel",
        filter: "CheckboxFilterComponent",
        headerName: "Customer channel",
        fullHeaderName: "Customer channel",
        customFilter: [
          {
            "elementData": "Telstra International"
          },
          {
            "elementData": "Telstra Limited"
          },
          {
            "elementData": "Telstra Wholesale"
          },
          {
            "elementData": "Telstra Enterprise"
          },
          {
            "elementData": "Amplitel"
          },
          {
            "elementData": "External Direct"
          }

        ]
      },
      {
        name: 'Customer identifier (e.g., CIDN)', value: 'primaryCidn', checked: false, colId: "d",
        tooltipField: "primaryCidn",
        filter: "customTextFilter", headerName: "Customer identifier (e.g., CIDN)",
        fullHeaderName: "Customer identifier (e.g., CIDN)"
      },
      {
        name: 'Status', value: 'status', checked: false, colId: "e",
        tooltipField: "status",
        filter: "CheckboxFilterComponent", headerName: "Status",
        fullHeaderName: "Status",
        customFilter: [
          {
            "elementData": "Active"
          },
          {
            "elementData": "Inactive"
          }
        ]
      },
      {
        name: 'Contact name', value: 'contactName', checked: false, colId: "f",
        tooltipField: "contactName",
        filter: "customTextFilter", headerName: "Contact name",
        fullHeaderName: "Contact name"
      },
      {
        name: 'Contact number', value: 'contactNumber', checked: false, colId: "g",
        tooltipField: "contactNumber",
        filter: "customTextFilter", headerName: "Contact number",
        fullHeaderName: "Contact number"
      },
      {
        name: 'Contact email', value: 'contactEmail', checked: false, colId: "h",
        tooltipField: "contactEmail",
        filter: "customTextFilter", headerName: "Contact email",
        fullHeaderName: "Contact email",
      },
      {
        name: 'Created date', value: 'createdDate', checked: false, colId: "i",
        tooltipField: "createdDate",
        filter: "customDateFilter", headerName: "Created date",
        fullHeaderName: "Created date",
      },
      {
        name: 'Modified date', value: 'modifiedDate', checked: false, colId: "j",
        tooltipField: "modifieddate",
        filter: "customDateFilter", headerName: "Modified date",
        fullHeaderName: "Modified date",
        sortable: true,
        sort: 'desc',
      }

    ];
  public searchvalue: any;
  public gridConfig: GridOptions;
  public rowData: any;
  public columnDefs: any;
  public gridInitialized: Boolean;
  public aemLabelAuthoring: any;
  subscription: any;
  subscribedflag: Boolean = false;
  public options: GlobalConfig;
  public gridModel: any = {
    gridOptions: {},
    keysToSuppress: [13],
    labelAuthoring: {
      headerPageCount: '',
      filter: '',
      resetFilter: '',
      paginationHeader: '',
      noData: '',
      noDataOnFilter: '',
      noDataOnSearch: '',
      toTop: ''
    }
  };
  public filteredRowData: any = [];
  @Output() createCustomerEvent = new EventEmitter();
  @Output() modifyCustomerEvent = new EventEmitter();
  colDefsAthleteEXcluded: {
  }[];

  colDefsAthleteEXcluded1: {}[];
  sample: any;
  stopSelect: boolean = false;
  abc: any;
  item1: any;
  first: {};
  currentSelected: { field: String; colId: string; tooltipField: string; filter: string; headerName: string; fullHeaderName: string; customFilter: any, width: any, minWidth: any, maxWidth: any };
  focusableElementone: HTMLElement;
  columnHeaderId = [];
  columnsPresent: boolean = true;

  constructor(private dataSvc: CustomerService, private router: Router, private toastr: ToastrService, 
    private commonService: CommonService, private titleService: Title, private utilityService: UtilityService) {
    this.createGrid(null);
    this.checkedList = [];
    this.titleService.setTitle('InfraCo Customer Portal - Admin Settings');
  }

  focusOnTop(event) {
    if (event) {
      let focusableElement;
      focusableElement = document.getElementById('customerList');
      if (focusableElement) {
        focusableElement.setAttribute('tabindex', '0');
        focusableElement.addEventListener('blur', () => {
          focusableElement.removeAttribute('tabindex');
        });
        setTimeout(() => {
          if (focusableElement) {
            focusableElement.focus();
          }
        }, 10);
      }
    }
  }

  toggleCheckbox(a: any) {
    a.checked = !a.checked;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
     this.commonService.showTopButtonInFooter(true);
    }, 1000)
   }

  ngOnInit(): void {
    this.showFilterPop = false;
    this.getLabels();
  }
  ngOnChanges() {
    this.showFilterPop = this.selectedIndex === 1 ? false : this.showFilterPop;
  }
  ngOnDestroy() {
    this.commonService.showTopButtonInFooter(false);
  }

  getLabels() {
    return new Promise((resolve, _reject) => {
      this.dataSvc.getCustomerListModel().then(() => {
        this.aemLabelAuthoring = this.dataSvc.gridLabelAuthoringDataObj;
        this.gridModel.labelAuthoring = this.aemLabelAuthoring.form.grid;
        this.successMsg();
        resolve(true);
      }).catch(() => {
      });
    });
  }

  closePopup() {
    this.showFilterPop = false;
  }

  shareIndividualCheckedList(item: {}) {
  }

  createGrid(payload: any) {
    if (this.subscribedflag) {
      this.subscription.unsubscribe();
    } else {
      this.subscribedflag = true;
    }
    const promise1 = this.createColumnDefs();
    const promise2 = this.createRowData(payload);
    this.subscription = forkJoin([promise1, promise2]).subscribe(_result => {
      this.createGridConfig();
    }, _error => {
      this.createGridConfig();
      this.router.navigateByUrl('/error');
    });
  }

  private createColumnDefs() {
    return new Promise((resolve, reject) => {
      this.dataSvc.getGridHeader().then(() => {
        this.columnDefs = this.colDefsAthleteIncluded;
        this.columnDefs.forEach((x: any) => {
          this.columnHeaderId.push(x.field);
        });
        this.functionList.map(f => {
          if (this.columnHeaderId.includes(f.value)) {
            f.checked = true;
          }
        });
        this.checkedList = this.deepCopy(this.colDefsAthleteIncluded);
        resolve(true);
      }).catch((err) => {
        reject(err);
      });
    });
  }
  deepCopy(element) {
    return JSON.parse(JSON.stringify(element));
  }
  leaveColPref() {
    document.getElementById('colPrefTab').style.display = 'none';
  }
  disableRemainingCheckboxes() {
    Array.from(document.querySelectorAll('input[type="checkbox"]:not(:checked)')).forEach(ele => {
      ele.setAttribute('disabled', 'true');
    });
  }

  enableAllCheckboxes() {
    Array.from(document.querySelectorAll('input[type="checkbox"]:not(:checked)')).forEach(ele => {
      ele.removeAttribute('disabled');
    })
  }

  getSelectedValue(status: Boolean, value: String, colId: string, tooltipField: string, filter: string, headerName: string, fullHeaderName: string, customFilter: any) {
    if (status) {
      this.currentSelected = { field: value, colId: colId, tooltipField: tooltipField, filter: filter, headerName: headerName, fullHeaderName: fullHeaderName, customFilter: customFilter, width: 200, minWidth: 150, maxWidth: 200 };
      this.checkedList.push(this.currentSelected);
    } else {
      const index = this.checkedList.findIndex(obj => obj.field === value); // Find the index of the object with id=2
      if (index !== -1) {
        this.checkedList.splice(index, 1); // Remove the object at the found index
      }
    }
    this.checkedList.sort((a, b) => a.colId.localeCompare(b.colId))
    this.checkedList.map(c => {
    if (c.headerName === 'Created date') {
      c.sortable = true;
      c.comparator = (date1: string, date2: string) => this.utilityService.customDateSorter(date1, date2),
        c.getQuickFilterText = function (params) {
          return '';
        }
    }

    if (c.headerName === 'Modified date') {
      c.sortable = true;
      c.sort = 'desc';
      c.comparator = (date1: string, date2: string) => this.utilityService.customDateSorter(date1, date2),
        c.getQuickFilterText = function (params) {
          return '';
        }
    }
  });
    if (this.checkedList.length === 0) {
      this.columnsPresent = false;
    } else {
      this.columnsPresent = true;
    }
    setTimeout(() => {
      if (this.gridModel.gridOptions.api) {
        this.gridModel.gridOptions.api.setColumnDefs([]);
        this.gridModel.gridOptions.api.setColumnDefs(this.checkedList);
        this.gridModel.gridOptions.api.sizeColumnsToFit();
      }
      //   this.createGrid(null);

      setTimeout(() => {
        this.gridModel.gridOptions.api.setQuickFilter('');
        this.gridModel.gridOptions.api.setQuickFilter(this.searchvalue);
      }, 0);
    }, 500);
    //  this.shareCheckedlist();
  }
  showPop() {
    this.showFilterPop = true;
    setTimeout(() => {
      document.getElementById('colPrefTab').style.display = 'block';
      this.focusableElementone = document.getElementById('close');
      this.focusableElementone.focus();
    }, 1);
    this.commonService.trapFocusInModal('close', 'remove');
  }

  private createRowData(params?: any) {
    const param = params || null;
    return new Promise((resolve, reject) => {
      this.dataSvc.getGridData(param).then(() => {
        this.getRowData(this.dataSvc.gridDataObj);
        resolve(true);
      }).catch((err) => {
        this.rowData = [];
        reject(err);
      });
    });
  }


  getRowData(data) {
    this.rowData = data;
    const DATA_ROW = [];
    for (let i = 0; i < this.rowData.length; i++) {
      const SELECT_ROW = this.rowData[i];
      if (SELECT_ROW.roles) {
        const USER_ROLE = SELECT_ROW.roles;
        SELECT_ROW.roles = [];
        for (let j = 0; j < USER_ROLE.length; j++) {
          const TEMP = JSON.parse(JSON.stringify(SELECT_ROW));
          TEMP.roles = USER_ROLE[j].value;
          TEMP.businessName = USER_ROLE[j].businessName;
          if (TEMP.businessName) {
            DATA_ROW.push(TEMP);
          }
        }
      }
    }
    this.rowData = DATA_ROW.length > 0 ? DATA_ROW : this.rowData;
  }

  private createGridConfig() {
    this.gridConfig = <GridOptions>{};
    this.gridConfig.rowData = this.rowData;
    this.gridConfig.paginationPageSize = 10;
    this.gridConfig.columnDefs = this.columnDefs;
    this.gridConfig.suppressHorizontalScroll = true;
    this.gridConfig.pagination = true;
    this.gridConfig.rowSelection = 'single';
    this.gridInitialized = true;
    this.gridConfig.frameworkComponents = {
      CheckboxFilterComponent: <any>CheckboxFilterComponent,
      customTextFilter: <any>CustomTextFilterComponent,
      customDateFilter: <any>CustomDateFilterComponent
    };
    this.gridModel.gridOptions = this.gridConfig;
  }

  onSelectionChanged(arg: any) {
    const selectedRows = arg.gridApi.getSelectedRows();
    this.navigateToModify(selectedRows[0]);
  }

  navigateToCreateUser() {
    this.createCustomerEvent.emit('true');
  }

  onFilterSelected(filteredData: any) {
    if (filteredData.filteredRowData && filteredData.filteredRowData.length > 0) {
      filteredData.filteredRowData.forEach(row => {
        if (row.data) {
          this.filteredRowData.push(row.data);
        }
      });
    }
  }
  quickSearch() {
    this.gridModel.gridOptions.api.setQuickFilter(this.searchvalue);
  }
  clearsearch() {
    (<HTMLInputElement>document.getElementById("text")).value = '';
    this.gridModel.gridOptions.api.setQuickFilter('');
    this.searchvalue = '';
  }

  navigateToModify(data) {
    this.dataSvc.getCustomerData(data).then(res => {
      this.dataSvc.setCreateCustomerData(data);
      this.modifyCustomerEvent.emit('true');
    }).catch((err) => {
    });
  }

  successMsg() {
    if (this.dataSvc.createCustomerFormData) {
      let successMsg: string;
      if (this.dataSvc.createCustomerFormData.page === 'Create') {
        successMsg = 'You have successfully created a new customer.';
      } else if (this.dataSvc.createCustomerFormData.page === 'Modify') {
        successMsg = 'You have successfully modified existing customer.';
      }
      this.showToasterMessage(successMsg, '', this.options, 'success');
      this.dataSvc.createCustomerFormData = '';
    }
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.options.positionClass = 'toast-top-center';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 3000;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    const event: Event = window.event;
    document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
  }
 
  @HostListener('window:keydown', ['$event'])
  handleKeyUp(event) {
    if((event.keyCode === 32 || event.key === " " ) && event.target.nodeName !== 'INPUT') {
      event.preventDefault();
    }
    if (event.keyCode === 27) {
      this.closePopup();
    }
    if (event.keyCode === 13 && event.target.classList.contains('closePop') && this.showFilterPop) {
      this.closePopup();
    }
  }

}

