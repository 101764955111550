import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
// import { AuthService } from 'tw-core-ui';
import { Router } from '@angular/router';
import { AuthService } from 'tw-core-ui';
import { userManagerObj } from 'src/environments/environment';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) { }
  private userObj: any = userManagerObj;
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // FOR SERVER 
    const auth = 'Bearer ' + JSON.parse(localStorage.getItem('oidc.user:' + this.userObj.authority + ':' + this.userObj.client_id))?.access_token
    // FOR LOCAL 
    // const auth = this.authService.getAuthorizationHeaderValue()
    // ? this.authService.getAuthorizationHeaderValue()
    // : 'Bearer uyke6jFQJ9eZYxGhEZwSOXcgZGwS'

    if (!localStorage.getItem('oidc.user:' + this.userObj.authority + ':' + this.userObj.client_id)) {
      window.location.href = this.userObj.authority + '/idp/startSLO.ping?TargetResource=' + this.userObj.post_logout_redirect_uri;
    } else if (request.url !== 'https://maps.googleapis.com/maps/api/geocode/json' && !(request.body instanceof FormData)) {
      const CUSTOM_REQUEST = request.clone({
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: auth
        }),
      });

      return next.handle(CUSTOM_REQUEST).pipe(
        tap(
          (event: HttpEvent<any>) => { },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              this.errorHandler(err.error.path, err.status);
            }
          }
        )
      );

    } else if (request.body instanceof FormData) {
      const CUSTOM_REQUEST = request.clone({
        headers: new HttpHeaders({
          Accept: '*/*',
          Authorization: auth,
        }),
      });
      return next.handle(CUSTOM_REQUEST).pipe(
        tap(
          (event: HttpEvent<any>) => { },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              this.errorHandler(err.error.path, err.status);
            }
          }
        )
      );
    } else {
      return next.handle(request);
    }
  }

  errorHandler(path, statusCode) {
    // Error logic here\
  }
}
