<div class="container-fluid bulk-request-container">
  <div class="toastrContainer" aria-live="polite" role="alert" toastContainer></div>

  <main aria-labelledby="bulkQuoteUploadHeader">
    <div class="row">
      <div class="col-4 page-header-container">
        <label class="alert-section" aria-live="assertive" role="alert">Bulk Upload and Submit page</label>

        <h1 class="bulk-request-label" id="bulkQuoteUploadHeader"
          [attr.aria-label]="isModifyBulkQuote? aemLabelAuthoring?.pageHeader.modifyQuotePageHeader:  aemLabelAuthoring?.pageHeader.pageHeaderLabel">
          {{isModifyBulkQuote? aemLabelAuthoring?.pageHeader.modifyQuotePageHeader:
          aemLabelAuthoring?.pageHeader.pageHeaderLabel}}</h1>
        <div class="bulk-request-ref-id"><span aria-label="Bulk quote reference"
            class="color-grey-41 font-16">{{aemLabelAuthoring?.pageHeader.pageSubHeaderLabel}}</span> <b
            class="font-16 color-black"> {{bulkQuoteID}}</b>
        </div>
      </div>
      <div class="col-4">
        <app-page-stepper [stepperData]="stepperData" [stepName]="stepName"></app-page-stepper>
      </div>
    </div>
  </main>

  <div class="d-flex w-100 mt-40 align-items-center  justify-content-between align-items-center">
    <div class="d-flex align-items-center gap-3">
      <div><img src="../../../../assets/images/Upload-h2-icon.svg" alt=""></div>
      <div class="font-24 font-bold color-black">{{aemLabelAuthoring?.quoteUploadDetails}}</div>
    </div>
    <div class="d-flex">

      <button class="d-flex align-items-center gap-3 download-tem-button" tabindex="0" type="button" (keypress)="downloadTemplate()"  (click)="downloadTemplate()">
        <div><img src="../../../../assets/images/Download-template-icon.svg" alt="" width="22px" class="img-height"></div>
        <span class="font-18">{{aemLabelAuthoring?.downloadTemplate}}</span>
      </button>
    </div>
  </div>

  <hr />

  <div class="upload-document">
    <div class="uploadfilecontainer" appFileUpload [allowSingle]="true" (drop)="onFileDrop($event)">
      <input hidden type="file" #fileInput (change)="onFileChange($event)" (click)="fileInput.value=null" id="file">
      <div class="center-content">
        <img id="upload_to_cloud" src="../../../../../../assets/images/upload_to_cloud.png" alt="upload_to_cloud">
        <br />
        <h3 class="drag">{{aemLabelAuthoring?.dragAndDropFileFor}}</h3>
        <p class="instruction">{{aemLabelAuthoring?.csv}}</p>
        <button tabindex="0" aria-label="file attachment browse" role="button"
          class="btn secondary-btn browse-btn" (click)="fileInput.click()">{{aemLabelAuthoring?.button.browse}}</button>
        <p class="mb-0 mt-2 info">{{aemLabelAuthoring?.maxUploadSize}}</p>
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col-8" *ngIf="!isFileUploaded">
          <div class="files-list mb-2 max-w-100" [ngClass]="error ? 'files-list-error' : 'files-list-success'"
            *ngIf="file">
            <!--  -->
            <p class="mb-0 overflow-hidden word-break">
              {{file?.name}}
            </p>
            <!-- *ngIf="!error && uploadProgress" -->
            <ng-conatiner *ngIf="!error && uploadProgress" >
              <progress id="file" [value]="uploadProgress" [max]="100"> </progress>
            </ng-conatiner>

            <button aria-label="delete attachment" role="button" class="delete-file" (click)="deleteAttachment()">
              <i class="icon-icon-ui-cross icon-sm"
                [ngClass]="error ? 'file-cross-btn-error' : 'file-cross-btn-success'"></i>
            </button>
          </div>
          <small *ngIf="error" class="text-danger errorMsg d-block" role="alert" aria-describedby="error" [attr.aria-label]="error">
            <img class="error-icon" alt="error icon" src="../../../../assets/images/icon-error.svg" />
             <span>{{error}}</span> 
          </small>

        </div>

        <div class="col-8" *ngIf="isFileUploaded">
          <small class="uploaded-file-block d-block">
            <img class="file-icon" alt="file icon" src="../../../../assets/images/Icon-document.svg" />
            <span>
              {{file?.name}}
            </span>
          </small>


          <small class="uploaded-file-block d-block" *ngIf="errorCsv">
            <img class="file-icon" alt="file icon" src="../../../../assets/images/File-CSV-icon.svg" />
            <span class="text-danger">
              Error.csv
              <button class="download-btn" tabindex="0" aria-label="Download" type="button"
                (click)="downloadErrorCsv()">
                <img class="downloadIcon" src="../../../../assets/images/icon-download.svg" alt="Download icon" />
                {{aemLabelAuthoring?.button?.download}}
              </button>
            </span>
          </small>

        </div>
        <div class="col-4">
          <button mat-button class="app-btn-green save-cancel-btn pull-right" (click)="uploadFile()"
            [disabled]="!file || error || (uploadProgress > 0)">
            {{aemLabelAuthoring?.button.upload}}
          </button>
        </div>
      </div>
    </div>
  </div>

  <hr *ngIf="isFileUploaded" />

  <app-bulk-quote-list *ngIf="isFileUploaded && quotesData.quotes" [quotesData]="quotesData"></app-bulk-quote-list>

  <div class="row ms-5">
    <div class="col-12 ms-2">
      <div class="submitCancelBtnReview">

        <button mat-button role="button" (click)="cancel()" class="cancelBtn me-2">
          <i class="icon-icon-ui-cross"></i> {{aemLabelAuthoring?.button?.cancel}}</button>
        <button role="button" class="app-btn-green me-2" [disabled]="isSubmitDisabled"
          (click)="openTermsAndCondition()">
          {{aemLabelAuthoring?.button?.submit}}
        </button>

      </div>
    </div>
  </div>
</div>

<app-terms-conditions-modal [isBulkQuote]="true" [bulkQuotes]="quotesData" [bulkQuoteId]="bulkQuoteID"
  [tAndcText]="tAndcText" *ngIf="isTermsCondition" (onClickClose)="onClickClose($event)">
</app-terms-conditions-modal>
