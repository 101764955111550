import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'tw-core-ui';
import { GridHeaderInterface } from '../cabinets/dashboard.service';
import { BehaviorSubject, throwError as observableThrowError } from 'rxjs';
import { BtnCellRendererComponent } from './btn-cell-renderer/btn-cell-renderer.component';

@Injectable({
  providedIn: 'root'
})
export class WhitelistService {
  public infraCoGridData: any = [];
  private env: any = window['environment'];
  spinnerflag = false;
  private sendSpinnerFlag = new BehaviorSubject(this.spinnerflag);
  spinnerCurrentStatus = this.sendSpinnerFlag.asObservable();

  private domainURI = {
    appList: 'users/apps',
    domain: 'users/domains',
    domainGridHeader: 'whitelist-grid-header.json',
    deleteDomain: 'admin/domains'
  };

  constructor(private http: HttpClient, private authService: AuthService) { }

  public getAppList() {
    // this.sendSpinnerFlag.next(true);
    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    return new Promise((resolve, reject) => {
      const getAppListUrl = this.env.apiPoint + this.domainURI.appList;
      this.http.get<any>(getAppListUrl, httpOptions)
        .subscribe(
          res => {
            this.sendSpinnerFlag.next(false);
            resolve(res);
          },
          err => {
            this.sendSpinnerFlag.next(false);
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }

  public getGridHeader() {
    const map = {
      BtnCellRendererComponent: BtnCellRendererComponent
    }
    return new Promise((resolve, reject) => {
      const domainHeaderUrl = this.env.aemEndPoint + this.domainURI.domainGridHeader;
      this.http.get<GridHeaderInterface>(domainHeaderUrl)
        .subscribe(res => {
          res['domainHeaders'].forEach((x: any) => {
            if (x.cellRendererFramework) {
              x.cellRendererFramework = map[x.cellRendererFramework];
            }
          });
          resolve(res);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getDomainList(appId) {
    // this.sendSpinnerFlag.next(true);
    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    return new Promise<any>((resolve, reject) => {
      const getDomainListUrl = this.env.apiPoint + this.domainURI.domain + `?appId=${appId}`;
      this.http.get<any>(getDomainListUrl, httpOptions)
        .subscribe(res => {
          // this.sendSpinnerFlag.next(false);
          resolve(res);
        }, err => {
          // this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public createDomain(body) {
    // this.sendSpinnerFlag.next(true);
    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    let payload = {
      'appId': body.appId,
      'domain': body.domain
    }
    return new Promise<any>((resolve, reject) => {
      const createDomainUrl = this.env.apiPoint + this.domainURI.domain;
      this.http.post<any>(createDomainUrl, payload, httpOptions)
        .subscribe(res => {
          this.sendSpinnerFlag.next(false);
          resolve(res);
        }, err => {
          this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }


  public removeDomain(domainId) {
    this.sendSpinnerFlag.next(true);
    let httpOptions;
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : ''
      })
    };
    return new Promise<any>((resolve, reject) => {
      const deleteDomainUrl = this.env.apiPoint + this.domainURI.domain + `/${domainId}`;
      this.http.delete<any>(deleteDomainUrl, httpOptions)
        .subscribe(res => {
          this.sendSpinnerFlag.next(false);
          resolve(res);
        }, err => {
          this.sendSpinnerFlag.next(false);
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

}
