import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService, GlobalConfig, ToastContainerDirective } from 'ngx-toastr';
import { CustomDropDown } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { UsersService } from '../admin-settings/user/user-list/users.service';
import { HomePageService } from '../shared/services/homePage.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-manage-cabinet-access',
  templateUrl: './manage-cabinet-access.component.html',
  styleUrls: ['./manage-cabinet-access.component.css']
})

export class ManageCabinetsAccessComponent implements OnInit {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  isAddCabinetAccess: boolean;
  public aemLabelAuthoring: [];
  public createAccessForm: FormGroup;
  showRmv:boolean=false;
  public statusModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'cabinet id'
  };
  public roleModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'role functions'
  };
  public showCabinetRole: boolean = false;
  public accessItemsList = [];
  public tableCol: Array<string> = [];
  public rowData = [];
  public selectedCabinetsData = [];
  public originalCabinetsData = [];
  removeCabinetsId: string;
  options: GlobalConfig;
  userName: string;
  infracoAdmin: boolean = false;
  isInfracoUser: boolean;
  userDetails: any;
  allCabinetsData = [];
  isFormSubmitted: boolean;
  public roleItemsList = [];
  roleList = [{name: 'NBN Read Only', key: 'nbnreadonly'}, {name: 'NBN Read & Write', key: 'nbnreadandwrite'}];
  cabinetDetailsList = [];
  isCabinetsChange: boolean;
  customerDetails;
  roles;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private userService: UsersService,
    private toastr: ToastrService,
    private homepageservice: HomePageService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.getuserDetails();
    this.setRoleList();
    this.roles = this.commonService.getroleDetails().customerList;
    this.customerDetails = this.homepageservice.getCustomerDetails();
    this.isCabinetsChange = false;
    this.options = this.toastr.toastrConfig;
    const roles = this.commonService.getroleDetails().customerList;
    if (!roles.some((obj) => obj.role.includes('NBN') || obj.function.includes('NBN'))) {
      this.router.navigateByUrl('/access-denied');
    }
  }

  getuserDetails() {
    this.userDetails = this.userService.userDetailsData;
    if (this.userDetails) {
      const roleList = this.userDetails.roles.map(e => e.role);
      const isAuthorisedUser = this.userDetails.roles?.some(
        (a) => (a?.customerName.toUpperCase() === 'INFRACO' && a?.role === 'NBN Read Only' && a?.functionName.includes('NBN DA Documents'))
        || (a?.customerName.toUpperCase() === 'INFRACO' && a?.role === 'NBN Read & Write' && a?.functionName.includes('NBN DA Documents')));
      this.userName = this.userDetails.username;
      if (isAuthorisedUser) {
        this.isInfracoUser = true;
      } else {
        this.isInfracoUser = false;
      }
      this.getCabinetsDetails();
    } else {
      this.navigate('/manage-cabinet');
    }
  }

  createForm() {
    if (this.isInfracoUser) {
      this.createAccessForm = this.fb.group({
        ID: ['', [Validators.required]],
        role: ['', [Validators.required]],
      }, {
        validator: duplicate('ID', this.selectedCabinetsData)
      });
    } else {
      this.createAccessForm = this.fb.group({
        ID: ['', [Validators.required]]
      }, {
        validator: duplicate('ID', this.selectedCabinetsData)
      });
    }
    this.getLabels();
  }

  getLabels() {
    this.userService.getCabinetsAccessModel().then((data: any) => {
      this.aemLabelAuthoring = data;
      if (this.isInfracoUser) {
        this.tableCol = ["Cabinets ID", "Cabinets name", "Role functions"];
      } else {
        this.tableCol = ["Cabinets ID", "Cabinets name"];
      }
    }).catch((error) => {
    });
  }

  getCabinetsDetails() {
    this.userService.getCabinetsByUserName(this.userName).then((res: any) => {
      this.cabinetDetailsList = res || []
      this.getCabinetsList();
    })
    .catch((err) => {
      this.cabinetDetailsList = [];
    });
  }

  getCabinetsList() {
    this.userService.getCabinetsList().then((data: any) => {
      this.allCabinetsData = data || [];
      this.accessItemsList = this.allCabinetsData.map(ele => ele.cabinetId).sort(this.itemSort);
      this.setSelectedCabinetsId();
      this.createForm();
    }).catch((error) => {
    });
  }

  itemSort(a, b) {
    const str1 = a.toLowerCase();
    const str2 = b.toLowerCase();
    let comparison = 0;
    if (str1 > str2) {
      comparison = 1;
    } else if (str1 < str2) {
      comparison = -1;
    }
    return comparison;
  }

  setRoleList() {
    if (this.userDetails?.role_name === 'NBN Read Only') {
      this.roleList.splice(1,1)
    }
    this.roleItemsList = this.roleList.map(ele => ele.name);
    this.roleItemsList.sort();
  }

  setSelectedCabinetsId() {
    this.selectedCabinetsData = [];
    this.originalCabinetsData = [];
    this.cabinetDetailsList.forEach((ele) => {
      const index = this.allCabinetsData.findIndex(element => element.cabinetId == ele.cabinetId);
      const ind = this.roleList.findIndex(element => element.key == ele.cabinetRole);
      let selectData = {};
      if (this.isInfracoUser) {
        selectData = {};
        if (index > -1) {
          selectData = {
            cabinetId: this.allCabinetsData[index]['cabinetId'],
            cabinetName: this.allCabinetsData[index]['cabinetName']
          }
        }
        if (ind > -1) {
          selectData['role'] = this.roleList[ind]['key'];
          selectData['roleName'] = this.roleList[ind]['name'];
        }
      } else {
        selectData = {
          cabinetId: this.allCabinetsData[index]['cabinetId'],
          cabinetName: this.allCabinetsData[index]['cabinetName'],
          role: ''
        }
      }
      this.selectedCabinetsData.push(selectData);
      this.originalCabinetsData.push(selectData);
    });
  }

  checkDisability() {
    if (this.originalCabinetsData.length == this.selectedCabinetsData.length) {
      let count = 0;
      this.originalCabinetsData.forEach((org) => {
        let isSame = false;
        this.selectedCabinetsData.forEach((chng) => {
          if (this.isInfracoUser) {
            if ((org['cabinetId'] == chng['cabinetId']) && (org['roleName'] == chng['roleName'])) {
              isSame = true;
            }
          } else {
            if (org['cabinetId'] == chng['cabinetId']) {
              isSame = true;
            }
          }
        });
        if (isSame) {
          count = count + 1;
        }
      });
      if (this.originalCabinetsData.length == count) {
        this.isCabinetsChange = false;
      } else {
        this.isCabinetsChange = true;
      }

    } else {
      this.isCabinetsChange = true;
    }

  }

  onClickAddCabinetAccess(): void {
    this.isAddCabinetAccess = true;
  }

  onClickCancelCabinetAccess(): void {
    this.isAddCabinetAccess = false;
    this.createAccessForm.reset();
  }

  onClickSaveCabinetAccess() {
    if (this.isInfracoUser) {
      const index = this.allCabinetsData.findIndex(ele => ele.cabinetId === this.createAccessForm.value.ID);
      const ind = this.roleList.findIndex(ele => ele.name === this.createAccessForm.value.role);
      if (index !== -1 && ind !== -1) {
        const selectData = {
          cabinetId: this.allCabinetsData[index]['cabinetId'],
          cabinetName: this.allCabinetsData[index]['cabinetName'],
          role: this.roleList[ind]['key'],
          roleName: this.roleList[ind]['name']
        }
        this.selectedCabinetsData.push(selectData);
        this.isAddCabinetAccess = false;
      }
    } else {
      const index = this.allCabinetsData.findIndex(ele => ele.cabinetId === this.createAccessForm.value.ID);
      if (index !== -1) {
        const selectData = {
          cabinetId: this.allCabinetsData[index]['cabinetId'],
          cabinetName: this.allCabinetsData[index]['cabinetName'],
          role: ''
        }
        this.selectedCabinetsData.push(selectData);
        this.isAddCabinetAccess = false;
      }
    }
    this.checkDisability();
    this.createAccessForm.reset();
  }

  onClickSubmit() {
    this.userService.addCabinetAccess(this.userDetails, this.selectedCabinetsData).then(res => {
      const successMsg = 'Congratulations. You have successfully updated the cabinets access';
      this.showToasterMessage(successMsg, '', this.options, 'success', 'toast-top-center');
      window.scroll(0, 0);
      this.navigate('/manage-cabinet');
    }).catch((err) => {
      this.router.navigateByUrl('/error');
    });
  }

  removeRow(id) {
    this.removeCabinetsId = id;
    this.onClickRemove();
    this.showRmv=true;
  }

  navigate(path){
    window.scroll(0, 0);
    this.router.navigateByUrl(path);
  }


  onClickRemove() {
    const index = this.selectedCabinetsData.findIndex(ele => ele.cabinetId == this.removeCabinetsId);
    if (index !== -1) {
      this.selectedCabinetsData.splice(index, 1);
      this.checkDisability();
    }
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string, divPosition: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = divPosition;
    this.options.disableTimeOut = false;
    this.options.autoDismiss = false;
    this.options.timeOut = 5000;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = true;
    this.toastr.show(
      message,
      header === '' ? null : header,
      options,
      this.options.iconClasses[type]
    );
    document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
  }
}

export function duplicate(controlName: string, selectedCabinetsData: any) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];

    if (control.errors && !control.errors.duplicate) {
      return;
    }

    let duplicate = false;
    selectedCabinetsData.forEach((ele) => {
      if (ele.cabinetId === control.value) {
        duplicate = true;
      }
    })

    if (duplicate) {
      control.setErrors({ duplicate: duplicate });
    } else {
      control.setErrors(null);
    }
  }
}
