
import { BehaviorSubject, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'tw-core-ui';
import { CustomerService } from 'src/app/admin-settings/customer-list/customer.service';
import { HomePageService } from 'src/app/shared/services/homePage.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/shared/services/http.service';
// import { GridData } from '../../../shared/models/common-model';
// import { CreateUserData } from './users.model';

export interface GridHeaderInterface {
  headers: any;
}

export interface GridHeaderCabinetInterface {
  headers: any;
}

export interface GridDataInterface {
  data: any;
}

@Injectable()
export class myQuotesService {
  spinnerflag = false;
  // private sendSpinnerFlag = new BehaviorSubject(this.spinnerflag);
  // spinnerCurrentstatus = this.sendSpinnerFlag.asObservable();
  private env: any = window['environment'];
  adminRoles = [];
  validUserRoles = [];
  private aemDefaultUri = {
    gridHeader: 'myuser-grid-header',
    gridData: 'users',
    gridDataAll: 'users/all',
    newgridData: 'portal-users',
    users: 'users/portal-users',
    gridLabels: 'myquotes',
    gridLabelsSum: 'quotedetails.json',
    createUser: 'create-user',
    getUser: 'users',
    gridDataDetails: 'darkfibre/feasibility-requests/',
    quoteSummaryCableGrid: 'cable-details-grid-header',
    mockgridDetails: 'mock/quote-details.json',
    mockgridOrderDetails: 'mock/order-details.json',
    withdrawnDetails: 'darkfibre/feasibility-requests/withdraw',
    gridLabelsOrderSum: 'order-details.json',
    orderDataDetails: 'darkfibre-order/order-requests/',
    tAndCapi: 'darkfibre-order/order-requests/terms-conditions',
  };

  public gridHeaderDataObj: any = [];
  public gridHeaderDataObj1: any = [];
  public cableDetailsGridHeaderDataObj: any = [];
  public gridDataObj: any = [];
  public gridDataObj1: any = [];
  public gridLabelAuthoringDataObj: any;
  public withdrawnDataObj: any = [];
  public summaryLabels: any;
  public createUserLabelAuthoringDataObj: any;
  payLoad: any;
  getPayload: any;
  createUserRoleObj: any = [];
  public userDataObj: any = [];
  public createuserFormData: any;
  public existingUserData: any;
  sendCidn: any;
  public gridLabelAuthoringDataCabinetObj: any;
  public userDetailsData: any;
  public cabinatesAccessLabelAuthoringDataObj: any;
  public cabinetListObj: any;
  quoteDetails: {
    quoteReferenceId: string; customerReference: string; responseRequiredDateTime: string; updateDateTime: string; requestContactUserId: string; channel: string; createDateTime: string; customerName: string; quoteStatus: string; productName: string; linkRequirement: string; numberOfFibres: number; contractPeriod: number; doYouRequireDiversity: string; describeYourDiversity: string; existingLinesystem: string; thirdPartyPathDescription: string; aEndLeadIn: string; zEndLeadIn: string; aEndAdress: {
      searchMethod: string; selectedAddress: string; definedPropertyLocation: string; additionalAddressInfo: string; adborId: string; latitude: number; longitude // spinnerCurrentstatus = this.sendSpinnerFlag.asObservable();
      : number; nbnLocId: string; nodeCode: string; primaryContactName: string; primaryContactNumber: string; primaryContactEmail: string; alternativeContactName: string; alternativeContactNumber: string; alternativeContactEmail: string;
    }; aEndAdressSiteAlternativeName: string; zEndAdressSiteAlternativeName: string; a2EndAdressSiteAlternativeName: string; z2EndAdressSiteAlternativeName: string; sameAsAEndAddress: string; sameAsZEndAddress: string; sameAsAEndContact: string; sameAsZEndContact: string; sameAsA2EndContact: string; zEndAdress: { searchMethod: string; selectedAddress: string; definedPropertyLocation: string; additionalAddressInfo: string; adborId: string; latitude: number; longitude: number; nbnLocId: string; nodeCode: string; primaryContactName: string; primaryContactNumber: string; primaryContactEmail: string; alternativeContactName: string; alternativeContactNumber: string; alternativeContactEmail: string; }; feasibilityRequestType: string; previousReferenceId: string; businessDriver: string; externalReference: { externalReferenceType: string; externalReferenceValue: string; }[]; preferredMaxDistance: number; maxAllowableOpticalLoss: { wavelength: string; dbValue: number; }[]; requestorConstraints: string; fundingSource: string; powimcTypeOther: string; l3WBSCodeCapex: string; l3WBSCodeOpex: string; preApprovalCost: number; quoteExpiry: string; notes: { author: string; date: string; text: string; }[];
  }[];
  setQuoteDet: any;
  setSnowID: any;
  setSubDate: any;
  setquoteStatus: any;
  withdrawnDetailsFormValues: any;
  businessList = [];
  constructor(private apiService: HttpService, private http: HttpClient, private authService: AuthService, private customerService: CustomerService,
    private homepageservice: HomePageService, private commonService: CommonService, private router: Router) { }

  setWithdrawnDetailsFormValues(val) {
    //  sessionStorage.setItem('withdrawnDetailsFormValues', val)
    this.withdrawnDetailsFormValues = val;
  }

  getWithdrawnDetailsFormValues() {
    return this.withdrawnDetailsFormValues;
  }


  public getQuoteListModel() {
    return new Promise((resolve, reject) => {
      if (!this.gridLabelAuthoringDataObj) {
        const worklistUrl = this.env.aemEndPoint + this.aemDefaultUri.gridLabels;
        this.http.get<any>(worklistUrl)
          .subscribe(
            res => {
              this.gridLabelAuthoringDataObj = res.data.content;
              resolve(this.gridLabelAuthoringDataObj);
            },
            err => {
              this.gridLabelAuthoringDataObj = null;
              reject(err);
              return observableThrowError(err || 'Server error');
            }
          );
      } else {
        resolve(this.gridLabelAuthoringDataObj);
      }
    });
  }

  public getQuoteSummaryListModel(isOrder?) {
    if (!isOrder) {
      return new Promise((resolve, reject) => {
        if (!this.summaryLabels) {
          const worklistUrl = this.env.aemEndPoint + this.aemDefaultUri.gridLabelsSum;
          this.http.get<any>(worklistUrl)
            .subscribe(
              res => {
                this.summaryLabels = res.data.content;
                resolve(this.summaryLabels);
              },
              err => {
                this.summaryLabels = null;
                reject(err);
                return observableThrowError(err || 'Server error');
              }
            );
        } else {
          resolve(this.summaryLabels);
        }
      });
    } else {
      return new Promise((resolve) => {
        const worklistUrl = this.env.aemEndPoint + this.aemDefaultUri.gridLabelsOrderSum;
        this.http.get<any>(worklistUrl)
          .subscribe(
            res => {
              resolve(res.data.content);
            }
          );
      });
    }

  }


  public getGridHeader() {
    return new Promise((resolve, reject) => {
      this.http.get<GridHeaderInterface>(this.env.aemEndPoint + this.aemDefaultUri.gridHeader)
        .subscribe(res => {
          this.gridHeaderDataObj = res;
          resolve(true);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getCableDetailsGridHeader() {
    return new Promise((resolve, reject) => {
      this.http.get<GridHeaderInterface>(this.env.aemEndPoint + this.aemDefaultUri.quoteSummaryCableGrid)
        .subscribe(res => {
          this.cableDetailsGridHeaderDataObj = res;
          resolve(true);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getCableDetailsGridHeaderPath2() {
    return new Promise((resolve, reject) => {
      this.http.get<GridHeaderInterface>(this.env.aemEndPoint + this.aemDefaultUri.quoteSummaryCableGrid)
        .subscribe(res => {
          this.cableDetailsGridHeaderDataObj = res;
          resolve(true);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getGridData(params, cidn) {
    this.sendCidn = cidn;
    const res = {
      "data": {
        "quoteDetailsList": [
          {
            "customername": "Telstra Limited",
            "quoteReferenceId": "IR1234567890",

            "customerReference": "INT234567890",

            "status": "Expired",

            "siteAendAddress": "level 1,105 Melbourne",

            "siteZendAddress": "level 2,305 Lonsdale St,Melbourne",

            "updateDateTime": "06 Apr 2023 09:01",

            "quoteExpiry": "06 Apr 2023",
            "product": "Dark Fiber",
            "siteAalternativeName": "john deo",
            "siteZalternativeName": "john",
            "createDateTime": "06 Apr 2023 09:00",
            "requestContactUserId": "john@telstra.com.au",
            "RequestorChannel": "Portal"

          },
          {
            "customername": "Telstra Limited",
            "quoteReferenceId": "IR1234563456",

            "customerReference": "INT1234563456",

            "status": "In Progress",

            "siteAendAddress": "level 3,100 Point Cook Melbourne",

            "siteZendAddress": "level 8,89 Melbourne",

            "updateDateTime": "07 Apr 2023 09:00",

            "quoteExpiry": "10 Apr 2023",
            "product": "Dark Fiber",
            "siteAalternativeName": "Mary leo",
            "siteZalternativeName": "Mary",
            "createDateTime": "08 Apr 2023 09:00",
            "requestContactUserId": "mary@telstra.com.au",
            "RequestorChannel": "Portal"
          },
          {
            "customername": "TPG",
            "quoteReferenceId": "IR1234563333",

            "customerReference": "INT1234563333",

            "status": "Quote Ready",

            "siteAendAddress": "level 12,100 Melbourne",

            "siteZendAddress": "level 4,89 Point Cook Melbourne",

            "updateDateTime": "09 Apr 2023 09:00",

            "quoteExpiry": "18 Apr 2023",
            "product": "Dark Fiber",
            "siteAalternativeName": "jim",
            "siteZalternativeName": "Kate jim",
            "createDateTime": "11 Apr 2023 09:00",
            "requestContactUserId": "jim@telstra.com.au",
            "RequestorChannel": "Portal"
          },
          {
            "customername": "Microsoft",
            "quoteReferenceId": "IR123456111",

            "customerReference": "INT123456111",

            "status": "Draft",

            "siteAendAddress": "level 12,100 Melbourne",

            "siteZendAddress": "level 4,89 Melbourne",

            "updateDateTime": "09 Apr 2023 09:00",

            "quoteExpiry": "18 Apr 2023",
            "product": "Dark Fiber",
            "siteAalternativeName": "Rosa",
            "siteZalternativeName": "Rosa luna",
            "createDateTime": "11 Apr 2023 09:00",
            "requestContactUserId": "rosa@telstra.com.au",
            "RequestorChannel": "Portal"
          },
          {
            "customername": "Microsoft",
            "quoteReferenceId": "IR1234561234",

            "customerReference": "INT1234561234",

            "status": "Withdrawn",

            "siteAendAddress": "level 7,56  Lonsdale St,Melbourne",

            "siteZendAddress": "level 4,50 Melbourne",

            "updateDateTime": "12 Apr 2023 09:00",

            "quoteExpiry": "20 Apr 2023",
            "product": "Dark Fiber",
            "siteAalternativeName": "Icy",
            "siteZalternativeName": "Icy ab",
            "createDateTime": "14 Apr 2023 09:00",
            "requestContactUserId": "icy@telstra.com.au",
            "RequestorChannel": "Portal"
          },
          {
            "customername": "Telstra Limited",
            "quoteReferenceId": "IR1234569999",

            "customerReference": "INT1234569999",

            "status": "Cancelled",

            "siteAendAddress": "level 2,13 Melbourne",

            "siteZendAddress": "level 9,80  Lonsdale St,Melbourne",

            "updateDateTime": "12 Apr 2023 09:00",

            "quoteExpiry": "20 Apr 2023",
            "product": "Dark Fiber",
            "siteAalternativeName": "pinky",
            "siteZalternativeName": "pinky ghosh",
            "createDateTime": "14 Apr 2023 09:00",
            "requestContactUserId": "pinky@telstra.com.au",
            "RequestorChannel": "Portal"
          },
          {
            "customername": "TPG",
            "quoteReferenceId": "IR1234562222",

            "customerReference": "INT1234562222",

            "status": "Submitted",

            "siteAendAddress": "level 13,13 Point Cook Melbourne",

            "siteZendAddress": "level 9,90 Melbourne",

            "updateDateTime": "12 Apr 2023 09:00",

            "quoteExpiry": "20 Apr 2023",
            "product": "Dark Fiber",
            "siteAalternativeName": "elis",
            "siteZalternativeName": "elis",
            "createDateTime": "14 Apr 2023 09:00",
            "requestContactUserId": "elis@telstra.com.au",
            "RequestorChannel": "Portal"
          },
          {
            "customername": "Digicell",
            "quoteReferenceId": "IR1230992222",

            "customerReference": "INT1230992222",

            "status": "Awaiting Customer Response",

            "siteAendAddress": "level 13,13 Melbourne",

            "siteZendAddress": "level 9,90 Melbourne",

            "updateDateTime": "12 Apr 2023 09:00",

            "quoteExpiry": "20 Apr 2023",
            "product": "Dark Fiber",
            "siteAalternativeName": "elis",
            "siteZalternativeName": "elis",
            "createDateTime": "14 Apr 2023 09:00",
            "requestContactUserId": "elis@telstra.com.au",
            "RequestorChannel": "Portal"
          },
          {
            "customername": "TPG",
            "quoteReferenceId": "IR1230333222",

            "customerReference": "INT1230333222",

            "status": "Awaiting Dependent Action",

            "siteAendAddress": "level 13,Point Cook Melbourne",

            "siteZendAddress": "level 9,90 Melbourne",

            "updateDateTime": "12 Apr 2023 09:00",

            "quoteExpiry": "20 Apr 2023",
            "product": "Dark Fiber",
            "siteAalternativeName": "tim",
            "siteZalternativeName": "tim",
            "createDateTime": "14 Apr 2023 09:00",
            "requestContactUserId": "tim@telstra.com.au",
            "RequestorChannel": "Portal"
          },
          {
            "customername": "Telstra Limited",
            "quoteReferenceId": "IR1230333222",

            "customerReference": "INT1230333222",

            "status": "Awaiting Customer Response",

            "siteAendAddress": "level 13,Point Cook Melbourne",

            "siteZendAddress": "level 9,90 Melbourne",

            "updateDateTime": "12 Apr 2023 09:00",

            "quoteExpiry": "20 Apr 2023",
            "product": "Dark Fiber",
            "siteAalternativeName": "tim",
            "siteZalternativeName": "tim",
            "createDateTime": "14 Apr 2023 09:00",
            "requestContactUserId": "tim@telstra.com.au",
            "RequestorChannel": "Portal"
          },
          {
            "customername": "TPG",
            "quoteReferenceId": "IR1230333222",

            "customerReference": "INT1230333222",

            "status": "Awaiting Dependent Action",

            "siteAendAddress": "level 13,Point Cook Melbourne",

            "siteZendAddress": "level 9,90 Melbourne",

            "updateDateTime": "12 Apr 2023 09:00",

            "quoteExpiry": "20 Apr 2023",
            "product": "Dark Fiber",
            "siteAalternativeName": "tim",
            "siteZalternativeName": "tim",
            "createDateTime": "14 Apr 2023 09:00",
            "requestContactUserId": "tim@telstra.com.au",
            "RequestorChannel": "Portal"
          },
          {
            "customername": "Telstra Limited",
            "quoteReferenceId": "IR1230333222",

            "customerReference": "INT1230333222",

            "status": "Awaiting Dependent Action",

            "siteAendAddress": "level 13,Point Cook Melbourne",

            "siteZendAddress": "level 9,90 Melbourne",

            "updateDateTime": "12 Apr 2023 09:00",

            "quoteExpiry": "20 Apr 2023",
            "product": "Dark Fiber",
            "siteAalternativeName": "tim",
            "siteZalternativeName": "tim",
            "createDateTime": "14 Apr 2023 09:00",
            "requestContactUserId": "tim@telstra.com.au",
            "RequestorChannel": "Portal"
          }


        ]
      }
    }

    return new Promise<any>((resolve, reject) => {
      // const tokenValue = this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : false;
      // let httpOptions;
      // if (tokenValue) {
      //   httpOptions = {
      //     headers: new HttpHeaders({
      //       'Content-Type': 'application/json',
      //       'Accept': '*/*',
      //       'Authorization': tokenValue
      //     })
      //   };
      // } else {
      //   httpOptions = {
      //     headers: new HttpHeaders({
      //       'Content-Type': 'application/json',
      //       'Accept': '*/*'
      //     })
      //   };
      // }
      // const url = this.env.apiPoint + this.aemDefaultUri.gridData + "?cidn=" + cidn;
      // this.http.get<any>(this.env.apiPoint + this.aemDefaultUri.gridData + "?cidn=" + cidn, httpOptions)
      //   .subscribe(res => {
      //     this.gridDataObj = res['data']['userDetailsList'];
      //     resolve(res);
      //   }, err => {
      //     this.gridDataObj = [];
      //     reject(err);
      //     return observableThrowError(err || 'Server error');
      //   });

      this.gridDataObj = res['data']['quoteDetailsList'];
      resolve(res);
    });


  }


  getSummaryDetails(quoteId, sysId, cidn, timezone, isOrder?, path?) {
    if (!isOrder) {
      return new Promise<any>((resolve, reject) => {
        const tokenValue = this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : false;
        let httpOptions;
        if (tokenValue) {
          httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Accept': '*/*',
              'Authorization': tokenValue
            })
          };
        } else {
          httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Accept': '*/*'
            })
          };
        }


        let url = this.env.apiPoint + this.aemDefaultUri.gridDataDetails + quoteId + "?cidn=" + cidn;
        if (sysId) {
        url += "&sys-id=" + sysId + '&timezone=' + timezone;
        }
        //To run in local
         // const url = this.env.aemEndPoint + this.aemDefaultUri.mockgridDetails;

        this.http.get<any>(url, httpOptions)
          .subscribe(res => {
          //  this.gridDataObj = res['data']['quoteListDetails'];
            resolve(res);
          }, err => {
            this.gridDataObj = [];
            reject(err);
            return observableThrowError(err || 'Server error');
          });
      });

    } else {
      return new Promise((resolve) => {
        //for local
        // const worklistUrl = this.env.aemEndPoint + this.aemDefaultUri.mockgridOrderDetails;
        // for API
        let worklistUrl = this.env.apiPoint + this.aemDefaultUri.orderDataDetails + quoteId + "?cidn=" + cidn + '&orderConvertedFrom=' + path + '&screenName=orderSummary';
        if (sysId) {
          worklistUrl += "&sys-id=" + sysId + '&timezone=' + timezone;
        }

        this.http.get<any>(worklistUrl)
          .subscribe(
            res => {
              resolve(res);
            }
          );
      });
    }
  }

  getTandCforOrder(cidn, product) {
    return new Promise((resolve) => {
      const tAndCapi = this.env.apiPoint + this.aemDefaultUri.tAndCapi + '?cidn=' + cidn + '&product=' + product;
      this.http.get<any>(tAndCapi, { headers: this.apiService.getHeaders().headers})
        .subscribe(
          res => {
            resolve(res.data.termsAndConditions);
          }
        );
    });
  }

  saveSelectedID(data) {
    localStorage.setItem('quoteID', data);

  }
  saveSnowID(data) {
    localStorage.setItem('snowID', data);
  }

  saveSubmittedDate(data) {
    localStorage.setItem('subDate', data);
  }
  getWithdrawnDetails(quoteId, sysId, cidn, timezone) {
    return new Promise<any>((resolve, reject) => {
      const tokenValue = this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : false;
      let httpOptions;
      if (tokenValue) {
        httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization': tokenValue
          })
        };
      } else {
        httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': '*/*'
          })
        };
      }


      let url = this.env.apiPoint + this.aemDefaultUri.withdrawnDetails + quoteId + "?cidn=" + cidn;
      if (sysId) {
        url += "&sys-id=" + sysId;
        url += "&sys-id=" + sysId + '&timezone=' + timezone;
      }
      //To run in local
      //  const url = this.env.aemEndPoint + this.aemDefaultUri.mockgridDetails;

      this.http.get<any>(url, httpOptions)
        .subscribe(res => {
          this.withdrawnDataObj = res['data']['withdrawnListDetails'];
          resolve(res);
        }, err => {
          this.withdrawnDataObj = [];
          reject(err);
          return observableThrowError(err || 'Server error');
        });

    });

  }

  public saveWithdrawnDetails(params, val, quoteID) {
    const username = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail'];
    const payload = {
      quoteReferenceId: params.quoteReferenceId ? params.quoteReferenceId : '',
      cidn: params.cidn ? params.cidn : '',
      sys_id: params.sys_id ? params.sys_id : '',
      withdrawalReason: params.withdrawalReason ? params.withdrawalReason : '',
      additionalDetails: params.additionalDetails ? params.additionalDetails : '',
      quoteStatus: params.quoteStatus ? params.quoteStatus : '',

    }
    this.setWithdrawnDetailsFormValues(payload);
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',
      })
    };

    const url = this.env.apiPoint + this.aemDefaultUri.gridDataDetails + quoteID + "/withdraw"
    //To run in local
    // const url = this.env.aemEndPoint + this.aemDefaultUri.mockSaveQuote;
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(url, payload, httpOptions)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }
  makeSaveColSelected(sourceArray, targetArray) {
    const foundIds = new Set();
    for (const sourceObject of sourceArray) {
      for (const targetObject of targetArray) {
        if (sourceObject.field === targetObject.value) {
          targetObject.checked = true;
          foundIds.add(sourceObject.field);
        }
      }
    }
    for (const targetObject of targetArray) {
      if (!foundIds.has(targetObject.value)) {
        targetObject.checked = false;
      }
    }
    return targetArray;
  }
  customerNameDropdownChange(isOrder, data, quoteDetails?) {

    this.customerService.getLinkedCIDNs().then(res => {
      if (res && quoteDetails && quoteDetails.businessNameDetails && quoteDetails.businessNameDetails.length > 0) {

        const iteratedArr = quoteDetails.businessNameDetails.map(({
          bussinessName,
          cidn
        }) => [bussinessName, cidn]);

        for (var i = 0; i < iteratedArr.length; i++) {
          const businessName = iteratedArr[i][0];
          const cidn = iteratedArr[i][1];
          if (businessName || cidn) {
            this.businessList.push({
              businessName: businessName,
              cidn: cidn
            });

          }
        }
      }
      else {
        let userName = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail']
        this.homepageservice
          .getUserProfile(userName)
          .then((response) => {
            let myLinkedCustomerList = response?.data?.customerDetails;
            if (myLinkedCustomerList) {
              myLinkedCustomerList.forEach((option) => {
                this.businessList.push({
                  businessName: option.customerName,
                  cidn: option.cidn
                });
              });

            }
          });
      }
      if (this.businessList?.length) {
        let isReadWriteOnQuoteMgmt = false;
        let isReadOnlyQuoteRole = false;
        let isReadWriteOnOrderMgmt = false;
        let isReadOnlyOrderRole = false;
        this.businessList.forEach((item, i) => {
          if((!isReadWriteOnQuoteMgmt && !isReadOnlyQuoteRole && !isOrder) ||
          (!isReadWriteOnOrderMgmt && !isReadOnlyOrderRole && isOrder)) {
          if (item.businessName === data) {
            let cidnSelected = item.cidn;
            localStorage.setItem('selectedHeaderCIDN', cidnSelected)
            const roles = this.commonService.getroleDetails().customerList;
            if (!isOrder) {
              //     const isReadWriteOnQuoteMgmt = roles.some((obj) => (obj?.cidn === cidnSelected && obj?.role === 'Read & Write' && obj?.function === 'Quote Request Management'));
              roles.forEach((obj, index) => {
                if(!isReadWriteOnQuoteMgmt && !isReadOnlyQuoteRole) {
                if (obj?.cidn === cidnSelected && obj?.role === 'Read & Write' && obj?.function === 'Quote Request Management') {
                  isReadWriteOnQuoteMgmt = true;
                  this.router.navigate(['/myQuotes']);
                  return;
                } else if (obj?.cidn === cidnSelected && obj?.role === 'Read Only' && obj?.function === 'Quote Request Management') {
                  isReadOnlyQuoteRole = true;
                  this.router.navigate(['/myQuotes']);
                  return;
                }
                if ((index === roles.length-1) && !isReadWriteOnQuoteMgmt && !isReadOnlyQuoteRole) {
                  this.router.navigate(['']);
                  return;
                }
                return;
              }
              })
              //  const isReadOnlyRole = roles.some((obj) => (obj?.cidn === cidnSelected && obj?.role === 'Read Only' && obj?.function === 'Quote Request Management'));
              if (isReadWriteOnQuoteMgmt || isReadOnlyQuoteRole) {
                this.router.navigate(['/myQuotes']);
                return;
              }
              else {
                this.router.navigate(['']);
                return;
              }
            } else {
              //     const isReadWriteOnQuoteMgmt = roles.some((obj) => (obj?.cidn === cidnSelected && obj?.role === 'Read & Write' && obj?.function === 'Quote Request Management'));
              roles.forEach((obj, index) => {
                if(!isReadWriteOnOrderMgmt && !isReadOnlyOrderRole) {
                if (obj?.cidn === cidnSelected && obj?.role === 'Read & Write' && obj?.function === 'Order Management') {
                  isReadWriteOnOrderMgmt = true;
                  this.router.navigate(['/myOrders']);
                  return;
                } else if (obj?.cidn === cidnSelected && obj?.role === 'Read Only' && obj?.function === 'Order Management') {
                  isReadOnlyOrderRole = true;
                  this.router.navigate(['/myOrders']);
                  return;
                }
                if ((index === roles.length-1) && !isReadWriteOnOrderMgmt && !isReadOnlyOrderRole) {
                  this.router.navigate(['']);
                  return;
                }
                return;
              }
              })
              //  const isReadOnlyRole = roles.some((obj) => (obj?.cidn === cidnSelected && obj?.role === 'Read Only' && obj?.function === 'Quote Request Management'));
              if (isReadWriteOnOrderMgmt || isReadOnlyOrderRole) {
                this.router.navigate(['/myOrders']);
                return;
              }
              else {
                this.router.navigate(['']);
                return;
              }
            }
            return;
          }
          // else {
          //   this.router.navigate(['']);
          // }
          if (i === (this.businessList.length-1)) {
            this.router.navigate(['']);
            return;
          }
        }
        })

        //   })
        // }).catch((err) => {
      }
    });

  }

}
