import {throwError as observableThrowError,  Observable } from 'rxjs';
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private env: Object = window['environment'];
  private apiUrl = {
    errorDetails: 'errorDetails',
  };
  errorDetails: any;
  constructor(private http: HttpClient) { }

  public getErrorDetails() {
    return new Promise((resolve, reject) => {
      this.http.get(this.env['aemEndPoint'] + this.apiUrl.errorDetails).pipe(catchError((error: any): any => {
        reject(error);
        return observableThrowError(error.json().error || 'Server error');
      })).subscribe( (res: any) => {
        this.errorDetails = res.data;
        resolve(this.errorDetails);
      });
    });
  }
}
