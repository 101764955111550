<div class="container-fluid manageCabinetList infra-manage-cabinets-container" *ngIf="aemLabelAuthoring" id="qa-banner">
  <h1 id="new" class="heading">{{aemLabelAuthoring.pageHeader.cabinets}}</h1>
  <div class="manage-cabintets">
    <form [formGroup]="searchForm" id="userList" #userList="ngForm" class="col-12">
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4" *ngIf="infracoAdmin">
          <label class="user-details-label-top required-field" automationType="label" automationId="lblrole"
            id="cabinetsAccessId" attr.aria-label="{{'Customer name'}}">
            {{ 'Customer name' }}
          </label>
          <app-cui-searchable-dropdown name="businessTypes" [formControlName]="'businessTypes'"
            [model]="businessNameDropDownModel" [labelName]="'Business name'" [placeholder]="'Select customer name'"
            [isRequired]=true [items]=businessRoles automationType="dropdown" automationId="drpdwnroleType"
            [noAutoComplete]=false id="statusDropdown" class="user-dropDown"
            [ngClass]="{'invalid-form-control': (searchForm.controls.businessTypes.invalid && searchForm.controls.businessTypes.touched )}"
            required>
          </app-cui-searchable-dropdown>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4">
          <label class="user-details-label-top" aria-label="search users">
            {{aemLabelAuthoring.form.userName.headerText}}
          </label>
          <app-cui-textfield [(model)]="userNameModel" [parentFormGroup]="searchForm"
            [ngClass]="{'invalidUserName': userNameInvalidMessage}" (keyup)="clearBusinessName($event)">
          </app-cui-textfield>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 btn-pad">
          <div class="d-flex btn-container">
            <button type="button" [disabled]="checkSearchBtnDisable()" class="app-btn-green"
              (click)="searchBusinessData(userList)">{{aemLabelAuthoring.form.searchBox.search}}
            </button>
            &nbsp;&nbsp;
            <button type="button" class="resetBtn" (click)="resetSearch()" [disabled]="!userNameModel.inputText">
              {{aemLabelAuthoring.form.searchBox.reset}}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="grid-container manage-cabinet-grid" tabindex="-1">
    <app-cui-ag-grid *ngIf="gridConfig && gridInitialized" [model]="gridModel" (rowClicked)="onRowClicked($event)"
      (filtersOnChanged)="onFilterSelected($event)" (focusOnTop)="focusOnTop($event)">
    </app-cui-ag-grid>
  </div>
</div>
