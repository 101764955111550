import { Component, Input, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { forkJoin } from 'rxjs';
import { BulkQuoteService } from '../bulk-quote.service';
import { myQuotesService } from 'src/app/quote-management/my-quotes/myquotes.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ErrorRendererComponent } from './error-renderer/error-renderer.component';
@Component({
  selector: 'app-bulk-quote-list',
  templateUrl: './bulk-quote-list.component.html',
  styleUrls: ['./bulk-quote-list.component.less']
})
export class BulkQuoteListComponent implements OnInit {
  headers: any;
  columnDefs: any;
  gridInitialized: boolean = false;
  public gridModel: any = {
    // CustomAgGrid = {
    gridOptions: {},
    keysToSuppress: [13], // Supress Enter key row selection
    labelAuthoring: {
      headerPageCount: '',
      filter: '',
      resetFilter: '',
      paginationHeader: '',
      noData: '',
      noDataOnFilter: '',
      noDataOnSearch: '',
      toTop: ''
    }
  };
  public gridConfig: GridOptions;
  subscription: any;
  public rowData: any = [];
  isErrored: boolean = false;
  cidnSelected: any;
  @Input() quotesData;
  constructor(private dataSvc: myQuotesService, private bulkQuoteService: BulkQuoteService,
    private commonService: CommonService) {
  }

  ngOnInit(): void {
    this.getLabels();
  }
  ngOnChanges() {
    this.rowData = this.quotesData?.quotes;
    if (this.rowData) {
      this.createColumnDefs();
    }
  }

  private createGridConfig() {
    this.gridConfig = <GridOptions>{};
    this.gridConfig.rowData = this.rowData;
    this.gridConfig.paginationPageSize = 10;
    this.gridConfig.columnDefs = this.columnDefs;
    this.gridConfig.suppressHorizontalScroll = true;
    this.gridConfig.pagination = true;
    this.gridConfig.rowSelection = 'single';
    this.gridInitialized = true;
    this.gridConfig.tooltipShowDelay = 0;
    this.gridModel.gridOptions = this.gridConfig;
  }

  private createColumnDefs() {
    return new Promise((resolve, reject) => {
      this.bulkQuoteService.getGridHeader(this.quotesData.serviceType).then((res: any) => {
        this.columnDefs = res?.headers;
        this.columnDefs.forEach((x: any) => {
          if (x.field === 'rowNumber') {
            x.comparator = (a, b) => a.localeCompare(b, undefined, { numeric: true })
          }
        });
        if (this.quotesData?.file?.length > 0) {

          this.isErrored = true;
          const erroredRow = {
            "headerName": "",
            "fullHeaderName": "",
            "filter": "",
            "field": "valid",
            "tooltipField": "valid",
            "minWidth": 155,
            "cellRendererFramework": ErrorRendererComponent
          }
          this.columnDefs.splice(0, 0, erroredRow);
        }
        this.createGridConfig();
        resolve(true);
      }).catch((err) => {
        console.log(err);
        reject(err);
      });
    });
  }
  getLabels() {
    return new Promise((resolve, reject) => {
      this.dataSvc.getQuoteListModel().then(() => {
        //    this.aemLabelAuthoring = this.dataSvc.gridLabelAuthoringDataObj;
        this.gridModel.labelAuthoring = this.dataSvc.gridLabelAuthoringDataObj.form.grid;
        // this.successMsg();
        resolve(true);
      }).catch(() => {
        // reject(false);
      });
    });
  }
}
