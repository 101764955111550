<h1 class="col-lg-12 col-md-12 col-sm-12 col-xs-12 heading">
  {{faqHeader}}
</h1>
<div class="container-fluid page-container">
  <div class="faq-page">
    <h2 class="faq-message">{{faqText}}</h2>
    <hr>
    <div class="section-header" *ngFor="let header of faqSectionHeaders">
      <div>
        <h3 *ngIf="roleObj !== 'duct'" >{{header}}</h3>
        <div *ngFor="let item of faqData">
          <div class="section-qa" *ngFor="let qa of item[header];index as i">
            <button tabindex="0" id="btn_{{i}}" attr.aria-controls="sec_{{i}}"
              attr.aria-label="Q: {{qa.faqQuestion}} Hit enter to expand and read the answer.later hit enter to collapse it" class="accordion"
              (keypress)="toggleAccordian($event, qa)" (click)="toggleAccordian($event, qa)">
              Q: {{qa.faqQuestion}}</button>
            <ng-container *ngIf="qa.type == 'plain'">
              <div class="panel" attr.aria-labelledby="btn_{{i}}" id="sec_{{i}}" role="answer">
                <p *ngIf="qa?.show"> A: {{qa.faqAnswer}} </p>
              </div>
            </ng-container>
            <ng-container *ngIf="qa.type == 'mail'">
              <div class="panel" attr.aria-labelledby="btn_{{i}}" id="sec_{{i}}" role="answer">
                <!-- <p [innerHtml]="qa[roleObj]"></p> -->
                <p *ngIf="qa?.show" [innerHtml]="qa[roleObj]"></p>
              </div>
            </ng-container>
            <ng-container *ngIf="qa.type == 'link'">
              <div class="panel" attr.aria-labelledby="btn_{{i}}" id="sec_{{i}}" role="answer">
                <p *ngIf="qa?.show" [innerHtml]="qa.faqAnswer" style="margin-bottom: 5px;"></p>
                <a *ngIf="qa?.show" (click)="viewDocument('assets/labelAuthoring/user-guide.pdf')">User Guide</a>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>