<div class="custom-arrow-up"></div>
<div class="custom-filter">
  <a 
    class="closefilterclass"
      aria-label="click to close filter" 
      tabindex="0"
      (keypress)="closeFilter('keypress')"  
      (click)="closeFilter('click')"><i class="icon-icon-ui-cross icon-xs closeicon"></i>
    </a>
    <h3>{{customFilterObj?.fullHeaderName}}
  </h3>
  <div class="container">
    <label for="{{customFilterObj?.fullHeaderName}}">Filter by {{customFilterObj?.fullHeaderName | lowercase }}</label>
    <input 
      #filterInput
      (paste)="onPaste($event)"
      (keyup)="validateFilter(filterInput.value)"
      tabindex="0" 
     aria-labelledby="text"
      class="form-control">
      <span id="text" aria-hidden="true">Filter by {{customFilterObj?.fullHeaderName}}</span>
    <button 
      id="applyFilter" tabindex="0"
      disabled="disabled" 
      (click)="applyFilter(filterInput.value)" 
      aria-label="Apply filter" 
      class="secondary-btn">Apply filter</button>
  </div>
</div>
