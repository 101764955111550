<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="cdkOrigin" [cdkConnectedOverlayOpen]="isPopOpen"
    (overlayOutsideClick)="closeNotification()" (detach)="isPopOpen=false" cdkConnectedOverlayPush="true" 
    [cdkConnectedOverlayDisableClose]="true"
    (overlayKeydown)="$event.key === 'Escape' ? closeNotification() : null">

    <div class="notification-container" cdkTrapFocus cdkTrapFocusAutoCapture="true">
        <div class="pointer" ></div>
        <h3 class="notification-header"  tabindex="0" aria-label="Notifications dialog">Notifications</h3>
        <mat-divider class="notification-divider"></mat-divider>
        <div *ngIf="!(isDataLoaded && notificationData && notificationData.length === 0)" class="notification-content" loaderId="notificationLoader" style="position: relative;">
            <div class="loader" role="alert" aria-live="assertive" aria-atomic="true" >
                <ngx-ui-loader loaderId="notificationLoader" [fgsTemplate]="foregroundSpinner"
                    logoUrl="../../assets/images/Logo.svg" logoSize="100" logoPosition="center-center" text="Loading..."
                    textPosition="center-center" gap="-45" role="alert">
                </ngx-ui-loader>
            </div>

            <!-- <ng-container *ngIf="notificationData" id="dialog-description" >
                <ng-container *ngFor="let x of notificationData; let i=index" role="menu">
                    <div class="notification" role="menu" tabindex="0" (click) = navigateRoute(x) (keydown.enter)="navigateRoute(x)">
                        <div class="id">{{x.requestReferenceId}}</div>
                        <div>
                            <div class="message">
                                <span *ngIf="x.requestType">{{x.requestType}}</span> 
                                <mat-divider class="vertical-divider" *ngIf="x.requestType" [vertical]="true"></mat-divider>
                                <span>{{x.nextStatus}}</span></div>
                            <div class="timestamp">{{x.eventTimestamp}}</div>
                        </div>
                    </div>
                    <mat-divider class="notification-divider" *ngIf="i < (notificationData.length-1)"></mat-divider>
                </ng-container>
            </ng-container> -->

            <ul class="notification-list" *ngIf="notificationData" id="dialog-description">
                <li *ngFor="let x of notificationData; let i=index" tabindex="0" (click)=navigateRoute(x) (keydown.enter)="navigateRoute(x)">
                    <div class="notification">
                        <div class="id">{{x.requestReferenceId}}</div>
                        <div>
                            <div class="message">
                                <span *ngIf="x.requestType">{{x.requestType}}</span>
                                <mat-divider class="vertical-divider" *ngIf="x.requestType" [vertical]="true"></mat-divider>
                                <span>{{x.nextStatus}}</span>
                            </div>
                            <div class="timestamp">{{x.eventTimestamp}}</div>
                        </div>
                    </div>
                    <mat-divider class="notification-divider" *ngIf="i < (notificationData.length-1)"></mat-divider>
                </li>
            </ul>


        </div>

        <ng-container *ngIf="notificationData && notificationData.length == 0" >
            <div class="no-notification" id="dialog-no-description" tabindex="0">
                <span>No notifications</span>
            </div>
        </ng-container>

    </div>

</ng-template>

<ng-template #foregroundSpinner></ng-template>

<div class="toastrContainer" aria-live="assertive" role="alert" toastContainer></div>