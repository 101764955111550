import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { forkJoin } from 'rxjs';
import { CustomAgGrid, CustomDropDown, CustomTextField } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { UsersService } from '../admin-settings/user/user-list/users.service';
import { CheckboxFilterComponent } from '../grid-filter/checkbox-filter/checkbox-filter.component';
import { CustomTextFilterComponent } from '../grid-filter/custom-text-filter/custom-text-filter.component';
import { CustomDateFilterComponent } from '../grid-filter/custom-date-filter/custom-date-filter.component';
// import { GridTilesUserViewComponent } from '../components/grid-tiles-user-view/grid-tiles-user-view.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DashboardService } from '../cabinets/dashboard.service';
import { UtilityService } from '../shared/services/utility.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { HomePageService } from 'src/app/shared/services/homePage.service';
import { CIDNS } from '../shared/model/general.model';
import { ToastrService, GlobalConfig, ToastContainerDirective } from 'ngx-toastr';
import { AuthService } from 'tw-core-ui';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-manage-cabinets-list',
  templateUrl: './manage-cabinets-list.component.html',
  styleUrls: ['./manage-cabinets-list.component.less']
})

export class ManageCabinetsListComponent implements OnInit, OnDestroy {
  public gridConfig: GridOptions;
  public rowData: any;
  public columnDefs: any;
  public gridInitialized: Boolean;
  public options: GlobalConfig;
  subscription: any;
  subscribedflag: Boolean = false;
  public isSelected: boolean = false;
  public infracoAdmin: boolean = false;
  isSearched: Boolean = false;
  isTextSearch: Boolean = false;
  isValidUserName: Boolean = false;
  userNameInvalidMessage = '';
  showFilterPop: boolean = false;
  public currentlyCheckedCount = 0;
  currentSelected: { field: String; colID: string; tooltipField: string; filter: string; headerName: string; fullHeaderName: string; customFilter: any, width: any, minWidth: any, maxWidth: any };
  checkedList: any[];
  colDefsAthleteEXcluded: {}[];
  colDefsAthleteEXcluded1: {}[];
  colDefsAthleteEXcluded2: {}[];
  colDefsAthleteEXcluded3: {}[];
  colDefsAthleteEXcluded4: {}[];
  stopSelect: boolean = false;
  manageCabinetGridHeader = [
    {
      headerName: "Username",
      fullHeaderName: "Username",
      filter: "customTextFilter",
      field: "username",
      tooltipField: "username",
      colId: "grid-username",
      minWidth: 320,
      suppressNavigable: false
    },
    {
      headerName: "First name",
      fullHeaderName: "First name",
      field: "firstName",
      tooltipField: "firstName",
      colId: "grid-firstName",
      filter: "customTextFilter",
      minWidth: 150
    },
    {
      headerName: "Last name",
      fullHeaderName: "Last name",
      field: "lastName",
      tooltipField: "lastName",
      colId: "grid-lastName",
      filter: "customTextFilter",
      minWidth: 150
    },
    {
      headerName: "Business name",
      fullHeaderName: "Business name",
      field: "customerName",
      colId: "grid-customerName",
      tooltipField: "customerName",
      minWidth: 200,
      filter: "customTextFilter"
    },
    {
      headerName: "Role access",
      fullHeaderName: "Role access",
      field: "role_name",
      colId: "grid-role_name",
      tooltipField: "role_name",
      filter: "CheckboxFilterComponent",
      minWidth: 200,
    },
    {
      headerName: "Cabinet ID",
      fullHeaderName: "Cabinet ID",
      field: "cabinetId",
      colId: "grid-cabinetId",
      tooltipField: "cabinetId",
      minWidth: 230,
      filter: "customTextFilter",
      // cellRendererFramework: "CabinetsIdRendererComponent",
      // cellRendererParams:{}
    }
  ]
  functionList: any =
    [
      {
        name: 'Username', value: 'username', checked: false, colId: "grid-username",
        tooltipField: "username", filter: "customTextFilter", headerName: "Username",
        fullHeaderName: "Username"
      },
      {
        name: 'First name', value: 'firstName', checked: false, colId: "grid-firstName",
        tooltipField: "firstName",
        filter: "customTextFilter",
        headerName: "First name",
        fullHeaderName: "First name"
      },
      {
        name: 'Last name', value: 'lastName', checked: false, colId: "grid-lastName",
        tooltipField: "lastName",
        filter: "customTextFilter",
        headerName: "Last name",
        fullHeaderName: "Last name"
      },
      {
        name: 'Role access', value: 'role_name', checked: false, colId: "grid-role_name",
        tooltipField: "role_name",
        filter: "CheckboxFilterComponent", headerName: "Role access",
        fullHeaderName: "Role access",
        customFilter: [
          {
            "elementData": "NBN Administrator"
          },
          {
            "elementData": "NBN Read & Write"
          },
          {
            "elementData": "NBN Read Only"
          }
        ]
      },
      {
        name: 'Cabinet ID', value: 'cabinetId', checked: false, colId: "grid-cabinetId",
        tooltipField: "cabinetId",
        filter: "customTextFilter", headerName: "Cabinet ID",
        fullHeaderName: "Cabinet ID",
      }
    ];
  public gridModel: any = {
    gridOptions: {},
    keysToSuppress: [13],
    labelAuthoring: {
      headerPageCount: 'Records',
      filter: '',
      resetFilter: '',
      paginationHeader: '',
      noData: '',
      noDataOnFilter: '',
      noDataOnSearch: '',
      toTop: ''
    }
  };
  public userNameModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblMyUserName',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxuserName',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divUserNameValidationErrMsg',
    placeholderCaption: '',
    id: 'userName',
    tabindex: '',
    maxlength: '64',
    headerText: '',
    headerTextAccessible: 'Search users',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'userName',
    formControlName: 'userName',
    isDisabled: false,
    showValidationIcon: false
  };

  public businessNameDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'businessNameHeader'
  };
  public aemLabelAuthoring: any;
  public filteredRowData: any = [];
  public searchForm: FormGroup;
  public businessRoles: any = [];
  public businessRolesList: any = [
    { name: 'NBNCo Users', key: 'nbnuser', cidn: CIDNS.NBNCO_CIDN },
    { name: 'Infraco Telstra Staff', key: 'infracouser', cidn: CIDNS.INFRACO_CIDN }
  ];
  roles = [];
  noData: string;
  noDataOnSearch: string;
  focusableElement: HTMLElement;
  constructor(
    private dataSvc: UsersService,
    private router: Router,
    private fb: FormBuilder,
    private utility: UtilityService,
    private commonService: CommonService,
    private homePageService: HomePageService,
    private authService: AuthService,
    private titleService: Title
  ) {
    this._setRoles();
    this.checkedList = [];
    this.titleService.setTitle('InfraCo Customer Portal - Manage cabinet-list');
  }

  ngOnInit() {
    this.commonService.showTopButtonInFooter(true);
    this.searchForm = this.fb.group({
      businessTypes: [''],
      userName: ['', [Validators.required, Validators.pattern(this.utility.getEmailRegEx())]]
    });
    this.searchForm.get('userName').valueChanges.subscribe(val => {
      this.userNameInvalidMessage = '';
      if (this.searchForm.controls['userName'].valid) {
        this.isValidUserName = true;
      } else {
        this.isValidUserName = false;
      }
      this.searchByUserBtnValidation();
    });
    this.searchForm.get('businessTypes').valueChanges.subscribe(val => {
      if (val) {
        this.isSelected = true;
        this.userNameInvalidMessage = '';
      }
    });
    const roles = this.commonService.getroleDetails().customerList;
    if (!roles.some((obj) => obj.role.includes('NBN') || obj.function.includes('NBN'))) {
      this.router.navigateByUrl('/access-denied');
    }
  }

  searchByUserBtnValidation() {
    if (this.isValidUserName) {
      this.isTextSearch = true;
    } else {
      this.isTextSearch = false;
    }
  }

  clearBusinessName($event) {
    this.searchByUserBtnValidation();
  }

  focusOnTop(event) {
    if (event) {
      let focusableElement;
      focusableElement = document.getElementById('new');
      if (focusableElement) {
        focusableElement.setAttribute('tabindex', '0');
        focusableElement.addEventListener('blur', () => {
          focusableElement.removeAttribute('tabindex');
        });
        setTimeout(() => {
          if (focusableElement) {
            focusableElement.focus();
          }
        }, 10);
      }
    }
  }

  getLabels() {
    return new Promise((resolve, reject) => {
      if (!this.infracoAdmin) {
        this.createGrid('');
      } else if (this.infracoAdmin) {
        this.businessRolesList.forEach(ele => {
          this.businessRoles.push(ele.name)
        });
      }
      this.dataSvc.getManageCabinetsModel(this.infracoAdmin).then(() => {
        this.aemLabelAuthoring = { ...this.dataSvc.gridLabelAuthoringDataCabinetObj };
        this.gridModel.labelAuthoring = { ...this.aemLabelAuthoring.form.grid };
        this.gridModel.labelAuthoring.icon = "";
        this.gridModel.labelAuthoring.createNew = "";
        this.userNameModel.placeholderCaption = this.aemLabelAuthoring.form.userName.placeholderCaption;
        this.userNameModel.validationMessage = this.aemLabelAuthoring.form.userName.validationMessage;
        if (!this.noDataOnSearch || !this.noData) {
          this.noDataOnSearch = this.gridModel.labelAuthoring.noDataOnSearch;
          this.noData = this.gridModel.labelAuthoring.noData;
        }
        resolve(true);
      }).catch((error) => {
        reject(false);
      });
    });
  }

  createGrid(value) {
    if (this.subscribedflag) {
      this.subscription.unsubscribe();
    } else {
      this.subscribedflag = true;
    }
    const promise1 = this.createColumnDefs();
    const promise2 = this.createRowData(value);
    this.subscription = forkJoin([promise1, promise2]).subscribe(result => {
      this.createGridConfig();
    }, error => {
      this.createGridConfig();
      this.router.navigateByUrl('/error');
    });
    this.dataSvc.existingUserData = '';
  }

  private createColumnDefs() {
    if (!this.infracoAdmin) {
      this.manageCabinetGridHeader.splice(3, 1)
    }
    return new Promise((resolve, reject) => {
      this.dataSvc.getCabinetGridHeader().then(() => {
        this.columnDefs = this.manageCabinetGridHeader;
        if (!this.infracoAdmin) {
          const index = this.columnDefs.findIndex(ele => ele.field == 'role_name');
          if (index !== -1) {
            this.columnDefs[index].hide = true;
          }
        }
        const businessNameIndex = this.columnDefs.findIndex(ele => ele.field == 'customerName');
        if (businessNameIndex !== -1) {
          this.columnDefs[businessNameIndex].hide = true;
        }
        resolve(true);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  private createRowData(userSearch) {
    const param = {};
    param['cabinetId'] = true;
    if (this.infracoAdmin) {
      if (userSearch && userSearch.businessTypes) {
        const role = this.businessRolesList.filter(ele => ele.name == userSearch.businessTypes);
        param['role'] = role[0].key;
        param['cidn'] = role[0].cidn;
      }
      if (userSearch && userSearch.userName) {
        param['userName'] = userSearch.userName;
      }
    } else {
      param['role'] = 'nbnuser';
      param['cidn'] = CIDNS.NBNCO_CIDN;
      if (userSearch && userSearch.userName) {
        param['userName'] = userSearch.userName;
      }
    }
    return new Promise((resolve, reject) => {
      this.dataSvc.getCabinetsGridData(param).then(() => {
        this.rowData = this.dataSvc.gridDataObj1;
        if (this.rowData.length > 0) {
          this.rowData.forEach((value, index) => {
            this.rowData[index].role_name = value.roles[0].role_name;
            this.rowData[index].role = value.roles[0].role;
            this.rowData[index].cidn = value.roles[0].cidn;
          });
        } else {
          if (userSearch.userName) {
            this.gridModel.labelAuthoring.noDataOnSearch = this.noDataOnSearch;
          } else {
            this.gridModel.labelAuthoring.noDataOnSearch = this.noData;
          }
        }
        resolve(true);
      }).catch((err) => {
        this.rowData = [];
        if (userSearch) {
          this.gridModel.labelAuthoring.noDataOnSearch = this.noDataOnSearch;
        } else {
          this.gridModel.labelAuthoring.noDataOnSearch = this.noData;
        }
        reject(err);
      });
    });
  }

  resetGrid(payload) {
    this.gridInitialized = false;
    this.createGrid(null);
  }

  createGridConfig() {
    if (this.infracoAdmin) {
      const userRoles = [];
      const filterRoleOptions = [];
      this.rowData.forEach(val => {
        if (userRoles.indexOf(val.role) === -1) {
          userRoles.push(val.role);
          filterRoleOptions.push({ 'elementData': val.role_name });
        }
      });

      const uniqueFilterRoleOptions = filterRoleOptions.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.elementData === value.elementData)
      );

      const index = this.columnDefs.findIndex(ele => ele.field == 'role_name');
      if (index !== -1) {
        this.columnDefs[index].customFilter = uniqueFilterRoleOptions;
      }
    }
    this.gridConfig = <GridOptions>{};
    this.gridConfig.rowData = this.rowData;
    this.gridConfig.paginationPageSize = 10;
    this.gridConfig.columnDefs = this.columnDefs;
    this.gridConfig.suppressHorizontalScroll = true;
    this.gridConfig.pagination = true;
    this.gridConfig.rowSelection = 'single';
    this.gridInitialized = true;
    this.gridConfig.frameworkComponents = {
      CheckboxFilterComponent: <any>CheckboxFilterComponent,
      customTextFilter: <any>CustomTextFilterComponent,
      customDateFilter: <any>CustomDateFilterComponent
    };
    this.gridModel.gridOptions = this.gridConfig;
  }

  onRowClicked(arg) {
    this.navigateToModify(arg.data);
  }

  onFilterSelected(filteredData) {
    this.filteredRowData = [];
    if (filteredData.filteredRowData && filteredData.filteredRowData.length > 0) {
      filteredData.filteredRowData.forEach(row => {
        if (row.data) {
          this.filteredRowData.push(row.data);
        }
      });
    }
  }

  navigateToCreateUser() {
  }

  ngOnDestroy() {
    this.commonService.showTopButtonInFooter(false);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  searchBusinessData(form) {
    this.gridInitialized = false;
    this.isSearched = true;
    this.createGrid(form.form.value);
  }

  resetSearch() {
    this.isSearched = false;
    this.isTextSearch = false;
    this.isValidUserName = false;
    this.searchForm.reset();
    this.searchForm.markAsUntouched();
    this.isSelected = false;
    this.gridInitialized = false;
    this.isSearched = false;
    if (!this.infracoAdmin) {
      this.createGrid('');
    }
  }

  navigateToModify(data) {
    window.scroll(0, 0);
    this.dataSvc.setUserDetailsData(data);
    this.router.navigate(['manage-cabinet-access']);
  }

  checkSearchBtnDisable() {
    if (this.infracoAdmin) {
      if (this.searchForm.controls.businessTypes.value) {
        if (this.isTextSearch && this.searchForm.controls.userName.value) {
          return false;
        } else if (!this.isTextSearch && this.searchForm.controls.userName.value) {
          return true
        } else {
          return false;
        }
      }
    } else {
      if (this.isTextSearch) return false;
    }
    return true;
  }

  private _setRoles() {
    const roles = this.commonService.getroleDetails();
    this.roles = roles.customerList;
    const userName = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail'];
    this.homePageService.getUserProfile(userName).then((response) => {
      const customerDetails = response?.data?.customerDetails;
      customerDetails?.forEach(element => {
        if ((element.customerName.toUpperCase() == 'INFRACO') && this.roles?.some((a) => (a?.role === 'NBN Administrator' && a?.function === 'NBN Cabinet Access Management'))) {
          this.infracoAdmin = true;
          this.userNameModel.isMandatory = false;
          this.getLabels();
        } else if ((element.customerName == 'NBNCo') && this.roles?.some((a) => (a?.role === 'NBN Administrator' && a?.function === 'NBN Cabinet Access Management'))) {
          this.infracoAdmin = false;
          this.getLabels();
        } else {
          this.router.navigateByUrl('/access-denied');
        }
      });
    });
  }

  showPop() {
    this.showFilterPop = true;
    document.getElementById('colPrefTabMg').style.display = 'block';
    setTimeout(() => {
      this.focusableElement = document.getElementById('fir');
      this.focusableElement.focus();
    }, 1);
    this.commonService.trapFocusInModal('fir', 'rmv');
  }

  closePopup() {
    this.showFilterPop = false;
  }

  leaveColPref() {
    document.getElementById('colPrefTabMg').style.display = 'none';
  }

  getSelectedValue(status: Boolean, value: String, colID: string, tooltipField: string, filter: string, headerName: string, fullHeaderName: string, customFilter: any) {
    if (status) {
      this.currentSelected = { field: value, colID: colID, tooltipField: tooltipField, filter: filter, headerName: headerName, fullHeaderName: fullHeaderName, customFilter: customFilter, width: 260, minWidth: 230, maxWidth: 250 };
      this.checkedList.push(this.currentSelected);
    } else {
      const index = this.checkedList.findIndex(obj => obj.field === value);
      if (index !== -1) {
        this.checkedList.splice(index, 1);
      }
    }
    this.shareCheckedlist();
  }

  shareCheckedlist() {
    this.colDefsAthleteEXcluded = [
      this.checkedList[0]
    ]
    this.gridModel.gridOptions.api.setColumnDefs(this.colDefsAthleteEXcluded);
    if (this.checkedList[1]) {
      this.colDefsAthleteEXcluded2 = [
        this.checkedList[0],
        this.checkedList[1]
      ]
      this.gridModel.gridOptions.api.setColumnDefs(this.colDefsAthleteEXcluded2);
    }
    if (this.checkedList[2]) {
      this.colDefsAthleteEXcluded3 = [
        this.checkedList[0],
        this.checkedList[1],
        this.checkedList[2]
      ]
      this.gridModel.gridOptions.api.setColumnDefs(this.colDefsAthleteEXcluded3);
    }
    if (this.checkedList[3]) {
      this.colDefsAthleteEXcluded4 = [
        this.checkedList[0],
        this.checkedList[1],
        this.checkedList[2],
        this.checkedList[3]
      ]
      this.gridModel.gridOptions.api.setColumnDefs(this.colDefsAthleteEXcluded4);
    }
    if (this.checkedList[4]) {
      this.colDefsAthleteEXcluded4 = [
        this.checkedList[0],
        this.checkedList[1],
        this.checkedList[2],
        this.checkedList[3],
        this.checkedList[4]
      ]
      this.gridModel.gridOptions.api.setColumnDefs(this.colDefsAthleteEXcluded4);
    }
  }
}

