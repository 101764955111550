import { Component, OnInit } from '@angular/core';
import { SuccessModalWithCopyLinkService } from './success-modal-with-copy-link.service';
import {Clipboard} from '@angular/cdk/clipboard';
import { CommonService } from 'src/app/shared/services/common.service';
import { CabinetsService } from '../../cabinets.service';

@Component({
  selector: 'app-success-modal-with-copy-link',
  templateUrl: './success-modal-with-copy-link.component.html',
  styleUrls: ['./success-modal-with-copy-link.component.less']
})
export class SuccessModalWithCopyLinkComponent implements OnInit {

  content: any
  focusableElement: HTMLElement;

  constructor(
    private clipboard: Clipboard,
    private successModalWithCopyLinkService: SuccessModalWithCopyLinkService,
    private commonService:CommonService,
    private cabinetsService: CabinetsService
  ) { }

  ngOnInit(): void {
    this.cabinetsService.fetchError(null);
    this.successModalWithCopyLinkService.contentObs
      .subscribe(val => {
        this.content = val;
      }); 
      setTimeout(() => {
        this.focusableElement = document.getElementById('success-modal-with-copy-link');
        if(this.focusableElement){
        this.focusableElement.focus();
        }
      }, 1);
      this.commonService.trapFocusInModal('close','copy');
  }

  remove() {
    this.successModalWithCopyLinkService.remove()
  }

  copyLink() {
    this.clipboard.copy(this.content.link);
    this.content.toastrMsg = 'Link copied';
  }
}
