import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterContentInit, Output, EventEmitter, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomTextField, CustomDropDown } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/shared/services/common.service';
import { CustomerService } from '../customer-list/customer.service';
import { ToastContainerDirective, ToastrService, GlobalConfig } from 'ngx-toastr';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-modify-customer',
  templateUrl: './modify-customer.component.html',
  styleUrls: ['./modify-customer.component.css']
})

export class ModifyCustomerComponent implements OnInit, OnDestroy, AfterContentInit {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  public modifyCustomerForm: FormGroup;
  public customerTypes: string[] = ["Agent", "Direct", "Reseller"];
  public customerChannels: string[] = ["Amplitel", "Telstra Enterprise", "Telstra International", "Telstra Limited", "Telstra Wholesale", "External Direct"];
  public contactTypes: string[] = ["Primary", "Alternate"];
  public primaryContactType: string[] = ["Primary"];
  public alternateContactType: string[] = ["Alternate"];
  public linkedCIDNs;
  public isSecondaryContact: boolean = false;
  public rowData: any = [];
  public enableSaveCidn: boolean = false;
  public isAuditModalOpen: boolean = false;
  public profileTableColumn = ['Customer name', 'Linked CIDN'];
  @ViewChild('myModal') modal!: ElementRef;
  @ViewChild("cntct") cntct: ElementRef;
  options: GlobalConfig;
  public customerData: any = null;
  public responseData: any = null;
  public aemLabelAuthoring: any = null;
  showRemoveAlert: boolean = false;
  statusDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Status Required Dropdown.Press Enter/return to expand the Dropdown and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  statuses = [
    "Active",
    "Inactive"
  ]
  disableSave: boolean = true;
  @Output() moveTab = new EventEmitter<void>();
  public customerTypeDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Customer type Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  public customerChannelDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Customer channel Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  public contactTypeDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Contact type Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  public contactTypeDropDownModelSec: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Contact type Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  public linkedCIDNDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Linked customer identifier (e.g., CIDN) Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  public customerNameModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblname',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxname',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divCheckServiceValidationErrMsg',
    placeholderCaption: 'Enter customer name',
    id: 'customerName',
    tabindex: '0',
    maxlength: '255',
    headerText: '',
    headerTextAccessible: 'customer Name required',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'customerName',
    formControlName: 'customerName',
    isDisabled: true,
    showValidationIcon: false
  };
  public primaryCIDNModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblcidn',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxcidn',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divCheckServiceValidationErrMsg',
    placeholderCaption: 'Enter primary CIDN',
    id: 'primaryCIDN',
    tabindex: '0',
    maxlength: '255',
    headerText: '',
    headerTextAccessible: 'Customer identifier example CIDN required',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'primaryCIDN',
    formControlName: 'primaryCIDN',
    isDisabled: true,
    showValidationIcon: false
  };
  public contactNameModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblename',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxname',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divCheckServiceValidationErrMsg',
    placeholderCaption: 'Enter contact name',
    id: 'contactName',
    tabindex: '0',
    maxlength: '255',
    headerText: '',
    headerTextAccessible: 'contact Name required',
    inputText: '',
    requiredText: '',
    validationMessage: '',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'contactName',
    formControlName: 'contactName',
    isDisabled: false,
    showValidationIcon: false
  };
  public contactNameModelSec: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblename',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxname',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divCheckServiceValidationErrMsg',
    placeholderCaption: 'Enter contact name',
    id: 'contactNameSec',
    tabindex: '0',
    maxlength: '255',
    headerText: '',
    headerTextAccessible: 'contact Name',
    inputText: '',
    requiredText: 'Please enter contact name',
    validationMessage: 'Please enter values except ,`=~#!<>+@e',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'contactNameSec',
    formControlName: 'contactNameSec',
    isDisabled: false,
    showValidationIcon: false
  };

  constructor(
    private fb: FormBuilder,
    private route: Router,
    public commonservice: CommonService,
    private modalService: NgbModal,
    private customerService: CustomerService,
    private toastr: ToastrService,
    private utility: UtilityService,
    private titleService: Title
  ) {
    this.options = this.toastr.toastrConfig;
  }

  async ngOnInit() {
    const CUSTOMER_DATA = this.customerService.getCreateCustomerData();
    this.customerData = CUSTOMER_DATA;
    this.customerService.getLinkedCIDNs().then(res => {
      this.linkedCIDNs = this.getBusinessList1(res);
    }).catch((err) => {
    }).then(() => {
      // once this.customerData and this.linkedCIDNs is populated, need to remove the primary cidn from the linked cidn options
      this.filterPrimaryCidn();
    });
    this.createForm();
    this.getLabels();
    const cidnList = this.customerData?.linkedCidn?.split(',');

    let row;
    this.customerService.getLinkedCIDNs().then(res => {
      const noEmptyCidn = cidnList.filter((str) => str !== '')
      if (noEmptyCidn && noEmptyCidn.length > 0) {
        for (const cidn of cidnList) {
          const matchedCidnObj = this.getBusinessList(res)?.find(e => e.cidn === cidn);
          row = {
            customerName: matchedCidnObj?.businessName ? matchedCidnObj.businessName : null,
            linkedCIDN: cidn,
            //customerData:cidn.modifiedDetails.replace(/,/g,'')
          }
          this.rowData.push(row);
        }
      } else {
        this.rowData = [];
      }
    }).catch((err) => {
    });
    this.titleService.setTitle('Modify Customer Screen');
  }

  ngAfterContentInit() {
  }

  filterPrimaryCidn() {
    if (this.customerData) {
      for (var i = 0; i < this.linkedCIDNs.length; i++) {
        if (this.linkedCIDNs[i].includes(this.customerData.primaryCidn) && 
          (this.linkedCIDNs[i].includes(this.customerData.customerName))) {
            // remove the entry generated by getBusinessList1() from the linked CIDN dropdown list
            this.linkedCIDNs.splice(i, 1);
        }
      }
    }
  }

  createForm() {
    if (this.customerData) {
      this.customerNameModel.inputText = this.customerData ? this.customerData.customerName : '';
      this.primaryCIDNModel.inputText = this.customerData ? this.customerData.primaryCidn : '';
      this.contactNameModel.inputText = this.customerData?.contactDetails[0]?.contactName ? this.customerData?.contactDetails[0]?.contactName : '';
      this.contactNameModelSec.inputText = this.customerData?.contactDetails[1]?.contactName ? this.customerData?.contactDetails[1]?.contactName : '';
    }
    let channel;
    if (this.customerData?.customerType === 'Direct') {
      this.customerChannels = ["External Direct", "Telstra Limited", "Telstra International", "Amplitel"];
    } else {
      this.customerChannels = ["Amplitel", "Telstra Enterprise", "Telstra International", "Telstra Wholesale"];
    }
    channel = this.customerData?.customerChannel;

    this.modifyCustomerForm = this.fb.group({
      customerName: [this.customerNameModel.inputText, [Validators.required, Validators.pattern(this.utility.getNameRegEx())]],
      primaryCIDN: [this.primaryCIDNModel.inputText, [Validators.required, Validators.pattern(this.utility.getCidnRegEx())]],
      contactName: [this.contactNameModel.inputText, [Validators.required, Validators.pattern(this.utility.getNameRegEx())]],
      contactNumber: [this.customerData?.contactDetails[0]?.contactNumber, [Validators.required, Validators.pattern(this.utility.getNumberRegEx())]],
      contactEmail: [this.customerData?.contactDetails[0]?.contactEmail, [Validators.required, Validators.pattern(this.utility.getEmailRegEx())]],
      customerType: [this.customerData?.customerType, [Validators.required]],
      linkedCIDN: [''],
      customerChannel: [channel, [Validators.required]],
      contactType: [this.customerData?.contactDetails[0]?.contactType, [Validators.required]],
      contactTypeSec: [this.customerData?.contactDetails[1]?.contactType ? 'Alternate' : null, []],
      contactNameSec: [this.contactNameModelSec.inputText, []],
      contactNumberSec: [this.customerData?.contactDetails[1]?.contactNumber ? this.customerData?.contactDetails[1]?.contactNumber : null, []],
      contactEmailSec: [this.customerData?.contactDetails[1]?.contactEmail ? this.customerData?.contactDetails[1]?.contactEmail : null, []],
      status: [this.customerData?.status ? this.customerData?.status : this.statuses[0], [Validators.required]]
    });

    if (this.customerData?.contactDetails[1] && this.customerData?.contactDetails[1]?.contactName && this.customerData?.contactDetails[1]?.contactNumber) {
      this.addContact();
    } else {
      this.isSecondaryContact = false;
    }
  }

  getLabels() {
    this.customerService.getCreateMyUserModel().then((data: any) => {
      this.aemLabelAuthoring = data;
      this.customerNameModel.placeholderCaption = this.aemLabelAuthoring.form.businessNamePlaceholder;
      this.customerNameModel.validationMessage = this.aemLabelAuthoring.form.businessNameValidationMessage;
      this.customerNameModel.requiredText = this.aemLabelAuthoring.form.businessNameRequiredMessage;
      this.primaryCIDNModel.placeholderCaption = this.aemLabelAuthoring.form.cidnPlaceholder;
      this.primaryCIDNModel.validationMessage = this.aemLabelAuthoring.form.cidnValidationMessage;
      this.primaryCIDNModel.requiredText = this.aemLabelAuthoring.form.cidnRequiredMessage;
      this.contactNameModel.placeholderCaption = this.aemLabelAuthoring.form.contactNamePlaceholder;
      this.contactNameModel.validationMessage = this.aemLabelAuthoring.form.contactNameValidationMessage;
      this.contactNameModel.requiredText = this.aemLabelAuthoring.form.contactNameRequiredMessage;
      this.contactNameModelSec.placeholderCaption = this.aemLabelAuthoring.form.contactNamePlaceholder;
      this.contactNameModelSec.validationMessage = this.aemLabelAuthoring.form.contactNameValidationMessage;
      this.contactNameModelSec.requiredText = this.aemLabelAuthoring.form.contactNameRequiredMessage;
    }).catch((error) => {
    });
  }

  cidnChange() {
    const selectedCidn = this.modifyCustomerForm.get('linkedCIDN').value.split('-')[0];
    const primaryCidn = this.modifyCustomerForm.get('primaryCIDN').value;
    const cidnList = this.rowData.map(function (el) { return el.linkedCIDN; });
    cidnList.push(primaryCidn);
    if (cidnList.includes(selectedCidn)) {
      this.enableSaveCidn = false;
    } else {
      this.enableSaveCidn = true;
    }
  }

  saveCIDN = (): void => {
    const selectedCidn = this.modifyCustomerForm.get('linkedCIDN').value.split('-')[0];
    this.customerService.getLinkedCIDNs().then(res => {
      const selectedArray = this.getBusinessList(res).find(e => e.cidn === selectedCidn);
      this.rowData.push({
        customerName: selectedArray.businessName,
        linkedCIDN: selectedArray.cidn
      });
    }).catch((err) => {
    });
    this.modifyCustomerForm.get('linkedCIDN').setValue('');
    this.enableSaveCidn = false;
  }

  getBusinessList(res) {
    let businessList = [];
    const iteratedArr = res.data.businessNameDetails.map(({ bussinessName, cidn }) => [bussinessName, cidn]);
    for (var i = 0; i < iteratedArr.length; i++) {
      const businessName = iteratedArr[i][0];
      const cidn = iteratedArr[i][1];
      if (businessName || cidn) {
        businessList.push({ businessName: businessName, cidn: cidn });
      }
    }
    return businessList;
  }

  getBusinessList1(res) {
    let businessList = [];
    const iteratedArr = res.data.businessNameDetails.map(({ bussinessName, cidn }) => [bussinessName, cidn]);
    for (var i = 0; i < iteratedArr.length; i++) {
      const businessName = iteratedArr[i][0];
      const cidn = iteratedArr[i][1];
      if (businessName || cidn) {
        businessList.push(cidn + '-' + businessName);
      }
    }
    return businessList;
  }

  public cancelCIDN = (): void => {
    this.modifyCustomerForm.controls['linkedCIDN'].setValue(null);
  }

  public cancelBtnClick = (): void => {
    this.moveTab.emit();
    this.toastr.clear();
  }

  public addContact = (): void => {
    this.isSecondaryContact = true;
    if (this.modifyCustomerForm) {
      this.modifyCustomerForm.get('contactTypeSec').setValue('Alternate');
      this.modifyCustomerForm.get('contactTypeSec').setValidators([Validators.required])
      this.modifyCustomerForm.get('contactNameSec').setValidators([Validators.required, Validators.pattern(this.utility.getNameRegEx())])
      this.modifyCustomerForm.get('contactNumberSec').setValidators([Validators.required, Validators.pattern(this.utility.getNumberRegEx())])
      this.modifyCustomerForm.get('contactEmailSec').setValidators([Validators.required, Validators.pattern(this.utility.getEmailRegEx())])
      setTimeout(() => this.cntct?.nativeElement.focus(), 1);
    }
  }

  public openAuditModal = (): void => {
    this.isAuditModalOpen = true;
  }

  public onClickClose(event) {
    this.isAuditModalOpen = false;
  }

  ngOnDestroy() {
    this.modalService.dismissAll();
    this.isAuditModalOpen = false;
  }

  public customerTypeChange(channel) {
    if (channel === 'Direct') {
      this.modifyCustomerForm.controls['customerChannel'].setValue('External Direct');
      this.customerChannels = ["External Direct", "Telstra Limited", "Telstra International", "Amplitel"];
    }
    else {
      this.modifyCustomerForm.controls['customerChannel'].setValue('');
      this.customerChannels = ["Amplitel", "Telstra Enterprise", "Telstra International", "Telstra Wholesale"];
    }
  }


  removeCustomerDetails(index) {
    this.modifyCustomerForm.markAsDirty();
    if (index > -1) {
      this.rowData.splice(index, 1);
    }
    this.showRemoveAlert = true;
  }


  removeSecondaryContact() {
    this.isSecondaryContact = false;
    this.modifyCustomerForm.get('contactNameSec').reset();
    this.modifyCustomerForm.get('contactNumberSec').reset();
    this.modifyCustomerForm.get('contactEmailSec').reset();
    this.modifyCustomerForm.get('contactTypeSec').clearValidators();
    this.modifyCustomerForm.get('contactNameSec').clearValidators();
    this.modifyCustomerForm.get('contactNumberSec').clearValidators();
    this.modifyCustomerForm.get('contactEmailSec').clearValidators();
    this.modifyCustomerForm.get('contactTypeSec').updateValueAndValidity()
    this.modifyCustomerForm.get('contactNameSec').updateValueAndValidity();
    this.modifyCustomerForm.get('contactNumberSec').updateValueAndValidity();
    this.modifyCustomerForm.get('contactEmailSec').updateValueAndValidity();
    this.modifyCustomerForm.markAsDirty();
  }

  onSubmit(): any {
    const DATA = this.modifyCustomerForm.value;
    const cidns = this.rowData.map(({ linkedCIDN }) => linkedCIDN);
    this.customerService.modifyCustomer(DATA, cidns, this.isSecondaryContact).then((response) => {
      this.customerService.createCustomerFormData = this.modifyCustomerForm.value;
      this.customerService.createCustomerFormData['page'] = 'Modify';
      // TO RESET HEADER DROPDOWN CUSTOMER LIST
      this.commonservice.setHeaderCustomerDropdown(true);
      this.moveTab.emit();
      this.showToasterMessage(this.aemLabelAuthoring.form.modifySuccessMsg, '', this.options, 'success');
    }).catch((err) => {
      const msg = err.error.message ? err.error.message : 'Something went wrong';
      this.showToasterMessage(msg, '', this.options, 'error');
    });
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    /* this.options.positionClass = 'toast-bottom-right'; */
    this.options.positionClass = 'toast-top-center';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 5000;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyUp(event) {
    if (event.keyCode === 27) {
      this.isAuditModalOpen = false;
      this.modalService.dismissAll();
    }
  }

}
