<div class="container-fluid duct">
<div class="row">
<h1 id="duct" class="col-12  page-heading">
  Raise a new duct access request
</h1>
<span class="accLabel">Fields marked with <span class="star">*</span> are mandatory</span>
</div>
<div class="toastrContainer" aria-live="polite" role="alert" toastContainer></div>
<div class="container-fluid page-container">
  <form [formGroup]="ductAccessForm">
    <mat-horizontal-stepper [linear]="isLinear" #stepper>
      <mat-step [stepControl]="firstFormGroup" formGroupName="firstForm" [completed]="false">
        <ng-template matStepLabel>Company & customer details</ng-template>
        <app-company-customer-details></app-company-customer-details>
        <div class="submitCancelBtn btnRow">
          <button mat-button (click)="cancel(stepper)" class="cancelBtn" tabindex="0"
            [disabled]="ductAccessForm.controls.firstForm.pristine">
            <i class="icon-icon-ui-cross"></i>Cancel</button>
          <button mat-button matStepperNext class="app-btn-green" (click)="updateStudyTypeValue()"
          [disabled]="ductAccessForm.controls.firstForm.invalid">Next</button>          
        </div>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup" formGroupName="secondForm" [completed]="false">
        <ng-template matStepLabel>Address details</ng-template>
        <app-address-details [studyTypeValue]="studyTypeSelectedValue"></app-address-details>
        <div class="submitCancelBtn btnRow">
          <button mat-button (click)="cancel(stepper)" class="cancelBtn"
            [disabled]="ductAccessForm.controls.secondForm.pristine">
            <i class="icon-icon-ui-cross"></i>Cancel</button>
          <button mat-button matStepperNext class="saveBtn"
            [ngClass]="{'enableSaveBtn': !ductAccessForm.controls.secondForm.invalid}"
            [ngStyle]="{'cursor': !ductAccessForm.controls.secondForm.invalid ? 'pointer' : 'not-allowed' }"
            [disabled]="ductAccessForm.controls.secondForm.invalid">Next</button>
        </div>
      </mat-step>
      <mat-step [stepControl]="thirdFormGroup" formGroupName="thirdForm" [completed]="false">
        <ng-template matStepLabel>Attachments & other requirements</ng-template>
        <app-attachment-requirement></app-attachment-requirement>
        <div class="submitCancelBtn btnRow">
          <button class="cancelBtn" (click)="cancel(stepper)" tabindex="0"
            [disabled]="ductAccessForm.controls.thirdForm.pristine">
            <i class="icon-icon-ui-cross"></i>Cancel</button>
          <button class="saveBtn"
            [disabled]="ductAccessForm.controls.thirdForm.invalid || thirdForm.controls.validationError.value"
            (click)="onSub(); move(0)" value="Submit" type="submit"
            [ngClass]="{'enableSaveBtn': !(ductAccessForm.controls.thirdForm.invalid || thirdForm.controls.validationError.value)}"
            tabindex="0">Submit</button>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </form>
</div>
</div>