import {
  AfterContentInit,
  AfterViewChecked,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'tw-core-ui';
import { CustomerService } from '../admin-settings/customer-list/customer.service';
import { CommonService } from '../shared/services/common.service';
import { HomePageService } from '../shared/services/homePage.service';
import { Title } from '@angular/platform-browser';

export interface DialogData {
  feasibilityType: any;  
  status: any;
  sysID: any;
  name: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnDestroy, AfterContentInit {
  name: string = '';
  toShowPop = false;
  @ViewChild('myModal') modal!: ElementRef;
  userDate: any = {};
  defaultCustomer: any = {};
  dropdownArray: any[] = [];
  public businessNames: any[] = [];
  userName: any = '';
  nbnInfracoAdmin: boolean;
  nbnUser: boolean;
  nonNbnUser: boolean;
  roles = [];
  private env: any = window['environment'];
  public isNBN = undefined;
  private nbn: boolean = false;
  isDuctRole: boolean = false;

  constructor(
    private commonservice: CommonService,
    private modalService: NgbModal,
    private homepageservice: HomePageService,
    private authService: AuthService,
    private customerService: CustomerService,
    private titleService: Title
  ) {
    this.titleService.setTitle('InfraCo Customer Portal - Home Page');
  }

  async ngOnInit() {
    this.isNBN = await this.commonservice.getroleDetails().customerList.some((obj) => obj.role.includes('NBN') || obj.function.includes('NBN'));
    this.isDuctRole = this.commonservice.getDuctRoleDetails().hasAllDuctRoles;
    if (this.isDuctRole) {
      this.commonservice.showTopButtonInFooter(true);
    }
    this.getLabels();
   this.getProfileDetails();
  }

  ngAfterContentInit() {

  }

  getLabels = () => {
    return new Promise((resolve, reject) => {
      this.homepageservice
        .getLabels()
        .then(() => {
          resolve(true);
        })
        .catch(() => { });
    });
  };

  getProfileDetails = () => {
    this.userName = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail'];
    this.homepageservice.getUserProfile(this.userName).then((response) => {
      this.defaultCustomer = response.data?.preferenceReference;
      this.dropdownArray = response.data?.customerDetails;
      this.dropdownArray.forEach((element) => {
        this.businessNames.push(element.customerName);
      });
      this.getPreferencesDetails();
      this.getRoleDetails();
    })
      .catch((err) => {
      });
  };

  getPreferencesDetails = () => {
    this.userName = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail']
    this.homepageservice.getPreferences(this.userName).then(response => {
      //check if multiple customers are there
      if (this.dropdownArray.length === 1) {
        this.toShowPop = false;
        this.modalService.dismissAll();
        const saved = this.commonservice.getSavePrefSingleCust();
        if (!saved) {
          this.commonservice.setProfileDefaultCustomer(this.businessNames[0]);
          this.save();
        }
      } else {
        // matching existing preferences with hubpreferences
        const hubPreference = response.data?.hubPreferences ? response.data.hubPreferences : [];
        hubPreference.forEach(hub => {
          this.defaultCustomer.forEach(preference => {
            if (hub.preferenceType === preference.preferenceType &&
              hub.preferenceCategory === preference.preferenceCategory) {
              if (hub.preference === null || hub.preference === '') {
                this.toShowPop = true;
              } else {
                this.toShowPop = false;
                this.modalService.dismissAll();
                const headerReset = this.commonservice.getHeaderValueChangeTrace();
                if (!headerReset) {
                  this.commonservice.setDefaultCustomer(hub.preference);
                }
                sessionStorage.setItem('hubPref', hub.preference);
                this.commonservice.setProfileDefaultCustomer(hub.preference);
              }
            }
          });
        });
      }

      //storing data for customer list for popup and profile page
      let data = {
        dataList: this.defaultCustomer,
        dropdownOptions: this.businessNames,
        allCustomerList: this.dropdownArray,
      };
      this.commonservice.setDefaultCustomerObject(JSON.stringify(data));

      if (this.toShowPop) {
        if (JSON.parse(this.commonservice.getDefaultCustomerObject())) {
          const modalRef = this.modalService.open(this.modal);
        }
      }
    })
      .catch((err) => {
      });
  };

  save(): void {
    const payload = {
      userId: this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail'],
      preferenceType: this.defaultCustomer[0]?.preferenceType, //"defaultcustomer",
      preference: this.businessNames[0],
      preferenceCategory: this.defaultCustomer[0]?.preferenceCategory, //"hub_preference",
      status: this.defaultCustomer[0]?.status,
      deliveryChannel: this.defaultCustomer[0]?.deliveryChannel,
    };
    this.homepageservice
      .putPreferences(payload)
      .then((response) => {
        const headerReset = this.commonservice.getHeaderValueChangeTrace();
        if (!headerReset) {
          this.commonservice.setDefaultCustomer(this.businessNames[0]);
        }
      })
      .catch((err) => {
      });

    this.modalService.dismissAll();
  }

  getRoleDetails() {
    if ((this.businessNames[0].toUpperCase() == 'INFRACO') && this.isNBN) { //nbn infaco
      this.nbnInfracoAdmin = true;
    } else if ((this.businessNames[0] == 'NBNCo') && this.isNBN) { //nbn nbnco
      this.nbnUser = true;
    } else { //non-nbn
      this.nonNbnUser = true;
    }
  }

  ngOnDestroy() {
    this.commonservice.showTopButtonInFooter(false);
    this.modalService.dismissAll();
    this.toShowPop = false;
  }
}
