<div class="text-center mt-md-4 px-4 d-flex
          align-items-center  justify-content-between  slider-container  mb-md-3" aria-label="Steps">
    <div class="slider-item" *ngFor="let step of stepperData; let i=index;">
        <div class="step-circle d-inline-block" attr.aria-label="step {{i+1}}" [ngClass]="{'step-on' : stepperData.indexOf(stepName) >=i}">
            <div class="step-number">{{i+1}}</div>
        </div>
        <p class="m-0 fs-14">
            {{step}}
        </p>
    </div>
</div>
