import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DuctAccessService } from './duct-access.service';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { MatStepper } from '@angular/material/stepper';
import { AuthService } from 'tw-core-ui';
import { UtilityService } from '../shared/services/utility.service';
import { ProfileService } from '../profile/profile.service';
import { CommonService } from '../shared/services/common.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-duct-access-form',
  templateUrl: './duct-access-form.component.html',
  styleUrls: ['./duct-access-form.component.less']
})
export class DuctAccessFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  @ViewChild('stepper') stepper: MatStepper;
  isLinear = false;
  isFormSubmitted: boolean;
  fileToUpload: FileList = null;
  ductAccessForm: FormGroup;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  thirdForm: FormGroup;
  submitted: boolean = null;
  options: GlobalConfig;
  studyTypeSelectedValue: String;
  fileAsBase64: any;

  constructor(private ductService: DuctAccessService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthService,
    private utility: UtilityService,
    private profileService : ProfileService,
    private commonService: CommonService,
    private titleService: Title) {
      this.titleService.setTitle('InfraCo Customer Portal - Duct Access Form');
  }

  ngOnInit(): void {
    this.ductAccessForm = this.fb.group({
      firstForm: this.fb.group({
        companyName: ['', [Validators.required]],
        date: new FormControl(),
        study: ['', [Validators.required]],
        telstraReservID: [""],
        contactName: ["", [Validators.required, Validators.pattern(this.utility.getNameRegEx())]],
        contactNumber: ["", [Validators.required,Validators.pattern(this.utility.getNumberRegEx())]],
        contactEmail: ["", [Validators.required,Validators.pattern(this.utility.getEmailRegEx())]],
        ipoContactName: ["", [Validators.required, Validators.pattern(this.utility.getNameRegEx())]],
        ipoContactNum: ["", [Validators.required,,Validators.pattern(this.utility.getNumberRegEx())]],
        ipoContactEmail: ["", [Validators.required,Validators.pattern(this.utility.getEmailRegEx())]],
        isChecked: [false],
        studyType: ['', [Validators.required]]
      }),
      secondForm: this.fb.group({
        additionalEnds: this.fb.array([this.initAddEndRows()]),
        totalMeters: [0, [Validators.pattern(this.utility.getPositiveNumberRegEx())]],
        bareFibre: [0, [Validators.pattern(this.utility.getPositiveNumberRegEx())]],
        subduct: [0, [Validators.pattern(this.utility.getPositiveNumberRegEx())]],
        tunnel: [""],
        teba: [""],
        termofFacility: [15, [Validators.required,Validators.pattern(this.utility.getPositiveNumberRegEx())]],
        breakouts: [0, [Validators.pattern(this.utility.getPositiveNumberRegEx())]],
        splices: [0, [Validators.pattern(this.utility.getPositiveNumberRegEx())]],
        loops: [0, [Validators.pattern(this.utility.getPositiveNumberRegEx())]],
        leadIn: [""],
        address: [""],
        fibre: [""],
        spclConditionText: new FormControl(''),
        ductingRadioValue: new FormControl(''),
        ductingText: new FormControl(''),
        accessRadioValue: new FormControl(''),
        lengthOfCable: ['',[Validators.pattern(this.utility.getPositiveNumberRegEx())]],
        oftuRadioValue: new FormControl(''),
        oftuText: new FormControl(''),
        numberOfCable: ['',[Validators.pattern(this.utility.getPositiveNumberRegEx())]],
        customerCableLengthRadioValue: new FormControl(''),
        customerCableLengthText: new FormControl(''),
        rackLocation: [''],
        optionsOFTU: [''],
        fCable: [''],
        fCableLocFrom: [''],
        fCableLocTo: [''],
        ofCable: [''],
        subductManType: [''],
        cableManName: [''],
        cableType: [''],
        radioValue: new FormControl('', Validators.required)
      }),
      thirdForm: this.fb.group({
        surveyingCompany: [""],
        surveyorName: [''],
        surveyorContactNumber: ["",[Validators.pattern(this.utility.getNumberRegEx())]],
        civilsCompany: [""],
        civilsContactName: [""],
        civilsContactNumber: ["",[Validators.pattern(this.utility.getNumberRegEx())]],
        haulingCompany: [""],
        haulingContactName: [""],
        haulingContactNumber: ["",[Validators.pattern(this.utility.getNumberRegEx())]],
        file: [""],
        fileName: [''],
        validationError: [''],
        saci: [false],
        saciCompany: [""],
        contactNameSaci: [""],
        contactNumberSaci: [""],
        termsCheck: [false, Validators.requiredTrue]
      })
    });
    this.thirdForm = this.ductAccessForm.controls['thirdForm'] as FormGroup;
    this.setCustomerDetails();
    this.commonService.showTopButtonInFooter(true);

  }

  ngAfterViewInit(): void {
    this.removeAccessibilityAttribute();
  }

  initAddEndRows() {
    return this.fb.group({
      aEndAddress: ["", [Validators.required]],
      gpsCoordsA: [""],
      bEndAddress: ["", [Validators.required]],
      gpsCoordsB: [""],
      stateA: ['', [Validators.required]],
      stateB: ['', [Validators.required]],
      suburbA: ['', [Validators.required]],
      postcodeA: ['', [Validators.required,Validators.pattern(this.utility.getPostCodeRegEx())]],
      suburbB: ['', [Validators.required]],
      postcodeB: ['', [Validators.required,Validators.pattern(this.utility.getPostCodeRegEx())]]
    })
  }

  reset() {
    this.ductAccessForm.reset();
    this.ductAccessForm.get('secondForm')['controls']['additionalEnds'].clear();
    this.ductAccessForm.get('secondForm')['controls']['additionalEnds'].push(this.initAddEndRows());
    this.ductAccessForm.get('secondForm')['controls']['termofFacility'].reset(15);
    this.ductAccessForm.get('secondForm')['controls']['breakouts'].reset(0);
    this.ductAccessForm.get('secondForm')['controls']['splices'].reset(0);
    this.ductAccessForm.get('secondForm')['controls']['loops'].reset(0);
    this.ductAccessForm.get('secondForm')['controls']['totalMeters'].reset(0);
    this.ductAccessForm.get('secondForm')['controls']['bareFibre'].reset(0);
    this.ductAccessForm.get('secondForm')['controls']['subduct'].reset(0);
    this.ductAccessForm.get('thirdForm')['controls']['file'].reset('');
    this.setCustomerDetails();
  }

  cancel(stepper: MatStepper) {
    this.reset();
    stepper.selectedIndex = 0;
  }

  setCustomerDetails(){
    let profile = this.profileService.getProfileDetails();
    this.ductAccessForm.get('firstForm')['controls']['contactName'].setValue(profile.contactName);
    this.ductAccessForm.get('firstForm')['controls']['contactEmail'].setValue(profile.contactEmail);
  }

  async onSub() {
    let params = {
      "dp_request": {
        companyName: this.ductAccessForm.get('firstForm')['controls']['companyName'].value,
        exDate: this.ductAccessForm.get('firstForm')['controls']['date'].value,
        study: this.ductAccessForm.get('firstForm')['controls']['study'].value,
        telstraReservationId: this.ductAccessForm.get('firstForm')['controls']['telstraReservID'].value,
        nominatedContactName: this.ductAccessForm.get('firstForm')['controls']['contactName'].value,
        nominatedContactNumber: this.ductAccessForm.get('firstForm')['controls']['contactNumber'].value,
        nominatedContactEmailAddress: this.ductAccessForm.get('firstForm')['controls']['contactEmail'].value,
        initialContactName: this.ductAccessForm.get('firstForm')['controls']['ipoContactName'].value,
        initialContacttNumber: this.ductAccessForm.get('firstForm')['controls']['ipoContactNum'].value,
        initialContactEmailAddress: this.ductAccessForm.get('firstForm')['controls']['ipoContactEmail'].value,
        typeOfStudy: this.ductAccessForm.get('firstForm')['controls']['studyType'].value,
        totalMeters: this.ductAccessForm.get('secondForm')['controls']['totalMeters'].value,
        bareFibre: this.ductAccessForm.get('secondForm')['controls']['bareFibre'].value,
        subDuct: this.ductAccessForm.get('secondForm')['controls']['subduct'].value,
        tunnel: this.ductAccessForm.get('secondForm')['controls']['tunnel'].value,
        tebabackhaul: this.ductAccessForm.get('secondForm')['controls']['teba'].value,
        intendedTerm: this.ductAccessForm.get('secondForm')['controls']['termofFacility'].value,
        noOfBreakOuts: this.ductAccessForm.get('secondForm')['controls']['breakouts'].value,
        noOfSplices: this.ductAccessForm.get('secondForm')['controls']['splices'].value,
        noOfLoops: this.ductAccessForm.get('secondForm')['controls']['loops'].value,
        leadIn: this.ductAccessForm.get('secondForm')['controls']['leadIn'].value,
        address: this.ductAccessForm.get('secondForm')['controls']['address'].value,
        bareFibreStreetNetwork: this.ductAccessForm.get('secondForm')['controls']['fibre'].value,
        specialConditionsComments: this.ductAccessForm.get('secondForm')['controls']['spclConditionText'].value,
        warrenAndBrownInstallation: this.ductAccessForm.get('secondForm')['controls']['ductingRadioValue'].value,
        installationNotRequiredDetails: this.ductAccessForm.get('secondForm')['controls']['ductingText'].value,
        backhaulAccess: this.ductAccessForm.get('secondForm')['controls']['accessRadioValue'].value,
        lengthOfOpticalFibre: this.ductAccessForm.get('secondForm')['controls']['lengthOfCable'].value,
        ofturequired: this.ductAccessForm.get('secondForm')['controls']['oftuRadioValue'].value,
        ofturackOfTermination: this.ductAccessForm.get('secondForm')['controls']['oftuText'].value,
        numberOfCustomerCables: this.ductAccessForm.get('secondForm')['controls']['numberOfCable'].value,
        cableGreaterThan30mts: this.ductAccessForm.get('secondForm')['controls']['customerCableLengthRadioValue'].value,
        lengthOfCableGreaterThan30mts: this.ductAccessForm.get('secondForm')['controls']['customerCableLengthText'].value,
        rackOfCableGreaterThan30mts: this.ductAccessForm.get('secondForm')['controls']['rackLocation'].value,
        oftulocationsOptions: this.ductAccessForm.get('secondForm')['controls']['optionsOFTU'].value,
        fCable: this.ductAccessForm.get('secondForm')['controls']['fCable'].value,
        fCableLocFrom: this.ductAccessForm.get('secondForm')['controls']['fCableLocFrom'].value,
        fCableLocTo: this.ductAccessForm.get('secondForm')['controls']['fCableLocTo'].value,
        oFCable: this.ductAccessForm.get('secondForm')['controls']['ofCable'].value,
        subductManType: this.ductAccessForm.get('secondForm')['controls']['subductManType'].value,
        cableManName: this.ductAccessForm.get('secondForm')['controls']['cableManName'].value,
        cableType: this.ductAccessForm.get('secondForm')['controls']['cableType'].value,
        surveyingCompany: this.ductAccessForm.get('thirdForm')['controls']['surveyingCompany'].value,
        surveyorName: this.ductAccessForm.get('thirdForm')['controls']['surveyorName'].value,
        contactNumber: this.ductAccessForm.get('thirdForm')['controls']['surveyorContactNumber'].value,
        civilsCompany: this.ductAccessForm.get('thirdForm')['controls']['civilsCompany'].value,
        civilsContactName: this.ductAccessForm.get('thirdForm')['controls']['civilsContactName'].value,
        civilsContactNumber: this.ductAccessForm.get('thirdForm')['controls']['civilsContactNumber'].value,
        haulingCompany: this.ductAccessForm.get('thirdForm')['controls']['haulingCompany'].value,
        haulingContactName: this.ductAccessForm.get('thirdForm')['controls']['haulingContactName'].value,
        haulingContactNumber: this.ductAccessForm.get('thirdForm')['controls']['haulingContactNumber'].value,
        mutipleSubductJoiner: this.ductAccessForm.get('secondForm')['controls']['radioValue'].value,
        saci: this.ductAccessForm.get('thirdForm')['controls']['saci'].value ? '1':'0',
        saciCompany: this.ductAccessForm.get('thirdForm')['controls']['saciCompany'].value,
        sacicontactName: this.ductAccessForm.get('thirdForm')['controls']['contactNameSaci'].value,
        sacicontactNumber: this.ductAccessForm.get('thirdForm')['controls']['contactNumberSaci'].value

      },
      "dp_end_details": this.ductAccessForm.get('secondForm')['controls']['additionalEnds'].value

    }


    this.ductService.postData(params,this.ductAccessForm.get('thirdForm')['controls']['file'].value).then((res: any) => {
      this.options = this.toastr.toastrConfig;
      let successMsg = 'You have successfully submitted the duct access request form';
      this.showToasterMessage(successMsg, '', this.options, 'success');
      this.reset();
      this.router.navigateByUrl('/duct-access-form');
    }).catch((err) => {
      if (err['error']['status'] === 500 && err['error']['code'] === 500) {
        let msg = err.error.message ? err.error.message : 'Something went wrong';
        this.options = this.toastr.toastrConfig;
        this.showToasterMessage(msg, '', this.options, 'error');
      } else {
        this.router.navigateByUrl('/error');
      }
    });

  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-top-center';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 6000;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    const event: Event = window.event;
  }

  move(index: number) {
    this.stepper.selectedIndex = index;
  }

  updateStudyTypeValue() {

    this.studyTypeSelectedValue = this.ductAccessForm.value.firstForm.studyType;

    if (this.studyTypeSelectedValue == 'C0 Study - Backhaul only') {
      this.addRequiredValidator('ductingRadioValue');
      this.addRequiredValidator('accessRadioValue');
      this.addRequiredValidator('oftuRadioValue');
      this.addRequiredValidator('customerCableLengthRadioValue');
    } else {
      this.removeRequiredValidator('ductingRadioValue');
      this.ductAccessForm.get('secondForm')['controls']['ductingText'].value = "";
      this.removeRequiredValidator('accessRadioValue');
      this.ductAccessForm.get('secondForm')['controls']['lengthOfCable'].value = "";
      this.removeRequiredValidator('oftuRadioValue');
      this.ductAccessForm.get('secondForm')['controls']['oftuText'].value = "";
      this.ductAccessForm.get('secondForm')['controls']['numberOfCable'].value = "";
      this.removeRequiredValidator('customerCableLengthRadioValue');
      this.ductAccessForm.get('secondForm')['controls']['customerCableLengthText'].value = "";
      this.ductAccessForm.get('secondForm')['controls']['rackLocation'].value = "";
      this.ductAccessForm.get('secondForm')['controls']['optionsOFTU'].value = "";
    }

    if(this.studyTypeSelectedValue != 'CFD Study - (Construction Finalisation Linked to relevant TP or ADR study)'){
      this.ductAccessForm.get('secondForm')['controls']['fCable'].value = "";
      this.ductAccessForm.get('secondForm')['controls']['fCableLocFrom'].value = "";
      this.ductAccessForm.get('secondForm')['controls']['fCableLocTo'].value = "";
      this.ductAccessForm.get('secondForm')['controls']['ofCable'].value = "";
      this.ductAccessForm.get('secondForm')['controls']['subductManType'].value = "";
      this.ductAccessForm.get('secondForm')['controls']['cableManName'].value = "";
      this.ductAccessForm.get('secondForm')['controls']['cableType'].value = "";
    }
  }

  addRequiredValidator(id: String) {
    this.ductAccessForm.get('secondForm')['controls'][id].setValidators([Validators.required]);
    this.ductAccessForm.get('secondForm')['controls'][id].updateValueAndValidity();
  }

  removeRequiredValidator(id: String) {
    this.ductAccessForm.get('secondForm')['controls'][id].clearValidators();
    this.ductAccessForm.get('secondForm')['controls'][id].updateValueAndValidity();
    this.ductAccessForm.get('secondForm')['controls'][id].value = "";
  }

  backToTop(){
    document.documentElement.scrollTop = 0;
  }

  removeAccessibilityAttribute() {
    const elements = document.getElementsByClassName('mat-horizontal-stepper-content');
    for(let i=0; i< elements.length; i++) {
      elements[i].removeAttribute('aria-labelledby');
    }
  }

  ngOnDestroy(): void {
    this.commonService.showTopButtonInFooter(false);
  }
}
