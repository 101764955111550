import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class RouteGuardService {

  constructor(public router: Router) { }


  canActivate(route: ActivatedRouteSnapshot): boolean {
    const routVal = route && route.data && route.data.path ? route.data.path : null;
    if ((routVal === 'profile')) {
      return true;
    } 
    else if ((routVal === 'admin')) {
      return true;
    } else if ((routVal === 'manage-cabinet')) {
      return true;
    } 
    else if ((routVal === 'document')) {
      return true;
    } 
    else if ((routVal === 'cabinet-list')) {
      return true;
    } 
    else if ((routVal === 'view-document')) {
      return true;
    } 
    else {
      // this.router.navigateByUrl('/');
      return true;
    }

  }
}
