import { HostListener, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from './shared/services/common.service';
import { HomePageService } from '../app/shared/services/homePage.service';

@Injectable({
  providedIn: 'root'
})
export class CanNavigateGuardService implements CanActivate {
  cidnSelected: string = '';
  cidnDefault: string;
  infraCoCIDN = window['environment'].InfraCoCIDN;
  constructor(private commonService: CommonService, private router: Router, private homepageservice: HomePageService) {
    //this.cidnSelected = sessionStorage.getItem('selectedCidn');
    this.cidnSelected =  localStorage.getItem('selectedHeaderCIDN');
    // Listen for changes in localStorage
    window.addEventListener('storage', (e) => {
      if (e.key === 'selectedHeaderCIDN') {
        this.cidnSelected = e.newValue;
      }
    });
    if (!this.cidnSelected) {
      this.getCommonServices();
    }
    this.cidnDefault = localStorage.getItem('CIDNCan');
  }
  @HostListener('window:beforeunload', ['$event'])
  handleRefresh(event: any) {
    const currentRoute = this.router.url.split('/').pop(); // Get the last segment of the current URL
  
    // Check if the current route is one of the specified ones
    if (['product-details', 'site-details', 'request-details', 'review-submit', 'success', 'bulk-quote-request-details', 'bulk-quote-success'].includes(currentRoute)) {
      this.router.navigateByUrl('/myQuotes'); // Redirect for specific routes
    }
  
    const currentRouteWithParams = this.router.url;
  
    // Check if the current route matches the pattern 'bulk-quote-upload/:id'
    if (currentRouteWithParams.includes('bulk-quote-upload')) {
      this.router.navigateByUrl('/myQuotes'); // Redirect for the 'bulk-quote-upload' route
    }
  }
  
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const routVal = next && next.data && next.data.path ? next.data.path : null;

    if (routVal === 'product-details' || routVal === 'site-details'
      || routVal === 'request-details' || routVal === 'review-submit' || routVal === 'success' || routVal ==='bulk-quote-request-details' || routVal.includes('bulk-quote-upload') || routVal ==='bulk-quote-success') {
      const roles = this.commonService.getroleDetails().customerList;
      const cidntoCheck = localStorage.getItem('selectedHeaderCIDN');
      let cidnPresentIndex = false;
      if (cidntoCheck && roles.some((obj) => obj.role.includes('Read & Write') && obj.function.includes('Quote Request Management'))) {
        roles.map((r) => {
          if (r.cidn === cidntoCheck && r.role.includes('Read & Write') && r.function.includes('Quote Request Management')) {
            cidnPresentIndex = true;
            return true;
          }
        });
        if (!cidnPresentIndex) {
          this.router.navigateByUrl('/access-denied');
        } else {
          return true;
        }
        return true;
      } else {
        this.router.navigateByUrl('/myQuotes');
        return true;
      }
    }
    else if (routVal === 'myQuotes' || routVal.startsWith('quoteSummary')) {
      const roles = this.commonService.getroleDetails().customerList;
      const cidntoCheck = this.cidnSelected;
      if ((cidntoCheck) && roles.some((obj) => obj && obj.function.includes('Quote Request Management') && (obj.role.includes('Read & Write') || obj.role.includes('Read Only')) && obj?.cidn === cidntoCheck)) {
        return true;
      } else if ((cidntoCheck) && roles.some((obj) => obj && obj.function.includes('Quote Request Management') && (obj.role.includes('Read & Write') || obj.role.includes('Read Only')) && obj?.cidn !== cidntoCheck)) {
        this.router.navigateByUrl('/access-denied');
      } else if ((this.cidnDefault) && ((roles.some((obj) => obj && obj.function.includes('Quote Request Management') && (obj.role.includes('Read & Write') || obj.role.includes('Read Only')) && obj?.cidn === this.cidnDefault)))) {
        return true;
      } else {
        this.router.navigateByUrl('/access-denied');
      }
    }  else if (routVal === 'myOrders' || routVal.startsWith('orderSummary')) {
      const roles = this.commonService.getroleDetails().customerList;
      const cidntoCheck = this.cidnSelected;
      if ((cidntoCheck) && roles.some((obj) => obj && obj.function.includes('Order Management') && (obj.role.includes('Read & Write') || obj.role.includes('Read Only')) && obj?.cidn === cidntoCheck)) {
        return true;
      } else if ((cidntoCheck) && roles.some((obj) => obj && obj.function.includes('Order Management') && (obj.role.includes('Read & Write') || obj.role.includes('Read Only')) && obj?.cidn !== cidntoCheck)) {
        this.router.navigateByUrl('/access-denied');
      } else if ((this.cidnDefault) && ((roles.some((obj) => obj && obj.function.includes('Order Management') && (obj.role.includes('Read & Write') || obj.role.includes('Read Only')) && obj?.cidn === this.cidnDefault)))) {
        return true;
      } else {
        this.router.navigateByUrl('/access-denied');
      }
    } else if (routVal === 'order-site-details' || routVal === 'order-request-details' ||
     routVal === 'order-review-submit' || routVal === 'order-success') {
    const roles = this.commonService.getroleDetails().customerList;
    const cidntoCheck = localStorage.getItem('selectedHeaderCIDN');
    let cidnPresentIndex = false;
    if (cidntoCheck && roles.some((obj) => obj && obj.role.includes('Read & Write') && obj.function.includes('Order Management'))) {
      roles.map((r) => {
        if (r.cidn === cidntoCheck && r.role.includes('Read & Write') && r.function.includes('Order Management')) {
          cidnPresentIndex = true;
          return true;
        }
      });
      if (!cidnPresentIndex) {
        this.router.navigateByUrl('/access-denied');
      } else {
        return true;
      }
      return true;
    } else {
      this.router.navigateByUrl('/myOrders');
      return true;
    }
  }
  }
  getCommonServices() {
    this.commonService.getDefaultCustomer().pipe().subscribe(resp => {
      if (resp) {
        this.setCustomerDropdown(resp);
      }
    });
  }

  setCustomerDropdown = (selectedHeaderValue?: string) => {
    // const userName = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail'];
    const profileDetails = this.homepageservice.userProfileDetails;
    if (profileDetails) {
      const custDetails = profileDetails?.data?.customerDetails;
      if (selectedHeaderValue) {
        const findCIDNobject = this.searchCidn(custDetails, selectedHeaderValue)
        this.cidnSelected = findCIDNobject?.cidn;
        localStorage.setItem('CIDNCan', this.cidnSelected)
        // this.getFibreMenu();
      }
    }

  }

  searchCidn(myArray, nameKey) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].customerName === nameKey) {
        return myArray[i];
      }
    }
  }
}

