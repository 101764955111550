import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomDatePicker, CustomDropDown } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { DuctAccessService } from '../duct-access.service';
// import { UploadDocumentPageData } from '../attachment-requirement/attachment-requirement.model';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-company-customer-details',
  templateUrl: './company-customer-details.component.html',
  styleUrls: ['./company-customer-details.component.less']
})
export class CompanyCustomerDetailsComponent implements OnInit {
  firstFormGroup: FormGroup;
  ductAccessForm: FormGroup;
  public aemLabelAuthoring: any;
  companyNameData: any = [];
  companyNameWithDate: { [key: string]: any } = [];
  executionDate: any = [];
  public companyNameList = [];
  public companyNameModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Full Company Name Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list'
  };
  public studyTypeList = [];
  public studyTypeModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'studyType Required'
  };

  date: NgbDateStruct;
  showStudyType: boolean = false;
  showCompanyName: boolean = false;
  public fromDatePickerFilter: CustomDatePicker = {
    automationTypeImage: '',
    automationIdImage: '',
    automationTypeDatePicker: '',
    automationIdDatePicker: '',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: '',
    automationIdValidationError: '',
    placeholderCaption: '',
    id: 'date',
    tabindex: '',
    requiredText: 'Please select a date',
    validationMessage: '',
    isMandatory: false,
    name: 'date',
    formControlName: 'date',
    isDisabled: true,
    isWeekendsDisabled: false
  };

  constructor(private ductService: DuctAccessService,
    private router: Router, private activeRoute: ActivatedRoute, private fb: FormBuilder, private rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    this.getLabels();
    this.getCompanyListDropDown();
    this.firstFormGroup = this.rootFormGroup.control.get('firstForm') as FormGroup;
    const elements = document.getElementsByClassName('field-input');
    window.onkeyup = function (event) {
      if (event.keyCode === 9) {
        for (let i = 0; i < elements.length; i++) {
          const inputElement = elements[i];
          inputElement.classList.remove("focused")
        }
        const activeElement = document.activeElement;
        if (activeElement && activeElement.classList.contains('field-input')) {
          activeElement.classList.add("focused")
        }
      }
    }
  }

  checkValue(value: boolean) {
    if (value) {
      this.firstFormGroup.controls.ipoContactName.setValue(this.firstFormGroup.value.contactName);
      this.firstFormGroup.controls.ipoContactNum.setValue(this.firstFormGroup.value.contactNumber);
      this.firstFormGroup.controls.ipoContactEmail.setValue(this.firstFormGroup.value.contactEmail)
    }
    else {
      this.firstFormGroup.controls.ipoContactName.setValue(undefined);
      this.firstFormGroup.controls.ipoContactNum.setValue(undefined);
      this.firstFormGroup.controls.ipoContactEmail.setValue(undefined)
    }
  }

  getLabels() {
    this.ductService.getDuctFormData().then((data: any) => {
      this.aemLabelAuthoring = data;
      this.studyTypeList = this.aemLabelAuthoring.form.studyTypeList;
    }).catch((error) => {
    });
  }

  getCompanyListDropDown() {
    this.ductService.getCompanyNameData().then((data: any) => {
      this.companyNameData = data;
      this.companyNameData.forEach(element => {
        this.companyNameList.push(element.customerName);
        this.companyNameWithDate[element.customerName]=element.executionDate;
      });
      this.companyNameList.sort(this.itemSort);
    }).catch((error) => {
    });
  }

  itemSort(a, b) {
    const str1 = a.toLowerCase();
    const str2 = b.toLowerCase();
    let comparison = 0;
    if (str1 > str2) {
      comparison = 1;
    } else if (str1 < str2) {
      comparison = -1;
    }
    return comparison;
  }

  updateExecutionDate(companyName: any) {

    if (companyName && this.companyNameWithDate[companyName]) {

      this.executionDate = this.companyNameWithDate[companyName]?.split("-");
      this.date = { year: parseInt(this.executionDate[0]), month: parseInt(this.executionDate[1]), day: parseInt(this.executionDate[2]) };
      this.firstFormGroup.controls.date.setValue(this.companyNameWithDate[companyName]);  
    }
    else {
      this.date = undefined;
    }
  }

  touchStudyType() {
    this.showStudyType = true;
  }

  touchCompanyName() {
    this.showCompanyName = true;
  }
}

