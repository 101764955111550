<div class="container-fluid" role="main">
  <section class="view-container">
    <h1 tabindex="0" role="heading" class="wlcm-cls" id="welcomemMsg" aria-label="Welcome {{contactName}}">
      Welcome {{contactName}},
    </h1>
    <ngb-carousel tabindex="0" class="custom-slider" #carousel [interval]="5000" [pauseOnHover]="pauseOnHover" *ngIf="images"
      (slide)="onSlide($event)" [showNavigationArrows]="showNavigationArrows"
      [showNavigationIndicators]="showNavigationIndicators">
      <ng-template tabindex="0" ngbSlide *ngFor="let img of images; let i=index">
        <a tabindex="0" *ngIf="img.url" [href]="img.url" (click)="NaviagteUrl(img.url, 'currentSlide')" target="_blank"
          rel="nofollow noopener noreferrer">
          <div tabindex="0" class="picsum-img-wrapper">
            <img [src]="img.imgLink" [alt]="img.imgAltText" width="100%">
          </div>
        </a>
        <div tabindex="0" *ngIf="!img.url" class="picsum-img-wrapper">
          <img [src]="img.imgLink" [alt]="img.imgAltText" width="100%">
        </div>
      </ng-template>
    </ngb-carousel>
  </section>
  <div>
