import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { CustomNameField } from 'src/app/shared/model/custom-core-ui.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { HomePageService } from 'src/app/shared/services/homePage.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { TwCoreUIUtilService } from 'tw-core-ui';
import { CustomTextField, CustomDropDown } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { CustomerService } from '../../customer-list/customer.service';
import { UserService } from '../user.service';
import { DashboardService } from '../../../cabinets/dashboard.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modify-user',
  templateUrl: './modify-user.component.html',
  styleUrls: ['./modify-user.component.css']
})

export class ModifyUserComponent implements OnInit, OnDestroy {
  public modifyUserForm: FormGroup;
  firstNameModel = new CustomNameField;
  userNameModel = new CustomNameField;
  lastNameModel = new CustomNameField;
  mobileNumberModel = new CustomNameField;
  statusDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Status Required Dropdown.Press Enter/return to expand the Dropdown and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  statuses = [
    "Active",
    "Inactive"
  ];
  customernameDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Customer Name Required Dropdown.Press Enter/return to expand the Dropdown and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  customers = [];
  applicationDropDownModel: CustomDropDown = {
    isDisabled: true,
    accessibleHeaderId: 'Application Required Dropdown.Press Enter/return to expand the Dropdown and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  applications = [];
  roleAccessDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Role access Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  roleAccesses = [];
  functionList: any = [];
  isRoleAccessEnabled: boolean = true;
  canSubmit: boolean = true;
  customerDetailsList: any = [
    {
      cidn: '',
      customername: '',
      application: '',
      roleaccess: '',
      rolefunctions: ''
    }
  ];
  userNameExistMessage = '';
  @Output() moveTab = new EventEmitter<string>();
  checkedRoleFunctions = [];
  toAddCustomer: boolean = false;
  isCustomerNameValid: boolean = true;
  isRoleAccessValid: boolean = true;
  isRoleFunctionDisabled: boolean = false;
  isRoleFunctionValid: boolean = false;
  isResetFunctions: boolean = false;
  aemLabelAuthoring: any = '';
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  options: GlobalConfig;
  domainErrorMessage: string = '';
  public operations: Array<Object> = [];
  userDataToModify: any;
  public newRowData = [];
  allRoleFunctionsMasterList: any[][] = [];
  modifyUserLabelModel: any;
  isNewRoleAdded: boolean = false;
  customerList: { cidn: string; app: string; role: string; function: string; businessName: string }[];
  linkedCIDNs: any;
  myLinkedCustomerListFromCaiman = [];
  previousRoleRemoved: boolean = false;
  cidn: string = '';
  infraCoCIDN = window['environment'].InfraCoCIDN;
  NBNCidn = window['environment'].NBNCidn;
  storedUserData: any;
  deletedExistingRoles = [];
  existingRolesToModify = [];
  myUserId: string = '';
  @ViewChild('divToScroll') divToScroll: ElementRef;
  @ViewChild("tref") tref: ElementRef;
  public isNBN: boolean = false;
  private roles: any;
  public isDuctRole: boolean = false;
  disableSaveButton: boolean = false;
  headerDropdownValue: string;
  isInfracoAdminUserMgmtRole: boolean = false;
  showHideSectionOnInactiveStatus: boolean = false;
  showErrorMsg: boolean = false;
  showErrorMsgRole: boolean = false;
  isRoleFunctionTouched: boolean = false;
  defaultCustSubscription:Subscription;

  constructor(
    private route: Router,
    public fb: FormBuilder,
    private utility: UtilityService,
    private userservice: UserService,
    private util: TwCoreUIUtilService,
    private toastr: ToastrService,
    private homepageservice: HomePageService,
    private commonservice: CommonService,
    private customerService: CustomerService,
    private dashBoardSvc: DashboardService,
  ) {
    this.modifyUserForm = this.fb.group({
      username: ['', [Validators.required, Validators.pattern(this.utility.getEmailRegEx())]],
      firstname: ['', [Validators.required, Validators.pattern(this.utility.getNameRegEx())]],
      lastname: ['', [Validators.required, Validators.pattern(this.utility.getNameRegEx())]],
      mobilenumber: ['', [Validators.pattern(this.utility.getNumberRegEx())]],
      status: [this.statuses[0], [Validators.required]],
      customername: ['', [Validators.required]],
      application: ['', [Validators.required]],
      roleaccess: ['', [Validators.required]]
    });

    this.options = this.toastr.toastrConfig;
    this.setuserNameModelFieldValue();
    this.setfirstNameModelFieldValue();
    this.setlastNameModelFieldValue();
    this.setmobileNumberModelFieldValue();
  }

  ngOnInit(): void {
    this.fetchRoles();
    this.getLabels();
    this.controlsObservables();
    this.getCommonServices();
    this.getUserToModifyDetails();
    this.getRoles();
  }

  getLabels = () => {
    return new Promise((resolve, reject) => {
      this.userservice.getLabels().then((res) => {
        this.aemLabelAuthoring = res;
        resolve(true);
      }).catch(() => {
      });
    });
  }

  private fetchRoles = () => {
    this.roles = this.commonservice.getroleDetails().customerList;
    const nbn = this.roles.some(
      (obj) => obj.role.includes('NBN') || obj.function.includes('NBN')
    );
    this.isNBN = nbn ? true : false;
    const isDuct = this.roles.some(
      (obj) => obj.role.includes('Duct') || obj.function.includes('Duct')
    );
    this.isDuctRole = isDuct ? true : false;
    if (this.isDuctRole) {
      this.commonservice.showTopButtonInFooter(true);
    }
    const isInfracoAdmin = this.roles.some((obj) => obj.role.includes('Administrator') && obj.function.includes('User Management'));
    if (isInfracoAdmin) {
      this.isInfracoAdminUserMgmtRole = true;
    } else {
      this.isInfracoAdminUserMgmtRole = false;
    }
  }

  private getUserToModifyDetails() {
    //get user details on selected user
    this.storedUserData = this.userservice.getModifyUserData();
    const email = this.storedUserData.email || this.modifyUserForm.get('username').value;
    this.userservice.getUserData(this.storedUserData.email, this.cidn)
      .then(res => {
        if (res.status === 200) {
          const resData = res.data;
          this.existingRolesToModify = [];
          res.data?.roles?.forEach(element => {
            this.existingRolesToModify.push({
              cidn: element.cidn,
              application: this.buildRoleBasedOnLabelsSaved(element.appName),
              customername: element.customerName,
              roleaccess: this.buildRoleBasedOnLabelsSaved(element.role_name),
              rolefunctions: this.buildRoleBasedOnLabelsSaved(element.functionName) //.toString()
            });
          })
          this.myUserId = res.data.id;
          const ResDataRoles = [];
          resData.roles?.forEach(each => {
            ResDataRoles.push({
              cidn: each.cidn,
              application: this.buildRoleBasedOnLabelsSaved(each.appName),
              customername: each.customerName,
              roleaccess: this.buildRoleBasedOnLabelsSaved(each.role_name),
              rolefunctions: this.buildRoleBasedOnLabelsSaved(each.functionName) //.toString()
            });
          })

          const response = {
            userName: resData.username,
            firstname: resData.firstName,
            lastname: resData.lastName,
            mobilenumber: resData.phoneNumber,
            status: resData.status ? resData.status : "Active",
            roleDetails: ResDataRoles
          }
          this.userDataToModify = response;
          this.userNameModel.inputText = response?.userName;
          this.modifyUserForm?.get('username').setValue(response?.userName);
          this.firstNameModel.inputText = response?.firstname;
          this.modifyUserForm?.get('firstname').setValue(response?.firstname)
          this.lastNameModel.inputText = response?.lastname;
          this.modifyUserForm?.get('lastname').setValue(response?.lastname);
          this.mobileNumberModel.inputText = response?.mobilenumber;
          this.modifyUserForm?.get('mobilenumber').setValue(response?.mobilenumber)
          this.modifyUserForm?.get('status').setValue(response?.status);
          this.changeStatusDropdown();
          this.customerDetailsList = response?.roleDetails;
        }
        else if (res.status === 401) {
          this.showToasterMessage(this.aemLabelAuthoring.form.unauthorizedErrorMessage, '', this.options, 'error', 'toast-top-center');
          this.route.navigate(['/']);
        }
      })
      .catch((err) => {
        if (err.status === 401) {
          this.showToasterMessage(this.aemLabelAuthoring.form.unauthorizedErrorMessage, '', this.options, 'error', 'toast-top-center');
          this.route.navigate(['/']);
        }
      });
  }

  getCommonServices = () => {
    if (this.commonservice.getDefaultCustomerObject()) {
      const popUpData = JSON.parse(this.commonservice.getDefaultCustomerObject());
      this.myLinkedCustomerListFromCaiman = popUpData.allCustomerList;
    }
    //get selected cidn
    this.cidn = this.commonservice.getSelectedCidn();
    this.setCustomerDropdown(this.headerDropdownValue); //initial call
    this.defaultCustSubscription = this.commonservice.getDefaultCustomer().pipe().subscribe(resp => {
      if (resp) {
        this.headerDropdownValue = resp;
        this.setCustomerDropdown(resp);
        this.customerDetailsList = [];
      }
    });
  }

  setCustomerDropdown = (selectedHeaderValue?: string) => {
    const getRolesFromCaiman = this.commonservice.getroleDetails().customerList;
    const custNames = [];
    this.customerService.getLinkedCIDNs().then(res => {
      this.linkedCIDNs = res.data.businessNameDetails;
      this.customers = [];
      //get latest CIDN
      if (selectedHeaderValue) {
        const findCIDNobject = this.searchCidn(this.linkedCIDNs, selectedHeaderValue)
        this.cidn = findCIDNobject?.cidn;
        this.getUserToModifyDetails();
      }
      //check if my role is Infraco Admin,populate all linked cidn list
      if (getRolesFromCaiman.some(ele => ele.cidn === this.infraCoCIDN) && selectedHeaderValue !== 'NBNCo') {
        this.linkedCIDNs.forEach(item => {
          custNames.push(item.bussinessName);
        });
      } else {
        this.linkedCIDNs.forEach(item => {
          //matching Infraco cidn to show all customer list else show the selectd cidn customer only
          if (this.cidn === this.infraCoCIDN) {
            custNames.push(item.bussinessName);
          }
          else if (getRolesFromCaiman.some(ele => ele.cidn === item.cidn && ele.cidn !== this.infraCoCIDN && ele.role === 'Administrator' && item.status === 'Active' && ele.function === 'User Management')) {
            getRolesFromCaiman.forEach((ele) => {
              custNames.push(item.bussinessName);
            });
          } else if (item.cidn === this.cidn) {
            custNames.push(item.bussinessName); //matching cidn for other company
          }
        });
      }
      this.customers = [...new Set(custNames)];
    }).catch((err) => {
    });
  }

  getRoles = () => {
    const response = this.homepageservice.getRoleFunctionMapping();
    response.forEach(item => {
      if (this.applications.indexOf(item.application) === -1) {
        this.applications.push(item.application);
      }
      if (this.isNBN || this.isDuctRole) {
        this.roleAccesses = [];
      } else {
        if (this.roleAccesses.indexOf(item.role) === -1) {
          this.roleAccesses.push(item.role);
        }
      }
      //set value of role functions
      this.allRoleFunctionsMasterList[item.role] = this.allRoleFunctionsMasterList[item.role] || [];
      this.allRoleFunctionsMasterList[item.role].push(item.function);
    });
    this.modifyUserForm.get('application').setValue(this.applications[0]);
  }

  setuserNameModelFieldValue() {
    this.userNameModel.automationIdLabel = 'lbleusrnme';
    this.userNameModel.automationIdTextField = 'txtbxusrnme';
    this.userNameModel.placeholderCaption = 'Enter user name';
    this.userNameModel.id = 'username';
    this.userNameModel.headerTextAccessible = 'UserName required';
    this.userNameModel.isKeyDownDefaultValidation = false;
    this.userNameModel.name = 'username';
    this.userNameModel.formControlName = 'username';
    this.userNameModel.validationMessage = 'Please enter an email address in the format: email@example.com';
    this.userNameModel.requiredText = 'Please enter user name';
    this.userNameModel.maxlength = '64';
    this.modifyUserForm.controls['username'].disable();
  }

  setfirstNameModelFieldValue() {
    this.firstNameModel.automationIdLabel = 'lblefrstnme';
    this.firstNameModel.automationIdTextField = 'txtbxfrstnme';
    this.firstNameModel.placeholderCaption = 'Enter first name';
    this.firstNameModel.id = 'firstname';
    this.firstNameModel.headerTextAccessible = 'FirstName required';
    this.firstNameModel.isKeyDownDefaultValidation = false;
    this.firstNameModel.name = 'firstname';
    this.firstNameModel.formControlName = 'firstname';
    this.firstNameModel.validationMessage = "Only letters, numbers and '&- can be used in the first name";
    this.firstNameModel.requiredText = 'Please enter first name';
    this.firstNameModel.maxlength = '64';
  }

  setlastNameModelFieldValue() {
    this.lastNameModel.automationIdLabel = 'lblelstnme';
    this.lastNameModel.automationIdTextField = 'txtbxlstnme';
    this.lastNameModel.placeholderCaption = 'Enter last name';
    this.lastNameModel.id = 'lastname';
    this.lastNameModel.headerTextAccessible = 'Lastname required';
    this.lastNameModel.isKeyDownDefaultValidation = false;
    this.lastNameModel.name = 'lastname';
    this.lastNameModel.formControlName = 'lastname';
    this.lastNameModel.validationMessage = "Only letters, numbers and '&- can be used in the last name";
    this.lastNameModel.requiredText = 'Please enter last name';
    this.lastNameModel.maxlength = '64';
  }

  setmobileNumberModelFieldValue() {
    this.mobileNumberModel.automationIdLabel = 'lblembleNmbr';
    this.mobileNumberModel.automationIdTextField = 'txtbxmbleNmbr';
    this.mobileNumberModel.placeholderCaption = 'Enter mobile number';
    this.mobileNumberModel.id = 'mobilenumber';
    this.mobileNumberModel.headerTextAccessible = 'MobileNumber';
    this.mobileNumberModel.isKeyDownDefaultValidation = false;
    this.mobileNumberModel.name = 'mobilenumber';
    this.mobileNumberModel.formControlName = 'mobilenumber';
    this.mobileNumberModel.validationMessage = "Please enter a valid phone number (20 digits)";
    this.mobileNumberModel.maxlength = '20';
  }


  controlsObservables = () => {
    this.modifyUserForm?.get('customername')?.valueChanges.subscribe(val => {
      if (this.modifyUserForm?.get('customername')?.valid && val) {
        this.isCustomerNameValid = true;
      }
    });
    this.modifyUserForm?.get('roleaccess')?.valueChanges.subscribe(val => {
      if (this.modifyUserForm?.get('roleaccess')?.valid) {
        this.isRoleAccessValid = true;
      }
    });
  }

  controlsEnabling = () => {
    this.modifyUserForm.controls['firstname'].enable();
    this.modifyUserForm.controls['lastname'].enable();
    this.modifyUserForm.controls['mobilenumber'].enable();
    this.customernameDropDownModel.isDisabled = false;
    this.roleAccessDropDownModel.isDisabled = false;
    this.isRoleFunctionDisabled = false;
  }

  resetControls = () => {
    this.modifyUserForm?.get('customername')?.reset();
    this.modifyUserForm?.get('roleaccess')?.reset();
    this.isResetFunctions = true;
    this.checkedRoleFunctions = [];
    this.functionList.forEach(element => {
      element.checked = false;
    });
    this.isCustomerNameValid = true;
    this.isRoleAccessValid = true;
    this.toAddCustomer = false;
  }

  changeStatusDropdown = () => {
    if (this.modifyUserForm?.get('status').value === this.statuses[1]) {
      this.modifyUserForm.controls['firstname'].reset();
      this.modifyUserForm.controls['lastname'].reset();
      this.modifyUserForm.controls['mobilenumber'].reset();
      this.modifyUserForm.controls['firstname'].setValue(this.userDataToModify.firstname);
      this.modifyUserForm.controls['lastname'].setValue(this.userDataToModify.lastname);
      if (this.userDataToModify.mobilenumber) {
        this.modifyUserForm.controls['mobilenumber'].setValue(this.userDataToModify.mobilenumber);
      }
      this.modifyUserForm.controls['firstname'].disable();
      this.modifyUserForm.controls['lastname'].disable();
      this.modifyUserForm.controls['mobilenumber'].disable();
      this.customernameDropDownModel.isDisabled = true;
      this.roleAccessDropDownModel.isDisabled = true;
      this.isRoleFunctionDisabled = true;
      //disable buttons and roles
      this.showHideSectionOnInactiveStatus = true;
    } else {
      this.controlsEnabling();
      this.showHideSectionOnInactiveStatus = false;
    }
  }

  changeRoleDropdown = () => {
    this.checkedRoleFunctions = [];
    this.isRoleFunctionValid = true;
    this.isRoleFunctionTouched = false
    const selectedCustomer = this.modifyUserForm.get('customername').value;
    const selectedRoleAccess = this.modifyUserForm.get('roleaccess').value;
    if (this.allRoleFunctionsMasterList[selectedRoleAccess]) {
      this.functionList = [];
      this.isResetFunctions = !this.isResetFunctions;
      this.allRoleFunctionsMasterList[selectedRoleAccess].forEach(role => {
        this.functionList.push({ name: role, checked: false })
      });
      if (this.isNBN) { //nbn users with infraco customer
        if (selectedCustomer.toUpperCase() == 'INFRACO') {
          if (selectedRoleAccess === 'NBN Read & Write') {
            let filteredArray = this.functionList.filter(e => (e.name == 'NBN DA Documents'));
            this.functionList = filteredArray;
          } else if (selectedRoleAccess === 'NBN Read Only') {
            let filteredArray = this.functionList.filter(e => (e.name == 'NBN Cabinets') || (e.name == 'NBN DA Documents'));
            this.functionList = filteredArray;
          }
        } else { //customer nbnco
          if (selectedRoleAccess === 'NBN Read Only') {
            let filteredArray = this.functionList.filter(e => (e.name == 'NBN Cabinets') || (e.name == 'NBN DA Documents'));
            this.functionList = filteredArray;
          }
        }
      } else if (this.isDuctRole) {
        if (selectedCustomer.toUpperCase() == 'INFRACO') {
          if (selectedRoleAccess === 'Duct Administrator') {
            let filteredArray = this.functionList.filter(e => (e.name == 'Duct User Management'));
            this.functionList = filteredArray;
          } else if (selectedRoleAccess === 'Duct Read & Write') {
            let filteredArray = this.functionList.filter(e => (e.name == 'Duct Access Form'));
            this.functionList = filteredArray;
          }
        } else { //customer nbnco
          if (selectedRoleAccess === 'Duct Read & Write') {
            let filteredArray = this.functionList.filter(e => (e.name == 'Duct Access Form'));
            this.functionList = filteredArray;
          }
        }
      }
      else { //non-nbn
        //for selected customer other than NBN, NBN option should not come and for customers other than InfraCo with Admin role, User Management function to be excluded
        if (selectedCustomer !== 'NBN CO') {
          let filteredArray = this.functionList.filter(e => e.name !== 'NBN Cabinet')
          this.functionList = filteredArray;
        }
        if (selectedCustomer.toUpperCase() !== 'INFRACO' && selectedRoleAccess === 'Administrator') {
          let filteredArray = this.functionList.filter(e => (e.name !== 'Customer Management')) //|| (e.name !== 'customermgmt')
          this.functionList = filteredArray;
        }
        if (selectedCustomer.toUpperCase() === 'INFRACO' && selectedRoleAccess === 'Read & Write') {
          let filteredArray = this.functionList.filter(e => (e.name !== 'Quote Request Management'))
          this.functionList = filteredArray;
        }
        if (selectedCustomer.toUpperCase() === 'INFRACO' && selectedRoleAccess === 'Read & Write') {
          let filteredArray = this.functionList.filter(e => (e.name !== 'Order Management'))
          this.functionList = filteredArray;
        }
      }
    }
  }

  changeCustomerDropdown = (e) => {
    this.modifyUserForm.get('roleaccess').reset();
    this.isResetFunctions = true;
  //  this.isResetFunctions = !this.isResetFunctions;
    if (this.isNBN) {
      if (e.toUpperCase() == 'INFRACO') {
        this.roleAccesses = ['NBN Administrator', 'NBN Read & Write', 'NBN Read Only'];
      } else if (this.headerDropdownValue === 'NBNCo') {
        this.roleAccesses = ['NBN Read Only'];
      } else {
        this.roleAccesses = ['NBN Administrator', 'NBN Read Only'];
      }
      if (this.customerDetailsList.length && ((this.customerDetailsList.some(list => list.customername.toUpperCase() === 'INFRACO') && (e == 'NBNCo'))
        || (this.customerDetailsList.some(list => list.customername === 'NBNCo') && (e.toUpperCase() == 'INFRACO')))) {
        this.showToasterMessage('Selected role is not applicable for the user domain. Please add a different role.', '', this.options, 'error', 'toast-middle-center');
        this.disableSaveButton = true;
      } else {
        this.disableSaveButton = false;
      }
    } else if (this.isDuctRole) {
      if (e.toUpperCase() == 'INFRACO') {
        this.roleAccesses = ['Duct Administrator', 'Duct Read & Write'];
      } else {
        this.roleAccesses = ['Duct Read & Write'];
      }
    }
    this.showErrorMsgRole = false;
    this.isRoleFunctionValid = true;
    this.isRoleFunctionTouched = false
  }

  checkFormValidation = () => {
    if (this.modifyUserForm.controls['username'].value && this.modifyUserForm.controls['firstname'].value && this.modifyUserForm.controls['lastname'].value && !this.disableSaveButton) {
    // && this.modifyUserForm.controls.customername?.valid && this.modifyUserForm.controls.application?.valid &&
    // this.modifyUserForm.controls.roleaccess?.valid && this.checkedRoleFunctions?.length>0 && !this.disableSaveButton){
      return false
    } else {
      return true
    }
  }

  saveCustomer = (): void => {
    if (this.modifyUserForm.controls.customername.invalid) {
      this.showErrorMsg = true;
    }
    if (this.modifyUserForm.controls.roleaccess.invalid) {
      this.showErrorMsgRole = true;
    }
    if (this.checkedRoleFunctions.length < 1) {
      this.isRoleFunctionValid = false;
    }
    if (this.modifyUserForm.valid && this.checkedRoleFunctions.length) {
      let isCustomerError = false;
      this.checkedRoleFunctions.forEach(role => {
        if (this.customerDetailsList.some(list => list.rolefunctions === role && list.roleaccess === this.modifyUserForm.value.roleaccess
          && list.customername === this.modifyUserForm.value.customername)) {
          this.showToasterMessage('Role already added. Please add a different role', '', this.options, 'error', 'toast-middle-center');
          this.divToScroll.nativeElement.scrollTop = 0;
          isCustomerError = true;
          return false;
        }
      });
      if (!isCustomerError) {
        //fetch the cidn of the selected customer
        let findCIDNobject;
        findCIDNobject = this.searchCidn(this.myLinkedCustomerListFromCaiman, this.modifyUserForm.value.customername)
        if (!findCIDNobject?.cidn) {
          findCIDNobject = this.searchCidn(this.linkedCIDNs, this.modifyUserForm.value.customername)
        }
        this.checkedRoleFunctions.forEach(each => {
          this.customerDetailsList.push({
            cidn: findCIDNobject?.cidn,
            customername: this.modifyUserForm.controls['customername'].value,
            application: this.modifyUserForm.controls['application'].value,
            roleaccess: this.modifyUserForm.controls['roleaccess'].value,
            rolefunctions: each
          })
        });
        this.resetControls();
        this.toAddCustomer = false;
        this.isRoleFunctionValid = true;
        this.isNewRoleAdded = true;
      }
    } else {
      if (!this.modifyUserForm.controls['customername'].value) {
        this.isCustomerNameValid = false;
      }
      if (!this.modifyUserForm.controls['roleaccess'].value) {
        this.isRoleAccessValid = false;
      }
      if (!this.checkedRoleFunctions.length) {
        this.isRoleFunctionValid = false;
      }
    }
  }

  addRoleAccess = () => {
    this.isRoleAccessEnabled = !this.isRoleAccessEnabled;
    this.toAddCustomer = true;
    this.functionList = [];
    setTimeout(() => this.tref.nativeElement.focus(), 1);
    this.showErrorMsg = false;
    this.showErrorMsgRole = false;
    this.isRoleFunctionTouched = false;
  }

  cancel = () => {
    this.moveTab.emit('user');
  }

  shareCheckedList = (item: any[]) => {
    this.checkedRoleFunctions = item;
    this.isRoleFunctionValid = true;
  }

  shareIndividualCheckedList = (item: {}) => {
  }

  removeCustomerDetails(index) {
    if (index > -1) { // only splice array when item is found
      //save values for the deleted roles
      this.deletedExistingRoles.push({
        cidn: this.customerDetailsList[index].cidn,
        customername: this.customerDetailsList[index].customername,
        roleaccess: this.customerDetailsList[index].roleaccess,
        rolefunctions: this.customerDetailsList[index].rolefunctions,
        application: this.customerDetailsList[index].application
      });
      this.customerDetailsList.splice(index, 1); // 2nd parameter means remove one item only
      this.previousRoleRemoved = true;
      this.announcement();
      document.getElementById('removeBtn').setAttribute('aria-label', 'successfully removed');
    }
  }

  announcement() {
    const announcement = document.createElement('div');
    announcement.setAttribute('role', 'alert');
    announcement.setAttribute('aria-live', 'assertive');
    announcement.textContent = 'Successfully Removed';
    document.body.appendChild(announcement);
    setTimeout(() => {
      document.body.removeChild(announcement)
    }, 1000)

  }
  checkSubmitBtnDisabled = () => {
    if (this.modifyUserForm.get('status').value === this.statuses[0] &&
      this.modifyUserForm.controls['firstname'].valid && this.modifyUserForm.controls['lastname'].valid &&
      this.modifyUserForm.controls['mobilenumber'].valid && this.customerDetailsList.length && !this.toAddCustomer) {
      let dataModified = false;
      if (this.modifyUserForm.get('firstname').value != this.userDataToModify.firstname || this.modifyUserForm.get('lastname').value != this.userDataToModify.lastname ||
        this.modifyUserForm.get('mobilenumber').value != this.userDataToModify.mobilenumber || this.modifyUserForm.get('status').value != this.userDataToModify.status) {
        dataModified = true;
      }
      if (this.isNewRoleAdded || this.previousRoleRemoved) {
        dataModified = true;
      }
      if (dataModified === true) {
        return false;
      } else {
        return true;
      }
    } else if (this.modifyUserForm.get('status').value != this.userDataToModify?.status && this.modifyUserForm.get('status').value === this.statuses[1] && this.modifyUserForm.controls['firstname'].value && this.modifyUserForm.controls['lastname'].value) {
      return false;
    } else return true
  }

  submitUserForm(): any {
    this.requestData();
    if (this.operations && this.operations.length === 0) {
      this.cancel();
    }
    else {
      this.userservice.toggleIsusperadim(false);
      this.userservice.modifyUser(this.operations, this.myUserId).then(res => {
        this.showToasterMessage(this.aemLabelAuthoring.form.modifyUserSuccessMessage
          , '', this.options, 'success', 'toast-top-center');
        this.cancel();
      }).catch((err) => {
        if (err.error.status === 401 && err.error.message.toLowerCase() === 'userdomain is not authorized') {
          this.domainErrorMessage = this.aemLabelAuthoring.form.domainErrorMessage;
        } else {
          this.showToasterMessage(this.aemLabelAuthoring.form.modifyUserFailedMessage
            , '', this.options, 'error', 'toast-middle-center');
          this.util.bringFocusToElement(window.event, 'toast-close-button', false);
          setTimeout(() => (document.getElementById('toast-container').
            getElementsByClassName('toast-close-button')[0] as HTMLElement).focus(), 0);
        }
      });
    }
  }

  removeRolesIfUserInactive() {
    if (this.modifyUserForm.get('status').value === this.statuses[1]) {
      this.customerDetailsList = [];
      this.previousRoleRemoved = true;
    }
  }

  requestData() {
    this.operations = [];
    this.newRowData = [];
    // check the USER STATUS if active/inactive; if INACTIVE, all the roles are to be removed
    this.removeRolesIfUserInactive();

    if (this.isNewRoleAdded) {
      this.newRowData = [];
      this.customerDetailsList.forEach(val => {
        if (!this.existingRolesToModify.some(temp => temp.roleaccess === val.roleaccess && temp.cidn === val.cidn && temp.rolefunctions === val.rolefunctions)) {
          this.newRowData.push({
            role: val.roleaccess,// this.roleMapping[val.role].roleName,
            cidn: val.cidn,
            functionName: val.rolefunctions.toString(),
            customerName: val.customername,
            appName: val.application,
            status: this.modifyUserForm.get('status').value
          });
        }
      });
    }
    if (this.newRowData.length > 0) {
      this.operations.push({ 'op': 'add', 'roles': this.newRowData });
    }
    if (this.previousRoleRemoved) {
      let removeRole = [];
      this.existingRolesToModify.forEach(val => {
        if (!this.customerDetailsList.some(temp => temp.roleaccess === val.roleaccess && temp.cidn === val.cidn && temp.rolefunctions === val.rolefunctions)) {
          removeRole.push({
            role: val.application + '_' + val.roleaccess + '_' + val.rolefunctions?.toString(),// this.roleMapping[val.role].roleName,
            cidn: val.cidn
          });
        }
      });
      this.operations.push({
        op: 'remove',
        removeRoles: removeRole
      });
    }

    if (this.userDataToModify.firstname !== this.modifyUserForm.value.firstname ||
      this.userDataToModify.lastname !== this.modifyUserForm.value.lastname ||
      this.userDataToModify.mobilenumber !== this.modifyUserForm.value.mobilenumber ||
      this.userDataToModify.status !== this.modifyUserForm.value.status) {
      this.operations.push({
        op: 'replace',
        name: {
          givenName: this.modifyUserForm.getRawValue().firstname,
          givenNameUpdated: this.userDataToModify.firstname !== this.modifyUserForm.getRawValue().firstname,
          familyName: this.modifyUserForm.getRawValue().lastname,
          familyNameUpdated: this.userDataToModify.lastname !== this.modifyUserForm.getRawValue().lastname
        },
        phoneNumber: this.modifyUserForm.getRawValue().mobilenumber,
        phoneNumberUpdate: this.userDataToModify.mobilenumber !== this.modifyUserForm.getRawValue().mobilenumber,
        status: this.modifyUserForm.value.status, // this.modifyUserForm.value.status === this.statuses[0] ? 'true' : 'false',
        statusUpdated: this.userDataToModify.status !== this.modifyUserForm.value.status
      });
    }
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string, divPosition: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = divPosition; //'toast-middle-center';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = false;
    this.options.timeOut = 5000;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = true;
    this.toastr.show(
      message,
      header === '' ? null : header,
      options,
      this.options.iconClasses[type]
    );
    document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
    document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
  }

  searchCidn(myArray, nameKey) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].bussinessName === nameKey) {
        return myArray[i];
      }
    }
  }

  buildRoleBasedOnLabelsSaved(value) {
    if (value === 'infraco') {
      return 'InfraCo Customer Portal';
    }
    if (value === 'admin') {
      return 'Administrator';
    }
    if (value === 'readwrite') {
      return 'Read & Write';
    }
    if (value === 'readonly') {
      return 'Read Only';
    }
    if (value === 'customermgmt') {
      return 'Customer Management';
    }
    if (value === 'nbncabinet') {
      return 'NBN Cabinets';
    }
    if (value === 'nbnread') {
      return 'NBN Read Only';
    }
    if (value === 'nbnreadwrite') {
      return 'NBN Read & Write';
    }
    if (value === 'nbnadmin') {
      return 'NBN Administrator';
    }
    if (value === 'nbndocument') {
      return 'NBN DA Documents';
    }
    if (value === 'usermgmt') {
      return 'User Management';
    }
    if (value === 'nbnusermgmt') {
      return 'NBN User Management';
    }
    if (value === 'nbncabinetmgmt') {
      return 'NBN Cabinet Access Management';
    }
    if (value === 'ductadmin') {
      return 'Duct Administrator';
    }
    if (value === 'ductreadwrite') {
      return 'Duct Read & Write';
    }
    if (value === 'ductusermgmt') {
      return 'Duct User Management';
    }
    if (value === 'ductaccessform') {
      return 'Duct Access Form';
    }
    if (value === 'quotemgmt') {
      return 'Quote Request Management';
    }
    if (value === 'ordermgmt') {
      return 'Order Management';
    }
    if (value === 'servicemgmt') {
      return 'Service Management';
    }
    if (value === 'documentmgmt') {
      return 'Document Management';
    }
  }
  touchCustomerName() {
    this.showErrorMsg = true;
  }

  touchRoleAccess() {
    this.showErrorMsgRole = true;
  }

  touchRoleFunction() {
    this.isRoleFunctionTouched = true;
  }
  ngOnDestroy(): void {
    this.commonservice.showTopButtonInFooter(false);
    this.defaultCustSubscription.unsubscribe();
  }
}
