import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';
import { Observable,Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import 'rxjs/add/operator/delay';
import { AuthService } from 'tw-core-ui';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http:HttpClient, private authService: AuthService) { }

  public get<T>(path:string,params?:Params):Observable<T>{
    let queryParams:Params={};
    if(params){
      queryParams = this.setParameters(params);
    }
    return this.http.get<T>(this.path(path),{params:queryParams});
}

public post<T>(path:string,body:any,params?:Params):Observable<any>{
  let queryParams:Params={};
  if(params){
    queryParams = this.setParameters(params);
  }
  return this.http.post(this.path(path),body,{params:queryParams});
}

public put<T>(path:string,body:any,params?:Params):Observable<any>{
  let queryParams:Params={};
  if(params){
    queryParams = this.setParameters(params);
  }
  return this.http.put(this.path(path),body,{params:queryParams});
}

private setParameters(params:Params){
  let queryParams = new HttpParams();
  for(const key in params){
    if(params.hasOwnProperty(key)){
      queryParams = queryParams.set(key,params[key])
    }
  }
  return queryParams;
}

getHeaders() {
  return {
    headers: new HttpHeaders({
      'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',
    })
  };
}

private path(path:string){
  //let isProdEnv = `${environment.production}`;
  return `${environment.apiPoint}${path}`;
}
}
