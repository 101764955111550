<form [formGroup]="latLngForm" autocomplete="off">
  <div class="d-flex flex-column py-4 w-100 lat-long-div">
    <div class="d-flex w-100 gap-4 py-4 latlong-container">
      <div class="d-flex flex-column w-50">
        <label class="product-details-secondary-label" for="latitude">Latitude <span
            class="inpt-reqrd-cls" aria-hidden="true">*</span></label>
        <div aria-live="polite" aria-atomic="true" aria-relevant="additions">
          <input aria-required="true" formControlName="latitude"  aria-label="Latitude Search" placeholder="For example: -37.809981" class="text-box-quote" type="text"
            maxlength="20"
            aria-describedby="latitude_error"
            [ngClass]="{'invalid-form-control': (latLngForm.controls.latitude.touched && latLngForm.controls.latitude.invalid)}">
          <ng-container
            *ngIf="latLngForm.controls['latitude'].invalid && (latLngForm.controls['latitude'].dirty || latLngForm.controls['latitude'].touched)">
            <span id="latitude_error" *ngIf="latLngForm.controls['latitude'].errors.required" class="help-block errorMsg"><img
                class="error-icon" src="../../../../assets/images/icon-error.svg" />Please enter Latitude</span>
            <span id="latitude_error" role="alert"
              *ngIf="!latLngForm.controls['latitude'].errors.required && latLngForm.controls['latitude'].invalid"
              class="help-block errorMsg"><img class="error-icon"
                src="../../../../assets/images/icon-error.svg" />Please enter a valid Latitude</span>
          </ng-container>
        </div>
      </div>
      <div class="d-flex flex-column w-50">
        <label class="product-details-secondary-label" for="longitude">Longitude <span
            class="inpt-reqrd-cls" aria-hidden="true">*</span></label>
        <div aria-live="polite" aria-atomic="true" aria-relevant="additions">
          <input aria-required="true" aria-label="Longitude Search" formControlName="longitude" class="text-box-quote" placeholder="For example: 144.969588" type="text"
            maxlength="20"
            aria-describedby="longitude_error"
            [ngClass]="{'invalid-form-control': (latLngForm.controls.longitude.touched && latLngForm.controls.longitude.invalid)}">
          <ng-container
            *ngIf="latLngForm.controls['longitude'].invalid && (latLngForm.controls['longitude'].dirty || latLngForm.controls['longitude'].touched)">
            <small role="alert" id="longitude_error" *ngIf="latLngForm.controls['longitude'].errors.required" class="text-danger errorMsg">
              <img class="error-icon" src="../../../../assets/images/icon-error.svg" />Please enter Longitude
            </small>
            <small role="alert" id="longitude_error" *ngIf="!latLngForm.controls['longitude'].errors.required && latLngForm.controls['longitude'].invalid"
              class="text-danger errorMsg">
              <img class="error-icon" src="../../../../assets/images/icon-error.svg" />Please enter a valid Longitude
            </small>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end gap-4">
      <button mat-button (click)="resetForm()" class="cancelBtn save-cancel-btn"
        [disabled]="!latLngForm.value.latitude || !latLngForm.value.latitude">
        <span class="cancel-btn">
          <i class="icon-icon-ui-cross"></i>Clear
        </span>
      </button>
      <button [disabled]="latLngForm.invalid" type="button"
        class="btn btn-primary d-flex gap-2 justify-content-center align-items-center search-btn"
        (click)="searchViaLatLng()"
        [disabled]="latLngForm.controls['longitude'].invalid || latLngForm.controls['longitude'].errors || latLngForm.controls['latitude'].errors || latLngForm.controls['latitude'].invalid">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi"
          viewBox="0 0 16 16">
          <path
            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
        </svg>
        Search
      </button>
    </div>
    <div *ngIf="searchResults.length" class="lat-searchbox-title">Search result</div>
    <ng-container *ngIf="searchResults.length">
      <div style="position:relative">
        <img src="../../../../../../assets/images/Search.svg" class="bi bi-search" />
        <input type="text" formControlName="address" class="address-input-text" />
      </div>
      <!-- <div style="padding-top: 10px;padding-bottom: 10px;color: red;" *ngIf="this.latLngForm.controls['address'].value === '(New address)'">
        Hoops! address not found
      </div> -->
      <div *ngIf="showAddressContainer" class="outer-address-container">
        <div class="address-container">
          <div class="multi-adr-lbl" *ngIf="searchResults?.length>1">We found multiple addresses</div>
          <div class="address-options" *ngFor="let result of searchResults" (click)="selectAddress(result)">
            <div class="primary-adr">{{result?.address}} </div>
            <span *ngIf="result?.subaddresses?.length>0">
              <span *ngIf="!showSubAddresses"><img src="../../../../../../assets/images/BlackCircleArrow.svg" /></span>
              <span *ngIf="showSubAddresses"><img
                  src="../../../../../../assets/images/BlackCircleDownArrow.svg" /></span>
            </span>
            <div *ngIf="result?.subaddresses?.length>0 && showSubAddresses">
              <div class="sub-address-options" *ngFor="let subAddress of result?.subaddresses"
                (click)="selectSubAddress(subAddress)">
                {{subAddress.address}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-5" *ngIf="!preDefinedSiteNode">
        <div class="Is-the-address-a-defined-property-location text-nowrap">
          <span>Is the address a defined property location?
            <span class="text-danger" aria-hidden="true">*</span>
          </span>
        </div>
        <div class="For-example-Has-your-address-been-recognised-by-your-local-council">
          For example: Has your address been recognised by your local council?
        </div>
        <div class="radio-btn-container radio-left">
          <mat-radio-group formControlName="definedPropertyLocation">
            <div
              tabindex="0"
              id="inlineRadio1"
              role="radio"
              [attr.aria-checked]="latLngForm.value.definedPropertyLocation === 'Yes' ? 'true' : 'false'"
              (click)="onChangeRadioButton('Yes')"
              (keyup.enter)="onChangeRadioButton('Yes')"
              class="form-check form-check-inline">

              <mat-radio-button
                class="col-2 smart-ui-button-group diversity-radio-btn"
                [value]="aemLabelAuthoring?.label?.yes">
                <div class="mat-custom-label">
                  <span aria-label="Is the address a defined property location? Yes" value="Yes">{{aemLabelAuthoring?.label?.yes}}</span>
                </div>
              </mat-radio-button>
            </div>

            <div
              tabindex="0"
              id="inlineRadio2"
              role="radio"
              [attr.aria-checked]="latLngForm.value.definedPropertyLocation === 'No' ? 'true' : 'false'"
              (click)="onChangeRadioButton('No')"
              (keyup.enter)="onChangeRadioButton('No')"
              class="form-check form-check-inline">

              <mat-radio-button
                class="col-2 smart-ui-button-group diversity-radio-btn"
                [value]="aemLabelAuthoring?.label?.no">
                <div class="mat-custom-label">
                  <span aria-label="Is the address a defined property location? No" value="No">{{aemLabelAuthoring?.label?.no}}</span>
                </div>
              </mat-radio-button>
            </div>
          </mat-radio-group>

        </div>

      </div>

      <div class="col-12 mt-5">
        <div class="Provide-additional-address-information w-100 mb-0">
          Provide additional address information
          <span class="text-danger" aria-hidden="true">*</span>
        </div>
        <textarea aria-describedby="additionalAddressInfo_error" aria-required="true" aria-label="Provide additional address information" class="Path-13254 w-100" formControlName="additionalAddressInfo"
          placeholder="For example: New construction, LOT number, business estate"
          [ngClass]="{'invalid-form-control': (latLngForm.controls.additionalAddressInfo.touched && latLngForm.controls.additionalAddressInfo.invalid)}"
          maxlength="250"></textarea>
        <small class="pull-right text-success">{{latLngForm?.value?.additionalAddressInfo?.length}}/250</small>

        <ng-container
          *ngIf="latLngForm.controls['additionalAddressInfo'].invalid && (latLngForm.controls['additionalAddressInfo'].dirty || latLngForm.controls['additionalAddressInfo'].touched)">

          <small id="additionalAddressInfo_error" *ngIf="latLngForm.controls['additionalAddressInfo'].errors.required" class="text-danger errorMsg">
            <img class="error-icon" src="../../../../assets/images/icon-error.svg" /> Please enter additional address information
          </small>
          <small id="additionalAddressInfo_error"
            *ngIf="!latLngForm.controls['additionalAddressInfo'].errors.required && latLngForm.controls['additionalAddressInfo'].invalid"
            class="text-danger errorMsg">
            <img class="error-icon" src="../../../../assets/images/icon-error.svg" /> The following special characters [<>;] are unable to be used, please revise your input
          </small>

        </ng-container>
      </div>
    </ng-container>
  </div>
</form>
