<div class="withdraw-container">
  <!-- <div class="toastrContainer1" aria-live="polite" role="alert" toastContainer></div> -->
  <label style="position:absolute;left:-9999px" aria-live="assertive">Withdraw request pop up opens</label>
<div class="modal-header d-flex justify-content-between">
  <h4 class="modal-title labelHead"> {{aemLabelAuthoring?.pageHeader?.withdrawnRequest}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="onCancel()">
    <img class="close-icon" src="../../../../assets/images/Icon-Close-btn.svg" alt="upload icon" />
    <!-- <span aria-hidden="true">&times;</span> -->
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="withdrawRequestForm" class="row">
    <div class="row heading-text text-padding">
      <div class="col-6 text-bold-18">{{aemLabelAuthoring?.pageHeader?.withdrawnReason}}
        <span class="inpt-reqrd-cls">*</span>
      </div>
    </div>
    <div class="row ">
      <div class="form-group">
        <app-cui-searchable-dropdown name="withdrawalReason" [model]="withdrawnReasonModel" id="type" (click)="selectAlertReason()"
          [labelName]="'withdrawnReasonDropDown'" [placeholder]="'Select withdrawal reason'"
          formControlName="withdrawalReason" [isRequired]=true [items]=withdrawnReasonList automationType="dropdown"
          automationId="drpdwnwithdrawnReason" [noAutoComplete]=false id="withdrawalReason"
          class="customer-dropDown placeholder-label withdraw-dropdown"
          [ngClass]="{'invalid-form-control': (withdrawRequestForm.controls.withdrawalReason.invalid &&  withdrawRequestForm.controls.withdrawalReason.touched)}"
          required>
        </app-cui-searchable-dropdown>
        <span role="alert"
          *ngIf="showAlertReason && withdrawRequestForm.controls.withdrawalReason.invalid"
          class="help-block errorMsg color-red">
          <img class="error-icon" src="../../../../assets/images/icon-error.svg" /> {{aemLabelAuthoring?.pageHeader?.withdrawnReasonMessage}}
        </span>
      </div>
    </div>
    <div class="row label-padding">
      <div class="col-6 comment-label">{{aemLabelAuthoring?.pageHeader?.comments}}</div>
    </div>
    <div class="row heading-text details-font text-values">
      <div class="form-group col-6 reason-dropdown">
        <textarea class="form-control placeholder-label textbox-border" rows="4" id="additionalDetails" maxlength="1000"
          formControlName="additionalDetails" name="additionalDetails" attr.aria-label="document description"
          (keypress)="onCommentFieldKeyPress($event, withdrawRequestForm?.value?.additionalDetails?.length)"
          placeholder="Enter comment"
          [ngClass]="{'invalid-form-control': (withdrawRequestForm.controls.additionalDetails.dirty || withdrawRequestForm.controls.additionalDetails.touched) && withdrawRequestForm.invalid}"></textarea>
          <ng-container>
            <small *ngIf="withdrawRequestForm.controls['additionalDetails'].invalid && (withdrawRequestForm.controls['additionalDetails'].dirty || withdrawRequestForm.controls['additionalDetails'].touched)" class="text-danger">
              <img class="error-icon" src="../../../../../assets/images/icon-error.svg" />
              <!-- Please enter valid comments -->
              {{aemLabelAuthoring?.validationMsg?.specialCharaterValidation}}
            </small>
          </ng-container>

        <div class="text-right">
          <span class="text-right-green">{{withdrawRequestForm.value.additionalDetails.length}}/1000</span>
        </div>
      </div>
    </div>
    <div class="row note-text label-padding note-detailed" *ngIf="feasibilityType == 'Detailed' ">
      <img class="info-icon" src="../../../../assets/images/Icon-info.svg" alt="upload icon" />
      <span class="disclaimer-txt">{{aemLabelAuthoring?.pageHeader?.noteText}}</span>
    </div>
  </form>

</div>

</div>
<div class="footer">
  <button class="texttype-globalbtn-cls app-cancel-btn cancel" tabindex="0" (click)="onCancel()">
    <i class="icon-icon-ui-cross"></i>{{aemLabelAuthoring?.pageHeader?.cancel}}
  </button>
  <button class="primary-globalbtn-cls app-save-btn" [disabled]="!withdrawRequestForm.valid" (click)="onWithdrawn()"
    tabindex="0">{{aemLabelAuthoring?.pageHeader?.withdrawnBtn}}</button>
</div>
