import {
  AfterViewInit,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { userManagerObj } from 'src/environments/environment';
import { AuthService } from 'tw-core-ui';
import {
  CustomTextField,
  CustomDropDown,
} from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { HomePageService } from '../shared/services/homePage.service';
import { HostListener } from '@angular/core';
import { CustomerService } from '../admin-settings/customer-list/customer.service';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  public headerForm: FormGroup;
  focusableElement: HTMLElement;
  public businessNames: any[] = [];
  public counter = 0;
  public businessNameDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId:
      'Customer Name Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under',
  };
  public profleDrpdwnIsOpn = false;
  public isNBNuser = false;
  public isAuthorizedAdminRights = false;
  userDate: any = {};
  aemLabelAuthoring: any = '';
  headerModel: any = {};
  userName: any = '';
  contactName: string = sessionStorage.getItem('contactName')
    ? sessionStorage.getItem('contactName')
    : '';
  nameInitials: string = '';
  private userObj: any = userManagerObj;
  public logoutUrl =
    this.userObj.authority +
    '/idp/startSLO.ping?TargetResource=' +
    this.userObj.post_logout_redirect_uri;
  cidnList: any;
  dropdownArray: any;
  customerList: any;
  cidnSelected: any;
  roleAccess: any;
  roleFunction: any;
  linkedCIDNs: any;
  infraCoCIDN = window['environment'].InfraCoCIDN;
  custNames: any[] = [];
  businessList: any[];
  isNbnClicked: boolean = false;
  ifInfracoCustomerRole: boolean = false;
  showHeaderDropdown: boolean;
  headerRefreshCheck: boolean = false;
  defaultCustomer: any = {};
  showEIMenu: boolean = false;
  isEIMenuClicked: boolean = false;
  isFibreMenuClicked: boolean = false;
  public isAccessDeniedPage: boolean = false;
  customerListUpdated: boolean = false;
  showFibreDropdown: boolean;
  router: any;
  isNotificationOpen:boolean;
  isNotificationIconDisabled:boolean = false;
  notificationParams:any;

  constructor(
    public fb: FormBuilder,
    public commonservice: CommonService,
    private route: Router,
    private homepageservice: HomePageService,
    private authService: AuthService,
    private customerService: CustomerService,
    private titleService: Title
  ) {
    this.headerForm = this.fb.group({
      businessTypes: ['', [Validators.required]],
    });
  }
  userManager: any;
  ngOnInit() {
    this.userName = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail'];
    this.showMenuBasedOnRole();
    this.showBusinessNameDropdown();
    //this.disableHeaderDropdown();
    this.getLabels();
    this.notificationParams={
      isNotificationOpen:false,
      isNotificationIconDisabled:false
    }
  }

  ngAfterViewInit() {
    this.getCommonServices();
    this.getProfileDetails();
    this.nameInitials = this.contactName
      .match(/(\b\S)?/g)
      .join('')
      .match(/(^\S|\S$)?/g)
      .join('')
      .toUpperCase();
  }

  getCommonServices = () => {
    const headerReset = this.commonservice.getHeaderValueChangeTrace();
    if (!headerReset && !this.customerListUpdated) {
      this.commonservice
        .getDefaultCustomer()
        .pipe()
        .subscribe((resp) => {
          if (resp) {
            this.headerForm.get('businessTypes')?.setValue(resp);
            this.commonservice.setheadervalPage(resp);
          } else {
            this.headerRefreshCheck = true;
            //this.checkRouteUrl();
          }
        });
    }

    // to reset the customer dropdown list first to enlist newly created customers and then populated the selected CIDN
    this.commonservice.getHeaderCustomerDropdown().pipe().subscribe((resp) => {
      if (resp) {
        this.businessNames = [];
        this.headerForm.get('businessTypes')?.reset();
        this.linkedCIDNs = [];
        this.custNames = [];
        //get fresh list of customers
        this.headerRefreshCheck = true;
        this.getProfileDetails();
        this.customerListUpdated = true;
        // reset customer list to false
        this.commonservice.setHeaderCustomerDropdown(false);
        //
      }
      else {
        this.customerListUpdated = false;
      }
    });
  };

  getLabels = () => {
    return new Promise((resolve, reject) => {
      this.homepageservice
        .getLabels()
        .then((res) => {
          this.aemLabelAuthoring = res;
          this.headerModel = this.aemLabelAuthoring.form.header;
          resolve(true);
        })
        .catch(() => {
        });
    });
  };

  hubKeypress(e) {
    if (e) {
      this.route.navigate(['/']);
    }
  }

  getProfileDetails = () => {
    this.homepageservice
      .getUserProfile(this.userName)
      .then((response) => {
        this.defaultCustomer = response.data?.preferenceReference;
        this.dropdownArray = response.data?.customerDetails ? response.data.customerDetails : [];
        this.dropdownArray.forEach((option) => {
          if (option.cidn === this.infraCoCIDN) {
            //match Infraco cidn to get all cutomer list to show in dropdown
            if (this.isNBNuser) {
              this.matchNBNCidn();
            } else {
              this.matchCIDN();
            }
          } else {
            const customerName = option.customerName;
            if (!this.businessNames.includes(customerName)) {
              this.businessNames.push(customerName);
            }
          }
        });
        if (this.headerRefreshCheck) {
          this.getPreferencesDetails();
          //this.checkRouteUrl();
        }
      })
      .catch((err) => {
      });
  };

  matchCIDN = () => {
    //get all roles
    const getRolesFromCaiman = this.commonservice.getroleDetails().customerList;
    //get all customers
    this.customerService.getLinkedCIDNs().then((res) => {
      this.linkedCIDNs = res.data.businessNameDetails;
      //match customers condition
      this.linkedCIDNs.forEach((item) => {
        const isDuctAdmin = getRolesFromCaiman.some(a => a.role === 'Duct Administrator')
        getRolesFromCaiman.forEach((ele) => {
          if ((ele.role === 'Administrator' || ele.role === 'Read Only' || isDuctAdmin) && item.status === 'Active') {
            this.custNames.push(item.bussinessName);
            this.businessNames = [...new Set(this.custNames)];
          }
          if (ele.role == 'Duct Read & Write' && ele.function === 'Duct Access Form') {
            this.dropdownArray.forEach((option) => {
              this.businessNames.push(option.customerName);
              this.businessNames = [...new Set(this.businessNames)];
            });

          }

        });

      });

    })

      .catch((err) => { });

  };



  matchNBNCidn = () => {
    const getRolesFromCaiman = this.commonservice.getroleDetails().customerList;
    this.customerService.getLinkedCIDNs().then((res) => {
      this.linkedCIDNs = res.data.businessNameDetails;
      this.linkedCIDNs.map((item) => {
        getRolesFromCaiman.forEach((ele) => {
          const customerDetails = this.homepageservice.getCustomerDetails();
          if (ele.role === 'NBN Administrator' && item.status === 'Active' && customerDetails.isInfraCoCustomer) {
            this.custNames.push(item.bussinessName);
          }
        });
      });
      this.businessNames = [...new Set(this.custNames)];
    }).catch((err) => { });
  }

  getPreferencesDetails = () => {

    this.userName = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail']
    this.homepageservice.getPreferences(this.userName).then(response => {
      // matching existing preferences with hubpreferences
      const hubPreference = response.data?.hubPreferences ? response.data.hubPreferences: [];
      hubPreference.forEach(hub => {
        this.defaultCustomer.forEach(preference => {
          if (hub.preferenceType === preference.preferenceType &&
            hub.preferenceCategory === preference.preferenceCategory) {
            this.commonservice.setDefaultCustomer(hub.preference);
            this.setCustomerCidn(hub.preference)
          }
        });
      });
    })
      .catch((err) => {
      });
  };

   trap(){
    setTimeout(() => {
      this.focusableElement = document.getElementById('one');
      this.focusableElement.focus();
    }, 1);
    this.commonservice.trapFocusInModal('one', 'last');
  }

  profileClick = (pageLink?) => {
    this.commonservice.showCreateUser(false);
    this.profleDrpdwnIsOpn = !this.profleDrpdwnIsOpn;
    if (pageLink === 'Profile') {
      this.route.navigate(['/profile'])
    } else if (pageLink === 'Admin') {
      this.route.navigate(['/admin'])
    } else if (pageLink === 'SignOut') {
      this.logout();
    }
  };

  closeDropdownOnEnter(event: KeyboardEvent) {
    this.titleService.setTitle('');
    event.preventDefault();
    this.profleDrpdwnIsOpn = !this.profleDrpdwnIsOpn;
    // this.commonservice.trapFocusInModal('one', 'last');
    this.commonservice.showCreateUser(false);
  }

  mouseLeaveEvent = () => {
    this.profleDrpdwnIsOpn = false;
  };

  roleChange(customerName: string) {
    if (customerName) {
      this.commonservice.setheadervalPage(customerName);
    }

    this.customerService
      .getLinkedCIDNs()
      .then((res) => {
        if (res) {
          let businessList = [];
          const iteratedArr = res.data.businessNameDetails.map(
            ({ bussinessName, cidn }) => [bussinessName, cidn]
          );
          for (var i = 0; i < iteratedArr.length; i++) {
            const businessName = iteratedArr[i][0];
            const cidn = iteratedArr[i][1];
            if (businessName || cidn) {
              businessList.push({
                businessName: businessName,
                cidn: cidn,
              });
            }
          }
          businessList.forEach((item) => {
            if (item.businessName === customerName) {
              const cidn = item.cidn;
              this.commonservice.setSelectedCidn(cidn);
            }
          });
        } else {
          this.dropdownArray.forEach((item) => {
            if (item.customerName === customerName) {
              // return item.cidn;
              this.commonservice.setSelectedCidn(item.cidn);
            }
          });
        }
      })
      .catch((err) => { });
    this.setCustomerCidn(customerName);
    if (customerName !== sessionStorage.getItem('defaultCustomerValue')) {
      this.commonservice.setDefaultCustomer(customerName);
      this.commonservice.setHeaderValueChangeTrace(true);
    }
  }

  setCustomerCidn = (selectedHeaderValue?: string) => {
    const profileDetails = this.homepageservice.userProfileDetails;
    if (profileDetails) {
      const custDetails = profileDetails?.data?.customerDetails;
      if (selectedHeaderValue) {
        const findCIDNobject = this.searchCidn(custDetails, selectedHeaderValue)
       this.cidnSelected = findCIDNobject?.cidn;
          // this.cidnSelected = '9999999998';
        this.showMenuBasedOnRole();
        localStorage.setItem('selectedHeaderCIDN', this.cidnSelected)
      }
    }
  }

  searchCidn(myArray, nameKey) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].customerName === nameKey) {
        return myArray[i];
      }
    }
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    this.logout();
  }

  keydownTab(event) {
    this.commonservice.focusOnElement('header_logo');
  }

  focusToMainContent() {
    if (this.route.url === '/') {
      this.manageHeaderFocus('welcomemMsg');
    } else if (this.route.url === '/profile') {
      this.manageHeaderFocus('profileHeader');
    } else if (this.route.url === '/admin') {
      this.manageHeaderFocus('adminBtn');
    }
    else if (this.route.url === '/create-user') {
      this.manageHeaderFocus('createUser');
    }
    else if (this.route.url === '/cabinet') {
      this.manageHeaderFocus('upload');
    }
    else if (this.route.url === '/manage-cabinet-access') {
      this.manageHeaderFocus('access');
    }
    else if (this.route.url === '/duct-access-form') {
      this.manageHeaderFocus('duct');
    }
    else if (this.route.url === '/product-details') {
      this.manageHeaderFocus('productDetailsHeader');
    }
    else if (this.route.url === '/site-details') {
      this.manageHeaderFocus('siteDetailsHeader');
    }
    else if (this.route.url === '/request-details') {
      this.manageHeaderFocus('requestDetailsHeader');
    }
    else if (this.route.url === '/review-submit') {
      this.manageHeaderFocus('reviewSubmitHeader');
    }
    else if (this.route.url === '/success') {
      this.manageHeaderFocus('successScreenHeader');
    }
    else if (this.route.url === '/order-success') {
      this.manageHeaderFocus('successScreenHeader');
    }
    else if (this.route.url === '/bulk-quote-success') {
      this.manageHeaderFocus('successScreenHeader');
    }
    else if (this.route.url === '/myQuotes') {
      this.manageHeaderFocus('myQuotesHeader');
    }
    else if (this.route.url.startsWith('/quoteSummary')) {
      this.manageHeaderFocus('quoteSummaryHeader');
    }
    else if (this.route.url === '/myOrders') {
      this.manageHeaderFocus('myOrdersHeader');
    }
    else if (this.route.url.startsWith('/order-site-details')) {
      this.manageHeaderFocus('orderSiteDetailsHeader');
    }
    else if (this.route.url.startsWith('/order-request-details')) {
      this.manageHeaderFocus('orderRequestDetailsHeader');
    }
    else if (this.route.url.startsWith('/order-review-submit')) {
      this.manageHeaderFocus('orderReviewDetailsHeader');
    }
    else if (this.route.url === '/order-review') {
      this.manageHeaderFocus('myOrdersHeader');
    }
    else if(this.route.url.startsWith('/orderSummary')){
      this.manageHeaderFocus('quoteSummaryHeader');
    }
    else if (this.route.url.startsWith('/bulk-quote-request-details')) {
      this.manageHeaderFocus('bulkQuoteRequestDetailsHeader');
    }
    else if (this.route.url.startsWith('/bulk-quote-upload')) {
      this.manageHeaderFocus('bulkQuoteUploadHeader');
    }
  }

  manageHeaderFocus(id: string) {
    let focusableElement;
    focusableElement = document.getElementById(id);
    if (focusableElement) {
      focusableElement.setAttribute('tabindex', '0');
      focusableElement.addEventListener('blur', () => {
        focusableElement.removeAttribute('tabindex');
      });
      setTimeout(() => {
        if (focusableElement) {
          focusableElement.focus();
        }
      }, 10);
    }
  }

  logout(): any {
    this.authService.removeUser();
    this.profleDrpdwnIsOpn = !this.profleDrpdwnIsOpn;
    sessionStorage.clear();
    window.sessionStorage.clear();
    this.logOutThenNavigate();
  }


  logOutThenNavigate() {
   // this.retrieveDataPromise().then(() => {
     // this.authService.clearState();
      setTimeout(() => { // <--- Change it - your service data retrieval
        sessionStorage.clear();
        window.sessionStorage.clear();
        window.location.href = this.logoutUrl;
        window.sessionStorage && window.sessionStorage.clear();
      }, 1000);
 //   });
  }

  // retrieveDataPromise(): Promise<any> {
  //   return new Promise((resolve) => {
  //     // this.retrieveDataResolver = resolve;
  //     // this.authService.removeUser();
  //   })
  // }

  nbnClick() {
    this.isNbnClicked = !this.isNbnClicked;
    if(this.isNbnClicked) {
      document.getElementById('NBNDropdown').setAttribute('aria-label', 'expanded');
      } else {
        document.getElementById('NBNDropdown').setAttribute('aria-label', 'collapsed');
      }
  }

  closeDropdownMenu() {
    this.isNbnClicked = false;
    this.isEIMenuClicked = false;
    this.isFibreMenuClicked = false;
  }

  showMenuBasedOnRole = () => {
    const roles = this.commonservice.getroleDetails().customerList;
    const isUserMgmtRole = roles.some((obj) => (this.cidnSelected === this.infraCoCIDN && obj?.role === 'Administrator' && obj?.function === 'User Management'));
    const isQuoteMgmtRole = roles.some((obj) => (obj?.cidn === this.cidnSelected && obj?.function === 'Quote Request Management'));
    const isOrderMgmtRole = roles.some((obj) => (obj?.cidn === this.cidnSelected && obj?.function === 'Order Management'));

    this.homepageservice
      .getUserProfile(this.userName)
      .then((response) => {
        const custDetails = response?.data?.customerDetails;
        if (isUserMgmtRole || isQuoteMgmtRole || isOrderMgmtRole) {
          this.showFibreDropdown = true;
          this.isNBNuser = false;
          this.showEIMenu = false;
        } else if (
          roles.some(
            (obj) => obj.role.includes('NBN') || obj.function.includes('NBN')
          )
        ) {
          this.isNBNuser = true;
          this.showEIMenu = false;
          this.showFibreDropdown = false;
        } else if (roles.some(
          (obj) => obj.role.includes('Duct') || obj.function.includes('Duct')
        )) {
          this.isNBNuser = false;
          this.showEIMenu = true;
          this.showFibreDropdown = false;
        } else {
          this.isNBNuser = false;
          this.showEIMenu = false;
          this.showFibreDropdown = false;
        }
      })

  }

  showBusinessNameDropdown() {
    const roles = this.commonservice.getroleDetails();
    const customer = roles.customerList;
    if (customer.some(a => a?.role === 'NBN Administrator' && a?.function === 'NBN User Management')
      || customer.some(a => a?.role === 'Administrator' && a?.function === 'Customer Management')
      || customer.some(a => a?.role === 'Administrator' && a?.function === 'User Management')
      || customer.some(a => a?.role === 'Duct Administrator' && a?.function === 'Duct User Management')) {
      this.isAuthorizedAdminRights = true;
    } else {
      this.isAuthorizedAdminRights = false;
    }
  }

  showHeaderBussinessDropdown = () => {
    const customerDetails = this.homepageservice.getCustomerDetails();
    const isNbnAdminPage = (!((this.route.url === '/admin') || (this.route.url === '/create-user')) && this.isNBNuser);
    if (customerDetails.isNBNCustomer) {
      this.showHeaderDropdown = false;
    } else if (customerDetails.isInfraCoCustomer && isNbnAdminPage) {
      this.showHeaderDropdown = false;
    } else {
      this.showHeaderDropdown = true;
    }
    return this.showHeaderDropdown;
  }

  disableHeaderDropdown() {
    this.route.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.checkRouteUrl();
    })
  }

  checkRouteUrl() {
    const isQuoteScreen = ((this.route.url === '/product-details') || (this.route.url === '/site-details') || (this.route.url === '/request-details') || (this.route.url === '/review-submit') || (this.route.url === '/myQuotes') || (this.route.url.startsWith('/quoteSummary')))
    if (isQuoteScreen) {
      this.businessNameDropDownModel.isDisabled = true;
    } else {
      this.businessNameDropDownModel.isDisabled = false;
    }
  }

  EIMenuClick() {
    this.isEIMenuClicked = !this.isEIMenuClicked;
    if(this.isEIMenuClicked) {
    document.getElementById('EIDropdown').setAttribute('aria-label', 'expanded');
    } else {
      document.getElementById('EIDropdown').setAttribute('aria-label', 'collapsed');
    }
  }

  fibreMenuClick() {
    this.isFibreMenuClicked = !this.isFibreMenuClicked;
    if(this.isFibreMenuClicked) {
      document.getElementById('fibreDropdown').setAttribute('aria-label', 'expanded');
      } else {
        document.getElementById('fibreDropdown').setAttribute('aria-label', 'collapsed');
      }
  }

  showDropdown(event) {
    this.isFibreMenuClicked = event;
  }

    // if (this.commonService.getDefaultCustomer()) {
    //   this.prodHeaderChange = this.commonService.getDefaultCustomer().pipe().subscribe(resp => {

    //     this.businessList.forEach(item => {

    //       if (item.businessName === resp) {
    //         this.cidnSelected = item.cidn;
    //         localStorage.setItem('selectedHeaderCIDN', this.cidnSelected)
    //         const roles = this.commonService.getroleDetails().customerList;
    //         const isReadWriteOnQuoteMgmt = roles.some((obj) => (obj?.cidn === this.cidnSelected && obj?.role === 'Read & Write' && obj?.function === 'Quote Request Management'));
    //         const isReadOnlyRole = roles.some((obj) => (obj?.cidn === this.cidnSelected && obj?.role === 'Read Only' && obj?.function === 'Quote Request Management'));
    //         if (isReadWriteOnQuoteMgmt || isReadOnlyRole) {
    //           this.router.navigate(['/myQuotes']);
    //         }
    //         else {
    //           this.router.navigate(['']);
    //         }

    //       }

    //     })

    //   })
    // }

  @HostListener('window:keydown', ['$event'])
  handleKeyUp(event) {
    if (event.keyCode === 27) {
      this.profleDrpdwnIsOpn = false;
    }
  }

  getNotifications() {
    this.notificationParams={
      isNotificationOpen:true,
      isNotificationIconDisabled:true
    }
  }

}
