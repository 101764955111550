import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { CustomAgGrid, CustomTextField, CustomDropDown } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { DashboardService } from './../cabinets/dashboard.service';
import { CheckboxFilterComponent } from '../grid-filter/checkbox-filter/checkbox-filter.component';
import { CustomTextFilterComponent } from '../grid-filter/custom-text-filter/custom-text-filter.component';
import { CustomDateFilterComponent } from '../grid-filter/custom-date-filter/custom-date-filter.component';
import { WhitelistService } from './whitelist.service';
import * as moment_ from 'moment';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { isNumber } from '@ng-bootstrap/ng-bootstrap/util/util';
import { CommonService } from '../shared/services/common.service';
import { HomePageService } from '../shared/services/homePage.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-whitelist',
  templateUrl: './whitelist.component.html',
  styleUrls: ['./whitelist.component.less']
})
export class WhitelistComponent implements OnInit {
  cidn: string;
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  public domainForm: FormGroup;
  options: GlobalConfig;
  moment = moment_;
  isModalOpen: Boolean = false;
  private columnDefs;
  rowData = [];
  filteredRowData = [];
  subscription: any;
  subscribedflag: Boolean = false;
  public gridConfig: GridOptions;
  gridInitialized: boolean;
  public gridModel: CustomAgGrid = {
    gridOptions: {},
    keysToSuppress: [13],
    labelAuthoring: {
      headerPageCount: 'Domain',
      filter: '',
      resetFilter: '',
      paginationHeader: '',
      noData: '',
      noDataOnFilter: "Sorry, we couldn't find what you are looking for.",
      noDataOnSearch: 'No Whitelisted Domain',
      toTop: 'Back to top'
    }
  };
  appList = [];
  appListWithId:{[key:string]: any} = [];
  public appNameModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'application required'
  };
  public domainModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblDomainName',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxdomainName',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divdomanNameValidationErrMsg',
    placeholderCaption: 'Enter Domain Name',
    id: 'domainName',
    tabindex: '',
    maxlength: '64',
    headerText: '',
    headerTextAccessible: 'Enter Domain Name to be added to whitelisting',
    inputText: '',
    requiredText: 'Please enter domain name',
    validationMessage: '',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'domainName',
    formControlName: 'domainName',
    isDisabled: false
  };
  private isNBN: boolean = false;
  focusableElement: HTMLElement;
  focuElement: HTMLElement;
  customerName: any;

  constructor(private whitelistService: WhitelistService,
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private dashBoardSvc: DashboardService,
    private utility: UtilityService,
    private commonService: CommonService,
    private homepageService: HomePageService,private titleService: Title) {
      this.options = this.toastr.toastrConfig;
      this.titleService.setTitle('InfraCo Customer Portal - Domain Whitelist');
      this.getCommonServices();
    }

  ngOnInit(): void {
    const customerDetails = this.homepageService.getCustomerDetails();
    const roles = this.commonService.getroleDetails().customerList;
    const nbn = roles.some((obj) => obj.role.includes('NBN') || obj.function.includes('NBN'));
    this.isNBN = nbn ? true : false;
      this.loadPage();
    this.domainForm = this.fb.group({
      appName: ['',[Validators.required]],
      domainName: ['', [Validators.required, Validators.pattern(this.utility.getDomainRegEx())]],
    });
    this.commonService.showTopButtonInFooter(false);

  }

  getCommonServices() {
    this.commonService.getDefaultCustomer().pipe().subscribe(resp => {
      if (resp) {
        this.customerName = resp;
        this.setCustomerDropdown(resp);
      }
    });
  }
  
  setCustomerDropdown = (selectedHeaderValue?: string) => {
    const profileDetails = this.homepageService.userProfileDetails;
    if (profileDetails) {
      const custDetails = profileDetails?.data?.customerDetails;
      if (selectedHeaderValue) {
        const findCIDNobject = this.searchCidn(custDetails, selectedHeaderValue)
        this.cidn = findCIDNobject?.cidn;
        if(this.cidn !== window['environment'].InfraCoCIDN) {
          this.router.navigateByUrl('/');
        }
      }
    }
  }

  searchCidn(myArray, nameKey) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].customerName === nameKey) {
        return myArray[i];
      }
    }
  }

  loadPage(){
    this.whitelistService.getAppList().then((res: any) => {
      let appListData = res['data']['domainDetails'];
      appListData.forEach(element =>{
        this.appList.push(element.appName);
        this.appListWithId[element.appName]=element.whitelistDomainsAppNameId;
      });
      this.appList.sort(this.itemSort);
      this.domainForm.get('appName').setValue(this.appList[0]);
      this.createGrid();
    }).catch((error) => {
    });
  }

  updateGrid(){
    this.gridInitialized = false;
    this.createGrid();
  }

  onSubmitDomain(){
    let body = {
      'appId':this.appListWithId[this.domainForm.get('appName').value],
      'domain':this.domainForm.get('domainName').value
    }
    this.whitelistService.createDomain(body).then(() => {
      this.isModalOpen = false;
      this.updateGrid();
      this.showToasterMessage('Domain added successfully' , '', this.options, 'success');
    }).catch(err => {
      const msg = err?.error?.message ? err?.error?.message : 'Something went wrong';
      this.showToasterMessage(msg , '', this.options, 'error');
    });
    this.domainForm.controls.domainName.setValue('');
    this.domainForm.controls.domainName.markAsUntouched();
  }

  downloadDomainList(){
    const data = [];
    let dataHeaders = [];
    dataHeaders = ['Domain', 'Created Date', 'Created By'];
    const now = this.moment().format('YYYYMMDDHHmmss');
    let dataToBeDownloaded = [];
    if (this.filteredRowData && this.filteredRowData.length > 0) {
      dataToBeDownloaded = this.filteredRowData;
    }
    else if (this.rowData && this.rowData.length) {
      dataToBeDownloaded = this.rowData;
    }
    dataToBeDownloaded.forEach(selectedRow => {
      data.push({
        'Domain': selectedRow.domain,
        'Created Date': selectedRow.createdAt,
        'Created By': selectedRow.createdBy
      });
    });
    new Angular5Csv(data, 'Domain_Whitelist_' + this.domainForm.get('appName').value + "_" + now, { headers: dataHeaders });
  }

  removeDomain(data){
    this.whitelistService.removeDomain(data.whitelistDomainsId).then(() =>{
      this.updateGrid();
      this.showToasterMessage('Domain deleted successfully' , '', this.options, 'success');
    })
    .catch(err =>{
      const msg = err.error.message ? err.error.message : 'Something went wrong';
      this.showToasterMessage(msg , '', this.options, 'error');
    });
  }

  addDomain(){
    this.isModalOpen = true;
  }

  domainNameFocus(){
    if(this.domainForm && this.domainForm.controls && this.domainForm.controls.domainName){
      this.domainForm.controls.domainName.valueChanges.subscribe((control)=>{
        if(control.length > 0) this.domainForm.controls.domainName.markAsTouched();
      });  
    }
  
  }

  setFocusInModal(event) {
  if(event.key === 'Tab') {
    if(this.domainForm.valid) {
    document.getElementById('sub').focus();
    setTimeout(() => {
      this.focusableElement = document.getElementById('close');
      this.focuElement = document.getElementById('sub');
      this.focuElement.focus();
    }, 1);
      this.commonService.trapFocusInModal('close','sub');
    } else{
    setTimeout(() => {
      this.focusableElement = document.getElementById('close');
      this.focuElement = document.getElementById('can');
      this.focusableElement.focus();
    }, 1);
      this.commonService.trapFocusInModal('close','can');
      }
    }
  }

  closeModal(){
    this.isModalOpen = false;
    this.domainForm.get('domainName').reset();
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyUp(event) {
    if (event.keyCode === 27) {
      this.closeModal();
    }
    if (event.keyCode === 13 && event.target.classList.contains('close')) {
      this.closeModal();
    }
  }

  createGrid() {
    if (this.subscribedflag) {
      this.subscription.unsubscribe();
    } else {
      this.subscribedflag = true;
    }
    const promise1 = this.createColumnDefs();
    const promise2 = this.createRowData();
    this.subscription = forkJoin([promise1, promise2]).subscribe(result => {
      this.createGridConfig();
    }, error => {
      this.createGridConfig();
    });
  }

  private createColumnDefs() {
    return new Promise((resolve, reject) => {
      this.whitelistService.getGridHeader().then(res => {
        this.columnDefs = res['domainHeaders'];
        this.columnDefs.forEach((x: any) => {
          if (x.cellRendererFramework) {
            if(x.colId == 'remove'){
              x.cellRendererParams.clicked = function (data: any) {
                this.removeDomain(data);
              }.bind(this);
            }
          }
        });
        resolve(true);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  private createRowData() {
    return new Promise((resolve, reject) => {
      this.whitelistService.getDomainList(this.appListWithId[this.domainForm.get('appName').value]).then(res => {
        this.rowData = res.data.domainWhitelistDetails;
        resolve(true);
      }).catch((err) => {
        this.rowData = [];
        reject(err);
      });
    });
  }

  createGridConfig() {
    this.gridConfig = <GridOptions>{};
    this.gridConfig.rowData = this.rowData;
    this.gridConfig.paginationPageSize = 10;
    this.gridConfig.columnDefs = this.columnDefs;
    this.gridConfig.suppressHorizontalScroll = true;
    this.gridConfig.pagination = true;
    this.gridConfig.suppressPaginationPanel = true;
    this.gridConfig.rowSelection = 'single';
    this.gridInitialized = true;
    this.gridConfig.frameworkComponents = {
      CheckboxFilterComponent: <any>CheckboxFilterComponent,
      customTextFilter: <any>CustomTextFilterComponent,
      CustomDateFilterComponent: <any>CustomDateFilterComponent
    };
    this.gridModel.gridOptions = this.gridConfig;
  }

  onFilterSelected(filteredData) {
    this.filteredRowData = [];
    if (filteredData.filteredRowData && filteredData.filteredRowData.length > 0) {
      filteredData.filteredRowData.forEach(row => {
        if (row.data) {
          this.filteredRowData.push(row.data);
        }
      });
    }
  }

  itemSort(a, b) {
    const str1 = a.toLowerCase();
    const str2 = b.toLowerCase();
    let comparison = 0;
    if (str1 > str2) {
      comparison = 1;
    } else if (str1 < str2) {
      comparison = -1;
    }
    return comparison;
  }

  focusOnTop(event) {
    if (event) {
      let focusableElement;
      focusableElement = document.getElementById('dom');
      if(focusableElement){
      focusableElement.setAttribute('tabindex', '0');
      focusableElement.addEventListener('blur', () => {
        focusableElement.removeAttribute('tabindex');
      });
      setTimeout(() => {
        if (focusableElement) {
          focusableElement.focus();
        }
      }, 10);
    }
  }
}

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-top-center';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 5000;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header , options, this.options.iconClasses[type]);
    const event: Event = window.event;
    const close=document?.getElementById("closeButton");
    close?.focus();
  }
}
