<div class="header-block-cls">   
  <ul *ngIf="this.headerModel?.headerLinks" class="app-header-menu"> 
    <li> 
      <button role="link" tabindex="0" id="skip-to-main-content" class="skip-to-content-link" (keydown.Enter)="focusToMainContent()"
      (keydown.Tab)="keydownTab($event)" (click)="focusToMainContent()">
      Skip to main content
    </button>
     <span role="banner">
      <img class="logo-img-cls" src="../../../../assets/images/InfraCo-Customer-Portal-Logo.svg" id="header_logo"
        role="link" tabindex="0" (keypress)="hubKeypress($event)" alt="Infraco hub home page icon" routerLink="/" />
     </span>
    </li>
     <!-- <div aria-live="assertive" class="visually-hidden">
      <span role="presentation" tabindex="0" style="position:absolute;left:-9999px">Home page</span>
    </div> -->
    <!--  -->
    <ng-container *ngIf="!((route.url === '/access-denied') || (route.url === '/error'))">
      <li role="list" tabindex="0" class="headr-option-cls" *ngIf="showFibreDropdown" 
        (keypress)="fibreMenuClick()" id="fibreDropdown" (click)="fibreMenuClick()" [ngClass]="{'active-menu': isFibreMenuClicked}"
        [attr.aria-label]="isFibreMenuClicked ? 'expanded Fibre menu dropdown' : 'collapsed Fibre menu dropdown'">
        <span aria-label="Fibre menu dropdown"> <strong>{{this.headerModel?.headerLinks[0]}}</strong>
          <i *ngIf="!isFibreMenuClicked" class="icon-icon-ui-down-arrow-thick icon-xs" aria-label="collapsed"></i>
          <i *ngIf="isFibreMenuClicked" class="icon-icon-ui-up-arrow-thick icon-xs"></i>
        </span>
      </li>
      <li role="list" tabindex="0" class="headr-option-cls" *ngIf="showEIMenu" (keypress)="EIMenuClick()"
        (click)="EIMenuClick()" id="EIDropdown" [ngClass]="{'active-menu': isEIMenuClicked}">
        <span aria-label="Exchanges & Infrastructure menu dropdown"> <strong>{{this.headerModel?.headerLinks[1]}}</strong>
          <i *ngIf="!isEIMenuClicked" class="icon-icon-ui-down-arrow-thick icon-xs" aria-label="collapsed"></i>
          <i *ngIf="isEIMenuClicked" class="icon-icon-ui-up-arrow-thick icon-xs"></i>
        </span>
      </li>
      <li tabindex="0" class="headr-option-cls" role="list" *ngIf="isNBNuser" (keypress)="nbnClick()"
        (click)="nbnClick()" id="NBNDropdown" [ngClass]="{'active-menu': isNbnClicked}">
        <div aria-label="NBN Definitive Agreements menu dropdown"> <strong>{{this.headerModel?.headerLinks[2]}}</strong>
          <i *ngIf="!isNbnClicked" class="icon-icon-ui-down-arrow-thick icon-xs" aria-label="collapsed"></i>
          <i *ngIf="isNbnClicked" class="icon-icon-ui-up-arrow-thick icon-xs"></i>
        </div>
      </li>
    </ng-container>
    <div *ngIf="isNbnClicked"><app-nbn-dropdown-menu (closeDropdownMenu)="closeDropdownMenu()"></app-nbn-dropdown-menu>
    </div>

    <div *ngIf="isEIMenuClicked"><duct-access-dropdown-menu
        (closeDropdownMenu)="closeDropdownMenu()"></duct-access-dropdown-menu></div>

    <div *ngIf="isFibreMenuClicked"><app-fibre-dropdown-menu
        (closeDropdownMenu)="closeDropdownMenu()"
        (showDropdown)="showDropdown($event)"></app-fibre-dropdown-menu></div>

    <ng-container *ngIf="!((route.url === '/access-denied') || (route.url === '/error'))">
      <span class="combo-dropdown-cls">
        <li class="drpdwn-combo-cls">
          <form [formGroup]="headerForm" id="headerForm">
            <div class="drpdwn-cls" [ngClass]="{'hideDropdown': !showHeaderBussinessDropdown()}">
              <app-cui-searchable-dropdown name="businessTypes" [formControlName]="'businessTypes'"
                [model]="businessNameDropDownModel" [labelName]="'businessDropdown'" [isRequired]=true
                [items]=businessNames automationType="dropdown" automationId="drpdwnbusinessType" [noAutoComplete]=false
                id="businessTypes" (ngModelChange)='roleChange($event)'>
              </app-cui-searchable-dropdown>
            </div>
          </form>
        </li>
        <!-- Notification Icon implementation -->
        <div *ngIf="showFibreDropdown">
          <div class="notification-cls" [ngClass]="notificationParams.isNotificationOpen ? 'notification-open-cls' : ''">
            <div cdkOverlayOrigin #trigger="cdkOverlayOrigin" [disabled]="notificationParams.isNotificationIconDisabled"
              [ngClass]="{'notification-icon-disabled':notificationParams.isNotificationIconDisabled}" tabindex="-1">
              <!-- <div id="notification_icon" aria-label="Notification"
              class="notificatoin-image" role="button"
              (click)="!notificationParams.isNotificationIconDisabled && getNotifications()"
              (keydown.enter)="!notificationParams.isNotificationIconDisabled && getNotifications()" tabindex="0" ></div> -->
             <img  id="notification_icon" 
                src="../../assets/images/Notification-SVG.svg"
                aria-label="Notification" *ngIf="notificationParams.isNotificationOpen"
                class="notificatoin-image" role="button"
                (click)="!notificationParams.isNotificationIconDisabled && getNotifications()"
                (keydown.enter)="!notificationParams.isNotificationIconDisabled && getNotifications()" tabindex="0" />
              <img  
              src="../../assets/images/Notification-Icon.svg"
              id="notification_icon" aria-label="Notification" *ngIf="!notificationParams.isNotificationOpen"
                class="notificatoin-image" role="button"
                (click)="!notificationParams.isNotificationIconDisabled && getNotifications()"
                (keydown.enter)="!notificationParams.isNotificationIconDisabled && getNotifications()" tabindex="0" />
            </div>
          </div>

          <div *ngIf="showFibreDropdown">
            <app-notification [notificationParams]="notificationParams" [cdkOrigin]=trigger role="dialog" aria-modal="true" aria-label="Notification"></app-notification>
          </div>
        </div>

        

        
        <!--End - Notificatoin Icon-->
        <span (mouseleave)="mouseLeaveEvent()">
          <div class="profl-drpdwn-cls">
            <p class="avatr-icon-cls" *ngIf="nameInitials">{{nameInitials}}</p>
            <span class="profile-active" (click)="profileClick()" *ngIf="contactName">{{contactName}}</span>
            <ng-container *ngIf="profleDrpdwnIsOpn">
              <button (keydown.enter)="closeDropdownOnEnter($event)" id="one" class="prfl-icon-arw-cls"
                aria-label="profile dropdown" (click)="profileClick()">
                <i class="icon-icon-ui-up-arrow-thick icon-xs"></i>
              </button>
            </ng-container>
            <ng-container *ngIf="!profleDrpdwnIsOpn">
              <button (keydown.enter)="closeDropdownOnEnter($event)" role="Contextual menu" class="prfl-icon-arw-cls" aria-label="profile dropdown"
                (click)="profileClick()">
                <i class="icon-icon-ui-down-arrow-thick icon-xs"></i>
              </button>
            </ng-container>
          </div>
          <div id="headerTab" [ngClass]="profleDrpdwnIsOpn ? 'prfl-drpdwn-cls' : 'no-display'">
            <div role="list" (click)="profileClick()" tabindex="0" (keypress)="profileClick('Profile')" class="drpdwn-lnk-cls"
              routerLink="/profile" aria-label="list 1 of 3 My profile">
              <span>
                <img class="icon-img-cls" src="../../assets/images/icon-my-profile.svg " id="profile_icon"
                  alt="" />
                {{this.headerModel?.profileDropdownLinks[0]}}
              </span>
            </div>
            <hr class="hr-cls">
            <div tabindex="0" (click)="profileClick()" (keypress)="profileClick('Admin')"
              *ngIf="isAuthorizedAdminRights" class="drpdwn-lnk-cls" role="list" routerLink="/admin" aria-label="list 2 of 3 Admin Settings">
              <span>
                <img class="icon-img-cls" src="../../assets/images/icon-setting.svg " id="admin_icon"
                  alt="" />
                {{this.headerModel?.profileDropdownLinks[1]}}
              </span>
            </div>
            <hr class="hr-cls">
            <div tabindex="0" id="last" (keydown)="trap()" (click)="logout()" (keypress)="profileClick('SignOut')" class="drpdwn-lnk-cls"  role="list" aria-label="list 3 of 3 SignOut">
              <span>
                <img class="icon-img-cls" src="../../assets/images/icon-open-account-logout.svg " id="logout_icon"
                  alt="" />
                {{this.headerModel?.profileDropdownLinks[2]}}
              </span>
            </div>
          </div>
        </span>
      </span>
    </ng-container>

  </ul>
</div>
