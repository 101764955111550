import { Component, Input, Output, EventEmitter, SimpleChange, OnChanges, HostBinding, HostListener, ViewChild, ElementRef } from '@angular/core';


@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.css']
})
export class MultiSelectDropdownComponent implements OnChanges {
  @Input() list: any[] | undefined;
  @Input() placeholder: string = '';
  @Input() resetDropdown: any | undefined;
  @Input() isDisabled: any[] | undefined;
  @Output() shareCheckedList = new EventEmitter();
  @Output() shareIndividualCheckedList = new EventEmitter();
  showDropDown = false
  checkedList: any[];
  currentSelected: {} | undefined;

  constructor() {
    this.checkedList = [];
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    let resetDropdownChange: SimpleChange = changes['resetDropdown'];
    this.resetDropdown = resetDropdownChange?.currentValue;
    if (resetDropdownChange?.currentValue || resetDropdownChange?.previousValue) {
      this.checkedList = [];
      this.currentSelected = { checked: false, name: '' };
    }
    this.shareCheckedlist();
    this.shareIndividualStatus();

  }

  getSelectedValue(status: Boolean, value: String) {
    if (status) {
      this.checkedList.push(value);
    } else {
      var index = this.checkedList.indexOf(value);
      this.checkedList.splice(index, 1);
    }

    this.currentSelected = { checked: status, name: value };

    //share checked list
    this.shareCheckedlist();

    //share individual selected item
    this.shareIndividualStatus();
  }
  shareCheckedlist() {
    this.shareCheckedList.emit(this.checkedList);
  }
  shareIndividualStatus() {
    this.shareIndividualCheckedList.emit(this.currentSelected);
  }

  toggleDropdown() {
    this.showDropDown=!this.showDropDown
  }

  dropsh(e: KeyboardEvent){
    if(e.key==='Enter') {
      this.showDropDown = true;
      setTimeout(() => {
        document.getElementById('role-func-checkbox').focus();
      },0)
      if(this.showDropDown) {
        document.getElementById('multiSelectDrpdwnContainer').setAttribute('aria-label', 'expanded');
      }
    }
  }


  takeme(e) {
    if (e) {
      if (e.key === 'ArrowDown') {
        this.setFocusToFirstElement();
      }
    }
  }

  setFocusToFirstElement() {
    const firstElement = document.querySelector('input');
    if (firstElement) {
      firstElement.focus();
    }
  }

  onLabelKeypress(event:KeyboardEvent, value, isLastValue) {
    if(event.key==='Enter' || event.key===" ") {
      value.checked = !value.checked
      this.getSelectedValue(value.checked, value.name)
    }

    if(isLastValue && event.key==='Tab') {
      this.showDropDown = false;
      document.getElementById('multiSelectDrpdwnContainer').setAttribute('aria-label', 'Role function combobox required collapsed');
    }
  }

  @HostListener('focus')
  focusHandler() {
    //focus event handler in working status >accessibility fix
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyUp(event) {
    if (event.keyCode === 27) {
      this.showDropDown = false;
    }
  }

  @HostListener('blur') onblur() {
    this.showDropDown = false;
  }
}