<ng-template #content role="dialog" aria-modal="true">
  <div>
    <div *ngIf="(showDiversity && quoteDetails?.describeYourDiversity === 'All new services with diversity') && (!quoteDetails?.quoteAcceptedFrom)" class="d-flex">
      <div class="info-reminder d-flex">
        <div class="d-flex pt-1 ml-10"><img alt="error" src="../../assets/images/Icon-info.svg"></div>
        <div class="d-flex ml-15">{{aemLabelAuthoring?.pageHeader?.reminderLabel}}
          <br>
          {{aemLabelAuthoring?.pageHeader?.reminderMsg}}
        </div>
      </div>
      <button class="cancelBtn close d-flex" (click)="close()" aria-label="Close"><i class="icon-icon-ui-cross"></i></button>
    </div>
    <section class="modal-header" [ngClass]="{'mt-20': ((showDiversity && quoteDetails?.describeYourDiversity === 'All new services with diversity') && (!quoteDetails?.quoteAcceptedFrom))}"
     aria-modal="true">
      <h1 class="headerLabel" id="modal-basic-title">{{aemLabelAuthoring?.pageHeader?.pageHeaderLabel}}</h1>
       <button class="cancelBtn close" (click)="close()" aria-label="Close"><i class="icon-icon-ui-cross"
        *ngIf="!((showDiversity && quoteDetails?.describeYourDiversity === 'All new services with diversity') && (!quoteDetails?.quoteAcceptedFrom))"></i></button> 
    </section>

    <div lass="modal-dialog modal-dialog-centered">
      <!-- <div class="modal-content" *ngIf="!type">
        <span>{{aemLabelAuthoring?.pageHeader?.terms}}</span><br>
        <span>{{aemLabelAuthoring?.pageHeader?.order}}</span>
      </div> -->
      <div class="">
        <pre [innerHTML]="tAndcText"></pre>
      </div>
    </div>

    <div class="footer">
      <button class="texttype-globalbtn-cls app-cancel-btn cancel" tabindex="0" (click)="close()">
        <i class="icon-icon-ui-cross"></i>{{aemLabelAuthoring?.pageHeader?.cancel}}
      </button>
      <button class="primary-globalbtn-cls app-save-btn " tabindex="0" (click)="navigateSuccess()"
        *ngIf="!type && !isBulkQuote">{{aemLabelAuthoring?.pageHeader?.Accept}}</button>
      <button class="primary-globalbtn-cls app-save-btn " tabindex="0" (click)="agree()"
        *ngIf="type == 'Path1' || type == 'Path2'">{{aemLabelAuthoring?.pageHeader?.Agree}}</button>
      <button class="primary-globalbtn-cls app-save-btn " tabindex="0" 
      (click)="isModifyBulkQuote ? submitBulkQuoteModify() : uploadBulkQuote()"
        *ngIf="isBulkQuote">{{aemLabelAuthoring?.pageHeader?.Accept}}</button>
    </div>
  </div>
</ng-template>