<div class="row">
  <div class="col-6">
     <label class="alert-section" aria-live="assertive" role="alert">Site details page</label>
    <div class="row">
      <div class="col-lg-1 col-md-2 col-sm-2" role="img" aria-label="location pointer image">
        <!-- <object role="graphics-symbol" data="./assets/images/LocationPinBlack.svg" type="image/svg+xml"></object>  -->
      <img src="./assets/images/LocationPinBlack.svg" alt="graphics-symbol">
      </div>
      <div class="col-lg-11 col-md-10 col-sm-10 mt-2 text-indent-10">
        <h3 class="text-dark"><span>Provide site address details</span></h3>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <ng-container *ngFor="let item of formsConfig; let formIndex=index">
    <hr *ngIf="item.activateZ2endOnSearch" />
    <div class="col-12 col-md-6">
      <div class="row" *ngIf="item.activateZ2endOnSearch">
        <div class="col-12 mt-2 text-indent-10">
          <div class="text-dark text-diversity-label">Service have diverse relationship</div>
        </div>
      </div>
      <div class="row m-t-40">
        <div class="col">
          <div class="row">
            <div tabindex="-1" style="padding-bottom: 5px;" class="col-1" aria-label="location pointer image" role="img">
             <!-- <object role="graphics-symbol" data="./assets/images/LocationPin.svg" type="image/svg+xml"></object> -->
            <img src="./assets/images/LocationPin.svg" alt="graphics-symbol">
            </div>
            <div class="col-11 mt-2 pl-0">
              <h3 class="text-dark-subheading"><span>{{item.title}}</span></h3>
            </div>
          </div>
        </div>
      </div>

      <div class="row tab-content">
        <div id="{{formIndex}}" class="col" [ngClass]="(formIndex%2===0 && formsConfig.length >1 && formIndex==0) || (formIndex%2===0 && formsConfig.length >3 && formIndex==2) ? 'site-search-content' : ''">
          <ul ngbNav #nav="ngbNav" (navChange)="userChangedTab($event, item, formIndex)" [(activeId)]="item.activeTab"
            class="nav nav-pills nav-fill nav-underline tab-label">
            <li [ngbNavItem]="1">
              <a ngbNavLink aria-label="Search Address tab for address search">Search</a>
              <ng-template ngbNavContent>
                <app-search-form [aemLabelAuthoring]="aemLabelAuthoring" [formConfig]='item'
                  (onSearchActivateZend)="activateZend($event)"
                  (onSearchA2ctivateZend)="activateA2end($event)"
                  (onSearchZ2ctivateZend)="activateZ2end($event)"
                  (onSearchDetails)="getSearchInputs($event, item, formIndex)" (onSelectRefForm)="onSelectRefForm($event)" (onSelectLatLngForm)="onSelectLatLngForm($event)" (patch2CheckedInfo)="getpatch2CheckedInfo($event, item, index)"></app-search-form>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink aria-label="Latitude and longitude tab for address search">Latitude and longitude</a>
              <ng-template ngbNavContent>
                <app-lat-lng-form [aemLabelAuthoring]="aemLabelAuthoring" [formConfig]='item'
                  (onlatlngActivateZend)="activateZend($event)"
                  (onlatlngA2ctivateZend)="activateA2end($event)"
                  (onlatlngZ2ctivateZend)="activateZ2end($event)"
                  (onSearchLatlong)="getLatLongSearch($event, item, formIndex)" [UpdateLatLngFromMap]="updateLatLngFromMap" (clearBtnClicked)="clearBtnClicked($event)"></app-lat-lng-form>
              </ng-template>

            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink aria-label="Location reference tab for address search">Location reference</a>
              <ng-template ngbNavContent>
                <app-refrence-form [aemLabelAuthoring]="aemLabelAuthoring" [formConfig]='item'
                  (onrefActivateZend)="activateZend($event)"
                  (onrefA2ctivateZend)="activateA2end($event)"
                  (onrefZ2ctivateZend)="activateZ2end($event)"
                  (onrefLocationRef)="getLocationRefSearch($event, item, formIndex)" (clearBtnClicked)="clearBtnClicked($event)"  [updateReferenceForm]="updateReferenceForm"></app-refrence-form>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>

      </div>



    </div>
    <div class="col-12 col-md-6">
      <div style="padding-left: 20px;">
        <div>
          <!-- Map here! -->
          <div>
            <div>
              <div aria-label="location pointer image" role="img"
                [ngClass]="item.activateZ2endOnSearch ? 'mt-100' : ''" class="d-flex gap-2 align-items-center"
                style="padding-bottom: 16px;" (click)="updateMapData(false, formIndex, item)">
                <!-- <object role="graphics-symbol" aria-hidden="true" data="./assets/images/DropPinMap.svg"
                  type="image/svg+xml"></object> -->
                  <img src="./assets/images/DropPinMap.png" alt="graphics-symbol">
                <div class="drop-pin-label">
                  Drop the pin
                </div>
              </div>
            </div>

            <google-map #sitemap [center]="item.mapCenter" [zoom]="item.mapZoom" [disableZoomControl]="true" height="465px"
              width="100%" (mapClick)="addMarker($event, formIndex, item, markerElem)">
              <map-marker #markerElem="mapMarker" *ngFor="let marker of item.markers;  let markerIndex=index"
                [position]="marker.position" [title]="marker.title" [options]="siteAddressGoogleMapsCustomMarker"
                (mapClick)="nopesnInfo(formIndex,markerIndex, marker, markerElem, item)">
                </map-marker>
              <map-info-window id="mapInfo" #MapInfoWindow>
               <span>{{infoContent | titlecase}}</span>
                <button  *ngIf="selectBtnMap"
                (click)="infowindowSelectActions(formIndex, item, $event)" class="btn map-select-btn">Select</button>
              </map-info-window>
            </google-map>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

</div>
