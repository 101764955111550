<div class="container-fluid landing-page-container page-bg" *ngIf="aemLabelAuthoring" id="qa-banner">
  <h1 class="wlcm-cls" id="welco">Welcome {{profile.contactName}},</h1>
  <h3 class="header-label">
    {{ aemLabelAuthoring.pageHeader.pageHeaderLabel }}
  </h3>
  <div class="header-content mb-0">
    Welcome to the Telstra - InfraCo Portal, your gateway to Telstra documents
    provided to NBNCo under the Definitive Agreement.
  </div>
  <br />
  <div>
    <h3 class="header-label">
      {{ aemLabelAuthoring.pageHeader.pageSubHeaderLabel }}
    </h3>
    <div class="header-content">
      {{ aemLabelAuthoring.pageHeader.pageHeaderContentLabel }}
    </div>
  </div>
  <div class="grid-container-list" *ngIf="gridInitialized">
    <span>
      <app-cui-ag-grid [model]="gridData" (gridOnReady)="gridReady($event)" (focusOnTop)="focusOnTop($event)">
      </app-cui-ag-grid>
    </span>
  </div>
  <br />
  <div class="view-btn-container">
    <div tabindex="0" class="view-more-btn-container">
    <button
      *ngIf="viewMore && rowData.length > 0"
      (click)="viewMoreTests()"
      class="btn view-more-btn"
    >
      View more
    </button>
  </div>
  </div>
</div>
