import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/shared/services/api.service';
import { SiteDetailsService } from 'src/app/quote-management/create-new-quote/site-details/site-details.service';
import { TitleCasePipe } from '@angular/common';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { CreateQuoteService } from '../../../create-quote.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-lat-lng-form',
  templateUrl: './lat-lng-form.component.html',
  styleUrls: ['./lat-lng-form.component.less', '../../site-details.component.less',
    '../../../product-details/product-details.component.less'],
  providers: [TitleCasePipe]
})
export class LatLngFormComponent implements OnInit, OnChanges {
  @Output() onlatlngActivateZend = new EventEmitter<any>();
  @Output() onSearchLatlong = new EventEmitter<any>();
  @Output() onlatlngA2ctivateZend = new EventEmitter<any>();
  @Output() onlatlngZ2ctivateZend = new EventEmitter<any>();
  @Output() clearBtnClicked = new EventEmitter<any>();



  @Input() formConfig;
  @Input() pinOnMapData;
  @Input() UpdateLatLngFromMap;

  @Output() formData;
  @Input() aemLabelAuthoring;
  primaryAutoCompleteOptions = [];
  @ViewChild('trigger', { static: false }) primarySelect: MatAutocompleteTrigger;
  pattern = /^[a-zA-Z0-9!@#$%^&*()\-\_=+:'",.?|{}\n\[\]\/\`\~\\ ]*$/;
  env = window['environment'];
  latLngApiEndPoint = 'infraco-addresses/search-by-latitude-longitude';
  mockLatLngApiEndPoint = 'mock/search-by-latitude-longitude.json';

  defaultLatLngForm = {
    latitude: new FormControl('', [Validators.required, Validators.pattern(/^(\+|-)?(?:90(?:(?:\.0{1,18})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,18})?))$/)]),
    longitude: new FormControl('', [Validators.required, Validators.pattern(/^(\+|-)?(?:180(?:(?:\.0{1,18})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,18})?))$/)]),
    siteType: new FormControl(''),
    searchMethod: new FormControl('Latitude and Longitude'),
    selectedAddress: new FormControl(''),
    additionalAddressInfo: new FormControl('', [Validators.required, Validators.maxLength(255), Validators.pattern(this.pattern)]),
    definedPropertyLocation: new FormControl('Yes'),
    adborId: new FormControl(''),
    primaryAutoComplete: new FormControl(''),
    address: new FormControl('', [Validators.required])
  };

  latLngForm = new FormGroup(this.defaultLatLngForm);
  searchResults: any[] = [];
  disableClear: boolean;
  primaryAutoComplete: any;
  preDefinedSiteNode: any;
  address: any;
  showSubAddresses: boolean;
  showAddressContainer: boolean;
  subscription: Subscription;
  public modifyQuoteSubscription: Subscription;
  isModifyQuote: boolean = false;
  existingQuoteData: any;
  constructor(
    private siteDetailsService: SiteDetailsService,
    private api: ApiService,
    private titleCasePipe: TitleCasePipe,
    private createQuoteService: CreateQuoteService,
  ) { }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.UpdateLatLngFromMap?.currentValue?.siteType?.length) {
      if (this.latLngForm.value.formName == changes.UpdateLatLngFromMap.currentValue.siteType) {
        this.latLngForm.patchValue({
          latitude: changes.UpdateLatLngFromMap.currentValue.lat || '',
          longitude: changes.UpdateLatLngFromMap.currentValue.lng || ''
        })
      }
    }


  }

  ngOnInit(): void {
    this.latLngForm.patchValue({
      siteType: this.formConfig.formName,
    });
    this.subscription = this.latLngForm.valueChanges.subscribe(value => {
      setTimeout(() => {
        this.disableClear = false;
        let latLngFormValue = this.latLngForm.value;
        latLngFormValue.formName = this.formConfig.formName;
        if (latLngFormValue.latitude && latLngFormValue.longitude) {
          latLngFormValue.valid = this.latLngForm.valid;
          latLngFormValue.searchMethod = "Latitude and Longitude";
          latLngFormValue.address = this.address;
        }

        if (this.clearClicked) {
          this.clearClicked = false;
          latLngFormValue.clearClicked = true;
        } else {
          latLngFormValue.clearClicked = false;
        }
        this.siteDetailsService.latLongFormChange(latLngFormValue);



        if (this.formConfig.activateZendOnSearch && this.latLngForm.valid)
          this.onlatlngActivateZend.emit(true);

        if (this.formConfig.activateA2endOnSearch && this.formConfig.formName == "zEndAdress" && this.latLngForm.valid)
          this.onlatlngA2ctivateZend.emit(true);

        if (this.formConfig.activateZ2endOnSearch && this.formConfig.formName == "a2EndAdress" && this.latLngForm.valid)
          this.onlatlngZ2ctivateZend.emit(true);
      }, 1);
    })
    this.modifyQuoteSubscription = this.createQuoteService.isModifyQuote.subscribe(val => {
      if (val) {
        this.isModifyQuote = val;
      } else {
        this.isModifyQuote = false
      }
    })
    this.fillDefaultData();
  }


  fillDefaultData(clear?) {
    this.latLngForm.patchValue({
      siteType: this.formConfig.formName,
      definedPropertyLocation: 'Yes',
    })

    if (!clear) {
      let formData = this.createQuoteService.productDetailsFormValue[this.formConfig.formName];
      this.formConfig.formData = formData;

      if (this.formConfig && this.formConfig.formData?.selectedAddress && this.formConfig.formData?.searchMethod == 'Latitude and Longitude') {
        this.latLngForm.patchValue(this.formConfig.formData);
        this.latLngForm.patchValue({
          address: this.formConfig.formData.selectedAddress
        })
        this.address = {
          primaryAddress: this.primaryAutoComplete,
          adborId: this.formConfig.formData.adborId,
          latitude: this.formConfig.formData.latitude,
          longitude: this.formConfig.formData.longitude,
          nbnLocId: this.formConfig.formData.nbnLocId,
          nodeCode: this.formConfig.formData.nodeCode
        }

        if (this.formConfig.formName == 'zEndAdress') {
          this.latLngForm.patchValue({ refresh: true }, { emitEvent: true });
          this.onlatlngA2ctivateZend.emit(true);
        }
        if (this.formConfig.formName == 'a2EndAdress') {
          this.latLngForm.patchValue({ refresh: true }, { emitEvent: true });
          this.onlatlngZ2ctivateZend.emit(true);
        }
        this.searchViaLatLng();
      }
    }
  }

  searchViaLatLng() {
    this.siteDetailsService.clearClicked = false;
    // this.api.get(this.env.aemEndPoint + this.mockLatLngApiEndPoint, {
    this.api.post(this.env.apiPoint + this.latLngApiEndPoint, {
      latitude: this.latLngForm.value.latitude,
      longitude: this.latLngForm.value.longitude
    }).subscribe(res => {
      if(res['data'].addresses.length){
        res['data'].addresses.map(obj => obj.address = this.titleCasePipe.transform(obj.address));
        this.searchResults = res['data'].addresses;
      } else {
        this.searchResults = this.siteDetailsService.fillNoDataLatLng(this.latLngForm.value.latitude, this.latLngForm.value.longitude);
      }

      if (this.searchResults.length > 0) {
        this.showAddressContainer = true;
      } else {
        this.latLngForm.controls['address'].setValue('(New address)');
        this.onSearchLatlong.emit({ lat: this.latLngForm.value.latitude, long: this.latLngForm.value.longitude, type: 'latlong' });
      }

      if (this.isModifyQuote && this.formConfig && this.formConfig.formData.latitude === this.searchResults[0].latitude && this.formConfig.formData.longitude === this.searchResults[0].longitude) {
        this.searchResults.forEach((val) => {
          if (val.latitude === this.formConfig.formData.latitude && val.address === this.formConfig.formData.selectedAddress) {
            this.latLngForm.patchValue({
              selectedAddress: val
            })
            if (this.formConfig && this.formConfig.formData.selectedAddress && this.formConfig.formData.searchMethod == 'Latitude and Longitude') {
              this.selectAddress(val)
            }
            this.primaryAutoComplete = val.address;
          }
        })

      } else {
        if (this.formConfig && this.formConfig.formData && this.formConfig.formData.selectedAddress && this.formConfig.formData.searchMethod == 'Latitude and Longitude') {
          this.searchResults.forEach((val) => {
            if (val.address === this.formConfig.formData.selectedAddress) {
              this.selectAddress(val)
            }
          })
        }
        this.primaryAutoComplete = this.formConfig.formData.selectedAddress ? this.formConfig.formData.selectedAddress : this.searchResults[0]?.address;
      }
    }, err => {
      if (err.status !== 0) {
        this.searchResults = this.siteDetailsService.fillNoDataLatLng(this.latLngForm?.value?.latitude, this.latLngForm?.value?.longitude);
        this.latLngForm.patchValue({
          selectedAddress: this.searchResults[0]
        })
        this.primaryAutoComplete = this.searchResults[0].address;
      }
    })
  }

  clearClicked = false;
  resetForm() {
    if (this.formConfig.formName == "aEndAdress") {
      this.siteDetailsService.onTabChangeClearData({ activeId: 2 }, { formName: "zEndAdress" });
    }

    this.formConfig.formData =
      this.siteDetailsService.onTabChangeClearData({ activeId: 2 }, { formName: this.formConfig.formName });


    this.clearClicked = true;
    this.clearBtnClicked.emit({ clearBtnClicked: true, formName: this.formConfig.formName })
    this.siteDetailsService.clearClicked = true;
    this.latLngForm.reset();
    this.fillDefaultData('cls');
    this.searchResults = [];
    if (this.formConfig.activateZendOnSearch)
      this.onlatlngActivateZend.emit(false);
  }


  updateFormValueForSearch(address, preDefinedSiteNode?) {
    address = this.titleCasePipe.transform(address);
    this.latLngForm.patchValue({
      selectedAddress: address
    })
    this.primaryAutoComplete = address;
    this.preDefinedSiteNode = (preDefinedSiteNode && preDefinedSiteNode != null && preDefinedSiteNode.toLowerCase() != "no") ? preDefinedSiteNode : false;
  }

  onChangeRadioButton(value){
    this.latLngForm.controls['definedPropertyLocation'].setValue(value);
  }

  selectAddress(result) {
    if (result?.subaddresses?.length > 0) {
      this.showSubAddresses = !this.showSubAddresses;
    } else {
      this.latLngForm.controls['address'].setValue(result?.address);

      if (result?.address == '(New address)') {
        this.onSearchLatlong.emit({ lat: result?.latitude, long: result?.longitude, type: 'latlong' })
      } else {
        this.onSearchLatlong.emit({ address: result?.address, type: 'address', formName: this.formConfig.formName })
      }

      this.showAddressContainer = !this.showAddressContainer;
      this.address = result;

      this.latLngForm.patchValue({
        selectedAddress: this.address.address
      })
      // if (this.formConfig.activateZendOnSearch) 
      //   this.onlatlngActivateZend.emit(true);

      // if (this.formConfig.activateA2endOnSearch && this.formConfig.formName == "zEndAdress")
      //   this.onlatlngA2ctivateZend.emit(true);

      // if (this.formConfig.activateZ2endOnSearch && this.formConfig.formName == "a2EndAdress")
      //   this.onlatlngZ2ctivateZend.emit(true);

    }
    const preDefinedSiteNode = result.predefinedSiteNode;
    this.preDefinedSiteNode = (preDefinedSiteNode && preDefinedSiteNode != null && preDefinedSiteNode.toLowerCase() != "no") ? preDefinedSiteNode : false;
    if (this.preDefinedSiteNode) {
      this.latLngForm.controls['definedPropertyLocation'].setValue(preDefinedSiteNode);
    }
  }
  selectSubAddress(subAddress) {
    this.latLngForm.controls['address'].setValue(subAddress.address);
    this.address = subAddress;

    if (subAddress && subAddress.address == '(New address)') {
      this.onSearchLatlong.emit({ lat: subAddress.latitude, long: subAddress.longitude, type: 'latlong' })
    } else {
      this.onSearchLatlong.emit({ address: subAddress && subAddress.address, type: 'address', formName: this.formConfig.formName })
    }
    this.showAddressContainer = !this.showAddressContainer;

    // if (this.formConfig.activateZendOnSearch) {
    //   this.onlatlngActivateZend.emit(true);
    // }

    // if (this.formConfig.activateA2endOnSearch && this.formConfig.formName == "zEndAdress")
    //   this.onlatlngA2ctivateZend.emit(true);

    // if (this.formConfig.activateZ2endOnSearch && this.formConfig.formName == "a2EndAdress")
    //   this.onlatlngZ2ctivateZend.emit(true);
    const preDefinedSiteNode = subAddress.predefinedSiteNode;
    this.preDefinedSiteNode = (preDefinedSiteNode && preDefinedSiteNode != null && preDefinedSiteNode.toLowerCase() != "no") ? preDefinedSiteNode : false;
    if (this.preDefinedSiteNode) {
      this.latLngForm.controls['definedPropertyLocation'].setValue(preDefinedSiteNode);
    }
  }
  ngOnDestroy(): void {
    if (this.siteDetailsService.clearClicked) {
      if (this.formConfig.formName == 'zEndAdress') {
        this.resetForm();
      }

      this.siteDetailsService.clearClicked = false;
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
