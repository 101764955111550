import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from '../../dashboard.service';
import { AuthService } from 'tw-core-ui';
import { CommonService } from 'src/app/shared/services/common.service';
import { HomePageService } from 'src/app/shared/services/homePage.service';


@Component({
  selector: 'app-cabinets-document-list-action-renderer',
  templateUrl: './cabinets-document-list-action-renderer.component.html',
  styleUrls: ['./cabinets-document-list-action-renderer.component.css']
})

export class CabinetsDocumentListActionRendererComponent {

  @Input() public status: any;
  params;
  cabinetRole: string;
  roles = [];
  showDeleteButton: boolean = true;
  copyToltip: string = 'Copy external document URL'
  constructor(
    private activeRoute: ActivatedRoute,
    private commonService: CommonService,
    private homepageservice: HomePageService
  ) {
    const routeParams = this.activeRoute.snapshot.params;
    this.cabinetRole = decodeURIComponent(routeParams.role || '');
    const roles = this.commonService.getroleDetails();
    try {
      this.roles = roles.customerList;
      const customerDetails = this.homepageservice.getCustomerDetails();
      if (customerDetails.isNBNCustomer) {
        this.showDeleteButton = false;
      } else if (
        customerDetails.isInfraCoCustomer &&
        this.roles.some((a) => (a?.role === 'NBN Administrator' && a?.function === 'NBN DA Documents'))) {
        this.showDeleteButton = true;
      } else {
        this.showDeleteButton = false;
      }
    } catch (err) {
      this.roles = [];
    }
  }

  agInit(params: any): void {
    this.status = params.value;
    this.params = params;
  }

  viewDocument() {
    this.params.clicked(this.params.data, "view");
  }

  download() {
    this.params.clicked(this.params.data, "download");
  }

  copyDocument() {
    this.params.clicked(this.params.data, "copy");
    this.copyToltip='Link copied'
  }

  deleteDocument() {
    this.params.clicked(this.params.data, "delete");
  }

  showTooltip() {
    this.copyToltip = 'Copy external document URL'
  }

}
