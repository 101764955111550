<form [formGroup]="modifyUserForm" id="modifyUserForm">
  <div>
    <!-- <div aria-live="assertive" class="visually-hidden">
      <span role="presentation" tabindex="0" style="position:absolute;left:-9999px">Modify User Screen opened</span>
    </div> -->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" role="link"><a href="/">Home</a></li>
        <i class="icon-icon-ui-right-arrow breadcrumb-icon"></i>
        <li class="breadcrumb-item mid" role="link" (keypress)="moveTab.emit('user')" (click)="moveTab.emit('user')" tabindex="0"><a>My user</a></li>
        <i class="icon-icon-ui-right-arrow breadcrumb-icon"></i>
        <li class="breadcrumb-item active" aria-current="page">Modify user</li>
      </ol>
    </nav>
    <h2 class="user-hdng-cls">{{modifyUserForm.controls['firstname'].value}}’s profile</h2>
    <span class="accLabel">Fields marked with <span class="star">*</span> are mandatory</span>
    <div>
      <label class="user-lbl-cls"> Username <span class="inpt-reqrd-cls">*</span> </label>
      <div class="row">
        <div class="inpt-type1-cls">
          <app-cui-textfield [model]="userNameModel" [parentFormGroup]="modifyUserForm">
          </app-cui-textfield>
        </div>
      </div>
    </div>
    <div class="row ml-0 pl-0 col-xs-12 col-sm-8 col-md-8 col-lg-8" aria-live="assertive">
      <span role="alert" *ngIf="userNameExistMessage" class="help-block errorMsg">{{userNameExistMessage}}</span>
      <span role="alert" *ngIf="domainErrorMessage" class="help-block errorMsg">{{domainErrorMessage}}</span>
    </div>

    <div class="row">

      <div class="inpt-col-cls">
        <label class="user-lbl-cls"> First name <span class="inpt-reqrd-cls">*</span> </label>
        <div class="inpt-type2-cls">
          <app-cui-textfield [model]="firstNameModel" [parentFormGroup]="modifyUserForm">
          </app-cui-textfield>
        </div>
      </div>

      <div class="inpt-col-cls">
        <label class="user-lbl-cls"> Last name <span class="inpt-reqrd-cls">*</span> </label>
        <div class="inpt-type2-cls">
          <app-cui-textfield [model]="lastNameModel" [parentFormGroup]="modifyUserForm">
          </app-cui-textfield>
        </div>
      </div>

      <div class="inpt-col-cls">
        <label class="user-lbl-cls"> Mobile number</label>
        <div class="inpt-type2-cls">
          <app-cui-textfield [model]="mobileNumberModel" [parentFormGroup]="modifyUserForm">
          </app-cui-textfield>
        </div>
      </div>

      <div class="inpt-col-cls" *ngIf="isNBN || isDuctRole || isInfracoAdminUserMgmtRole" >
        <label class="user-lbl-cls"> Status <span class="inpt-reqrd-cls">*</span> </label>
        <div class="inpt-type2-cls">
          <app-cui-searchable-dropdown #status name="businessTypes" [formControlName]="'status'"
            [model]="statusDropDownModel" [labelName]="'statusDropdown'" [placeholder]="'Select Status'"
            [isRequired]=true [items]=statuses automationType="dropdown" automationId="drpdwnstatusType"
            [noAutoComplete]=false id="status" class="user-dropDown" required (ngModelChange)="changeStatusDropdown()">
          </app-cui-searchable-dropdown>
        </div>
        <div class="row ml-0" aria-live="assertive">
          <span role="alert" *ngIf="modifyUserForm.controls.status.invalid && modifyUserForm.controls.status.touched"
            class="help-block errorMsg">
            Please select status
          </span>
        </div>
      </div>

    </div>

    <table class="tble-cls" *ngIf="customerDetailsList?.length>0 && !showHideSectionOnInactiveStatus" #divToScroll>

      <tr>
        <th>Customer name</th>
        <th>Application</th>
        <th>Role access</th>
        <th style="width: 35%;">Role function</th>
        <th></th>
      </tr>
      <tr class="modfy-usrs-list-cls" *ngFor="let list of customerDetailsList;let i = index">
        <td>{{list.customername}}</td>
        <td>{{list.application}}</td>
        <td>{{list.roleaccess}}</td>
        <td>{{list.rolefunctions}}</td>
        <td>
          <button mat-button class="texttype-globalbtn-cls" aria-live="polite"  (click)="removeCustomerDetails(i)" attr.aria-label="Remove {{list?.customername}}">
            <img src="../../../../assets/images/Icon-Close-btn.svg" alt="Remove" class="rmve-cstmr-cls" />
            <span aria-hidden="true">Remove</span>
          </button>
        </td>
      </tr>
    </table>

    <div class="row div-block-cls" *ngIf="toAddCustomer">
      <div class="inpt-col-cls">
        <label class="user-lbl-cls"> Customer name <span class="inpt-reqrd-cls">*</span> </label>
        <div class="inpt-type2-cls add-access-req" *ngIf="customers.length"  #tref tabindex="-1">
          <app-cui-searchable-dropdown name="customername" [formControlName]="'customername'"
            [model]="customernameDropDownModel" [labelName]="'customerDropdown'" [placeholder]="'Select Customer'"
            [isRequired]=true [items]=customers automationType="dropdown" automationId="drpdwncustomerType"
            [ngClass]="{'invalid-form-control': (modifyUserForm.controls.customername.touched && modifyUserForm.controls.customername.invalid)}"  [noAutoComplete]=false id="customername"
            [noAutoComplete]=false required (ngModelChange)="changeCustomerDropdown($event)" (click)="touchCustomerName()">
          </app-cui-searchable-dropdown>
        </div>
        <div class="row ml-0  " aria-live="assertive" *ngIf="modifyUserForm.controls.customername.invalid && showErrorMsg">
          <span role="alert" class="error-cls">
            <img class="error-icon" src="../../../../assets/images/icon-error.svg" />
            Please select customer name
          </span>
        </div>
      </div>

      <div class="inpt-col-cls">
        <label class="user-lbl-cls"> Application <span class="inpt-reqrd-cls">*</span> </label>
        <div class="inpt-type2-cls">
          <app-cui-searchable-dropdown name="application" [formControlName]="'application'"
            [model]="applicationDropDownModel" [labelName]="'appicationDropdown'" [placeholder]="'Select Appication'"
            [isRequired]=true [items]=applications automationType="dropdown" automationId="drpdwnappication"
            [noAutoComplete]=false id="application">
          </app-cui-searchable-dropdown>
        </div>
      </div>

      <div class="inpt-col-cls">
        <label class="user-lbl-cls"> Role access <span class="inpt-reqrd-cls">*</span> </label>
        <div class="inpt-type2-cls">
          <app-cui-searchable-dropdown name="roleaccess" [formControlName]="'roleaccess'"
            [model]="roleAccessDropDownModel" [labelName]="'roleaccessDropdown'" [placeholder]="'Select Role Access'"
            [isRequired]=true [items]=roleAccesses automationType="dropdown" automationId="drpdwnroleAccess"
            [ngClass]="{'invalid-form-control': (modifyUserForm.controls.roleaccess.invalid && modifyUserForm.controls.roleaccess.touched)}" [noAutoComplete]=false required
            [noAutoComplete]=false required id="roleaccess" (ngModelChange)="changeRoleDropdown()" (click)="touchRoleAccess()">
          </app-cui-searchable-dropdown>
        </div>
        <div class="row ml-0" aria-live="assertive" *ngIf="modifyUserForm.controls.roleaccess.invalid && showErrorMsgRole">
          <span role="alert" class="error-cls">
            <img class="error-icon" src="../../../../assets/images/icon-error.svg" />
            Please select role access
          </span>
        </div>
      </div>
      <div class="inpt-col-cls">
        <label class="user-lbl-cls">Role function <span
            class="inpt-reqrd-cls">*</span> </label>
        <!-- <div tabindex="0"> -->
          <app-multi-select-dropdown [list]="functionList" [placeholder]="'Role Function'"
            [isDisabled]="isRoleFunctionDisabled" (shareCheckedList)="shareCheckedList($event)" role="combobox" aria-required="true"
            
            [resetDropdown]="isResetFunctions" (shareIndividualCheckedList)="shareIndividualCheckedList($event)"
            required (click)="touchRoleFunction()">
          </app-multi-select-dropdown>
        <!-- </div> -->
        <div class="row ml-0" aria-live="assertive" *ngIf="((!isRoleFunctionValid && checkedRoleFunctions.length <1) 
        || ( isRoleFunctionTouched && checkedRoleFunctions.length <1))">
          <span role="alert" class="error-cls">
            <img class="error-icon" src="../../../../assets/images/icon-error.svg" />
            Please select role function
          </span>
        </div>
      </div>

      <div class="add-role-btn">
        <div class="save-cncl-btn-cls">
          <button class="texttype-globalbtn-cls" (click)="resetControls() && isResetFunctions = !isResetFunctions"
            tabindex="0">
            <i class="icon-icon-ui-cross"></i>Cancel
          </button>
          <button class="primary-globalbtn-cls" (click)="saveCustomer()" [disabled]="checkFormValidation()"
            tabindex="0">Save</button>
        </div>

      </div>
    </div>


    <div style="width: 50%;padding:30px 0 10px 0;" *ngIf="!toAddCustomer">
      <button class="outline-globalbtn-cls" (click)="addRoleAccess()"  [disabled]="showHideSectionOnInactiveStatus">
        <i class="icon-icon-ui-plus icon-type2-cls roundGreenBorderCls" ></i>
        Additional access required
      </button>

    </div>

    <div class="submitCancelBtn">
      <button class="texttype-globalbtn-cls" (click)="cancel()" tabindex="0">
        <i class="icon-icon-ui-cross"></i>Cancel
      </button>
      <button class="primary-globalbtn-cls" (click)="submitUserForm()" [disabled]="checkSubmitBtnDisabled()"
      tabindex="0">Submit</button>
    </div>
  </div>

</form>
