<form [formGroup]="secondFormGroup">
  <div class="backButtonTag">
    <button class="backButton" mat-button matStepperPrevious><span class="back-arrow"></span>Back</button>
  </div>
  <div class="stepper-container">
    <div class="addressDetailsIcon"></div>
    <div class="header">
      <h2 class="section-header">Address details</h2>
      <p class="section-header-label">Destinations between which Facilities Access is being sought <strong>(for
          multiple points please provide spreadsheet
          details with multiple A & B Ends)</strong></p>
    </div>
  </div>
  <hr>
  <div class="group1" formArrayName="additionalEnds">
    <div *ngFor="let add of additionalEnds?.controls; let i=index" [formGroupName]="i">
      <hr *ngIf="i > [0];">
      <div class="row">
        <div class="col-1 addressAandBEndIcon"></div>
        <div class="col-11">
          <div class="note">
            <span role="alert" aria-label="A-END" *ngIf="i > [0]; else elseBlock">A{{i}}-End</span>
            <ng-template #elseBlock>A-End</ng-template>
          </div>
          <div class="row col-12 pt-3 pb-3">
            <div class="col-2 pl-0">
              <label class="user-details-label-top required-field" aria-label="A-End details section.A-End Address" for="aEndAddress" aria-required="true" required>Address</label>
              <input type="text" aria-required="true" class="form-control field-input" maxlength="300" id="aEndAddress" name="aEndAddress"
                placeholder="Enter address" attr.aria-label="document title" formControlName="aEndAddress">
            </div>
            <div class="col-2">
              <label class="user-details-label-top required-field" aria-label="A-End Suburb" for="suburbA" aria-required="true" required>Suburb</label>
              <input type="text" aria-required="true" class="form-control field-input" maxlength="100" id="suburbA" name="suburbA"
                placeholder="Enter suburb" attr.aria-label="document title" formControlName="suburbA">
            </div>
            <div class="col-2">
              <label class="user-details-label-top required-field" aria-label="A-End Postcode" for="postcodeA" aria-required="true" required>Postcode</label>
              <input type="text" aria-required="true" class="form-control field-input" maxlength="4" id="postcodeA" name="postcodeA"
                placeholder="Enter postcode" attr.aria-label="document title" formControlName="postcodeA"
                [ngClass]="{'invalid-form-control': (secondFormGroup.get('additionalEnds')['controls'][i].controls.postcodeA.touched && secondFormGroup.get('additionalEnds')['controls'][i].controls.postcodeA.invalid)}">
              <span role="alert"
                *ngIf="secondFormGroup.get('additionalEnds')['controls'][i].controls.postcodeA.touched && secondFormGroup.get('additionalEnds')['controls'][i].controls.postcodeA.invalid"
                class="help-block errorMsg">Please enter a valid postcode</span>
            </div>
            <div class="col-2">
              <label class="user-details-label-top required-field" automationType="label" automationId="lblrole" for="state"
              aria-label="A-End State">
                State
              </label>
              <app-cui-searchable-dropdown #businessTypes class="selectDropdowns" formControlName="stateA" name="stateA"
                [items]="stateList" [isRequired]=true [model]="stateModel" [placeholder]="'Select state'"
                automationType="dropdown" automationId="drpdwnroleType" [labelName]="'Records'" [noAutoComplete]=false
                id="stateA" class="user-dropDown" required></app-cui-searchable-dropdown>
            </div>
            <div class="col-3">
              <label class="user-details-label-top required-field"  aria-label="A-End GPS co-ordinates" for="gpsCoordsA" aria-required="true" required>GPS co-ordinates</label>
              <input type="text" aria-required="true" class="form-control field-input" maxlength="100" id="gpsCoordsA" name="gpsCoordsA"
                placeholder="Enter GPS co-ordinates" attr.aria-label="document title" formControlName="gpsCoordsA">
            </div>
          </div>
          <div class="removeBtn">
            <button class="removeButton" *ngIf="i > [0];" (click)="removeEnd(i)">
              <i class="icon-icon-ui-cross"></i>
              Remove
            </button>
          </div>
          <div class="note">
            <span *ngIf="i > [0]; else elseBlockB">B{{i}}-End</span>
            <ng-template #elseBlockB>B-End</ng-template>
          </div>
          <div class="row col-12 pt-3 pb-3">
            <div class="col-2 pl-0">
              <label class="user-details-label-top required-field"  aria-label="B-End details section.B-End Address" for="bEndAddress" aria-required="true" required>Address</label>
              <input type="text" aria-required="true" class="form-control field-input" maxlength="300" id="bEndAddress" name="bEndAddress"
                placeholder="Enter address" attr.aria-label="document title" formControlName="bEndAddress">
            </div>
            <div class="col-2">
              <label class="user-details-label-top required-field"  aria-label="B-End Suburb" for="suburbB" aria-required="true" required>Suburb</label>
              <input type="text" aria-required="true"class="form-control field-input" maxlength="100" id="suburbB" name="suburbB"
                placeholder="Enter suburb" attr.aria-label="document title" formControlName="suburbB">
            </div>
            <div class="col-2">
              <label class="user-details-label-top required-field" aria-label="B-End Postcode" for="postcodeB" aria-required="true" required>Postcode</label>
              <input type="text" aria-required="true" class="form-control field-input" maxlength="4" id="postcodeB" name="postcodeB"
                placeholder="Enter postcode" attr.aria-label="document title" formControlName="postcodeB"
                [ngClass]="{'invalid-form-control': (secondFormGroup.get('additionalEnds')['controls'][i].controls.postcodeB.touched && secondFormGroup.get('additionalEnds')['controls'][i].controls.postcodeB.invalid)}">
              <span role="alert"
                *ngIf="secondFormGroup.get('additionalEnds')['controls'][i].controls.postcodeB.touched && secondFormGroup.get('additionalEnds')['controls'][i].controls.postcodeB.invalid"
                class="help-block errorMsg">Please enter a valid postcode</span>
            </div>
            <div class="col-2">
              <label class="user-details-label-top required-field" automationType="label" automationId="lblrole" for="state"
              aria-label="B-End State">
                State
              </label>
              <app-cui-searchable-dropdown #businessTypes class="selectDropdowns" formControlName="stateB" name="stateB"
                [items]="stateList" [isRequired]=true [model]="stateModel" [placeholder]="'Select state'"
                automationType="dropdown" automationId="drpdwnroleType" [labelName]="'Records'" [noAutoComplete]=false
                id="stateB" class="user-dropDown"></app-cui-searchable-dropdown>
            </div>
            <div class="col-3">
              <label class="user-details-label-top required-field" for="gpsCoordsB" aria-label="B-End GPS co-ordinates" aria-required="true" required>GPS co-ordinates</label>
              <input type="text" aria-required="true" class="form-control field-input" maxlength="100" id="gpsCoordsB" name="gpsCoordsB"
                placeholder="Enter GPS co-ordinates" attr.aria-label="document title" formControlName="gpsCoordsB">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <h2 class="note">
    Estimated Duct length between all the destinations
  </h2>
  <div class="row col-12 pt-3">
    <div class="col-3 pl-0">
      <label class="user-details-label-top" for="totalMeters" aria-required="true" required>Total Meters for all
        routes</label>
      <input type="number" min="0" (input)="onlyPositiveNumbers('totalMeters')" class="form-control field-input"
        maxlength="250" id="totalMeters" name="totalMeters" placeholder="Enter total meter"
        attr.aria-label="document title" formControlName="totalMeters">
      <span class="field-comment">(E.g: A1-B1, A2-B2 etc.)</span>
    </div>
    <div class="col-2">
      <label class="user-details-label-top" for="bareFibre" aria-required="true" required>Bare Fibre</label>
      <input type="number" min="0" (input)="onlyPositiveNumbers('bareFibre')" class="form-control field-input"
        maxlength="250" id="bareFibre" name="bareFibre" placeholder="Enter total meter" attr.aria-label="document title"
        formControlName="bareFibre">
    </div>
    <div class="col-2">
      <label class="user-details-label-top" for="subduct" aria-required="true" required>Subduct</label>
      <input type="number" min="0" (input)="onlyPositiveNumbers('subduct')" class="form-control field-input"
        maxlength="250" id="subduct" name="subduct" placeholder="Enter subduct" attr.aria-label="document title"
        formControlName="subduct">
    </div>
    <div class="col-2">
      <label class="user-details-label-top" for="tunnel" aria-required="true" required>Tunnel</label>
      <input type="text" class="form-control field-input" maxlength="20" id="tunnel" name="tunnel"
        placeholder="Enter tunnel" attr.aria-label="document title" formControlName="tunnel">
    </div>
    <div class="col-2">
      <label class="user-details-label-top" for="teba" aria-required="true" required>TEBA/Backhaul</label>
      <input type="text" class="form-control field-input" maxlength="20" id="teba" name="teba"
        placeholder="Enter TEBA/Backhaul" attr.aria-label="document title" formControlName="teba">
    </div>
  </div>
  <div class="stepper-container">
    <div class="otherReqIcon"></div>
    <h2 class="section-header">Other Special Requirement</h2>
  </div>
  <hr>
  <div class="row col-12 pt-3">
    <div class="col-4 pl-0">
      <label class="user-details-label-top required-field" for="termofFacility" aria-required="true" required>Intended term of Facilities
        Access</label>
      <input type="number" aria-required="true" min="15" (input)="onlyPositiveNumbers('termofFacility')" class="form-control field-input"
        maxlength="250" id="termofFacility" name="termofFacility" placeholder="Enter years"
        attr.aria-label="document title" formControlName="termofFacility">
    </div>
  </div>
  <div class="special-requirement-group">
    <div class="row col-12 pt-3">
      <div class="col-4 pl-0">
        <label class="user-details-label-top" for="breakouts" aria-required="true" required>No. of Breakouts</label>
        <input type="number" min="0" (input)="onlyPositiveNumbers('breakouts')" class="form-control field-input"
          maxlength="250" id="breakouts" name="breakouts" placeholder="Enter no. of breakouts"
          attr.aria-label="document title" formControlName="breakouts">
      </div>
      <div class="col-4">
        <label class="user-details-label-top" for="splices" aria-required="true" required>No. of Splices</label>
        <input type="number" min="0" (input)="onlyPositiveNumbers('splices')" class="form-control field-input"
          maxlength="250" id="splices" name="splices" placeholder="Enter no. of splices"
          attr.aria-label="document title" formControlName="splices">
      </div>
      <div class="col-4">
        <label class="user-details-label-top" for="loops" aria-required="true" required>No. of Loops</label>
        <input type="number" min="0" (input)="onlyPositiveNumbers('loops')" class="form-control field-input"
          maxlength="250" id="loops" name="loops" placeholder="Enter no. of loops" attr.aria-label="document title"
          formControlName="loops">
      </div>
    </div>
    <div class="row col-12 pt-3">
      <div class="col-4 pl-0">
        <label class="user-details-label-top" for="leadIn" aria-required="true" required>Lead-in (specify cable spec)</label>
        <app-cui-searchable-dropdown #businessTypes tabindex="0" class="selectDropdowns" formControlName="leadIn" name="leadIn"
          [items]="fibreList" [isRequired]=false [model]="leadInModel" [placeholder]="'Select lead-in'"
          automationType="dropdown" automationId="drpdwnroleType" [labelName]="'Records'" [noAutoComplete]=false
          id="leadIn" class="user-dropDown">
        </app-cui-searchable-dropdown>
      </div>
      <div class="col-4">
        <label class="user-details-label-top" for="address" aria-required="true" required>Lead-IN Address</label>
        <input type="text" class="form-control field-input" maxlength="300" id="address" name="address"
          placeholder="Enter lead-in address" attr.aria-label="document title" formControlName="address">
      </div>
      <div class="col-4">
        <label class="user-details-label-top" automationType="label" automationId="lblrole" for="fibre">
          Bare Fibre – street network
          <span class="field-comment">(specify cable spec)</span>
        </label>
        <app-cui-searchable-dropdown #businessTypes tabindex="0" class="selectDropdowns" formControlName="fibre" name="fibre"
          [items]="fibreList" [isRequired]=false [model]="fibreModel" [placeholder]="'Select appropriate fibre'"
          automationType="dropdown" automationId="drpdwnroleType" [labelName]="'Records'" [noAutoComplete]=false
          id="fibre" class="user-dropDown">
        </app-cui-searchable-dropdown>
      </div>
    </div>
    <div class="mt-4"  role="radiogroup">
      <span class="text required-field" style="font-size: 15px;" aria-label="Do you require a Multiple Subduct Joiner?">
        Do you require a Multiple Subduct Joiner?
      </span><br>
      <span class="subJoinerNote">
        Note:- Your FA, CRA or TWA must provide for the installation of Multiple Subduct Joiners before you can
        utilise this option
      </span>
      <span class="radioCheck">
        <input type="radio" aria-required="true" id="radioButton1" formControlName="radioValue" value="1" checked role="radio"
        
          style="transform: scale(1.45);">
         <label aria-label="Do you require a Multiple Subduct Joiner? Note:- Your FA, CRA or TWA must provide for the installation of Multiple Subduct Joiners before you can
         utilise this option. YES ." for="radioButton1">Yes</label> &nbsp; &nbsp;
        <input type="radio" id="radioButton2" formControlName="radioValue" value="0" checked
          style="transform: scale(1.45);">
         <label aria-label="Do you require a Multiple Subduct Joiner? Note:- Your FA, CRA or TWA must provide for the installation of Multiple Subduct Joiners before you can
         utilise this option. NO" for="radioButton2">No</label>
      </span>
    </div>
    <div class="row col-12 pt-3">
      <div class="textArea">
        <label class="user-details-label-top" for="spclConditionText" aria-required="true">Special
          conditions/comments</label>
        <textarea #textOne class="form-control document-txtarea" maxlength="1000" formControlName="spclConditionText"
          rows="4" id="spclConditionText" name="spclConditionText" aria-label="Special conditions/comments"
          placeholder="Enter all the details here"></textarea>
        <div class="text-right">
          <span role="alert">{{textOne.value ? textOne.value.length : 0}}/1000</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="studyTypeValue == 'C0 Study - Backhaul only'">
    <div class="stepper-container">
      <div class="twssp-sprite otherReqIcon sprite-m"></div>
      <h2 class="section-header">Backhaul/ Teba requirements</h2>
    </div>
    <hr>
    <div class="mb-4" role="radiogroup">
      <span class="user-details-label-top required-field">
        Is Warren & Brown Ducting (in line with TM00151 (Telstra Standard)) needed to this installation?
      </span><br>
      <div>
        <input type="radio" aria-required="true" id="ductingRadioButton1" formControlName="ductingRadioValue" value="1" checked
          style="transform: scale(1.45);" (click)="clearDynamicFieldValue('ductingText')" (keydown) = "clearDynamicField($event, 'ductingText')"> &nbsp;
        <label aria-label="Is Warren & Brown Ducting (in line with TM00151 (Telstra Standard)) needed to this installation? YES" for="ductingRadioButton1" class="user-details-label-top">Yes</label> &nbsp; &nbsp;
        <input type="radio" id="ductingRadioButton2" formControlName="ductingRadioValue" value="0" checked
          style="transform: scale(1.45);"> &nbsp;
        <label aria-label="Is Warren & Brown Ducting (in line with TM00151 (Telstra Standard)) needed to this installation? No" for="ductingRadioButton2" class="user-details-label-top">No</label>
      </div>
      <div class="textArea" *ngIf="secondFormGroup.controls.ductingRadioValue.value == '0'">
        <label aria-label="If No please indicate why it is not needed?" class="user-details-label-top required-field" for="ductingText" aria-required="true">
          If "No" please indicate why it is not needed?
        </label>
        <textarea #textDucting class="form-control document-txtarea" maxlength="300" formControlName="ductingText"
          rows="4" id="ductingText" name="ductingText" attr.aria-label="document description"
          placeholder="Enter all the details here"></textarea>
        <div class="text-right">
          <span>{{textDucting.value ? textDucting.value.length : 0}}/300</span>
        </div>
      </div>
    </div>
    <div class="mb-4" role="radiogroup">
      <span class="user-details-label-top required-field">
        Do you require Backhaul Access:
      </span><br>
      <div>
        <input type="radio" aria-required="true" id="accessRadioButton1" formControlName="accessRadioValue" value="1" checked
          style="transform: scale(1.45);" tabindex="0"> &nbsp;
        <label aria-label="Do you require Backhaul Access: YES" for="accessRadioButton1" class="user-details-label-top">Yes</label> &nbsp; &nbsp;
        <input type="radio" id="accessRadioButton2" formControlName="accessRadioValue" value="0" checked
          style="transform: scale(1.45);"> &nbsp;
        <label aria-label="Do you require Backhaul Access: NO" for="accessRadioButton2" class="user-details-label-top">No</label>
      </div>
    </div>
    <div class="mb-4">
      <label class="user-details-label-top" aria-label="Length of Optical Fibre cable within the Telstra exchange" for="lengthOfCable" aria-required="true">
        Length of Optical Fibre cable within the Telstra exchange
      </label>
      <div class="row col-12">
        <div class="col-4 pl-0">
          <input type="number" min="0" (input)="onlyPositiveNumbers('lengthOfCable')" class="form-control field-input"
            maxlength="20" id="lengthOfCable" name="lengthOfCable" placeholder="i.e. cable chamber to TEBA"
            attr.aria-label="document title" formControlName="lengthOfCable">
        </div>
        <div class="col-2 pt-3 user-details-label-top">Mts</div>
      </div>
    </div>
    <div class="mb-4" role="radiogroup">
      <span class="user-details-label-top required-field">
        Is an OFTU required?
      </span><br>
      <div>
        <input type="radio" aria-required="true" id="oftuRadioButton1" formControlName="oftuRadioValue" value="1" checked
          style="transform: scale(1.45);" (click)="clearDynamicFieldValue('oftuText')"> &nbsp;
        <label aria-label="Is an OFTU required? YES" for="oftuRadioButton1" class="user-details-label-top">Yes</label> &nbsp; &nbsp;
        <input type="radio" id="oftuRadioButton2" formControlName="oftuRadioValue" value="0" checked
          style="transform: scale(1.45);"> &nbsp;
        <label aria-label="Is an OFTU required? NO" for="oftuRadioButton2" class="user-details-label-top">No</label>
      </div>
      <div class="textArea" *ngIf="secondFormGroup.controls.oftuRadioValue.value == '0'">
        <label class="user-details-label-top required-field" for="oftuText" aria-required="true">
          If "No" please indicate the rack for OF termination?
        </label>
        <textarea #textOFTU class="form-control document-txtarea" maxlength="300" formControlName="oftuText" rows="4"
          id="oftuText" name="oftuText" attr.aria-label="document description"
          placeholder="Enter all the details here"></textarea>
        <div class="text-right">
          <span>{{textOFTU.value ? textOFTU.value.length : 0}}/300</span>
        </div>
      </div>
    </div>
    <div class="mb-4">
      <label class="user-details-label-top" for="numberOfCable" aria-required="true">
        Number of Acquirer's cables <span class="field-comment">(This order)</span>
      </label>
      <div class="row col-12">
        <div class="col-4 pl-0">
          <input type="number" min="0" (input)="onlyPositiveNumbers('numberOfCable')" class="form-control field-input"
            maxlength="100" id="numberOfCable" name="numberOfCable" placeholder="i.e. cable chamber to TEBA"
            attr.aria-label="document title" formControlName="numberOfCable">
        </div>
      </div>
    </div>
    <div class="mb-4" role="radiogroup">
      <span class="user-details-label-top required-field">
        Are any Customer cables > 30m in length?
      </span><br>
      <div>
        <input type="radio" aria-required="true" id="customerCableLengthRadioButton1" formControlName="customerCableLengthRadioValue"
          value="1" checked style="transform: scale(1.45);"> &nbsp;
        <label aria-label="  Are any Customer cables > 30m in length? Yes" for="customerCableLengthRadioButton1" class="user-details-label-top">Yes</label> &nbsp; &nbsp;
        <input type="radio" id="customerCableLengthRadioButton2" formControlName="customerCableLengthRadioValue"
          value="0" checked style="transform: scale(1.45);" (click)="clearDynamicFieldValue('customerCableLengthText')">
        &nbsp;
        <label aria-label="  Are any Customer cables > 30m in length? No" for="customerCableLengthRadioButton2" class="user-details-label-top">No</label>
      </div>
      <div *ngIf="secondFormGroup.controls.customerCableLengthRadioValue.value == '1'">
        <label class="user-details-label-top required-field" for="customerCableLengthText" aria-required="true">
          If "Yes" please indicate the length (in metres) for each of those cables > 30m
        </label>
        <div class="col-4 pl-0">
          <input type="text" class="form-control field-input" maxlength="20" id="customerCableLengthText"
            name="customerCableLengthText" placeholder="Enter in meters" attr.aria-label="document title"
            formControlName="customerCableLengthText">
        </div>
      </div>
    </div>
    <div class="mb-4">
      <label class="user-details-label-top" for="rackLocation" aria-required="true">
        Rack location or designation for each Customer cable (Pigtail) termination
      </label>
      <div class="col-4 pl-0">
        <input type="text" class="form-control field-input" maxlength="20" id="rackLocation" name="rackLocation"
          placeholder="Enter the details here" attr.aria-label="document title" formControlName="rackLocation">
      </div>
    </div>
    <div class="mb-4">
      <label class="user-details-label-top" for="optionsOFTU" aria-required="true">
        Proposed options of OFTU Locations near TEBA area
      </label>
      <div class="col-4 pl-0">
        <input type="text" class="form-control field-input" maxlength="300" id="optionsOFTU" name="optionsOFTU"
          placeholder="Enter the details here" attr.aria-label="document title" formControlName="optionsOFTU">
      </div>
    </div>
    <div class="mb-4">
      <p class="user-details-label-top">
        Provide a sketch or plan indicating proposed route of Optic Fibre from cable chamber to proposed location of
        OFTU
      </p>
    </div>
  </div>
  <div *ngIf="studyTypeValue == 'CFD Study - (Construction Finalisation Linked to relevant TP or ADR study)'">
    <div class="stepper-container">
      <div class="twssp-sprite otherReqIcon sprite-m"></div>
      <span class="section-header">Sub-duct/fibre cable haul</span>
    </div>
    <hr>
    <div class="mb-4 pt-3">
      <span>Fibre Cable&nbsp;</span>
      <span style="display: inline-block;width: 20%;">
        <app-cui-searchable-dropdown #businessTypes tabindex="0" class="selectDropdowns" formControlName="fCable" name="fCable"
          [items]="fibreList" [isRequired]=false [model]="fibreModel" [placeholder]="'Select fibre cable'"
          automationType="dropdown" automationId="drpdwnroleType" [labelName]="'Records'" [noAutoComplete]=false
          id="fCable" class="user-dropDown">
        </app-cui-searchable-dropdown>
      </span>
      <span>
        &nbsp;F cable has been hauled in&nbsp;
      </span>
      <span style="display: inline-block">
        <input type="text" class="form-control field-input" maxlength="500" id="fCableLocFrom" name="fCableLocFrom"
          placeholder="Enter location" attr.aria-label="document title" formControlName="fCableLocFrom">
      </span>
      <span>&nbsp;to&nbsp;</span>
      <span style="display: inline-block">
        <input type="text" class="form-control field-input" maxlength="500" id="fCableLocTo" name="fCableLocTo"
          placeholder="Enter location" attr.aria-label="document title" formControlName="fCableLocTo">
      </span>
      <span>&nbsp;installed sub-duct in Telstra infrastructure.</span>
    </div>
    <div class="mb-4">
      <span class="note">Materials Used</span>
      <div class="col-4 pl-0 pt-3">
        <label class="user-details-label-top required-field" for="ofCable" aria-required="true" required>Optical fibre cable</label>
        <input type="text" class="form-control field-input" maxlength="100" id="ofCable" name="ofCable"
          placeholder="Enter optical fibre cable" attr.aria-label="document title" formControlName="ofCable">
      </div>
      <div class="col-4 pl-0 pt-3">
        <label class="user-details-label-top required-field" for="subductManType" aria-required="true" required>Multiple Subduct Joiner
          manufacturer and type</label>
        <input type="text" class="form-control field-input" maxlength="500" id="subductManType" name="subductManType"
          placeholder="Enter multiple subduct joiner manufacturer and type" attr.aria-label="document title"
          formControlName="subductManType">
      </div>
      <div class="col-4 pl-0 pt-3">
        <label class="user-details-label-top required-field" for="cableManName" aria-required="true" required>Cable manufacturers name</label>
        <input type="text" class="form-control field-input" maxlength="100" id="cableManName" name="cableManName"
          placeholder="Enter cable manufacturers name" attr.aria-label="document title" formControlName="cableManName">
      </div>
      <div class="col-4 pl-0 pt-3">
        <label class="user-details-label-top required-field" for="cableType" aria-required="true" required>Cable type</label>
        <input type="text" class="form-control field-input" maxlength="100" id="cableType" name="cableType"
          placeholder="Enter cable type" attr.aria-label="document title" formControlName="cableType">
      </div>
    </div>
  </div>
</form>