import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'tw-core-ui';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToolTipComponent } from '../shared/components/tooltip/tool-tip.component';
import { HttpService } from '../shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class BulkQuoteService {

  public bulkLabelDataObj: any;
  public env: any = window['environment'];
  public aemDefaultUri = {
    bulkGridLabel: 'bulkList',
    bulkGridHeader: 'bulk-header.json',
    bulkGridHeaderNonRG: 'bulk-header-non-rg.json',
    mockGetBulkList: 'mock/bulk-quote-upload.json',
    bulkList: 'darkfibre-order/order-requests',
    bulkUpload: 'bulk-quote-upload.json',
    fileUpload: 'darkfibre/bulk-quote-requests/upload-csv/',
    bulkRequest: 'bulk-quote-request.json',
    bulkRequestDetails: 'darkfibre/bulk-quote-requests',
    mockbulkQuoteRequest: 'mock/bulk-quote-request-details.json',
    downloadTemplate: 'darkfibre/bulk-quote-requests/download-template/',
    bulkRequestSubmit: 'darkfibre/bulk-quote-requests/process-csv'
  };
  public gridHeaderDataObj: any = [];
  public requestDetailsLabelAuthoringDataObj: any;
  public bulkSiteDetailsLabelAuthoringDataObj: any;
  public bulkRequestDetailsLabelAuthoringDataObj: any;
  public bulkReferenceId: any;
  public allGridHeaderDataObj: any = [];
  constructor(private http: HttpClient, private authService: AuthService, private apiService: HttpService) {

  }

  setBulkReferenceId(val) {
    this.bulkReferenceId = val;
  }

  getBulkReferenceId() {
    return this.bulkReferenceId;
  }

  public getBulkLabels() {
    return new Promise((resolve, reject) => {
      if (!this.bulkLabelDataObj) {
        const url = this.env.aemEndPoint + this.aemDefaultUri.bulkGridLabel;
        this.http.get<any>(url)
          .subscribe(
            res => {
              this.bulkLabelDataObj = res.data.content;
              resolve(this.bulkLabelDataObj);
            },
            err => {
              this.bulkLabelDataObj = null;
              reject(err);
              return observableThrowError(err || 'Server error');
            }
          );
      } else {
        resolve(this.bulkLabelDataObj);
      }
    });
  }
  public getGridHeader(serviceType) {
    const map = {
      ToolTipComponent: ToolTipComponent,

    };
    return new Promise((resolve, reject) => {
      const url = this.env.aemEndPoint + (serviceType == 'RG' ? this.aemDefaultUri.bulkGridHeader : this.aemDefaultUri.bulkGridHeaderNonRG);
      this.http.get<any>(url)
        .subscribe(res => {
          res.headers.forEach((x: any) => {
            if (x.cellRendererFramework) {
              x.cellRendererFramework = map[x.cellRendererFramework];
            }
          });
          this.gridHeaderDataObj = res;
          resolve(this.gridHeaderDataObj);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }
  public getBulkList(cidn) {
    const username = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail'];
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',

      })
    };
    const url = this.env.apiPoint + this.aemDefaultUri.bulkList + '?cidn=' + cidn;
    //To run in local
    // const url = this.env.aemEndPoint + this.aemDefaultUri.mockGetBulkList;
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(url, httpOptions)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }
  public saveNewBulk(payload) {

    const username = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail'];

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',
      })
    };
    const url = this.env.apiPoint + this.aemDefaultUri.bulkList;
    //To run in local
    //  const url = this.env.aemEndPoint + this.aemDefaultUri.mockSaveBulk;

    // if(payload.aEndAdress.siteType === "a2EndAdress"){
    //   payload.aEndAdress.siteType = "aEndAdress";
    // }

    return new Promise<any>((resolve, reject) => {
      this.http.put<any>(url, payload, httpOptions)
        //  this.http.get<any>(url, httpOptions)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }


  public saveBulkRequestsDetails(payload, isModifyBulkQuote) {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',
      })
    };
    const url = this.env.apiPoint + this.aemDefaultUri.bulkRequestDetails;
    if (isModifyBulkQuote) {
      return new Promise<any>((resolve, reject) => {
        this.http.patch<any>(url, payload, httpOptions)
          .subscribe(res => {
            resolve(res);
          }, err => {
            reject(err);
            return observableThrowError(err || 'Server error');
          });
      });

    } else {
      return new Promise<any>((resolve, reject) => {
        this.http.put<any>(url, payload, httpOptions)
          .subscribe(res => {
            resolve(res);
          }, err => {
            reject(err);
            return observableThrowError(err || 'Server error');
          });
      });
    }
  }


  public patchBulkRequestsDetails(payload) {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',
      })
    };

    const url = this.env.apiPoint + this.aemDefaultUri.bulkRequestDetails;

    return new Promise<any>((resolve, reject) => {
      this.http.patch<any>(url, payload, httpOptions)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getBulkRequestPageLabel() {
    // this.sendSpinnerFlag.next(true);
    return new Promise((resolve, reject) => {
      const requestDetailsUrl = this.env.aemEndPoint + this.aemDefaultUri.bulkRequest;

      this.http.get<any>(requestDetailsUrl)
        .subscribe(
          res => {
            this.bulkRequestDetailsLabelAuthoringDataObj = res;
            resolve(this.bulkRequestDetailsLabelAuthoringDataObj);
          },
          err => {
            this.bulkRequestDetailsLabelAuthoringDataObj = null;
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }

  public getBulkUploadPageLabel() {
    // this.sendSpinnerFlag.next(true);
    return new Promise((resolve, reject) => {
      const requestDetailsUrl = this.env.aemEndPoint + this.aemDefaultUri.bulkUpload;

      this.http.get<any>(requestDetailsUrl)
        .subscribe(
          res => {
            this.bulkRequestDetailsLabelAuthoringDataObj = res;
            resolve(this.bulkRequestDetailsLabelAuthoringDataObj);
          },
          err => {
            this.bulkRequestDetailsLabelAuthoringDataObj = null;
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }

  public downloadTemplate(cidn, serviceType) {
    this.http.get(this.env.apiPoint + this.aemDefaultUri.downloadTemplate + cidn, { responseType: 'blob', headers: this.apiService.getHeaders().headers })
      .pipe(
        tap(response => {
          this.downloadCsv(response, serviceType);
        }),
        catchError(error => {
          return throwError(error);
        })
      )
      .subscribe();
  }

  bulkRequestSubmit(payload, buldRequestId, cidn) {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',
      })
    };
    const url = this.env.apiPoint + this.aemDefaultUri.bulkRequestSubmit + '/' + buldRequestId + '?cidn=' + cidn;

    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(url, payload, httpOptions)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  downloadCsv(blob, serviceType) {
    let filename = '';
    if (serviceType == 'RG') {
      filename = 'TelstraLimitedBulkRequestsUploadForm.xlsx'; // Extract from response headers
    } else {
      filename = 'BulkRequestsUploadForm.xlsx';
    }

    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(blobUrl);
  }

  downloadErrorCsv(csvContent) {
    const base64EncodedData = csvContent;
    const decodedData = atob(base64EncodedData);
    const csv = decodedData.split("\n").join("\n");
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Error.csv";
    link.click();
  }

  public getBulkRequestDetails(cidn, timeZone, bulkRequestId, sysId) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',

      })
    };
    //  const url = this.env.aemEndPoint + this.aemDefaultUri.mockbulkQuoteRequest;

    let url = this.env.apiPoint + this.aemDefaultUri.bulkRequestDetails + '/' + bulkRequestId + '?cidn=' + cidn + '&timezone=' + timeZone;
    if (sysId) {
      url += "&sys-id=" + sysId;
    }
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(url, httpOptions)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

}

function observableThrowError(arg0: any): void {
  throw new Error('Function not implemented.');
}


