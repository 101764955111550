import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges, HostListener } from '@angular/core';
import { FormArray, FormControl, FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { CreateQuoteService } from 'src/app/quote-management/create-new-quote/create-quote.service';
import { CustomDropDown, CustomTextField } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { SiteDetailsService } from 'src/app/quote-management/create-new-quote/site-details/site-details.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.less']
})
export class ContactDetailsComponent implements OnInit {
  @Input() aemLabelAuthoring;
  @Input() savedData;
  phoneCountryList;
  showA1ValErr: boolean = false;
  showA2ValErr: boolean = false;
  showZ1ValErr: boolean = false;
  showZ2ValErr: boolean = false;

  isEmailInvalidA1siteContactEmailAddress: boolean = false;
  isEmailInvalidA1alternativeSiteContactEmailAddress: boolean = false;
  isEmailInvalidZ1siteContactEmailAddress: boolean = false;
  isEmailInvalidZ1alternativeSiteContactEmailAddress: boolean = false;
  isEmailInvalidA2siteContactEmailAddress: boolean = false;
  isEmailInvalidA2alternativeSiteContactEmailAddress: boolean = false;
  isEmailInvalidZ2siteContactEmailAddress: boolean = false;
  isEmailInvalidZ2alternativeSiteContactEmailAddress: boolean = false;

  formGroupObjBasic = {
    detailedAssessment: new FormControl('No')
  }


  contactDetailsForm = new FormGroup(this.formGroupObjBasic);
  isModifyQuote: boolean = false;

  public fundingSourceDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Funding source (POW/IMC/Other) Required Combobox. Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  createQuotePayload: any;
  pattern = /^[a-zA-Z0-9!@#$%^&*()\-\_=+:'",.?|{}\[\]\/\`\~\\ ]*$/;
  productOfferings: any;
  fundingSourceList = [];
  constructor(private createQuoteService: CreateQuoteService, private siteDetailsService: SiteDetailsService) { }

  subscription: Subscription;
  isDiversity = false; formGroupObjWithoutDiversity = {}; formGroupObjDiversity = {};
  isUpdatingValueAndValidity: boolean = false;
  ngOnInit(): void {
    this.createQuotePayload = this.createQuoteService.getProductDetailsFormValues();
    this.isDiversity = (this.createQuotePayload.doYouRequireDiversity == "Yes" && this.createQuotePayload.describeYourDiversity === 'All new services with diversity') ? true : false;
    this.getPhoneCountryList();
    this.productOfferings = this.createQuoteService.getProductProductData();
    this.productOfferings[0]?.prodSpecCharValueUse.map(p => {
      if (p.name === 'POW/IMC') {
        let fundingVal = p.productSpecCharacteristicValue;
        fundingVal.map(f => {
          this.fundingSourceList.push(f.value);
        });
      }
      this.fundingSourceList?.sort();
    })
    this.formGroupObjBasic = {
      detailedAssessment: new FormControl((this.savedData?.feasibilityRequestType && this.savedData?.feasibilityRequestType == "Detailed") ? "Yes" : "No")
    }
    this.contactDetailsForm = new FormGroup(this.formGroupObjBasic);
    const forbiddenCharsPattern = /[<>;]/;

    this.formGroupObjWithoutDiversity = {
      A1siteContactName: new FormControl(this.savedData?.aEndAdress?.primaryContactName || '', [Validators.maxLength(21), Validators.pattern(this.pattern), this.forbiddenCharsValidator(forbiddenCharsPattern)]),
      A1siteContactNumber: new FormControl((this.savedData?.aEndAdress?.primaryContactNumber) ? this.savedData?.aEndAdress?.primaryContactNumber.substring(this.savedData?.aEndAdress?.primaryContactNumber.indexOf(' ') + 1) : '', [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$")]),
      A1siteContactEmailAddress: new FormControl(this.savedData?.aEndAdress?.primaryContactEmail || '', [Validators.maxLength(100), this.ValidateEmail, this.forbiddenCharsValidator(forbiddenCharsPattern)]),

      A1alternativeSiteContactName: new FormControl(this.savedData?.aEndAdress?.altContactName || '', [Validators.maxLength(21), Validators.pattern(this.pattern), this.forbiddenCharsValidator(forbiddenCharsPattern)]),
      A1alternativeSiteContactNumber: new FormControl((this.savedData?.aEndAdress?.altContactNumber) ? this.savedData?.aEndAdress?.altContactNumber.substring(this.savedData?.aEndAdress?.altContactNumber.indexOf(' ') + 1) : '', [Validators.maxLength(10), Validators.pattern("^[0-9]*$")]),
      A1alternativeSiteContactEmailAddress: new FormControl(this.savedData?.aEndAdress?.altContactEmail || '', [Validators.maxLength(100), this.ValidateEmail,this.forbiddenCharsValidator(forbiddenCharsPattern)]),


      sameAsAEndContact: new FormControl(this.savedData?.sameAsAEndContact == "Yes" ? true : false),
      Z1siteContactName: new FormControl(this.savedData?.zEndAdress?.primaryContactName || '', [Validators.required, Validators.maxLength(21), Validators.pattern(this.pattern), this.forbiddenCharsValidator(forbiddenCharsPattern)]),
      Z1siteContactNumber: new FormControl((this.savedData?.zEndAdress?.primaryContactNumber) ? this.savedData?.zEndAdress?.primaryContactNumber.substring(this.savedData?.zEndAdress?.primaryContactNumber.indexOf(' ') + 1) : '', [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$")]),
      Z1siteContactEmailAddress: new FormControl(this.savedData?.zEndAdress?.primaryContactEmail || '', [Validators.maxLength(100), this.ValidateEmail, this.forbiddenCharsValidator(forbiddenCharsPattern)]),

      Z1alternativeSiteContactName: new FormControl(this.savedData?.zEndAdress?.altContactName || '', [Validators.maxLength(21), Validators.pattern(this.pattern), this.forbiddenCharsValidator(forbiddenCharsPattern)]),
      Z1alternativeSiteContactNumber: new FormControl((this.savedData?.zEndAdress?.altContactNumber) ? this.savedData?.zEndAdress?.altContactNumber.substring(this.savedData?.zEndAdress?.altContactNumber.indexOf(' ') + 1) : '', [Validators.maxLength(10), Validators.pattern("^[0-9]*$")]),
      Z1alternativeSiteContactEmailAddress: new FormControl(this.savedData?.zEndAdress?.altContactEmail || '', [Validators.maxLength(100), this.ValidateEmail, this.forbiddenCharsValidator(forbiddenCharsPattern)]),

      fundingSource: new FormControl(this.savedData?.fundingSource || '', [Validators.required]),
      l3WBSCodeCapex: new FormControl(this.savedData?.l3WBSCodeCapex || ''),
      l3WBSCodeOpex: new FormControl(this.savedData?.l3WBSCodeOpex || ''),
      otherPowImc: new FormControl(this.savedData?.otherPowImc || ''),
      preApprovalCost: new FormControl(this.savedData?.preApprovalCost || '', [Validators.required])
    };

    if (this.savedData?.l3WBSCodeCapex && this.contactDetailsForm.controls['l3WBSCodeCapex']) {
      this.contactDetailsForm.controls['l3WBSCodeCapex'].setValue(this.savedData?.l3WBSCodeCapex);
    } else if (this.createQuotePayload.l3WBSCodeCapex && this.contactDetailsForm.controls['l3WBSCodeCapex']) {
      this.contactDetailsForm.controls['l3WBSCodeCapex'].setValue(this.createQuotePayload.l3WBSCodeCapex);
    }
    if (this.savedData?.l3WBSCodeOpex && this.contactDetailsForm.controls['l3WBSCodeOpex']) {
      this.contactDetailsForm.controls['l3WBSCodeOpex'].setValue(this.savedData?.l3WBSCodeOpex);
    } else if (this.createQuotePayload.l3WBSCodeCapex && this.contactDetailsForm.controls['l3WBSCodeOpex']) {
      this.contactDetailsForm.controls['l3WBSCodeOpex'].setValue(this.createQuotePayload.l3WBSCodeOpex);
    }
    if (this.savedData?.otherPowImc && this.contactDetailsForm.controls['otherPowImc']) {
      this.contactDetailsForm.controls['otherPowImc'].setValue(this.savedData?.otherPowImc);
    } else if (this.createQuotePayload.otherPowImc && this.contactDetailsForm.controls['otherPowImc']) {
      this.contactDetailsForm.controls['otherPowImc'].setValue(this.createQuotePayload.otherPowImc);
    }
    if (this.savedData?.feasibilityRequestType == "Detailed" && this.savedData?.fundingSource) {
      setTimeout(() => {
        this.fundingSourceListChange(this.savedData?.fundingSource);
      }, 10)
    }
    if (this.savedData?.sameAsAEndContact && this.contactDetailsForm.controls['sameAsAEndContact']) {
      const chkBox = this.savedData?.sameAsAEndContact === "Yes" ? true : false
      this.contactDetailsForm.controls['sameAsAEndContact'].setValue(chkBox);
      (<HTMLInputElement>document.getElementById('path1SiteZEndAddressSame as A end contact details')).checked = chkBox;
    } else if (this.createQuotePayload.sameAsAEndContact && this.contactDetailsForm.controls['sameAsAEndContact']) {
      const chkBox = this.contactDetailsForm.controls['sameAsAEndContact'].value === "Yes" ? true : false
      this.contactDetailsForm.controls['sameAsAEndContact'].setValue(chkBox);
      (<HTMLInputElement>document.getElementById('path1SiteZEndAddressSame as A end contact details')).checked = chkBox;
    }

    this.formGroupObjDiversity = {
      path2A2sameAsAEndContact: new FormControl(this.savedData?.path2A2sameAsAEndContact == "Yes" ? true : false),
      path2A2sameAsZEndContact: new FormControl(this.savedData?.path2A2sameAsZEndContact == "Yes" ? true : false),

      A2siteContactName: new FormControl(this.savedData?.a2EndAdress?.primaryContactName || '', [Validators.required, Validators.maxLength(21), Validators.pattern(this.pattern), this.forbiddenCharsValidator(forbiddenCharsPattern)]),
      A2siteContactNumber: new FormControl((this.savedData?.a2EndAdress?.primaryContactNumber) ? this.savedData?.a2EndAdress?.primaryContactNumber.substring(this.savedData?.a2EndAdress?.primaryContactNumber.indexOf(' ') + 1) : '', [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$")]),
      A2siteContactEmailAddress: new FormControl(this.savedData?.a2EndAdress?.primaryContactEmail || '', [Validators.maxLength(100), this.ValidateEmail, this.forbiddenCharsValidator(forbiddenCharsPattern)]),

      A2alternativeSiteContactName: new FormControl(this.savedData?.a2EndAdress?.altContactName || '', [Validators.maxLength(21), Validators.pattern(this.pattern), this.forbiddenCharsValidator(forbiddenCharsPattern)]),
      A2alternativeSiteContactNumber: new FormControl((this.savedData?.a2EndAdress?.altContactNumber) ? this.savedData?.a2EndAdress?.altContactNumber.substring(this.savedData?.a2EndAdress?.altContactNumber.indexOf(' ') + 1) : '', [Validators.maxLength(10), Validators.pattern("^[0-9]*$")]),
      A2alternativeSiteContactEmailAddress: new FormControl(this.savedData?.a2EndAdress?.altContactEmail || '', [Validators.maxLength(100), this.ValidateEmail, this.forbiddenCharsValidator(forbiddenCharsPattern)]),

      path2Z2sameAsAEndContact: new FormControl(this.savedData?.path2Z2sameAsAEndContact == "Yes" ? true : false),
      path2Z2sameAsZEndContact: new FormControl(this.savedData?.path2Z2sameAsZEndContact == "Yes" ? true : false),
      path2Z2sameAsA2EndContact: new FormControl(this.savedData?.path2Z2sameAsA2EndContact == "Yes" ? true : false),

      Z2siteContactName: new FormControl(this.savedData?.z2EndAdress?.primaryContactName || '', [Validators.required, Validators.maxLength(21), Validators.pattern(this.pattern), this.forbiddenCharsValidator(forbiddenCharsPattern)]),
      Z2siteContactNumber: new FormControl((this.savedData?.z2EndAdress?.primaryContactNumber) ? this.savedData?.z2EndAdress?.primaryContactNumber.substring(this.savedData?.z2EndAdress?.primaryContactNumber.indexOf(' ') + 1) : '', [Validators.required, Validators.maxLength(10), Validators.pattern("^[0-9]*$")]),
      Z2siteContactEmailAddress: new FormControl(this.savedData?.z2EndAdress?.primaryContactEmail || '', [Validators.maxLength(100), this.ValidateEmail, this.forbiddenCharsValidator(forbiddenCharsPattern)]),

      Z2alternativeSiteContactName: new FormControl(this.savedData?.z2EndAdress?.altContactName || '', [Validators.maxLength(21), Validators.pattern(this.pattern), this.forbiddenCharsValidator(forbiddenCharsPattern)]),
      Z2alternativeSiteContactNumber: new FormControl((this.savedData?.z2EndAdress?.altContactNumber) ? this.savedData?.z2EndAdress?.altContactNumber.substring(this.savedData?.z2EndAdress?.altContactNumber.indexOf(' ') + 1) : '', [Validators.maxLength(10), Validators.pattern("^[0-9]*$")]),
      Z2alternativeSiteContactEmailAddress: new FormControl(this.savedData?.z2EndAdress?.altContactEmail || '', [Validators.maxLength(100), this.ValidateEmail, this.forbiddenCharsValidator(forbiddenCharsPattern)]),
    }

    this.createContactDetailsForm();

    this.createQuoteService.isModifyQuote.subscribe(val => {
      if (val) {
        this.isModifyQuote = val;
        this.fundingSourceDropDownModel.isDisabled = true;
      } else {
        this.isModifyQuote = false
      }
    });



  }


  forbiddenCharsValidator(forbiddenCharsPattern: RegExp) {
    return (control: FormControl): { [key: string]: any } | null => {
      const forbidden = forbiddenCharsPattern.test(control.value);
      return forbidden ? { 'forbiddenChars': { value: control.value } } : null;
    };
  }


  ValidateEmail(control: AbstractControl) {
    if (!control.value)
      return null;

    const pattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let isValid = pattern.test(control.value);

    return isValid ? null : { 'emailError': 'Email is invalid' };
  }

  ValidatePreApprovalCost(control: AbstractControl) {
    const pattern = /^(999999|\d{1,4}(\.\d{1,2})?)$/;
    let isValid = pattern.test(control.value);

    return isValid ? null : { 'preApprovalCostError': 'Pre approval cost is invalid' };
  }


  createContactDetailsForm(event?) {
    let formGroupObj = (this.isDiversity) ? { ...this.formGroupObjWithoutDiversity, ...this.formGroupObjDiversity } : { ...this.formGroupObjWithoutDiversity };
    formGroupObj = (this.contactDetailsForm.get("detailedAssessment")?.value == "Yes") ? { ...this.formGroupObjBasic, ...formGroupObj } : { ...this.formGroupObjBasic };
    this.contactDetailsForm = new FormGroup(formGroupObj);

    this.subscription = this.contactDetailsForm?.valueChanges.subscribe(value => {

      setTimeout(() => {
        if (this.contactDetailsForm.get("sameAsAEndContact") && this.contactDetailsForm.get("sameAsAEndContact")?.value) {
          let e = { target: { checked: true } };
          this.onCheckChange(e, "sameAsPath1AEndContactDetails", "sameAsPath1ZEndContactDetails");
        }

        if (this.contactDetailsForm.get("path2A2sameAsAEndContact") && this.contactDetailsForm.get("path2A2sameAsAEndContact")?.value) {
          let e = { target: { checked: true } };
          this.onCheckChange(e, "sameAsPath1AEndContactDetails", "sameAsPath2AEndContactDetails");
        }

        if (this.contactDetailsForm.get("path2A2sameAsZEndContact") && this.contactDetailsForm.get("path2A2sameAsZEndContact")?.value) {
          let e = { target: { checked: true } };
          this.onCheckChange(e, "sameAsPath1ZEndContactDetails", "sameAsPath2AEndContactDetails");
        }

        if (this.contactDetailsForm.get("path2Z2sameAsAEndContact") && this.contactDetailsForm.get("path2Z2sameAsAEndContact")?.value) {
          let e = { target: { checked: true } };
          this.onCheckChange(e, "sameAsPath1AEndContactDetails", "sameAsPath2ZEndContactDetails");
        }

        if (this.contactDetailsForm.get("path2Z2sameAsZEndContact") && this.contactDetailsForm.get("path2Z2sameAsZEndContact")?.value) {
          let e = { target: { checked: true } };
          this.onCheckChange(e, "sameAsPath1ZEndContactDetails", "sameAsPath2ZEndContactDetails");
        }

        if (this.contactDetailsForm.get("path2Z2sameAsA2EndContact") && this.contactDetailsForm.get("path2Z2sameAsA2EndContact")?.value) {
          let e = { target: { checked: true } };
          this.onCheckChange(e, "sameAsPath2AEndContactDetails", "sameAsPath2ZEndContactDetails");
        }

        let contactDetailsFormValue = this.contactDetailsForm.value;
        contactDetailsFormValue.valid = this.contactDetailsForm.valid;

        this.siteDetailsService.contactDetailsFormChange(contactDetailsFormValue);
      }, 1);
    })
  }

  detailedAssessmentRadioFnc(value) {
    this.contactDetailsForm['controls']['detailedAssessment'].setValue(value);
    this.createContactDetailsForm({ value });
  }

  getPhoneCountryList() {
    this.createQuoteService
      .getPhoneCountryList()
      .then((data: any) => {
        let unique = []
        data.forEach(item => {
          let count = unique.filter(x => x.code == item.code).length

          if (count == 0) {
            unique.push(item)
          }
        })

        this.phoneCountryList = unique.sort((a, b) => a.code - b.code);;
      })
  }

  fundingOtherField = false;
  fundingSourceListChange(e) {
    if (e.toLowerCase().indexOf('sbc') > -1) {
      this.clearFormValidator('otherPowImc');
      this.contactDetailsForm['controls']['l3WBSCodeCapex']?.setValidators([Validators.required, Validators.maxLength(32), Validators.pattern(this.pattern)]);
      this.contactDetailsForm['controls']['l3WBSCodeCapex']?.updateValueAndValidity();
      this.contactDetailsForm['controls']['l3WBSCodeOpex']?.setValidators([Validators.required, Validators.maxLength(32), Validators.pattern(this.pattern)]);
      this.contactDetailsForm['controls']['l3WBSCodeOpex']?.updateValueAndValidity();
    } else if (e === 'Other') {
      //this.aemLabelAuthoring.label.fundingDetails.isOtherPowImc) {
      this.contactDetailsForm['controls']['l3WBSCodeCapex']?.setValidators([Validators.required, Validators.maxLength(32), Validators.pattern(this.pattern)]);
      this.contactDetailsForm['controls']['l3WBSCodeCapex']?.updateValueAndValidity();
      this.contactDetailsForm['controls']['l3WBSCodeOpex']?.setValidators([Validators.required, Validators.maxLength(32), Validators.pattern(this.pattern)]);
      this.contactDetailsForm['controls']['l3WBSCodeOpex']?.updateValueAndValidity();
      this.contactDetailsForm['controls']['otherPowImc']?.setValidators([Validators.required, Validators.maxLength(32), Validators.pattern(this.pattern)]);
      this.contactDetailsForm['controls']['otherPowImc']?.updateValueAndValidity();
    } else {
      this.clearFormValidator('l3WBSCodeCapex')
      this.clearFormValidator('l3WBSCodeOpex')
      this.clearFormValidator('otherPowImc')
    }
  }


  sameAsPath1ZEndContactDetailsDisable = false;
  ZsameAsAEndContact = false;
  path2A2sameAsAEndContact = false;
  path2A2sameAsZEndContact = false;
  path2Z2sameAsAEndContact = false;
  path2Z2sameAsZEndContact = false;
  path2Z2sameAsA2EndContact = false;

  onCheckChange(e, type, from) {


    if (type == "sameAsPath1AEndContactDetails" && from == "sameAsPath1ZEndContactDetails") {
      if (e.target.checked) {
        if (
          this.contactDetailsForm.get("Z1siteContactName")?.value != this.contactDetailsForm.get("A1siteContactName")?.value ||
          this.contactDetailsForm.get("Z1siteContactNumber")?.value != this.contactDetailsForm.get("A1siteContactNumber")?.value ||
          this.contactDetailsForm.get("Z1siteContactEmailAddress")?.value != this.contactDetailsForm.get("A1siteContactEmailAddress")?.value ||
          this.contactDetailsForm.get("Z1alternativeSiteContactName")?.value != this.contactDetailsForm.get("A1alternativeSiteContactName")?.value ||
          this.contactDetailsForm.get("Z1alternativeSiteContactNumber")?.value != this.contactDetailsForm.get("A1alternativeSiteContactNumber")?.value ||
          this.contactDetailsForm.get("Z1alternativeSiteContactEmailAddress")?.value != this.contactDetailsForm.get("A1alternativeSiteContactEmailAddress")?.value
        ) {
          this.sameAsPath1ZEndContactDetailsDisable = true;
          this.contactDetailsForm.patchValue(
            {
              Z1siteContactName: this.contactDetailsForm.get("A1siteContactName")?.value,
              Z1siteContactNumber: this.contactDetailsForm.get("A1siteContactNumber")?.value,
              Z1siteContactEmailAddress: this.contactDetailsForm.get("A1siteContactEmailAddress")?.value,
              Z1alternativeSiteContactName: this.contactDetailsForm.get("A1alternativeSiteContactName")?.value,
              Z1alternativeSiteContactNumber: this.contactDetailsForm.get("A1alternativeSiteContactNumber")?.value,
              Z1alternativeSiteContactEmailAddress: this.contactDetailsForm.get("A1alternativeSiteContactEmailAddress")?.value,
            }
          );



          if(this.contactDetailsForm.get("Z1siteContactNumber")?.value){
            this.contactDetailsForm.get("Z1siteContactNumber")?.markAsTouched();
          }

          if(this.contactDetailsForm.get("Z1alternativeSiteContactNumber")?.value){
            this.contactDetailsForm.get("Z1alternativeSiteContactNumber")?.markAsTouched();
          }

            if(this.contactDetailsForm.get("Z1siteContactEmailAddress")?.value){
              this.contactDetailsForm.get("Z1siteContactEmailAddress")?.markAsTouched();
            }


            if(this.contactDetailsForm.get("Z1alternativeSiteContactEmailAddress")?.value){
              this.contactDetailsForm.get("Z1alternativeSiteContactEmailAddress")?.markAsTouched();
            }


        }
      } else {
        this.sameAsPath1ZEndContactDetailsDisable = false;
        this.contactDetailsForm.patchValue(
          {
            Z1siteContactName: '',
            Z1siteContactNumber: '',
            Z1siteContactEmailAddress: '',
            Z1alternativeSiteContactName: '',
            Z1alternativeSiteContactNumber: '',
            Z1alternativeSiteContactEmailAddress: '',
          }
        );
      }
    }


    if (type == "sameAsPath1AEndContactDetails" && from == "sameAsPath2AEndContactDetails") {
      if (e.target.checked) {
        this.path2A2sameAsAEndContact = true;
        this.contactDetailsForm.patchValue(
          {
            path2A2sameAsAEndContact: true,
            path2A2sameAsZEndContact: false,
            A2siteContactName: this.contactDetailsForm.get("A1siteContactName")?.value,
            A2siteContactNumber: this.contactDetailsForm.get("A1siteContactNumber")?.value,
            A2siteContactEmailAddress: this.contactDetailsForm.get("A1siteContactEmailAddress")?.value,
            A2alternativeSiteContactName: this.contactDetailsForm.get("A1alternativeSiteContactName")?.value,
            A2alternativeSiteContactNumber: this.contactDetailsForm.get("A1alternativeSiteContactNumber")?.value,
            A2alternativeSiteContactEmailAddress: this.contactDetailsForm.get("A1alternativeSiteContactEmailAddress")?.value,
          }
        );


        if(this.contactDetailsForm.get("A2siteContactNumber")?.value){
          this.contactDetailsForm.get("A2siteContactNumber")?.markAsTouched();
        }

        if(this.contactDetailsForm.get("A2alternativeSiteContactNumber")?.value){
          this.contactDetailsForm.get("A2alternativeSiteContactNumber")?.markAsTouched();
        }

          if(this.contactDetailsForm.get("A2siteContactEmailAddress")?.value){
            this.contactDetailsForm.get("A2siteContactEmailAddress")?.markAsTouched();
          }

          if(this.contactDetailsForm.get("A2alternativeSiteContactEmailAddress")?.value){
            this.contactDetailsForm.get("A2alternativeSiteContactEmailAddress")?.markAsTouched();
          }


      } else {
        this.path2A2sameAsAEndContact = false;
        this.contactDetailsForm.patchValue(
          {
            A2siteContactName: '',
            A2siteContactNumber: '',
            A2siteContactEmailAddress: '',
            A2alternativeSiteContactName: '',
            A2alternativeSiteContactNumber: '',
            A2alternativeSiteContactEmailAddress: '',
          }
        );
      }
    }

    if (type == "sameAsPath1ZEndContactDetails" && from == "sameAsPath2AEndContactDetails") {
      if (e.target.checked) {
        this.path2A2sameAsZEndContact = true;
        this.contactDetailsForm.patchValue(
          {
            path2A2sameAsAEndContact: false,
            path2A2sameAsZEndContact: true,
            A2siteContactName: this.contactDetailsForm.get("Z1siteContactName")?.value,
            A2siteContactNumber: this.contactDetailsForm.get("Z1siteContactNumber")?.value,
            A2siteContactEmailAddress: this.contactDetailsForm.get("Z1siteContactEmailAddress")?.value,
            A2alternativeSiteContactName: this.contactDetailsForm.get("Z1alternativeSiteContactName")?.value,
            A2alternativeSiteContactNumber: this.contactDetailsForm.get("Z1alternativeSiteContactNumber")?.value,
            A2alternativeSiteContactEmailAddress: this.contactDetailsForm.get("Z1alternativeSiteContactEmailAddress")?.value,
          }
        );

        if(this.contactDetailsForm.get("A2siteContactNumber")?.value){
          this.contactDetailsForm.get("A2siteContactNumber")?.markAsTouched();
        }
        if(this.contactDetailsForm.get("A2alternativeSiteContactNumber")?.value){
          this.contactDetailsForm.get("A2alternativeSiteContactNumber")?.markAsTouched();
        }


          if(this.contactDetailsForm.get("A2siteContactEmailAddress")?.value){
            this.contactDetailsForm.get("A2siteContactEmailAddress")?.markAsTouched();
          }


          if(this.contactDetailsForm.get("A2alternativeSiteContactEmailAddress")?.value){
            this.contactDetailsForm.get("A2alternativeSiteContactEmailAddress")?.markAsTouched();
          }


      } else {
        this.path2A2sameAsZEndContact = false;
        this.contactDetailsForm.patchValue(
          {
            A2siteContactName: '',
            A2siteContactNumber: '',
            A2siteContactEmailAddress: '',
            A2alternativeSiteContactName: '',
            A2alternativeSiteContactNumber: '',
            A2alternativeSiteContactEmailAddress: '',
          }
        );
      }
    }


    if (type == "sameAsPath1AEndContactDetails" && from == "sameAsPath2ZEndContactDetails") {
      if (e.target.checked) {
        this.path2Z2sameAsAEndContact = true;
        this.contactDetailsForm.patchValue(
          {
            path2Z2sameAsAEndContact: true,
            path2Z2sameAsZEndContact: false,
            path2Z2sameAsA2EndContact: false,
            Z2siteContactName: this.contactDetailsForm.get("A1siteContactName")?.value,
            Z2siteContactNumber: this.contactDetailsForm.get("A1siteContactNumber")?.value,
            Z2siteContactEmailAddress: this.contactDetailsForm.get("A1siteContactEmailAddress")?.value,
            Z2alternativeSiteContactName: this.contactDetailsForm.get("A1alternativeSiteContactName")?.value,
            Z2alternativeSiteContactNumber: this.contactDetailsForm.get("A1alternativeSiteContactNumber")?.value,
            Z2alternativeSiteContactEmailAddress: this.contactDetailsForm.get("A1alternativeSiteContactEmailAddress")?.value,
          }
        );


        if(this.contactDetailsForm.get("Z2siteContactNumber")?.value){
          this.contactDetailsForm.get("Z2siteContactNumber")?.markAsTouched();
        }


        if(this.contactDetailsForm.get("Z2alternativeSiteContactNumber")?.value){
          this.contactDetailsForm.get("Z2alternativeSiteContactNumber")?.markAsTouched();
        }


        if(this.contactDetailsForm.get("Z2siteContactEmailAddress")?.value){
          this.contactDetailsForm.get("Z2siteContactEmailAddress")?.markAsTouched();
        }

        if(this.contactDetailsForm.get("Z2alternativeSiteContactEmailAddress")?.value){
          this.contactDetailsForm.get("Z2alternativeSiteContactEmailAddress")?.markAsTouched();
        }





      } else {
        this.path2Z2sameAsAEndContact = false;
        this.contactDetailsForm.patchValue(
          {
            Z2siteContactName: '',
            Z2siteContactNumber: '',
            Z2siteContactEmailAddress: '',
            Z2alternativeSiteContactName: '',
            Z2alternativeSiteContactNumber: '',
            Z2alternativeSiteContactEmailAddress: '',
          }
        );
      }
    }

    if (type == "sameAsPath1ZEndContactDetails" && from == "sameAsPath2ZEndContactDetails") {
      if (e.target.checked) {
        this.path2Z2sameAsZEndContact = true;
        this.contactDetailsForm.patchValue(
          {
            path2Z2sameAsAEndContact: false,
            path2Z2sameAsZEndContact: true,
            path2Z2sameAsA2EndContact: false,
            Z2siteContactName: this.contactDetailsForm.get("Z1siteContactName")?.value,
            Z2siteContactNumber: this.contactDetailsForm.get("Z1siteContactNumber")?.value,
            Z2siteContactEmailAddress: this.contactDetailsForm.get("Z1siteContactEmailAddress")?.value,
            Z2alternativeSiteContactName: this.contactDetailsForm.get("Z1alternativeSiteContactName")?.value,
            Z2alternativeSiteContactNumber: this.contactDetailsForm.get("Z1alternativeSiteContactNumber")?.value,
            Z2alternativeSiteContactEmailAddress: this.contactDetailsForm.get("Z1alternativeSiteContactEmailAddress")?.value,
          }
        );


        if(this.contactDetailsForm.get("Z2siteContactNumber")?.value){
          this.contactDetailsForm.get("Z2siteContactNumber")?.markAsTouched();
        }


        if(this.contactDetailsForm.get("Z2alternativeSiteContactNumber")?.value){
          this.contactDetailsForm.get("Z2alternativeSiteContactNumber")?.markAsTouched();
        }

        if(this.contactDetailsForm.get("Z2siteContactEmailAddress")?.value){
          this.contactDetailsForm.get("Z2siteContactEmailAddress")?.markAsDirty();
        }


        if(this.contactDetailsForm.get("Z2alternativeSiteContactEmailAddress")?.value){
          this.contactDetailsForm.get("Z2alternativeSiteContactEmailAddress")?.markAsDirty();
        }




      } else {
        this.path2Z2sameAsZEndContact = false;
        this.contactDetailsForm.patchValue(
          {
            Z2siteContactName: '',
            Z2siteContactNumber: '',
            Z2siteContactEmailAddress: '',
            Z2alternativeSiteContactName: '',
            Z2alternativeSiteContactNumber: '',
            Z2alternativeSiteContactEmailAddress: '',
          }
        );
      }
    }

    if (type == "sameAsPath2AEndContactDetails" && from == "sameAsPath2ZEndContactDetails") {
      if (e.target.checked) {
        this.path2Z2sameAsA2EndContact = true;
        this.contactDetailsForm.patchValue(
          {
            path2Z2sameAsAEndContact: false,
            path2Z2sameAsZEndContact: false,
            path2Z2sameAsA2EndContact: true,
            Z2siteContactName: this.contactDetailsForm.get("A2siteContactName")?.value,
            Z2siteContactNumber: this.contactDetailsForm.get("A2siteContactNumber")?.value,
            Z2siteContactEmailAddress: this.contactDetailsForm.get("A2siteContactEmailAddress")?.value,
            Z2alternativeSiteContactName: this.contactDetailsForm.get("A2alternativeSiteContactName")?.value,
            Z2alternativeSiteContactNumber: this.contactDetailsForm.get("A2alternativeSiteContactNumber")?.value,
            Z2alternativeSiteContactEmailAddress: this.contactDetailsForm.get("A2alternativeSiteContactEmailAddress")?.value,
          }
        );


        if(this.contactDetailsForm.get("Z2siteContactNumber")?.value){
          this.contactDetailsForm.get("Z2siteContactNumber")?.markAsTouched();
        }

        if(this.contactDetailsForm.get("Z2alternativeSiteContactNumber")?.value){
          this.contactDetailsForm.get("Z2alternativeSiteContactNumber")?.markAsTouched();
        }

          if(this.contactDetailsForm.get("Z2siteContactEmailAddress")?.value){
            this.contactDetailsForm.get("Z2siteContactEmailAddress")?.markAsTouched();
          }


          if(this.contactDetailsForm.get("Z2alternativeSiteContactEmailAddress")?.value){
            this.contactDetailsForm.get("Z2alternativeSiteContactEmailAddress")?.markAsTouched();
          }

      } else {
        this.path2Z2sameAsA2EndContact = false;
        this.contactDetailsForm.patchValue(
          {
            Z2siteContactName: '',
            Z2siteContactNumber: '',
            Z2siteContactEmailAddress: '',
            Z2alternativeSiteContactName: '',
            Z2alternativeSiteContactNumber: '',
            Z2alternativeSiteContactEmailAddress: '',
          }
        );
      }
    }

  }


  clearFormValidator(id: string) {
    this.contactDetailsForm['controls'][id]?.clearValidators();
    this.contactDetailsForm['controls'][id]?.updateValueAndValidity();
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  numberWithDecimal(event): boolean {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }



  @HostListener('window:keydown', ['$event'])
  handleKeyUp(event) {
    document.getElementById('detailedAssessmentYesRadio')?.setAttribute('tabindex', '0');
    document.getElementById('detailedAssessmentNoRadio')?.setAttribute('tabindex', '0');
  }


  onNumericKeyPressDown(event: KeyboardEvent) {
    if (event.key >= '0' && event.key <= '9') {
      event.preventDefault();
    }
  }


onPasteCheckNumber(event: ClipboardEvent): void {
  const d = event.clipboardData.getData('text/plain');
  if (this.validateNumber(d)) {
    event.preventDefault();
  }
}

validateNumber(value: string): boolean {
  return /^\d+$/.test(value);
}

}
