import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-stepper',
  templateUrl: './page-stepper.component.html',
  styleUrls: ['./page-stepper.component.less']
})
export class PageStepperComponent implements OnInit {

  constructor() { }
  @Input() stepperData: any;
  @Input() stepName: string;
  ngOnInit(): void {
  }


}
